<template src='./activityRescheduleRequests.html'>
</template>

<script>
import inputContainer from '../../Components/inputContainer'
import RescheduleRequestsRepository from '../../Repository/RescheduleRequests'
import UserRepository from '../../Repository/User'
import LectureRepository from '../../Repository/lectures'
import SubjectRepository from '../../Repository/Subjects'
import { RescheduleRequestStatus } from '../../Constants/Utils/Statics'
import { convertDate, timeStringConverter } from '../../Services/Utils/DateTimeFormatter'
export default {
  name: 'activityRescheduleRequests',
  components: {
    inputContainer
  },
  data () {
    return {
      userData: {},
      selectedInstitute: '',
      selectedSemester: {},
      selectedMode: 'Incoming Requests',
      modes: ['Incoming Requests', 'Outgoing Requests'],
      incomingRequests: [],
      outgoingRequests: [],
      incomingHeaders: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Course Year',
          value: 'courseYear'
        },
        {
          text: 'Division',
          value: 'division'
        },
        {
          text: 'Date Of Lecture',
          value: 'dateTime'
        },
        {
          text: 'Subject',
          value: 'subjectName'
        },
        {
          text: 'Sent By',
          value: 'facultyName'
        },
        {
          text: 'Recieved At',
          value: 'rescheduleRequestTime'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      outgoingHeaders: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Course Year',
          value: 'courseYear'
        },
        {
          text: 'Division',
          value: 'division'
        },
        {
          text: 'Date Of Lecture',
          value: 'dateTime'
        },
        {
          text: 'Subject',
          value: 'subjectName'
        },
        {
          text: 'Sent To',
          value: 'facultyName'
        },
        {
          text: 'Sent At',
          value: 'rescheduleRequestTime'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      selectedRequest: {},
      availableSubjects: [],
      availableSubjectsNames: [],
      tempSubject: '',
      acceptingRequest: false
    }
  },
  created () {
    this.$store.commit('liveData/set_selectedActivityName', 'My Reschedule Requests')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.userRepositoryInstance = new UserRepository(this)
    this.lectureRepositoryInstance = new LectureRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.rescheduleRequestsRepositoryInstance = new RescheduleRequestsRepository(this)
    console.log(this.userData, this.selectedInstitute, this.selectedSemester)
    this.fetchData()
  },
  methods: {
    async fetchData () {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId
      }
      if (this.selectedMode === 'Incoming Requests') {
        this.getIncomingRequests(objToPush)
      } else {
        this.getOutgoingRequests(objToPush)
      }
    },
    async getIncomingRequests (objToPush) {
      try {
        const incomingRequests = await this.rescheduleRequestsRepositoryInstance.getReceivedRescheduleRequests(objToPush)
        this.incomingRequests = await this.processRequests(incomingRequests, true)
      } catch (err) {
        console.log(err)
        this.incomingRequests = []
      }
    },
    async getOutgoingRequests (objToPush) {
      try {
        const outgoingRequests = await this.rescheduleRequestsRepositoryInstance.getSentRescheduleRequests(objToPush)
        this.outgoingRequests = await this.processRequests(outgoingRequests, false)
      } catch (err) {
        console.log(err)
        this.outgoingRequests = []
      }
    },
    async processRequests (requests, isIncomingRequest) {
      let promises = []
      console.log(requests)

      for (let i = 0; i < requests.length; i++) {
        requests[i].rescheduleRequestTime = this.getDateAndTimeFromDateString(requests[i].requestSentAt)

        promises.push(this.getDetailsOfRequest(requests[i], isIncomingRequest))
        // promises.push(this.getSubjectName(requests[i]))
        // promises.push(this.getUserName(requests[i]))
        // const subjectName = await this.getSubjectName(requests[i].subjectId)
        // requests[i].subjectName = subjectName
        // const facultyName = await this.getUserName(requests[i].subjectId)
        // requests[i].facultyName = facultyName
        // requests.push(requests[i])
      }

      promises = await Promise.allSettled(promises)
      console.log(promises)
      return promises.map((promise) => promise.value)
    },
    async getDetailsOfRequest (requestObject, isIncomingRequest) {
      requestObject = await this.getLectureForARequest(requestObject)
      await this.getSubjectName(requestObject)
      await this.getUserName(requestObject, isIncomingRequest)
      return requestObject
    },
    async getLectureForARequest (requestObject) {
      try {
        const lecture = await this.lectureRepositoryInstance.getLecture({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          lectureId: requestObject.requestingLectureId
        })
        requestObject.lecture = lecture
      } catch (err) {
        console.log(err)
      }
      return requestObject
    },
    async getUserName (requestObject, isIncomingRequest) {
      try {
        requestObject.facultyName = await this.userRepositoryInstance.getFullNameOfUser({ uId: isIncomingRequest ? requestObject.lecture ? requestObject.lecture.uId : '' : requestObject.requestedFacultyId })
      } catch (err) {
        console.log(err)
        requestObject.facultyName = ''
      }
    },
    async getSubjectName (requestObject) {
      try {
        if (!requestObject.subjectName) {
          const lecture = await this.lectureRepositoryInstance.getLecture({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            lectureId: requestObject.requestingLectureId
          })
          requestObject.department = lecture.department
          requestObject.courseYear = lecture.courseYear
          requestObject.division = lecture.division
          requestObject.batch = lecture.batch
          requestObject.dateTime = this.getDateAndTimeFromDateString(lecture.dateTime)
          requestObject.subjectName = await this.subjectRepositoryInstance.getSubjectName({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            subjectId: lecture.subjectId
          })
        }
      } catch (err) {
        console.log(err)
        requestObject.subjectName = ''
      }
    },
    getDateAndTimeFromDateString (dateString) {
      const parsedDate = new Date(dateString)
      const time = timeStringConverter(
        ('0' + parsedDate.getHours()).slice(-2) +
        '_' +
        ('0' + parsedDate.getMinutes()).slice(-2)
      )
      return convertDate(dateString) + ' ' + time
    },
    async cancelRequest (request) {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          rescheduleRequestId: this.selectedRequest.rescheduleRequestId,
          requestingLectureId: this.selectedRequest.requestingLectureId,
          status: RescheduleRequestStatus.CANCELLED
        }

        this.selectedRequest.status = await this.rescheduleRequestsRepositoryInstance.cancelAPendingRescheduleRequest(objToPush)
        this.incomingRequests[this.incomingRequests.indexOf(this.selectedRequest)].status = this.selectedRequest.status
        this.selectedRequest = ''
      } catch (err) {
        console.log(err)
      }
    },
    async acceptRequestStep1 (item) {
      this.selectedRequest = item
      this.availableSubjects = Object.values(this.$store.getters['mappings/get_subjectsMapping'])
      this.availableSubjectsNames = this.availableSubjects.map((subjectObject) => subjectObject.subjectName)
      this.tempSubject = ''
      this.acceptingRequest = true
    },
    async acceptRequestStep2 () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          rescheduleRequestId: this.selectedRequest.rescheduleRequestId,
          requestingLectureId: this.selectedRequest.requestingLectureId,
          requestedFacultyId: this.selectedRequest.requestedFacultyId,
          subjectId: this.availableSubjects[this.availableSubjectsNames.indexOf(this.tempSubject)].subjectId,
          roomId: '',
          status: RescheduleRequestStatus.ACCEPTED
        }
        this.selectedRequest.status = await this.rescheduleRequestsRepositoryInstance.acceptOrRejectARescheduleRequest(objToPush)
        this.incomingRequests[this.incomingRequests.indexOf(this.selectedRequest)].status = this.selectedRequest.status
        this.selectedRequest = ''
      } catch (err) {
        console.log(err)
      }
      console.log(this.availableSubjects[this.availableSubjectsNames.indexOf(this.tempSubject)])
      this.acceptingRequest = false
    },
    async declineRequest (item) {
      console.log(item)
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          rescheduleRequestId: item.rescheduleRequestId,
          requestingLectureId: item.requestingLectureId,
          status: RescheduleRequestStatus.REJECTED
        }
        console.log(objToPush)
        item.status = await this.rescheduleRequestsRepositoryInstance.acceptOrRejectARescheduleRequest(objToPush)
        // this.incomingRequests[this.incomingRequests.indexOf(item)].status = this.selectedRequest.status
        this.selectedRequest = ''
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style src='./activityRescheduleRequests.css'>
</style>
