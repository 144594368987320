export default {
  getAssignmentUsersOfAnAssignmentForADivision:
    "/assignmentUser/getAssignmentUsersOfAnAssignmentForADivision",
    getAssignmentUserForMultipleAssignment:
    "/assignmentUser/getAssignmentUserForMultipleAssignment",
  getSubmittedCountOfAnAssignmentForADivision:
    "/assignmentUser/getSubmittedCountOfAnAssignmentForADivision",
  updateMarksObtainedOfAnUserForAnAssignment:
    "/assignmentUser/updateMarksObtainedOfAnUserForAnAssignment",
    bulkUpdateMarksObtainedForAnAssignment:
    "/assignmentUser/bulkUpdateMarksObtainedForAnAssignment",
    updateQuestionsMarksOfAnUserForAnAssignment:
    "/assignmentUser/updateQuestionsMarksOfAnUserForAnAssignment",
    updateQuestionsMarksOfAllAssignmentUsers:
    "/assignmentUser/updateQuestionsMarksOfAllAssignmentUsers",
    updateUserAssignment:
    "/assignmentUser/updateUserAssignment",
  updateRemarkOfAnUserForAnAssignment:
    "/assignmentUser/updateRemarkOfAnUserForAnAssignment",
    bulkUpdateUserAssignment:
    "/assignmentUser/bulkUpdateUserAssignment",
  checkAssignment: "/assignmentUser/checkAssignment",
  deleteAssignment: "/assignmentUser/deleteAssignmentUser"
};
