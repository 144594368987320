export default {
  createAssignment: '/assignment/createAssignment',
  getAssignmentById: '/assignment/getAssignmentById',
  updateAssignment: '/assignment/updateAssignment',
  getAssignmentsByAFaculty: '/assignment/getAssignmentsByAFaculty',
  getAssignmentsByAFacultyForASubject: '/assignment/getAssignmentsByAFacultyForASubject',
  getAssignmentsByAFacultyForASubjectForADivision: '/assignment/getAssignmentsByAFacultyForASubjectForADivision',
  getAssignmentsByAFacultyForASubjectForAGroup: '/assignment/getAssignmentsByAFacultyForASubjectForAGroup',
  generateAssignmentReport: '/excel/assignment/generateAssignmentReport',
  deleteAssignment: '/assignment/deleteAssignment'
}
