import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activityCreateInstituteOrUser"},[_c(VApp,[_c(VAppBar,{staticStyle:{"background":"#010127","color":"white"},attrs:{"app":"","absolute":"","elevate-on-scroll":""}},[_c('div',{staticStyle:{"margin-right":"10px"}},[_c(VImg,{staticClass:"grey lighten-2",attrs:{"src":require("../../Assets/Images/logo2.png"),"width":"30","height":"30","aspect-ratio":"1"}})],1),_c(VToolbarTitle,[_vm._v("InProspect Assistant")]),_c(VSpacer)],1),_c(VMain,[_c('activityProfile')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }