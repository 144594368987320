import firebase from "firebase";
import { store } from "../../Store/index";

var config = {
  apiKey: "AIzaSyBEv8iqVOBCyAEOUzqNYNPF6CfAf5GPXBg",
  authDomain: "finalinprospect.firebaseapp.com",
  databaseURL: "https://finalinprospect.firebaseio.com",
  projectId: "finalinprospect",
  storageBucket: "finalinprospect.appspot.com",
  messagingSenderId: "787468078530",
  appId: "1:787468078530:web:9f1b569a476f4239"
};

const auth = {
  context: null,
  messaging: null,

  init(context) {
    // let self = this
    this.context = context;
    this.users = [];
    firebase.initializeApp(config);

    firebase.auth().onAuthStateChanged((user) => {
      // const self = this
      if (user) {
        user.getIdToken().then((authToken) => {
          this.context.$store.dispatch("user/set_user", user);
          this.context.$store.dispatch("user/set_authToken", authToken);
          this.context.$router.push("/activitySetup");
        });
      }
    });
  },
  getFirebaseObject() {
    return firebase;
  },
  login() {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("Profile");
    provider.addScope("email");
    return firebase
      .auth()
      .signInWithPopup(provider)
      .catch(function (err) {
        console.log(err);
        alert(
          "Something went wrong while logging in using Gmail. Please try again."
        );
      });
  },
  loginWithMicrosoft() {
    var provider = new firebase.auth.OAuthProvider("microsoft.com");
    provider.addScope("profile");
    provider.addScope("email");
    return firebase
      .auth()
      .signInWithPopup(provider)
      .catch(function (err) {
        console.log(err);
        alert(
          "Something went wrong while logging in using microsoft. Please try again."
        );
      });
  },
  user() {
    return this.context ? firebase.auth().currentUser : null;
  },
  logout(context) {
    firebase
      .auth()
      .signOut()
      .then(function () {
        context.$router.push("/activityWelcome");
      })
      .catch(function (err) {
        console.log(err);
      });
  },
  async getPortalNavigationSecurity(data) {
    try {
      const collectionRef = firebase.database().ref("portalNavigationBackup");
      const snapshot = await collectionRef
        .orderByChild("adminUId")
        .equalTo(data.adminUId)
        .once("value");
      let isFound = false;
      snapshot.forEach((childSnapshot) => {
        const temp = childSnapshot.val();
        if (temp.authenticationId === data.reAuthenticationId) {
          isFound = true;
        }
      });
      return isFound;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  getAttendance(obj) {
    // status = 1   attendance marked,
    // status = 2   attendance is already marked
    // status = 3   not belong to that lecture
    // status = 4   token mismatched
    // status = 5   Look like proxy attempt (deviceId already added)
    const deviceIdArr = []
    var commentsRef = firebase
      .database()
      .ref("qrCodeAttendance/" + obj.lectureId);
    commentsRef.on("child_added", (data) => {
      const tokenId = store.getters["liveData/get_tokenForQr"];
      // console.log('tokenId', tokenId);
      // console.log('data#', data.val().token);
      if (!deviceIdArr.includes(data.val().deviceId)) {
      if (tokenId === data.val().token) {
        const liveData = store.getters["liveData/get_studentAttendance"];
        const isAlreadyPresent = liveData.findIndex(
          (item) => item.uId === data.val().uId
        );
        if (isAlreadyPresent === -1) {
          const index1 = obj.applicableStudent.findIndex(
            (item) => item.uId === data.val().uId
          );
          if (index1 !== -1) {
            store.commit("liveData/set_studentAttendance", [
              ...liveData,
              obj.applicableStudent[index1]
            ]);
            const updatedObj = data.val();
            updatedObj["status"] = 1;
            deviceIdArr.push(data.val().deviceId)
            const updates = {};
            updates["/qrCodeAttendance/" + obj.lectureId + "/" + data.key] =
              updatedObj;
            firebase.database().ref().update(updates);
          } else {
            // send mes your not belong to that lecture
            const updatedObj = data.val();
            updatedObj["status"] = 3;
            const updates = {};
            updates["/qrCodeAttendance/" + obj.lectureId + "/" + data.key] =
              updatedObj;
            firebase.database().ref().update(updates);
          }
        } else {
          // send mes your attendance is already marked
          const updatedObj = data.val();
          updatedObj["status"] = 2;
          deviceIdArr.push(data.val().deviceId)
          const updates = {};
          updates["/qrCodeAttendance/" + obj.lectureId + "/" + data.key] =
            updatedObj;
          firebase.database().ref().update(updates);
        }
      } else {
        // token mismatched
        const updatedObj = data.val();
        updatedObj["status"] = 4;
        const updates = {};
        updates["/qrCodeAttendance/" + obj.lectureId + "/" + data.key] =
          updatedObj;
        firebase.database().ref().update(updates);
      }
      } else {
        const updatedObj = data.val()
        updatedObj["status"] = 5
        const updates = {};
        const liveData = store.getters['liveData/get_studentAttendance']
        const isAlreadyPresent = liveData.findIndex(item => item.uId === data.val().uId);
        if (isAlreadyPresent === -1) {
          updatedObj["status"] = 5
        } else {
          updatedObj["status"] = 2
        }
        updates['/qrCodeAttendance/' + obj.lectureId + '/' + data.key] = updatedObj;
        firebase.database().ref().update(updates)
      }
      //find proxy
      // const proxyLiveData = store.getters['liveData/get_proxyData']
      // const obj1 = {
      //   deviceId: data.val().deviceId,
      //   uIds: [data.val().uId]
      // }
      // if (proxyLiveData.length === 0) {
      //   proxyLiveData.push(obj1)
      // } else {
      //   let ifFound = false
      //   proxyLiveData.map((info) => {
      //     if (info.deviceId === data.val().deviceId) {
      //       ifFound = true
      //       if (!info.uIds.includes(data.val().uId)) {
      //         info.uIds.push(data.val().uId)
      //       }
      //     }
      //   })
      //   if (ifFound === false) {
      //     proxyLiveData.push(obj1)
      //   }
      // }
      // store.commit("liveData/set_proxyData", proxyLiveData)
    });
  },
  deleteDocumentFromFirebase(lectureId) {
    firebase
      .database()
      .ref("/qrCodeAttendance/" + lectureId)
      .remove();
  },
  async getTempThresholdFromFirebase(instituteId) {
    let infoData = [];
    // Specify the ID you want to check

    // Reference the collection and the specific document
    const docRef = firebase.database().ref("/tempThreshold/" + instituteId);
    // Get the data for the specific document
    await docRef
      .once("value")
      .then(function (snapshot) {
        if (snapshot.exists()) {
          // The document with the specified ID exists
          infoData = snapshot.val();
        } else {
          // The document with the specified ID does not exist
          console.log("Document does not exist!");
        }
      })
      .catch(function (error) {
        console.log("Error fetching document data:", error);
      });
    return infoData;
  },
  async getInstituteAccessDocument(instituteId) {
    let infoData = [];
    // Specify the ID you want to check

    // Reference the collection and the specific document
    const docRef = firebase
      .database()
      .ref("/instituteCustomization/" + instituteId);
    // Get the data for the specific document
    await docRef
      .once("value")
      .then(function (snapshot) {
        if (snapshot.exists()) {
          // The document with the specified ID exists
          infoData = [snapshot.val()];
          // console.log("infoData", infoData);
        } else {
          // The document with the specified ID does not exist
          console.log("Document does not exist!");
        }
      })
      .catch(function (error) {
        console.log("Error fetching document data:", error);
      });
    return infoData;
  },
  async qrCodeAttendanceDataTransferToBackup(lectureData) {
    // console.log('lectureData', lectureData);
    let infoData = [];
    const docRef = firebase
      .database()
      .ref("/qrCodeAttendance/" + lectureData.lectureId);
    await docRef
      .once("value")
      .then(function (snapshot) {
        if (snapshot.exists()) {
          infoData = snapshot.val();
          const arrayOfValues = Object.values(infoData);
          // const count = 0;
          const objToPush = {
            instituteId: lectureData.instituteId,
            semId: lectureData.semId,
            department: lectureData.department,
            courseYear: lectureData.courseYear,
            subjectName: lectureData.subjectName,
            subjectId: lectureData.subjectId,
            division: lectureData.division,
            batch: lectureData.batch ? lectureData.batch : "",
            group: lectureData.groupForSubject
              ? lectureData.groupForSubject
              : "",
            timeOfLecture: lectureData.dateTime,
            lectureId: lectureData.lectureId,
            facultyName: lectureData.facultyName,
            uId: lectureData.uId,
            scanTime: new Date(),
            allScanDetails: arrayOfValues
          };
          // console.log('objToPush', objToPush);
          if (arrayOfValues.length > 0) {
            firebase.database().ref("qrAttendanceBackup/" + lectureData.lectureId).push(objToPush);

            firebase.database().ref('/qrCodeAttendance/' + lectureData.lectureId).remove()
          } else {
            // firebase.database().ref('/qrCodeAttendance/' + lectureData.lectureId).remove()
          }
        } else {
          console.log("Document does not exist!");
        }
      })
      .catch(function (error) {
        console.log("Error fetching document data:", error);
      });
  },
  async getDocumentFromFirebase(collectionName, id) {
    let infoData = [];
    // Specify the ID you want to check
    // Reference the collection and the specific document
    const docRef = firebase.database().ref(`/${collectionName}/` + id);
    // Get the data for the specific document
    await docRef
      .once("value")
      .then(function (snapshot) {
        if (snapshot.exists()) {
          // The document with the specified ID exists
          infoData = snapshot.val();
        } else {
          // The document with the specified ID does not exist
          console.log("Document does not exist!");
        }
      })
      .catch(function (error) {
        console.log("Error fetching document data:", error);
      });
    return infoData;
  }

};

export default auth;
