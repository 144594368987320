<template src="./activityAttendanceAnalysis.html"></template>

<script>
import inputContainer from '../../Components/inputContainer'
// import showStatus from '../../NetworkManager/showStatus'
import LectureRepository from '../../Repository/lectures'
import UserRepository from '../../Repository/User'
import SemesterUserRepository from '../../Repository/SemesterUser'
import SemesterRepository from "../../Repository/Semester";
// import showStatus from "../../NetworkManager/showStatus";
import moment from 'moment'
import axios from 'axios'
import ExcelJS from 'exceljs'
import {
  convertUTCDateToLocalDate, timeStringConverter
} from "../../Services/Utils/DateTimeFormatter";
import arrayOps from "../../Services/Utils/arrayOps";

export default {
  name: 'activityAttendanceAnalysis',
  components: {
    inputContainer
  },
  props: ['prop_subject', 'prop_type', 'prop_divisionName', 'prop_groupInfo'],
  data() {
    return {
      addedLecInfo: [],
      updatedLecCount: 0,
      beyondDateLec: [],
      pageLoader: false,
      dialogForLectureSummary: false,
      errorMessageArr: [],
      StudentList2: [],
      timeAndDateFormatError: [],
      alreadyCreatedLecture: [],
      lectureNotCreatedArr: [],
      lectureCreatedSummery: [],
      validationProp: false,
      studentRollNumberNotPresentArr: [],
      groupNameList: [],
      selectedGroupName: '',
      studentData: [],
      allLecturesData: [],
      file: [],
      uploadExcelForSubject: false,
      userData: {},
      isMobileView: false,
      totalStudents: 0,
      totalLectures: 0,
      selectedLimit: 75,
      defaulterStudentsPercent: 0,
      defaulterStudentsCount: 0,
      nondefaulterStudentsPercent: 0,
      nondefaulterStudentsCount: 0,
      search: '',
      studentsList: [],
      headersMobile: [
        {
          text: '',
          value: 'defaulterStatusDotnondefaulterStatusDot'
        },
        {
          text: 'Name | RollNumber',
          value: 'namerollNumber'
        },
        {
          text: 'Attended | Total',
          value: 'totalAttendedtotalConducted',
          width: 20
        }
      ],
      headers: [
        {
          text: '',
          value: 'defaulterStatusDotnondefaulterStatusDot'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Roll Number',
          value: 'rollNumber'
        },
        {
          text: 'Reporting Date',
          value: 'reportingDate'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Attended',
          value: 'totalAttended'
        },
        {
          text: 'Total',
          value: 'totalConducted'
        },
        {
          text: 'Percentage%',
          value: 'percentage'
        }
      ],
      selectedSubject: {},
      dates: [
        moment().subtract(6, 'months').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      menu: false
    }
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    }
  },
  created() {
    // console.log('this.prop_divisionName', this.prop_divisionName);
    const currentDate = moment(); // Get the current date
    const oneDayLater = currentDate.add(1, 'days'); // Add 1 day to the current date
    const formattedDate = oneDayLater.format('YYYY-MM-DD');
    this.dates[1] = formattedDate
    this.$store.commit(
      'liveData/set_selectedActivityName',
      'Attendance Analysis'
    )
    if (this.prop_subject.groupForSubject.length > 0) {
      this.prop_subject.groupForSubject.map((data) => {
        this.groupNameList.push(data.groupName)
      })
      this.selectedGroupName = this.prop_subject.groupForSubject[0].groupName
    }
    this.selectedInstitute =
      this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester =
      this.$store.getters['instituteData/get_selectedSemester']
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    this.userData = this.$store.getters['user/get_userData']
    this.lectureRepositoryInstance = new LectureRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    // this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    if (this.prop_subject === 'classteacher') {
      // this.selectedSubject = JSON.parse(JSON.stringify(this.prop_subject))
    } else {
      this.selectedSubject = JSON.parse(
        JSON.stringify(this.$store.getters['liveData/get_selectedSubject'])
      )
    }
    this.fetchData()
  },
  methods: {
    async groupNameChanged() {
      const dateRangeFilter = {}
      if (this.dates.length === 2) {
        dateRangeFilter.fromDate = this.dates[0]
        dateRangeFilter.toDate = this.dates[1]
      }
      let groupName = ''
      let groupId = ''
      this.prop_subject.groupForSubject.map((data) => {
        if (data.groupName === this.selectedGroupName) {
          groupId = data.groupId
          groupName = data.groupName
        }
      })
      try {
        const objToPush = {
          type: 'Group',
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear,
          division: '',
          batch: this.selectedSubject.batch,
          subjectId: this.selectedSubject.subjectId,
          uId: this.userData.uId,
          groupId: groupId,
          groupName: groupName,
          ...dateRangeFilter
        }
        const info =
          await this.lectureRepositoryInstance.getAttendanceAnalysisForADivisionForASubject(
            objToPush
          )
        // console.log('info', info);
        this.studentsList = []
        this.studentsList = info.attendanceAnalysis
        // this.studentsList.sort((a, b) => a.rollNumber - b.rollNumber)
        this.studentsList = arrayOps.sortByProperty(this.studentsList, 'rollNumber')
        this.allLecturesData = []
        this.allLecturesData = info.lecture

        this.totalLectures =
          this.studentsList.length > 0
            ? this.studentsList[0].totalConducted
            : 0
      } catch (err) {
        console.log(err)
        this.studentsList = []
        this.totalLectures = 0
      }
      // console.log(this.studentsList)

      // const promises = []
      this.totalStudents = this.studentsList.length
      this.defaulterStudentsCount = 0
      this.nondefaulterStudentsCount = 0

      for (let i = 0; i < this.studentsList.length; i++) {
        this.studentsList[i].percentage =
          this.studentsList[i].totalConducted > 0
            ? (
              (this.studentsList[i].totalAttended /
                this.studentsList[i].totalConducted) *
              100
            ).toFixed(2)
            : 0
        // promises.push(this.getNameOfStudent(i))
      }
      this.changeLimit()
      this.studentsList = this.studentsList.slice()
    },
    async fetchDateRangeData() {
      await this.fetchData()
      this.$refs.menu.save(this.dates)
    },
    async fetchData() {
      try {
        let objToPush = {}
        const dateRangeFilter = {}
        const examSemester = await this.semesterRepositoryInstance.getSemestersBySemId({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        });
        this.dates[0] = examSemester[0].startDate.split('T')[0]
        this.dates[1] = examSemester[0].endDate.split('T')[0]
        if (this.dates.length === 2) {
          dateRangeFilter.fromDate = this.dates[0]
          dateRangeFilter.toDate = this.dates[1]
        }
        if (this.prop_subject === 'classteacher') {
          const objToPush =
            await this.userRepositoryInstance.getDivisionInformationOfAFacultyAsClassTeacher(
              {
                instituteId: this.userData.instituteId,
                semId: this.userData.semId,
                uId: this.userData.uId
              }
            )
          this.studentsList =
            await this.lectureRepositoryInstance.getAttendanceAnalysisForADivision(
              Object.assign(objToPush, {
                instituteId: this.userData.instituteId,
                semId: this.userData.semId,
                uId: this.userData.uId,
                ...dateRangeFilter
              })
            )
          // this.studentsList.sort((a, b) => a.rollNumber - b.rollNumber)
        this.studentsList = arrayOps.sortByProperty(this.studentsList, 'rollNumber')

          // console.log(this.studentsList)
        } else {
          objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedSubject.department,
            courseYear: this.selectedSubject.courseYear,
            subjectId: this.selectedSubject.subjectId,
            uId: this.userData.uId,
            selectedGroupDetails: [],
            ...dateRangeFilter
          };

          if (this.prop_type === 'MultiDivision' && this.selectedSubject.groupName !== 'Common') {
            objToPush.type = 'MultiDivision';
          } else if (this.prop_type === 'Division') {
            objToPush.type = 'Division';
            objToPush.division = this.prop_divisionName;
            // objToPush.batch = this.selectedSubject.batch;
          } else if (this.prop_type === 'Group') {
            objToPush.type = 'Group';
            objToPush.division = '';
            objToPush.batch = this.selectedSubject.batch;
            objToPush.groupId = this.prop_groupInfo.groupId;
            objToPush.groupName = this.prop_groupInfo.groupName;
          }
          if (this.prop_type === 'Batch') {
            objToPush.type = 'Batch';
            objToPush.division = this.selectedSubject.division;
            objToPush.batch = this.selectedSubject.batch;
          }
          // Now objToPush contains the appropriate properties based on prop_type
        }
        const info =
          await this.lectureRepositoryInstance.getAttendanceAnalysisForADivisionForASubject(
            objToPush
          )

        // console.log('info', info);
        this.studentsList = info.attendanceAnalysis
        // this.studentsList.sort((a, b) => a.rollNumber - b.rollNumber)
        this.studentsList = arrayOps.sortByProperty(this.studentsList, 'rollNumber')

        this.allLecturesData = []
        this.allLecturesData = info.lecture

        // this.totalLectures =
        //   this.studentsList.length > 0
        //     ? this.studentsList[0].totalConducted
        //     : 0
      } catch (err) {
        console.log(err)
        this.studentsList = []
        this.totalLectures = 0
      }
      this.totalLectures = 0
      // console.log(this.studentsList)

      // const promises = []

      this.totalStudents = this.studentsList.length
      this.defaulterStudentsCount = 0
      this.nondefaulterStudentsCount = 0

      for (let i = 0; i < this.studentsList.length; i++) {
        if (this.studentsList[i].totalConducted > this.totalLectures) {
          this.totalLectures = this.studentsList[i].totalConducted
        }
        this.studentsList[i].percentage =
          this.studentsList[i].totalConducted > 0
            ? (
              (this.studentsList[i].totalAttended /
                this.studentsList[i].totalConducted) *
              100
            ).toFixed(2)
            : 0

        // promises.push(this.getNameOfStudent(i))
      }

      this.changeLimit()

      // const batchSize = 100

      // const promises = []
      // for (let i = 0; i < this.studentsList.length; i += batchSize) {
      //   promises.push(
      //     this.getNamesOfStudents(
      //       i,
      //       i + batchSize < this.studentsList.length
      //         ? i + batchSize
      //         : this.studentsList.length
      //     )
      //   )
      // }

      // await Promise.allSettled(promises)

      // await Promise.allSettled(promises)

      this.studentsList = this.studentsList.slice()
      // console.log('this.totalLectures', this.totalLectures);
      if (this.totalLectures === 0) {
        try {
          let data = await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject({
            instituteId: this.prop_subject.instituteId,
            semId: this.prop_subject.semId,
            subjectId: this.prop_subject.subjectId
          })
          if (this.prop_type === 'Division' || this.prop_type === 'MultiDivision') {
            const dummyArrForFilter = data.filter((info) => {
              if (info.division === this.prop_divisionName) {
                return info
              }
            })
            data = dummyArrForFilter
            // console.log('dummyArrForFilter', dummyArrForFilter);
          }
          if (this.prop_type === 'Group') {
            const dummyArr = []
            data.map((info) => {
              info.assignedGroupForSubject.map((sub) => {
                if (sub.groupName === this.prop_groupInfo.groupName && sub.groupId === this.prop_groupInfo.groupId) {
                  dummyArr.push(info)
                }
              })
            })
            data = dummyArr
            // console.log('dummyArr', dummyArr);
          }
          if (this.prop_type === 'Batch') {
            const dummyArrForFilter = data.filter((info) => {
              if (info.division === this.prop_divisionName && info.batch === this.selectedSubject.batch) {
                return info
              }
            })
            data = dummyArrForFilter
            // console.log('dummyArrForFilterBatch', dummyArrForFilter);
          }
          // console.log('data', data);
          const idsArr = []
          let fullNames = []
          if (data.length > 0) {
            data.map((user) => {
              idsArr.push(user.uId)
            })
            fullNames = await this.userRepositoryInstance.getFullNameOfUsers({ uIds: idsArr })
            console.log('fullNames', fullNames);
          }
          data.map((user) => {
            fullNames.map((name) => {
              if (name.uId === user.uId) {
                user.fullName = name.fullName
              }
            })
          })
          this.StudentList2 = []
          this.StudentList2 = data
        } catch (error) {
          this.StudentList2 = []
          console.log('error', error);
        }
      }
    },
    async getNamesOfStudents(firstIndex, lastIndex) {
      // console.log(firstIndex, lastIndex)
      try {
        const objToPush = {
          uIds: []
        }

        for (let i = firstIndex; i < lastIndex; i++) {
          if (!this.studentsList[i].name) {
            objToPush.uIds.push(this.studentsList[i].uId)
          }
        }

        const fullNames = await this.userRepositoryInstance.getFullNameOfUsers(
          objToPush
        )

        for (let i = firstIndex; i < lastIndex; i++) {
          const user = fullNames.find(
            (elem) => elem.uId === this.studentsList[i].uId
          )
          this.studentsList[i].name = user ? user.fullName : ''
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getNameOfStudent(studentIndex) {
      // console.log(studentIndex)
      try {
        if (!this.studentsList[studentIndex].name) {
          this.studentsList[studentIndex].name =
            await this.userRepositoryInstance.getFullNameOfUser({
              uId: this.studentsList[studentIndex].uId
            })
        }
      } catch (err) {
        console.log(err)
        this.studentsList[studentIndex].name = ''
      }
    },
    changeLimit() {
      if (this.selectedLimit === '' || this.selectedLimit < 0) {
        this.selectedLimit = 1
      }
      if (this.selectedLimit > 100) {
        this.selectedLimit = 100
      }
      if (isNaN(this.selectedLimit)) {
        this.selectedLimit = 0
      }
      this.defaulterStudentsCount = 0
      this.nondefaulterStudentsCount = 0
      for (let i = 0; i < this.studentsList.length; i++) {
        if (
          parseInt(this.studentsList[i].percentage) <
          parseInt(this.selectedLimit)
        ) {
          this.studentsList[i].status = 'Defaulter'
          this.defaulterStudentsCount++
        } else {
          this.studentsList[i].status = 'Non-Defaulter'
          this.nondefaulterStudentsCount++
        }
      }
      this.defaulterStudentsPercent = (
        (this.defaulterStudentsCount / this.totalStudents) *
        100
      ).toFixed(0)
      this.nondefaulterStudentsPercent = (
        (this.nondefaulterStudentsCount / this.totalStudents) *
        100
      ).toFixed(0)
    },
    addStyleToExcelCell(cell) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFBF80' }
      }
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
      cell.font = {
        name: 'Calibri',
        family: 4,
        size: 12,
        bold: true
      };
    },

    addBorderToExcelCell(cell) {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    },

    async downloadPdf() {
      try {
        let fullNames = []
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');
        const dateForExcel = new Date().toISOString().split('T')
        console.log('dateForExcel', dateForExcel);
        // const firstRow = worksheet.addRow(['', '', '09.00AM - 10.00AM']);
        // this.addStyleToExcelCell(worksheet.getCell(1, 3))
        // console.log('firstRow', firstRow);
        worksheet.getCell(2, 1).value = 'Department'
        worksheet.getCell(3, 1).value = 'CourseYear'
        worksheet.getCell(4, 1).value = 'Subject'
        if (this.prop_type === 'Division') {
          worksheet.getCell(5, 1).value = 'Division'
          worksheet.getCell(5, 2).value = this.prop_divisionName
        }
        if (this.prop_type === 'Group') {
          worksheet.getCell(5, 1).value = 'Group'
          worksheet.getCell(5, 2).value = this.prop_groupInfo.groupName
        }
        if (this.prop_type === 'Batch') {
          worksheet.getCell(5, 1).value = 'Batch'
          worksheet.getCell(5, 2).value = this.selectedSubject.batch
        }
        worksheet.getCell(7, 1).value = 'Name'
        worksheet.getCell(7, 2).value = 'Roll No'
        worksheet.getCell(7, 3).value = 'Date'
        worksheet.getCell(6, 3).value = '09.00AM - 10.00AM'
        worksheet.getCell(2, 2).value = this.selectedSubject.department
        worksheet.getCell(3, 2).value = this.selectedSubject.courseYear
        worksheet.getCell(4, 2).value = this.selectedSubject.subjectName
        // const SecondRow = worksheet.addRow(['Name', 'Roll No', dateForExcel[0]]);
        this.addStyleToExcelCell(worksheet.getCell(2, 1))
        this.addStyleToExcelCell(worksheet.getCell(3, 1))
        this.addStyleToExcelCell(worksheet.getCell(4, 1))
        this.addStyleToExcelCell(worksheet.getCell(5, 1))
        this.addStyleToExcelCell(worksheet.getCell(6, 3))
        this.addStyleToExcelCell(worksheet.getCell(7, 1))
        this.addStyleToExcelCell(worksheet.getCell(7, 2))
        this.addStyleToExcelCell(worksheet.getCell(7, 3))

        this.addBorderToExcelCell(worksheet.getCell(2, 2))
        this.addBorderToExcelCell(worksheet.getCell(3, 2))
        this.addBorderToExcelCell(worksheet.getCell(4, 2))
        this.addBorderToExcelCell(worksheet.getCell(5, 2))
        const columnArray = []
        for (let i = 0; i < worksheet.columnCount; i++) {
          columnArray.push({ width: 20 })
        }
        worksheet.columns = columnArray
        // console.log('SecondRow', SecondRow);
        let data = await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject({
          instituteId: this.prop_subject.instituteId,
          semId: this.prop_subject.semId,
          subjectId: this.prop_subject.subjectId
        })
        if (this.prop_type === 'Division' || this.prop_type === 'MultiDivision') {
          const dummyArrForFilter = data.filter((info) => {
            if (info.division === this.prop_divisionName) {
              return info
            }
          })
          data = dummyArrForFilter
          // console.log('dummyArrForFilter', dummyArrForFilter);
        }
        if (this.prop_type === 'Batch') {
          const dummyArrForFilter = data.filter((info) => {
            if (info.division === this.prop_divisionName && info.batch === this.selectedSubject.batch) {
              return info
            }
          })
          data = dummyArrForFilter
          // console.log('dummyArrForFilter', dummyArrForFilter);
        }
        if (this.prop_type === 'Group') {
          const dummyArr = []
          data.map((info) => {
            info.assignedGroupForSubject.map((sub) => {
              if (sub.groupName === this.prop_groupInfo.groupName && sub.groupId === this.prop_groupInfo.groupId) {
                dummyArr.push(info)
              }
            })
          })
          data = dummyArr
          // console.log('dummyArr', dummyArr);
        }
        const idsArr = []
        if (data.length > 0) {
          data.map((user) => {
            idsArr.push(user.uId)
          })
          fullNames = await this.userRepositoryInstance.getFullNameOfUsers({ uIds: idsArr })
          // console.log('fullNames', fullNames);
        }
        data.map((user, i) => {
          fullNames.map((name) => {
            if (name.uId === user.uId) {
              user.fullName = name.fullName
            }
          })
          const name = user.fullName !== undefined && user.fullName !== '' ? user.fullName : '-'
          const rollNumber = user.rollNumber !== undefined && user.rollNumber !== '' ? user.rollNumber : '-'
          // worksheet.addRow([name, rollNumber]);
          worksheet.getCell(8 + i, 1).value = name
          worksheet.getCell(8 + i, 2).value = rollNumber
        })
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.prop_subject.subjectName}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }
    },

    async downloadTemplate() {
      this.allLecturesData.map((info) => {
        const startTimeForLec = convertUTCDateToLocalDate(new Date(info.startTime))
        const endTimeForLec = convertUTCDateToLocalDate(new Date(info.endTime))
        info.startTime1 = timeStringConverter(startTimeForLec)
        info.endTime1 = timeStringConverter(endTimeForLec)
      })
      this.allLecturesData.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
      this.prop_subject.division = this.prop_divisionName
      const obj = {
        subjectDetails: this.prop_subject,
        studentDetails: this.studentsList,
        lectureDetails: this.allLecturesData,
        uId: this.userData.uId,
        groupName: this.selectedGroupName,
        type: 'Division'
      }
      if (this.prop_type === 'Group') {
        obj.type = 'Group'
      }
      if (this.prop_type === 'Batch') {
        obj.type = 'Batch'
      }
      if (this.selectedInstitute === '6a2b6228-14b8-4d0b-8044-b55fe74240b3' && this.prop_subject.groupName !== 'Common') {
        obj.type = 'MultiDivision'
      }
      // console.log('obj', obj);
      // console.log(this.prop_subject)
      // console.log('obj', obj);
      try {
        const pdfBlob =
          await this.lectureRepositoryInstance.generateAttendanceSheetExcel(
            obj
          )
        const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(pdfBlob2)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Attendance Report.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }
      // axios.post('https://server.inprospecttechnologies.in/inprospect/excel/lecture/attendanceTemplate', objToPush, {
      //   responseType: 'arraybuffer'
      // }).then(res => {
      //   const url = window.URL.createObjectURL(new Blob([res.data], { type: 'vnd.ms-excel;charset=utf-8' }))
      //   const link = document.createElement('a')
      //   link.href = url
      //   link.setAttribute('download', 'Attendance Sheet.xlsx')
      //   document.body.appendChild(link)
      //   link.click()
      // }).catch(err => {
      //   console.log(err)
      //   window.alert('Something went wrong while downloading the excel.')
      // })
    },
    async downloadTemplateForCreateLectureAndAttendance() {
      // console.log('this.prop_subject', this.prop_subject)
      // console.log('this.studentsList', this.studentsList)
      let dummyUidArray = []
      if (this.studentsList.length > 0) {
        dummyUidArray = this.studentsList.map((student) => {
          return student.uId
        })
      }
      // console.log('dummyUidArray', dummyUidArray);
      try {
        let responce = []
        if (dummyUidArray.length > 0) {
          responce = await this.userRepositoryInstance.getUsers({ uIds: dummyUidArray })
        }
        // console.log('responce', responce);
        this.studentData = []
        responce.map((stu, i) => {
          const obj = {}
          obj.index = i + 1
          obj.name = stu.firstName + ' ' + stu.lastName
          obj.collegePRNNo = stu.collegePRNNo ? stu.collegePRNNo : '-'
          obj.rollNo = stu.rollNo ? stu.rollNo : '-'
          obj.attendance = "P"
          if (i === 2 || i === 4 || i === 6 || i === 8 || i === 10) {
            obj.attendance = "A"
          }
          this.studentData.push(obj)
        })
        // console.log('studentData', studentData);
        this.prop_subject.studentArray = this.studentData
        // console.log('this.prop_subject', this.prop_subject);

        const pdfBlob =
          await this.lectureRepositoryInstance.generateExcelForCreateLectureAndAttendance(
            this.prop_subject
          )
        const fileName = `Sample Attendance ${this.prop_subject.subjectName}.xlsx`
        // console.log('pdfBlob', pdfBlob);
        const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(pdfBlob2)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }
    },
    validateTimeFormat(input) {
      const timeFormatPattern = /^\d{2}\.\d{2}-\d{2}\.\d{2}$/;
      // console.log('timeFormatPattern.test(input)', timeFormatPattern.test(input));
      return timeFormatPattern.test(input);
    },
    validateDateFormat(input) {
      const dateFormatPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      // console.log('dateFormatPattern.test(input)', dateFormatPattern.test(input));
      return dateFormatPattern.test(input);
    },
    async handelAddSubjectExcel(res) {
      const arrangeLectureArr = []
      const updateLecArr = []
      // console.log('res#', res);
      this.lectureCreatedSummery = []
      this.lectureNotCreatedArr = []
      this.timeAndDateFormatError = []
      this.alreadyCreatedLecture = []
      this.errorMessageArr = []
      this.studentRollNumberNotPresentArr = []
      this.pageLoader = true
      try {
        let responce = []
        if (res.data.prevLecture.lectures) {
          responce = res.data.students.semesterUsers
        }
        if (res.data.allData.length > 0 && responce.length > 0) {
          res.data.allData.map((lec) => {
            if (this.validateTimeFormat(lec.time) && this.validateDateFormat(lec.date)) {
              if (lec.studentData.length > 0) {
                lec.studentData.map((stu) => {
                  // console.log('stu.rollNo', stu.rollNo, responce[2]);
                  const studentPresentInClass = responce.findIndex((obj) => {
                    console.log(String(obj.rollNumber).trim(), typeof obj.rollNumber.trim(), String(stu.rollNo).trim(), typeof String(stu.rollNo).trim());
                    return obj.rollNumber.trim() === String(stu.rollNo).trim();
                  });

                  console.log('studentPresentInClass', studentPresentInClass);
                  if (studentPresentInClass === -1) {
                    this.studentRollNumberNotPresentArr.push(stu)
                  }
                  if (stu.attendance === null) {
                    this.errorMessageArr.push(stu)
                  }
                })
              }
            } else {
              lec.lineNo = lec.lineNo + ' contains error in date or time format please check'
              this.timeAndDateFormatError.push(lec)
            }
          })
        }
        if (this.errorMessageArr.length === 0 && this.studentRollNumberNotPresentArr.length === 0 && this.timeAndDateFormatError.length === 0) {
          responce.map((stu) => {
            res.data.allData.map((lec) => {
              lec.studentData.map((student) => {
                if (stu.rollNumber === String(student.rollNo)) {
                  student.uId = stu.uId
                }
              })
            })
          })
          // get all lecture for that subject
          let response = []
          if (res.data.prevLecture.lectures) {
            response = res.data.prevLecture.lectures
          }
          this.beyondDateLec = []
          res.data.allData.map(async (data) => {
            if (data.date < new Date().toISOString()) {
              const presentStudentArray = [];
              const absentStudentArray = [];

              data.studentData.map((student) => {
                if (student.attendance === 'P' || student.attendance === 'p') {
                  if (!presentStudentArray.includes(student.uId)) {
                    presentStudentArray.push(student.uId)
                  }
                } else {
                  if (!absentStudentArray.includes(student.uId) && !presentStudentArray.includes(student.uId)) {
                    absentStudentArray.push(student.uId)
                  }
                }
              })
              const ifLectureAlreadyPresent = []
              if (data.department === this.prop_subject.department && data.CourseYear === this.prop_subject.courseYear && data.subjectName === this.prop_subject.subjectName) {
                let [startTime, endTime] = data.time.split('-')
                startTime = this.convertTimeToreqFormat(startTime)
                endTime = this.convertTimeToreqFormat(endTime)
                // console.log('dates1', startTime, endTime);
                let utcDate;
                let day1;
                if (data.time.length === 10) {
                  const [day, month, year] = data.date.split('-')
                  const createDateFormat = year + '-' + month + '-' + day
                  utcDate = new Date(createDateFormat);
                  day1 = utcDate.getDay();
                } else {
                  utcDate = data.date
                  day1 = new Date(utcDate).getDay()
                }
                const dateTime = this.getDateTimeOfLecture(
                  utcDate,
                  startTime
                )
                const dateTime2 = dateTime.toISOString()
                const utcStartTime = this.getUtcTimeOfLecture(
                  utcDate,
                  startTime
                )
                const utcEndTime = this.getUtcTimeOfLecture(
                  utcDate,
                  endTime
                )
                if (response.length > 0) {
                  response.map(async (preLecture) => {
                    const utcEndTime2 = utcEndTime.toISOString()
                    if (preLecture.dateTime === dateTime2 && preLecture.endTime === utcEndTime2) {
                      const objToPush2 = {}
                      // console.log('ForUpdate')
                      ifLectureAlreadyPresent.push(preLecture)
                      objToPush2.lectureId = preLecture.lectureId
                      objToPush2.present = presentStudentArray;
                      objToPush2.absent = absentStudentArray;
                      objToPush2.instituteId = this.prop_subject.instituteId
                      objToPush2.semId = this.prop_subject.semId
                      updateLecArr.push(objToPush2)
                    }
                  })
                }
                if (ifLectureAlreadyPresent.length === 0) {
                  // console.log('Euu')
                  const lectureToArrange = {
                    instituteId: this.prop_subject.instituteId,
                    semId: this.prop_subject.semId,
                    department: this.prop_subject.department,
                    courseYear: this.prop_subject.courseYear,
                    division: this.prop_subject.division,
                    subjectId: this.prop_subject.subjectId,
                    uId: this.userData.uId,
                    roomId: '-',
                    day: this.getDayNameFromDayNumber(day1)
                  }
                  lectureToArrange.present = presentStudentArray;
                  lectureToArrange.absent = absentStudentArray;
                  lectureToArrange.startTime = utcStartTime
                  lectureToArrange.endTime = utcEndTime
                  lectureToArrange.isConducted = true
                  lectureToArrange.dateTime = new Date(dateTime2).toISOString()
                  if (this.prop_type === 'Group') {
                    lectureToArrange.groupForSubject = {
                      groupName: this.prop_groupInfo.groupName,
                      groupId: this.prop_groupInfo.groupId
                    }
                  }
                  if (this.prop_type === 'Batch') {
                    lectureToArrange.batch = this.prop_subject.batch
                  }

                  arrangeLectureArr.push(lectureToArrange)
                }
              } else {
                this.pageLoader = false
                // showStatus('Please try again', 2000, 'error', this)
              }
            } else {
              this.pageLoader = false
              this.beyondDateLec.push(data)
              // showStatus('Excel date beyond today date', 2000, 'error', this)
            }
          })
          // console.log('updateLecArr', updateLecArr);
          // console.log('arrangeLectureArr', arrangeLectureArr);
          this.addedLecInfo = []
          this.updatedLecCount = 0

          if (arrangeLectureArr.length > 0) {
            const data = await this.lectureRepositoryInstance.createMultipleLectures(arrangeLectureArr)
            this.addedLecInfo = data.lecturesCount
          }
          if (updateLecArr.length > 0) {
            const updateLecArrData = await this.lectureRepositoryInstance.updateMultipleLectureDocument({ bulkDataArray: updateLecArr })
            this.updatedLecCount = updateLecArrData.lectureUpdatedCount.nModified
          }
          await this.fetchData()
          setTimeout(() => {
            this.dialogForLectureSummary = true
          }, 1000);
        } else {
          this.validationProp = true
          this.pageLoader = false
        }
      } catch (error) {
        this.pageLoader = false
        console.log('error', error);
      }
      this.pageLoader = false
    },

    convertDate(dateFor) {
      const utcDateString = dateFor;
      const utcDate = new Date(utcDateString);
      // Convert UTC date to IST
      utcDate.setHours(utcDate.getHours() + 5); // Adding 5 hours to convert to IST
      utcDate.setMinutes(utcDate.getMinutes() + 30); // Adding 30 minutes for the IST offset
      // Extract the IST date components
      const year = utcDate.getFullYear();
      const month = utcDate.getMonth() + 1; // Months are zero-indexed
      const day = utcDate.getDate();
      // Format the date as "YYYY-MM-DD"
      const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`;
      return formattedDate;
    },

    handleGoBack() {
      history.back()
    },

    convertDatetimeToIst(dateFor) {
      const utcDateString = dateFor;
      const utcDate = new Date(utcDateString);
      // Create a date format function for IST to display only the time and AM/PM
      const formatTimeIST = (date) => {
        const options = {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
          timeZoneName: "short",
          timeZone: "Asia/Kolkata" // Set the time zone to IST
        };
        return date.toLocaleString("en-US", options);
      };
      // Convert UTC date to IST and format it to display only the time and AM/PM
      const formattedTime = formatTimeIST(utcDate);

      return formattedTime.split('GMT')[0];
    },

    convertTimeToreqFormat(time) {
      const [a, b] = time.split('.')
      return a + '_' + b
    },
    getUtcTimeOfLecture(date, tempTime) {
      const dateObject = new Date(date)
      const timeArray = tempTime
        .split("_")
        .map((time) => parseInt(time));
      const time = new Date(
        dateObject.getFullYear(),
        dateObject.getMonth(),
        dateObject.getDate(),
        ...timeArray
      );
      console.log('time', time);
      return time
    },
    getDayNameFromDayNumber(dayNumber) {
      switch (dayNumber) {
        case 0: return 'Sunday'
        case 1: return 'Monday'
        case 2: return 'Tuesday'
        case 3: return 'Wednesday'
        case 4: return 'Thursday'
        case 5: return 'Friday'
        case 6: return 'Saturday'
      }
    },
    getDateTimeOfLecture(date, startTimeOfLecture) {
      const dateObject = new Date(date)
      const timeArray = startTimeOfLecture
        .split("_")
        .map((time) => parseInt(time));
      return new Date(
        dateObject.getFullYear(),
        dateObject.getMonth(),
        dateObject.getDate(),
        ...timeArray
      );
    },
    uploadExcel() {
      console.log('fhgj', this.file);
      if (this.file) {
        console.log('hello');
        var formData = new FormData()

        formData.append('excel', this.file)
        formData.append('department', this.prop_subject.department)
        formData.append('subject', JSON.stringify(this.prop_subject))
        formData.append('uId', this.userData.uId)
        const url = process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT + '/excel/attendance/parseAttendanceAndLecture'
        console.log('1', url);
        axios
          .post(
            process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT + '/excel/attendance/parseAttendanceAndLecture',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          .then(
            response => {
              // console.log('response1123', response);
              if (response.status === 200) {
                this.handelAddSubjectExcel(response)
              } else {
                console.log("not parse")
              }
            },
            error => {
              console.log(error)
            }
          )
          .then(() => {
            this.file = null;
            this.uploadExcelForSubject = false
          })
      }
    },
    openUploadSubjectDialog() {
      this.uploadExcelForSubject = true
    }
  }
}
</script>

<style src="./activityAttendanceAnalysis.css"></style>
