<template src='./activityTopicFeedbackAction.html'>
</template>

<script>
import smallButton from '../../Components/smallButton'
import inputContainer from '../../Components/inputContainer'

import TopicFeedbackActionRepository from '../../Repository/TopicFeedbackAction'
import TopicFeedbackActionTemplateRepository from '../../Repository/TopicFeedbackActionTemplate'

import { convertDateTime } from '../../Services/Utils/DateTimeFormatter'
import arrayOps from '../../Services/Utils/arrayOps'

export default {
  name: 'activityTopicFeedbackAction',
  components: {
    inputContainer,
    smallButton
  },
  props: [
    'prop_subject',
    'prop_feedback'
  ],
  data () {
    return {
      userData: {},
      showCreateTopicFeedbackAction: false,
      showTopicFeedbackActionLinks: false,
      addingLink: false,
      topicFeedbackActions: [],
      topicFeedbackActionTemplates: [],
      headersForTopicFeedbackActionTemplate: [
        {
          text: 'Name Of Action Template',
          value: 'topicFeedbackActionTemplateName'
        },
        {
          text: 'Actions',
          value: 'actions'
        },
        {
          text: 'Created On',
          value: 'dateOfCreation'
        },
        {
          text: '',
          value: 'action'
        }
      ],
      headersForTopicFeedbackActionResponses: [
        {
          text: 'Action Name',
          value: 'action'
        },
        {
          text: 'Links',
          value: 'links'
        },
        {
          text: '',
          value: 'addMoreLinks'
        }
      ],
      createTopicFeedbackActionStepNumber: 1,
      selectedTopicFeedbackAction: '',
      selectedTopicFeedbackActionTemplate: '',
      tempTopicFeedbackActionName: '',
      selectedActionId: '',
      tempLink: '',
      selection: []
    }
  },
  created () {
    if (this.prop_subject) {
      this.selectedSubject = JSON.parse(JSON.stringify(this.prop_subject))
    } else {
      this.selectedSubject = JSON.parse(JSON.stringify(this.$store.getters['liveData/get_selectedSubject']))
    }
    this.topicFeedbackActionRepositoryInstance = new TopicFeedbackActionRepository(this)
    this.topicFeedbackActionTemplateRepositoryInstance = new TopicFeedbackActionTemplateRepository(this)

    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.topicFeedbackActions = []
      this.topicFeedbackActionTemplates = []

      try {
        this.topicFeedbackActions = await this.topicFeedbackActionRepositoryInstance.getTopicFeedbackActionsOfATopicFeedback(this.prop_feedback)
        console.log(this.topicFeedbackActions)
      } catch (err) {
        console.log(err)
        this.topicFeedbackActions = []
      }

      try {
        this.topicFeedbackActionTemplates = await this.topicFeedbackActionTemplateRepositoryInstance.getTopicFeedbackActionTemplatesOfAnInstitute(this.prop_feedback)
      } catch (err) {
        this.topicFeedbackActionTemplates = []
        console.log(err)
      }

      for (let i = 0; i < this.topicFeedbackActions.length; i++) {
        const topicFeedbackActionTemplate = this.topicFeedbackActionTemplates.find((elem) => elem.topicFeedbackActionTemplateId === this.topicFeedbackActions[i].topicFeedbackActionTemplateId)

        if (topicFeedbackActionTemplate) {
          this.topicFeedbackActions[i].responses = this.topicFeedbackActions[i].responses.map((response) => {
            response.action = topicFeedbackActionTemplate.actions.find((elem) => elem.actionId === response.actionId).action
            return response
          })
        }
      }
      this.topicFeedbackActions = this.topicFeedbackActions.slice()
    },
    startCreatingTopicFeedbackAction () {
      this.createTopicFeedbackActionStepNumber = 1
      this.selectedTopicFeedbackActionTemplate = ''
      this.selection = []
      this.tempTopicFeedbackActionName = ''
      this.showCreateTopicFeedbackAction = true
    },
    closeCreateFeedbackActionDialog () {
      this.createTopicFeedbackActionStepNumber = 1
      this.selectedTopicFeedbackActionTemplate = ''
      this.selection = []
      this.tempTopicFeedbackActionName = ''
      this.showCreateTopicFeedbackAction = false
    },
    selectTemplateAndProceedToNextStep (item) {
      this.selectedTopicFeedbackActionTemplate = item
      this.createTopicFeedbackActionStepNumber = 2
    },
    goBackToChoosingTemplates () {
      this.createTopicFeedbackActionStepNumber = 1
      this.selectedTopicFeedbackActionTemplate = ''
    },
    showTopicFeedbackActionLinksDialog (item) {
      this.selectedTopicFeedbackAction = item
      this.showTopicFeedbackActionLinks = true
    },
    closeTopicFeedbackActionLinksDialog () {
      this.showTopicFeedbackActionLinks = false
      this.selectedTopicFeedbackAction = ''
    },
    uploadLinksToFirebase () {},
    showAddLinkDialog (item) {
      this.showTopicFeedbackActionLinks = false
      this.addingLink = true
      this.selectedActionId = item.actionId
    },
    closeAddLinkDialog () {
      this.tempLink = ''
      this.selectedActionId = ''
      this.addingLink = false
      this.showTopicFeedbackActionLinks = true
    },
    async addLink () {
      const index = this.selectedTopicFeedbackAction.responses.findIndex((elem) => elem.actionId === this.selectedActionId)
      if (index > -1) {
        this.selectedTopicFeedbackAction.responses[index].links.push(this.tempLink)
      }
      this.saveLinks(index)
    },
    async saveLinks (index) {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          topicFeedbackId: this.selectedTopicFeedbackAction.topicFeedbackId,
          topicFeedbackActionId: this.selectedTopicFeedbackAction.topicFeedbackActionId,
          arrayOfLinks: this.selectedTopicFeedbackAction.responses[index].links,
          actionId: this.selectedActionId
        }
        this.topicFeedbackActionRepositoryInstance.submitActionResponseByAFacultyForATopicFeedbackAction(objToPush)
      } catch (err) {
        console.log(err)
        this.selectedTopicFeedbackAction.responses[index].links.pop()
      }
      this.closeAddLinkDialog()
    },
    removeLinkFromArray (item, link) {
      const index = this.selectedTopicFeedbackAction.responses.indexOf(item)
      this.selectedTopicFeedbackAction.responses[index].links = arrayOps.removeFromArray(this.selectedTopicFeedbackAction.responses[index].links, this.selectedTopicFeedbackAction.responses[index].links.indexOf(link))
      this.selectedActionId = this.selectedTopicFeedbackAction.responses[index].actionId
      this.saveLinks(index)
    },
    async createAndLaunchFeedbackAction () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          topicFeedbackId: this.prop_feedback.topicFeedbackId,
          topicFeedbackActionTemplateId: this.selectedTopicFeedbackActionTemplate.topicFeedbackActionTemplateId,
          topicFeedbackActionName: this.tempTopicFeedbackActionName,
          responses: this.selectedTopicFeedbackActionTemplate.actions.map((action) => ({
            actionId: action.actionId,
            uIds: [],
            links: []
          })),
          createdBy: this.userData.uId
        }
        await this.topicFeedbackActionRepositoryInstance.createTopicFeedbackAction(objToPush)
        this.closeCreateFeedbackActionDialog()
        this.fetchData()
      } catch (err) {
        console.log(err)
      }
    },
    convertDateTime (date) {
      return convertDateTime(date)
    }
  }
}
</script>

<style src='./activityTopicFeedbackAction.css'>
</style>
