export default {
  getNBA: '/nba/getNBA',
  getDepartmentNBA: '/nba/getDepartmentNBA',
  getSubjectNBA: '/nba/getSubjectNBA',
  updateNBA: '/nba/updateNBA',
  updateDepartmentNBA: '/nba/updateDepartmentNBA',
  updateSubjectNBA: '/nba/updateSubjectNBA',
  getCOMapping: '/nba/getCOMapping',
  updateCOMapping: '/nba/updateCOPOMapping',
  getCOPSOMapping: '/nba/getCOPSOMapping',
  updateCOPSOMapping: '/nba/updateCOPSOMapping',

  //
  getCos: '/criteria3.1/course_outcome/getBylmsInstituteId',
  getPos: '/criteria3.1/PO_PSO/program_outcome/getByLmsInstituteId',
  getPsos: '/criteria3.1/PO_PSO/program_specific_outcome/getByLmsInstituteId',
  getLevelsOfInstitute: '/criteria3.2/criteria3.2.1/getLevelsByLmsInstituteId',
  getTargetsOfInstitute: '/criteria3.2/criteria3.2.1/getTargetsByLmsInstituteId'
}
