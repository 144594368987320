import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/ExamFormAndHallTicket'

class ExamFormAndHallTicket {
  constructor (context) {
    this.context = context
  }

  async createExamFormAndHallTicket (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createExamFormAndHallTicket, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getExamFormAndHallTicket (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getExamFormAndHallTicket, data)
    console.log("here or what", data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  // async getExam (data) {
  //   this.context.$store.commit('liveData/set_loading', true)
  //   const response = await networkManager.getRequest(ApiNames.getExam, data)
  //   this.context.$store.commit('liveData/set_loading', false)
  //   return response
  // }

  // async deleteExam (data) {
  //   this.context.$store.commit('liveData/set_loading', true)
  //   const response = await networkManager.deleteRequest(ApiNames.deleteExam, data)
  //   this.context.$store.commit('liveData/set_loading', false)
  //   return response.status
  // }
}

export default ExamFormAndHallTicket
