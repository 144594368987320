import { render, staticRenderFns } from "./activityCreateInstituteOrUser.html?vue&type=template&id=3b08a5b8&scoped=true&external"
import script from "./activityCreateInstituteOrUser.vue?vue&type=script&lang=js"
export * from "./activityCreateInstituteOrUser.vue?vue&type=script&lang=js"
import style0 from "./activityCreateInstituteOrUser.css?vue&type=style&index=0&id=3b08a5b8&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b08a5b8",
  null
  
)

export default component.exports