const state = {
  selectedInstitute: '',
  selectedSemester: '',
  selectedEditingSemester: '',
  applicableInstitutes: [],
  applicableSemesters: [],
  config: {},
  instituteName: ''
}

const getters = { // getters for state
  get_selectedInstitute: state => state.selectedInstitute,
  get_selectedSemester: state => state.selectedSemester,
  get_selectedEditingSemester: state => state.selectedEditingSemester,
  get_applicableInstitutes: state => state.applicableInstitutes,
  get_applicableSemesters: state => state.applicableSemesters,
  get_instituteName: state => state.instituteName,
  get_config: state => state.config
}

const mutations = { // mutations for state
  set_selectedInstitute: (state, value) => {
    state.selectedInstitute = value
  },
  set_instituteName: (state, value) => {
    state.instituteName = value
  },
  set_selectedSemester: (state, value) => {
    state.selectedSemester = value
  },
  set_selectedEditingSemester: (state, value) => {
    state.selectedEditingSemester = value
  },
  set_applicableInstitutes: (state, value) => {
    state.applicableInstitutes = value
  },
  set_applicableSemesters: (state, value) => {
    state.applicableSemesters = value
  },
  set_config: (state, value) => {
    state.config = value
  },
  reset (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

const actions = { // actions for state
  set_selectedInstitute: ({ commit, state }, newValue) => {
    commit('set_selectedInstitute', newValue)
    return state.message
  },
  set_instituteName: ({ commit, state }, newValue) => {
    commit('set_instituteName', newValue)
    return state.message
  },
  set_selectedSemester: ({ commit, state }, newValue) => {
    commit('set_selectedSemester', newValue)
    return state.message
  },
  set_selectedEditingSemester: ({ commit, state }, newValue) => {
    commit('set_selectedEditingSemester', newValue)
    return state.message
  },
  set_applicableInstitutes: ({ commit, state }, newValue) => {
    commit('set_applicableInstitutes', newValue)
    return state.message
  },
  set_applicableSemesters: ({ commit, state }, newValue) => {
    commit('set_applicableSemesters', newValue)
    return state.message
  },
  set_config: ({ commit, state }, newValue) => {
    commit('set_config', newValue)
    return state.message
  }
}

export default { // exports the vuex object of 'institutesData' store
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

function initialState () {
  return {
    selectedInstitute: '',
    selectedSemester: '',
    selectedEditingSemester: '',
    applicableInstitutes: [],
    applicableSemesters: [],
    instituteName: '',
    config: {}
  }
}
