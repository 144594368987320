<template src="./activityFeedbackAnalysis.html"> </template>

<script>
import bar from '../../Components/AttendanceAnalysisBarGraph'
import inputContainer from '../../Components/inputContainer'
import TimetableRepository from '../../Repository/timetable'
import SubjectsRepository from '../../Repository/Subjects'
import { subjectTypes } from '../../Constants/Utils/Statics'
import TopicFeedbackQuestion from '../../Repository/TopicFeedbackQuestion'

export default {
  name: 'activityFeedbackAnalysis',
  props: ['prop_data'],
  components: { inputContainer, bar },
  data() {
    return {
      responses: {},
      isMobileView: false,
      userData: null,
      selectedInstitute: '',
      selectedSemester: '',
      subjects: [],
      tempData: [],
      subjectTypes: subjectTypes,
      dataProccessed: true,
      series: [],
      questions: []
    }
  },
  created() {
    console.log('prop_Data', this.prop_data)
    this.$store.commit(
      'liveData/set_selectedActivityName',
      'Feedback Analysis'
    )
    this.topicFeedbackQuestionInstance = new TopicFeedbackQuestion(this)

    this.isMobileView = this.$store.getters['liveData/get_isMobileView']

    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    this.selectedSemester = this.$store.getters[
      'instituteData/get_selectedSemester'
    ]
    this.timetableRepositoryInstance = new TimetableRepository(this)
    this.subjectsRepositoryInstance = new SubjectsRepository(this)
    // Call API to fetch subjects

    const series = [2, 1, 2, 4, 5]
    const filledSeries = series
    const emptySeries = series.map(num => 5 - num)

    const chartSeries = [
      {
        name: 'Rating Given',
        data: filledSeries
      },
      {
        name: 'Rating Not Given',
        data: emptySeries
      }
    ]
    this.series = chartSeries
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const tempAnswers = []
      this.responses = this.prop_data.SelectedFeedback.responses
      this.questions = this.prop_data.SelectedFeedback.questions
      for (const [key, value] of Object.entries(this.responses)) {
        tempAnswers.push(value)
        console.log('key', key);
      }
      for (let i = 0; i < tempAnswers.length; i++) {
        const tempArr = []
        const ans = {
          "1 Star": 0,
          "2 Star": 0,
          "3 Star": 0,
          "4 Star": 0,
          "5 Star": 0
        }
        let totalStarts = 0
        for (const [key, value] of Object.entries(tempAnswers[i])) {
          console.log('key', key);
          totalStarts += parseInt(value)
          tempArr.push(value)
        }
        for (const num of tempArr) {
          ans[`${num} Star`] = ans[`${num} Star`] ? ans[`${num} Star`] + 1 : 1;
        }
        const objToPush = {
          question: this.questions[i],
          avg: totalStarts / tempArr.length,
          data: ans
        }
        this.tempData.push(objToPush)
      }
    }
  }
}
</script>

<style src="./activityFeedbackAnalysis.css">
</style>
