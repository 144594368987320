<template src='./activityCOPOMapping.html'>
</template>
<script>
import NBARepository from '../../Repository/NBA'
import inputContainer from '../../Components/inputContainer'
export default {
  name: 'activityCOPOMapping',
  props: [
    'prop_NBA',
    'prop_subjectNBA',
    'prop_COPOMapping'
  ],
  components: {
    inputContainer
  },
  data () {
    return {
      COPOMapping: {},
      model: 0,
      model2: 0,
      COs: [],
      POs: [],
      tempMap: '',
      colors: [
        'primary',
        'secondary',
        'yellow darken-2',
        'red',
        'orange'
      ]
    }
  },
  created () {
    this.COPOMapping = JSON.parse(JSON.stringify(this.prop_COPOMapping))
    this.COs = this.prop_subjectNBA.subjectCourseOutcomes
    this.POs = this.prop_NBA.instituteProgramOutcomes
    this.tempMap = this.COPOMapping[this.COs[0].coId][this.POs[0].poId]
    this.$store.commit('liveData/set_selectedActivityName', 'CO-PO Mapping')
    this.NBARepositoryInstance = new NBARepository(this)
    console.log(this.prop_NBA, this.prop_subjectNBA)
    this.fetchData()
  },
  methods: {
    async fetchData () {
      const res = await this.NBARepositoryInstance.getCOMapping(
        {
          coId: 'nxkcascnasjkcb'
        }
      )
      console.log(res)
    },
    async updateMapping () {
      const objToPush = {
        coId: this.COs[this.model].coId,
        poId: this.POs[this.model2].poId,
        poMap: this.tempMap
      }
      console.log(objToPush)
      const res = await this.NBARepositoryInstance.updateCOMapping(
        objToPush
      )
      this.COPOMapping[this.COs[this.model].coId][this.POs[this.model2].poId] = this.tempMap
      console.log(res)
    }
  }
}
</script>
<style scoped src='./activityCOPOMapping.css'>
</style>
