import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Syllabus'

class Syllabus {
  constructor (context) {
    this.context = context
  }

  async createSyllabus (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createSyllabus, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getSyllabus (data) {
    this.context.$store.commit('liveData/set_loading', true)
    // console.log("here do what")
    const response = await networkManager.getRequest(ApiNames.getSyllabus, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  // async getExam (data) {
  //   this.context.$store.commit('liveData/set_loading', true)
  //   const response = await networkManager.getRequest(ApiNames.getExam, data)
  //   this.context.$store.commit('liveData/set_loading', false)
  //   return response
  // }

  // async deleteExam (data) {
  //   this.context.$store.commit('liveData/set_loading', true)
  //   const response = await networkManager.deleteRequest(ApiNames.deleteExam, data)
  //   this.context.$store.commit('liveData/set_loading', false)
  //   return response.status
  // }
}

export default Syllabus
