<template src='./activitySelectInstituteNSemester.html'></template>
<script>
import showStatus from '../../NetworkManager/showStatus'
import UserRepository from '../../Repository/User'
import TimetableRepository from '../../Repository/timetable'
import state from '../../Store/Modules/liveData'
import state2 from '../../Store/Modules/instituteData'
import SemesterRepository from '../../Repository/Semester'
import SemesterUserRepository from '../../Repository/SemesterUser'
import GetInstitutesAndSemestersRepository from '../../Repository/GetInstitutesAndSemesters'
import NetworkManager from '../../NetworkManager'
import SubjectsRepository from '../../Repository/Subjects'
import LectureRepository from "../../Repository/lectures";
import InstituteAPIs from '../../ApiNames/Institute'
import auth from '../../Services/Firebase/auth'
import { convertUTCDateToLocalDate, formatToSlashSepearatedDate2 } from '../../Services/Utils/DateTimeFormatter'
export default {
  name: 'activitySelectInstituteNSemester',
  data() {
    return {
      state: state,
      state2: state2,
      isMobileView: false,
      creatingInstitute: false,
      userData: {},
      alignment: 'center',
      justify: 'center',
      selectedInstitute: '',
      cardColumn: 4,
      institutes: [],
      user: '',
      tempFirstName: '',
      tempMiddleName: '',
      tempLastName: '',
      logoutDialog: false,
      brandingName: '',
      brandingLogo: '',
      isLoading: false,
      dataLoader: false,
      userRole: "",
      notificationsCount: 0,
      notificationTableHeaders: [
        {
          text: 'Events',
          value: 'event'
        },
        {
          text: 'Attendance',
          value: 'attendance',
          align: 'end'
        }
      ],
      notificationTableData: [],
      notificationDialog: false,
      today: ''
    }
  },
  async created() {
    if (window.innerWidth < 1200) {
      this.isMobileView = true
    } else {
      this.isMobileView = false
    }
    // this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.lectureRepositoryInstance = new LectureRepository(this);
    this.subjectsRepositoryInstance = new SubjectsRepository(this)
    this.timetableRepositoryInstance = new TimetableRepository(this)
    this.getInstitutesAndSemestersRepositoryInstance = new GetInstitutesAndSemestersRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.userData = this.$store.getters['user/get_userData']
    this.dataLoader = true
    await this.getAllLectures()
    await this.fetchData()
    this.dataLoader = false
  },
  methods: {
    async getAllLectures() {
      try {
        const currentDate = new Date();
        this.today = formatToSlashSepearatedDate2(currentDate.toISOString().split('T')[0])
        const endTime1 = `${currentDate.toISOString().split('T')[0]}T18:30:00.000Z`

        currentDate.setDate(currentDate.getDate() - 1);

        // Set the time to '18:30:00'
        currentDate.setHours(24, 0, 0, 0);
        this.notificationsCount = 0
        const startTime1 = currentDate.toISOString();
        const data = await this.lectureRepositoryInstance.getAllLecturesOfMultipleInstitutesPerDayByUId({
          uId: this.userData.uId,
          fromDate: startTime1,
          toDate: endTime1
        })
        this.notificationsCount = data?.lecture.length || 0
        const lectures = data.lecture || []
        const subjectDetails = data?.subjectDetails?.subjects || []
        const facultyDetails = data?.facultyDetails?.fullNames || []
        const instituteDetails = data?.instituteDetails?.instituteNames || []
        lectures.map((lec) => {
          lec['type'] = 'Lecture'
          lec.startTime = convertUTCDateToLocalDate(new Date(lec.startTime))
          lec.endTime = convertUTCDateToLocalDate(new Date(lec.endTime))
          lec['subjectName'] = subjectDetails.filter(item => item.subjectId === lec.subjectId)[0].subjectName
          lec['instituteDetails'] = instituteDetails.filter(item => item.instituteId === lec.instituteId)[0]
          lec['facultyDetails'] = facultyDetails.filter(item => item.uId === lec.uId)[0]
        })
        this.notificationTableData = lectures
      } catch (error) {
        this.notificationsCount = 0
        console.log('error', error);
      }
    },
    showNotifications() {
      this.notificationDialog = true
    },
    async gotoLectureAttendance(item) {
      this.selectedInstitute = item.instituteDetails.instituteId
      const objToPush = {
        uId: item.uId,
        instituteId: item.instituteDetails.instituteId
      }
      let semesterObject = {}
      this.$store.dispatch('instituteData/set_selectedInstitute', item.instituteDetails.instituteId)
      this.$store.commit("liveData/set_instituteType", item.instituteDetails.tier);
      this.$store.commit("liveData/set_attainmentCalculationType", item.instituteDetails.attainmentCalculationType);
      this.$store.dispatch('instituteData/set_instituteName', item.instituteDetails.instituteName)
      this.$store.dispatch('instituteData/set_config', item.instituteDetails.config)
      this.$store.commit('user/set_userName', item?.facultyDetails?.fullName)

      try {
        semesterObject = await this.semesterUserRepositoryInstance.getCurrentSemesterUserForAUser(objToPush)

        objToPush.semId = semesterObject.semId
        semesterObject.semName = await this.semesterRepositoryInstance.getNameOfASemester(objToPush)
        // this.selectSemester(semesterObject)
      } catch (error) {
        console.error('error', error);
      }
      this.$store.commit('instituteData/set_selectedSemester', semesterObject)
      this.$store.dispatch('user/set_userData', semesterObject)
      try {
        const subjectObjects = await this.timetableRepositoryInstance.getAllocationsOfAFaculty(
          objToPush
        );
        const subjectIds = subjectObjects.map(obj => obj.subjectId)
        const data = await this.subjectsRepositoryInstance.getSubjectsOnlyByIds({ subjectIds })
        data.map((obj) => {
          this.$store.commit('mappings/add_subjectsMapping', obj)
        })
      } catch (error) {
        console.error('error ', error);
      }
      this.$router.push({
        name: "activityTakeAttendance",
        params: {
          prop_lecture: item
        }
      });
    },
    async fetchBrandingDetails(instituteId) {
      try {
        const data = await NetworkManager.getRequest(
          InstituteAPIs.getInstituteBranding,
          { instituteId: instituteId }
        )
        return data
      } catch (error) {
        console.error({ error })
      }
    },
    async fetchData() {
      try {
        this.isLoading = true
        const objToPush = {
          uId: this.userData.uId
        }
        this.institutes = [];
        this.user = await this.userRepositoryInstance.getUserProfile(objToPush)
        this.tempFirstName = this.user.firstName
        this.tempMiddleName = this.user.middleName
        this.tempLastName = this.user.lastName
        this.institutes = await this.getInstitutesAndSemestersRepositoryInstance.getInstitutes(objToPush);
        showStatus('Got Institutes and Semesters Successfully!', 1000, 'success', this)
        for (let i = 0; i < this.institutes.length; i++) {
          const data = await this.fetchBrandingDetails(this.institutes[i].instituteId)
          this.institutes[i].instituteLogoLink = data.instituteLogoLink
          this.institutes[i].instituteFacultyBrandingName = data.instituteFacultyBrandingName
        }
        // if (this.institutes.length === 1) {
        //   this.cardColumn = 6
        //   this.selectInstitute(this.institutes[0])
        // }
        this.isLoading = false
      } catch (err) {
        console.error(err)
      }
    },
    async selectInstitute(institute) {
      this.isLoading = true
      this.selectedInstitute = institute.instituteId
      const instituteName = institute.instituteName
      this.$store.commit("liveData/set_brandingName", institute.instituteBrandingName);
      this.brandingName = institute.instituteBrandingName
      this.brandingLogo = institute.instituteLogoLink

      const tempInstituteData = await NetworkManager.getRequest(
        InstituteAPIs.getInstituteInformation,
        {
          uId: this.userData.uId,
          instituteId: institute.instituteId
        }
      )
      this.$store.commit("liveData/set_instituteType", tempInstituteData.institute.tier);
      if (tempInstituteData.institute.attainmentCalculationType) {
        this.$store.commit("liveData/set_attainmentCalculationType", tempInstituteData.institute.attainmentCalculationType);
      }
      this.$store.dispatch('instituteData/set_selectedInstitute', this.selectedInstitute)
      this.$store.dispatch('instituteData/set_instituteName', instituteName)
      this.$store.dispatch('instituteData/set_config', institute.config)
      const objToPush = {
        uId: this.userData.uId,
        instituteId: this.selectedInstitute
      }
      console.error(objToPush)
      try {
        const semesterObject = await this.semesterUserRepositoryInstance.getCurrentSemesterUserForAUser(objToPush)
          objToPush.semId = semesterObject.semId
          semesterObject.semName = await this.semesterRepositoryInstance.getNameOfASemester(objToPush)
          this.selectSemester(semesterObject)
      } catch (err) {
        showStatus('Institute not available, kindly reach administrator', 3000, 'error', this)
        console.error(err)
        this.getPreviousSemesters()
      }
      // to show selection of institute as highlighted
    },
    async getPreviousSemesters() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        uId: this.userData.uId
      }
      try {
        this.semesters = await this.semesterUserRepositoryInstance.getSemesterUsersForAUser(objToPush)
      } catch (err) {
        console.error(err)
        this.semesters = []
      }

      const semIdToSemNameMapping = {}
      try {
        const semesterNamesArray = await this.semesterRepositoryInstance.getNamesOfSemesters(objToPush)
        semesterNamesArray.forEach((semesterNameObject) => {
          semIdToSemNameMapping[semesterNameObject.semId] = semesterNameObject.semName
        })
      } catch (err) {
        console.error(err)
      }

      this.semesters.forEach((semester) => {
        if (semIdToSemNameMapping[semester.semId]) {
          semester.semName = semIdToSemNameMapping[semester.semId]
        } else {
          semester.semName = ''
        }
      })
      this.semesters = this.semesters.slice()
      this.showPreviousSemestersDialog = true
      this.isLoading = false
    },
    async selectSemester(semesterObject) {
      try {
        this.isLoading = true
        this.$store.dispatch('user/set_userData', semesterObject)
        let userName = '';
        if (this.tempFirstName) userName += this.tempFirstName;
        if (this.tempMiddleName) userName += (userName ? " " : "") + this.tempMiddleName;
        if (this.tempLastName) userName += (userName ? " " : "") + this.tempLastName;
        this.$store.commit('user/set_userName', userName)
        this.$store.commit('instituteData/set_selectedSemester', semesterObject)
        const semesters = await this.semesterRepositoryInstance.getNamesOfSemesters({
          uId: this.userData.uId,
          instituteId: this.selectedInstitute
        });
        console.log('this.selectedInstitute sonalll', this.selectedInstitute);
        if (Array.isArray(semesters) && semesters.length > 0) {
          setTimeout(() => this.$router.push('/activityChangeSemester'), 100)
        } else {
          setTimeout(() => this.$router.push('/activityMySubjects'), 100)
        }
      } catch (e) {
        console.error(e)
        showStatus('An error occured', 3000, 'error', this)
      } finally {
        this.isLoading = false
      }
    },
    gotoMyProfile() {
      this.$router.push('/activityUser')
    },
    logout() {
      this.logoutDialog = false
      this.$store.commit('user/reset')
      this.$store.commit('instituteData/reset')
      const user = window.localStorage.getItem("userKey")
      if (user) {
        window.localStorage.removeItem('userKey')
        this.$router.push('/activityWelcome')
      } else {
        auth.logout(this)
      }
    }
  }
}
</script>
<style scoped src='./activitySelectInstituteNSemester.css'></style>
