<template src='./activityMyTimetable.html'></template>

<script>
import auth from "../../Services/Firebase/auth";
// import axios from 'axios'

import inputContainer from "../../Components/inputContainer";
import {
  timeStringConverter,
  convertFromYYYYMMDDToDate, convertUTCDateToLocalDate,
  convertTime

} from "../../Services/Utils/DateTimeFormatter";
import { subjectTypes, lectureTypes, days } from "../../Constants/Utils/Statics";
import arrayOps from "../../Services/Utils/arrayOps";
import SemesterUserRepository from '../../Repository/SemesterUser'
import UserRepository from "../../Repository/User";
import LectureRepository from "../../Repository/lectures";
import OnlineLectureRepository from "../../Repository/OnlineLecture";
import ZoomMeetingRepository from "../../Repository/zoomMeeting";
import RescheduleRequestsRepository from "../../Repository/RescheduleRequests";
import InfrastructureReferenceRepository from "../../Repository/InfrastructureReference";
import SubjectRepository from "../../Repository/Subjects";
import TopicRepository from "../../Repository/Topic";
import TimetableRepository from "../../Repository/timetable";
import LectureUnblockRepository from "../../Repository/LectureUnblock";
import showStatus from "../../NetworkManager/showStatus";
import apiV3 from "../../NetworkManager/apiV3";
import { spacesUrl } from "../../NetworkManager";
var firestorage = null;
export default {
  name: "activityMyTimetable",
  components: {
    inputContainer
  },
  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        "state_changed",
        (sp) => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.uploadEnd = true;
            this.downloadURLs.push(downloadURL);
            this.uploadCount++;
            if (this.uploadCount === this.files.length) {
              this.uploadVideo();
            } else {
              this.progressUpload = 0;
              this.upload(this.files[this.uploadCount]);
            }
          });
        }
      );
    },
    selectedForCopyFrom(newValue, oldValue) {
      this.handleModelChange(newValue, oldValue);
    }
  },
  data() {
    return {
      allTopicOfDiv: [],
      allSubTopicOfDiv: [],
      selectedTopic: [],
      loadingForTopic: false,
      coveredTopicDialog: false,
      plannedTopicDialog: false,
      selectedLec: {},
      selectedLecIds: [],
      type: 'day',
      flagForReq: false,
      flagForReqNotAccpeted: false,
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        custom: 'Custom'
      },
      isNaralkar: false,
      check: [],
      dummyTwo: [],
      dummyArrayForSort: [],
      studentListForSubject: [],
      loadingForDataMain: true,
      dialogAttendance: false,
      copiedAttendance: [],
      item: '',
      arrayOfSelectedLectureToMerge: [],
      tempCourseYear: '',
      tempIndex: null,
      tempLectureId: '',
      selectedItemDropedArray: [],
      tempSubject: '',
      tempDivision: '',
      tempStartTime: '',
      tempEndTime: '',
      tempBatch: '',
      arrayOfSelectedAttendanceToMerge: [],
      dialogOfMarkAttendance: false,
      showRecording: false,
      recording: false,
      tempLink: "",
      isMobileView: false,
      files: [],
      uploadTask: "",
      uploadEnd: false,
      downloadURLs: [],
      uploadCount: 0,
      fileName: "",
      uploading: false,
      createOnlineLectureLoading: false,
      selectedDate: "",
      userData: null,
      selectedInstitute: "",
      selectedSemester: "",
      timetable: [],
      plannedLectures: [],
      assignedLectures: [],
      selectedForCopyFrom: [],
      conductedLecForCopy: [],
      subjectTypes: subjectTypes,
      headersForCopyAttendance: [

        {
          text: "Time",
          value: "dateTime",
          align: "center"
        },
        {
          text: "Dept_CY",
          value: "dept_CourseYear",
          align: "center"
        },
        {
          text: "Division",
          value: "division",
          align: "center"
        },
        {
          text: "Batch",
          value: "batch",
          align: "center"
        },
        {
          text: "Group",
          value: "group",
          align: "center"
        },
        {
          text: "Subject",
          value: "subjectName",
          align: "center"
        },
        {
          text: "Faculty",
          value: "fullName",
          align: "center"
        }
      ],
      headersForTopic: [
        {
          text: "Topic Name",
          value: "topicName",
          align: "center"
        },
        {
          text: "SubTopics",
          value: "subTopicName"
        }
      ],

      headersForAttendance: [
        {
          text: "",
          value: "checkBox",
          align: "center"
        },
        {
          text: "Course Year",
          value: "courseYear",
          align: "left"
        },
        {
          text: "Subject",
          value: "subject"
          // align: "center"
        },
        {
          text: "Division",
          value: "division"
          // align: "center"
        },
        {
          text: "Batch",
          value: "batch",
          align: "center"
        },
        {
          text: "Time Slot",
          value: "timeSlot",
          align: "left"
        }
      ],
      plannedHeaders: [
        {
          text: "Date",
          value: "dateOnly",
          width: "110px"
        },
        {
          text: "Day",
          value: "day",
          width: "110px"
        },
        {
          text: "Department | Course Year | Division / Groups",
          value: "departmentCourseYearDivision",
          width: "200px"
        },
        {
          text: "Subject",
          value: "subjectName",
          width: "120px"
        },
        // {
        //   text: "Shift",
        //   value: "shift",
        //   width: "100px"
        // },
        {
          text: "Time",
          value: "time",
          width: "160px"
        },
        {
          text: "Faculty Allocated to Subject",
          value: "faculty",
          width: "160px"
        },
        {
          text: "Session no",
          value: "session",
          width: "120px"
        },
        {
          text: "Mode of Conduction",
          value: "modeOfConduction",
          width: "180px"
        },
        {
          text: "Room",
          value: "roomName",
          width: "104px"
        },
        {
          text: "Status",
          value: "status"
        }
      ],
      headers: [
        {
          text: "Status",
          value: "isConducted",
          width: "90px"
          // value: "statusDot",
        },
        {
          text: "Date",
          value: "date",
          width: "110px"
        },
        {
          text: "Day",
          value: "day"
        },
        {
          text: "Department | Course Year | Division / Groups",
          value: "departmentCourseYearDivision",
          width: "200px"
        },
        // {
        //  text: 'Course Year',
        //  value: 'courseYear'
        // },
        // {
        //  text: 'Division',
        //  value: 'division'
        // },
        {
          text: "Subject",
          value: "subjectName",
          width: "120px"
        },
        // {
        //   text: "Shift",
        //   value: "shift",
        //   width: "100px"
        // },
        {
          text: "Time",
          value: "time",
          width: "160px"
        },
        // {
        //   text: "Session no",
        //   value: "session",
        //   width: "120px"
        // },
        // {
        //   text: "Topic",
        //   value: "topic",
        //   width: "120px"
        // },
        {
          text: "Mode of Conduction",
          value: "modeOfConduction",
          width: "180px"
        },
        {
          text: "Room",
          value: "roomName",
          width: "100px"
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
          align: "center"
        }
      ],
      headersForNaralkar: [
        {
          text: "Status",
          value: "isConducted",
          width: "90px"
          // value: "statusDot",
        },
        {
          text: "Date",
          value: "date",
          width: "110px"
        },
        {
          text: "Day",
          value: "day"
        },
        {
          text: "Department | Course Year | Division / Groups",
          value: "departmentCourseYearDivision",
          width: "200px"
        },
        // {
        //  text: 'Course Year',
        //  value: 'courseYear'
        // },
        // {
        //  text: 'Division',
        //  value: 'division'
        // },
        {
          text: "Subject",
          value: "subjectName",
          width: "120px"
        },
        // {
        //   text: "Shift",
        //   value: "shift",
        //   width: "100px"
        // },
        {
          text: "Time",
          value: "time",
          width: "160px"
        },
        // {
        //   text: "Session no",
        //   value: "session",
        //   width: "120px"
        // },
        // {
        //   text: "Topic",
        //   value: "topic",
        //   width: "120px"
        // },
        {
          text: "Mode of Conduction",
          value: "modeOfConduction",
          width: "180px"
        },
        {
          text: "Room",
          value: "roomName",
          width: "100px"
        },
        {
          text: "Attendance Status",
          value: "attenadceStatus",
          width: "180px"
        },
        {
          text: "Topics Mapped",
          value: "topicMapped"
        },
        {
          text: "Topics Coverd",
          value: "topicCoverd"
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
          align: "center"
        }
      ],
      customSelectedStudents: [],
      studentHeader: [
        {
          text: "",
          value: "statusDot"
        },
        {
          text: "Student Names",
          align: "start",
          value: "fullName"
        }
      ],
      subjectsMapping: {},
      reasonForCancellingLecture: "",
      redirectionUrl: "",
      meetingId: "",
      password: "",
      showRescheduleDialog: false,
      availableFacultyList: [],
      availableFacultyNames: [],
      selectedFacultyName: "",
      facultyNamesMapping: {},
      selectedLecture: {},
      showCancellingDialog: false,
      showCreateOnlineLectureDialog: false,
      showCreateOnlineLectureDialogLoading: false,
      showUploadVideo: false,
      loading: false,
      loadingLectures: false,
      showDownloadVideo: false,
      progressUpload: 0,
      lectureTypes: {},
      showCrossConnectionDialog: false,
      isJoiningLecture: false,
      joiningLectureId: "",
      userName: null,
      studentsListPresent: [],
      studentsListAbsent: [],
      checkedNames: "",
      toggle: true,
      usersData: [],
      studentArray: [],
      presentCheckbox: false,
      absentCheckbox: false,
      customCheckbox: false,
      selectedItem: {},
      recordingEnableStudent: "",
      selectedArray: [],
      lecturesArray: [],
      allUserArray: [],
      getUserArray: [],
      customize: '',
      today: '',
      videoFiles: [],
      uploadRecording: false,
      uploadingRecordedVideo: [],
      currentlyUploadingNumber: 0,
      currentUploadProgress: 0,
      uploadedVideoFile: [],
      subjectIds: [],
      customSignedUrl: '',
      nonEmptyFiles: 0,
      uploadCustomVideoDialog: false,
      isAddingRecording: false,
      finaltimeTable: [],
      roomIds: [],
      lectureIds: [],
      studentList: [],
      onlineAttendance: [],
      isNicmar: false,
      facId: [],
      data: [],
      facultiesNameWithUid: [],
      menu: false,
      isEndTimeGreater: false,
      isStartTimeGreater: false,
      copyAttendanceDialog: false,
      compareEndTime: '',
      calenderDate: [],
      lecToCopy: [],
      actionsForNicmar: [
        { title: 'Mark Attendance', index: '0' },
        { title: 'Copy Attendance', index: '1' }
      ],
      todayDate: "",
      startDateForCalender: "",
      endDateForCalender: "",
      selectedForCopyTo: [],
      isGetRecording: false,
      actions: [
        { title: 'Mark Attendance', index: '0' },
        // { title: 'Copy Attendance', index: '1' },
        { title: 'Cancel Lecture', index: '1' },
        // { title: '-', index: '3' },
        { title: 'Get Recording', index: '2' },
        { title: 'Manage Recording', index: '3' },
        { title: 'Upload Recording', index: '4' },
        { title: 'Download Recording', index: '5' }
      ],
      accessForInstitute: {
        markAttendance: false,
        arrangeLecture: true,
        cancelLecture: false,
        copyAttendance: false,
        createLecture: false,
        downloadLectureRecording: false,
        manageRecording: false,
        uploadLectureRecording: false
      }
    };
  },
  async created() {
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    const firebase = auth.getFirebaseObject();
    firestorage = firebase.storage();
    this.$store.commit("liveData/set_selectedActivityName", "Schedule");
    this.subjectsMapping = this.$store.getters["mappings/get_subjectsMapping"];
    const date = new Date();
    this.selectedDate =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    this.userData = this.$store.getters["user/get_userData"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    if (this.selectedInstitute === "86db902d-4346-4ef5-ad36-e1416d3306ae") {
      this.isNicmar = true
    }
    if (this.selectedInstitute === "a2459a84-980b-4f07-82da-7f1f45616c39" || this.selectedInstitute === "f977ee47-4b0f-4af5-b68c-b2685236e4d9") {
      this.isNaralkar = true
    }
    this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];
    this.userRepositoryInstance = new UserRepository(this);
    this.TopicRepositoryInstance = new TopicRepository(this);
    this.lectureUnblockRepositoryInstance = new LectureUnblockRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.lectureRepositoryInstance = new LectureRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.onlineLectureRepositoryInstance = new OnlineLectureRepository(this);
    this.zoomMeetingInstance = new ZoomMeetingRepository(this);
    this.timetableRepositoryInstance = new TimetableRepository(this);
    this.rescheduleRequestsRepositoryInstance = new RescheduleRequestsRepository(this);
    this.infrastructureReferenceRepositoryInstance = new InfrastructureReferenceRepository(this);
    this.lectureTypes = lectureTypes;
    this.selectDay();
    this.fetchData();
    this.getFireBaseDataForAccess()
  },
  methods: {
    async addTopicToLec(type) {
      const ids = []
      if (this.selectedTopic.length > 0) {
        this.selectedTopic.map((item) => {
          ids.push(item.id)
        })
      }
      let arr = []
      if (type === 'planned') {
        arr = [{
          lectureId: this.selectedLec.lectureId,
          instituteId: this.selectedLec.instituteId,
          semId: this.selectedLec.semId,
          plannedTopicIds: ids
        }]
      } else {
        arr = [{
          lectureId: this.selectedLec.lectureId,
          instituteId: this.selectedLec.instituteId,
          semId: this.selectedLec.semId,
          coveredTopicIds: ids
        }]
      }
      console.log('arr', arr);
      // function to update lec in bulk
      this.updateCopyLectureAttendance(arr)
      // this.plannedTopicDialog = false
      // this.coveredTopicDialog = false
    },
    async getTopicForSubAndDiv(lec) {
      this.selectedLec = lec
      this.allSubTopicOfDiv = []
      // const obj = {
      //   instituteId: lec.instituteId,
      //   semId: lec.semId,
      //   subjectId: lec.subjectId,
      //   department: lec.department,
      //   courseYear: lec.courseYear,
      //   division: lec.division
      // }
      try {
        // this.allTopicOfDiv = await this.TopicRepositoryInstance.getTopicsOfADivisionOfASubject(obj)
        this.allTopicOfDiv = await this.TopicRepositoryInstance.getTopicsOfASubjectOfAFaculty(
          this.selectedLec
        )
        let typeOfLec;
        if (this.selectedLec.division && this.selectedLec.division !== '') {
          if (this.selectedLec.batch && this.selectedLec.batch !== '') {
            typeOfLec = 'Batch'
          } else {
            typeOfLec = 'Division'
          }
        } else if (this.selectedLec.groupForSubject && this.selectedLec.groupForSubject.length > 0) {
          typeOfLec = 'Group'
        }
        // console.log('this.topics', this.topics);
        if (typeOfLec === "Division") {
          let onlyDivisionMaterial = []
          if (this.allTopicOfDiv.length > 0) {
            onlyDivisionMaterial = this.allTopicOfDiv.filter((data) => data.division === this.selectedLec.division && (data.batch === undefined || data.batch === ''))
            this.allTopicOfDiv = onlyDivisionMaterial
          }
        }
        if (typeOfLec === "Batch") {
          let onlyDivisionMaterial = []
          if (this.allTopicOfDiv.length > 0) {
            onlyDivisionMaterial = this.allTopicOfDiv.filter((data) => data.division === this.selectedLec.division && data.batch === this.selectedLec.batch)
            this.allTopicOfDiv = onlyDivisionMaterial
          }
        }
        if (typeOfLec === "Group") {
          let onlyGroupMaterial = []
          if (this.allTopicOfDiv.length > 0) {
            onlyGroupMaterial = this.allTopicOfDiv.filter((data) => data.assignedGroupForSubject?.groupId === this.selectedLec.groupForSubject[0].groupId)
            this.allTopicOfDiv = onlyGroupMaterial
          }
        }
        this.allSubTopicOfDiv = []
        if (this.allTopicOfDiv.length > 0) {
          this.allTopicOfDiv.map((item) => {
            if (Array.isArray(item.subTopics) && item.subTopics.length > 0) {
              item.subTopics.map((sub) => {
                this.allSubTopicOfDiv.push(
                  {
                    id: sub.subTopicId,
                    subTopicName: sub.subTopicName,
                    topicId: item.topicId,
                    topicName: item.topicName
                  }
                )
              })
            } else {
              this.allSubTopicOfDiv.push(
                {
                  id: item.topicId,
                  topicId: item.topicId,
                  subTopicName: '-',
                  topicName: item.topicName
                }
              )
            }
          })
        }
      } catch (error) {
        console.error(error)
      }
    },
    async addPlannedTopic(lec) {
      this.loadingForTopic = true
      this.plannedTopicDialog = true
      await this.getTopicForSubAndDiv(lec)
      this.selectedTopic = []
      // let arrForSubtopics = []
      if (this.allSubTopicOfDiv.length > 0) {
        if (lec.plannedTopicIds && lec.plannedTopicIds.length > 0) {
          lec.plannedTopicIds.map((plannedIds) => {
            this.allSubTopicOfDiv.map((topic) => {
              console.log('plannedIds === topic.topicId', plannedIds === topic.topicId);
              console.log('plannedIds === topic.id', plannedIds === topic.id);
              if (plannedIds === topic.topicId || plannedIds === topic.id) {
                this.selectedTopic.push(topic)
              }
            })
          })
          console.log('this.selectedTopic', this.selectedTopic);
          this.selectedTopic = [...new Set(this.selectedTopic)]
        }
      }
      this.loadingForTopic = false
    },
    async addCoveredTopic(lec) {
      this.loadingForTopic = true
      this.coveredTopicDialog = true
      await this.getTopicForSubAndDiv(lec)
      this.selectedTopic = []
      if (this.allSubTopicOfDiv.length > 0) {
        if (lec.coveredTopicIds && lec.coveredTopicIds.length > 0) {
          lec.coveredTopicIds.map((coveredId) => {
            this.allSubTopicOfDiv.map((topic) => {
              if (coveredId === topic.topicId || coveredId === topic.id) {
                this.selectedTopic.push(topic)
              }
            })
          })
        }
        this.selectedTopic = [...new Set(this.selectedTopic)]
      }
      this.loadingForTopic = false
    },
    async createReqForAttendanceUnblock() {
      const objToPush = {
        instituteId: this.selectedLec.instituteId,
        semId: this.selectedLec.semId,
        department: this.selectedLec.department,
        courseYear: this.selectedLec.courseYear,
        subjectId: this.selectedLec.subjectId,
        uId: this.selectedLec.uId,
        division: this.selectedLec.division,
        lectureIds: this.selectedLecIds,
        requestTime: (new Date()).toISOString()
      }
      try {
        const daata = await this.lectureUnblockRepositoryInstance.createSingleRequestForLectureUnlock(objToPush)
        console.log('daata', daata);
      } catch (error) {
        console.error(error)
      }
    },
    async getLastLec(obj) {
      let data = []
      try {
        const value = await this.lectureRepositoryInstance.getPreviousLecForFacForDivForSub(obj)
        data = value.result
      } catch (error) {
        console.error(error)
      }
      return data
    },
    async getReqForSubjectForFac(obj) {
      let data = []
      try {
        const value = await this.lectureUnblockRepositoryInstance.getAllReqForSubjectForFacultyForDivision(obj)
        console.log('value', value);
        data = value.result
      } catch (error) {
        console.error(error)
      }
      return data
    },

    tableClick() {
      // console.log('dfghj')
    },
    openCopyDialog() {
      this.copyAttendanceDialog = true
      this.selectedForCopyFrom = []
      this.selectedForCopyTo = []
      this.conductedLecForCopy = []
      if (this.assignedLectures.length > 0) {
        this.assignedLectures.map((info) => {
          if (info.isConducted === true) {
            this.conductedLecForCopy.push(info)
          }
        })
      }
    },
    async getFireBaseDataForAccess() {
      try {
        const data = await auth.getInstituteAccessDocument(this.selectedInstitute)
        if (data.length > 0) {
          this.accessForInstitute = {
            markAttendance: data[0].facultyAccessControl.schedule.markAttendance ? data[0].facultyAccessControl.schedule.markAttendance : false,
            arrangeLecture: data[0].facultyAccessControl.schedule.arrangeLecture !== undefined ? data[0].facultyAccessControl.schedule.arrangeLecture : true,
            cancelLecture: data[0].facultyAccessControl.schedule.cancelLecture ? data[0].facultyAccessControl.schedule.cancelLecture : false,
            copyAttendance: data[0].facultyAccessControl.schedule.copyAttendance ? data[0].facultyAccessControl.schedule.copyAttendance : false,
            createLecture: data[0].facultyAccessControl.schedule.createLecture ? data[0].facultyAccessControl.schedule.createLecture : false,
            downloadLectureRecording: data[0].facultyAccessControl.schedule.downloadLectureRecording ? data[0].facultyAccessControl.schedule.downloadLectureRecording : false,
            manageRecording: data[0].facultyAccessControl.schedule.manageRecording ? data[0].facultyAccessControl.schedule.manageRecording : false,
            uploadLectureRecording: data[0].facultyAccessControl.schedule.uploadLectureRecording ? data[0].facultyAccessControl.schedule.uploadLectureRecording : false
          }
          this.actions = []
          if (this.accessForInstitute.markAttendance === true) {
            this.actions.push({ title: 'Mark Attendance', index: '0' })
          }
          // if (this.accessForInstitute.copyAttendance === true) {
          //   this.actions.push({ title: 'Copy Attendance', index: '1' })
          // }
          if (this.accessForInstitute.cancelLecture === true) {
            this.actions.push({ title: 'Cancel Lecture', index: '1' })
          }
          if (this.accessForInstitute.downloadLectureRecording === true) {
            this.actions.push({ title: 'Get Recording', index: '2' })
          }
          if (this.accessForInstitute.manageRecording === true) {
            this.actions.push({ title: 'Manage Recording', index: '3' })
          }
          if (this.accessForInstitute.uploadLectureRecording === true) {
            this.actions.push({ title: 'Upload Recording', index: '4' })
          }
          if (this.accessForInstitute.downloadLectureRecording === true) {
            this.actions.push({ title: 'Download Recording', index: '5' })
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    facName(id) {
      const name = []
      let arr = []
      this.data.subjectDetails.subjects.map((sub) => {
        if (sub.subjectId === id) {
          arr = sub.faculties
        }
      })
      // this.facultiesNameWithUid
      this.facultiesNameWithUid.map((fac) => {
        if (arr.length > 0) {
          arr.map((id1) => {
            if (id1 === fac.uId) {
              name.push(fac.fullName)
            }
          })
        }
      })
      return name
    },
    swapDate() {
      this.menu = false;
      this.newCalenderDate = []

      if (this.calenderDate.length === 1) {
        this.calenderDate[1] = this.calenderDate[0]
        this.getLectureForAssignedSubject('customRange');
      }

      if (this.calenderDate[0] === this.calenderDate[1]) {
        this.getLectureForAssignedSubject('customRange');
      }

      if (this.calenderDate[0] > this.calenderDate[1]) {
        const temp = this.calenderDate[0];
        this.calenderDate[0] = this.calenderDate[1];
        this.calenderDate[1] = temp;

        this.getLectureForAssignedSubject('customRange');
      } else if (this.calenderDate[0] < this.calenderDate[1]) {
        this.getLectureForAssignedSubject('customRange');
      }
    },
    prev() {
      if (this.type === 'day') {
        this.selectPreviousDay();
      } else if (this.type === 'week') {
        this.selectPreviousWeek();
      } else if (this.type === 'month') {
        this.getPrevMonthRange();
      } else if (this.type === 'custom') {
        this.selectCustomPrevious();
      }
    },
    next() {
      if (this.type === 'day') {
        this.selectNextDay();
      } else if (this.type === 'week') {
        this.selectNextWeek();
      } else if (this.type === 'month') {
        this.getNextMonthRange();
      } else if (this.type === 'custom') {
        this.selectCustomNext();
      }
    },
    selectPreviousDay() {
      const currentDate = new Date(this.todayDate);
      currentDate.setDate(currentDate.getDate() - 1);
      this.todayDate = this.formatDate(currentDate);
      this.newFormattedDate = new Date(this.todayDate).toLocaleDateString('en-GB');
      this.getLectureForAssignedSubject('day');
    },
    selectNextDay() {
      const currentDate = new Date(this.todayDate);
      currentDate.setDate(currentDate.getDate() + 1);
      this.todayDate = this.formatDate(currentDate);
      this.newFormattedDate = new Date(this.todayDate).toLocaleDateString('en-GB');
      this.getLectureForAssignedSubject('day');
    },
    selectCustomPrevious() {
      const currentDate = new Date(this.calenderDate[0]);
      currentDate.setDate(currentDate.getDate() - 1);
      this.dayBeforeCustom = this.formatDate(currentDate);
      this.$set(this.calenderDate, 0, this.dayBeforeCustom);
      this.getLectureForAssignedSubject('customRange');
    },
    selectCustomNext() {
      const currentDate = new Date(this.calenderDate[1]);
      currentDate.setDate(currentDate.getDate() + 1);
      this.dayAfterCustom = this.formatDate(currentDate);
      this.$set(this.calenderDate, 1, this.dayAfterCustom);
      this.getLectureForAssignedSubject('customRange');
    },
    saveCopyAttendance() {
      this.copyAttendanceDialog = false
      const arr = []
      if (this.selectedForCopyTo.length > 0) {
        this.selectedForCopyTo.map((lec) => {
          const obj = {}
          obj.present = this.selectedForCopyFrom[0].present
          obj.absent = this.selectedForCopyFrom[0].absent
          obj.isConducted = true
          obj.lectureId = lec.lectureId
          obj.semId = lec.semId
          obj.instituteId = lec.instituteId
          arr.push(obj)
        })
        // console.log('this.selectedForCopyTo', this.selectedForCopyTo);
        this.updateCopyLectureAttendance(arr)
      }
    },
    async updateCopyLectureAttendance(arr) {
      try {
        const data = await this.lectureRepositoryInstance.updateMultipleLectureDocument({
          bulkDataArray: arr
        })
        await this.fetchData()
        console.log('data', data);
      } catch (err) {
        console.error('err', err);
      }
    },
    handleModelChange() {
      this.lecToCopy = []
      if (this.selectedForCopyFrom.length > 0) {
        this.selectedForCopyFrom[0]['type'] = 'Division'
        if (this.selectedForCopyFrom[0].groupForSubject && this.selectedForCopyFrom[0].groupForSubject.length > 0) {
          this.selectedForCopyFrom[0]['type'] = 'Group'
        }
        if (this.selectedForCopyFrom[0].batch !== undefined && this.selectedForCopyFrom[0].batch !== '') {
          this.selectedForCopyFrom[0]['type'] = 'Batch'
        }
        this.assignedLectures.map((lec) => {
          lec['type'] = 'Division'
          if (lec.groupForSubject && lec.groupForSubject.length > 0) {
            lec['type'] = 'Group'
          }
          if (lec.batch && lec.batch !== '') {
            lec['type'] = 'Batch'
          }
          console.log('lec.type', lec.type);
          if (lec.lectureId !== this.selectedForCopyFrom[0].lectureId && lec.type === this.selectedForCopyFrom[0]['type']) {
            if (lec.department === this.selectedForCopyFrom[0].department && lec.courseYear === this.selectedForCopyFrom[0].courseYear) {
              if (lec['type'] === 'Division') {
                if (this.selectedForCopyFrom[0].division === lec.division) {
                  this.lecToCopy.push(lec)
                  console.log('division')
                }
              }

              if (lec['type'] === 'Batch') {
                if (this.selectedForCopyFrom[0].batch === lec.batch && this.selectedForCopyFrom[0].division === lec.division) {
                  console.log('batch')
                  this.lecToCopy.push(lec)
                }
              }

              if (lec['type'] === 'Group') {
                if (this.selectedForCopyFrom[0]?.groupForSubject[0]?.groupName === lec?.groupForSubject[0]?.groupName) {
                  this.lecToCopy.push(lec)
                  // console.log('group')
                }
              }
            }
          }
        })
      }
    },
    convertUTCtoLocalTimeWithAMPM(utcDateString) {
      // console.log('utcDateString', utcDateString);
      const utcDate = new Date(utcDateString);
      // console.log('utcDate', utcDate);
      const hours = utcDate.getHours();
      const minutes = utcDate.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      // console.log('formattedHours', formattedHours);
      const result = formattedHours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;
      // console.log('results', result);
      return result;
    },
    getDayNameByDate(dateString, type) {
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      let dayOfWeek;
      if (type === 1) {
        const dateFor = dateString.split('T')[0]
        const date = new Date(dateFor);
        dayOfWeek = date.getDay();
      }
      if (type === 2) {
        const date = new Date(dateString);
        dayOfWeek = date.getDay();
      }
      return daysOfWeek[dayOfWeek];
    },
    async getLectureForAssignedSubject(item) {
      this.facId = []
      try {
        let obj = {}
        if (item === 'customRange') {
          if (this.calenderDate) {
            const startDate = new Date(this.calenderDate[0]);
            startDate.setDate(startDate.getDate() - 1);
            const fromDate = `${this.formatDate(startDate)}T18:30:00`;

            const adjustedEndDate = new Date(this.calenderDate[1]);
            adjustedEndDate.setDate(adjustedEndDate.getDate());
            const toDate = `${this.formatDate(adjustedEndDate)}T18:29:59`;

            obj = {
              subjectIds: Object.keys(this.subjectsMapping),
              fromDate: fromDate,
              toDate: toDate,
              uId: this.userData.uId,
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId
            };
          }
        }
        if (item === 'prevWeek') {
          if (this.startDateForCalender) {
            const startDate = new Date(this.startDateForCalender);
            startDate.setDate(startDate.getDate() - 1);
            const fromDate = `${this.formatDate(startDate)}T18:30:00`;

            const adjustedEndDate = new Date(this.endDateForCalender);
            adjustedEndDate.setDate(adjustedEndDate.getDate());
            const toDate = `${this.formatDate(adjustedEndDate)}T18:29:59`;

            obj = {
              subjectIds: Object.keys(this.subjectsMapping),
              fromDate: fromDate,
              toDate: toDate,
              uId: this.userData.uId,
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId
            };
          }
        }
        if (item === 'nextWeek') {
          if (this.startDateForCalender) {
            const startDate = new Date(this.startDateForCalender);
            startDate.setDate(startDate.getDate() - 1);
            const fromDate = `${this.formatDate(startDate)}T18:30:00`;

            const adjustedEndDate = new Date(this.endDateForCalender);
            adjustedEndDate.setDate(adjustedEndDate.getDate());
            const toDate = `${this.formatDate(adjustedEndDate)}T18:29:59`;

            obj = {
              subjectIds: Object.keys(this.subjectsMapping),
              fromDate: fromDate,
              toDate: toDate,
              uId: this.userData.uId,
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId
            };
          }
        }
        if (item === 'prevMonth') {
          if (this.startDateForCalender) {
            const startDate = new Date(this.startDateForCalender);
            startDate.setDate(startDate.getDate() - 1);
            const fromDate = `${this.formatDate(startDate)}T18:30:00`;

            const adjustedEndDate = new Date(this.endDateForCalender);
            adjustedEndDate.setDate(adjustedEndDate.getDate());
            const toDate = `${this.formatDate(adjustedEndDate)}T18:29:59`;

            obj = {
              subjectIds: Object.keys(this.subjectsMapping),
              fromDate: fromDate,
              toDate: toDate,
              uId: this.userData.uId,
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId
            };
          }
        }
        if (item === 'nextMonth') {
          if (this.startDateForCalender) {
            const startDate = new Date(this.startDateForCalender);
            startDate.setDate(startDate.getDate() - 1);
            const fromDate = `${this.formatDate(startDate)}T18:30:00`;
            obj = {
              subjectIds: Object.keys(this.subjectsMapping),
              fromDate: fromDate,
              toDate: `${this.endDateForCalender}T18:29:59`,
              uId: this.userData.uId,
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId
            };
          }
        }
        if (item === 'day') {
          obj = {
            subjectIds: Object.keys(this.subjectsMapping),
            fromDate: this.getStartUtc(),
            uId: this.userData.uId,
            toDate: this.getEndUtc(),
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId
          }
        } else if (item === 'week') {
          obj = {
            subjectIds: Object.keys(this.subjectsMapping),
            fromDate: this.getWeekStartDateUtc(),
            toDate: this.getWeekEndDateUtc(),
            uId: this.userData.uId,
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId
          }
        } else if (item === 'month') {
          obj = {
            subjectIds: Object.keys(this.subjectsMapping),
            fromDate: this.getMonthStartDateUtc(),
            toDate: this.getMonthEndDateUtc(),
            uId: this.userData.uId,
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId
          }
        }
        this.plannedLectures = []

        const data = await this.lectureRepositoryInstance.getLecturesForDateForAssignedSubjects(obj)
        this.data = data
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        if (data.lecture && data.lecture.length > 0) {
          data.lecture.map(async (info) => {
            info.subjectFaculty = []
            info.fullName = ''
            info.roomName = ''
            info.subjectName = ''

            if (data.roomDetails.roomName.length > 0) {
              data.roomDetails.roomName.map((room) => {
                if (room.roomId === info.roomId) {
                  info.roomName = room.roomName
                }
              })
            }
            if (data.subjectDetails.subjects.length > 0) {
              data.subjectDetails.subjects.map((sub) => {
                if (sub.subjectId === info.subjectId) {
                  info.subjectName = sub.subjectName
                  info.subjectType = sub.subjectType
                }
              })
            }
            this.facId = []
            for (let i = 0; i < data.subjectDetails.subjects.length; i++) {
              this.facId.push(...data.subjectDetails.subjects[i].faculties);
            }
            this.facultiesNameWithUid = []
            this.facultiesNameWithUid = await this.userRepositoryInstance.getFullNameOfUsers({
              uIds: this.facId
            });
            info.subjectFaculty = this.facName(info.subjectId)
            let arrForPlan = []
            arrForPlan = data.lecture
              .map(plannedLectures => {
                const dateTime = new Date(plannedLectures.dateTime);
                const dateOnly = dateTime.toISOString().split('T')[0];
                const modeOfConduction = plannedLectures.isOnline ? 'Online' : 'Offline';
                const tempDay = days[plannedLectures.day]
                return {
                  ...plannedLectures,
                  dateOnly: dateOnly,
                  modeOfConduction: modeOfConduction,
                  day: tempDay
                };
              });
            this.plannedLectures = arrForPlan
          })
        }
        let lecturesArray = [];
        lecturesArray = await this.lectureRepositoryInstance.getAllLecturesOfMultipleInstitutesPerDayByUId(obj);
        if (lecturesArray.lecture && lecturesArray.lecture.length > 0) {
          lecturesArray.lecture.map((info, i) => {
            info.afterUtc = false
            // if (new Date("2022-10-31T00:00:00.000Z").toISOString() < t.dateTime) {
            if (!(info.startTime.split('_').length > 1)) {
              info.afterUtc = true
              info.start = info.startTime
              info.end = info.endTime
              info.startTime = convertUTCDateToLocalDate(new Date(info.startTime))
              info.endTime = convertUTCDateToLocalDate(new Date(info.endTime))

              info.currentTimeUTC = new Date().toISOString();
              console.log('info.currentTimeUTC', info.currentTimeUTC);
              console.log('info.start', info.start);
              info['isEndTimeGreater'] = false
              if (info.end > info.currentTimeUTC && info.start < info.currentTimeUTC) {
                info['isEndTimeGreater'] = true
              }
              console.log('info.isEndTimeGreater', info.isEndTimeGreater, i);
              // info.isStartTimeGreater = info.start < info.currentTimeUTC;
            }
            // }
            this.onlineAttendance.map((res) => {
              if (res.lectureId === info.lectureId) {
                info.OnlineAttendanceMarked = true
              }
            })

            info.fullName = ''
            info.roomName = ''
            info.subjectName = ''
            if (lecturesArray.facultyDetails.fullNames.length > 0) {
              lecturesArray.facultyDetails.fullNames.map((fac) => {
                if (fac.uId === info.uId) {
                  info.fullName = fac.fullName
                }
              })
            }
            if (lecturesArray.roomDetails.roomName.length > 0) {
              lecturesArray.roomDetails.roomName.map((room) => {
                if (room.roomId === info.roomId) {
                  info.roomName = room.roomName
                }
              })
            }
            if (lecturesArray.subjectDetails.subjects.length > 0) {
              lecturesArray.subjectDetails.subjects.map((sub) => {
                if (sub.subjectId === info.subjectId) {
                  info.subjectName = sub.subjectName
                  info.subjectType = sub.subjectType
                }
              })
            }
          })
        }

        this.assignedLectures = []
        this.assignedLectures = lecturesArray.lecture.map(assignedLectures => {
          const currentTimeUTC = new Date().toISOString();
          assignedLectures['isEndTimeGreater'] = false
          if (assignedLectures.endTime > currentTimeUTC && assignedLectures.startTime < currentTimeUTC) {
            assignedLectures['isEndTimeGreater'] = true
          }
          const dateTime = new Date(assignedLectures.dateTime);
          const dateOnly = dateTime.toISOString().split('T')[0];
          const modeOfConduction = assignedLectures.isOnline ? 'Online' : 'Offline';
          return {
            ...assignedLectures,
            dateOnly: dateOnly,
            modeOfConduction: modeOfConduction
          };
        });
        console.log('this.assignedLectures', this.assignedLectures);
        // START COMMENT
        try {
          this.onlineAttendance = []
          const lectureIdsArray = []
          lecturesArray.lecture.map((t) => {
            lectureIdsArray.push(t.lectureId)
          })
          const result = await apiV3.postRequest("/meeting/get-attendance", {
            lectureIds: lectureIdsArray
          });
          this.onlineAttendance = result.totalDuration
        } catch (e) {
          console.log('e', e);
        }

        try {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            lectureIds: this.lectureIds
          };
          const lectureOnlineStatus = await this.onlineLectureRepositoryInstance.getIsOngoingForALectures(
            objToPush
          );
          if (lectureOnlineStatus !== undefined) {
            lectureOnlineStatus.map((lecture) => {
              lecturesArray.lecture.map((lecture1) => {
                if (lecture.lectureId === lecture1.lectureId) {
                  lecture1.isOnline = lecture.isOnline;
                }
              })
            })
          } else {
            lecturesArray.lecture.map((lecture) => {
              lecture.isOnline = false
            })
          }
        } catch (err) {
          this.timetable = []
          this.loadingLectures = false
          this.loadingForDataMain = false
          console.log(err);
        }
      } catch (err) {
        console.log(err)
      }
    },
    selectDay() {
      this.todayDate = "";
      this.type = 'day';
      const indianDate = this.getIndianDate();
      this.todayDate = indianDate;
      this.newFormattedDate = new Date(indianDate).toLocaleDateString('en-GB');

      const startUtc = this.getStartUtc();
      const endUtc = this.getEndUtc();
      console.log(startUtc);
      console.log(endUtc);
      this.getLectureForAssignedSubject('day');
    },
    getIndianDate() {
      const currentDate = new Date();
      // const utcOffset = 5.5 * 60 * 60 * 1000;
      // const indianTime = currentDate.getTime() + utcOffset;
      const indianTime = currentDate.getTime();
      const indianDate = new Date(indianTime);

      const year = indianDate.getFullYear();
      const month = String(indianDate.getMonth() + 1).padStart(2, '0');
      const day = String(indianDate.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    getStartUtc() {
      const currentDate = new Date(this.todayDate);
      currentDate.setUTCHours(18, 30, 0, 0);
      currentDate.setDate(currentDate.getDate() - 1);
      return currentDate.toISOString();
    },
    getEndUtc() {
      const currentDate = new Date(this.todayDate);
      currentDate.setUTCHours(18, 30, 0, 0);
      return currentDate.toISOString();
    },
    getWeekStartDateUtc() {
      const currentDate = new Date();
      const currentDay = currentDate.getUTCDay();
      const mondayOffset = currentDay === 0 ? -6 : 1 - currentDay;
      currentDate.setUTCHours(18, 30, 0, 0);
      currentDate.setDate(currentDate.getUTCDate() + mondayOffset);
      return currentDate.toISOString();
    },
    getWeekEndDateUtc() {
      const currentDate = new Date();
      const currentDay = currentDate.getUTCDay();
      const sundayOffset = currentDay === 0 ? 0 : 7 - currentDay;
      currentDate.setUTCHours(18, 30, 0, 0);
      currentDate.setDate(currentDate.getUTCDate() + sundayOffset + 1);
      return currentDate.toISOString();
    },
    getMonthStartDateUtc() {
      const currentDate = new Date();
      currentDate.setUTCHours(18, 30, 0, 0);
      currentDate.setMonth(currentDate.getMonth());
      currentDate.setDate(1);
      return currentDate.toISOString();
    },
    getMonthEndDateUtc() {
      const currentDate = new Date();
      currentDate.setUTCHours(18, 29, 59, 0);
      currentDate.setMonth(currentDate.getMonth() + 1);
      currentDate.setDate(0);
      return currentDate.toISOString();
    },
    selectWeek() {
      this.currentDate = new Date()
      this.type = 'week';
      const { start, end } = this.getWeekRange();
      this.startDateForCalender = start;
      this.endDateForCalender = end;
      this.newFormattedStartWeek = new Date(start).toLocaleDateString('en-GB');
      this.newFormattedEndWeek = new Date(end).toLocaleDateString('en-GB');
      const startUtc = this.getWeekStartDateUtc();
      const endUtc = this.getWeekEndDateUtc();
      console.log(startUtc);
      console.log(endUtc);
      this.getLectureForAssignedSubject('week')
    },
    getWeekRange() {
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const mondayOffset = currentDay === 0 ? -6 : 1 - currentDay;
      const sundayOffset = currentDay === 0 ? 0 : 7 - currentDay;

      const startDate = new Date(currentDate);
      startDate.setDate(startDate.getDate() + mondayOffset);
      const endDate = new Date(currentDate);
      endDate.setDate(endDate.getDate() + sundayOffset);

      const formattedStartDate = this.formatDate(startDate);
      const formattedEndDate = this.formatDate(endDate);
      return { start: formattedStartDate, end: formattedEndDate };
    },
    selectPreviousWeek() {
      const currentDay = this.currentDate.getDay();
      const mondayOffset = currentDay === 0 ? -6 : 1 - currentDay;
      const sundayOffset = currentDay === 0 ? 0 : 7 - currentDay;

      const startDate = new Date(this.currentDate);
      startDate.setDate(startDate.getDate() + mondayOffset - 7);
      const endDate = new Date(this.currentDate);
      endDate.setDate(endDate.getDate() + sundayOffset - 7);

      this.currentDate.setDate(this.currentDate.getDate() - 7);

      const prevStartDate = this.formatDate(startDate);
      const prevEndDate = this.formatDate(endDate);

      this.startDateForCalender = prevStartDate;
      this.endDateForCalender = prevEndDate;

      this.newFormattedStartWeek = new Date(this.startDateForCalender).toLocaleDateString('en-GB');
      this.newFormattedEndWeek = new Date(this.endDateForCalender).toLocaleDateString('en-GB');

      this.getLectureForAssignedSubject('prevWeek')
    },
    selectNextWeek() {
      this.startDateForCalender = "";
      this.endDateForCalender = "";

      if (!this.currentDate) {
        this.currentDate = new Date();
      }

      const currentDay = this.currentDate.getDay();
      const mondayOffset = currentDay === 0 ? -6 : 1 - currentDay;
      const sundayOffset = currentDay === 0 ? 0 : 7 - currentDay;

      const startDate = new Date(this.currentDate);
      startDate.setDate(startDate.getDate() + mondayOffset + 7);
      const endDate = new Date(this.currentDate);
      endDate.setDate(endDate.getDate() + sundayOffset + 7);

      this.currentDate.setDate(this.currentDate.getDate() + 7);

      const nextStartDate = this.formatDate(startDate);
      const nextEndDate = this.formatDate(endDate);

      this.startDateForCalender = nextStartDate;
      this.endDateForCalender = nextEndDate;

      this.newFormattedStartWeek = new Date(this.startDateForCalender).toLocaleDateString('en-GB');
      this.newFormattedEndWeek = new Date(this.endDateForCalender).toLocaleDateString('en-GB');
      this.getLectureForAssignedSubject('nextWeek')
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    selectMonth() {
      this.currentDate = new Date();
      this.startDateForCalender = "";
      this.endDateForCalender = "";
      this.type = 'month';
      const { start, end } = this.getMonthRange();
      this.startDateForCalender = start;
      this.endDateForCalender = end;

      this.newFormattedMonth = new Date(this.startDateForCalender)
        .toLocaleDateString('en-US', { month: 'long', year: 'numeric' });

      const startUtc = this.getMonthStartDateUtc();
      const endUtc = this.getMonthEndDateUtc();

      console.log(startUtc);
      console.log(endUtc);
      this.getLectureForAssignedSubject('month')
    },
    getMonthRange() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();

      const firstDayOfMonth = new Date(year, month, 1);
      const lastDayOfMonth = new Date(year, month + 1, 0);
      const formattedStartDate = this.formatDate(firstDayOfMonth);
      const formattedEndDate = this.formatDate(lastDayOfMonth);

      return { start: formattedStartDate, end: formattedEndDate };
    },
    getPrevMonthRange() {
      this.startDateForCalender = "";
      this.endDateForCalender = "";

      if (!this.currentDate) {
        this.currentDate = new Date();
      }

      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth();

      const firstDayOfMonth = new Date(year, month, 1);

      firstDayOfMonth.setMonth(firstDayOfMonth.getMonth() - 1);

      const lastDayOfMonth = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth() + 1, 0);

      const formattedStartDate = this.formatDate(firstDayOfMonth);
      const formattedEndDate = this.formatDate(lastDayOfMonth);

      this.currentDate.setMonth(this.currentDate.getMonth() - 1);

      this.startDateForCalender = formattedStartDate;
      this.endDateForCalender = formattedEndDate;
      this.newFormattedMonth = new Date(this.startDateForCalender)
        .toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
      this.getLectureForAssignedSubject('prevMonth')
    },
    getNextMonthRange() {
      this.endDateForCalender = "";
      this.startDateForCalender = "";

      if (!this.currentDate) {
        this.currentDate = new Date();
      }

      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth();

      const firstDayOfNextMonth = new Date(year, month + 1, 1);

      const lastDayOfNextMonth = new Date(firstDayOfNextMonth.getFullYear(), firstDayOfNextMonth.getMonth() + 1, 0);

      const formattedStartDate = this.formatDate(firstDayOfNextMonth);
      const formattedEndDate = this.formatDate(lastDayOfNextMonth);

      this.currentDate.setMonth(this.currentDate.getMonth() + 1);

      this.startDateForCalender = formattedStartDate;
      this.endDateForCalender = formattedEndDate;
      this.newFormattedMonth = new Date(this.startDateForCalender)
        .toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
      this.getLectureForAssignedSubject('nextMonth')
    },
    selectCustom() {
      this.type = 'custom';
      this.calenderDate = []
      this.getLectureForAssignedSubject('customRange')
    },
    handleViewChange() {
      if (this.selectedView === 'Week') {
        const today = new Date();
        const weekday = today.getDay();
        const diff = 6 - weekday;
        const endDate = new Date(today);
        endDate.setDate(today.getDate() + diff);

        const dd = String(endDate.getDate()).padStart(2, '0');
        const mm = String(endDate.getMonth() + 1).padStart(2, '0');
        const yyyy = endDate.getFullYear();

        this.selectedDate = `${yyyy}-${mm}-${dd}`;
      } else if (this.selectedView === 'Day') {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();

        this.selectedDate = `${yyyy}-${mm}-${dd}`;
      } else if (this.selectedView === 'Month') {
        const today = new Date();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();

        const lastDayOfMonth = new Date(yyyy, mm, 0).getDate();

        this.selectedDate = `${yyyy}-${mm}-01`;
        this.selectedDate = `${yyyy}-${mm}-${String(lastDayOfMonth).padStart(2, '0')}`;
      }
    },
    editList(item) {
      console.log('item.', item);
      const currentTimeUTC = new Date().toISOString();
      console.log('currentTimeUTC', currentTimeUTC);
      // console.log('currentTimeUTC', currentTimeUTC);
      // console.log('currentTimeUTC', currentTimeUTC);
      console.log('item.end', item.end);
      console.log('item.start', item.start);
      console.log(' item.endTime > currentTimeUTC', item.end > currentTimeUTC);
      console.log('item.startTime < currentTimeUTC', item.start < currentTimeUTC);
      if (item.end > currentTimeUTC && item.start < currentTimeUTC) {
        this.actions[2].title = 'Create Online Lecture'
      } else {
        this.actions[2].title = 'Get Recording'
      }
    },
    selectActions(id, item) {
      console.log(`⭐ id:`, id, item)
      if (id === 0) {
        if (this.canMarkAttendance(item)) {
          this.gotoTakeAttendance(item)
        }
      }
      // if (id === 1) {
      //   this.markAttendance(item)
      // }
      if (id === 1) {
        if (this.canCancelLecture(item)) {
          this.cancelLectureStep1(item)
        }
      }
      if (id === 2) {
        if (this.canCreateOnlineLecture(item)) {
          this.createOnlineLecture(item)
        } else if (this.canGetRecordingOfOnlineLecture(item)) {
          this.getRecordingOfOnlineLecture(item)
        }
      }
      if (id === 3) {
        this.enableRecordings(item)
      }
      if (id === 4) {
        this.customRecording(item)
      }
      if (id === 5) {
        this.goToRecordingLink(item)
      }
    },
    async copyAttendance() {
      this.arrayOfSelectedLectureToMerge.push(this.item)
      this.arrayOfSelectedLectureToMerge.map(async (ele) => {
        this.item.copiedAttendanceLectureId.push(ele.lectureId)
        this.item.copiedAttendanceLectureId = [...new Set(this.item.copiedAttendanceLectureId)]
        ele.attendanceCopiedFrom = this.item.lectureId
        if (this.item.lectureId !== ele.lectureId) {
          await this.filterStudentForSubject(ele)
        }
        const objToPush = {
          instituteId: ele.instituteId,
          semId: ele.semId,
          lectureId: ele.lectureId,
          department: ele.department,
          courseYear: ele.courseYear,
          division: ele.division,
          uId: ele.uId,
          subjectId: ele.subjectId,
          present: ele.present,
          absent: ele.absent
        }

        if (ele.lectureId !== this.item.lectureId) {
          objToPush.attendanceCopiedFrom = ele.attendanceCopiedFrom
        }
        if (ele.lectureId === this.item.lectureId) {
          objToPush.copiedAttendanceLectureId = ele.copiedAttendanceLectureId
        }
        await this.lectureRepositoryInstance.saveAttendance(objToPush)
      })
      this.copiedAttendance = [...this.item.copiedAttendanceLectureId]
      this.dialogAttendance = true
      this.dialogOfMarkAttendance = false
      this.fetchData();
    },
    markAttendance(item) {
      if (item.isStartTimeGreater === true) {
        alert("Lecture is yet to conduct")
      } else {
        this.arrayOfSelectedLectureToMerge = [];
        this.selectedItemDropedArray = [];
        this.item = item
        this.tempCourseYear = item.courseYear
        this.tempSubject = item.subjectName;
        this.tempDivision = item.division;
        this.tempStartTime = item.startTime;
        this.tempEndTime = item.endTime;
        this.tempBatch = item.batch;
        this.tempLectureId = item.lectureId
        this.timetable.map((obj) => {
          if (this.tempDivision === obj.division && this.tempCourseYear === obj.courseYear && obj.lectureId !== this.tempLectureId) {
            this.selectedItemDropedArray.push(obj)
          }
        })
        this.tempLectureId = ''
        this.dialogOfMarkAttendance = true
      }
    },
    async filterStudentForSubject(obj) {
      const objOfStudent = {
        instituteId: obj.instituteId,
        semId: obj.semId,
        subjectId: obj.subjectId
      };
      this.studentListForSubject =
        await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject(
          objOfStudent
        );
      this.item.present.map((item) => {
        this.studentListForSubject.map((data) => {
          if (data.uId === item) {
            obj.present.push(item)
          }
        })
      })
      this.item.absent.map((item) => {
        this.studentListForSubject.map((data) => {
          if (data.uId === item) {
            obj.absent.push(item)
          }
        })
      })
    },
    appendVideoFileInput() {
      this.videoFiles.push({
        name: "",
        thumbnailUrl: "",
        url: ""
      });
    },
    async fetchData() {
      this.loadingForDataMain = true
      this.assignedLectures = []
      const data = await this.userRepositoryInstance.getUserProfile({
        uId: this.userData.uId
      });
      const date = new Date(this.selectedDate.split('-'))
      this.today = days[date.getDay()]
      this.userName =
        `${data.firstName} ${data.middleName} ${data.lastName}`.trim();
      this.loadingLectures = true;
      const objToPush = {
        uId: this.userData.uId,
        fromDate: this.getStartUtc(),
        toDate: this.getEndUtc()
      };
      this.timetable = [];
      let lecturesArray = [];
      try {
        lecturesArray = await this.lectureRepositoryInstance.getAllLecturesOfMultipleInstitutesPerDayByUId(objToPush);
        if (lecturesArray.lecture && lecturesArray.lecture.length > 0) {
          lecturesArray.lecture.map((info) => {
            info.fullName = ''
            info.roomName = ''
            info.subjectName = ''
            if (lecturesArray.facultyDetails.fullNames.length > 0) {
              lecturesArray.facultyDetails.fullNames.map((fac) => {
                if (fac.uId === info.uId) {
                  info.fullName = fac.fullName
                }
              })
            }
            if (lecturesArray.roomDetails.roomName.length > 0) {
              lecturesArray.roomDetails.roomName.map((room) => {
                if (room.roomId === info.roomId) {
                  info.roomName = room.roomName
                }
              })
            }
            if (lecturesArray.subjectDetails.subjects.length > 0) {
              lecturesArray.subjectDetails.subjects.map((sub) => {
                if (sub.subjectId === info.subjectId) {
                  info.subjectName = sub.subjectName
                  info.subjectType = sub.subjectType
                }
              })
            }
          })
        }
        this.assignedLectures = lecturesArray.lecture;
        lecturesArray = arrayOps.sortByStartTime(lecturesArray.lecture);
        lecturesArray.map((lecture) => {
          this.lectureIds.push(lecture.lectureId);
          if (lecture.roomId !== '') {
            this.roomIds.push(lecture.roomId);
          }
          for (const [key, value] of Object.entries(this.subjectsMapping)) {
            if (key === lecture.subjectId) {
              lecture.subjectName = value.subjectName;
              lecture.lectureType = value.lectureType;
            }
          }
        })
        this.finaltimeTable = lecturesArray.filter(lecture => lecture.subjectName !== undefined)
      } catch (err) {
        console.log(err);
        this.lecturesArray = []
        this.timetable = []
        this.loadingLectures = false
        this.loadingForDataMain = false
        return
      }
      if (this.roomIds.length > 0) {
        try {
          const objToPush = {
            instituteId: this.selectedInstitute,
            roomIds: this.roomIds
          };
          const roomNames = (await this.infrastructureReferenceRepositoryInstance.getRoomNames(
            objToPush
          )) || "";
          this.finaltimeTable.map((lecture) => {
            roomNames.map((room) => {
              if (lecture.roomId === room.roomId) {
                lecture.roomName = room.roomName;
              }
            });
          })
        } catch (e) {
          this.timetable = []
          this.loadingLectures = false
          this.loadingForDataMain = false
          console.log("error", e.stack);
        }
      }

      try {
        this.onlineAttendance = []
        const lectureIdsArray = []
        this.finaltimeTable.map((t) => {
          lectureIdsArray.push(t.lectureId)
        })
        const result = await apiV3.postRequest("/meeting/get-attendance", {
          lectureIds: lectureIdsArray
        });
        this.onlineAttendance = result.totalDuration
      } catch (e) {
        console.log('e', e);
      }
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          lectureIds: this.lectureIds
        };
        const lectureOnlineStatus = await this.onlineLectureRepositoryInstance.getIsOngoingForALectures(
          objToPush
        );
        if (lectureOnlineStatus !== undefined) {
          lectureOnlineStatus.map((lecture) => {
            this.finaltimeTable.map((lecture1) => {
              if (lecture.lectureId === lecture1.lectureId) {
                lecture1.isOnline = lecture.isOnline;
              }
            })
          })
        } else {
          this.finaltimeTable.map((lecture) => {
            lecture.isOnline = false
          })
        }
      } catch (err) {
        this.timetable = []
        this.loadingLectures = false
        this.loadingForDataMain = false
        console.log(err);
      }
      this.finaltimeTable.map((t) => {
        t.afterUtc = false
        // if (new Date("2022-10-31T00:00:00.000Z").toISOString() < t.dateTime) {
        if (!(t.startTime.split('_').length > 1)) {
          t.afterUtc = true
          t.start = t.startTime
          t.end = t.endTime
          t.startTime = convertUTCDateToLocalDate(new Date(t.startTime))
          t.endTime = convertUTCDateToLocalDate(new Date(t.endTime))
        }
        // }
        this.onlineAttendance.map((res) => {
          if (res.lectureId === t.lectureId) {
            t.OnlineAttendanceMarked = true
          }
        })
      })
      this.timetable = this.finaltimeTable.map(timetable => {
        const dateTime = new Date(timetable.dateTime);
        const dateOnly = dateTime.toISOString().split('T')[0];
        const modeOfConduction = timetable.isOnline ? 'Online' : 'Offline';
        return {
          ...timetable,
          dateOnly: dateOnly,
          modeOfConduction: modeOfConduction
        };
      });

      this.loadingLectures = false;
      this.lecturesArray = lecturesArray;
      this.loadingForDataMain = false
    },
    formatTime(timeString) {
      let [hourString, minute] = timeString.split(":");
      const hour = +hourString % 24;
      if (minute === undefined) {
        minute = 0
      }
      return (hour % 12 || 12) + ":" + minute + " " + (hour < 12 ? "AM" : "PM");
    },
    convertTime12to24(time12h) {
      const [time, modifier] = time12h.split(' ');

      let [hours, minutes, second] = time.split(':');
      console.log(second);

      if (hours === '12') {
        hours = '00';
      }

      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }

      return `${hours}:${minutes}`;
    },
    convertDateToUTC(date) {
      return new Date(date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds())
    },
    uploadFiles() {
      this.loading = true;
      this.downloadURLs = [];
      this.uploadCount = 0;
      this.files.forEach((file) => {
        this.upload(file);
      });
      if (this.files.length > 0) {
        this.upload(this.files[0]);
      }
    },
    cancelUpload() {
      this.files = [];
      this.selectedLecture = {};
      this.showUploadVideo = false;
    },
    async uploadVideo() {
      this.loading = false;
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          lectureId: this.selectedLecture.lectureId,
          remarksForLecture: this.downloadURLs.map((downloadUrl, index) => {
            return { name: "Lecture Video " + index, link: downloadUrl };
          })
        };
        await this.lectureRepositoryInstance.updateRemarksForLecture(objToPush);
        const indexOfLecture = this.timetable.findIndex(
          (lecture) => lecture.lectureId === this.selectedLecture.lectureId
        );
        if (indexOfLecture > -1) {
          this.timetable[indexOfLecture].remarksForLecture =
            this.downloadURLs.map((downloadUrl, index) => {
              return { name: "Lecture Video " + index, link: downloadUrl };
            });
        }
        this.uploading = false;
        this.showUploadVideo = false;
        this.uploadCount = 0;
        this.progressUpload = 0;
      } catch (err) {
        console.log(err);
      }
    },
    upload(file) {
      this.fileName = file.name;
      this.uploading = true;
      this.uploadTask = firestorage
        .ref(
          "lectureVideos/" +
          this.selectedLecture.lectureId +
          "/" +
          new Date().valueOf().toString() +
          "________" +
          file.name
        )
        .put(file);
    },
    filesChanged(e) {
      console.log(e);
    },
    canGetRecordingOfOnlineLecture(item) {
      if (item.afterUtc !== true) {
        const currentTime = new Date();
        const date = item.dateTime.slice(0, 10).split("-");
        const time = item.endTime.split("_");
        const parsedEndTimeOfLecture = new Date(
          date[0],
          parseInt(date[1]) - 1,
          date[2],
          time[0],
          time[1]
        );
        return (
          currentTime - parsedEndTimeOfLecture >= 60 * 60 * 1000 &&
          [
            lectureTypes.ORDINARY,
            lectureTypes.PENDING,
            lectureTypes.RESCHEDULED_EXTRA,
            lectureTypes.REMEDIAL
          ].includes(item.status)
        );
      } else {
        const currentTime = (new Date()).toISOString();
        return (
          currentTime > item.end &&
          [
            lectureTypes.ORDINARY,
            lectureTypes.PENDING,
            lectureTypes.RESCHEDULED_EXTRA,
            lectureTypes.REMEDIAL
          ].includes(item.status)
        );
      }
    },
    canMarkAttendance(item) {
      if (item.isStartTimeGreater === true) {
        alert("Lecture is yet to conduct")
      } else {
        if (item.afterUtc !== true) {
          const currentDate = new Date();
          let adjustForOffset = 0;
          if (convertTime(item.dateTime) !== item.startTime) {
            adjustForOffset = 19800000; // 6 * 60 * 60 * 1000 - 30 * 60 * 1000 (5:30 hours)
          }
          const parsedDateTimeOfLecture = new Date(
            new Date(item.dateTime).valueOf() - adjustForOffset
          );
          // return true
          return (
            parsedDateTimeOfLecture <= currentDate &&
            [
              lectureTypes.ORDINARY,
              lectureTypes.RESCHEDULED_EXTRA,
              lectureTypes.REMEDIAL
            ].includes(item.status)
          );
        } else {
          const currentTime = new Date();
          const endTime = new Date(new Date(item.start))
          // return true
          return (
            endTime <= currentTime &&
            [
              lectureTypes.ORDINARY,
              lectureTypes.RESCHEDULED_EXTRA,
              lectureTypes.REMEDIAL
            ].includes(item.status)
          );
        }
      }
    },
    canCancelLecture(item) {
      return (
        !item.isConducted &&
        [
          lectureTypes.ORDINARY,
          lectureTypes.RESCHEDULED_EXTRA,
          lectureTypes.REMEDIAL
        ].includes(item.status)
      );
    },
    canUploadVideo(item) {
      return [
        lectureTypes.ORDINARY,
        lectureTypes.RESCHEDULED_EXTRA,
        lectureTypes.REMEDIAL
      ].includes(item.status);
    },
    canRescheduleLecture(item) {
      return (
        !item.isConducted &&
        [lectureTypes.ORDINARY, lectureTypes.REMEDIAL].includes(item.status)
      );
    },
    canCreateOnlineLecture(item) {
      if (item.afterUtc !== true) {
        const currentTime = new Date();
        const date = item.dateTime.slice(0, 10).split("-");
        const time = item.endTime.split("_");
        const parsedEndTimeOfLecture = new Date(
          date[0],
          parseInt(date[1]) - 1,
          date[2],
          time[0],
          time[1]
        );
        return (
          parsedEndTimeOfLecture > currentTime &&
          [
            lectureTypes.ORDINARY,
            lectureTypes.PENDING,
            lectureTypes.RESCHEDULED_EXTRA,
            lectureTypes.REMEDIAL
          ].includes(item.status)
        );
      } else {
        const currentTime = new Date();
        const time = new Date(new Date(item.end))
        return (
          currentTime < time &&
          [
            lectureTypes.ORDINARY,
            lectureTypes.PENDING,
            lectureTypes.RESCHEDULED_EXTRA,
            lectureTypes.REMEDIAL
          ].includes(item.status)
        );
      }
    },
    checkValidDate() {
      if (
        this.convertToISOString(convertFromYYYYMMDDToDate(this.selectedDate))
      ) {
      } else {
        this.timetable = [];
      }
    },
    timeStringConverter(item) {
      console.log('timeStringConverter(item)', item.toLocaleTimeString());
      return timeStringConverter(item);
    },
    gotoArrangeLecture() {
      const freeSlots = [];
      this.timetable.forEach((slot) => {
        if (slot.status !== 5 && slot.status !== 6 && slot.status !== 2) {
          freeSlots.push({
            startTime: slot.startTime,
            endTime: slot.endTime
          });
        }
      });
      this.$router.push({
        name: "activityArrangeLecture",
        params: {
          prop_selectedDate: this.newFormattedDate,
          prop_occupiedSlots: freeSlots
        }
      });
    },
    async gotoTakeAttendance(lecture) {
      this.selectedLec = lecture
      this.flagForReq = false
      const date = new Date().toISOString()
      // console.log('date', date);
      this.flagForReqNotAccpeted = false
      if (this.isNaralkar === true) {
        if (date > '2025-11-12T12:30:24.215Z') {
          if (lecture.multiDivision && lecture.multiDivision.length > 0) {
            this.$router.push({
              name: "activityTakeAttendance",
              params: {
                prop_lecture: lecture
              }
            });
          } else {
            try {
              const currentDate = new Date();
              const twoDaysAgo = new Date(currentDate);
              twoDaysAgo.setDate(currentDate.getDate() - 2);
              const isoDate = twoDaysAgo.toISOString();
              const currentDateTime = new Date().toISOString();
              // Convert it to the start time of the date (12:00 AM UTC)
              const startOfDay = new Date(currentDateTime);
              startOfDay.setUTCHours(0, 0, 0, 0);
              // Get the ISO string for the start of the day
              const startOfDayISOString = startOfDay.toISOString();
              const obj = {
                instituteId: lecture.instituteId,
                semId: lecture.semId,
                department: lecture.department,
                courseYear: lecture.courseYear,
                division: lecture.division,
                uId: lecture.uId,
                subjectId: lecture.subjectId,
                currentDateTime: startOfDayISOString,
                time: isoDate
              }

              let lec = []
              lec = await this.getLastLec(obj)
              //count last three lec is not conducted
              // console.log('lec', lec);
              // let countFlag = 0
              // let countFlagTopic = 0
              // let countFlagPlannedTopic = 0
              const arrFordays = []
              this.selectedLecIds = []
              // console.log('lec.length', lec.length);
              if (lec.length < 3) {
                this.$router.push({
                  name: "activityTakeAttendance",
                  params: {
                    prop_lecture: lecture
                  }
                });
              } else {
                if (Array.isArray(lec) && lec.length > 0) {
                  lec.map((item) => {
                    let count = 0
                    this.selectedLecIds.push(item.lectureId)
                    if (item.isConducted === false) {
                      count++;
                    }
                    if (item.coveredTopicIds === undefined || item.coveredTopicIds.length === 0) {
                      count++;
                    }
                    if (item.plannedTopicIds === undefined || item.plannedTopicIds.length === 0) {
                      count++;
                    }
                    arrFordays.push(count)
                  })
                }

                let finalCount = 0
                if (arrFordays.length > 0) {
                  arrFordays.map((item) => {
                    if (item > 0) {
                      finalCount++
                    }
                  })
                }
                if (finalCount > 2) {
                  const req = await this.getReqForSubjectForFac(obj)
                  console.log('req', req);
                  let latestReq = {}
                  if (Array.isArray(req) && req.length === 0) {
                    this.flagForReq = true
                  }
                  if (Array.isArray(req) && req.length === 1) {
                    latestReq = req[0]
                    if (latestReq.isEnable === false) {
                      this.flagForReqNotAccpeted = true
                    } else {
                      this.$router.push({
                        name: "activityTakeAttendance",
                        params: {
                          prop_lecture: lecture
                        }
                      });
                    }
                  }
                  if (Array.isArray(req) && req.length > 0) {
                    latestReq = req[req.length - 1]
                    if (latestReq.isEnable === false) {
                      this.flagForReqNotAccpeted = true
                    } else {
                      this.$router.push({
                        name: "activityTakeAttendance",
                        params: {
                          prop_lecture: lecture
                        }
                      });
                    }
                  }
                } else {
                  this.$router.push({
                    name: "activityTakeAttendance",
                    params: {
                      prop_lecture: lecture
                    }
                  });
                }
              }
              //req not accepted by admin yet
              // this.flagForReqNotAccpeted = false
              // this.flagForReq = true
              // console.log('this.flagForReqNotAccpeted', this.flagForReqNotAccpeted);
              // console.log('this.this.flagForReq', this.flagForReq);
              // console.log('lec', lec);
            } catch (error) {
              console.error(error)
            }
          }
        } else {
          this.$router.push({
            name: "activityTakeAttendance",
            params: {
              prop_lecture: lecture
            }
          });
        }
      } else {
        this.$router.push({
          name: "activityTakeAttendance",
          params: {
            prop_lecture: lecture
          }
        });
      }
    },
    //recording
    async enableRecordings(item) {
      if (item.isStartTimeGreater === true) {
        alert("Lecture is yet to conduct")
      } else {
        // console.log('item', item);
        this.customSelectedStudents = []
        if (item.isRecordingEnableForStudents === false && (item.studentsListForRecordingEnable.length > 0) === false) {
          this.toggle = false
        } else {
          this.toggle = true
        }
        this.selectedItem = item;
        this.recording = true;
        //get student list
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: item.department,
          courseYear: item.courseYear,
          division: item.division,
          batch: item.batch || '',
          subjectId: item.subjectId,
          uId: this.userData.uId
        }
        this.studentList = await this.semesterUserRepositoryInstance.getSemesterUsersOfASubjectOfADivision(objToPush)
        if (this.studentList) {
          this.studentList.map((student) => {
            this.subjectIds.push(student.uId)
          })
        }
        const data = await this.userRepositoryInstance.getFullNameOfUsers({
          uIds: this.subjectIds
        });
        if (item.studentsListForRecordingEnable.length > 0) {
          data.map((obj) => {
            if (item.studentsListForRecordingEnable.includes(obj.uId)) {
              this.customSelectedStudents.push(obj)
              this.recordingEnableStudent = 'customize'
            }
          })
        }
        this.usersData = data;
        this.usersData.map((obj) => {
          this.allUserArray.push(obj.uId);
        });
      }
    },
    async handleToggle() {
      this.toggle = false
      this.recordingEnableStudent = ''
    },
    async closeRecordingDialogue() {
      this.recording = false
      this.allUserArray = []
      this.getUserArray = []
      this.studentArray = []
      this.usersData = []
      this.recordingEnableStudent = ''
    },
    async submitRecordingResult() {
      if (this.recordingEnableStudent === "present") {
        this.getStudentsListPresent();
      } else if (this.recordingEnableStudent === "absent") {
        this.getStudentsListAbsent();
      } else if (this.recordingEnableStudent === "customize") {
        this.getStudentsListCustom();
      } else {
        this.disableRecording();
      }
    },
    async getStudentsListPresent() {
      if (this.recordingEnableStudent === "present") {
        // console.log("this.studentsListPresent");
      } else {
        this.studentsListPresent = [];
      }
      try {
        const objToPush = {
          instituteId: this.selectedItem.instituteId,
          semId: this.selectedItem.semId,
          lectureId: this.selectedItem.lectureId,
          studentsListForRecordingEnable: this.studentsListPresent,
          isRecordingEnableForStudents: false
        };
        const res = await this.lectureRepositoryInstance.updateLecturerById(
          objToPush
        );
        console.log("res", res);
        this.closeRecordingDialogue()
        this.fetchData()
      } catch (e) {
        console.log("err", e.stack);
      }
    },
    async getStudentsListAbsent() {
      if (this.recordingEnableStudent === "absent") {
        // console.log(
        //   'this.recordingEnableStudent'
        // );
      } else {
        this.studentsListAbsent = [];
      }
      try {
        const objToPush = {
          instituteId: this.selectedItem.instituteId,
          semId: this.selectedItem.semId,
          lectureId: this.selectedItem.lectureId,
          studentsListForRecordingEnable: this.studentsListAbsent,
          isRecordingEnableForStudents: false
        };
        const res = await this.lectureRepositoryInstance.updateLecturerById(
          objToPush
        );
        console.log("res", res);
        this.closeRecordingDialogue()
        this.fetchData()
      } catch (e) {
        console.log("err", e.stack);
      }
    },
    async getStudentsListCustom() {
      this.customSelectedStudents.map((obj) => {
        this.selectedArray.push(obj.uId);
      });
      if (this.recordingEnableStudent === "customize") {
        // console.log("this.selectedArray");
      } else {
        this.selectedArray = [];
      }
      try {
        const objToPush = {
          instituteId: this.selectedItem.instituteId,
          semId: this.selectedItem.semId,
          lectureId: this.selectedItem.lectureId,
          studentsListForRecordingEnable: this.selectedArray,
          isRecordingEnableForStudents: false
        };
        const res = await this.lectureRepositoryInstance.updateLecturerById(
          objToPush
        );
        console.log("res", res);
        this.selectedArray = [];
        this.closeRecordingDialogue()
        this.fetchData()
      } catch (e) {
        console.log("err", e.stack);
      }
    },
    async disableRecording() {
      this.recordingEnableStudent = ''
      if (this.toggle === true && this.recordingEnableStudent === '') {
        try {
          const objToPush = {
            instituteId: this.selectedItem.instituteId,
            semId: this.selectedItem.semId,
            lectureId: this.selectedItem.lectureId,
            studentsListForRecordingEnable: [],
            isRecordingEnableForStudents: true
          };
          const res = await this.lectureRepositoryInstance.updateLecturerById(
            objToPush
          );
          console.log("res", res);
          this.handleToggle()
          this.closeRecordingDialogue()
          this.fetchData()
        } catch (e) {
          console.log("err", e.stack);
        }
      } else {
        try {
          const objToPush = {
            instituteId: this.selectedItem.instituteId,
            semId: this.selectedItem.semId,
            lectureId: this.selectedItem.lectureId,
            studentsListForRecordingEnable: [],
            isRecordingEnableForStudents: false
          };
          const res = await this.lectureRepositoryInstance.updateLecturerById(
            objToPush
          );
          console.log("res", res);
          this.handleToggle()
          this.closeRecordingDialogue()
          this.fetchData()
        } catch (e) {
          console.log("err", e.stack);
        }
      }
    },
    //upload recording
    async customRecording(item) {
      if (item.isStartTimeGreater === true) {
        alert("Lecture is yet to conduct")
      } else {
        this.selectedItem = item;
        this.uploadCustomVideoDialog = true
        this.appendVideoFileInput()
      }
    },
    async addVideoRecording() {
      this.currentlyUploadingNumber = 0;
      this.currentUploadProgress = 0;
      this.uploadRecording = true;
      this.nonEmptyFiles = this.videoFiles.filter(item => item.name).length
      this.uploadedVideoFile = await this.uploadVideos();
      this.uploadRecording = false
    },
    async uploadVideos() {
      if (this.videoFiles.length === 0) return [];
      const videoFilePromises = [];
      const videoFiles = this.videoFiles.filter(item => item.name);
      videoFiles.forEach(videoFile => {
        videoFilePromises.push(
          this.getSignedUrl(videoFile, `${this.userData.uId}/videos/`)
        );
      });
      const signedUrlsData = await Promise.all(videoFilePromises);
      const signedUrls = signedUrlsData.map(item => item.signedUrl);
      const uploadPromises = [];
      if (signedUrls.length === videoFiles.length) {
        uploadPromises.push(this.uploadToSpaces(videoFiles[0], signedUrls[0]));
        await Promise.all(uploadPromises);
        return signedUrlsData.map((item, i) => ({
          url: spacesUrl + "/" + item.fileName,
          thumbnailUrl: "",
          name: videoFiles[i].name,
          mimeType: item.mimeType
        }));
      }
      return [];
    },
    async getSignedUrl(file, path = "") {
      const fileName = path + file.name;
      const body = JSON.stringify({
        fileName,
        fileType: file.type
      });
      const signedUrl = await this.lectureRepositoryInstance.getSignedUrl(
        body
      );
      // console.log('signedUrl of recording', signedUrl);
      this.customSignedUrl = signedUrl.signedUrl.split('?')[0]
      return signedUrl;
    },
    futch(url, opts = {}, onProgress) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method || "get", url);
        for (var k in opts.headers || {}) {
          xhr.setRequestHeader(k, opts.headers[k]);
        }
        xhr.onload = e => resolve(e.target.responseText);
        xhr.onerror = reject;
        if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
        xhr.send(opts.body);
      });
    },
    async uploadToSpaces(file, signedUrl) {
      const res = await this.futch(
        signedUrl,
        {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
            "x-amz-acl": "public-read"
          }
        },
        event => {
          const progress = parseInt((event.loaded / event.total) * 100);
          setTimeout(() => {
            this.currentUploadProgress = progress;
            if (progress > 99) {
              this.currentlyUploadingNumber++;
            }
          }, 200);
        }
      );

      this.currentUploadProgress = 0;
      return res;
    },
    async submitCustomRecording() {
      try {
        this.isAddingRecording = true
        const objToPush = {
          instituteId: this.selectedItem.instituteId,
          semId: this.selectedItem.semId,
          lectureId: this.selectedItem.lectureId,
          recordingLink: this.customSignedUrl
        };
        const res = await this.lectureRepositoryInstance.updateLectureRecordingLinkById(
          objToPush
        );
        console.log('res', res);
        this.isAddingRecording = false
        this.uploadCustomVideoDialog = false
        this.clearVideoRecording()
        this.fetchData()
      } catch (e) {
        console.log("err", e.stack);
      }
    },
    async clearVideoRecording() {
      this.uploadCustomVideoDialog = false
      this.uploadRecording = false
      this.videoFiles = []
      this.uploadingRecordedVideo = []
      this.currentlyUploadingNumber = 0
      this.currentUploadProgress = 0
      this.uploadedVideoFile = []
      this.customSignedUrl = ''
    },
    async cancelLectureStep1(lecture) {
      if (this.canCancelLecture(lecture)) {
        // console.log(lecture);
        this.selectedLecture = JSON.parse(JSON.stringify(lecture));
        this.showCancellingDialog = true;
        this.reasonForCancellingLecture = ''
      } else {
        if (new Date(lecture.dateTime) <= new Date()) {
          showStatus("Past lectures cannot be cancelled.", 2000, "error", this);
        } else if (lecture.isConducted) {
          showStatus(
            "Conducted lectures cannot be cancelled.",
            2000,
            "error",
            this
          );
        } else if (
          ![
            lectureTypes.ORDINARY,
            lectureTypes.RESCHEDULED_EXTRA,
            lectureTypes.REMEDIAL
          ].includes(lecture.status)
        ) {
          showStatus(
            "This lecture is marked as " +
            Object.keys(lectureTypes)[lecture.status],
            2000,
            "error",
            this
          );
        }
      }
    },
    async startUploadVideo(lecture) {
      // console.log(lecture);
      this.selectedLecture = JSON.parse(JSON.stringify(lecture));
      this.showUploadVideo = true;
    },
    startDownloadVideo(lecture) {
      this.selectedLecture = JSON.parse(JSON.stringify(lecture));
      this.showDownloadVideo = true;
    },

    async cancelLecture(lecture) {
      try {
        const indexOfLecture = this.timetable.indexOf(lecture);

        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          lectureId: lecture.lectureId,
          reason: this.reasonForCancellingLecture
        };
        this.timetable[indexOfLecture].status =
          await this.lectureRepositoryInstance.cancelALecture(objToPush);
      } catch (err) {
        console.log(err);
        // showStatus('Lecture could not be cancelled', 1000, 'error', this)
      }
      this.showCancellingDialog = false;
      this.getLectureForAssignedSubject('day');
    },
    async joinLecture(lectureId) {
      try {
        const { redirectUrl } = await apiV3.getRequest("/meeting/join", {
          lectureId,
          fullName: this.userName,
          userRole: 1,
          uId: this.userData.uId
        });
        window.open(redirectUrl, "_blank");
      } catch (err) {
        console.error(err);
        alert("An error occured joining lecture, try later");
      }
      try {
        const objToPush = {
          instituteId: this.selectedLecture.instituteId,
          semId: this.selectedLecture.semId,
          department: this.selectedLecture.department,
          division: this.selectedLecture.division,
          courseYear: this.selectedLecture.courseYear,
          subjectId: this.selectedLecture.subjectId,
          title: `${this.selectedLecture.subjectName} Live lecture started`,
          description: `Prof ${this.userName}, ${this.selectedLecture.subjectName}. Click to join`
        }
        // console.log('objToPush', objToPush);
        const res = await this.lectureRepositoryInstance.lectureNotification(objToPush);
        console.log('res', res);
      } catch (e) {
        if (e.response.status > 500) {
          console.log('err from notification server, but lecture can be Continued')
        }
        console.log('e', e);
      }
    },
    async createOnlineLecture(lecture) {
      const { lectureId, start, end, mergedLectures } = lecture;
      try {
        this.isJoiningLecture = true;
        this.selectedLecture = lecture;
        this.showCreateOnlineLectureDialog = true;
        this.joiningLectureId = lectureId;
        const startTime = new Date(start)
        const endTime = new Date(end)
        // console.log('endTimeInSeconds', startTime);
        const endTimeInSeconds = (startTime - endTime) / 1000;
        // console.log('endTimeInSeconds', endTimeInSeconds);
        await apiV3.postRequest("/meeting/create", {
          meetingName: lecture.subjectName,
          endTimeInSeconds: Math.abs(endTimeInSeconds),
          lectureIds:
            mergedLectures.length > 0 ? [...mergedLectures] : [lectureId]
        });
        // IMP: the /join API requires lectureId to join the student
        this.meetingId = lectureId;
      } catch (err) {
        // 409 means meeting already exists which is normal
        // behaviour but axios treats 4xx codes as errors
        if (err.response.status === 409) {
          this.selectedLecture = lecture;
          this.showCreateOnlineLectureDialog = true;
          // IMP: the /join API requires lectureId to join the student
          this.meetingId = lectureId;
        } else {
          console.error(err);
          alert("An error occured, try later or contact admin");
        }
      } finally {
        this.isJoiningLecture = false;
        this.joiningLectureId = lectureId;
      }
    },
    async startRescheduleProcess(lecture) {
      if (this.canRescheduleLecture(lecture)) {
        // console.log(lecture);
        this.selectedLecture = JSON.parse(JSON.stringify(lecture));
        this.availableFacultyList = [];
        this.availableFacultyNames = [];
        try {
          this.availableFacultyList =
            await this.lectureRepositoryInstance.getFreeFacultiesForALecture(
              this.selectedLecture
            );
          // console.log(this.availableFacultyList);
        } catch (err) {
          console.log(err);
        }

        const promises = [];

        for (let i = 0; i < this.availableFacultyList.length; i++) {
          promises.push(this.getFullNameOfFaculty(i));
        }

        await Promise.allSettled(promises);
        this.showRescheduleDialog = true;
      } else {
        if (new Date(lecture.dateTime) <= new Date()) {
          showStatus(
            "Past lectures cannot be rescheduled.",
            2000,
            "error",
            this
          );
        } else if (lecture.isConducted) {
          showStatus(
            "Conducted lectures cannot be rescheduled.",
            2000,
            "error",
            this
          );
        } else if (
          ![
            lectureTypes.ORDINARY,
            lectureTypes.RESCHEDULED_EXTRA,
            lectureTypes.REMEDIAL
          ].includes(lecture.status)
        ) {
          showStatus(
            "This lecture is marked as " +
            Object.keys(lectureTypes)[lecture.status],
            2000,
            "error",
            this
          );
        }
      }
    },
    async getFullNameOfFaculty(indexOfAvailableFaculty) {
      try {
        const name = await this.userRepositoryInstance.getFullNameOfUser({
          uId: this.availableFacultyList[indexOfAvailableFaculty]
        });
        this.facultyNamesMapping[this.availableFacultyList] = name;
        this.availableFacultyNames[indexOfAvailableFaculty] = name;
      } catch (err) {
        console.log(err);
      }
    },
    cancelRescheduling() {
      this.selectedLecture = {};
      this.availableFacultyList = [];
      this.availableFacultyNames = [];
      this.selectedFacultyName = "";
      this.showRescheduleDialog = false;
    },
    cancelCancelling() {
      this.selectedLecture = {};
      this.reasonForCancellingLecture = "";
      this.showCancellingDialog = false;
    },
    // async getRecordingLink (lecture) {
    //   console.log('this is lecture in getRecording', lecture)
    // },
    async sendRescheduleRequest() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          requestingLectureId: this.selectedLecture.lectureId,
          requestedFacultyId:
            this.availableFacultyList[
            this.availableFacultyNames.indexOf(this.selectedFacultyName)
            ]
        };
        await this.rescheduleRequestsRepositoryInstance.sendARescheduleRequest(
          objToPush
        );
        this.cancelRescheduling();
        showStatus("Request sent successfully.", 1000, "success", this);
      } catch (err) {
        console.log(err);
        showStatus("Could not send request.", 1000, "error", this);
      }
    },
    async closeCreateOnlineLectureDialog() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          lectureId: this.selectedLecture.lectureId,
          uId: this.selectedLecture.uId
        };
        await this.onlineLectureRepositoryInstance.updateOnlineLecture(
          objToPush
        );
        this.showCreateOnlineLectureDialog = false;
        this.timetable[
          this.timetable.indexOf(this.selectedLecture)
        ].isOngoing = false;
        this.selectedLecture = {};
      } catch (err) {
        console.log(err);
        showStatus("Online Lecture could not be created", 1000, "error", this);
      }
    },
    async goToOnlineLectureUrl() {
      try {
        const meetingUrl = new URL(this.redirectionUrl);
        const mid = meetingUrl.searchParams.get("mid");
        const { lectureId, startTime, endTime } = this.selectedLecture;

        const endTimeInSeconds = (startTime - endTime) / 1000;
        // console.log('endTimeInSeconds', endTimeInSeconds);
        await this.onlineLectureRepositoryInstance.createOnlineLectureForStudentsNew(
          {
            lectureId,
            mid,
            endTimeInSeconds
          }
        );
        window.open(this.redirectionUrl, "_blank");
      } catch (e) {
        console.error(e);
        alert("An error occured, refresh and try again");
      }
    },
    async getRecordingOfOnlineLecture(lecture) {
      if (lecture.recordingLink) {
        this.goToRecordingLink(lecture);
      } else {
        alert("No lecture recordings found");
      }
    },
    goToRecordingLink(item) {
      if (item.isStartTimeGreater === true) {
        alert("Lecture is yet to conduct")
      } else {
        if (item.recordingLinkMp4) {
          console.log(item.recordingLinkMp4);
          window.open(item.recordingLinkMp4, "_blank");
          // this.tempLink = link
          // this.showRecording = true
        } else {
          alert("No lecture recordings found");
        }
      }
    },
    convertToISOString(date) {
      try {
        const convertedDate = new Date(date.valueOf());
        // IST is 5.30 hours ahead of UTC
        convertedDate.setHours(5, 30, 0, 0);
        return convertedDate.toISOString();
      } catch (err) {
        console.log(err);
        return undefined;
      }
    }
  }
};
</script>

<style src='./activityMyTimetable.css'></style>
