import Vue from 'vue'
import App from './App.vue'
import { router } from './Router/' // import router object
import { store } from './Store/' // import store (vuex) object
import Vuetify from 'vuetify/lib'
import auth from './Services/Firebase/auth'
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import VueChartJs from 'vue-chartjs'
import VueApexCharts from 'vue-apexcharts'
import OtpInput from "@bachdgvn/vue-otp-input";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DatetimePicker from 'vuetify-datetime-picker';
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./main.css"

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueChartJs)
Vue.use(Chartkick.use(Chart))
Vue.use(Vuetify)
Vue.component("v-otp-input", OtpInput);
Vue.use(CKEditor)
Vue.use(DatetimePicker)
Vue.use(BootstrapVue)

const vuetify = new Vuetify({})

Vue.config.productionTip = false

new Vue({
  // use router object
  router,

  // use store (vuex) object
  store,

  vuetify,

  beforeCreate () {
    auth.init(this)
  },

  render: h => h(App)
}).$mount('#app')

if (process.env.NODE_ENV === "production") {
  require("../disableLogs");
}
