<template src="./activityEditAssessment.html"></template>

<script>
import NbaRepository from "../../../Repository/NBA";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import UserRepository from "../../../Repository/User";
import AssessmentRubricRepository from "../../../Repository/AssessmentRubric";
import AssessmentRepository from "../../../Repository/Assessment";
import { spacesUrl } from "../../../NetworkManager";
import AssignmentRepository from '../../../Repository/Assignment'
import AssignmentUserRepository from "../../../Repository/AssignmentUser";
// import auth from "../../Services/Firebase/auth";

import axios from 'axios'

import showStatus from '../../../NetworkManager/showStatus';

export default {
    name: 'activityEditAssessment',
    props: ['prop_assessment', 'prop_subject', 'prop_type', 'prop_groupInfo'],
    data() {
        return {
            e1: 1,
            linkWater: '#E1E2F8',
            resolutionBlue: '#00128C',
            orange: '#FF4F1F',
            title: '',
            description: '',
            totalMarks: 0,
            datePickerMenu: false,
            dueDate: null,
            dueTime: null,
            timePickerMenu: false,
            assessmentMethods: [
                {
                    text: 'Rubric (Overall)',
                    value: 'rubricOverall'
                },
                {
                    text: 'Rubric (Question-wise)',
                    value: 'rubricQuestionWise'
                },
                {
                    text: 'Marks Only (Overall)',
                    value: 'marksOverall'
                },
                {
                    text: 'Marks Only (Question-wise)',
                    value: 'marksQuestionWise'
                }
            ],
            attachedFiles: [],
            fileTypes: [
                {
                    text: "Image",
                    value: 'image',
                    extension: 'image/*'
                },
                {
                    text: "Video",
                    value: 'video',
                    extension: 'video/*'
                },
                {
                    text: "Audio",
                    value: 'audio',
                    extension: 'audio/*'
                },
                {
                    text: "File",
                    value: 'file',
                    extension: '.doc,.docx,.xml'
                }
            ],
            allFileTypes: false,
            currentDate: '',
            assessmentMethod: '',
            questionArray: [],
            addQuestionDialog: false,
            subQuestionArray: [],
            question: '',
            editQuestionDialog: false,
            questionToEdit: {},
            rubric: {},
            selectedSubject: {},
            assessmentScaleLimits: [],
            studentData: [],
            studentMarksTableHeaders: [],
            studentDataLoader: false,
            assessmentCriteriaDialog: false,
            assessmentScaleDialog: false,
            maxMarksForAssessmentCriteria: 0,
            assessmentCriteriaWeightage: 0,
            assessmentCriteria: '',
            rubricName: '',
            assessmentCriteriaArray: [],
            tempUpperLimit: 0,
            finalRubric: [],
            assessmentCriteriaDescription: '',
            assessmentCriteriaTitle: '',
            displayStudentMarksTable: false,
            uploadMarksExcelDialog: false,
            excelUploadSuccess: false,
            studentMarksExcel: [],
            studentDataForExcel: [],
            isForNba: false,
            thresholdMethod: '',
            overallThreshold: 0,
            mappedCOs: [],
            mappedBlooms: [],
            bloomsDialog: false,
            coDialog: false,
            totalBlooms: [
                {
                    name: 'CREATE (C)',
                    shortCode: 'C',
                    description: 'Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)'
                },
                {
                    name: 'EVALUATE (E)',
                    shortCode: 'E',
                    description: 'Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)'
                },
                {
                    name: 'ANALYZE (A)',
                    shortCode: 'A',
                    description: 'Draw connections among ideas (Verbs : differentiate, organize, relate, compare, contrast, distinguish, examine, experiment, question, test)'
                },
                {
                    name: 'APPLY (Ap)',
                    shortCode: 'Ap',
                    description: 'Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)'
                },
                {
                    name: 'UNDERSTAND (U)',
                    shortCode: 'U',
                    description: 'Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)'
                },
                {
                    name: 'REMEMBER (R)',
                    shortCode: 'R',
                    description: 'Recall facts and basic concepts (Verbs : define, duplicate, list, memorize, repeat, state)'
                }
            ],
            totalCos: [],
            totalPsos: [],
            selectedSemester: {},
            selectedInstituteId: "",
            nbaAttainmentObject: {},
            uploadedAssessmentFiles: [],
            assessmentCriteriaArrayDummy: [],
            studentMarks: [],
            updateStudentMarksLoader: false,
            updateBasicDetailsLoader: false,
            editAssessmentCriteriaDialog: false,
            deleteAssessmentCriteriaDialog: false,
            indexToEditCriteria: 0,
            indexToEditQuestion: 0,
            maxCriteriaMarks: 0,
            inputRules: {
                limit: value => Number(value) > this.tempUpperLimit || `Value should be greater than ${this.tempUpperLimit}`,
                numberOnly: value => {
                    const pattern = /^[0-9]*$/
                    return pattern.test(value) || 'Please Enter Only Numbers.'
                },
                maxMarksForStudent: value => Number(value) <= this.maxCriteriaMarks || `Marks should not be greater than ${this.maxCriteriaMarks}`
                // maxMarksForCriteria: value => Number(value) <= this.maxMarksForAssessmentCriteria || `Marks should not be greater than ${this.maxMarksForAssessmentCriteria}`
            },
            rubricDetails: {},
            scaleError: '',
            rubricUpdated: false,
            criteriaHeaders: [],
            search: '',
            addRemarkDialog: false,
            studentToEvaluate: {},
            rubricAsRemark: [],
            assignmentUsers: [],
            updatedStudents: [],
            submittedDocumentDialog: false,
            submittedDocumentUrl: '',
            selectedStudent: {},
            resubmitStatusLoader: false,
            completedStatusLoader: false,
            showCanvasDialog: false,
            canvasContainer: null,
            checkFileLoader: false,
            tempStud: {}
        }
    },
    async created() {
        // console.log('prop_type', this.prop_type)
        this.assessmentRubricRepositoryInstance = new AssessmentRubricRepository(this);
        this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
        this.userRepositoryInstance = new UserRepository(this);
        this.assessmentRepositoryInstance = new AssessmentRepository(this);
        this.nbaRepositoryInstance = new NbaRepository(this);
        this.assignmentRepositoryInstance = new AssignmentRepository(this)
        this.assignmentUserRepositoryInstance = new AssignmentUserRepository(this);

        // const firebase = auth.getFirebaseObject();
        // this.firebaseInstance = firebase;
        // fireStorage = firebase.storage();

        this.selectedInstituteId =
            this.$store.getters["instituteData/get_selectedInstitute"];
        this.selectedSemester =
            this.$store.getters["instituteData/get_selectedSemester"];
        this.selectedSubject = this.$store.getters['liveData/get_selectedSubject']
        this.userData = this.$store.getters['user/get_userData']

        this.thresholdMethod = 'overallThreshold'
        this.assessmentMethod = 'rubricOverall'

        this.getCourseOutcomes()
        // this.getProgramOutcomes()
        // this.getProgramSpecificOutcomes()

        // console.log('prop_assessment', this.prop_assessment);
        this.title = this.prop_assessment.title
        this.totalMarks = this.prop_assessment.totalObtainableMarks
        this.description = this.prop_assessment.description
        this.dueDate = this.prop_assessment.deadlineForSubmission.slice(0, 10)
        this.dueTime = this.prop_assessment.time
        this.allowedFileTypes = this.prop_assessment.allowedFileTypes
        if (this.allowedFileTypes.length === this.fileTypes.length) {
            this.allFileTypes = true
        }
        this.assessmentMethod = this.prop_assessment.assessmentMethod
        this.isForNba = this.prop_assessment.isForNBA
        if (this.isForNba) {
            if (this.prop_assessment.nbaAttainmentObject.thresholdMethod === "Overall") {
                this.overallThreshold = this.prop_assessment.nbaAttainmentObject.thresholdPercentage
            }
        }
        if (this.prop_assessment.mappedBlooms) {
            this.mappedBlooms = this.prop_assessment.mappedBlooms
        }
        if (this.prop_assessment.mappedCOs) {
            this.mappedCOs = this.prop_assessment.mappedCOs
        }
        if (this.prop_assessment.questions) {
            this.questionArray = this.prop_assessment.questions
        }
        if (this.prop_assessment.maxMarksForAssessmentCriteria) {
            this.maxMarksForAssessmentCriteria = this.prop_assessment.maxMarksForAssessmentCriteria
        }
        this.maxCriteriaMarks = this.maxMarksForAssessmentCriteria
        // this.maxMarksForAssessmentCriteria

        if (this.prop_assessment.rubricId) {
            this.getRubricDetails()
        }
        const timestamp = new Date();
        this.currentDate = timestamp.toISOString().split("T")[0];

        if (this.prop_assessment.studentMarks.length > 0) {
            this.studentData = this.prop_assessment.studentMarks
            this.studentData.forEach((student) => {
                student.totalMarksOfAssessment = this.prop_assessment.totalObtainableMarks
                student.maxMarksForAssessmentCriteria = this.maxMarksForAssessmentCriteria
            })
        } else {
            await this.getStudentDetails()
        }

        this.getSubmittedAssignmentDetails()
    },
    mounted() {
        const self = this;
        self.canvasContainer = document.getElementById("canvasContainer");
        if (self.canvasContainer.addEventListener) {
            self.canvasContainer.addEventListener(
                "contextmenu",
                function (e) {
                    e.preventDefault();
                },
                false
            );
        } else {
            self.canvasContainer.attachEvent("oncontextmenu", function () {
                window.event.returnValue = false;
            });
        }

        self.mainCard = document.getElementById("mainCard");
    },
    methods: {
        gotoAssessments() {
            this.$router.push({
                name: 'activityAssessments',
                params: {
                    prop_subject: this.prop_subject,
                    prop_type: this.prop_type,
                    prop_groupInfo: this.prop_groupInfo
                }
            })
        },
        async getRubricDetails() {
            const response = await this.assessmentRubricRepositoryInstance.getAssessmentRubricById({
                rubricId: this.prop_assessment.rubricId,
                semId: this.prop_assessment.semId,
                instituteId: this.prop_assessment.instituteId
            })
            this.rubricDetails = response.rubric.rubric
            this.finalRubric = response.rubric.rubric.rubric
            this.rubricName = response.rubric.rubric.rubricName
            response.rubric.rubric.rubric.map((r) => {
                this.assessmentCriteriaArray.push({
                    criteriaTitle: r.criteriaTitle,
                    criteriaDescription: r.criteriaDescription,
                    weightage: r.weightage
                })
            })
            this.assessmentScaleLimits = response.rubric.rubric.rubric[0].limits
            if (this.prop_assessment.rubricId) {
                this.generateStudentMarksTableHeader(this.assessmentCriteriaArray)
                this.displayStudentMarksTable = true
            }
        },
        handleAllFileTypes() {
            if (this.allFileTypes) {
                this.allowedFileTypes = this.fileTypes
            } else {
                this.allowedFileTypes = []
            }
        },
        addQuestion() {
            this.question = ''
            this.subQuestionArray = []
            this.addQuestionDialog = true
        },
        saveQuestion() {
            const tempQuestionObj = {
                number: this.questionArray.length + 1,
                question: this.question,
                subQuestions: this.subQuestionArray
            }
            this.questionArray = [...this.questionArray, tempQuestionObj]

            this.addQuestionDialog = false
        },
        updateQuestion() {
            this.questionArray[this.indexToEditQuestion].number = this.indexToEditQuestion
            this.questionArray[this.indexToEditQuestion].question = this.question
            this.questionArray[this.indexToEditQuestion].subQuestions = this.subQuestionArray
            this.editQuestionDialog = false
        },
        addSubQuestion() {
            const tempSubQuestionObj = {
                number: this.subQuestionArray.length + 1,
                subQuestion: ''
            }
            this.subQuestionArray = [...this.subQuestionArray, tempSubQuestionObj]
        },
        deleteSubQuestion(index) {
            this.subQuestionArray.splice(index, 1)
        },
        deleteQuestion(index) {
            this.questionArray.splice(index, 1)
        },
        editQuestion(item, i) {
            this.question = item.question
            this.subQuestionArray = item.subQuestions
            this.questionToEdit = item
            this.indexToEditQuestion = i
            this.editQuestionDialog = true
        },
        addScaleLimit() {
            this.assessmentScaleLimits.push({
                scaleLabel: '',
                lowerLimit: 0,
                upperLimit: 0
            })
        },
        setUpperLimit() {
            this.tempUpperLimit = 0
            this.tempUpperLimit = this.assessmentScaleLimits[this.assessmentScaleLimits.length - 1].upperLimit
        },
        saveAssessmentScale() {
            const i = this.assessmentScaleLimits[this.assessmentScaleLimits.length - 1].upperLimit
            if (i > this.maxMarksForAssessmentCriteria) {
                this.scaleError = `Upper limit cannot exceed ${this.maxMarksForAssessmentCriteria} marks.`
            } else {
                this.createFinalRubric()
                this.toggleAddAssessmentCriteria = false
                this.assessmentScaleDialog = false
                this.scaleError = ''
            }
        },
        addAssessmentScale() {
            this.assessmentScaleDialog = true
        },
        editAssessmentCriteria(criteria, i) {
            this.editAssessmentCriteriaDialog = true
            this.assessmentCriteriaTitle = criteria.criteriaTitle
            this.assessmentCriteriaWeightage = criteria.weightage
            this.assessmentCriteriaDescription = criteria.criteriaDescription
            this.indexToEditCriteria = i
        },
        updateAssessmentCriteria() {
            this.finalRubric[this.indexToEditCriteria].criteriaTitle = this.assessmentCriteriaTitle
            this.finalRubric[this.indexToEditCriteria].weightage = this.assessmentCriteriaWeightage
            this.finalRubric[this.indexToEditCriteria].criteriaDescription = this.assessmentCriteriaDescription

            this.assessmentCriteriaArray[this.indexToEditCriteria].criteriaTitle = this.assessmentCriteriaTitle
            this.assessmentCriteriaArray[this.indexToEditCriteria].weightage = this.assessmentCriteriaWeightage
            this.assessmentCriteriaArray[this.indexToEditCriteria].criteriaDescription = this.assessmentCriteriaDescription
            this.editAssessmentCriteriaDialog = false

            this.rubricDetails.rubric = this.finalRubric
            this.rubricUpdated = true

            this.handleCriteriaWeightage()
        },
        deleteAssessmentCriteria(i) {
            this.assessmentCriteriaArray.splice(i, 1)
            this.finalRubric.splice(i, 1)
            this.rubricDetails.rubric = this.finalRubric
            this.rubricUpdated = true
        },
        handleCriteriaDescription() {
            this.rubricUpdated = true
        },
        addAssessmentCriteria() {
            this.assessmentCriteriaArray.push({
                criteriaTitle: this.assessmentCriteriaTitle,
                criteriaDescription: this.assessmentCriteriaDescription,
                weightage: this.assessmentCriteriaWeightage
            })

            if (this.assessmentScaleLimits.length > 0) {
                this.createFinalRubric()
            }
            this.assessmentCriteriaTitle = ''
            this.assessmentCriteriaDescription = ''
            this.assessmentCriteriaWeightage = 0
            this.assessmentCriteriaDialog = false
        },
        createFinalRubric() {
            this.finalRubric = []
            this.assessmentCriteriaArray.map((c, ci) => {
                this.finalRubric = [...this.finalRubric, {
                    criteriaTitle: c.criteriaTitle,
                    criteriaDescription: c.criteriaDescription,
                    weightage: c.weightage,
                    limits: this.finalRubric.limits ? this.finalRubric.limits : []
                }]
                this.assessmentScaleLimits.map((l, li) => {
                    this.finalRubric[ci].limits = [...this.finalRubric[ci].limits, {
                        scaleLabel: l.scaleLabel,
                        lowerLimit: l.lowerLimit,
                        upperLimit: l.upperLimit,
                        description: this.finalRubric[ci].description ? this.finalRubric[ci].description : '',
                        highestObtainableMarks: this.maxMarksForAssessmentCriteria
                    }]
                })
            })
            // console.log('this.rubricNameeeeee', this.rubricName)
            this.rubricDetails = {
                rubricName: this.rubricName,
                rubric: this.finalRubric
            }
            this.displayRubricTable = true
        },
        async getStudentDetails() {
            this.studentData = [];
            this.studentDataLoader = true;
            const uIds = [];
            let response;

            const commonParams = {
                instituteId: this.selectedSubject.instituteId,
                semId: this.selectedSubject.semId,
                department: this.selectedSubject.department,
                courseYear: this.selectedSubject.courseYear
            };

            if (this.prop_type === 'Group') {
                const objToPushForStudentList = {
                    ...commonParams,
                    groupName: this.prop_groupInfo.groupName,
                    groupId: this.prop_groupInfo.groupId,
                    subjectId: this.selectedSubject.subjectId
                };

                // console.log('objToPushForStudentList', objToPushForStudentList);
                response = await this.semesterUserRepositoryInstance.getSemesterUsersOfASubjectOfAGroup(objToPushForStudentList);
                // console.log('responseForGroup', response);
            } else {
                response = await this.semesterUserRepositoryInstance.getStudentsOfADivision({
                    ...commonParams,
                    division: this.selectedSubject.division
                });
                if (this.prop_type === 'Batch') {
                    const dummy = response.filter((stu) => stu.batch === this.selectedSubject.batch)
                    response = dummy
                }
            }

            uIds.push(...response.map(r => r.uId));

            const studResponse = await this.userRepositoryInstance.getUsers({ uIds });

            this.studentData = studResponse.map(stud => ({
                uId: stud.uId,
                prnNumber: stud.collegePRNNo,
                fullName: `${stud.firstName} ${stud.middleName} ${stud.lastName}`,
                rollNo: stud.rollNo
            }));

            this.generateStudentMarksTableHeader();
        },
        async generateStudentMarksTableHeader(headerData) {
            this.studentMarksTableHeaders = [{
                text: 'PRN',
                value: 'prnNumber',
                width: '100'
            },
            {
                text: 'Name',
                value: 'fullName',
                width: '200'
            },
            {
                text: 'Total Marks',
                value: 'totalMarksOfAssessment'
            },
            {
                text: 'Maximum marks per criteria',
                value: 'maxMarksPerCriteria'
            },
            {
                text: 'Status',
                value: 'status'
            },
            {
                text: 'Submission Date & Time',
                value: 'submittedOn',
                width: '200'
            },
            {
                text: 'Submitted Files',
                value: 'linksOfAttachments'
            }
            ]
            this.criteriaHeaders = []
            if (headerData) {
                headerData.map((criteria) => {
                    this.criteriaHeaders.push({
                        text: criteria.criteriaTitle,
                        value: criteria.criteriaTitle.toLowerCase(),
                        width: '100'
                    })
                    this.studentMarksTableHeaders.push({
                        text: criteria.criteriaTitle,
                        value: criteria.criteriaTitle.toLowerCase(),
                        width: '100'
                    })
                })
            } else {
                this.assessmentCriteriaArray.map((criteria) => {
                    this.criteriaHeaders.push({
                        text: criteria.criteriaTitle,
                        value: criteria.criteriaTitle.toLowerCase(),
                        width: '100'
                    })
                    this.studentMarksTableHeaders.push({
                        text: criteria.criteriaTitle,
                        value: criteria.criteriaTitle.toLowerCase(),
                        width: '100'
                    })
                })
            }

            this.studentMarksTableHeaders.push(
                {
                    text: 'Total Obtained Marks',
                    value: 'marksObtained',
                    width: '100'
                },
                {
                    text: 'Remark',
                    value: 'remark'
                },
                {
                    text: 'Action',
                    value: 'action',
                    width: '400'
                }
            )
            await this.generateStudentData()
        },
        generateStudentData() {
            if (this.prop_assessment.studentMarks.length === 0) {
                this.studentData.map((student) => {
                    const arr = []
                    this.assessmentCriteriaArray.map((criteria) => {
                        arr.push({
                            criteria: criteria.criteriaTitle,
                            marks: 0,
                            weightage: criteria.weightage
                        })
                    })
                    student.marksObtained = 0
                    student.criteriaMarks = arr
                    student.totalMarksOfAssessment = this.prop_assessment.totalObtainableMarks
                    student.maxMarksForAssessmentCriteria = this.maxMarksForAssessmentCriteria
                    student.remark = 'remark'
                    student.action = 'action'
                    student.instituteId = this.selectedInstituteId
                    student.semId = this.selectedSemester.semId
                    student.assignmentId = this.prop_assessment.assignmentId
                })
            }
            this.studentDataLoader = false
        },
        handleCriteriaWeightage() {
            for (let i = 0; i < this.studentData.length; i++) {
                let sum = 0
                const student = this.studentData[i];
                for (let j = 0; j < student.criteriaMarks.length; j++) {
                    const element = student.criteriaMarks[j];
                    element.weightage = Number(this.finalRubric[j].weightage)
                    sum += (Number(element.marks) * (Number(element.weightage) / 100))
                }
                student.marksObtained = this.roundOffUptoTwoDecimals((sum * this.totalMarks) / this.maxMarksForAssessmentCriteria)
            }
        },
        roundOffUptoTwoDecimals(num) {
            return Math.round(num * 100) / 100
        },
        async calculateTotalMarks(student, i) {
            const tempArr = this.studentData
            let finalTotal = 0
            let sum = 0
            await student.criteriaMarks.map((cri) => {
                sum += (Number(cri.marks) * (Number(cri.weightage) / 100))
            })
            finalTotal = (sum * this.totalMarks) / this.maxMarksForAssessmentCriteria
            student.marksObtained = Math.round(finalTotal * 100) / 100
            this.studentData[i] = student
            this.studentData = [...tempArr]
        },
        async calculateTotalMarksViaExcel(studentExcelResponse) {
            this.studentDataLoader = true
            // console.log('studentExcelResponse', studentExcelResponse);

            studentExcelResponse.map((student) => {
                student.criteriaMarks.map((obj) => {
                    this.assessmentCriteriaArray.map((criteria) => {
                        if (obj.criteria === criteria.criteriaTitle) {
                            obj['weightage'] = criteria.weightage
                        }
                    })
                })

                let finalTotal = 0
                let sum = 0
                student.criteriaMarks.map((cri) => {
                    sum += (Number(cri.marks) * (Number(cri.weightage) / 100))
                })
                finalTotal = (sum * this.totalMarks) / this.maxMarksForAssessmentCriteria
                student.marksObtained = Math.round(finalTotal * 100) / 100
                student.instituteId = this.selectedInstituteId
                student.semId = this.selectedSemester.semId
                student.assignmentId = this.prop_assessment.assignmentId
                student.totalMarksOfAssessment = this.prop_assessment.totalObtainableMarks
                student.maxMarksForAssessmentCriteria = this.maxMarksForAssessmentCriteria
                // student.uId = this.studentData.filter()
                const array = this.studentData.filter(obj => obj.prnNumber === student.prnNumber)
                student.uId = array[0].uId

                this.assignmentUsers.forEach((item) => {
                    if (item.prnNumber === student.prnNumber) {
                        if (student.status === 'Submitted') {
                            student.status = item.status
                            student.submittedOn = new Date(item.submittedOn).toLocaleString()
                            student.attemptNumber = item.attemptNumber
                            student.linksOfAttachments = item.linksOfAttachments
                            student.remarkAttachments = item.remarkAttachments
                        } else {
                            student.status = 'Pending'
                            student.submittedOn = null
                            student.attemptNumber = 0
                            student.linksOfAttachments = []
                        }
                    }
                })
            })
            this.studentData = studentExcelResponse
            await this.getSubmittedAssignmentDetails()
            // console.log('this.studentData via', this.studentData);
            this.studentDataLoader = false
            this.displayStudentMarksTable = true
        },
        uploadMarksViaExcel() {
            this.uploadMarksExcelDialog = true
        },
        async uploadMarksManually() {
            await this.getStudentDetails()
            this.displayStudentMarksTable = true
        },
        async generateMarksSampleExcel() {
            try {
                const pdfBlob =
                    await this.assessmentRepositoryInstance.generateSampleMarksExcel({
                        studentData: this.studentData,
                        criteriaData: this.assessmentCriteriaArray
                    })
                const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
                const url = window.URL.createObjectURL(pdfBlob2)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'Marks Sample.xlsx')
                document.body.appendChild(link)
                link.click()
            } catch (err) {
                console.log('error', err)
            }
        },
        uploadMarksExcel() {
            this.excelUploadSuccess = true
            if (this.studentMarksExcel) {
                var formData = new FormData()
                formData.append('excel', this.studentMarksExcel)
                const url = process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT + '/excel/assessment/parse'
                axios
                    .post(
                        url,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    )
                    .then((response) => {
                        this.calculateTotalMarksViaExcel(response.data.studentData)
                        this.uploadMarksExcelDialog = false
                        this.excelUploadSuccess = false
                    })
            }
            this.studentMarksExcel = []
        },
        handleNbaCriteriaAttainment() {
            if (this.thresholdMethod === 'overallThreshold') {
                this.nbaAttainmentObject.thresholdMethod = 'Overall'
                this.nbaAttainmentObject.thresholdPercentage = this.overallThreshold
            }
        },
        async getCourseOutcomes() {
            try {
                const nbaData = await this.nbaRepositoryInstance.getCos({
                    lmsInstituteId: this.selectedInstituteId,
                    semesterId: this.selectedSemester.semId,
                    subjectId: this.selectedSubject.subjectId
                });
                nbaData.data.map((d) => {
                    this.totalCos.push({
                        coNumber: d.CONumber,
                        coName: d.COName
                    })
                })
            } catch (error) {
                console.log('error', error);
            }
            // this.mappedCos = this.totalCos
        },
        async getProgramOutcomes() {
            try {
                const nbaData = await this.nbaRepositoryInstance.getPos({
                    lmsInstituteId: this.selectedInstituteId
                });
                nbaData.data.map((d) => {
                    this.totalPos.push({
                        poNumber: d.poNumber,
                        poName: d.poName
                    })
                })
            } catch (error) {
                console.log('error', error);
            }
        },
        async uploadAssessmentFiles() {
            this.uploadedAssessmentFiles = await this.uploadFiles(this.attachedFiles);
            // "https://mediainprospect.sfo3.digitaloceanspaces.com/465800c3-eddc-4aea-ac8f-482ccdb3e6de/assessmentFiles/Attendance Report (2)1671865730584.xlsx"
        },
        async uploadFiles(fileData) {
            if (fileData.length === 0) return [];

            const attachedFilePromises = [];
            const attachedFiles = fileData.filter(item => item.name);

            attachedFiles.forEach(attachedFile => {
                attachedFilePromises.push(
                    this.getSignedUrl(attachedFile, `${this.userData.uId}/assessmentFiles/`)
                );
            });
            const signedUrlsData = await Promise.all(attachedFilePromises);
            const signedUrls = signedUrlsData.map(item => item.signedUrl);

            const uploadPromises = [];
            if (signedUrls.length === attachedFiles.length) {
                attachedFiles.forEach((attachedFile, i) => {
                    uploadPromises.push(this.uploadToSpaces(attachedFile, signedUrls[i]));
                });

                await Promise.all(uploadPromises);
                return signedUrlsData.map((item, i) => ({
                    url: spacesUrl + "/" + item.fileName,
                    name: attachedFiles[i].name,
                    mimeType: item.mimeType
                }));
            }
            return [];
        },
        async getSignedUrl(file, path = "") {
            const fileName = path + file.name;
            const body = {
                fileName,
                fileType: file.type
            };
            const signedUrl = await this.assessmentRepositoryInstance.getSignedUrl(
                body
            );
            return signedUrl;
        },
        async uploadToSpaces(file, signedUrl) {
            const res = await this.futch(
                signedUrl,
                {
                    method: "PUT",
                    body: file,
                    headers: {
                        "Content-Type": file.type,
                        "x-amz-acl": "public-read"
                    }
                },
                event => {
                    const progress = parseInt((event.loaded / event.total) * 100);
                    setTimeout(() => {
                        this.currentUploadProgress = progress;
                        if (progress > 99) {
                            this.currentlyUploadingNumber++;
                        }
                    }, 200);
                }
            );
            this.currentUploadProgress = 0;

            return res;
        },
        futch(url, opts = {}, onProgress) {
            return new Promise((resolve, reject) => {
                var xhr = new XMLHttpRequest();
                xhr.open(opts.method || "get", url);
                for (var k in opts.headers || {}) {
                    xhr.setRequestHeader(k, opts.headers[k]);
                }
                xhr.onload = e => resolve(e.target.responseText);
                xhr.onerror = reject;
                if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
                xhr.send(opts.body);
            });
        },
        removeCo(index) {
            this.mappedCOs.splice(index, 1)
        },
        removeBloom(index) {
            this.mappedBlooms.splice(index, 1)
        },
        async updateBasicDetails() {
            this.updateBasicDetailsLoader = true;

            // Upload assessment files if there are any
            if (this.attachedFiles.length > 0) {
                await this.uploadAssessmentFiles();
            }

            const commonBasicDetails = {
                instituteId: this.selectedInstituteId,
                semId: this.selectedSemester.semId,
                department: this.selectedSubject.department,
                courseYear: this.selectedSubject.courseYear,
                subjectId: this.selectedSubject.subjectId,
                assignmentId: this.prop_assessment.assignmentId,
                title: this.title,
                description: this.description,
                totalObtainableMarks: this.totalMarks,
                deadlineForSubmission: this.dueDate,
                time: this.dueTime,
                linksOfAttachments: this.uploadedAssessmentFiles,
                questions: this.questionArray,
                isForNBA: this.isForNba,
                allowedFileTypes: this.allowedFileTypes,
                assessmentMethod: this.assessmentMethod
            };

            if (this.prop_type === 'Group') {
                commonBasicDetails.groupId = this.prop_groupInfo.groupId;
            }
            if (this.prop_type === 'Division') {
                commonBasicDetails.division = this.selectedSubject.division;
                // commonBasicDetails.batch = this.selectedSubject.batch;
            }
            if (this.prop_type === 'Batch') {
                commonBasicDetails.division = this.selectedSubject.division;
                commonBasicDetails.batch = this.selectedSubject.batch;
            }

            if (this.isForNba) {
                await this.handleNbaCriteriaAttainment();
                commonBasicDetails.nbaAttainmentObject = this.nbaAttainmentObject;
                commonBasicDetails.mappedCOs = this.mappedCOs;
                commonBasicDetails.mappedBlooms = this.mappedBlooms;
            }

            // console.log('updateBasicDetailsObject', commonBasicDetails);

            try {
                // await this.assessmentRepositoryInstance.updateAssessmentDetails(commonBasicDetails)
                await this.assignmentRepositoryInstance.updateAssignment(commonBasicDetails);

                showStatus('Basic details updated successfully.', 5000, 'success', this);
                this.updateBasicDetailsLoader = false;
            } catch (error) {
                showStatus('Error while updating basic details of assessment.', 5000, 'error', this);
                console.log('error', error);
                this.updateBasicDetailsLoader = false;
            }
        },
        async updateStudentMarks() {
            this.updateStudentMarksLoader = true;

            // Remove unnecessary properties from each student data
            this.studentData.forEach(stud => {
                delete stud.totalMarksOfAssessment;
                delete stud.maxMarksForAssessmentCriteria;
            });

            const commonStudentMarks = {
                instituteId: this.selectedInstituteId,
                semId: this.selectedSemester.semId,
                department: this.selectedSubject.department,
                courseYear: this.selectedSubject.courseYear,
                subjectId: this.selectedSubject.subjectId,
                assignmentId: this.prop_assessment.assignmentId,
                studentMarks: this.studentData
            };

            if (this.prop_type === 'Group') {
                commonStudentMarks.groupId = this.prop_groupInfo.groupId;
            }
            if (this.prop_type === 'Division') {
                commonStudentMarks.division = this.selectedSubject.division;
                // commonStudentMarks.batch = this.selectedSubject.batch;
            }
            if (this.prop_type === 'Batch') {
                commonStudentMarks.division = this.selectedSubject.division;
                commonStudentMarks.batch = this.selectedSubject.batch;
            }

            // console.log('this.studentData', this.studentData);

            if (this.rubricUpdated) {
                commonStudentMarks.isRubricUpdated = true;
                commonStudentMarks.rubric = this.finalRubric;
            } else {
                commonStudentMarks.isRubricUpdated = false;
            }

            try {
                if (this.rubricUpdated) {
                    await this.updateAssessmentRubric();
                }

                await this.assignmentRepositoryInstance.updateAssignment(commonStudentMarks);
                await this.assignmentUserRepositoryInstance.bulkUpdateUserAssignment(this.studentData);

                // console.log('this.rubricUpdated', this.rubricUpdated);
                showStatus('Student details updated successfully.', 5000, 'success', this);
                this.updateStudentMarksLoader = false;
            } catch (error) {
                showStatus('Error while updating student details.', 5000, 'error', this);
                console.log('error', error);
                this.updateStudentMarksLoader = false;
            }
        },
        async updateAssessmentRubric() {
            this.rubricDetails = {
                rubricName: this.rubricName,
                rubric: this.finalRubric
            }
            // console.log('this.rubricName', this.rubricName)
            const rubricObj = {
                instituteId: this.selectedInstituteId,
                semId: this.selectedSemester.semId,
                rubricId: this.prop_assessment.rubricId,
                rubric: this.rubricDetails
            }
            try {
                await this.assessmentRubricRepositoryInstance.updateAssessmentRubricDetails(rubricObj)
            } catch (error) {
                console.log(error);
            }
        },
        async getSubmittedAssignmentDetails() {
            const commonParams = {
                instituteId: this.selectedInstituteId,
                semId: this.selectedSemester.semId,
                assignmentId: this.prop_assessment.assignmentId,
                department: this.selectedSubject.department,
                courseYear: this.selectedSubject.courseYear,
                subjectId: this.selectedSubject.subjectId
            };

            try {
                let assignmentUsers;
                if (this.prop_type === 'Group') {
                    assignmentUsers = await this.assignmentUserRepositoryInstance.getAssignmentUsersOfAnAssignmentForAGroup({
                        ...commonParams,
                        groupId: this.prop_groupInfo.groupId
                    });
                } else {
                    if (this.prop_type === 'Division') {
                        commonParams.division = this.selectedSubject.division
                    }
                    if (this.prop_type === 'Batch') {
                        commonParams.division = this.selectedSubject.division
                        commonParams.batch = this.selectedSubject.batch
                    }
                    assignmentUsers = await this.assignmentUserRepositoryInstance.getAssignmentUsersOfAnAssignmentForADivision({
                        ...commonParams
                    });
                }

                this.studentData.forEach((stud) => {
                    const submittedAssignments = assignmentUsers.find(u => u.prnNumber === stud.prnNumber && (u.status === 'Submitted' || u.status === 'Completed'));
                    if (submittedAssignments) {
                        stud.status = submittedAssignments.status;
                        stud.submittedOn = new Date(submittedAssignments.submittedOn).toLocaleString();
                        stud.attemptNumber = submittedAssignments.attemptNumber;
                        stud.linksOfAttachments = submittedAssignments.linksOfAttachments;
                        stud.remarkAttachments = submittedAssignments.remarkAttachments;
                        stud.fileEndpoint = typeof (submittedAssignments.linksOfAttachments[0].submittedFile) === 'object' ? 'DO' : 'FB';
                    } else {
                        stud.status = 'Pending';
                        stud.attemptNumber = 0;
                        stud.submittedOn = null;
                        stud.linksOfAttachments = [];
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        addRemark(item) {
            this.studentToEvaluate = item
            // console.log('kjsfbvljebf', subject.studentData.reduce((total, obj) => total += Number(obj[que.questionNumber]), 0));

            this.rubricAsRemark = []
            this.finalRubric.forEach((criteria) => {
                this.studentToEvaluate.criteriaMarks.forEach((item) => {
                    if (item.criteria === criteria.criteriaTitle) {
                        criteria.limits.forEach((limit) => {
                            if (Number(item.marks) >= Number(limit.lowerLimit) && Number(item.marks) <= Number(limit.upperLimit)) {
                                this.rubricAsRemark.push({
                                    scaleDescription: limit.description,
                                    criteriaTitle: criteria.criteriaTitle,
                                    criteriaDescription: criteria.criteriaDescription,
                                    criteriaMarks: item.marks,
                                    maxCriteriaMarks: this.studentToEvaluate.maxMarksForAssessmentCriteria
                                })
                            }
                        })
                    }
                })
            })
            // console.log('this.studentData', this.studentData);

            this.addRemarkDialog = true
        },
        async updateResubmit(item, index) {
            this.resubmitStatusLoader = true
            const objToPush = {
                instituteId: this.selectedInstituteId,
                semId: this.selectedSemester.semId,
                uId: item.uId,
                assignmentId: this.prop_assessment.assignmentId,
                status: "Rejected"
            }
            await this.assignmentUserRepositoryInstance.updateUserAssignment(
                objToPush
            );
            this.getSubmittedAssignmentDetails()
            this.resubmitStatusLoader = false
        },
        async updateCompleted(item) {
            this.completedStatusLoader = true
            const objToPush = {
                instituteId: this.selectedInstituteId,
                semId: this.selectedSemester.semId,
                uId: item.uId,
                assignmentId: this.prop_assessment.assignmentId,
                status: "Completed"
            }
            await this.assignmentUserRepositoryInstance.updateUserAssignment(
                objToPush
            );
            this.getSubmittedAssignmentDetails()
            this.completedStatusLoader = false
        },
        splitStoreUrl(url) {
            // console.log('url', url)s;
            if (url !== undefined && typeof (url) !== 'object') {
                return url.split("________")[1].split("?")[0].replaceAll("%", '')
            } else {
                return ''
            }
        },
        async showCanvas(itemFile, itemLink, assignmentUser) {
            this.tempStud = assignmentUser
            const file = itemFile.submittedFile.url
            const link = itemLink.url
            const tempType = itemLink.mimeType
            let contentType = ''

            if (tempType === 'jpeg' || tempType === 'jpg' || tempType === 'png') {
                contentType = 'Image'
            }
            //TODO: add a middle function in between to first
            //check if the file is image or not with a more
            //generic name eg: openFile or handleFileClick
            if (contentType !== 'Image') {
                window.open(link, "_blank");
                return;
            }
            this.showCanvasDialog = false;
            this.tempStroke = [];
            this.strokes = [];
            const self = this;
            self.tempFile = file;
            self.tempLink = link;
            self.tempAssignmentUser = assignmentUser;

            self.canvasContainer.innerHTML = "";
            const background = new Image();
            background.src = self.tempLink;
            background.setAttribute("crossorigin", "anonymous");
            const width = self.mainCard.offsetWidth - 20;
            background.onload = function () {
                self.background = background;
                self.canvas = document.createElement("canvas");
                self.ctx = self.canvas.getContext("2d");
                self.oc = document.createElement("canvas");
                self.octx = self.oc.getContext("2d");
                self.canvas.width = width;
                self.canvas.height = (self.canvas.width * this.height) / this.width;
                var cur = {
                    width: Math.floor(this.width * 0.5),
                    height: Math.floor(this.height * 0.5)
                };
                self.oc.width = cur.width;
                self.oc.height = cur.height;
                self.octx.drawImage(this, 0, 0, cur.width, cur.height);
                while (cur.width * 0.5 > width) {
                    cur = {
                        width: Math.floor(cur.width * 0.5),
                        height: Math.floor(cur.height * 0.5)
                    };
                    self.octx.drawImage(
                        self.oc,
                        0,
                        0,
                        cur.width * 2,
                        cur.height * 2,
                        0,
                        0,
                        cur.width,
                        cur.height
                    );
                }
                self.ctx.drawImage(
                    self.oc,
                    0,
                    0,
                    cur.width,
                    cur.height,
                    0,
                    0,
                    self.canvas.width,
                    self.canvas.height
                );
                self.canvasContainer.appendChild(self.canvas);
                self.showCanvasDialog = true;
                self.ctx.lineCap = "square";
                self.ctx.lineWidth = 5;
                self.ctx.globalCompositeOperation = "source-over";
                let lastPos = false;
                let isDown = false;
                self.canvas.onpointerdown = function (e) {
                    e.preventDefault();
                    isDown = true;
                    self.tempStroke = [];
                    lastPos = getPos(e);
                    self.ctx.strokeStyle = "red";
                };
                self.canvas.onpointermove = function (e) {
                    e.preventDefault();
                    if (!isDown) return;
                    var pos = getPos(e);
                    self.ctx.beginPath();
                    self.ctx.moveTo(lastPos.x, lastPos.y);
                    self.ctx.lineTo(pos.x, pos.y);
                    self.tempStroke.push({
                        lastX: lastPos.x,
                        lastY: lastPos.y,
                        posX: pos.x,
                        posY: pos.y
                    });
                    self.ctx.stroke();
                    lastPos = pos;
                };
                self.canvas.onpointerup = function (e) {
                    e.preventDefault();
                    self.strokes.push(self.tempStroke);
                    isDown = false;
                };
                self.canvas.touchstart = function (e) {
                    e.preventDefault();
                    isDown = true;
                    self.tempStroke = [];
                    lastPos = getPos(e);
                    self.ctx.strokeStyle = "red";
                };
                self.canvas.touchmove = function (e) {
                    e.preventDefault();
                    if (!isDown) return;
                    var pos = getPos(e);
                    self.ctx.beginPath();
                    self.ctx.moveTo(lastPos.x, lastPos.y);
                    self.ctx.lineTo(pos.x, pos.y);
                    self.tempStroke.push({
                        lastX: lastPos.x,
                        lastY: lastPos.y,
                        posX: pos.x,
                        posY: pos.y
                    });
                    self.ctx.stroke();
                    lastPos = pos;
                };
                self.canvas.touchend = function (e) {
                    e.preventDefault();
                    self.strokes.push(self.tempStroke);
                    isDown = false;
                };
                function getPos(e) {
                    var rect = self.canvas.getBoundingClientRect();
                    return { x: e.clientX - rect.left, y: e.clientY - rect.top };
                }
            };
            window.scroll({
                top: 1364,
                behavior: "smooth"
            });
        },
        async undoStroke() {
            const self = this;
            this.showCanvasDialog = true;
            self.canvasContainer.innerHTML = "";
            const width = self.mainCard.offsetWidth - 20;
            const background = new Image();
            background.src = self.tempLink;
            background.setAttribute("crossorigin", "anonymous");
            background.onload = function () {
                self.background = background;
                self.canvas = document.createElement("canvas");
                self.ctx = self.canvas.getContext("2d");
                self.oc = document.createElement("canvas");
                self.octx = self.oc.getContext("2d");
                self.canvas.width = width;
                self.canvas.height = (self.canvas.width * this.height) / this.width;
                var cur = {
                    width: Math.floor(this.width * 0.5),
                    height: Math.floor(this.height * 0.5)
                };
                self.oc.width = cur.width;
                self.oc.height = cur.height;
                self.octx.drawImage(this, 0, 0, cur.width, cur.height);
                while (cur.width * 0.5 > width) {
                    cur = {
                        width: Math.floor(cur.width * 0.5),
                        height: Math.floor(cur.height * 0.5)
                    };
                    self.octx.drawImage(
                        self.oc,
                        0,
                        0,
                        cur.width * 2,
                        cur.height * 2,
                        0,
                        0,
                        cur.width,
                        cur.height
                    );
                }
                self.ctx.drawImage(
                    self.oc,
                    0,
                    0,
                    cur.width,
                    cur.height,
                    0,
                    0,
                    self.canvas.width,
                    self.canvas.height
                );
                self.canvasContainer.appendChild(self.canvas);
                self.showCanvasDialog = true;
                self.ctx.lineCap = "square";
                self.ctx.lineWidth = 5;
                self.ctx.globalCompositeOperation = "source-over";
                let lastPos = false;
                let isDown = false;
                self.strokes.pop();
                // self.strokes.pop()
                if (self.strokes.length > 0) {
                    for (let i = 0; i < self.strokes.length; i++) {
                        for (let j = 0; j < self.strokes[i].length; j++) {
                            const str = self.strokes[i][j];
                            self.ctx.strokeStyle = "red";
                            self.ctx.beginPath();
                            self.ctx.moveTo(str.lastX, str.lastY);
                            self.ctx.lineTo(str.posX, str.posY);
                            self.ctx.stroke();
                        }
                    }
                }
                self.canvas.onpointerdown = function (e) {
                    e.preventDefault();
                    isDown = true;
                    self.tempStroke = [];
                    lastPos = getPos(e);
                    self.ctx.strokeStyle = "red";
                };
                self.canvas.onpointermove = function (e) {
                    e.preventDefault();
                    if (!isDown) return;
                    var pos = getPos(e);
                    self.ctx.beginPath();
                    self.ctx.moveTo(lastPos.x, lastPos.y);
                    self.ctx.lineTo(pos.x, pos.y);
                    self.tempStroke.push({
                        lastX: lastPos.x,
                        lastY: lastPos.y,
                        posX: pos.x,
                        posY: pos.y
                    });
                    self.ctx.stroke();
                    lastPos = pos;
                };
                self.canvas.onpointerup = function (e) {
                    e.preventDefault();
                    self.strokes.push(self.tempStroke);
                    isDown = false;
                };
                self.canvas.touchstart = function (e) {
                    e.preventDefault();
                    isDown = true;
                    self.tempStroke = [];
                    lastPos = getPos(e);
                    self.ctx.strokeStyle = "red";
                };
                self.canvas.touchmove = function (e) {
                    e.preventDefault();
                    if (!isDown) return;
                    var pos = getPos(e);
                    self.ctx.beginPath();
                    self.ctx.moveTo(lastPos.x, lastPos.y);
                    self.ctx.lineTo(pos.x, pos.y);
                    self.tempStroke.push({
                        lastX: lastPos.x,
                        lastY: lastPos.y,
                        posX: pos.x,
                        posY: pos.y
                    });
                    self.ctx.stroke();
                    lastPos = pos;
                };
                self.canvas.touchend = function (e) {
                    e.preventDefault();
                    self.strokes.push(self.tempStroke);
                    isDown = false;
                };
                function getPos(e) {
                    var rect = self.canvas.getBoundingClientRect();
                    return { x: e.clientX - rect.left, y: e.clientY - rect.top };
                }
            };
        },
        async saveImage() {
            this.checkFileLoader = true
            this.loading = true;
            const imageBase = this.canvas.toDataURL("image/png");
            const image = new Image();
            image.src = imageBase;
            const file = this.dataURLtoFile(imageBase, "checkedAssignment.png");
            const checkedFile = await this.uploadFiles([file]);
            await this.checkAssignment(checkedFile)
            this.getSubmittedAssignmentDetails()
            this.checkFileLoader = false
            this.cancelEditing();
        },
        async cancelEditing() {
            this.canvasContainer.innerHTML = "";
            this.showCanvasDialog = false;
            this.tempStroke = [];
            this.strokes = [];
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(",");
            var mime = arr[0].match(/:(.*?);/)[1];
            var bstr = atob(arr[1]);
            var n = bstr.length;
            var u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        },
        async checkAssignment(checkedFile) {
            this.tempAssignmentUser.linksOfAttachments.forEach((file) => {
                if (file.submittedFile.url === this.tempFile) {
                    file.checkedFile = checkedFile[0];
                }
            });
            try {
                const objToPush = {
                    instituteId: this.selectedInstituteId,
                    semId: this.selectedSemester.semId,
                    uId: this.tempAssignmentUser.uId,
                    assignmentId: this.prop_assessment.assignmentId,
                    attemptNumber: this.tempAssignmentUser.attemptNumber,
                    linksOfAttachments: this.tempAssignmentUser.linksOfAttachments || []
                };
                await this.assignmentUserRepositoryInstance.checkAssignment(objToPush);
            } catch (err) {
                console.log(err);
                showStatus("Error updating marks", 1000, "error", this);
            }
        }
    }
}
</script>

<style scoped src="./activityEditAssessment.css"></style>
