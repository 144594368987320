<template src='./activityCreateAssignments.html'></template>

<script>
import auth from '../../Services/Firebase/auth'
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
import { formatToSlashSepearated, convertUTCDateToLocalDate, convertDateTimeToYYYYMMDD, convertDate } from '../../Services/Utils/DateTimeFormatter'
import AssignmentUserRepository from '../../Repository/AssignmentUser'
import AssignmentRepository from '../../Repository/Assignment'
import SemesterUserRepository from '../../Repository/SemesterUser'
import NbaRepository from "../../Repository/NBA";
import UserRepository from '../../Repository/User'
import { generateJsonFromExcel, generateFromJson } from "../../utils/excel"
import showStatus from '../../NetworkManager/showStatus'
var firestorage = null

export default {
  name: 'activityCreateAssignments',
  components: {
    inputContainer,
    smallButton
  },
  props: ['prop_subject', 'prop_type', 'prop_groupInfo', 'prop_assignment'],
  watch: {
    uploadTask: function () {
      this.uploadTask.on('state_changed', sp => {
        this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
      },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploadEnd = true
            // console.log('hello', downloadURL)
            this.downloadURLs.push(downloadURL)
            this.uploadCount++
            if (this.uploadCount === this.files.length) {
              this.createAssignment()
            } else {
              this.progressUpload = 0
              this.upload(this.uploadCount)
            }
          })
        })
    },
    uploadTask2: function () {
      // console.log('state_changed', this.uploadTask2);
      this.uploadTask2.on('state_changed', sp => {
        this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
      },
        null,
        () => {
          this.uploadTask2.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploadEnd = true
            // console.log('updated download url', downloadURL)
            this.downloadURLs2.push(downloadURL)
            this.uploadCount++
            if (this.uploadCount === this.editFiles.length) {
              this.updateAssignment()
            } else {
              this.progressUpload = 0
              this.upload2(this.uploadCount)
            }
          })
        })
    }
  },
  data() {
    return {
      deleteQuestionDialog: false,
      questionItemToDelete: null,
      disabledRefreshBtn: false,
      currentAssignmentLoader: false,
      pastAssignmentLoader: false,
      menu2: false,
      tempThreshold: '',
      compulsoryFileTypes: [],
      time: null,
      publishTime: null,
      selectedStudentListForEdit: [],
      dummyIdsArray: [],
      editSelectedStudent: [],
      studentListArray: [],
      selectedStudentForAssignment: [],
      searchStudent: '',
      headersForStudentList: [
        {
          text: 'Roll Number',
          value: 'rollNo',
          align: 'left'
        },
        {
          text: 'Name Of Student',
          value: 'name',
          align: 'left'
        }
      ],
      addCos: false,
      addBlooms: false,
      headersForQuestionsList: [
        {
          text: 'Q.No',
          value: 'sn',
          align: 'center'
        },
        {
          text: 'Question',
          value: 'question',
          align: 'center'
        },
        {
          text: 'Marks',
          value: 'marks',
          align: 'center'
        },
        {
          text: 'COs Mapped',
          value: 'cos',
          align: 'center'
        },
        {
          text: 'Blooms',
          value: 'blooms',
          align: 'center'
        },
        {
          text: 'Action',
          value: 'action',
          align: 'center'
        }
      ],
      passingMarks: '',
      isMobileView: false,
      editAssignment: false,
      tempisForNBA: false,
      editFilesChanged: false,
      files: [],
      editFiles: [],
      uploadTask: '',
      uploadTask2: '',
      editTitle: '',
      assignmentIdToEdit: '',
      editDueDate: '',
      editDescription: '',
      editMarks: '',
      uploadEnd: false,
      editisForNBA: false,
      downloadURLs: [],
      courseOutcomeData: [],
      downloadURLs2: [],
      storeUrlSplit: '',
      uploadCount: 0,
      fileName: '',
      uploading: false,
      addingAssignment: false,
      tempTitle: '',
      tempDescription: '',
      tempMarks: 0,
      tempDueDate: '',
      tempPublishDate: '',
      filesUpload: '',
      pastAssignments: [],
      currentAssignments: [],
      progressUpload: 0,
      headers: [
        {
          text: 'Name Of the Assignment',
          value: 'title',
          width: "30%"
        },
        {
          text: "Allowed File Types",
          value: "allowedFileTypes",
          width: "20%"
        },
        {
          text: 'Published On',
          value: 'dateOfAssignment',
          width: "15%"
        },
        {
          text: 'Due',
          value: 'deadlineForSubmission',
          width: "15%"
        },
        {
          text: "Time",
          value: "time",
          width: "120px",
          align: "left"
        },
        {
          text: 'Edit',
          value: 'edit',
          width: "10%"
        },
        {
          text: 'Delete',
          value: 'delete',
          width: "10%"
        },
        {
          text: '',
          value: 'check',
          width: "15%"
        }
      ],
      loading: false,
      isInvalidMarks: false,
      isInvalidPercentage: false,
      passingPercentage: '',
      selectedSubject: {},
      allowedFileTypes: [],
      questionsList: [],
      selectedCos: [],
      headersForBlooms: [
        {
          text: 'Short-Code',
          value: 'shortCode'
        },
        {
          text: 'Name',
          value: 'name'
        }
      ],
      headersForCos: [
        {
          text: 'Number',
          value: 'coNumber'
        },
        {
          text: 'COs',
          value: 'coName'
        }
      ],
      selectedBlooms: [],
      allBlooms: [
        {
          name: "CREATE (C)",
          shortCode: "C",
          description:
            "Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)"
        },
        {
          name: "EVALUATE (E)",
          shortCode: "E",
          description:
            "Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)"
        },
        {
          name: "ANALYZE (A)",
          shortCode: "A",
          description:
            "Draw connections among ideas (Verbs : differentiate, organise, relate, compare, contrast, distinguish, examine, experiment, question, test)"
        },
        {
          name: "APPLY (Ap)",
          shortCode: "Ap",
          description:
            "Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)"
        },
        {
          name: "UNDERSTAND (U)",
          shortCode: "U",
          description:
            "Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)"
        },
        {
          name: "REMEMBER (R)",
          shortCode: "R",
          description:
            "Recall facts and basic concepts (Verbs : define, duplicate, list, memorise, repeat, state)"
        }
      ],
      isForAttainment: false,
      isAddQuestions: false,
      isEditingAssignment: false,
      isInvalidQuestionsMarks: false,
      editallowedFileTypes: [],
      type: '',
      currentQuestion: {},
      selectedQuestion: null,
      isDirectMarksEditDisabled: false,
      uploadQuestionExcelDialog: false,
      quizQuestionExcelFile: null,
      excelLink: "",
      formattedDueDate: '',
      formattedPublishedDate: '',
      modal: false,
      modal1: false
    }
  },
  async created() {
    this.type = this.prop_type
    const dueDate = new Date(this.prop_assignment?.deadlineForSubmission);
    const month = (dueDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed in JavaScript
    const day = dueDate.getDate().toString().padStart(2, '0');
    const year = dueDate.getFullYear();

    this.formattedDueDate = `${year}-${month}-${day}`;

    const publishedDate = new Date(this.prop_assignment?.dateOfAssignment);
    const month1 = (publishedDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed in JavaScript
    const day1 = publishedDate.getDate().toString().padStart(2, '0');
    const year1 = publishedDate.getFullYear();

    this.formattedPublishedDate = `${year1}-${month1}-${day1}`;
    console.log('this.formattedPublishedDate', this.formattedPublishedDate, this.formattedDueDate)
    this.$store.commit('liveData/set_selectedActivityName', 'Assignments Creation')
    this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    const firebase = auth.getFirebaseObject()
    firestorage = firebase.storage()
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.userData = this.$store.getters['user/get_userData']
    // console.log('this.userData', this.userData);
    this.assignmentRepositoryInstance = new AssignmentRepository(this)
    this.assignmentUserRepositoryInstance = new AssignmentUserRepository(this)
    this.SemesterUserRepository = new SemesterUserRepository(this)
    this.UserRepository = new UserRepository(this)
    this.nbaRepositoryInstance = new NbaRepository(this);
    if (this.prop_subject) {
      this.selectedSubject = JSON.parse(JSON.stringify(this.prop_subject))
    } else {
      this.selectedSubject = JSON.parse(JSON.stringify(this.$store.getters['liveData/get_selectedSubject']))
    }
    // console.log('this.selectedSubject', this.selectedSubject);
    this.pastAssignments = []
    this.currentAssignments = []
    await this.fetchData()
    if (this.prop_assignment) {
      this.syncDataForUpdate()
      this.isEditingAssignment = true
    }
  },
  methods: {
    openDeleteDialog(item) {
      this.questionItemToDelete = item;
      this.deleteQuestionDialog = true;
    },
    closeDeleteDialog() {
      this.questionItemToDelete = null;
      this.deleteQuestionDialog = false;
    },
    syncDataForUpdate() {
      this.tempTitle = this.prop_assignment.title
      this.isAddQuestions = 'true'
      this.tempDescription = this.prop_assignment.description
      this.downloadURLs = this.prop_assignment.linksOfAttachments
      this.tempDueDate = this.formattedDueDate
      this.tempPublishDate = this.formattedPublishedDate
      this.tempMarks = this.prop_assignment.totalObtainableMarks
      this.passingMarks = this.prop_assignment.passingMarks
      this.allowedFileTypes = this.prop_assignment.allowedFileTypes
      this.compulsoryFileTypes = this.prop_assignment.compulsoryFileTypes
      this.isForAttainment = this.prop_assignment.isForNBA
      this.tempThreshold = this.prop_assignment.nbaAttainmentObject?.thresholdPercentage
      this.assignedFacultyList = this.prop_assignment.assignedFacultyList
      this.time = this.prop_assignment.time
      this.passingPercentage = this.prop_assignment.passingPercentage
      const selectedStudentUids = this.prop_assignment.assignedStudentList
      this.studentListArray.map((e) => {
        if (selectedStudentUids.includes(e.uId)) {
          this.selectedStudentForAssignment.push(e)
        }
      })
      this.questionsList = this.prop_assignment.questions?.map((obj) => {
        if (!obj.cos) {
          obj.cos = []
        }
        if (!obj.blooms) {
          obj.blooms = []
        }
        return obj
      })
    },
    gotoBack() {
      this.$router.push({
        name: 'activityAssignments',
        params: {
          prop_subject: this.selectedSubject,
          prop_assignment: this.prop_assignment,
          prop_type: this.prop_type,
          prop_groupInfo: this.prop_groupInfo
        }
      })
    },
    handleSelectCos() {
      const obj = { ...this.currentQuestion }
      obj.cos = this.selectedCos
      this.$set(this.questionsList, this.selectedQuestion, obj);
      this.selectedQuestion = null
      this.selectedCos = []
      this.addCos = false
    },
    async handleParseQuestionQuizExcel() {
      this.questionsList = []
      const schema = {
        Sheet1: {
          ' Question ': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'question'
          },
          ' marks ': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'marks'
          }
        }
      }

      const res = await generateJsonFromExcel(this.quizQuestionExcelFile, schema, false);
      const { data } = res;
      const { Sheet1 } = data;
      let tempTotalMarks = 0;
      if (Array.isArray(Sheet1)) {
        for (let i = 0; i < Sheet1.length; i++) {
          if (Sheet1[i].marks) tempTotalMarks += +Sheet1[i].marks;
        }
      }
      if (tempTotalMarks > this.tempMarks) {
        showStatus(`The sum of marks of all question should not exceed the value of total marks`, 1000, 'error', this);
        return;
      }
      this.questionsList = [...Sheet1];
      this.uploadQuestionExcelDialog = false;
    },
    async handleGenerateExcel() {
      const schema = {
        Sheet1: {
          ' Question ': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'question'
          },
          ' marks ': {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'marks'
          }
        }
      }
      this.excelLink = await generateFromJson(schema)
    },
    async openExcelQuestionUploadModal() {
      await this.handleGenerateExcel();
      this.uploadQuestionExcelDialog = true
    },
    handleSelectBlooms() {
      const obj = { ...this.currentQuestion }
      obj.blooms = this.selectedBlooms
      this.$set(this.questionsList, this.selectedQuestion, obj);
      this.selectedQuestion = null
      this.selectedBlooms = []
      this.addBlooms = false
    },
    addingCos(item) {
      this.selectedQuestion = this.questionsList.indexOf(item)
      this.currentQuestion = this.questionsList[this.selectedQuestion]
      this.selectedCos = item.cos?.length > 0 ? item.cos : []
      this.getCourseOutcome()
      this.addCos = true
    },
    addingBlooms(item) {
      this.selectedQuestion = this.questionsList.indexOf(item)
      this.currentQuestion = this.questionsList[this.selectedQuestion]
      this.selectedBlooms = item.blooms?.length > 0 ? item.blooms : []
      this.addBlooms = true
    },
    deleteQuestion(obj) {
      const i = this.questionsList.indexOf(obj)
      this.questionsList.splice(i, 1)
      this.closeDeleteDialog()
    },
    addNewQuestion() {
      this.questionsList.push({})
    },
    async getCourseOutcome() {
      try {
        this.courseOutcomeData = [];
        // this.COFromNba = [];
        // this.assignedCoArray = [];
        const cos = await this.nbaRepositoryInstance.getCos({
          lmsInstituteId: this.selectedInstitute,
          semesterId: this.selectedSemester.semId,
          subjectId: this.prop_subject.subjectId
        });
        if (Array.isArray(cos.data)) {
          cos.data.forEach((nba) => {
            this.courseOutcomeData.push({
              id: nba._id,
              coNumber: nba.CONumber,
              coName: nba.COName
            });
          });
        } else {
          console.log("No COs Added")
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchData() {
      this.disabledRefreshBtn = true
      this.currentAssignmentLoader = true
      this.pastAssignmentLoader = true
      this.currentAssignments = []
      this.pastAssignments = []
      let assignmentsList = []
      let result = []
      try {
        const obj = {
          instituteId: this.selectedSubject.instituteId,
          semId: this.selectedSubject.semId,
          subjectId: this.selectedSubject.subjectId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear
        }
        if (this.prop_type === "Group") {
          obj.groupId = this.prop_groupInfo.groupId
          result = await this.assignmentRepositoryInstance.getAssignmentsByAFacultyForASubjectForAGroup(obj)
        } else {
          result = await this.assignmentRepositoryInstance.getAssignmentsByAFacultyForASubjectForADivision(this.selectedSubject)
        }
        // console.log('result', result);
        assignmentsList = result.filter(item => item.assignmentType === 'Normal')
        // console.log('assignmentsList1', assignmentsList);
        let dummyArrayToFilterAssignment = []
        dummyArrayToFilterAssignment = assignmentsList
        // console.log('dummyArrayToFilterAssignment1', dummyArrayToFilterAssignment);
        dummyArrayToFilterAssignment.map((assignment, index) => {
          if (assignment.assignedFacultyList.length > 0) {
            if (!assignment.assignedFacultyList.includes(this.userData.uId)) {
              dummyArrayToFilterAssignment.splice(index, 1)
            }
          }
        })
        assignmentsList = dummyArrayToFilterAssignment
        // console.log('dummyArrayToFilterAssignment', dummyArrayToFilterAssignment);
        // console.log('assignmentsList', assignmentsList);
      } catch (err) {
        console.log(err)
        assignmentsList = []
      }

      assignmentsList.reverse()

      const currentDate = new Date()

      for (let i = 0; i < assignmentsList.length; i++) {
        assignmentsList[i].deadlineForSubmission = new Date(assignmentsList[i].deadlineForSubmission)
        assignmentsList[i].dateOfAssignment = new Date(assignmentsList[i].dateOfAssignment)
        if (assignmentsList[i].deadlineForSubmission > currentDate) {
          this.currentAssignments.push(assignmentsList[i])
        } else {
          this.pastAssignments.push(assignmentsList[i])
        }
      }
      try {
        const objToPushForStudentList = {
          instituteId: this.selectedSubject.instituteId,
          semId: this.selectedSubject.semId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear,
          subjectId: this.selectedSubject.subjectId
        };

        if (this.prop_type === "Division") {
          objToPushForStudentList.division = this.selectedSubject.division;
        }
        if (this.prop_type === "Batch") {
          objToPushForStudentList.division = this.selectedSubject.division;
          objToPushForStudentList.batch = this.selectedSubject.batch;
        }
        if (this.prop_type === "Group") {
          objToPushForStudentList.groupName = this.prop_groupInfo.groupName;
          objToPushForStudentList.groupId = this.prop_groupInfo.groupId;
        }

        // console.log('objToPushForStudentList', objToPushForStudentList);
        let response = await (this.prop_type === "Division" || this.prop_type === "Batch"
          ? this.SemesterUserRepository.getSemesterUsersOfASubjectOfADivision(objToPushForStudentList)
          : this.SemesterUserRepository.getSemesterUsersOfASubjectOfAGroup(objToPushForStudentList));
        // console.log('response', response);
        if (this.prop_type === 'Batch') {
          const dummy = response.filter((data) => data.batch === this.selectedSubject.batch)
          response = dummy
        }

        const uIdsArray = response.map((student) => student.uId);
        this.studentListArray = await this.UserRepository.getUsers({ uIds: uIdsArray });
      } catch (error) {
        console.log('error', error);
      }
      this.currentAssignmentLoader = false
      this.pastAssignmentLoader = false
      this.disabledRefreshBtn = false
    },
    async updateAssignment() {
      const uIdsOfSelectedStudentEdit = []
      this.selectedStudentListForEdit.map((data) => {
        uIdsOfSelectedStudentEdit.push(data.uId)
      })
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        assignmentId: this.assignmentIdToEdit,
        title: this.editTitle,
        description: this.editDescription,
        totalObtainableMarks: this.editMarks,
        allowedFileTypes: this.editallowedFileTypes,
        isForNBA: this.editisForNBA,
        linksOfAttachments: this.downloadURLs2,
        deadlineForSubmission: new Date(new Date(this.editDueDate).setHours(23, 59, 59)),
        assignedStudentList: uIdsOfSelectedStudentEdit,
        time: this.time
      }
      // console.log('objToPush1', objToPush);
      try {
        const res = await this.assignmentRepositoryInstance.updateAssignment(objToPush)
        console.log('res', res);
        this.fetchData()
        this.progressUpload = 0
        this.editAssignment = false
      } catch (error) {
        console.log(error);
      }
    },
    async editAssignments(item) {
      this.selectedStudentListForEdit = []
      this.editAssignment = true
      this.loading = false
      this.editTitle = item.title
      this.assignmentIdToEdit = item.assignmentId
      this.editDescription = item.description
      this.editMarks = item.totalObtainableMarks
      this.editallowedFileTypes = item.allowedFileTypes
      this.editisForNBA = item.isForNBA
      this.dummyIdsArray = item.assignedStudentList
      this.time = item.time
      if (this.dummyIdsArray.length > 0) {
        this.studentListArray.map((stu) => {
          if (this.dummyIdsArray.includes(stu.uId)) {
            this.selectedStudentListForEdit.push(stu)
          }
        })
      }
      // this.selectedStudentListForEdit = []
      // this.editFiles = item.linksOfAttachments
      this.editDueDate = convertDateTimeToYYYYMMDD(item.deadlineForSubmission)
      if (typeof item.linksOfAttachments[0] === "string") {
        var url = item.linksOfAttachments[0]
        // console.log("url ",url.split("________")[1].split("?")[0] )
        this.storeUrlSplit = url.split("________")[1].split("?")[0].replaceAll("%", '')
        this.editFiles = item.linksOfAttachments
      }
    },
    async deleteAssignments(item) {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        assignmentId: item.assignmentId
      }
      const res = await this.assignmentRepositoryInstance.deleteAssignment(objToPush)
      console.log('res', res);
      this.fetchData()
    },
    async updateisforNbaStatus() {
      // console.log('this.tempisForNBA', this.tempisForNBA);
    },
    async updateeditisforNbaStatus() {
      // console.log('this.tempisForNBA', this.tempisForNBA);
    },
    async createAssignment() {
      const uIdsOfSelectedStudent = []
      this.selectedStudentForAssignment.map((data) => {
        uIdsOfSelectedStudent.push(data.uId)
      })
      this.questionsList.map((obj, i) => {
        obj.sn = i
        if (!obj.cos) {
          obj.cos = []
        }
        if (!obj.blooms) {
          obj.blooms = []
        }
      })
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear,
          division: this.selectedSubject.division,
          batch: this.selectedSubject.batch,
          uId: this.userData.uId,
          subjectId: this.selectedSubject.subjectId,
          subjectName: this.selectedSubject.subjectName,
          title: this.tempTitle,
          description: this.tempDescription,
          linksOfAttachments: this.downloadURLs,
          // tempDueDate: this.tempDueDate,
          // publishDate: this.tempPublishDate,
          deadlineForSubmission: new Date(new Date(this.tempDueDate).setHours(this.time?.split(':')[0], this.time?.split(':')[1], 59)),
          totalObtainableMarks: this.tempMarks,
          passingMarks: this.passingMarks,
          allowedFileTypes: this.allowedFileTypes,
          compulsoryFileTypes: this.compulsoryFileTypes,
          isForNBA: this.isForAttainment,
          nbaAttainmentObject: {
            thresholdMethod: "Overall",
            thresholdPercentage: this.tempThreshold
          },
          assignedStudentList: uIdsOfSelectedStudent,
          assignedFacultyList: [this.userData.uId],
          time: this.time,
          questions: this.questionsList,
          passingPercentage: this.passingPercentage,
          isAddQuestions: this.isAddQuestions,
          assignmentType: 'Normal'
        }
        if (this.tempPublishDate) {
          objToPush.dateOfAssignment = new Date(this.tempPublishDate)
        } else {
          objToPush.dateOfAssignment = new Date()
        }
        if (this.prop_type === "Group") {
          objToPush.assignedGroupForSubject = {
            groupName: this.prop_groupInfo.groupName,
            groupId: this.prop_groupInfo.groupId
          }
        }
        // console.log('createAssignment objToPush', objToPush);
        if (!this.isEditingAssignment) {
          const response = await this.assignmentRepositoryInstance.createAssignment(objToPush)
          console.log(response)
          this.loading = false
          this.progressUpload = 0
          // this.cancelCreating()
        } else {
          objToPush.assignmentId = this.prop_assignment.assignmentId
          console.log('this.tempDueDate12', objToPush.tempDueDate, objToPush.publishDate)
          const response = await this.assignmentRepositoryInstance.updateAssignment(objToPush)
          console.log('response', response)
        }
        this.gotoBack()
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    uploadFiles2() {
      this.loading = true
      this.downloadURLs2 = []
      this.uploadCount = 0
      if (this.editFilesChanged !== true) {
        this.updateAssignment()
      } else {
        this.downloadURLs2 = []
        this.startUploadingFiles2()
      }
      this.loading = false
      this.editAssignment = false
    },
    uploadFiles() {
      this.loading = true
      this.downloadURLs = []
      this.uploadCount = 0
      // console.log(this.files)
      if (this.files.length === 0) {
        this.createAssignment()
      } else {
        this.startUploadingFiles()
      }
    },
    startUploadingFiles() {
      this.upload(this.uploadCount)
    },
    upload(fileNumber) {
      this.fileName = this.files[fileNumber].name
      this.uploading = true
      // console.log(this.files[fileNumber])
      // console.log(`assignments/${this.userData.uId}/${new Date().valueOf().toString()}________${this.files[fileNumber].name}`)
      this.uploadTask = firestorage.ref('assignments/' + this.userData.uId + '/' + new Date().valueOf().toString() + '________' + this.files[fileNumber].name).put(this.files[fileNumber])
    },
    upload2(fileNumber) {
      this.fileName1 = this.editFiles[fileNumber].name
      this.uploading = true
      this.uploadTask2 = firestorage.ref('assignments/' + this.userData.uId + '/' + new Date().valueOf().toString() + '________' + this.editFiles[fileNumber].name).put(this.editFiles[fileNumber])
    },
    filesChanged(e) {
      console.log(e)
    },
    filesChanged1(e) {
      this.editFilesChanged = true
      console.log('e', e);
    },
    formatToSlashSepearated(date) {
      return formatToSlashSepearated(date)
    },
    startUploadingFiles2() {
      this.upload2(this.uploadCount)
    },
    cancelCreating() {
      this.tempTitle = ''
      this.tempDescription = ''
      this.tempDueDate = ''
      this.tempMarks = 0
      this.files = []
      this.addingAssignment = false
      this.allowedFileTypes = []
      this.selectedStudentForAssignment = []
      this.time = null
      this.gotoBack()
    },
    cancelEditing() {
      this.editTitle = ''
      this.editDescription = ''
      this.editisForNBA = ''
      this.editDueDate = ''
      this.editMarks = 0
      this.editFiles = []
      this.editAssignment = false
      this.editallowedFileTypes = []
      this.time = null
    },
    convertDate(dateTime) {
      return convertDate(dateTime)
    },
    convertUTCDateToLocalDate(dateTime) {
      return convertUTCDateToLocalDate(dateTime)
    },
    gotoAssignmentsCheck(assignment) {
      this.$router.push({
        name: 'activityCheckAssignments',
        params: {
          prop_subject: this.selectedSubject,
          prop_assignment: assignment,
          prop_type: this.prop_type,
          prop_groupInfo: this.prop_groupInfo
        }
      })
    },
    validateMarks() {
      if (this.tempMarks === '' || this.passingMarks < 0) {
        this.tempMarks = 0
      }
    },
    validatePassingMarks() {
      if (this.passingMarks === '' || this.passingMarks < 0) {
        this.passingMarks = 0
      }
      if (Number(this.passingMarks) > Number(this.tempMarks)) {
        this.passingMarks = null
        this.passingPercentage = null
        this.isInvalidMarks = true
      } else {
        this.isInvalidMarks = false
        this.passingPercentage = this.passingMarks / this.tempMarks * 100
      }
    },
    validatePassingPercentage() {
      if (this.passingPercentage === '' || this.passingPercentage < 0) {
        this.passinpassingPercentagegMarks = 0
      }
      if (Number(this.passingPercentage) > 100) {
        this.passingPercentage = null
        this.passingMarks = null
        this.isInvalidPercentage = true
      } else {
        this.isInvalidPercentage = false
        this.passingMarks = this.passingPercentage / 100 * this.tempMarks
      }
    },
    validateQuestionMarks(item) {
      const totalMarks = this.questionsList.reduce((acc, e) => acc + Number(e.marks), 0);
      if (Number(this.tempMarks) < Number(totalMarks)) {
        item.marks = null
        this.isInvalidQuestionsMarks = true
      } else {
        this.isInvalidQuestionsMarks = false
      }
      console.log('this.isInvalidQuestionsMarks', this.isInvalidQuestionsMarks, totalMarks, this.tempMarks);
    }
  }
}
</script>

<style src='./activityCreateAssignments.css'></style>
