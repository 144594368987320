import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Subjects'

class SubjectsRepository {
  constructor (context) {
    this.context = context
  }

  async getDetailsOfASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(
      ApiNames.getDetailsOfASubject,
      data
    )
    this.context.$store.commit('liveData/set_loading', false)
    return response.subject
  }

  async getSubjectsOfACourseYearInASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSubjectsOfACourseYear, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjects
  }

  async getSubjectDetails (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(
      ApiNames.getSubjectDetails,
      data
    )
    this.context.$store.commit('liveData/set_loading', false)
    return response.subject
  }

  async getExamForSimilarSubjectCode (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(
      ApiNames.getExamForSimilarSubjectCode,
      data
    )
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getFacultiesTeachingASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(
      ApiNames.getFacultiesTeachingASubject,
      data
    )
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getSubjectsTaughtByAFaculty (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(
      ApiNames.getSubjectsTaughtByAFaculty,
      data
    )
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getSubjects (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(
      ApiNames.getSubjects,
      data
    )
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjects
  }

  async getSubjectsOfAFaculty (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(
      ApiNames.getSubjectsOfAFaculty,
      data
    )
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getSubjectName (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(
      ApiNames.getSubjectName,
      data
    )
    this.context.$store.commit('liveData/set_loading', false)
    return response.subjectName
  }

  async getSubjectOfACoordinator (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(
      ApiNames.getSubjectOfACoordinator,
      data
    )
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getSubjectsOfAInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(
      ApiNames.getSubjectsOfAInstitute,
      data
    )
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default SubjectsRepository
