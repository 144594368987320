<template src='./activityDirections.html'>
</template>
<script>
export default {
  name: 'activityDirections',
  created () {
    this.$store.commit('liveData/set_selectedActivityName', '')
  }
}
</script>
<style src='./activityDirections.css' scoped>
</style>
