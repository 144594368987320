<template src="./activityCreateAssessment.html"></template>

<script>
import NbaRepository from "../../../Repository/NBA";
import AssessmentRepository from "../../../Repository/Assessment";
import AssignmentRepository from '../../../Repository/Assignment'
import AssessmentRubricRepository from "../../../Repository/AssessmentRubric";
import { spacesUrl } from "../../../NetworkManager";
import showStatus from "../../../NetworkManager/showStatus";

export default {
    name: "activityCreateAssessment",
    props: [
    'prop_subject', 'prop_type', 'prop_groupInfo'
  ],
    data() {
        return {
            textInputBgColor: "#E1E2F8",
            radioBtnColor: "#00128C",
            ctaOrange: "#FF4F1F",
            e1: 1,
            title: "",
            description: "",
            totalMarks: 0,
            datePickerMenu: false,
            dueDate: null,
            dueTime: null,
            timePickerMenu: false,
            attachedFiles: [],
            assessmentMethods: [
                {
                    text: "Rubric (Overall)",
                    value: "rubricOverall"
                },
                {
                    text: "Rubric (Question-wise)",
                    value: "rubricQuestionWise"
                },
                {
                    text: "Marks Only (Overall)",
                    value: "marksOverall"
                },
                {
                    text: "Marks Only (Question-wise)",
                    value: "marksQuestionWise"
                }
            ],
            assessmentMethod: "",
            addQuestionDialog: false,
            questionArray: [],
            subQuestionArray: [],
            question: "",
            isForNba: false,
            thresholdSettingMethods: [
                {
                    text: "Set Overall Threshold",
                    subText:
                        "Threshold will be set for the overall Score obtained by student.",
                    value: "overallThreshold"
                },
                {
                    text: "Set Assessment Criteria-wise Threshold",
                    subText:
                        "Threshold is set for each assessment criteria inside the rubric & average is taken for final score.",
                    value: "criteriaWiseThreshold"
                }
            ],
            thresholdMethod: "",
            assessmentCriteriaDialog: false,
            assessmentScaleDialog: false,
            scaleText: "",
            assessmentCriteriaWeightage: 0,
            assessmentCriteriaTitle: "",
            assessmentCriteriaDescription: "",
            assessmentCriteriaArray: [],
            assessmentScaleLimits: [],
            editQuestionDialog: false,
            questionToEdit: {},
            indexToEditQuestion: "",
            outcomesDialog: false,
            outcome: "cos",
            existingRubricDialog: false,
            rubricsInfoDialog: false,
            overallThreshold: 0,
            mappedCOs: [],
            mappedBlooms: [],
            rubricDetails: {},
            finalRubric: [],
            displayRubricTable: false,

            selectedSemester: {},
            selectedInstituteId: "",
            selectedSubject: {},
            totalCos: [],
            totalPos: [],
            totalPsos: [],
            nbaAttainmentObject: {},
            userData: {},
            uploadedAssessmentFiles: [],
            totalBlooms: [
                {
                    name: "CREATE (C)",
                    shortCode: "C",
                    description:
                        "Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)"
                },
                {
                    name: "EVALUATE (E)",
                    shortCode: "E",
                    description:
                        "Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)"
                },
                {
                    name: "ANALYZE (A)",
                    shortCode: "A",
                    description:
                        "Draw connections among ideas (Verbs : differentiate, organize, relate, compare, contrast, distinguish, examine, experiment, question, test)"
                },
                {
                    name: "APPLY (Ap)",
                    shortCode: "Ap",
                    description:
                        "Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)"
                },
                {
                    name: "UNDERSTAND (U)",
                    shortCode: "U",
                    description:
                        "Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)"
                },
                {
                    name: "REMEMBER (R)",
                    shortCode: "R",
                    description:
                        "Recall facts and basic concepts (Verbs : define, duplicate, list, memorize, repeat, state)"
                }
            ],
            // this.studentData[i].totalObtainedMarks = sum
            currentUploadProgress: 0,
            maxMarksForAssessmentCriteria: 0,
            rubricName: "",
            currentDate: "",
            bloomsDialog: false,
            coDialog: false,
            fileTypes: [
                {
                    text: "Image",
                    value: "image",
                    extension: "image/*"
                },
                {
                    text: "Video",
                    value: "video",
                    extension: "video/*"
                },
                {
                    text: "Audio",
                    value: "audio",
                    extension: "audio/*"
                },
                {
                    text: "File",
                    value: "file",
                    extension: ".doc,.docx,.xml"
                }
            ],
            allowedFileTypes: [],
            allFileTypes: false,
            tempUpperLimit: 0,
            inputRules: {
                limit: (value) =>
                    Number(value) > this.tempUpperLimit ||
                    `Value should be greater than ${this.tempUpperLimit}`,
                numberOnly: (value) => {
                    const pattern = /^[0-9]*$/;
                    return pattern.test(value) || "Please Enter Only Numbers.";
                },
                compulsoryField: value => !!value || 'This field cannot be empty.'
            },
            createAssessmentLoader: false,
            assessmentAsDraftLoader: false,
            toggleAddAssessmentCriteria: true,
            scaleError: "",
            criteriaWeightageError: "",
            selectedExistingRubric: {},
            existingRubrics: [],
            isExistingRubricUsed: false,
            existingRubricNameList: [],
            rubricNameErrorSnackbar: false,
            assessmentCreationError: false,
            compulsoryField: false,
            inputFileTypeError: false,
            remainingWeightagePercentageToAssign: 100
        };
    },
    created() {
        // console.log('prop_groupInfo2222', this.prop_type)
        this.$store.commit(
            "liveData/set_selectedActivityName",
            " Create New Assessment"
        );

        this.nbaRepositoryInstance = new NbaRepository(this);
        this.assessmentRepositoryInstance = new AssessmentRepository(this);
        this.assessmentRubricRepositoryInstance = new AssessmentRubricRepository(
            this
        );
        this.assignmentRepositoryInstance = new AssignmentRepository(this)

        this.selectedInstituteId =
            this.$store.getters["instituteData/get_selectedInstitute"];
        this.selectedSemester =
            this.$store.getters["instituteData/get_selectedSemester"];
        this.selectedSubject = this.$store.getters["liveData/get_selectedSubject"];
        this.userData = this.$store.getters["user/get_userData"];
        this.thresholdMethod = "overallThreshold";
        this.assessmentMethod = "rubricOverall";
        // this.mappedBlooms = this.totalBlooms

        const timestamp = new Date();
        this.currentDate = timestamp.toISOString().split("T")[0];

        this.getCourseOutcomes();
        this.getProgramOutcomes();
        this.getProgramSpecificOutcomes();

        this.getExistingRubrics();
    },
    methods: {
        handleAllFileTypes() {
            this.allFileTypes ? this.allowedFileTypes = this.fileTypes : this.allowedFileTypes = []
            this.inputFileTypeError = false
        },
        checkForAllFileTypes() {
            this.allowedFileTypes.length === this.fileTypes.length ? this.allFileTypes = true : this.allFileTypes = false
            this.inputFileTypeError = false
        },
        validateFields() {
            if (this.dueDate === null || !this.title || !this.totalMarks || this.dueTime === null || this.allowedFileTypes.length === 0) {
                this.compulsoryField = true
                this.inputFileTypeError = true
            } else {
                this.e1 = 2
            }
        },
        removeCo(index) {
            this.mappedCOs.splice(index, 1);
        },
        removeBloom(index) {
            this.mappedBlooms.splice(index, 1);
        },
        async getCourseOutcomes() {
            try {
                const nbaData = await this.nbaRepositoryInstance.getCos({
                    lmsInstituteId: this.selectedInstituteId,
                    semesterId: this.selectedSemester.semId,
                    subjectId: this.selectedSubject.subjectId
                });
                nbaData.data.map((d) => {
                    this.totalCos.push({
                        coNumber: d.CONumber,
                        coName: d.COName
                    });
                });
            } catch (error) {
                console.log('error', error);
            }
            // this.mappedCos = this.totalCos
        },
        async getProgramOutcomes() {
            try {
                const nbaData = await this.nbaRepositoryInstance.getPos({
                    lmsInstituteId: this.selectedInstituteId
                });
                nbaData.data.map((d) => {
                    this.totalPos.push({
                        poNumber: d.poNumber,
                        poName: d.poName
                    });
                });
            } catch (error) {
                console.log('error', error);
            }
        },
        async getProgramSpecificOutcomes() {
            try {
                const nbaData = await this.nbaRepositoryInstance.getPsos({
                    lmsInstituteId: this.selectedInstituteId
                });
                nbaData.data.map((d) => {
                    this.totalPsos.push({
                        psoNumber: d.psoNumber,
                        psoName: d.psoName
                    });
                });
            } catch (error) {
                console.log('error', error);
            }
        },
        addQuestion() {
            this.question = "";
            this.subQuestionArray = [];
            this.addQuestionDialog = true;
        },
        saveQuestion() {
            const tempQuestionObj = {
                number: this.questionArray.length + 1,
                question: this.question,
                subQuestions: this.subQuestionArray
            };
            this.questionArray = [...this.questionArray, tempQuestionObj];

            this.addQuestionDialog = false;
        },
        updateQuestion() {
            this.questionArray[this.indexToEditQuestion].number =
                this.indexToEditQuestion;
            this.questionArray[this.indexToEditQuestion].question = this.question;
            this.questionArray[this.indexToEditQuestion].subQuestions =
                this.subQuestionArray;
            this.editQuestionDialog = false;
        },
        addSubQuestion() {
            const tempSubQuestionObj = {
                number: this.subQuestionArray.length + 1,
                subQuestion: ""
            };
            this.subQuestionArray = [...this.subQuestionArray, tempSubQuestionObj];
        },
        deleteSubQuestion(index) {
            this.subQuestionArray.splice(index, 1);
        },
        deleteQuestion(index) {
            this.questionArray.splice(index, 1);
        },
        editQuestion(item, i) {
            this.question = item.question;
            this.subQuestionArray = item.subQuestions;
            this.questionToEdit = item;
            this.indexToEditQuestion = i;
            this.editQuestionDialog = true;
        },
        async createAssessment() {
            this.createAssessmentLoader = true;
            if (this.attachedFiles.length > 0) {
                await this.uploadAssessmentFiles();
            }
            if (this.isExistingRubricUsed) {
                if (this.selectedExistingRubric.rubric.rubric.length !== this.assessmentCriteriaArray.length) {
                    if (this.selectedExistingRubric.rubric.rubricName === this.rubricName) {
                        this.rubricNameErrorSnackbar = true
                        this.assessmentCreationError = true
                        this.createAssessmentLoader = false
                    } else {
                        this.assessmentCreationError = false
                    }
                }
            }
            if (!this.assessmentCreationError) {
                this.assessmentDetails = {
                    instituteId: this.selectedInstituteId,
                    semId: this.selectedSemester.semId,
                    department: this.selectedSubject.department,
                    courseYear: this.selectedSubject.courseYear,
                    // division: this.selectedSubject.division,
                    // batch: this.selectedSubject.batch,
                    subjectId: this.selectedSubject.subjectId,
                    subjectName: this.selectedSubject.subjectName,
                    title: this.title,
                    description: this.description,
                    dateOfAssignment: new Date(),
                    totalObtainableMarks: this.totalMarks,
                    deadlineForSubmission: this.dueDate,
                    time: this.dueTime,
                    linksOfAttachments: this.uploadedAssessmentFiles,
                    questions: this.questionArray,
                    isForNBA: this.isForNba,
                    status: "Published",
                    allowedFileTypes: this.allowedFileTypes,
                    assessmentMethod: this.assessmentMethod,
                    assignmentType: 'Rubric',

                    maxMarksForAssessmentCriteria: this.maxMarksForAssessmentCriteria
                };
                if (this.prop_type === 'Group') {
                    this.assessmentDetails.assignedGroupForSubject = {
                        groupName: this.prop_groupInfo.groupName,
                        groupId: this.prop_groupInfo.groupId
                    }
                }
                if (this.prop_type === 'Division') {
                    this.assessmentDetails.division = this.selectedSubject.division
                    // this.assessmentDetails.batch = this.selectedSubject.batch
                }
                if (this.prop_type === 'Batch') {
                    this.assessmentDetails.division = this.selectedSubject.division
                    this.assessmentDetails.batch = this.selectedSubject.batch
                }
                if (this.isExistingRubricUsed) {
                    this.assessmentDetails.rubricId = this.selectedExistingRubric.rubricId
                } else {
                    this.assessmentDetails.rubric = this.rubricDetails
                }
                if (this.isForNba) {
                    await this.handleNbaCriteriaAttainment();
                    this.assessmentDetails.nbaAttainmentObject = this.nbaAttainmentObject;
                    this.assessmentDetails.mappedCOs = this.mappedCOs;
                    this.assessmentDetails.mappedBlooms = this.mappedBlooms;
                }
                // console.log("this.assessmentDetails##", this.assessmentDetails);
                try {
                    // await this.assessmentRepositoryInstance.createAssessment(
                    //     this.assessmentDetails
                    // );
                    await this.assignmentRepositoryInstance.createAssignment(this.assessmentDetails)

                    this.$router.push({
                        name: "activityAssessments",
                        params: {
                            prop_subject: this.prop_subject,
                            prop_type: this.prop_type,
                            prop_groupInfo: this.prop_groupInfo
                        }
                    });
                    showStatus("Assessment published successfully.", 5000, "success", this);
                    this.createAssessmentLoader = false;
                } catch (error) {
                    console.log(error);
                    showStatus("Error while publishing assessment.", 5000, "error", this);
                    this.createAssessmentLoader = false;
                }
            }
        },
        async assessmentAsDraft() {
            this.assessmentAsDraftLoader = true;
            if (this.attachedFiles.length > 0) {
                await this.uploadAssessmentFiles();
            }
            this.assessmentDetails = {
                instituteId: this.selectedInstituteId,
                semId: this.selectedSemester.semId,
                department: this.selectedSubject.department,
                courseYear: this.selectedSubject.courseYear,
                division: this.selectedSubject.division,
                batch: this.selectedSubject.batch,
                subjectId: this.selectedSubject.subjectId,
                title: this.title,
                description: this.description,
                totalMarks: this.totalMarks,
                dueDate: this.dueDate,
                dueTime: this.dueTime,
                attachedFiles: this.uploadedAssessmentFiles,
                questions: this.questionArray,
                isForNBA: this.isForNba,
                status: "Draft",
                allowedFileTypes: this.allowedFileTypes,
                assessmentMethod: this.assessmentMethod,
                assignmentType: 'Rubric',

                maxMarksForAssessmentCriteria: this.maxMarksForAssessmentCriteria,
                rubric: this.rubricDetails
            };
            if (this.isForNba) {
                await this.handleNbaCriteriaAttainment();
                this.assessmentDetails.nbaAttainmentObject = this.nbaAttainmentObject;
                this.assessmentDetails.mappedCOs = this.mappedCOs;
                this.assessmentDetails.mappedBlooms = this.mappedBlooms;
            }
            try {
                await this.assessmentRepositoryInstance.assessmentAsDraft(
                    this.assessmentDetails
                );
                this.$router.push({
                    name: "activityAssessments"
                });
                showStatus("Assessment Saved as draft.", 5000, "success", this);
                // console.log("this.assessmentDetails", this.assessmentDetails);
                this.assessmentAsDraftLoader = false;
            } catch (error) {
                console.log(error);
                showStatus(
                    "Error while saving assessment as a draft.",
                    9000,
                    "error",
                    this
                );
                this.assessmentAsDraftLoader = false;
            }
        },
        handleNbaCriteriaAttainment() {
            if (this.thresholdMethod === "overallThreshold") {
                this.nbaAttainmentObject.thresholdMethod = "Overall";
                this.nbaAttainmentObject.thresholdPercentage = this.overallThreshold;
            }
        },
        addAssessmentCriteria() {
            // if (this.assessmentCriteriaArray.length > 0) {
            //     const weightageSum = this.assessmentCriteriaArray.reduce((total, obj) => total + Number(obj.weightage), 0)
            //     this.remainingWeightagePercentageToAssign = weightageSum - 100
            //     console.log('this.remainingWeightagePercentageToAssign', this.remainingWeightagePercentageToAssign);
            // }
            this.assessmentCriteriaArray.push({
                criteriaTitle: this.assessmentCriteriaTitle,
                criteriaDescription: this.assessmentCriteriaDescription,
                weightage: this.assessmentCriteriaWeightage
            });

            // if (weightageSum > 100) {
            //     this.criteriaWeightageError = 'Criteria Weightage cannot be more than 100.'
            // } else {
            if (this.assessmentScaleLimits.length > 0) {
                this.createFinalRubric();
            }
            this.assessmentCriteriaTitle = "";
            this.assessmentCriteriaDescription = "";
            this.assessmentCriteriaWeightage = 0;
            this.assessmentCriteriaDialog = false;
            // }
        },
        addAssessmentScale() {
            this.assessmentScaleDialog = true;
        },
        saveAssessmentScale() {
            const i =
                this.assessmentScaleLimits[this.assessmentScaleLimits.length - 1]
                    .upperLimit;
            if (i > this.maxMarksForAssessmentCriteria) {
                this.scaleError = `Upper limit cannot exceed ${this.maxMarksForAssessmentCriteria} marks.`;
            } else {
                this.createFinalRubric();
                this.toggleAddAssessmentCriteria = false;
                this.assessmentScaleDialog = false;
                this.scaleError = "";
            }
        },
        addScaleLimit() {
            this.assessmentScaleLimits.push({
                scaleLabel: "",
                lowerLimit: 0,
                upperLimit: 0
            });
        },
        createFinalRubric() {
            this.finalRubric = [];
            this.assessmentCriteriaArray.map((c, ci) => {
                this.finalRubric = [
                    ...this.finalRubric,
                    {
                        criteriaTitle: c.criteriaTitle,
                        criteriaDescription: c.criteriaDescription,
                        weightage: c.weightage,
                        limits: []
                    }
                ];
                this.assessmentScaleLimits.map((l, li) => {
                    this.finalRubric[ci].limits = [
                        ...this.finalRubric[ci].limits,
                        {
                            scaleLabel: l.scaleLabel,
                            lowerLimit: l.lowerLimit,
                            upperLimit: l.upperLimit,
                            description: "",
                            highestObtainableMarks: this.maxMarksForAssessmentCriteria
                        }
                    ];
                });
            });
            this.rubricDetails = {
                rubricName: this.rubricName,
                rubric: this.finalRubric
            };
            this.displayRubricTable = true;
        },
        async uploadAssessmentFiles() {
            this.uploadedAssessmentFiles = await this.uploadFiles();
            // "https://mediainprospect.sfo3.digitaloceanspaces.com/465800c3-eddc-4aea-ac8f-482ccdb3e6de/assessmentFiles/Attendance Report (2)1671865730584.xlsx"
        },
        async uploadFiles() {
            if (this.attachedFiles.length === 0) return [];

            const attachedFilePromises = [];
            const attachedFiles = this.attachedFiles.filter((item) => item.name);

            attachedFiles.forEach((attachedFile) => {
                attachedFilePromises.push(
                    this.getSignedUrl(
                        attachedFile,
                        `${this.userData.uId}/assessmentFiles/`
                    )
                );
            });
            const signedUrlsData = await Promise.all(attachedFilePromises);
            const signedUrls = signedUrlsData.map((item) => item.signedUrl);

            const uploadPromises = [];
            if (signedUrls.length === attachedFiles.length) {
                attachedFiles.forEach((attachedFile, i) => {
                    uploadPromises.push(this.uploadToSpaces(attachedFile, signedUrls[i]));
                });

                await Promise.all(uploadPromises);
                return signedUrlsData.map((item, i) => ({
                    url: spacesUrl + "/" + item.fileName,
                    thumbnailUrl: "",
                    name: attachedFiles[i].name,
                    mimeType: item.mimeType
                }));
            }
            return [];
        },
        async getSignedUrl(file, path = "") {
            const fileName = path + file.name;
            const body = {
                fileName,
                fileType: file.type
            };
            const signedUrl = await this.assessmentRepositoryInstance.getSignedUrl(
                body
            );
            return signedUrl;
        },
        async uploadToSpaces(file, signedUrl) {
            const res = await this.futch(
                signedUrl,
                {
                    method: "PUT",
                    body: file,
                    headers: {
                        "Content-Type": file.type,
                        "x-amz-acl": "public-read"
                    }
                },
                (event) => {
                    const progress = parseInt((event.loaded / event.total) * 100);
                    setTimeout(() => {
                        // console.log(event);
                        this.currentUploadProgress = progress;
                        if (progress > 99) {
                            this.currentlyUploadingNumber++;
                        }
                    }, 200);
                }
            );
            this.currentUploadProgress = 0;

            return res;
        },
        futch(url, opts = {}, onProgress) {
            return new Promise((resolve, reject) => {
                var xhr = new XMLHttpRequest();
                xhr.open(opts.method || "get", url);
                for (var k in opts.headers || {}) {
                    xhr.setRequestHeader(k, opts.headers[k]);
                }
                xhr.onload = (e) => resolve(e.target.responseText);
                xhr.onerror = reject;
                if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
                xhr.send(opts.body);
            });
        },
        setUpperLimit() {
            this.tempUpperLimit = 0;
            this.tempUpperLimit =
                this.assessmentScaleLimits[
                    this.assessmentScaleLimits.length - 1
                ].upperLimit;
        },
        async getExistingRubrics() {
            const rubricParams = {
                instituteId: this.selectedInstituteId,
                semId: this.selectedSemester.semId,
                department: this.selectedSubject.department,
                courseYear: this.selectedSubject.courseYear,
                subjectId: this.selectedSubject.subjectId
            };

            if (this.prop_type === 'Group') {
                // console.log('this.prop_type', this.prop_type, this.prop_groupInfo);
                rubricParams.groupId = this.prop_groupInfo.groupId;
            } else {
                rubricParams.division = this.selectedSubject.division;
                rubricParams.batch = this.selectedSubject.batch;
            }

            const response = await this.assessmentRubricRepositoryInstance.getExistingRubrics(rubricParams);

            this.existingRubrics.push(...response.rubrics.filter(rub => rub.rubric));
            this.existingRubricNameList.push(...response.rubrics.map(rub => rub.rubric.rubricName));

            this.existingRubrics.sort((a, b) => new Date(b.createdAt.slice(0, 10)) - new Date(a.createdAt.slice(0, 10)));
        },
        importExistingRubric() {
            this.finalRubric = this.selectedExistingRubric.rubric.rubric
            this.rubricName = this.selectedExistingRubric.rubric.rubricName
            const len = this.selectedExistingRubric.rubric.rubric[0].limits.length
            this.maxMarksForAssessmentCriteria = this.selectedExistingRubric.rubric.rubric[0].limits[len - 1].upperLimit
            this.selectedExistingRubric.rubric.rubric.map((r) => {
                this.assessmentCriteriaArray.push({
                    criteriaTitle: r.criteriaTitle,
                    criteriaDescription: r.criteriaDescription,
                    weightage: r.weightage
                })
            })
            this.assessmentScaleLimits = this.selectedExistingRubric.rubric.rubric[0].limits
            this.displayRubricTable = true
            this.toggleAddAssessmentCriteria = false
            this.isExistingRubricUsed = true
            this.existingRubricDialog = false
        }
    }
};
</script>

<style scoped src="./activityCreateAssessment.css"></style>
