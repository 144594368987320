import networkMananger from '../NetworkManager/index'
import ApiNames from '../ApiNames/RescheduleRequests'

class RescheduleRequests {
  constructor (context) {
    this.context = context
  }

  async sendARescheduleRequest (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkMananger.postRequest(ApiNames.sendARescheduleRequest, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.rescheduleRequest
  }

  async getReceivedRescheduleRequests (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkMananger.getRequest(ApiNames.getReceivedRescheduleRequests, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.rescheduleRequests
  }

  async getSentRescheduleRequests (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkMananger.getRequest(ApiNames.getSentRescheduleRequests, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.rescheduleRequests
  }

  async acceptOrRejectARescheduleRequest (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkMananger.putRequest(ApiNames.acceptOrRejectARescheduleRequest, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.rescheduleRequestStatus
  }

  async cancelAPendingRescheduleRequest (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkMananger.putRequest(ApiNames.cancelAPendingRescheduleRequest, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.rescheduleRequestStatus
  }
}

export default RescheduleRequests
