<template src='./activityCumulativeAttendance.html'></template>
<script>
import bar from '../../Components/AttendanceAnalysisBarGraph'
import pie from '../../Components/AttendanceAnalysisPieChart'
import LectureRepository from '../../Repository/lectures'
import UserRepository from '../../Repository/User'
import SemesterUserRepository from '../../Repository/SemesterUser'
// import { convertTime } from '../../Services/Utils/DateTimeFormatter'
import SubjectRepository from '../../Repository/Subjects'
import { subjectTypes } from '../../Constants/Utils/Statics'
import networkManager from '../../NetworkManager/index'
import axios from "axios";
export default {
  name: 'activityAttendanceAnalysis',
  data() {
    return {
      loading: false,
      upperDefaulters: 20,
      selectedDivByClassTeacher: {},
      selectedDiv: '',
      selectedGroupNameByFaculty: '',
      selectedDivByFaculty: '',
      isOnlyTeacher: false,
      allLectureForDiv: [],
      groupNameForFaculties: [],
      divForFaculty: [],
      myDiv: [],
      allSubjectForCourseYear: [],
      allStudentForDiv: [],
      subjectIdOfDivision: [],
      dataForDivisionWiseExcel: [],
      groupNames: [],
      isDypims: false,
      firstType: '',
      selectedGroupName: '',
      selectedSubjectByFaculty: '',
      selectedSubjectForAnalysis: '',
      typeOfAnalysis: ['Division Wise Report', 'Subject Wise Report'],
      analysisData: [],
      subjectOfDivision: [],
      subjectOfDivisionName: [],
      selectedDepartment: '',
      selectedCourseYear: '',
      selectedDivision: '',
      selectedBatch: '',
      items: [],
      itemsBackup: [],
      departments: [],
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Total',
          value: 'total'
        }
      ],
      headersBackup: [],
      dataProccessed: false,
      subjectCodes: [],
      attendanceDistribution: {
        '0 % - 10 %': 0,
        '10 % - 20 %': 0,
        '20 % - 30 %': 0,
        '30 % - 40 %': 0,
        '40 % - 50 %': 0,
        '50 % - 60 %': 0,
        '60 % - 70 %': 0,
        '70 % - 80 %': 0,
        '80 % - 90 %': 0,
        '90 % - 100 %': 0
      },
      attendanceDistributionBackup: {},
      disable: false,
      defaulterLimit: 75,
      upperDefaulterslimit: 20,
      defaulterCount: 0,
      defaulter: 26,
      limits: [0, 100],
      tempLimit: 75,
      changeDefaulterLimit: false,
      selectedSubject: null,
      userData: null,
      subjectsMapping: [],
      subjectTypes: subjectTypes
    }
  },
  components: {
    bar,
    pie
  },
  created() {
    this.loading = true
    this.instituteName = this.$store.getters['instituteData/get_instituteName']
    this.$store.commit('liveData/set_selectedActivityName', 'Attendance Analysis')
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    console.log('this.selectedInstitute', this.selectedInstitute);
    if (this.selectedInstitute === '6a2b6228-14b8-4d0b-8044-b55fe74240b3') {
      this.isDypims = true
    }
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    console.log('this.selectedSemester1', this.selectedSemester);
    this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    this.userData = this.$store.getters['user/get_userData']
    console.log('this.userData1', this.userData);
    this.lectureRepositoryInstance = new LectureRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.fetchData()
  },
  methods: {
   roundOffUptoTwoDecimals(value) {
      return Math.round(value * 100) / 100;
    },
    groupNameForFaculty() {
      this.groupNameForFaculties = []
      this.userData.subjects.map((data) => {
        if (!this.groupNameForFaculties.includes(data.groupName)) {
          this.groupNameForFaculties.push(data.groupName)
        }
      })
    },
    groupNameChangeByFaculty() {
      this.selectedSubjectByFaculty = ''

      this.subjectOfFaculty = []
      this.userData.subjects.map((data) => {
        if (this.selectedGroupNameByFaculty === data.groupName && !this.subjectOfFaculty.includes(data.groupName)) {
          this.subjectOfFaculty.push(data.subjectName + '-' + data.lectureType)
        }
      })
    },
    subjectChange() {
      if (this.selectedGroupNameByFaculty === 'Common') {
        this.divForFaculty = []
        const [name, type] = this.selectedSubjectByFaculty.split('-')
        // console.log('name', name);
        this.userData.subjects.map((data) => {
          if (data.subjectName === name) {
            // id = data.subjectId
            // subjectData = data
            console.log(type);
            this.divForFaculty.push(data.division)
          }
        })
        if (this.divForFaculty.length === 1) {
          this.selectedDivByFaculty = this.divForFaculty[0]
        }
      }
    },
    changeDiv() {
      this.selectedDepartment = {}
      this.departments.map((dep) => {
        if (this.selectedDiv === dep.division) {
          this.selectedDivByClassTeacher = dep
        }
      })
      console.log('this.selectedDivByClassTeacher', this.selectedDivByClassTeacher);
    },
    convertSubjectTypeToName(num) {
      let type;
      switch (num) {
        case 0: {
          type = 'Normal Theory'
          break;
        }
        case 1: {
          type = 'Normal Practical'
          break;
        }
        case 2: {
          type = 'Normal Tutorial'
          break;
        }
        case 3: {
          type = 'Normal Audit Course'
          break;
        }
        case 4: {
          type = 'Elective Theory'
          break;
        }
        case 5: {
          type = 'Elective Practical'
          break;
        }
        case 6: {
          type = 'Elective Tutorial'
          break;
        }
        case 7: {
          type = 'Elective Audit Course'
          break;
        }

        default:
          type = 'Theory'

        // code block
      }
      return type
    },
    firstTypeChange() {
      this.selectedGroupName = ''
      this.selectedSubjectForAnalysis = ''
      this.selectedDiv = ''
      this.subjectOfDivisionName = []
    },
    groupNameChange() {
      // const currentClass = this.departments[0].division
      this.selectedSubjectForAnalysis = ''
      this.subjectOfDivisionName = []
      this.allSubjectForCourseYear.map((data) => {
        if (data.groupName === this.selectedGroupName) {
          this.subjectOfDivisionName.push(data.subjectName + '-' + data.lectureType)
        }
      })
    },
    async attendanceAnalysisForClassTeacher() {
      // let res = []
      this.subjectOfDivision = []
      this.subjectOfDivisionName = []
      this.subjectIdOfDivision = []
      this.groupNames = []
      this.allSubjectForCourseYear.map((data) => {
        data.lectureType = this.convertSubjectTypeToName(data.subjectType)
        this.subjectOfDivisionName.push(data.subjectName + '-' + data.lectureType)
        this.subjectIdOfDivision.push(data)
        if (data.groupName !== '') {
          this.groupNames.push(data.groupName)
        }
      })
      this.groupNames = [...new Set(this.groupNames)]
      // try {
      //   const obj = {
      //     instituteId: '5d22724a-a9f5-4f6b-9e1f-dec36dcf0a4f',
      //     semId: '1d239354-6daa-4637-8af7-d0415765d288',
      //     department: 'School',
      //     division: 'B',
      //     multiDivision: 'B',
      //     courseYear: '9th'
      //   }
      //   this.allLectureForDiv = []
      //   this.allStudentForDiv = []
      //   res = await this.lectureRepositoryInstance.getAllLectureOfADivision(obj)
      //   this.allLectureForDiv = res.lecture
      //   this.allStudentForDiv = res.students
      //   const fullNames = res.fullNames
      //   for (let i = 0; i < this.allStudentForDiv.length; i++) {
      //     for (let j = 0; j < fullNames.length; j++) {
      //       if (fullNames[j].uId === this.allStudentForDiv[i].uId) {
      //         this.allStudentForDiv[i]['fullName'] = fullNames[j].fullName
      //       }
      //     }
      //   }
      //   console.log('lecture', res.lecture[0]);
      //   console.log('student', res.students[0]);
      // } catch (error) {
      //   console.log(error)
      //   res = []
      // }

      // this.dataForDivisionWiseExcel = []
      // if (this.subjectIdOfDivision.length > 0 && this.allLectureForDiv.length > 0 && this.allStudentForDiv.length > 0) {
      //   this.subjectIdOfDivision.map((subject) => {
      //     const obj = {
      //       subjectName: subject.subjectName,
      //       subjectId: subject.subjectId,
      //       lecture: [],
      //       studentList: []
      //     }
      //     this.allLectureForDiv.map((lec) => {
      //       lec['lectureTime'] = convertTime(lec.startTime) + '-' + convertTime(lec.endTime)
      //       if (lec.subjectId === subject.subjectId) {
      //         obj.lecture.push(lec)
      //       }
      //     })
      //     this.allStudentForDiv.map((student) => {
      //       if (student.subjects.includes(subject.subjectId)) {
      //         const studentObj = {
      //           fullName: student.fullName,
      //           subjectName: subject.subjectName,
      //           attendedCount: 0,
      //           uId: student.uId,
      //           totalPercentage: 0
      //         }
      //         if (obj.lecture.length > 0) {
      //           obj.lecture.map((subjectLec) => {
      //             if (subjectLec.present.includes(student.uId)) {
      //               studentObj.attendedCount += 1
      //             }
      //           })
      //         }
      //         if (studentObj.attendedCount !== 0 && obj.lecture.length !== 0) {
      //           studentObj.totalPercentage = ((studentObj.attendedCount / obj.lecture.length) * 100).toFixed(2)
      //         }
      //         obj.studentList.push(studentObj)
      //       }
      //     })
      //     this.dataForDivisionWiseExcel.push(obj)
      //   })
      // }
      // console.log('this.dataForDivisionWiseExcel', this.dataForDivisionWiseExcel);
    },
    async fetchData() {
      this.departments = await this.userRepositoryInstance.getDivisionInformationOfAFacultyAsClassTeacher({
        instituteId: this.userData.instituteId,
        semId: this.userData.semId,
        uId: this.userData.uId
      })
      // this.departments = []
      console.log('this.departments', this.departments);
      if (this.departments.length === 0) {
        this.isOnlyTeacher = true

        this.groupNameForFaculty()
      }
      this.myDiv = []
      this.departments.map((dept) => {
        this.myDiv.push(dept.division)
        dept.mydivisions = `${dept.courseYear}_${dept.division}`
      })
      this.loading = false
      try {
        this.allSubjectForCourseYear = []
        this.allSubjectForCourseYear = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester({
          instituteId: this.userData.instituteId,
          semId: this.userData.semId,
          department: this.userData.department,
          courseYear: this.departments[0].courseYear
        })
        console.log('allSubjectForCourseYear', this.allSubjectForCourseYear);
      } catch (error) {
        console.log(error)
      }
      this.attendanceAnalysisForClassTeacher()
    },
    async selectDivision() {
      this.selectedDivision.multiDivision = this.selectedDivision.division
      this.analysisData = await this.lectureRepositoryInstance.getAttendanceAnalysisForADivision(Object.assign(this.selectedDivision, {
        instituteId: this.userData.instituteId,
        semId: this.userData.semId,
        uId: this.userData.uId
      }))
      console.log('this.analysisData', this.analysisData)
      // },
      // fetchAnalysis () {
      this.dataProccessed = false
      this.disable = true
      this.items = []
      this.subjectCodes = []
      this.limits = [0, 20]
      this.headers = [
        {
          text: 'Roll No',
          value: 'rollNumber'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Percentage',
          value: 'total'
        },
        {
          text: 'Total_Lectures',
          value: 'totalConductedLectures'
        },
        {
          text: 'Total_Attended_Lectures',
          value: 'totalAttendedLectures'
        }
      ]
      console.log(this.selectedSubject)
      this.attendanceDistribution = {
        '0 % - 10 %': 0,
        '10 % - 20 %': 0,
        '20 % - 30 %': 0,
        '30 % - 40 %': 0,
        '40 % - 50 %': 0,
        '50 % - 60 %': 0,
        '60 % - 70 %': 0,
        '70 % - 80 %': 0,
        '80 % - 90 %': 0,
        '90 % - 100 %': 0
      }
      this.defaulterCount = 0
      for (let i = 0; i < this.analysisData.length; i++) {
        const obj = {}
        obj.uId = this.analysisData[i].uId
        const user = this.analysisData[i]
        const name = await this.userRepositoryInstance.getFullNameOfUser({
          uId: user.uId
        })
        obj.rollNumber = user.rollNumber;
        this.analysisData[i].name = name
        obj.name = name
        // console.log(name)
        let totalP = 0
        let totalT = 0
        let totalAttendedLectures = 0;
        let totalConductedLectures = 0;
        for (let j = 0; j < this.analysisData[i].subjects.length; j++) {
          if (this.subjectsMapping[this.analysisData[i].subjects[j].subjectId]) { } else {
            this.subjectsMapping[this.analysisData[i].subjects[j].subjectId] = await this.subjectRepositoryInstance.getDetailsOfASubject({
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              subjectId: this.analysisData[i].subjects[j].subjectId
            })
          }
          this.analysisData[i].subjects[j].subjectName = this.subjectsMapping[this.analysisData[i].subjects[j].subjectId].subjectName
          this.analysisData[i].subjects[j].subjectCode = this.subjectsMapping[this.analysisData[i].subjects[j].subjectId].subjectCode
          this.analysisData[i].subjects[j].subjectType = this.subjectTypes[this.subjectsMapping[this.analysisData[i].subjects[j].subjectId].subjectType]
          const obj2 = {
            text: this.analysisData[i].subjects[j].subjectName + '_' + this.analysisData[i].subjects[j].subjectType,
            value: this.analysisData[i].subjects[j].subjectName + '_' + this.analysisData[i].subjects[j].subjectType
          }
          if (this.subjectCodes.includes(this.analysisData[i].subjects[j].subjectName + '_' + this.analysisData[i].subjects[j].subjectType)) { } else {
            this.headers.push(obj2)
            this.subjectCodes.push(this.analysisData[i].subjects[j].subjectName + '_' + this.analysisData[i].subjects[j].subjectType)
          }
          if (this.analysisData[i].subjects[j].totalConducted > 0) {
            obj[this.analysisData[i].subjects[j].subjectName + '_' + this.analysisData[i].subjects[j].subjectType] = this.analysisData[i].subjects[j].totalAttended + " / " + this.analysisData[i].subjects[j].totalConducted
            //obj[this.analysisData[i].subjects[j].subjectName + '_' + this.analysisData[i].subjects[j].subjectCode + '_' + this.analysisData[i].subjects[j].subjectType] = Math.ceil((this.analysisData[i].subjects[j].totalAttended / this.analysisData[i].subjects[j].totalConducted) * 100)
          } else {
            obj[this.analysisData[i].subjects[j].subjectName + '_' + this.analysisData[i].subjects[j].subjectType] = "0 / 0"
          }
          totalP = totalP + this.analysisData[i].subjects[j].totalAttended
          totalT = totalT + this.analysisData[i].subjects[j].totalConducted
          totalAttendedLectures += this.analysisData[i].subjects[j].totalAttended;
          totalConductedLectures += this.analysisData[i].subjects[j].totalConducted;
        }
        obj.totalAttendedLectures = totalAttendedLectures
        obj.totalConductedLectures = totalConductedLectures
        let total = 0
        if (totalT > 0) {
         const val = (totalP / totalT) * 100
         total = this.roundOffUptoTwoDecimals(val)
        }
        obj.total = total
        if (total > 0 && total <= 10) {
          this.attendanceDistribution['0 % - 10 %']++
        }
        if (total > 10 && total <= 20) {
          this.attendanceDistribution['10 % - 20 %']++
        }
        if (total > 20 && total <= 30) {
          this.attendanceDistribution['20 % - 30 %']++
        }
        if (total > 30 && total <= 40) {
          this.attendanceDistribution['30 % - 40 %']++
        }
        if (total > 40 && total <= 50) {
          this.attendanceDistribution['40 % - 50 %']++
        }
        if (total > 50 && total <= 60) {
          this.attendanceDistribution['50 % - 60 %']++
        }
        if (total > 60 && total <= 70) {
          this.attendanceDistribution['60 % - 70 %']++
        }
        if (total > 70 && total <= 80) {
          this.attendanceDistribution['70 % - 80 %']++
        }
        if (total > 80 && total <= 90) {
          this.attendanceDistribution['80 % - 90 %']++
        }
        if (total > 90 && total <= 100) {
          this.attendanceDistribution['90 % - 100 %']++
        }
        if (total < this.defaulterLimit) {
          this.defaulterCount++
        }
        this.items.push(obj)
      }
      this.itemsBackup = JSON.parse(JSON.stringify(this.items))
      this.headersBackup = JSON.parse(JSON.stringify(this.headers))
      this.attendanceDistributionBackup = JSON.parse(JSON.stringify(this.attendanceDistributionBackup))
      console.log(this.items, this.headers, this.attendanceDistribution)
      this.disable = false
      this.dataProccessed = true
      this.loading = false
      // axios.post(apiConfig.getAttendanceAnalysis, objToPush).then(res => {
      //   console.log(res)
      //   this.analysisData = res.data.attendance
      // }).catch(err => {
      //   console.log(err)
      //   window.alert('This Activity is only for Class Teachers!')
      //   this.$store.dispatch('liveData/loading', false)
      //   this.$store.commit('liveData/setselectedActivity', 2)
      //   this.$router.push('activityMySubjects')
      // })
    },
    recalculateDefaulters() {
      this.defaulterCount = 0
      this.loading = true
      this.defaulterLimit = this.tempLimit
      for (let i = 0; i < this.itemsBackup.length; i++) {
        if (this.itemsBackup[i].total < this.defaulterLimit) {
          this.defaulterCount++
        }
      }
      this.loading = false
      this.changeDefaulterLimit = false
    },
    applyLimit() {
      this.dataProccessed = false
      this.loading = true
      this.items = []
      for (let i = 0; i < this.itemsBackup.length; i++) {
        if (this.itemsBackup[i].total <= this.limits[1] && this.itemsBackup[i].total >= this.limits[0]) {
          this.items.push(this.itemsBackup[i])
        }
      }
      console.log(this.limits[1])
      this.upperDefaulterslimit = this.limits[1]
      this.upperDefaulters = this.limits[1]
      this.defaulter = this.items.length
      this.dataProccessed = true
      this.loading = false
    },
    showDefaulters() {
      this.limits = [0, this.upperDefaulters]
      this.applyLimit()
        },
    async downloadCumulativeAttendanceExcelTest() {
      try {
        const pdfBlob = await this.lectureRepositoryInstance.generateCumulativeAttendanceExcel({
          items: this.items,
          headers: this.headers
        });
        const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/vnd.ms-excel' })
        const url = window.URL.createObjectURL(pdfBlob2)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Cumulative_Attendance.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }
    },
    async downloadCumulativeAttendanceExcel() {
      const subjectsMappingArray = Object.keys(this.subjectsMapping).map(key => this.subjectsMapping[key]);
      const tempArr = []
      Object.keys(this.subjectsMapping).forEach((key) => { tempArr.push(key) })
      console.log('this.subjectsMapping', { items: this.items, headers: this.headers, subjectDetails: tempArr, division: this.selectedDivision.division, subjectMapping: JSON.stringify(subjectsMappingArray), headingData: { department: this.userData.department, courseYear: this.selectedDivision.courseYear, instituteName: this.instituteName } });
      axios
        .post(networkManager.serverUrl + '/excel/lecture/generateCumulativeAttendanceExcel', { items: this.items, headers: this.headers, subjectDetails: tempArr, division: this.selectedDivision.division, subjectMapping: JSON.stringify(subjectsMappingArray), headingData: { department: this.userData.department, courseYear: this.selectedDivision.courseYear, instituteName: this.instituteName } }, {
          responseType: "blob"
        })
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Cumulative_Attendance.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },
    async downloadAttendanceAnalysisDivisionWise() {
      try {
        if (this.subjectIdOfDivision.length > 0) {
          const obj = {
            applicableSubject: this.subjectIdOfDivision,
            instituteId: this.userData.instituteId,
            semId: this.userData.semId,
            department: this.userData.department,
            division: this.selectedDivByClassTeacher.division,
            multiDivision: this.selectedDivByClassTeacher.division,
            courseYear: this.selectedDivByClassTeacher.courseYear,
            classTeacherId: this.userData.uId
          }
          const pdfBlob =
            await this.lectureRepositoryInstance.generateExcelAttendanceAnalysisDivisionWise(
              obj
            )
          // console.log('first')
          const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(pdfBlob2)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Attendance Analysis.xlsx')
          document.body.appendChild(link)
          link.click()
        } else {
          console.log('subjectID not present')
        }
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }
    },
    async downloadAttendanceAnalysisSubjectWise() {
      const [name, type] = this.selectedSubjectForAnalysis.split('-')
      let id = ''
      this.allSubjectForCourseYear.map((data) => {
        if (data.subjectName === name) {
          id = data.subjectId
        }
      })
      let facultyForSubject;
      try {
        facultyForSubject = await this.subjectRepositoryInstance.getFacultiesTeachingASubject({
          subjectId: id,
          instituteId: this.userData.instituteId,
          semId: this.userData.semId
        })
        console.log('facultyForSubject', facultyForSubject);
      } catch (error) {
        console.log('123')
        console.log(error);
      }

      try {
        const obj = {
          subjectId: id,
          subjectName: name,
          subjectType: type,
          instituteId: this.userData.instituteId,
          semId: this.userData.semId,
          department: this.userData.department,
          division: this.selectedGroupName === 'Common' ? this.selectedDiv : 'A',
          multiDivision: this.selectedGroupName === 'Common' ? this.selectedDiv : 'A',
          courseYear: this.departments[0].courseYear,
          uId: facultyForSubject.faculties[0],
          groupName: this.selectedGroupName
        }
        console.log('obj', obj);
        const pdfBlob =
          await this.lectureRepositoryInstance.generateExcelSubjectWise(
            obj
          )
        console.log('first')
        const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(pdfBlob2)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Attendance Analysis.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }
    },
    async downloadAttendanceAnalysisSubjectWiseByFaculty() {
      const [name, type] = this.selectedSubjectByFaculty.split('-')
      let id = ''
      let subjectData;
      this.userData.subjects.map((data) => {
        if (data.subjectName === name) {
          id = data.subjectId
          subjectData = data
        }
      })

      try {
        const obj = {
          subjectId: id,
          subjectName: name,
          subjectType: type,
          instituteId: this.userData.instituteId,
          semId: this.userData.semId,
          uId: this.userData.uId,
          department: this.userData.department,
          division: subjectData.groupName === 'Common' ? this.selectedDivByFaculty : 'A',
          multiDivision: 'A',
          courseYear: subjectData.courseYear,
          groupName: subjectData.groupName
        }
        console.log('obj123', obj);
        const pdfBlob =
          await this.lectureRepositoryInstance.generateExcelSubjectWise(
            obj
          )
        console.log('first')
        const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(pdfBlob2)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Attendance Analysis.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }
    }
  }
}
</script>
<style scoped src='./activityCumulativeAttendance.css'></style>
