<template src="./activityCheckAssignments.html"></template>

<script>
import inputContainer from "../../Components/inputContainer";
import AssignmentUserRepository from "../../Repository/AssignmentUser";
import AssignmentRepository from "../../Repository/Assignment";
import SemesterUserRepository from "../../Repository/SemesterUser";
import UserRepository from "../../Repository/User";
import auth from "../../Services/Firebase/auth";
import {
  convertDateTime, convertDate,
  formatToSlashSepearated, convertUTCDateToLocalDate
} from "../../Services/Utils/DateTimeFormatter";
import showStatus from "../../NetworkManager/showStatus";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadToDigitalOceanRepository from '../../Repository/UploadToDigitalOcean'
import { createExcelThroughJson, generateJsonFromExcel } from "../../utils/excel";

var firestorage = null;
export default {
  name: "activityCheckAssignments",
  components: {
    inputContainer
  },
  props: ["prop_subject", "prop_assignment", "prop_type", "prop_groupInfo"],
  data() {
    return {
      editor: ClassicEditor,
      refreshDisabled: false,
      dummyStudentListFiltered: [],
      editorData: "",
      questionsList: [],
      editorConfig: {
        toolbar: {
          items: [
            'heading', '|',
            'bold', 'italic', '|',
            'bulletedList', 'numberedList',
            'outdent', 'indent', '|',
            'undo', 'redo'
          ],
          shouldNotGroupWhenFull: true
        }
      },
      isMobileView: false,
      showCanvasDialog: false,
      updatingMarks: false,
      isInvalidMarks: false,
      invalidMarkStudent: {},
      students: [],
      search: '',
      dueDate: '',
      totalSubmissions: 0,
      headers: [
        {
          text: "Roll Number",
          value: "rollNumber"
        },
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Status",
          value: "status"
        },
        {
          text: "Files",
          value: "file"
        },
        {
          text: "Marks",
          value: "marksObtained"
        },
        {
          text: "Submitted On",
          value: "submittedOn",
          align: "center"
        },
        {
          text: "Remark / Resubmit",
          value: "actions"
        }
      ],
      headersForQuestion: [
        {
          text: "Quesion",
          value: "question"
        },
        {
          text: "Total Marks",
          value: "marks"
        },
        {
          text: "Obtained Marks",
          value: "obtainedMarks",
          width: "50%"
        }
      ],
      showDownload: false,
      selectedAssignment: {},
      strokes: [],
      tempStroke: [],
      tempFile: {},
      tempLink: "",
      tempAssignmentUser: {},
      tempDownloadURL: "",
      canvasContainer: null,
      mainCard: null,
      canvas: null,
      ctx: null,
      oc: null,
      octx: null,
      background: null,
      uploadTask: "",
      loading: false,
      firebaseInstance: null,
      isRemarkUpdating: false,
      showRemarkDialog: false,
      showQuestions: false,
      newRemarkAttachments: [],
      userData: {},
      selectedStudent: {},
      selectedStudentIndex: null,
      uploadMarkExcelDialog: false,
      quizMarkExcelFile: null,
      excelLink: ""
    };
  },
  created() {
    this.$store.commit('liveData/set_selectedActivityName', `Assignment's Details`)
    this.userName = this.$store.getters['user/get_userName']
    this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    const firebase = auth.getFirebaseObject();
    this.firebaseInstance = firebase;
    firestorage = firebase.storage();
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.userData = this.$store.getters['user/get_userData']
    console.log('this.userData', this.userData)

    this.assignmentRepositoryInstance = new AssignmentRepository(this);
    this.assignmentUserRepositoryInstance = new AssignmentUserRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.uploadToDigitalOceanInstance = new UploadToDigitalOceanRepository(this)
    this.questionsList = this.prop_assignment.questions
    this.dueDate = new Date(this.prop_assignment.deadlineForSubmission)
    if (this.questionsList.length > 0) {
      this.headers.push(
        {
          text: "Question / Marks",
          value: "queMarks"
        })
      this.isDirectMarksEditDisabled = true
    }
    this.fetchData();
  },
  mounted() {
    const self = this;
    self.canvasContainer = document.getElementById("canvasContainer");
    if (self.canvasContainer.addEventListener) {
      self.canvasContainer.addEventListener(
        "contextmenu",
        function (e) {
          e.preventDefault();
        },
        false
      );
    } else {
      self.canvasContainer.attachEvent("oncontextmenu", function () {
        window.event.returnValue = false;
      });
    }

    self.mainCard = document.getElementById("mainCard");
  },
  methods: {
    openExcelUploadModal() {
      console.log(this.students)
      this.uploadMarkExcelDialog = true;
    },
    handleGenerateExcel() {
      const data = [];
      this.students.forEach(stud => {
        const objToPush = {
          Name: stud.name,
          "Roll number": stud.rollNumber
        }
        this.questionsList.map(question => {
          objToPush[`Question Number ${question.sn + 1}`] = stud.questions?.filter(q => q.sn === question.sn)[0]?.obtainedMarks
        })
        data.push(objToPush);
      })
      this.excelLink = createExcelThroughJson(data, 'sample')
    },
    async handleParseMakrsExcel() {
      this.isInvalidMarks = false
      const schema = {
        Sheet1: {
          Name: {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'name'
          },
          "Roll number": {
            validation: {
              type: 'textLength'
            },
            variableName: 'rollNumber'
          }
        }
      }
      const questions = []
      let questionsTotalMarks = 0
      this.questionsList.map(question => {
        schema.Sheet1[`Question Number ${question.sn + 1}`] = {
          validation: {
            type: 'textLength',
            required: true
          },
          variableName: `sn${question.sn + 1}`
        }
        questions.push(`sn${question.sn + 1}`)
        questionsTotalMarks += Number(question.marks)
      })
      const res = await generateJsonFromExcel(this.quizMarkExcelFile, schema, false);
      const { data } = res;
      const { Sheet1 } = data;
      const studentsData = []
      Sheet1.map((obj) => {
        let studentTotalMarks = 0
        obj.questions = []
        questions.map((e, i) => {
          studentTotalMarks += Number(obj[e])
          obj.questions.push({ obtainedMarks: Number(obj[e]), sn: i })
        })
        if (questionsTotalMarks < studentTotalMarks) {
          this.isInvalidMarks = true
          this.invalidMarkStudent = { name: obj.name, marks: studentTotalMarks, totalMarks: questionsTotalMarks }
          // showStatus(`The sum of marks of all questions for ${obj.name} is exceeding `, 1000, 'error', this);
        }
        const data = this.students.map((e, i) => ({ data: e, index: i })).filter((element) => element.data.name === obj.name);
        if (Number(studentTotalMarks) >= 0 && !this.isInvalidMarks && !isNaN(data[0].data.marksObtained)) {
          data[0].data.marksObtained = studentTotalMarks
          data[0].data.questions = obj.questions
          obj.uId = data[0].data.uId
          obj.marksObtained = data[0].data.marksObtained
          this.$set(this.students, data[0].index, data[0].data)
          studentsData.push(obj)
        }
      })
      // console.log('this.quizMarkExcelFile', res)
      // console.log('questions1', questions)
      // console.log('studentsData', studentsData)
      if (!this.isInvalidMarks) {
        this.uploadMarkExcelDialog = false
        console.log(Sheet1);
        this.handleSubmitMarksForAllStudent(studentsData)
      }
    },
    openningQuestions(obj) {
      this.selectedStudent = obj
      this.selectedStudentIndex = this.students.indexOf(obj)
      this.questionsList.map((e) => {
        if (this.selectedStudent.questions?.length > 0) {
          console.log('e', e);
          e.obtainedMarks = this.selectedStudent.questions?.filter(q => e.sn === q.sn)[0]?.obtainedMarks
        } else {
          e.obtainedMarks = ''
        }
      })
      this.showQuestions = true
    },
    handleSubmitMarks() {
      for (let i = 0; i < this.questionsList.length; i++) {
        if (+this.questionsList[i].obtainedMarks > +this.questionsList[i].marks) {
          showStatus(`Obtained marks cannot be greater than marks for the question in question ${i + 1}`, 1000, "error", this);
          return;
        }
      }
      this.selectedStudent.questions = []
      this.selectedStudent.questions = this.questionsList.map((e) => {
        return {
          obtainedMarks: e.obtainedMarks,
          sn: e.sn
        }
      })
      this.handleSubmitQuestionsMarks(this.selectedStudent)
      this.$set(this.students, this.selectedStudentIndex, this.selectedStudent)
      this.showQuestions = false
      this.fetchData()
    },
    async handleSubmitQuestionsMarks(item) {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: item.uId,
          questions: item.questions,
          assignmentId: this.prop_assignment.assignmentId,
          status: 'Pending'
        };
        await this.assignmentUserRepositoryInstance.updateQuestionsMarksOfAnUserForAnAssignment(
          objToPush
        );
      } catch (err) {
        console.log(err);
        showStatus("Error updating marks", 1000, "error", this);
      }
    },
    async handleSubmitMarksForAllStudent(studentsData) {
      // console.log('studentsData12', studentsData)
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          assignmentId: this.prop_assignment.assignmentId,
          studentsData: studentsData
        };
        await this.assignmentUserRepositoryInstance.updateQuestionsMarksOfAllAssignmentUsers(
          objToPush
        );
      } catch (err) {
        console.log(err);
        showStatus("Error updating marks", 1000, "error", this);
      }
    },
    gotoAssignment() {
      this.$store.commit('liveData/set_selectedSubject', this.prop_subject)
      this.$router.push({
        name: 'activityAssignments',
        params: {
          prop_subject: this.prop_subject,
          prop_type: this.prop_type,
          prop_groupInfo: this.prop_groupInfo
        }
      })
    },
    convertDate(dateTime) {
      return convertDate(dateTime)
    },
    async fetchData() {
      this.refreshDisabled = true
      this.dummyStudentListFiltered = []
      this.loading = true;
      this.cancelEditing();
      this.totalSubmissions = 0;
      try {
        if (this.prop_type === "Division" || this.prop_type === "Batch") {
          this.students =
            await this.semesterUserRepositoryInstance.getSemesterUsersOfASubjectOfADivision(
              this.prop_subject
            );
        }
        if (this.prop_type === "Group") {
          this.prop_subject.groupId = this.prop_groupInfo.groupId
          this.students =
            await this.semesterUserRepositoryInstance.getSemesterUsersOfASubjectOfAGroup(
              this.prop_subject
            );
        }
        console.log('this.students', this.students);
        console.log("this.prop_assignment.assignedStudentList", this.prop_assignment.assignedStudentList)
        this.prop_assignment.assignedStudentList.map((id) => {
          this.students.map((stu) => {
            if (stu.uId === id) {
              this.dummyStudentListFiltered.push(stu)
            }
          })
        })
        this.students = [...this.dummyStudentListFiltered]
      } catch (err) {
        console.log(err);
      }

      let assignmentUsers = [];
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          assignmentId: this.prop_assignment.assignmentId,
          department: this.prop_subject.department,
          courseYear: this.prop_subject.courseYear,
          division: this.prop_subject.division,
          batch: this.prop_subject.batch,
          subjectId: this.prop_subject.subjectId
        };
        if (this.prop_type === "Group") {
          objToPush.groupId = this.prop_groupInfo.groupId
        }
        assignmentUsers =
          await this.assignmentUserRepositoryInstance.getAssignmentUsersOfAnAssignmentForADivision(
            objToPush
          );
        this.calculateStudent(assignmentUsers)
        console.log("assignmentUsers", assignmentUsers)
      } catch (err) {
        console.log(err);
        assignmentUsers = []
        this.calculateStudent(assignmentUsers)
      }
      this.refreshDisabled = false
    },
    async calculateStudent(assignmentUsers) {
      for (let i = 0; i < this.students.length; i++) {
        const assignmentUserDocs = assignmentUsers.filter(
          (assignmentUser) => assignmentUser.uId === this.students[i].uId
        );
        const assignmentUser =
          assignmentUserDocs[assignmentUserDocs.length - 1];

        if (assignmentUser) {
          let tempAnsMarks = 0;
          if (assignmentUser.questions?.length > 0) {
            for (let i = 0; i < assignmentUser.questions.length; i++) {
              tempAnsMarks += +assignmentUser.questions[i].obtainedMarks || 0;
            }
            this.students[i].marksObtained = tempAnsMarks;
          } else {
            this.students[i].marksObtained = assignmentUserDocs[0].marksObtained;
          }
          this.students[i].submittedOn = assignmentUser.submittedOn
          this.totalSubmissions++;
          this.students[i].status = assignmentUser.status === '' ? "Submitted" : assignmentUser.status;
          this.students[i].attemptNumber = assignmentUser.attemptNumber;
          this.students[i].files = assignmentUser.linksOfAttachments || [];
          this.students[i].remarkAttachments =
            assignmentUser.remarkAttachments || [];
          this.students[i].remark = assignmentUser.remark || "";
          this.students[i].questions = assignmentUser.questions || [];
        } else {
          this.students[i].marksObtained = 0;
          this.students[i].status = "Pending";
          this.students[i].attemptNumber = 1;
          this.students[i].files = [];
        }
      }

      const batchSize = 100;

      const promises = [];
      for (let i = 0; i < this.students.length; i += batchSize) {
        promises.push(
          this.getFullNameOfUser(
            i,
            i + batchSize < this.students.length
              ? i + batchSize
              : this.students.length
          )
        );
      }

      await Promise.allSettled(promises);

      this.students = this.students.slice();
      console.log("thy", this.students);
      this.loading = false;
    },
    async updateResubmit(item) {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: item.uId,
        assignmentId: this.prop_assignment.assignmentId,
        status: "Rejected"
      }
      await this.assignmentUserRepositoryInstance.updateUserAssignment(
        objToPush
      );
      this.students[this.students.indexOf(item)].status = 'Rejected'
      this.calculateStudent(this.students)
    },
    async downloadTemplate() {
      // console.log('this.prop_assignment', this.prop_assignment)
      this.prop_assignment.userName = this.userName
      this.prop_assignment.userData = this.userData
      try {
        const pdfBlob =
          await this.assignmentRepositoryInstance.generateAssignmentReport(
            this.prop_assignment
          )
        const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(pdfBlob2)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Attendance Report.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.log(err)
        window.alert('Something went wrong while downloading the excel.')
      }
      // axios.post('https://server.inprospecttechnologies.in/inprospect/excel/lecture/attendanceTemplate', objToPush, {
      //   responseType: 'arraybuffer'
      // }).then(res => {
      //   const url = window.URL.createObjectURL(new Blob([res.data], { type: 'vnd.ms-excel;charset=utf-8' }))
      //   const link = document.createElement('a')
      //   link.href = url
      //   link.setAttribute('download', 'Attendance Sheet.xlsx')
      //   document.body.appendChild(link)
      //   link.click()
      // }).catch(err => {
      //   console.log(err)
      //   window.alert('Something went wrong while downloading the excel.')
      // })
    },
    async checkAssignment(checkedFile) {
      this.tempAssignmentUser.files.forEach((file) => {
        if (file.submittedFile.url === this.tempFile) {
          file.checkedFile = checkedFile[0];
        }
      });
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.tempAssignmentUser.uId,
          assignmentId: this.prop_assignment.assignmentId,
          attemptNumber: this.tempAssignmentUser.attemptNumber,
          linksOfAttachments: this.tempAssignmentUser.files || []
        };
        await this.assignmentUserRepositoryInstance.checkAssignment(objToPush);
        this.fetchData();
      } catch (err) {
        console.log(err);
        showStatus("Error updating marks", 1000, "error", this);
        this.fetchData();
      }
    },
    async cancelEditing() {
      this.canvasContainer.innerHTML = "";
      this.showCanvasDialog = false;
      this.tempStroke = [];
      this.strokes = [];
    },
    async saveImage() {
      this.cancelEditing();
      this.loading = true;
      const imageBase = this.canvas.toDataURL("image/png");
      const image = new Image();
      image.src = imageBase;
      const file = this.dataURLtoFile(imageBase, "checkedAssignment.png");

      const doFile = await this.uploadFile([file])
      this.checkAssignment(doFile)
    },
    async uploadFile(fileArr) {
      try {
        // let urlLinkArr = [];
        if (fileArr.length > 0) {
          const fileName = fileArr.filter(item => item.name);
          const data = await this.uploadToDigitalOceanInstance.upload(fileName, this.userData.uId, 'checkedAssignments')
          return data
        }
      } catch (error) {
        const arr = []
        return arr
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    convertUTCDateToLocalDate(date) {
      return convertUTCDateToLocalDate(date)
    },
    async showCanvas(itemFile, itemLink, assignmentUser) {
      let file
      let link
      let contentType
      if (typeof (itemFile.submittedFile) === 'object') {
        file = itemFile.submittedFile.url
      } else {
        file = { submittedFile: itemLink.submittedFile.url }
      }
      if (itemFile.checkedFile) {
        if (typeof (itemFile.checkedFile) === 'object') {
          file = itemFile.checkedFile.url
        } else {
          file = { checkedFile: itemLink.url }
        }
      }
      // console.log('itemLink', itemLink);
      if (typeof (itemLink) === 'object') {
        link = itemLink.url
        contentType = itemLink.mimeType
      } else {
        link = itemLink
      }
      // console.log('link', link);
      //TODO: add a middle function in between to first
      //check if the file is image or not with a more
      //generic name eg: openFile or handleFileClick
      // console.log('contentType', contentType);
      if (typeof (itemLink) !== 'object') {
        const firebaseInstance = this.firebaseInstance;
        const storageRef = firebaseInstance.storage().refFromURL(link);
        contentType = await storageRef.getMetadata();
      }
      if (typeof (itemLink) !== 'object') {
        if (!contentType.includes("image")) {
          window.open(link, "_blank").focus();
          return;
        }
      } else {
        if (contentType !== 'jpeg' && contentType !== 'jpg' && contentType !== 'png') {
          window.open(link, "_blank").focus();
          return;
        }
      }
      this.showCanvasDialog = false;
      this.tempStroke = [];
      this.strokes = [];
      const self = this;
      self.tempFile = file;
      self.tempLink = link;
      self.tempAssignmentUser = assignmentUser;

      self.canvasContainer.innerHTML = "";
      const background = new Image();
      background.src = self.tempLink;
      background.setAttribute("crossorigin", "anonymous");
      const width = self.mainCard.offsetWidth - 20;
      background.onload = function () {
        self.background = background;
        self.canvas = document.createElement("canvas");
        self.ctx = self.canvas.getContext("2d");
        self.oc = document.createElement("canvas");
        self.octx = self.oc.getContext("2d");
        self.canvas.width = width;
        self.canvas.height = (self.canvas.width * this.height) / this.width;
        var cur = {
          width: Math.floor(this.width * 0.5),
          height: Math.floor(this.height * 0.5)
        };
        self.oc.width = cur.width;
        self.oc.height = cur.height;
        self.octx.drawImage(this, 0, 0, cur.width, cur.height);
        while (cur.width * 0.5 > width) {
          cur = {
            width: Math.floor(cur.width * 0.5),
            height: Math.floor(cur.height * 0.5)
          };
          self.octx.drawImage(
            self.oc,
            0,
            0,
            cur.width * 2,
            cur.height * 2,
            0,
            0,
            cur.width,
            cur.height
          );
        }
        self.ctx.drawImage(
          self.oc,
          0,
          0,
          cur.width,
          cur.height,
          0,
          0,
          self.canvas.width,
          self.canvas.height
        );
        self.canvasContainer.appendChild(self.canvas);
        self.showCanvasDialog = true;
        self.ctx.lineCap = "square";
        self.ctx.lineWidth = 5;
        self.ctx.globalCompositeOperation = "source-over";
        let lastPos = false;
        let isDown = false;
        self.canvas.onpointerdown = function (e) {
          e.preventDefault();
          isDown = true;
          self.tempStroke = [];
          lastPos = getPos(e);
          self.ctx.strokeStyle = "red";
        };
        self.canvas.onpointermove = function (e) {
          e.preventDefault();
          if (!isDown) return;
          var pos = getPos(e);
          self.ctx.beginPath();
          self.ctx.moveTo(lastPos.x, lastPos.y);
          self.ctx.lineTo(pos.x, pos.y);
          self.tempStroke.push({
            lastX: lastPos.x,
            lastY: lastPos.y,
            posX: pos.x,
            posY: pos.y
          });
          self.ctx.stroke();
          lastPos = pos;
        };
        self.canvas.onpointerup = function (e) {
          e.preventDefault();
          self.strokes.push(self.tempStroke);
          isDown = false;
        };
        self.canvas.touchstart = function (e) {
          e.preventDefault();
          isDown = true;
          self.tempStroke = [];
          lastPos = getPos(e);
          self.ctx.strokeStyle = "red";
        };
        self.canvas.touchmove = function (e) {
          e.preventDefault();
          if (!isDown) return;
          var pos = getPos(e);
          self.ctx.beginPath();
          self.ctx.moveTo(lastPos.x, lastPos.y);
          self.ctx.lineTo(pos.x, pos.y);
          self.tempStroke.push({
            lastX: lastPos.x,
            lastY: lastPos.y,
            posX: pos.x,
            posY: pos.y
          });
          self.ctx.stroke();
          lastPos = pos;
        };
        self.canvas.touchend = function (e) {
          e.preventDefault();
          self.strokes.push(self.tempStroke);
          isDown = false;
        };
        function getPos(e) {
          var rect = self.canvas.getBoundingClientRect();
          return { x: e.clientX - rect.left, y: e.clientY - rect.top };
        }
      };
      window.scroll({
        top: 64,
        behavior: "smooth"
      });
    },
    async undoStroke() {
      const self = this;
      this.showCanvasDialog = true;
      self.canvasContainer.innerHTML = "";
      const width = self.mainCard.offsetWidth - 20;
      const background = new Image();
      background.src = self.tempLink;
      background.setAttribute("crossorigin", "anonymous");
      background.onload = function () {
        self.background = background;
        self.canvas = document.createElement("canvas");
        self.ctx = self.canvas.getContext("2d");
        self.oc = document.createElement("canvas");
        self.octx = self.oc.getContext("2d");
        self.canvas.width = width;
        self.canvas.height = (self.canvas.width * this.height) / this.width;
        var cur = {
          width: Math.floor(this.width * 0.5),
          height: Math.floor(this.height * 0.5)
        };
        self.oc.width = cur.width;
        self.oc.height = cur.height;
        self.octx.drawImage(this, 0, 0, cur.width, cur.height);
        while (cur.width * 0.5 > width) {
          cur = {
            width: Math.floor(cur.width * 0.5),
            height: Math.floor(cur.height * 0.5)
          };
          self.octx.drawImage(
            self.oc,
            0,
            0,
            cur.width * 2,
            cur.height * 2,
            0,
            0,
            cur.width,
            cur.height
          );
        }
        self.ctx.drawImage(
          self.oc,
          0,
          0,
          cur.width,
          cur.height,
          0,
          0,
          self.canvas.width,
          self.canvas.height
        );
        self.canvasContainer.appendChild(self.canvas);
        self.showCanvasDialog = true;
        self.ctx.lineCap = "square";
        self.ctx.lineWidth = 5;
        self.ctx.globalCompositeOperation = "source-over";
        let lastPos = false;
        let isDown = false;
        self.strokes.pop();
        // self.strokes.pop()
        if (self.strokes.length > 0) {
          for (let i = 0; i < self.strokes.length; i++) {
            for (let j = 0; j < self.strokes[i].length; j++) {
              const str = self.strokes[i][j];
              self.ctx.strokeStyle = "red";
              self.ctx.beginPath();
              self.ctx.moveTo(str.lastX, str.lastY);
              self.ctx.lineTo(str.posX, str.posY);
              self.ctx.stroke();
            }
          }
        }
        self.canvas.onpointerdown = function (e) {
          e.preventDefault();
          isDown = true;
          self.tempStroke = [];
          lastPos = getPos(e);
          self.ctx.strokeStyle = "red";
        };
        self.canvas.onpointermove = function (e) {
          e.preventDefault();
          if (!isDown) return;
          var pos = getPos(e);
          self.ctx.beginPath();
          self.ctx.moveTo(lastPos.x, lastPos.y);
          self.ctx.lineTo(pos.x, pos.y);
          self.tempStroke.push({
            lastX: lastPos.x,
            lastY: lastPos.y,
            posX: pos.x,
            posY: pos.y
          });
          self.ctx.stroke();
          lastPos = pos;
        };
        self.canvas.onpointerup = function (e) {
          e.preventDefault();
          self.strokes.push(self.tempStroke);
          isDown = false;
        };
        self.canvas.touchstart = function (e) {
          e.preventDefault();
          isDown = true;
          self.tempStroke = [];
          lastPos = getPos(e);
          self.ctx.strokeStyle = "red";
        };
        self.canvas.touchmove = function (e) {
          e.preventDefault();
          if (!isDown) return;
          var pos = getPos(e);
          self.ctx.beginPath();
          self.ctx.moveTo(lastPos.x, lastPos.y);
          self.ctx.lineTo(pos.x, pos.y);
          self.tempStroke.push({
            lastX: lastPos.x,
            lastY: lastPos.y,
            posX: pos.x,
            posY: pos.y
          });
          self.ctx.stroke();
          lastPos = pos;
        };
        self.canvas.touchend = function (e) {
          e.preventDefault();
          self.strokes.push(self.tempStroke);
          isDown = false;
        };
        function getPos(e) {
          var rect = self.canvas.getBoundingClientRect();
          return { x: e.clientX - rect.left, y: e.clientY - rect.top };
        }
      };
    },
    async updateMarksOfAStudent(item, limit) {
      if (item.marksObtained > limit) {
        showStatus("Invalid marks entered", 1000, "error", this);
        this.fetchData();
      } else {
        if (item.marksObtained !== "") {
          try {
            console.log("item.submittedOn", item.submittedOn)
            const objToPush = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              uId: item.uId,
              assignmentId: this.prop_assignment.assignmentId,
              attemptNumber: item.attemptNumber,
              marksObtained: item.marksObtained,
              status: 'Submitted'
            };
            if (objToPush.status !== 'Submitted') {
              objToPush.submittedOn = item.submittedOn ? item.submittedOn : new Date()
            }
            await this.assignmentUserRepositoryInstance.updateMarksObtainedOfAnUserForAnAssignment(
              objToPush
            );
            this.students[this.students.indexOf(item)].marksObtained = item.marksObtained
            this.students[this.students.indexOf(item)].submittedOn = convertDateTime(objToPush.submittedOn);
          } catch (err) {
            console.log(err);
            showStatus("Error updating marks", 1000, "error", this);
          }
        }
      }
    },
    async updateRemarkOfAStudent() {
      try {
        this.isRemarkUpdating = true;
        const newRemarkAttachmentURLs = [];
        const ASSIGNMENT_ID = this.prop_assignment.assignmentId;
        const U_ID = this.selectedAssignment.uId;
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: U_ID,
          assignmentId: ASSIGNMENT_ID,
          remark: this.selectedAssignment.remark
        };
        if (this.newRemarkAttachments.length !== 0) {
          for await (const newRemarkAttachment of this.newRemarkAttachments) {
            const snapshot = await firestorage
              .ref(
                "remarkAttachments/" +
                ASSIGNMENT_ID +
                "___" +
                U_ID +
                "___" +
                new Date().valueOf().toString()
              )
              .put(newRemarkAttachment);
            const downloadURL = await snapshot.ref.getDownloadURL();
            newRemarkAttachmentURLs.push(downloadURL);
          }
          objToPush.remarkAttachments = [
            ...this.selectedAssignment.remarkAttachments,
            ...newRemarkAttachmentURLs
          ];
        } else {
          // If no new remark attachements are added
          objToPush.remarkAttachments = [
            ...this.selectedAssignment.remarkAttachments
          ];
        }
        const { remark, remarkAttachments } =
          await this.assignmentUserRepositoryInstance.updateRemarkOfAnUserForAnAssignment(
            objToPush
          );
        const self = this;
        this.students.find(function (student, index) {
          if (student.uId === self.selectedAssignment.uId) {
            self.students[index].remark = remark;
            self.students[index].remarkAttachments = remarkAttachments;
            return true;
          }
        });
        this.selectedAssignment = null;
        this.showRemarkDialog = false;
        this.newRemarkAttachments = [];
        showStatus("Successfully added a remark", 2000, "success", this);
      } catch (err) {
        console.error(err);
        showStatus("Error updating marks", 1000, "error", this);
        alert("An error occured");
      } finally {
        this.isRemarkUpdating = false;
      }
    },
    async getFullNameOfUser(firstIndex, lastIndex) {
      try {
        const objToPush = {
          uIds: []
        };

        for (let i = firstIndex; i < lastIndex; i++) {
          objToPush.uIds.push(this.students[i].uId);
        }

        const fullNames = await this.userRepositoryInstance.getFullNameOfUsers(
          objToPush
        );

        for (let i = firstIndex; i < lastIndex; i++) {
          const user = fullNames.find(
            (elem) => elem.uId === this.students[i].uId
          );
          this.students[i].name = user ? user.fullName : "";
        }
      } catch (err) {
        console.log(err);
      }
    },
    formatToSlashSepearated(date) {
      return formatToSlashSepearated(date);
    },
    openFile(fileUrl) {
      window.open(fileUrl, "_blank").focus();
    },
    async deleteRemarkAttachment(fileUrl) {
      try {
        this.isRemarkUpdating = true;
        const fileRef = await firestorage.refFromURL(fileUrl);
        await fileRef.delete();
        const ASSIGNMENT_ID = this.prop_assignment.assignmentId;
        const U_ID = this.selectedAssignment.uId;
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: U_ID,
          assignmentId: ASSIGNMENT_ID,
          remark: this.selectedAssignment.remark
        };
        const indexOfFileUrlToRemove =
          this.selectedAssignment.remarkAttachments.indexOf(fileUrl);
        this.selectedAssignment.remarkAttachments.splice(
          indexOfFileUrlToRemove,
          1
        );
        objToPush.remarkAttachments = [
          ...this.selectedAssignment.remarkAttachments
        ];
        const { remark, remarkAttachments } =
          await this.assignmentUserRepositoryInstance.updateRemarkOfAnUserForAnAssignment(
            objToPush
          );
        const self = this;
        this.students.find(function (student, index) {
          if (student.uId === self.selectedAssignment.uId) {
            self.students[index].remark = remark;
            self.students[index].remarkAttachments = remarkAttachments;
            return true;
          }
        });
        showStatus("Deleted attachement successfully", 3000, "success", this);
      } catch (err) {
        console.error(err);
        showStatus("An error occured, try again later", 3000, "error", this);
      } finally {
        this.isRemarkUpdating = false;
      }
    }
  }
};
</script>

<style src="./activityCheckAssignments.css"></style>
