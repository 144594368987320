const state = {
  loading: false,
  selectedActivity: 2,
  selectedActivityName: '',
  statusMessage: 'Success!',
  statusDuration: 2000,
  statusType: 'success',
  snackbar: false,
  editingSemester: {},
  isMobileView: false,
  selectedSubject: {},
  selectedExam: {},
  selectedExamSubject: {},
  selectedExamTimetable: {},
  selectedTopic: {},
  instituteType: '',
  attainmentCalculationType: '',
  studentAttendance: [],
  proxyData: [],
  tokenForQr: '',
  examDepartment: '',
  examCourseYear: '',
  examDivision: '',
  brandingName: '',
  examId: "",
  selectedSemester: {}
}

const getters = {
  // getters for state
  get_loading: state => state.loading,
  get_examDepartment: (state) => state.examDepartment,
  get_selectedActivity: state => state.selectedActivity,
  get_selectedActivityName: state => state.selectedActivityName,
  get_statusMessage: state => state.statusMessage,
  get_statusDuration: state => state.statusDuration,
  get_statusType: state => state.statusType,
  get_editingSemester: state => state.editingSemester,
  get_isMobileView: state => state.isMobileView,
  get_selectedSubject: state => state.selectedSubject,
  get_selectedExam: state => state.selectedExam,
  get_selectedExamSubject: state => state.selectedExamSubject,
  get_selectedExamTimetable: state => state.selectedExamTimetable,
  get_selectedTopic: state => state.selectedTopic,
  get_instituteType: state => state.instituteType,
  get_attainmentCalculationType: state => state.attainmentCalculationType,
  get_studentAttendance: state => state.studentAttendance,
  get_tokenForQr: state => state.tokenForQr,
  get_examCourseYear: state => state.examCourseYear,
  get_examDivision: state => state.examDivision,
  get_proxyData: state => state.proxyData,
  get_brandingName: state => state.brandingName,
  get_examId: (state) => state.examId
}

const mutations = {
  // mutations for state
  set_loading: (state, value) => {
    state.loading = value
  },
  set_selectedActivity: (state, value) => {
    state.selectedActivity = value
  },
  set_selectedActivityName: (state, value) => {
    state.selectedActivityName = value
  },
  set_statusMessage: (state, value) => {
    state.statusMessage = value
  },
  set_statusDuration: (state, value) => {
    state.statusDuration = value
  },
  set_statusType: (state, value) => {
    state.statusType = value
  },
  set_snackbar: (state, value) => {
    state.snackbar = value
  },
  set_editingSemester: (state, value) => {
    state.editingSemester = value
  },
  set_isMobileView: (state, value) => {
    state.isMobileView = value
  },
  set_selectedSubject: (state, value) => {
    state.selectedSubject = value
  },
  set_selectedExam: (state, value) => {
    state.selectedExam = value
  },
  set_selectedExamSubject: (state, value) => {
    state.selectedExamSubject = value
  },
  set_selectedExamTimetable: (state, value) => {
    state.selectedExamTimetable = value
  },
  set_selectedTopic: (state, value) => {
    state.selectedTopic = value
  },
  set_instituteType: (state, value) => {
    state.instituteType = value
  },
  set_studentAttendance: (state, value) => {
    state.studentAttendance = value
  },
  set_tokenForQr: (state, value) => {
    state.tokenForQr = value
  },
  set_attainmentCalculationType: (state, value) => {
    state.attainmentCalculationType = value
  },
  set_examDepartment: (state, value) => {
    state.examDepartment = value
  },
  set_examCourseYear: (state, value) => {
    state.examCourseYear = value
  },
  set_examDivision: (state, value) => {
    state.examDivision = value
  },
  set_proxyData: (state, value) => {
    state.proxyData = value
  },
  set_brandingName: (state, value) => {
    state.brandingName = value
  },
  set_examId: (state, value) => {
    state.examId = value
  },
  reset(state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

const actions = {
  // actions for state
  set_loading: ({ commit, state }, value) => {
    commit('set_loading', value)
    return state.message
  },
  set_selectedActivity: ({ commit, state }, value) => {
    commit('set_selectedActivity', value)
    return state.message
  },
  set_selectedTopic: ({ commit, state }, value) => {
    commit('set_selectedTopic', value)
    return state.message
  },
  set_attainmentCalculationType: ({ commit, state }, value) => {
    commit('set_attainmentCalculationType', value)
    return state.message
  },
  set_instituteType: ({ commit, state }, value) => {
    commit('set_instituteType', value)
    return state.message
  },
  set_studentAttendance: ({ commit, state }, value) => {
    commit('set_studentAttendance', value)
    return state.message
  },
  set_tokenForQr: ({ commit, state }, value) => {
    commit('set_tokenForQr', value)
    return state.message
  },
  set_examDepartment: ({ commit, state }, value) => {
    commit('set_examDepartment', value)
    return state.message
  },
  set_examCourseYear: ({ commit, state }, value) => {
    commit('set_examCourseYear', value)
    return state.message
  },
  set_examDivision: ({ commit, state }, value) => {
    commit('set_examDivision', value)
    return state.message
  },
  set_proxyData: ({ commit, state }, value) => {
    commit('set_proxyData', value)
    return state.message
  },
  set_brandingName: ({ commit, state }, value) => {
    commit('set_brandingName', value)
    return state.message
  }
}

export default {
  // exports the vuex object of 'institutesData' store
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

function initialState() {
  return {
    loading: false,
    selectedActivity: 1,
    statusMessage: 'Success!',
    statusDuration: 2000,
    statusType: 'success',
    snackbar: false,
    editingSemester: {},
    isMobileView: false,
    selectedSubject: {},
    selectedTopic: {},
    instituteType: state.instituteType,
    studentAttendance: [],
    proxyData: [],
    attainmentCalculationType: state.attainmentCalculationType,
    tokenForQr: '',
    examDepartment: '',
    examCourseYear: '',
    examDivision: '',
    brandingName: '',
    examId: ''
  }
}
