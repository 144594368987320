export default {
  createTopicFeedback: '/topicFeedback/createTopicFeedback',
  getTopicFeedbackById: '/topicFeedback/getTopicFeedbackById',
  updateTopicFeedback: '/topicFeedback/updateTopicFeedback',
  getTopicFeedbacksForAStudent: '/topicFeedback/getTopicFeedbacksForAStudent',
  getTopicFeedbacksForAStudentForASubject:
    '/topicFeedback/getTopicFeedbacksForAStudentForASubject',
  getTopicFeedbacksForAFacultyForASubject:
    '/topicFeedback/getTopicFeedbacksForAFacultyForASubject',
  getTopicFeedbacksForAFaculty: '/topicFeedback/getTopicFeedbacksForAFaculty',
  getTopicFeedbacksForADivisionForASubject:
    '/topicFeedback/getTopicFeedbacksForADivisionForASubject',
  submitFeedbackOfAStudent: '/topicFeedback/submitFeedbackOfAStudent'
}
