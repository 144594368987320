<template src="./activityCreateFeedback.html"> </template>

<script>
import inputContainer from '../../Components/inputContainer'
import TimetableRepository from '../../Repository/timetable'
import SubjectsRepository from '../../Repository/Subjects'
import TopicFeedbackQuestion from '../../Repository/TopicFeedbackQuestion'
import TopicFeedback from '../../Repository/TopicFeedback'
import { subjectTypes } from '../../Constants/Utils/Statics'
export default {
  name: 'activityCreateFeedback',
  props: ['topicName', 'id'],
  components: { inputContainer },
  data() {
    return {
      loading: false,
      isMobileView: false,
      userData: null,
      selectedInstitute: '',
      selectedSemester: '',
      subjects: [],
      selectedTopic: {
        name: '',
        id: ''
      },
      subjectTypes: subjectTypes,
      questions: [],
      question: '',
      publishDialog: false,
      endDate: new Date().toISOString().substr(0, 10),
      menu2: false,
      uId: ''
    }
  },
  created() {
    this.uId = this.$store.getters['user/get_userData'].uId
    this.selectedTopic = this.$store.getters['liveData/get_selectedTopic']

    this.$store.commit('liveData/set_selectedActivityName', 'Feedback Form')

    this.isMobileView = this.$store.getters['liveData/get_isMobileView']

    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    this.selectedSemester = this.$store.getters[
      'instituteData/get_selectedSemester'
    ]
    this.timetableRepositoryInstance = new TimetableRepository(this)
    this.subjectsRepositoryInstance = new SubjectsRepository(this)
    this.topicFeedbackQuestionInstance = new TopicFeedbackQuestion(this)
    this.topicFeedbackInstance = new TopicFeedback(this)
    console.log(this.selectedSemester)
    // Call API to fetch subjects
    this.fetchData()
  },
  methods: {
    async fetchData() {
      await this.fetchQuestions()
    },
    async fetchQuestions() {
      const data = await this.topicFeedbackQuestionInstance.getTopicFeedbackQuestion(
        {
          instituteId: this.selectedInstitute,
          uId: this.uId
        }
      )
      if (data.questions) {
        this.questions = data.questions.map((item, id) => ({
          id: id,
          name: item,
          checked: false
        }))
      }
    },
    async publishFeedback() {
      this.loading = true
      this.publishDialog = false
      const res = await this.topicFeedbackInstance.createTopicFeedback({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        topicId: this.selectedTopic.id,
        publishedOn: new Date(),
        questions: this.questions
          .filter(item => item.checked)
          .map(item => item.name),
        endDate: new Date(this.endDate).toISOString()
      })
      console.log('feedback publishded', res)
      this.loading = false

      this.$router.push('/activityFeedbackForm')
    },
    async addQuestion() {
      if (this.question.length > 5) {
        // const _questions = [...this.questions];
        // _questions.push({
        //   id: _questions.length + 1,
        //   name: this.question,
        //   checked: false
        // });
        // this.questions = _questions;
        await this.createQuestion()
      }
    },
    selectAll() {
      const _questions = this.questions
      _questions.forEach(item => (item.checked = true))
      this.questions = _questions
    },
    async updateQuestions() {
      const instituteId = this.selectedInstitute
      const uId = this.uId
      const questions = [
        ...this.questions.map(item => item.name),
        this.question
      ]
      const params = {
        instituteId,
        uId,
        questions
      }
      const res = await this.topicFeedbackQuestionInstance.updateTopicFeedbackQuestion(
        params
      )
      console.log('Update questions', res)
    },

    async createQuestion() {
      const instituteId = this.selectedInstitute
      const uId = this.uId
      let questions = []

      let res = null
      if (this.questions.length > 0) {
        questions = [...this.questions.map(item => item.name), this.question]
        const params = {
          instituteId,
          uId,
          questions
        }
        console.log({ params })
        res = await this.topicFeedbackQuestionInstance.updateTopicFeedbackQuestion(
          params
        )
      } else {
        console.log('Question', this.question)
        questions = [this.question]
        const params = {
          instituteId,
          uId,
          questions
        }
        console.log({ params })
        res = await this.topicFeedbackQuestionInstance.createTopicFeedbackQuestion(
          params
        )
        console.log(res)
      }
      const response = await this.topicFeedbackQuestionInstance.getTopicFeedbackQuestion(
        {
          instituteId: this.selectedInstitute,
          uId: uId
        }
      )
      console.log({ response })
      if (response.questions) {
        this.questions = response.questions.map((item, id) => ({
          id: id,
          name: item,
          checked: false
        }))
      }
      this.question = ''
      return res
    }
  }
}
</script>

<style src="./activityCreateFeedback.css">
</style>
