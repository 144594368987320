<template src="./activityMyAssessments.html"></template>

<script>
// import showStatus from '../../../NetworkManager/showStatus';
import TimetableRepository from '../../../Repository/timetable'
import SubjectRepository from '../../../Repository/Subjects'
import { subjectTypes } from '../../../Constants/Utils/Statics'

export default {
    name: 'activityMyAssessments',
    data() {
        return {
            userData: {},
            subjects: [],
            selectedInstituteselectedInstitute: {},
            subjectTypes: subjectTypes,
            selectedSubject: {}
        }
    },
    created() {
        this.$store.commit('liveData/set_selectedActivityName', 'My Assessments')
        this.userData = this.$store.getters['user/get_userData']
        this.selectedSubject = this.$store.getters['liveData/get_selectedSubject']

        this.selectedSemester = this.$store.getters[
            'instituteData/get_selectedSemester'
        ]
        this.selectedInstitute = this.$store.getters[
            'instituteData/get_selectedInstitute'
        ]
        this.timetableRepositoryInstance = new TimetableRepository(this)
        this.SubjectRepositoryInstance = new SubjectRepository(this)

        this.getSubjects()
    },
    methods: {
        gotoSubjectAssessment(subject) {
            this.$store.commit('liveData/set_selectedSubject', subject)

            this.$router.push({
                name: 'activityAssessments',
                params: {
                    prop_subject: subject
                }
            })
        },
        async getSubjects() {
            const subjectIds = []

            const objToPush = {
                instituteId: this.selectedInstitute,
                semId: this.selectedSemester.semId,
                uId: this.userData.uId
            }
            const subjectObjects = await this.timetableRepositoryInstance.getAllocationsOfAFaculty(
                objToPush
            )
            subjectObjects.map((obj) => {
                subjectIds.push(obj.subjectId)
            })
            this.getTimetableSubjects = subjectObjects
            const getsubjectObjToPush = {
                instituteId: this.selectedInstitute,
                semId: this.selectedSemester.semId,
                subjectIds: subjectIds
            }
            const subject = await this.SubjectRepositoryInstance.getSubjectDetails(
                getsubjectObjToPush
            )
            const tempSubjects = []
            subject.map((obj) => {
                this.$store.commit('mappings/add_subjectsMapping', obj)
                obj.lectureType = this.subjectTypes[obj.subjectType]
                this.getTimetableSubjects.map((sub) => {
                    if (obj.subjectId === sub.subjectId) {
                        tempSubjects.push({
                            ...obj,
                            ...sub
                        })
                    }
                })
            })
            this.subjects = tempSubjects
        }
    }
}
</script>

<style src="./activityMyAssessments.css" scoped>

</style>
