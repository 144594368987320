<template src='./activityTopicFeedback.html'>
</template>

<script>
import inputContainer from '../../Components/inputContainer'
// import ApexCharts from 'apexcharts'
import Vue from 'vue'
// import VueApexCharts from 'apexcharts'
import VueApexCharts from 'vue-apexcharts'

import TopicRepository from '../../Repository/Topic'
import TopicFeedbackRepository from '../../Repository/TopicFeedback'
import TopicFeedbackTemplateRepository from '../../Repository/TopicFeedbackTemplate'
import showStatus from '../../NetworkManager/showStatus'
import TopicFeedbackActionTemplateRepository from '../../Repository/TopicFeedbackActionTemplate'

import { convertDateTime } from '../../Services/Utils/DateTimeFormatter'

Vue.use(VueApexCharts)

export default {
  name: 'activityTopicFeedback',
  props: [
    'prop_subject'
  ],
  components: {
    apexcharts: VueApexCharts,
    inputContainer
  },
  data () {
    return {
      userData: {},
      showCreateTopicFeedback: false,
      topicFeedbacks: [],
      topicFeedbackTemplates: [],
      topics: [],
      headersForTopicFeedback: [
        {
          text: 'Name Of Topic Feedback',
          value: 'topicFeedbackName'
        },
        {
          text: 'Topics',
          value: 'topics'
        },
        {
          text: 'Created On',
          value: 'dateOfCreation'
        },
        {
          text: 'Responses',
          value: 'responses'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ],
      headersForTopicFeedbackTemplate: [
        {
          text: 'Name Of Template',
          value: 'topicFeedbackTemplateName'
        },
        {
          text: 'Questions',
          value: 'questions'
        },
        {
          text: 'Created On',
          value: 'dateOfCreation'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ],
      headersForTopics: [
        {
          text: 'Name Of Topic',
          value: 'topicName'
        }
      ],
      createTopicFeedbackStepNumber: 1,
      selectedTopicFeedback: '',
      selectedTopicFeedbackTemplate: '',
      listOfTopicsWithoutFeedback: [],
      tempTopicFeedbackName: '',
      selection: [],
      selectedSubject: {},
      showFeedbackAnalysis: false,
      barOptions: {
        series: [{
          name: 'Analysis',
          data: []
        }],
        chart: {
          type: 'bar',
          height: 257
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
            columnWidth: '70%',
            colors: {
              ranges: [{
                from: 0,
                to: 74,
                color: '#ff0000'
              },
              {
                from: 75,
                to: 89,
                color: '#050992'
              },
              {
                from: 90,
                to: 100,
                color: '#11bc7d'
              }],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: []
        }
      }
    }
  },
  created () {
    if (this.prop_subject) {
      this.selectedSubject = JSON.parse(JSON.stringify(this.prop_subject))
    } else {
      this.selectedSubject = JSON.parse(JSON.stringify(this.$store.getters['liveData/get_selectedSubject']))
    }
    this.topicRepositoryInstance = new TopicRepository(this)
    this.topicFeedbackRepositoryInstance = new TopicFeedbackRepository(this)
    this.topicFeedbackTemplateRepositoryInstance = new TopicFeedbackTemplateRepository(this)
    this.topicFeedbackActionTemplateRepository = new TopicFeedbackActionTemplateRepository(this)

    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.topics = []
      this.topicFeedbacks = []
      this.topicFeedbackTemplates = []

      try {
        this.topics = await this.topicRepositoryInstance.getTopicsOfASubjectOfAFaculty(this.selectedSubject)
      } catch (err) {
        this.topics = []
        console.log(err)
      }

      try {
        this.topicFeedbacks = await this.topicFeedbackRepositoryInstance.getTopicFeedbacksForAFacultyForASubject(this.selectedSubject)
      } catch (err) {
        this.topicFeedbacks = []
        console.log(err)
      }

      this.listOfTopicsWithoutFeedback = this.topics.filter(
        (topic) => this.topicFeedbacks.filter(
          (topicFeedback) => topicFeedback.topicIds.includes(topic.topicId)
        ).length === 0
      )

      try {
        this.topicFeedbackTemplates = await this.topicFeedbackTemplateRepositoryInstance.getTopicFeedbackTemplatesOfAnInstitute(this.selectedSubject)
      } catch (err) {
        this.topicFeedbackTemplates = []
        console.log(err)
      }

      for (let i = 0; i < this.topicFeedbacks.length; i++) {
        this.topicFeedbacks[i].topics = this.topicFeedbacks[i].topicIds
          .map((topicId) => this.topics.find((elem) => elem.topicId === topicId))
          .filter((elem) => elem)

        const topicFeedbackTemplate = this.topicFeedbackTemplates.find((elem) => elem.topicFeedbackTemplateId === this.topicFeedbacks[i].topicFeedbackTemplateId)
        const stats = {}
        if (topicFeedbackTemplate) {
          // this.topicFeedbacks[i].responses = this.topicFeedbacks[i].responses.map((response) => {
          //   response.question = topicFeedbackTemplate.questions.find((elem) => elem.questionId === response.questionId).question
          //   return response
          // })
          // stats[questionid] = {
          // question: questiion,
          // numberFilled: number
          // }
          topicFeedbackTemplate.questions.forEach((questionObject, index) => {
            stats[questionObject.questionId] = {
              question: questionObject.question,
              options: {},
              barchartid: 'barchart' + index
            }
            questionObject.options.forEach((option) => {
              stats[questionObject.questionId].options[option] = 0
            })
          })
          Object.values(this.topicFeedbacks[i].responses).forEach((response) => {
            Object.keys(response).forEach((questionId) => {
              stats[questionId].options[response[questionId]]++
            })
          })
        }
        this.topicFeedbacks[i].stats = Object.values(stats)
      }
      this.topicFeedbacks = this.topicFeedbacks.slice()
    },
    closeCreateFeedbackDialog () {
      this.createTopicFeedbackStepNumber = 1
      this.selectedTopicFeedbackTemplate = ''
      this.selection = []
      this.tempTopicFeedbackName = ''
      this.showCreateTopicFeedback = false
    },
    selectTemplateAndProceedToNextStep (item) {
      this.selectedTopicFeedbackTemplate = item
      console.log(item)
      this.createTopicFeedbackStepNumber = 3
    },
    async createAndLaunchFeedback () {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          topicFeedbackTemplateId: this.selectedTopicFeedbackTemplate.topicFeedbackTemplateId,
          topicIds: this.selection.map((elem) => elem.topicId),
          topicFeedbackName: this.tempTopicFeedbackName,
          responses: [],
          createdBy: this.userData.uId
        }
        console.log('hello')
        console.log(objToPush)
        await this.topicFeedbackRepositoryInstance.createTopicFeedback(objToPush)
        this.closeCreateFeedbackDialog()
        this.fetchData()
      } catch (err) {
        console.log(err)
        showStatus('Error creating feedback', 1000, 'error', this)
      }
    },
    goToTopicFeedbackActions (item) {
      this.$router.push({
        name: 'activityTopicFeedbackAction',
        params: {
          prop_subject: this.prop_subject,
          prop_feedback: item
        }
      })
    },
    goToTopicFeedbackTemplate () {
      this.$router.push({
        name: 'activityTopicFeedbackTemplate'
      })
    },
    openFeedbackAnalysis (item) {
      this.selectedTopicFeedback = item
      for (let i = 0; i < this.selectedTopicFeedback.stats.length; i++) {
        const chartOptions = {
          chart: {
            id: 'Question ' + (i + 1)
          },
          xaxis: {
            categories: Object.keys(this.selectedTopicFeedback.stats[i].options)
          }
        }
        const series = [{
          name: 'Options',
          data: Object.values(this.selectedTopicFeedback.stats[i].options)
        }]
        // for (let j = 0; j < this.barOptions.xaxis.categories.length; j++) {
        //   series[0].data[j] = Object.values(this.selectedTopicFeedback.stats[i].options)
        // }
        console.log(chartOptions, series)
        this.selectedTopicFeedback.stats[i].chartOptions = chartOptions
        this.selectedTopicFeedback.stats[i].series = series
      }
      this.showFeedbackAnalysis = true
    },
    closeFeedbackAnalysis () {
      this.selectedTopicFeedback = ''
      this.showFeedbackAnalysis = false
    },
    // async getTopicFeedbackTemplate () {
    //   try {
    //     const objToPush = {
    //       instituteId: this.selectedInstitute
    //     }
    //     this.topicFeedbackTemplates = await this.topicFeedbackTemplateRepositoryInstance.getTopicFeedbackTemplatesOfAnInstitute(objToPush)
    //     this.createTopicFeedbackStepNumber = 2
    //   } catch (err) {
    //     console.log(err)
    //   }
    // },
    convertDateTime (date) {
      return convertDateTime(date)
    }
  }
}
</script>

<style src='./activityTopicFeedback.css'>
</style>
