import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activityChangeSemester"},[(_vm.isLoading)?_c(VLayout,{attrs:{"align-center":"","justify-center":"","column":"","fill-height":""}},[_c(VFlex,{attrs:{"row":"","align-center":""}},[_c(VProgressCircular,{staticClass:"mt-10",attrs:{"size":50,"color":"#050991","indeterminate":""}})],1)],1):_c(VRow,_vm._l((_vm.semesters),function(semester){return _c(VCol,{key:semester.semId,attrs:{"cols":"12","md":"4","lg":"4","xl":"3","sm":"12","xs":"12"}},[_c(VCard,{staticStyle:{"padding":"10px","font-size":"medium","font-weight":"bold"},attrs:{"id":"checkNameHighlight","flat":"","color":"#e1e2fa"},on:{"click":function($event){return _vm.chooseAndUpdateSemester(semester)}}},[(_vm.currentlyChosenSemester === semester.semId)?_c(VIcon,{attrs:{"color":"#050991"}},[_vm._v("mdi-radiobox-marked")]):_c(VIcon,[_vm._v("mdi-radiobox-blank")]),_vm._v(" "+_vm._s(semester.semName))],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }