<template src='./activitySetup.html'></template>
<script>
import auth from '../../Services/Firebase/auth'
import UserRepository from '../../Repository/User'
import showStatus from '../../NetworkManager/showStatus'
export default {
  name: 'activitySetup',
  data() {
    return {
      user: null
    }
  },
  created() {
    this.user = this.$store.getters['user/get_user']
    this.userRepositoryInstance = new UserRepository(this)
    const authToken = this.$store.getters['user/get_authToken']
    if (this.user) {
      const objToPush = {
        registrationToken: '',
        authToken: authToken,
        uuId: this.user.uid,
        email: this.user.email,
        phone: this.user.phoneNumber ? this.user.phoneNumber.substring(3) : null
      }
      if (this.user.providerData[0].providerId === "microsoft.com") {
        objToPush.uuId = this.user.providerData[0].uid
        objToPush.email = this.user.providerData[0].email
      }
      this.userRepositoryInstance.login(objToPush).then(res => {
        showStatus('Login Successful!', 1000, 'success', this)
        console.log(res)
        this.$router.push('/activitySelectInstituteNSemester')
      }).catch(err => {
        console.log(err)
        auth.logout(this)
      })
    } else {
      this.$router.push('/activityWelcome')
    }
  }
}
</script>
<style scoped src='./activitySetup.css'></style>
