export default {
  createExam: '/exam/create',
  getExamById: '/exam/getExamById',
  getAllExams: '/exam/getAllExams',
  getExamsOfACriteria: '/exam/getExamsOfACriteria',
  getExamsOfAnInstitute: '/exam/getExamsOfAnInstitute',
  update: '/exam/update',
  publishExam: '/exam/publishExam',
  deleteExam: '/exam/deleteExam',
  getEkAllExams: '/examIntegration/getExamResultsBySubject',
  SyncDataToEklavya: '/examIntegration/SyncAllDataByEklavyaSystem'
}
