<template src="./activityManageExamsRevamped.html"></template>

<script>
import examsBasicDetails from "./examsBasicDetails/examsBasicDetails"
import examSubjectDetails from "./examSubjectDetails/examSubjectDetails";
import examForm from "./examForm/examForm";
import hallTicket from "./hallTicket/hallTicket";
import examQuestionDetails from "./examQuestionDetails/examQuestionDetails";
import examMarks from "./examMarks/examMarks";
export default {
  name: "activityManageExamsRevamped",
  components: {
    examsBasicDetails,
    examSubjectDetails,
    examForm,
    examQuestionDetails,
    examMarks,
    hallTicket
  },
  props: [
    'prop_tabNumber',
    'instituteToBeSet',
    'semesterToBeSet'
  ],
  data() {
    return {
      backgroundColor: "#E7E7FFCF",
      downArrow: "mdi-chevron-down",
      upArrow: "mdi-arrow-up",
      examTypes: ['Exam'], //'Theory', 'Online'
      examType: '',
      mainTabNames: ['Basic Details', 'Subject Details', 'Question & Answers', 'Student Marks'], //'Exam Form', 'Hall Ticket', 'Invagilation', 'Control Access',
      components: ['examsBasicDetails', 'examSubjectDetails', 'examForm'],
      currentTab: 0,
      tabs: 0,
      selectedInstitute: '',
      selectedSemester: '',
      selectedDepartment: '',
      selectedCourseYear: ''
    }
  },
  async created() {
    this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];
    this.selectedDepartment = this.$store.getters["liveData/get_examDepartment"];
    this.selectedCourseYear = this.$store.getters["liveData/get_examCourseYear"];
    console.log("checked 1" + this.selectedSemester)
    console.log("checked 2" + this.selectedCourseYear)
    console.log("checked 3" + this.selectedDepartment)
    console.log("checked 4" + this.prop_tabNumber)
    if (this.prop_tabNumber) this.currentTab = this.prop_tabNumber

    this.createMap();
  },
  computed: {
    currentTabComponent() {
      return this.components[this.currentTab];
    }
  },
  methods: {
    handleNextButtonClick() {
      // Increment the currentTab value to move to the next tab
      this.currentTab += 1;
    },
    goToExams() {
      // const objToPush = {
      //   prop_department: this.department,
      //   prop_course: this.course,
      //   prop_selectedSubject: this.selectedSubject,
      //   prop_typeDivGrpBatch: this.typeDivGrpBatch,
      //   prop_selectedDivision: this.selectedDivision,
      //   prop_selectedBatch: this.selectedBatch,
      //   prop_selectedGroup: this.selectedGroup,
      //   prop_selectedSemester: this.selectedSemester,
      //   prop_selectedFaculty: this.selectedFaculty
      // }
      //   if (exam) {
      //     objToPush['prop_exam'] = exam
      //   }
      this.$store.commit("liveData/set_selectedExam", null);
      this.$router.push({
        name: 'activityMyExam'
      })
    },
    createMap() {
      const examTabDependencies = {
        tab1: {
          tab2: false,
          tab3: false
        },
        tab2: {
          tab3: false
        },
        tab3: {
          tab1: false,
          tab2: false
        }
      }
      this.$store.commit("liveData/set_examTabDependencies", examTabDependencies);
    },
    beforeDestroy() {
      this.$store.commit(
        "liveData/set_selectedInstitute",
        this.instituteToBeSet
      );
      this.$store.commit(
        "liveData/set_selectedSemester",
        this.semesterToBeSet
      );
    }
  }
}
</script>

<style src="./activityManageExamsRevamped.css"></style>
