<script>
import { Pie } from 'vue-chartjs'
export default {
  extends: Pie,
  props: ['attendanceDistribution'],
  data () {
    return {
      chartOptions: {
        hoverBorderWidth: 20
      },
      chartData: {
        hoverBackgroundColor: 'red',
        hoverBorderWidth: 10,
        // options here
        labels: [],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: [
              '#5EB5EF',
              '#FF829D',
              '#FFD878',
              '#6FCDCD',
              '#AD85FF',
              '#FFB266',
              'rgba(252, 0, 212, 0.67)',
              'rgba(5, 9, 146, 0.76)',
              '#79D306',
              'rgba(0, 117, 167, 0.76)'
            ],
            // values here
            data: []
          }
        ]
      }
    }
  },
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.chartData.labels = Object.keys(this.attendanceDistribution)
    this.chartData.datasets[0].data = Object.values(this.attendanceDistribution)
    this.renderChart(this.chartData, {
      borderWidth: '10px',
      hoverBackgroundColor: 'red',
      hoverBorderWidth: '10px',
      legend: {
        position: 'bottom'
      }
    })
  }
}
</script>
