<template src='./activityCOAttainment.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import NBARepository from '../../Repository/NBA'
import ExamRepository from '../../Repository/Exam'
import ExamSubjectRepository from '../../Repository/ExamSubject'
import SemesterRepository from '../../Repository/Semester'
import ExamStudentRepository from '../../Repository/ExamStudent'
import arrayOps from '../../Services/Utils/arrayOps'
export default {
  name: 'activityCOAttainment',
  components: {
    inputContainer
  },
  props: [
    'prop_subject',
    'prop_subjectNBA'
  ],
  data () {
    return {
      dataFetched: false,
      search: '',
      subjectNBA: {},
      editing: false,
      tempMission: '',
      tempThreshold: '',
      tempRange1: '',
      tempRange2: '',
      tempAttainment1: '',
      tempAttainment2: '',
      tempBase1: '',
      tempBase2: '',
      tempBase3: '',
      addingExamDialog: false,
      secondStageOfAddingExamDialog: false,
      tempSelectedCOs: [],
      tempExamType: '',
      tempExamYear: '',
      tempExam: {},
      examTypes: [
        'tut',
        'abet',
        'insem',
        'endsem',
        'practical',
        'oral'
      ],
      examsYears: [
        'This Year',
        'Last Year',
        'Two years back',
        'Three years back',
        'Four year back'
      ],
      headers: [
        {
          text: 'Exam Name',
          value: 'examName'
        },
        {
          text: 'Exam Type',
          value: 'type'
        },
        {
          text: 'Attached COs',
          value: 'cos'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ],
      headers2: [
        {
          text: 'Semester Name',
          value: 'semName'
        },
        {
          text: 'Exam Name',
          value: 'name'
        },
        {
          text: 'Exam Type',
          value: 'type'
        },
        {
          text: 'Start Date',
          value: 'startDate'
        },
        {
          text: 'End Date',
          value: 'endDate'
        }
      ],
      allExamsList: [],
      examsToShow: [],
      COMapping: {},
      ExamsMapping: {},
      ExamSubjectsMapping: {}
    }
  },
  created () {
    this.NBARepositoryInstance = new NBARepository(this)
    this.examRepositoryInstance = new ExamRepository(this)
    this.examSubjectRepositoryInstance = new ExamSubjectRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.examStudentRepositoryInstance = new ExamStudentRepository(this)
    console.log(this.prop_subjectNBA)
    this.subjectNBA = JSON.parse(JSON.stringify(this.prop_subjectNBA))
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.dataFetched = false
      this.COMapping = {}
      this.subjectNBA.subjectCourseOutcomes.forEach(co => {
        this.COMapping[co.coId] = co
      })
      await this.fetchExams()
      this.discardValueChanges()
    },
    async fetchExams () {
      const allMainExamsList = await this.examRepositoryInstance.getExamsOfAnInstitute({
        instituteId: this.prop_subjectNBA.instituteId
      })
      this.ExamsMapping = {}
      allMainExamsList.forEach(exam => {
        this.ExamsMapping[exam.examId] = exam
      })
      this.allExamsList = await this.examSubjectRepositoryInstance.getExamsForASubject({
        instituteId: this.prop_subjectNBA.instituteId,
        semId: this.prop_subject.semId,
        subjectId: this.prop_subject.subjectId
      })
      this.ExamSubjectsMapping = {}
      this.allExamsList.forEach(exam => {
        this.ExamSubjectsMapping[exam.examId] = exam
      })
      this.examsToShow = []
      const addedExams = this.prop_subjectNBA.exams ? this.prop_subjectNBA.exams.map(exam => { return exam.examId + '_' + exam.subjectId }) : []
      for (let i = 0; i < this.allExamsList.length; i++) {
        const exam = this.allExamsList[i]
        try {
          exam.semName = await this.semesterRepositoryInstance.getNameOfASemester({
            instituteId: this.prop_subjectNBA.instituteId,
            semId: exam.semId
          })
          if (this.ExamsMapping[exam.examId]) {
            exam.name = this.ExamsMapping[exam.examId].name
            exam.startDate = this.ExamsMapping[exam.examId].startDate
            exam.endDate = this.ExamsMapping[exam.examId].endDate
            exam.type = this.ExamsMapping[exam.examId].type
            if (addedExams.includes(exam.examId + '_' + exam.subjectId)) {} else { this.examsToShow.push(exam) }
          }
        } catch (err) {
          console.log(err)
          exam.semName = ''
        }
      }
      this.dataFetched = true
      console.log(this.allExamsList, this.examsToShow)
    },
    addExam () {
      console.log(this.tempExam, this.tempExamType, this.tempSelectedCOs)
      this.secondStageOfAddingExamDialog = false
      this.subjectNBA.exams.push({
        examId: this.tempExam.examId,
        mappedCOs: this.tempSelectedCOs,
        type: this.tempExamType,
        year: this.examsYears.indexOf(this.tempExamYear)
      })
      this.updateSubjectNBA()
    },
    showAddingExamDialog () {
      this.addingExamDialog = true
    },
    discardValueChanges () {
      this.tempThreshold = this.subjectNBA.values ? this.subjectNBA.values.threshold : ''
      this.tempRange1 = this.subjectNBA.values ? this.subjectNBA.values.range1 : ''
      this.tempRange2 = this.subjectNBA.values ? this.subjectNBA.values.range2 : ''
      this.tempAttainment1 = this.subjectNBA.values ? this.subjectNBA.values.attainment1 : ''
      this.tempAttainment2 = this.subjectNBA.values ? this.subjectNBA.values.attainment2 : ''
      this.tempBase1 = this.subjectNBA.values ? this.subjectNBA.values.base1 : ''
      this.tempBase2 = this.subjectNBA.values ? this.subjectNBA.values.base2 : ''
      this.tempBase3 = this.subjectNBA.values ? this.subjectNBA.values.base3 : ''
      this.editing = false
    },
    selectExam (exam) {
      console.log(exam)
      this.tempExam = exam
      this.addingExamDialog = false
      this.tempSelectedCOs = []
      this.secondStageOfAddingExamDialog = true
    },
    saveValues () {
      const threshold = parseInt(this.tempThreshold)
      if (threshold > 0 && threshold < 100) {} else {
        alert('Invalid threshold value!')
        return
      }
      const range1 = parseInt(this.tempRange1)
      const range2 = parseInt(this.tempRange2)
      if (range2 > 0 && range1 > range2 && range1 < 100) {} else {
        alert('Invalid range values!')
        return
      }
      const attainment1 = parseInt(this.tempAttainment1)
      const attainment2 = parseInt(this.tempAttainment2)
      if (attainment2 > 0 && attainment1 > attainment2 && attainment1 < 100) {} else {
        alert('Invalid attainment values!')
        return
      }
      const base1 = parseInt(this.tempBase1)
      const base2 = parseInt(this.tempBase2)
      const base3 = parseInt(this.tempBase3)
      if (base3 > 0 && base2 > base3 && base1 > base2 && base1 < 100) {} else {
        alert('Invalid base values!')
        return
      }
      if (this.subjectNBA.values) {} else { this.subjectNBA.values = {} }
      this.subjectNBA.values.threshold = this.tempThreshold
      this.subjectNBA.values.range1 = this.tempRange1
      this.subjectNBA.values.range2 = this.tempRange2
      this.subjectNBA.values.attainment1 = this.tempAttainment1
      this.subjectNBA.values.attainment2 = this.tempAttainment2
      this.subjectNBA.values.base1 = this.tempBase1
      this.subjectNBA.values.base2 = this.tempBase2
      this.subjectNBA.values.base3 = this.tempBase3
      this.updateSubjectNBA()
    },
    async updateSubjectNBA () {
      console.log('this to update NBA', this.subjectNBA)
      await this.NBARepositoryInstance.updateSubjectNBA(
        this.subjectNBA
      )
      this.editing = false
      this.fetchData()
    },
    removeExam (exam) {
      this.removeFromArray(exam, this.subjectNBA.exams)
      this.updateSubjectNBA()
    },
    removeFromArray (item, arr) {
      arrayOps.removeFromArray(arr, arr.indexOf(item))
    },
    async calculateAttainment () {
      const threshold = parseInt(this.subjectNBA.values.threshold)
      const range1 = parseInt(this.subjectNBA.values.range1)
      const range2 = parseInt(this.subjectNBA.values.range2)
      const attainment1 = parseInt(this.subjectNBA.values.attainment1)
      const attainment2 = parseInt(this.subjectNBA.values.attainment2)
      const base1 = parseInt(this.subjectNBA.values.base1)
      const base2 = parseInt(this.subjectNBA.values.base2)
      const base3 = parseInt(this.subjectNBA.values.base3)
      for (let k = 0; k < this.subjectNBA.subjectCourseOutcomes.length; k++) {
        const co = this.subjectNBA.subjectCourseOutcomes[k]
        console.log('CO: ', co)
        for (let i = 0; i < this.examTypes.length; i++) {
          const examType = this.examTypes[i]
          console.log('Type: ', examType)
          const CAYs = []
          const thisYearExamStudents = []
          const applicableExams = this.subjectNBA.exams.filter(ex => ex.type === examType && ex.mappedCOs.includes(co.coId))
          const examsWithYearMap = {}
          applicableExams.forEach(ex => {
            if (examsWithYearMap[ex.year]) {} else {
              examsWithYearMap[ex.year] = []
            }
            examsWithYearMap[ex.year].push(ex)
          })
          for (let j = 0; j < Object.keys(examsWithYearMap).length; j++) {
            const year = Object.keys(examsWithYearMap)[j]
            // const examDetails = this.ExamsMapping[exam.examId]
            let aboveThresholdCount = 0
            let totalStudents = 0
            for (let l = 0; l < examsWithYearMap[year].length; l++) {
              const exam = examsWithYearMap[year][l]
              const examSubjectDetails = this.ExamSubjectsMapping[exam.examId]
              const totalObtainableMarks = parseInt(examSubjectDetails.totalObtainableMarks)
              const examStudents = await this.examStudentRepositoryInstance.getExamStudentForAnExam({
                instituteId: examSubjectDetails.instituteId,
                semId: examSubjectDetails.semId,
                examId: examSubjectDetails.examId
              })
              totalStudents += examStudents.length
              examStudents.forEach(examStudent => {
                const totalObtainedMarks = parseInt(examStudent.totalObtainedMarks)
                const percent = ((totalObtainedMarks / totalObtainableMarks) * 100)
                if (percent > threshold) {
                  aboveThresholdCount++
                }
                if (year === 0) {
                  thisYearExamStudents.push(percent)
                }
              })
            }
            console.log(aboveThresholdCount)
            CAYs.push((aboveThresholdCount / totalStudents) * 100)
          }
          let sum = 0
          CAYs.forEach(cay => { sum += cay })
          const X = sum / CAYs.length
          let DL = 3
          if (X > range2) {
            DL = 2
          }
          if (X > range1) {
            DL = 1
          }
          let base = 0
          if (DL === 3) {
            base = base3
          }
          if (DL === 2) {
            base = base2
          }
          if (DL === 1) {
            base = base1
          }
          console.log(base, thisYearExamStudents.length)
          let numberOfStudentsScoringMoreThanBase = 0
          thisYearExamStudents.forEach(student => {
            if (student > base) {
              numberOfStudentsScoringMoreThanBase++
            }
          })
          console.log(numberOfStudentsScoringMoreThanBase)
          const numberOfStudentsScoringMoreThanBasePercent = (numberOfStudentsScoringMoreThanBase / thisYearExamStudents.length) * 100
          let attainment = 3
          if (numberOfStudentsScoringMoreThanBasePercent > attainment2) {
            attainment = 2
          }
          if (numberOfStudentsScoringMoreThanBasePercent > attainment1) {
            attainment = 1
          }
          console.log(attainment)
          co.directAttainment[examType] = attainment.toString()
        }
      }
    }
  }
}
</script>
<style scoped src='./activityCOAttainment.css'>
</style>
