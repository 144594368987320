<template src="./hallTicket.html"></template>

<script>
import SubjectRepository from "../../../Repository/Subjects";
import NewExamRepository from "../../../Repository/NewExam";
import ExamSubjectAndQuestionRepository from "../../../Repository/ExamSubjectAndQuestion";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import UserRepository from "../../../Repository/User";
import UserAuthenticationRepository from "../../../Repository/UserAuthentication";
import ExamFormAndHallTicketRepository from "../../../Repository/ExamFormAndHallTicket";
import InstituteRepository from "../../../Repository/Institute";
import showStatus from "../../../NetworkManager/showStatus";
import apiV3 from "../../../NetworkManager/apiV3";

export default {
  name: "hallTicket",
  data() {
    return {
      primaryColor: "#050D92",
      secondaryColor: "#FF4F1F",
      progressIndicator: 12,
      examTabs: ["Student List", "Hall Ticket"],
      tabs: "",
      headers: [
        {
          text: "Publish Hall Ticket",
          value: "isHallTicketPublished",
          align: "center",
          sortable: false,
          width: "15%"
        },
        { text: "Sr. No", value: "srNo", align: "center" },
        { text: "Roll. No", value: "rollNumber", align: "center" },
        { text: "PRN", value: "collegePRNNo", align: "center" },
        { text: "Exam Seat Number", value: "examSeatNumber", align: "center" },
        { text: "Student Name", value: "studentName", align: "center" },
        {
          text: "Exam Form",
          value: "isExamFormAccepted",
          align: "center"
        },
        {
          text: "Remarks",
          value: "examFormRemark",
          align: "center",
          width: "20%"
        },
        {
          text: "Hall Ticket Status",
          value: "viewHallTicket",
          align: "center"
        }
      ],
      headersStudent: [
        { text: "Year/sem", value: "semester", align: "center" },
        { text: "Subject Code", value: "subjectCode", align: "center" },
        { text: "Subject Name", value: "subjectName", align: "center" },
        { text: "Exam Date", value: "examDate", align: "center" },
        { text: "Exam Time", value: "examTime", align: "center" }
      ],
      selectedDepartment: "",
      selectedCourseYear: "",
      selectedInstitute: "",
      selectedSemester: null,
      examId: null,
      courseYearStudents: [],
      search: "",
      hallTicketTableData: [],
      // remarksItem: ["Exam fee not paid", "college fee not paid", "others"],
      examFormDialog: false,
      selectedStudent: {},
      emailIdsOfStudents: [],
      exam: {},
      subjects: [],
      subjectsData: [],
      fetchedData: [],
      instituteInfo: {},
      examTabDependecies: {},
      filterMenu: false,
      filterOption: null,
      // examFormAndHallTicketInstance: false, //
      filteredHallTicketTableData: [],
      loading: false,
      publishAllHallTicketCheckbox: false,
      tabLoading: true,
      isSubjectsDataAvailable: true
    };
  },
  activated() {
    console.log("activated");
    // Your logic here
    const newValue = this.$store.state.liveData.examTabDependencies;
    let flag = false;
    Object.entries(newValue).forEach(([key, value]) => {
      console.log("examTabDependecies changed:123456' value", value);
      if (value["tab3"] === true) {
        flag = true;
        value["tab3"] = false;
      }
    });
    console.log("flag", flag);
    if (flag === true) {
      console.log("here to fetch data");
      this.initialize();
    }
  },
  async created() {
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.examSubjectAndQuestionRepositoryInstance =
      new ExamSubjectAndQuestionRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.userAuthenticationInstance = new UserAuthenticationRepository(this);
    this.examFormAndHallTicketInstance = new ExamFormAndHallTicketRepository(
      this
    );
    this.InstituteRepositoryInstance = new InstituteRepository(this);
    this.selectedDepartment =
      this.$store.getters["liveData/get_examDepartment"];
    this.selectedCourseYear =
      this.$store.getters["liveData/get_examCourseYear"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.examId = this.$store.getters["liveData/get_examId"];
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.getStudentsOfACourseYear();
      await this.getUsersDetails();
      await this.getUsersEmailId();
      await this.getStudentsExamForm();
      await this.getExam();
      await this.fetchSubjects();
      await this.getSubjectData();
      this.prepareHallTicketTable();
      this.calculateProgressIndicator();
      await this.temp();
      this.checkAllSelected();
      this.examTabDependecies =
        this.$store.getters["liveData/get_examTabDependencies"];
      // state['tab3'].isTriggered = true;
      this.prepareDataForHallTicket();
      this.tabLoading = false;
    },
    async getStudentsOfACourseYear() {
      try {
        this.courseYearStudents =
          await this.semesterUserRepositoryInstance.getStudentsOfACourseYear({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear
          });
      } catch (error) {
        this.courseYearStudents = [];
      }
    },
    async getUsersDetails() {
      const studentIds = this.courseYearStudents.map((student) => student.uId);
      const data = await this.userRepositoryInstance.getUsers({
        uIds: studentIds
      });
      this.userDetails = data;
      console.log("use detials", this.userDetails);
    },
    async getUsersEmailId() {
      // emailIdsOfStudents
      const studentIds = this.courseYearStudents.map((student) => student.uId);
      const allEmails =
        await this.userAuthenticationInstance.getUserAuthenticationsByUIds({
          uIds: studentIds
        });
        const filteredEmails = [];
      allEmails.forEach((item, index) => {
        const { uId } = item;
        filteredEmails[uId] = item;
      });
      this.emailIdsOfStudents = Object.values(filteredEmails);
    },
    async getExam() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          examId: this.examId
        };
        const res = await this.newExamRepositoryInstance.getExam(objToPush);
        this.exam = res.exam;
        console.log("exam resssssscueddss", this.exam);
        // this.exam = res
      } catch (error) {
        console.error("error", error);
      }
    },
    async fetchSubjects() {
      const res = await this.subjectRepositoryInstance.getSubjects({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        subjectIds: this.exam.subjectIds
      });
      this.subjects = res;
    },
    async getSubjectData() {
      console.log("response 99", this.exam);
      const response =
        await this.examSubjectAndQuestionRepositoryInstance.getExamMultipleSubjectQuestions(
          {
            instituteId: this.selectedInstitute,
            examId: this.examId,
            subjectIds: this.exam.subjectIds
          }
        );
        if (response.result.length === 0) {
          this.isSubjectsDataAvailable = false;
          return;
        }
      console.log("response of subjects 99", response);
      // let subjectsData = response.result
      const temp = [];
      this.subjects.forEach((subject) => {
        console.log("subject696969", subject);
        const data = response.result.find(
          (item) => subject.subjectId === item.subjectId
        );
        console.log("data696969", data);
        temp.push({
          subjectCode: subject.subjectCode,
          subjectName: subject.subjectName,
          allocatedStudents: data.allocatedStudents,
          semester: this.selectedSemester.semName,
          credits: subject.credits,
          examDate: data.examDate,
          examTime: data.examTime,
          examDuration: data.examDuration
        });
      });
      this.subjectsData = temp;
      console.log("temp of subjects", temp);
    },
    prepareHallTicketTable() {
      const temp = [];
      this.courseYearStudents.forEach((student, index) => {
        const userDetails = this.userDetails.find(
          (user) => student.uId === user.uId
        );
        const userEmail = this.emailIdsOfStudents.find((user) => student.uId === user.uId) ? this.emailIdsOfStudents.find((user) => student.uId === user.uId).email : ''
        const examFormDataForStudent = this.fetchedData.find(
          (item) => item.uId === student.uId
        );
        console.log("examFormDataForStudent", examFormDataForStudent);
        console.log("user details", userDetails);
        console.log("user details strudent", student);
        const subData = this.subjectsData.filter((subject) =>
          subject.allocatedStudents.includes(student.uId)
        );
        let isHallTicketPublished;
        if (examFormDataForStudent?.isHallTicketPublished !== undefined) {
          isHallTicketPublished = examFormDataForStudent.isHallTicketPublished
        } else {
          isHallTicketPublished = true
        }
        console.log("subDataForStudent", subData);
        temp.push({
          rollNumber: student.rollNumber,
          collegePRNNo: userDetails.collegePRNNo,
          studentName: userDetails.firstName + " " + userDetails.lastName,
          isExamFormAccepted: examFormDataForStudent
            ? examFormDataForStudent.isExamFormAccepted
            : false,
          examFormRemark: examFormDataForStudent
            ? examFormDataForStudent.examFormRemark
            : null,
          isHallTicketPublished: isHallTicketPublished,
          contactNumber: userDetails.mobile,
          userEmail: userEmail,
          uId: student.uId,
          gender: userDetails.gender,
          category: userDetails.category,
          isChanged: !(examFormDataForStudent?.isExamFormAccepted),
          subjectsData: subData,
          subjects: subData,
          examSeatNumber: student.examSeatNumber,
          motherName: userDetails.motherName || '-',
          examDetails: []
        });
      });
      console.log("examFormDataForStudent", temp);
      this.hallTicketTableData = temp;
      this.filteredHallTicketTableData = this.hallTicketTableData;
      console.log("filteredHallTicketTableData", this.filteredHallTicketTableData)
      console.log("HallTicketTableData", this.hallTicketTableData)
      console.log("instit info", this.instituteInfo)
    },
    handleHallTicketDialog(item) {
      this.examFormDialog = true;
      console.log("item", item);
      item.subjectsData = this.subjectsData.filter((subject) =>
        subject.allocatedStudents.includes(item.uId)
      );
      console.log("item", item)
      this.selectedStudent = item;
    },
    async saveAndPublishHallTicket() {
      const changedItems = this.hallTicketTableData
        .filter((item) => item.isChanged === true)
        .map(
          ({
            uId,
            isExamFormAccepted,
            examFormRemark,
            isHallTicketPublished
          }) => ({
            uId,
            // isExamFormAccepted,
            // examFormRemark,
            isHallTicketPublished,
            examId: this.examId,
            semId: this.selectedSemester.semId,
            instituteId: this.selectedInstitute,
            courseYear: this.selectedCourseYear
          })
        );
      console.log("changedItems", changedItems);
      try {
        if (changedItems.length > 0) {
          const res =
            await this.examFormAndHallTicketInstance.createExamFormAndHallTicket(
              changedItems
            );
          console.log("Res", res);
          if (res.status.status === 200) {
            this.hallTicketTableData.forEach((item) => {
              const present = changedItems.find((changedItem) => changedItem.uId === item.uId)
              console.log("Present", present, item)
              if (present) {
                item.isChanged = false
              }
            })
            showStatus("Successfully saved.", 2000, "success", this);
          }
        } else {
          showStatus("Result for students is already published", 2000, "warning", this);
        }
      } catch (error) {
        // console.error("Error",error)
        showStatus("Something went wrong", 2000, "error", this);
      }
      //herer\
      // for(let key in  this.examTabDependecies['tab3']){
      //   console.log("key99", key);
      //   // this.examTabDependecies['tab3'][key] = Math.random();
      //   this.examTabDependecies['tab3'][key] = true;
      // }
      // console.log("state99", this.examTabDependecies)
    },
    async getStudentsExamForm() {
      const studentIds = this.courseYearStudents.map((student) => student.uId);
      const res =
        await this.examFormAndHallTicketInstance.getExamFormAndHallTicket({
          instituteId: this.selectedInstitute,
          examId: this.examId,
          uIds: studentIds
        });
      this.fetchedData = res.result;
      console.log("detials fetched", res);
    },
    calculateProgressIndicator() {
      this.progressIndicator =
        (this.hallTicketTableData.filter((item) => item.isHallTicketPublished)
          .length /
          this.hallTicketTableData.length) *
        100;
    },

    async temp() {
      //get insti info
      this.instituteInfo =
        await this.InstituteRepositoryInstance.getInstituteInformation({
          instituteId: this.selectedInstitute
        }); //get exam info
      console.log("this info fetched insti info", this.instituteInfo);
      console.log("this info stundet", this.courseYearStudents);
      console.log("this info stundet considet", this.hallTicketTableData);
      console.log("this info exam", this.exam);
      //get stundet info
    },
    async downloadHallTicket() {
      this.loading = true;
      const objToPush = {
        instituteData: this.instituteInfo,
        studentData: [this.selectedStudent]
        // examData: this.exam
      };
        try {
          const result = await apiV3.postRequest(
            "/manageExam/generateStudentHallTicket",
            objToPush
          );
          if (result) {
        window.open(result.data.pdfUrl);
        }
          // this.downloadFileFromBuffer(result); // pass the buffer of the file
          showStatus('Hall Ticket downloaded successfully.', 2000, 'success', this)
        } catch (error) {
          console.error('Error Downloading Hall Ticket', error);
        }
        this.loading = false;
    },

    // downloadFileFromBuffer(result) {
    //   const blob = new Blob([result.data.pdfUrl], { type: 'application/pdf' });
    //   // Create a download link
    //   const link = document.createElement('a');
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = 'hall_ticket.pdf';
    //   // Append the link to the document and trigger a click
    //   document.body.appendChild(link);
    //   link.click();
    //   // Remove the link from the document
    //   document.body.removeChild(link);
    //   showStatus(
    //     "Error fetching link for online lecture .",
    //     1000,
    //     "success",
    //     this
    //   );
    // },
    calculateTimeFrame(startTime, durationMinutes) {
    // Splitting the startTime into hours and minutes
    let [startHour, startMinute] = startTime.split(':').map(Number);
    // Calculating total minutes
    const totalMinutes = startHour * 60 + startMinute + durationMinutes;
    // Calculating end time hours and minutes
    let endHour = Math.floor(totalMinutes / 60);
    const endMinute = totalMinutes % 60;

    // Adjusting endHour and endMinute if they exceed 24 hours
    endHour = endHour % 24;

    // Determining AM or PM for start and end times
    const startPeriod = startHour < 12 ? 'AM' : 'PM';
    const endPeriod = endHour < 12 ? 'AM' : 'PM';

    // Converting startHour and endHour to 12-hour format
    if (startHour === 0) {
        startHour = 12; // 12 AM
    } else if (startHour > 12) {
        startHour -= 12;
    }

    if (endHour === 0) {
        endHour = 12; // 12 AM
    } else if (endHour > 12) {
        endHour -= 12;
    }

    // Formatting start and end times
    const formattedStartTime = `${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')} ${startPeriod}`;
    const formattedEndTime = `${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')} ${endPeriod}`;

    return `${formattedStartTime} - ${formattedEndTime}`;
    },
    prepareDataForHallTicket() {
      console.log("exams9090909", this.instituteInfo);
      console.log("check90909", this.instituteInfo);
      // this.hallTicketTableData
      if (this.instituteInfo.examFormFormat) {
          this.examFormFormatPresent = true;
        }
        if (this.instituteInfo.hallTicketFormat) {
          this.hallTicketFormatPresent = true;
          this.hallTicketFormat = this.instituteInfo.hallTicketFormat
          if (this.hallTicketFormat.signaturePhoto.length > 0) {
            this.officialSignaturePresent = true
          }
          if (this.hallTicketFormat.isCollageStampNeeded) {
            this.collegeStampUrl = this.hallTicketFormat.collageStampPhoto[0].url
            this.collegeStampName = this.hallTicketFormat.collageStampPhoto[0].name
          }
        } else {
          this.hallTicketFormat = {}
        }
      this.subjectsData.map((sub, index) => {
        console.log("inside subject: ", sub, index)
        this.hallTicketTableData.map((stud) => {
          console.log("stud: ", stud)
          // stud['examDetails'] = []
          let timeFrame = '-'
          if (sub.examTime && sub.examDuration) {
            timeFrame = this.calculateTimeFrame(sub.examTime, sub.examDuration)
            console.log("timeFrame: ", timeFrame)
          }
          console.log("this exam 898", this.exam)
          const getDayFromDate = dateString => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][new Date(dateString).getDay()];
          // const dayOfWeek = getDayFromDate(sub);
          // console.log("day of week",dayOfWeeks)
          const obj = {
                    subjectName: sub.subjectName,
                    date: sub.examDate,
                    subjectCode: sub.subjectCode,
                    timeFrame: timeFrame,
                    typeOfExam: this.exam.examFormat,
                    semName: this.selectedSemester.semName,
                    department: this.selectedDepartment,
                    courseYear: this.selectedCourseYear,
                    examDay: getDayFromDate(sub.examDate)
                  };
            stud.examDetails.push(obj);
        })
      })
      if (this.instituteInfo.hallTicketFormat) {
        this.instituteInfo.hallTicketFormat["tableColumns"] = [];
        this.instituteInfo.hallTicketFormat.examDetails.map((item) => {
          const objToPush = {};
          if (item === "Department") {
            objToPush["key"] = "Department";
            objToPush["value"] = "department";
          }
          if (item === "Type of Exam") {
            objToPush["key"] = "Type of Exam";
            objToPush["value"] = "typeOfExam";
          }
          if (item === "Semester") {
            objToPush["key"] = "Semester";
            objToPush["value"] = "semName";
          }
          if (item === "Subject Code") {
            objToPush["key"] = "Subject Code";
            objToPush["value"] = "subjectCode";
          }
          if (item === "Subject Name") {
            objToPush["key"] = "Subject Name";
            objToPush["value"] = "subjectName";
          }
          if (item === "Exam Day") {
            objToPush["key"] = "Exam Day";
            objToPush["value"] = "examDay";
          }
          if (item === "Exam Time") {
            objToPush["key"] = "Exam Time";
            objToPush["value"] = "timeFrame";
          }
          if (item === "Exam Date") {
            objToPush["key"] = "Exam Date";
            objToPush["value"] = "date";
          }
          if (item === "Course Year") {
            objToPush["key"] = "Course Year";
            objToPush["value"] = "courseYear";
          }
          this.instituteInfo.hallTicketFormat.tableColumns.push(objToPush);
        });
      }
    },
    handleFilter() {
      if (this.filterOption === 0) {
        this.filteredHallTicketTableData = this.hallTicketTableData;
      } else if (this.filterOption === 1) {
        //only hall ticket published
        this.filteredHallTicketTableData = this.hallTicketTableData.filter(
          (item) => item.isHallTicketPublished === true
        );
      } else if (this.filterOption === 2) {
        //hall ticket not published
        this.filteredHallTicketTableData = this.hallTicketTableData.filter(
          (item) => item.isHallTicketPublished === false
        );
      } else if (this.filterOption === 3) {
        //student with remarks
        this.filteredHallTicketTableData = this.hallTicketTableData.filter(
          (item) => item.examFormRemark !== null
        );
      } else if (this.filterOption === 4) {
        //exam form accepted with remarks
        this.filteredHallTicketTableData = this.hallTicketTableData.filter(
          (item) => item.isExamFormAccepted === true && item.examFormRemark
        );
      } else if (this.filterOption === 5) {
        //exam form accepted
        this.filteredHallTicketTableData = this.hallTicketTableData.filter(
          (item) => item.isExamFormAccepted === true
        );
      }
      console.log("three", this.filteredHallTicketTableData);
      this.filterMenu = false;
    },
    goToNextPage() {
            console.log("next page")
            this.$emit('nextButtonClick');
        },
        publishAllHallTicket() {
          // console.log("acceptAllExamFormCheckbox",this.acceptAllExamFormCheckbox)
          const acceptValue = this.publishAllHallTicketCheckbox;
          this.hallTicketTableData.forEach((item) => {
            if (item.isHallTicketPublished !== acceptValue) {
              item.isHallTicketPublished = acceptValue;
              item.isChanged = true;
            }
          });
      },
      checkAllSelected() {
      this.publishAllHallTicketCheckbox = this.hallTicketTableData.every(item => item.isHallTicketPublished);
    }
  }
};
</script>

<style src="./hallTicket.css"></style>
