<template src="./activityAssessments.html"></template>

<script>
import showStatus from '../../../NetworkManager/showStatus';
import AssessmentRepository from "../../../Repository/Assessment";
import AssignmentRepository from '../../../Repository/Assignment'

export default {
    name: 'activityAssessments',
    props: [
        'prop_subject', 'prop_type', 'prop_groupInfo'
    ],
    data() {
        return {
            primaryOrange: '#FF4F1F',
            assessmentHeaders: [
                {
                    text: '',
                    value: 'isForNBA',
                    sortable: false,
                    width: '5%'
                },
                {
                    text: 'Status',
                    value: 'status',
                    sortable: false,
                    align: 'center',
                    width: '5%'
                },
                {
                    text: 'Assessment Name',
                    align: 'center',
                    value: 'title'
                },
                {
                    text: 'File Types',
                    align: 'center',
                    value: 'allowedFileTypes'
                },
                {
                    text: 'Published',
                    align: 'center',
                    value: 'publishedDate'
                },
                {
                    text: 'Due',
                    align: 'center',
                    value: 'dueDate'
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    align: 'center',
                    sortable: false
                }
            ],
            pastAssessmentHeaders: [],
            assessments: [
                {
                    title: 'First Assessment',
                    fileTypes: 'PDF, PNG, JPEG',
                    published: '3/11/2022, 2:49 PM',
                    due: '5/11/2022, 12:00 AM',
                    actionDelete: 'mdi-delete-outline',
                    nbaFlag: true
                },
                {
                    title: 'Second Assessment',
                    fileTypes: 'PDF, PNG, JPEG',
                    published: '5/11/2022, 8:49 PM',
                    due: '9/11/2022, 12:00 AM',
                    actionDelete: 'mdi-delete-outline'
                }
            ],
            loadAssessments: false,
            deleteAssessmentDialog: false,
            assessmentToDelete: {},
            assessmentList: [],
            selectedSubject: {},
            assessmentsLoaded: false,
            deleteAssessmentLoader: false,
            assessmentForNba: false
        }
    },
    created() {
        // console.log('prop_groupInfo1111', this.prop_groupInfo)
        this.$store.commit('liveData/set_selectedActivityName', ' Assessments')

        this.selectedSubject = this.$store.getters['liveData/get_selectedSubject']

        this.assessmentRepositoryInstance = new AssessmentRepository(this);
        this.assignmentRepositoryInstance = new AssignmentRepository(this)

        this.dummy()
        this.getAssessments()
    },
    methods: {
        async getAssessments() {
            this.assessmentsLoaded = true
            this.assessmentList = []
            try {
                const objToPush = {
                    instituteId: this.selectedSubject.instituteId,
                    semId: this.selectedSubject.semId,
                    department: this.selectedSubject.department,
                    courseYear: this.selectedSubject.courseYear,
                    subjectId: this.selectedSubject.subjectId
                };

                if (this.prop_type === 'Group') {
                    objToPush.groupId = this.prop_groupInfo.groupId;
                }
                if (this.prop_type === 'Division') {
                    objToPush.division = this.selectedSubject.division;
                }
                if (this.prop_type === 'Batch') {
                    objToPush.division = this.selectedSubject.division;
                    objToPush.batch = this.selectedSubject.batch;
                }
                console.log('objToPush', objToPush);
                let assignmentsList = [];

                if (this.prop_type === 'Group') {
                    assignmentsList = await this.assignmentRepositoryInstance.getAssignmentsByAFacultyForASubjectForAGroup(objToPush);
                }
                if (this.prop_type === 'Division' || this.prop_type === 'Batch') {
                    assignmentsList = await this.assignmentRepositoryInstance.getAssignmentsByAFacultyForASubjectForADivision(objToPush);
                    console.log('assignmentsList', assignmentsList);
                }

                this.assessmentList = assignmentsList.filter(item => item.assignmentType === 'Rubric');

                // console.log('this.assessmentList', this.assessmentList);
                this.assessmentList.sort(function (a, b) {
                    return new Date(b.dateOfAssignment.slice(0, 10)) - new Date(a.dateOfAssignment.slice(0, 10));
                });
                this.assessmentsLoaded = false
            } catch (error) {
                console.log('error', error);
                this.assessmentsLoaded = false
            }
        },
        createAssessment() {
            this.$router.push({
                name: 'activityCreateAssessment',
                params: {
                    prop_subject: this.prop_subject,
                    prop_type: this.prop_type,
                    prop_groupInfo: this.prop_groupInfo
                }
            })
        },
        dummy() {
            this.pastAssessmentHeaders = [...this.assessmentHeaders]
            // this.pastAssessmentHeaders = JSON.parse(JSON.stringify(this.assessmentHeaders))
            this.pastAssessmentHeaders.splice(5, 0, {
                text: 'Submission %',
                value: 'submission'
            })
        },
        gotoEditAssessment(item) {
            this.$router.push({
                name: 'activityEditAssessment',
                params: {
                    prop_assessment: item,
                    prop_subject: this.prop_subject,
                    prop_type: this.prop_type,
                    prop_groupInfo: this.prop_groupInfo
                }
            })
        },
        confirmDeleteAssessment(item) {
            // console.log('item', item)
            this.assessmentToDelete = item
            this.assessmentForNba = item.isForNBA
            this.deleteAssessmentDialog = true
        },
        async deleteAssessment() {
            this.deleteAssessmentLoader = true
            try {
                await this.assignmentRepositoryInstance.deleteAssignment({
                    instituteId: this.selectedSubject.instituteId,
                    semId: this.selectedSubject.semId,
                    assignmentId: this.assessmentToDelete.assignmentId
                })
                this.deleteAssessmentDialog = false
                this.deleteAssessmentLoader = false
                this.getAssessments()
                showStatus('Assessment deleted successfully.', 2000, 'success', this)
            } catch (error) {
                console.log('delete assessment error', error);
                this.deleteAssessmentDialog = false
                this.deleteAssessmentLoader = false
                showStatus('Error deleting assessment.', 9000, 'error', this)
            }
        }
    }
}
</script>

<style src="./activityAssessments.css" scoped></style>
