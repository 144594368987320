import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/Topic";

class TopicsRepository {
  constructor(context) {
    this.context = context;
  }

  async createTopicMultipleTopics(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createTopicMultipleTopics,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.topic;
  }

  async createTopic(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createTopic,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.topic;
  }

  async generateTopicReport(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.downloadFile(
      ApiNames.generateTopicReport,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getTopicsOfASubjectOfAFaculty(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getTopicsOfASubjectOfAFaculty,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.topics;
  }

  async getTopicOfALecture(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getTopicOfALecture,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.topics;
  }

  async markAsImplemented(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.markAsImplemented,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.isImplemented;
  }

  async updateTopic(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.updateTopic,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async markAsUnimplemented(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.putRequest(
      ApiNames.markAsUnimplemented,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.isImplemented;
  }

  async deleteTopic(data) {
    this.context.$store.commit("liveData/set_loading", true);
    await networkManager.deleteRequest(ApiNames.deleteTopic, data);
    this.context.$store.commit("liveData/set_loading", false);
  }

  async getTopicsOfASubject(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getTopicsOfASubject,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.topics;
  }

  async getTopicsOfADivisionOfASubject(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getTopicsOfADivisionOfASubject,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.topics;
  }
}

export default TopicsRepository;
