import networkManager, { spacesUrl } from "../NetworkManager";
const getSignedUrl = "/spaces/presigned_url";

// let progressNumber;

class uploadToDigitalOcean {
  constructor(context) {
    this.context = context;
  }

  async upload(fileArr, uId, folderName) {
    const path = `${uId}/${folderName}/`;
    const promisesReq = [];
    fileArr.map((file) => {
      const fileName = path + file.name;
      const body = {
        fileName,
        fileType: file.type
      };
      promisesReq.push(this.getSignedUrl(body));
    });
    const signedUrls = await Promise.all(promisesReq);

    const uploadPromises = [];
    if (signedUrls.length === fileArr.length) {
      fileArr.forEach((documentFile, i) => {
        uploadPromises.push(
          uploadToSpaces(documentFile, signedUrls[i].signedUrl)
        );
      });
      await Promise.all(uploadPromises);
      return signedUrls.map((item, i) => ({
        url: spacesUrl + "/" + item.fileName,
        name: fileArr[i].name,
        mimeType: item.mimeType
      }));
    } else {
      return [];
    }
  }

  async getSignedUrl(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(getSignedUrl, data, {
      "Content-Type": "application/json"
    });
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
}
async function uploadToSpaces(file, signedUrl) {
  let progressNumber;

    let currentlyUploadingNumber = 0;
    let currentUploadProgress = 0;
  const res = await futch(
    signedUrl,
    {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
        "x-amz-acl": "public-read"
      }
    },
    (event) => {
      const progress = parseInt((event.loaded / event.total) * 100);
      // console.log('progress', progress);
      progressNumber = progress;
      setTimeout(() => {
        currentUploadProgress = progress;
        if (progress > 99) {
          currentlyUploadingNumber++;
        }
      }, 200);
      console.log(progressNumber, currentlyUploadingNumber, currentUploadProgress);
    }
  );
  return res;
}
function futch(url, opts = {}, onProgress) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open(opts.method || "get", url);
    for (var k in opts.headers || {}) {
      xhr.setRequestHeader(k, opts.headers[k]);
    }
    xhr.onload = (e) => resolve(e.target.responseText);
    xhr.onerror = reject;
    if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
    xhr.send(opts.body);
  });
}
export default uploadToDigitalOcean;
