<template src='./activityEditExam.html'></template>
<script>
import ExamRepository from "../../../Repository/Exam";

import ExamStudentRepository from "../../../Repository/ExamStudent";
import SemesterRepository from "../../../Repository/Semester";
import SubjectsRepository from "../../../Repository/Subjects";
import showStatus from "../../../NetworkManager/showStatus";
import inputContainer from "../../../Components/inputContainer";
import VueApexCharts from "vue-apexcharts";
import apiV3 from "../../../NetworkManager/apiV3";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import NbaRepository from "../../../Repository/NBA";
import UserRepository from "../../../Repository/User";
import InfrastructureReferenceRepository from "../../../Repository/InfrastructureReference";
// import nba from "../../../NetworkManager/nba";
import { subjectTypes } from "../../../Constants/Utils/Statics";
import auth from "../../../Services/Firebase/auth";
import axios from "axios";
export default {
  name: "activityCreateExam",
  components: {
    inputContainer,
    apexcharts: VueApexCharts
  },

  data() {
    return {
      notSelectedStudentList: [],
      selectedStudentList: [],
      passingMarks: 0,
      ThirdPageDataIsLoaded: true,
      saveChangeBtnAssesmentPage: true,
      saveChangesBtnDisabled: true,
      dialogWarning: false,
      newSelectionSubjectListForGraph: [],
      subjectAssinedToFacultyDummy: [],
      subjectAssinedToFaculty: [],
      allStudentBtn: true,
      failStudentBtn: false,
      basicDetailsLoader: false,
      isBasicNbaDetailEditable: true,
      updatedSubjectThresholdArray: [],
      subjectThresholdArray: [],
      subjectThreshold: [],
      nbaAttainmentObject: {},
      subjectMark: [],
      updatedAllocatedSubjects: [],
      allSubjectDetails: [],
      subjectNameArray: [],
      subjectIdArray: [],
      departmentNameArray: [],
      courseYearArray: [],
      examDescription: "",
      selectedExamType: "",
      examEndDate: "",
      examStartDate: "",
      examDepartmentName: "",
      examCourseYear: "",
      // examName: '',
      overallThresholdPercentage: 0,
      allSubjects: false,
      sub: "",
      nbaCriteria: false,
      e1: 1,
      limits: [0, 100],
      selectedExam: {},
      isBasicDetailEditable: false,
      isSubjectDetailEditable: false,
      uploadQuestionsDialog: false,
      // uploadMarksDialog: false,
      thresholdType: null,
      examType: "",
      userData: null,
      examName: "",
      // examDescription: "",
      criteria: [],
      startDate: "",
      endDate: "",
      isOnline: "",
      examTypes: ["Insem Exam", "EndSem Exam", "Class Test", "ABET"],
      runningSemesters: [],
      modal1: false,
      modal2: false,
      loading: true,
      emptyName: false,
      emptyDescription: false,
      noCriteria: false,
      noStartDate: false,
      invalidStartDate: false,
      noEndDate: false,
      invalidEndDate: false,
      checkbox: true,
      dialog: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialogExcel: false,
      selectedDepartment: "",
      selectedCourseYear: "",
      selectedDivision: "",
      departments: [],
      courseYears: [],
      divisions: [],
      selectedInstitute: "",
      selectedSemester: {},
      uploadMarksDialog: false,
      uploadMarksManuallyStatus: false,
      uploadQuestionManuallyStatus: false,
      overallPercentageStatus: false,
      indivitualThresholdStatus: false,
      headers: [
        {
          text: "Ques No.s",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Question", value: "Question" },
        { text: "Marks", value: "Marks" },
        { text: "Mapped COs", value: "MappedCOs" }
      ],
      // donut chart
      donutOptions: {
        chart: {
          type: "donut",
          zoom: {
            enabled: false
          }
        },
        states: {
          hover: {
            filter: {
              type: "none"
            }
          }
        },
        noData: {
          text: "No Data.",
          style: {
            color: "red",
            fontSize: "22px"
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          fontSize: "14px",
          position: "right",
          formatter: function (donutSeries, opts) {
            return [
              donutSeries,
              " - ",
              opts.w.globals.series[opts.seriesIndex]
            ];
          },
          markers: {
            width: 25,
            height: 25,
            radius: 20
          },
          itemMargin: {
            vertical: 5
          }
        },
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              size: "55%"
            }
          }
        },
        labels: [
          "0 % - 10 %",
          "10 % - 20 %",
          "20 % - 30 %",
          "30 % - 40 %",
          "40 % - 50 %",
          "50 % - 60 %",
          "60 % - 70 %",
          "70 % - 80 %",
          "80 % - 90 %",
          "90 % - 100 %"
        ],
        colors: [
          "#FA3333",
          "#FA8733",
          "#FAB533",
          "#07603F",
          "#0DA5A5",
          "#11BC7D",
          "#060E9A",
          "#720E9A",
          "#3361FA",
          "#B4B6EA"
        ],
        series: []
      },
      // Bottom Dialog Box Table
      bloomsDonutOptions: {
        chart: {
          type: "donut",
          zoom: {
            enabled: false
          }
        },
        states: {
          hover: {
            filter: {
              type: "none"
            }
          }
        },
        noData: {
          text: "No Data.",
          style: {
            color: "red",
            fontSize: "22px"
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          fontSize: "14px",
          position: "right",
          formatter: function (donutSeries, opts) {
            return [
              donutSeries,
              " - ",
              opts.w.globals.series[opts.seriesIndex],
              "%"
            ];
          },
          markers: {
            width: 25,
            height: 25,
            radius: 20
          },
          itemMargin: {
            vertical: 5
          }
        },
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              size: "55%"
            }
          }
        },
        labels: [
          "Create (C)",
          "Evaluate (E)",
          "Analyse (A)",
          "Apply (Ap)",
          "Understand (U)",
          "Remember (R)"
        ],
        colors: [
          "#FA3333",
          "#FA8733",
          "#07603F",
          "#0DA5A5",
          "#060E9A",
          "#720E9A"
        ],
        series: []
      },
      grandTotalOfObtainedMarks: 0,
      selectedBloomSubjectName: "all",
      bloomsDataObject: {
        "Create (C)": 0,
        "Evaluate (E)": 0,
        "Analyse (A)": 0,
        "Apply (Ap)": 0,
        "Understand (U)": 0,
        "Remember (R)": 0
      },
      subjectNames: [],
      subjectAllocation: [],
      subjectDetails: [],
      studentList: [],
      studentArray: [],
      studentNames: [],
      applicableStudents: [],
      allStudents: [],
      facultyList: [],
      facultyArray: [],
      facultyNames: [],
      allFaculty: [],
      roomData: [],
      roomNames: [],
      allowedRoom: "",
      allRoom: [],
      roomName: "",
      subjects: [],
      // subjectNames: [],
      allSubject: [],
      // applicableStudents: [],
      duration: "",
      totalMarks: "",
      tempSelectedRoom: {},
      editingRoom: false,
      roomAllocation: [],
      selectedStudent: [],
      selectedStudentArray: [],
      selectedFaculty: [],
      selectedFacultyArray: [],
      selectedApplicablestudents: [],
      openPanel: undefined,
      editSubjectDetails: false,
      editQuestionDetails: false,
      editStatus: false,
      deleteDialog: false,
      // uploadMarksDialog: false,
      addNewRoomDialog: false,
      allStudentList: {},
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      selectedDate: "",
      selectedTime: "",
      dateModal: false,
      timeModal: false,
      indexValue: "",
      roomAllocationObject: {},
      show: false,
      applicableHeader: [
        {
          text: "Name",
          value: "fullName"
        },
        {
          text: "PRN",
          value: "prn"
        }
      ],
      studentHeaders: [
        {
          text: "Roll No",
          align: "start",
          sortable: false,
          value: "rollNo"
        },
        { text: "Student Name", value: "fullName" },
        { text: "PRN", value: "studentPrn" }
      ],
      facultyHeaders: [
        {
          text: "Faculty Name",
          align: "start",
          sortable: false,
          value: "fullName"
        },
        { text: "email", value: "email" }
      ],
      marksHeader: [],
      studentData: [],
      selected: [],
      series: [44, 55, 41, 17, 15],

      headerSubject: [
        {
          text: "RN",
          value: "rollNo"
        },
        { text: "Name", value: "Name" },
        { text: "Overall", value: "overall" }
      ],
      studentDialog: false,
      search: "",
      updatedRoom: "",
      deleteSubject: {},
      questionObject: [],
      totalQuestions: 0,
      questionsArray: [],
      questionSet: [],
      COFromNba: [],
      selectedCOS: [],
      // marksHeader: [],
      // studentData: [],
      // selected: [],
      studentMarks: "",
      queTitle: "",
      selectedStud: {},
      questionWiseMarks: [],
      indivitualMarks: "",
      questionsSet: [],
      questionDetails: [],
      courseOutcomeData: [],
      // series: [44, 55, 41, 17, 15],
      nbaObject: {},
      test: "",
      // applicableHeader: [
      //   {
      //     text: "Name",
      //     value: "fullName"
      //   },
      // ],
      // studentHeaders: [
      //   {
      //     text: "Roll No",
      //     align: "start",
      //     sortable: false,
      //     value: "rollNo"
      //   },
      //   { text: "Student Name", value: "fullName" },
      //   { text: "PRN", value: "prn" }
      // ],
      // facultyHeaders: [
      //   {
      //     text: "Faculty Name",
      //     align: "start",
      //     sortable: false,
      //     value: "fullName"
      //   },
      //   { text: "email", value: "email" }
      // ],
      dataLoaded: false,
      examDataLoaded: false,
      rangeVal: [0, 100],
      analysisV3: [],
      attendanceDistribution: {
        "0 % - 10 %": 0,
        "10 % - 20 %": 0,
        "20 % - 30 %": 0,
        "30 % - 40 %": 0,
        "40 % - 50 %": 0,
        "50 % - 60 %": 0,
        "60 % - 70 %": 0,
        "70 % - 80 %": 0,
        "80 % - 90 %": 0,
        "90 % - 100 %": 0
      },
      subjectTypes: subjectTypes,
      subjectIds: [],
      analysisTableItems: [],
      analysisTableHeaders: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Total",
          value: "total"
        }
      ],
      failedCount: 0,
      failingLimit: 40,
      tempLimit: 40,
      subjectIdToSubjectMapping: {},
      namesMapping: {},
      isPassingThresholdEditable: true,
      passingThreshold: "40",
      // search: '',
      totalPercentage: 0,
      totalNumberOfStudents: 0,
      averagePerformance: 0,
      donutDataLoaded: false,
      selectedSubjectName: "",
      tempTotalMarks: "",
      bloomDialog: false,
      grandTotal: 0,
      allBlooms: [
        {
          name: "CREATE (C)",
          shortCode: "C",
          description:
            "Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)"
        },
        {
          name: "EVALUATE (E)",
          shortCode: "E",
          description:
            "Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)"
        },
        {
          name: "ANALYZE (A)",
          shortCode: "A",
          description:
            "Draw connections among ideas (Verbs : differentiate, organise, relate, compare, contrast, distinguish, examine, experiment, question, test)"
        },
        {
          name: "APPLY (Ap)",
          shortCode: "Ap",
          description:
            "Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)"
        },
        {
          name: "UNDERSTAND (U)",
          shortCode: "U",
          description:
            "Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)"
        },
        {
          name: "REMEMBER (R)",
          shortCode: "R",
          description:
            "Recall facts and basic concepts (Verbs : define, duplicate, list, memorise, repeat, state)"
        }
      ],
      tableHeaders: [],
      isAnalysisVisible: false,
      subjectDataLoaded: false,
      toggleQuestionUpload: null,
      assignedCoArray: [],
      questionDataLoaded: false,
      overallMarksTableHeader: [
        {
          text: "",
          value: "action",
          align: "center"
        },
        {
          text: "RN",
          value: "rollNo"
        },
        {
          text: "Student Name",
          value: "studentName"
        },
        {
          text: "PRN",
          value: "studentPrn"
        },
        {
          text: "Total",
          value: "total"
        },
        {
          text: "Total Obtained Marks",
          value: "obtainedMarks"
        }
      ],
      uploadOverallMarksDialog: false,
      obtainedMarks: 0,
      noQuestionOverallThresholdPercentage: 0,
      toggleMarksUpload: null,
      updateQuestionDetailsLoader: false,
      updateSubjectDetailsLoader: false,
      noMarks: false,
      cancelQuestionDialog: false,
      noQuestions: false,
      toggleCancelQuestionDialog: false,
      questionSaveAsDraft: false,
      uploadQuestionExcelDialog: false,
      questionExcelFile: "",
      questionsLoadedViaExcel: false,
      uploadMarksExcelDialog: false,
      marksExcelFile: "",
      marksLoadedViaExcel: false,
      marksSampleExcelLink: "",
      subjectDetailsLoaded: false,
      currentDate: "",
      saveSubjectDetails: false,
      publishExam: false,
      maxMarks: 0,
      maxQuestionMarks: 0,
      inputRules: {
        numberOnly: (value) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || "Please Enter Only Numbers.";
        },
        numberCounter: (value) => value.length <= 2 || "Max 2 numbers",
        valueLimit: (value) =>
          Number(value) <= this.maxMarks ||
          `Marks cannot be more than ${this.maxMarks}`,
        maxQuestionMarks: (value) =>
          Number(value) <= this.maxQuestionMarks ||
          `Marks cannot be more than ${this.maxQuestionMarks}`
      },
      subjectDetailsAsDraftLoader: false,
      assignedBloomsArray: [],
      totalBloomCount: 0,
      instituteType: "",
      overallAttainmentLoader: false,
      beStudents: 0,
      meStudents: 0,
      eeStudents: 0,
      beStudentPercentage: 0,
      eeStudentPercentage: 0,
      meStudentPercentage: 0,
      attainmentLevelObject: {},
      selectedAttainmentSubject: {},
      overallAttainmentSubjects: [],
      levelAttainmentQuestionArray: [],
      teal: "#008080",
      red: "#f61919",
      targetsOfInstitute: [],
      studResponse: [],
      questionDetailSaveError: false,
      questionPanelError: "",
      snackbar: false,
      studentExceedingMarksLimit: [],
      excelCustomError: false,
      excelCustomErrorMessage: "",
      currentExam: {},
      examDivision: '',
      nbaData: {},
      tempGlobalThreshold: 0
    };
  },
  async created() {
    this.$store.commit("liveData/set_selectedActivityName", `Edit Exam`);
    this.editRoomIndex = -1;
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.userData = this.$store.getters["user/get_userData"];
    // console.log('this.userData', this.userData);
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.examRepositoryInstance = new ExamRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.examStudentRepositoryInstance = new ExamStudentRepository(this);
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.selectedExamStore = this.$store.getters["liveData/get_selectedExam"];
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.instituteType = this.$store.getters["liveData/get_instituteType"];
    this.infrastructureReferenceRepositoryInstance =
      new InfrastructureReferenceRepository(this);

    const timestamp = new Date();
    this.currentDate = timestamp.toISOString().split("T")[0];
    const temp = await auth.getTempThresholdFromFirebase(
      this.selectedInstitute
    );
    if (temp) {
      this.tempGlobalThreshold = temp
      this.thresholdType = "overallThreshold"
    } else {
      this.tempGlobalThreshold = 0
    }
    this.overallThresholdPercentage = this.tempGlobalThreshold
    this.getExam();
  },
  methods: {
    studentListChange() {
      const uIdsOfApplicable = [];
      this.applicableStudents.map((data) => {
        uIdsOfApplicable.push(data.uId);
      });
      this.notSelectedStudentList = [];
      this.selectedStudentList = [];
      this.studentNames.map((stu) => {
        if (!uIdsOfApplicable.includes(stu.uId)) {
          this.notSelectedStudentList.push(stu);
        } else {
          this.selectedStudentList.push(stu);
        }
      });
      // this.studentNames.map((stu) => {
      //     if (uIdsOfApplicable.includes(stu.uId)) {
      //       this.selectedStudentList.push(stu)
      //     }
      // })
      this.studentDialog = true;
    },
    async getExam() {
      this.subjectAssinedToFacultyDummy = [];
      this.userData.subjects.map((sub) => {
        this.subjectAssinedToFacultyDummy.push(sub.subjectId);
      });
      try {
        this.selectedExam = await apiV3.getRequest("/manageExam/getExam", {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          examId: this.selectedExamStore.examId,
          attemptNumber: this.selectedExamStore.attemptNumber ? this.selectedExamStore.attemptNumber : 1
        });
        this.currentExam = this.selectedExam.data[0];
        this.userData.subjects.map((item) => {
          if (item.subjectId) {
            if (!this.allSubjectDetails.find(sub => sub.subjectId === item.subjectId)) {
              this.allSubjectDetails.push(item)
            }
          }
        })
        // this.allSubjectDetails = this.userData.subjects;
        // await this.subjectsRepositoryInstance.getSubjectsOfACourseYearInASemester(
        //   {
        //     instituteId: this.selectedInstitute,
        //     semId: this.selectedSemester.semId,
        //     department: this.currentExam.criteria.department,
        //     courseYear: this.currentExam.criteria.courseYear
        //   }
        // );
        this.fetchData();
      } catch (error) {
        console.log(error);
        this.selectedExam = {};
        this.allSubjectDetails = [];
      }
    },
    gotoExams() {
      this.$router.push("/activityMyExam");
    },
    async fetchData() {
      this.subjectAssinedToFaculty = [
        ...new Set(this.subjectAssinedToFacultyDummy)
      ];
      this.subjectDetails = [];
      this.questionDetails = [];
      let tempSeeSubjectAllocation = [];
      let tempCieSubjectAllocation = [];
      let tempCieIndividualSubjectAllocation = [];
      let tempSubjectAllocation = [];
      this.subjectNameArray = [];
      this.examName = this.currentExam.name;
      this.examDivision = this.currentExam.criteria.division
      this.examDepartmentName = this.currentExam.criteria.department;
      this.examCourseYear = this.currentExam.criteria.courseYear;
      this.examStartDate = this.currentExam.startDate.slice(0, 10);
      this.examEndDate = this.currentExam.endDate.slice(0, 10);
      this.selectedExamType = this.currentExam.type;
      this.examDescription = this.currentExam.description;
      // this.subjectDetails = this.selectedExam.subjectDetails.filter((item) => {
      //   this.subjectAssinedToFaculty.map((id) => {
      //       console.log('id', id)
      //     // if (item.subjectId === id) {
      //     //   return item.subjectId
      //     // }
      //   })
      // })
      if (this.selectedExam.subjectDetails) {
        this.selectedExam.subjectDetails.map((item) => {
          this.subjectAssinedToFaculty.map((id) => {
            if (id === item.subjectId) {
              this.subjectDetails.push(item);
            }
          });
        });
      }
      if (this.selectedExam.questionDetails) {
        this.selectedExam.questionDetails.map((item) => {
          this.subjectAssinedToFaculty.map((id) => {
            if (id === item.subjectId) {
              this.questionDetails.push(item);
            }
          });
        });
      }
      this.nbaAttainmentObject = this.currentExam.nbaAttainment;

      if (this.currentExam.isForNBA && this.currentExam.nbaAttainment) {
        this.nbaCriteria = true;
        if (this.instituteType !== "NMIMS") {
          this.thresholdType = this.currentExam.nbaAttainment.thresholdType;
          this.examType = this.currentExam.nbaAttainment.typeOfExam;
          if (
            this.examType === "see" &&
            this.thresholdType === "individualThreshold"
          ) {
            this.subjectThresholdArray =
              this.currentExam.nbaAttainment.thresholdPercentage;
            this.subjectAllocation = this.subjectThresholdArray;
          } else if (
            this.examType === "see" &&
            this.thresholdType === "overallThreshold"
          ) {
            this.overallThresholdPercentage = parseInt(
              this.currentExam.nbaAttainment.thresholdPercentage
            );
            tempSeeSubjectAllocation = this.currentExam.applicableSubjects;
          } else if (
            this.examType === "cie" &&
            this.thresholdType === "overallThreshold"
          ) {
            this.overallThresholdPercentage = parseInt(
              this.currentExam.nbaAttainment.thresholdPercentage
            );
            tempCieSubjectAllocation = this.currentExam.applicableSubjects;
          } else if (
            this.examType === "cie" &&
            this.thresholdType === "individualThreshold"
          ) {
            tempCieIndividualSubjectAllocation =
              this.currentExam.applicableSubjects;
          }
        } else {
          tempSubjectAllocation = this.currentExam.applicableSubjects;
        }
      } else {
        this.nbaCriteria = false;
        tempSubjectAllocation = this.currentExam.applicableSubjects;
      }
      let res = [];

      try {
        res = await this.semesterRepositoryInstance.getDivisionsOfASemester({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        });
      } catch (error) {
        console.log(error);
        res = [];
      }

      res.map((r) => {
        if (this.departmentNameArray.indexOf(r.department) === -1) {
          this.departmentNameArray.push(r.department);
        }
        if (this.courseYearArray.indexOf(r.courseYear) === -1) {
          this.courseYearArray.push(r.courseYear);
        }
      });
      this.allSubjectDetails.map((sub) => {
        if (sub.subjectId) {
          this.subjectNameArray.push({
            subjectName: sub.subjectName,
            subjectId: sub.subjectId
          });
        }
        if (this.instituteType !== "NMIMS") {
          if (tempSubjectAllocation.includes(sub.subjectId)) {
            this.subjectAllocation.push({
              subjectName: sub.subjectName,
              subjectId: sub.subjectId
            });
          }
          if (
            this.examType === "see" &&
            this.thresholdType === "overallThreshold"
          ) {
            if (tempSeeSubjectAllocation.includes(sub.subjectId)) {
              this.subjectAllocation.push({
                subjectName: sub.subjectName,
                subjectId: sub.subjectId
              });
            }
          }
          if (
            this.examType === "cie" &&
            this.thresholdType === "overallThreshold"
          ) {
            if (tempCieSubjectAllocation.includes(sub.subjectId)) {
              this.subjectAllocation.push({
                subjectName: sub.subjectName,
                subjectId: sub.subjectId
              });
            }
          }
          if (
            this.examType === "cie" &&
            this.thresholdType === "individualThreshold"
          ) {
            if (tempCieIndividualSubjectAllocation.includes(sub.subjectId)) {
              this.subjectAllocation.push({
                subjectName: sub.subjectName,
                subjectId: sub.subjectId
              });
            }
          }
        } else if (this.instituteType === "NMIMS") {
          if (tempSubjectAllocation.includes(sub.subjectId)) {
            this.subjectAllocation.push({
              subjectName: sub.subjectName,
              subjectId: sub.subjectId
            });
          }
        }
      });
      if (
        this.examType === "see" &&
        this.thresholdType === "individualThreshold"
      ) {
        this.subjectNameArray.map((subject) => {
          this.subjectAllocation.map((sub) => {
            if (subject.subjectId === sub.subjectId) {
              subject.subjectThreshold = sub.subjectThreshold
                ? sub.subjectThreshold
                : "";
            }
          });
        });
      }
      if (this.subjectDetails) {
        const result = this.subjectDetails.every(this.checkSubject); //refer checkSubject function
        result
          ? (this.saveSubjectDetails = true)
          : (this.saveSubjectDetails = false);
      }
      if (this.questionDetails) {
        const result = this.questionDetails.every(this.checkQuestion); //refer checkQuestion function
        result ? (this.publishExam = true) : (this.publishExam = false);
      }
      if (this.subjectAllocation.length === this.subjectNameArray.length) {
        this.allSubjects = true;
      }
      this.updatedAllocatedSubjects = this.subjectAllocation.map(
        (sub) => sub.subjectId
      );
      console.log('this.subjectNameArray', this.subjectNameArray);
      this.examDataLoaded = true;
    },

    editBasicDetails() {
      this.isBasicDetailEditable = true;
    },
    checkSubject(sub) {
      if (sub.totalMarks && sub.passingMarks) {
        return true;
      } else {
        return false;
      }
    },
    checkQuestion(que) {
      if (que.editQuestionDetails) {
        return true;
      } else {
        return false;
      }
    },
    async updateBasicDetails() {
      this.isBasicDetailEditable = false;
      if (
        this.selectedExamType === "CIE(Internal)" &&
        this.thresholdType === "individualThreshold"
      ) {
        this.nbaAttainmentObject = {
          typeOfExam: "cie",
          thresholdType: "individualThreshold"
        };
      } else if (
        this.selectedExamType === "CIE(Internal)" &&
        this.thresholdType === "overallThreshold"
      ) {
        this.nbaAttainmentObject = {
          typeOfExam: "cie",
          thresholdType: "overallThreshold",
          thresholdPercentage: this.overallThresholdPercentage
        };
      } else if (
        this.selectedExamType === "SEE(External)" &&
        this.thresholdType === "individualThreshold"
      ) {
        this.nbaAttainmentObject = {
          typeOfExam: "see",
          thresholdType: "individualThreshold",
          thresholdPercentage: this.subjectThresholdArray
        };
      } else if (
        this.selectedExamType === "SEE(External)" &&
        this.thresholdType === "overallThreshold"
      ) {
        this.nbaAttainmentObject = {
          typeOfExam: "see",
          thresholdType: "overallThreshold",
          thresholdPercentage: this.overallThresholdPercentage
        };
      }
      const updatedBasicDetails = {
        examId: this.currentExam.examId,
        instituteId: this.currentExam.instituteId,
        semId: this.currentExam.semId,
        attemptNumber: this.currentExam.attemptNumber,
        // name: this.examName,
        // criteria: {
        //   department: this.examDepartmentName,
        //   courseYear: this.examCourseYear
        // },
        // startDate: this.examStartDate,
        // endDate: this.examEndDate,
        type: this.selectedExamType,
        // description: this.examDescription,
        // applicableSubjects: this.updatedAllocatedSubjects,
        isForNBA: this.nbaCriteria,
        questionDetails: this.questionDetails
      };
      if (this.nbaCriteria) {
        updatedBasicDetails.nbaAttainment = this.nbaAttainmentObject;
      }
      console.log('this.nbaAttainmentObject', this.nbaAttainmentObject);
      this.basicDetailsLoader = true;
      try {
        await apiV3.postRequest(
          "/manageExam/updateBasicDetails",
          updatedBasicDetails
        );
        this.basicDetailsLoader = false;
        showStatus(
          "Subject Details Updated Successfully",
          9000,
          "success",
          this
        );
      } catch (error) {
        console.log(error);
        this.basicDetailsLoader = false;
        showStatus(
          "Error updating question details. Please Try after some time",
          9000,
          "error",
          this
        );
      }
      this.isBasicNbaDetailEditable = false;
      // this.$router.push("./activityMyExam");
    },
    async saveAsDraft() {
      if (this.nbaCriteria) {
        await this.nbaDetails();
      }
      await this.createArray();

      let noErrors = true;
      if (!this.examName) {
        this.emptyName = true;
        noErrors = false;
      }
      if (!this.examDescription) {
        this.emptyDescription = true;
        noErrors = false;
      }
      if (this.selectedDepartment === "") {
        this.noCriteria = true;
        noErrors = false;
      }
      if (this.startDate) {
        if (
          new Date(this.startDate) ===
          new Date(new Date().toLocaleString().split(",")[0])
        ) {
          this.invalidStartDate = true;
          noErrors = false;
        }
      } else {
        this.noStartDate = true;
        noErrors = false;
      }
      if (this.endDate) {
        if (this.startDate) {
          if (!(new Date(this.startDate) < new Date(this.endDate))) {
            this.invalidEndDate = true;
            noErrors = false;
          }
        }
      } else {
        this.noEndDate = true;
        noErrors = false;
      }
      if (noErrors === false) {
        showStatus("Please Enter Valid Data", 5000, "error", this);
      }
      if (noErrors) {
        const criteria = {
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear
            ? this.selectedCourseYear
            : undefined
        };
        this.subjectAllocation.forEach((sub) => {
          this.subjectObject.push(sub.subjectId);
        });
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          name: this.examName,
          description: this.examDescription ? this.examDescription : "",
          type: this.selectedExamType ? this.selectedExamType : "",
          startDate: this.startDate,
          // startDate: this.startDate ? convertToISTTime(
          //   convertFromYYYYMMDDToDate(this.startDate)
          // ).toISOString() : "",
          // endDate: this.endDate ? convertToISTTime(
          //   convertFromYYYYMMDDToDate(this.endDate)
          // ).toISOString() : "",
          endDate: this.endDate,
          criteria: criteria,
          isForNBA: this.nbaCriteria,
          applicableSubjects: this.subjectObject ? this.subjectObject : [],
          subjectDetails: this.subjectDetails ? this.subjectDetails : [],
          questionDetails: this.questionDetails ? this.questionDetails : [],
          examStatus: "Draft"
        };
        if (this.nbaCriteria) {
          objToPush.nbaAttainment = this.nbaAttainmentObject
            ? this.nbaAttainmentObject
            : "";
        }
        try {
          await apiV3.postRequest("/manageExam/saveAsDraft", objToPush);
          showStatus("Exam Saved as a draft", 5000, "success", this);
          this.$router.push("./activityMyExam");
        } catch (err) {
          console.log(err);
          showStatus("Error while saving as a draft", 9000, "error", this);
        }
      }
    },
    handleSubejcts() {
      this.updatedAllocatedSubjects = [];
      this.subjectThresholdArray = [];
      this.subjectAllocation.map((sub) => {
        this.updatedAllocatedSubjects.push(sub.subjectId);
        this.subjectThresholdArray.push(sub);
      });
      this.subjectAllocation.length === this.subjectNameArray.length
        ? (this.allSubjects = true)
        : (this.allSubjects = false);
      this.subjectDetails = this.subjectAllocation.filter(
        (subject) => this.subjectDetails.indexOf(subject.subjectId) === -1
      );
    },
    handleAllSubejcts() {
      if (this.allSubjects === false) {
        this.subjectAllocation = [];
      } else {
        this.subjectAllocation = this.subjectNameArray;
      }
    },
    handleIndividualThreshold() {
      this.subjectThresholdArray = [];
      this.subjectAllocation.map((sub) => {
        this.subjectThresholdArray.push(sub);
      });
    },
    handleIndividualThresholdTextField(subjectId, subjectThreshold) {
      this.subjectNameArray.map((sub) => {
        if (sub.subjectId === subjectId) {
          sub.subjectThreshold = subjectThreshold;
        }
      });
    },
    //subjectconsole
    handleSubjectDetails() {
      // this.subjectIds = this.subjectDetails.map(sub => { return sub.subjectId })
      // this.subjectAllocation.map((sub) => {
      //   if (!this.subjectIds.includes(sub.subjectId)) {
      //     this.subjectDetails.push(sub)
      //   }
      // })
      this.startDate = this.examStartDate;
      this.endDate = this.examEndDate;
    },
    async updateSubjectDetails(status) {
      status === "publish"
        ? (this.updateSubjectDetailsLoader = true)
        : (this.subjectDetailsAsDraftLoader = true);
      try {
        const update = {
          examId: this.currentExam.examId,
          instituteId: this.currentExam.instituteId,
          semId: this.currentExam.semId,
          subjectDetails: this.subjectDetails,
          questionDetails: this.questionDetails
        };
        await apiV3.putRequest("/manageExam/updateSubjectDetails", update);
        this.updateSubjectDetailsLoader = false;
        status === "publish"
          ? (this.updateSubjectDetailsLoader = false)
          : (this.subjectDetailsAsDraftLoader = false);
        showStatus(
          "Subject Details Updated Successfully",
          5000,
          "success",
          this
        );
        // this.e1 = 3
      } catch (e) {
        console.log(e);
        this.updateSubjectDetailsLoader = false;
        status === "publish"
          ? (this.updateSubjectDetailsLoader = false)
          : (this.subjectDetailsAsDraftLoader = false);
        showStatus(
          "Error updating subject Details. Please try after some time",
          9000,
          "error",
          this
        );
      }
      // this.$router.push("./activityMyExam");
    },
    onSubjectExpansionPanelClick(e, sub) {
      if (
        !e.currentTarget.classList.contains("v-expansion-panel-header--active")
      ) {
        this.subjectPanel(sub);
      }
    },
    async subjectPanel(subject) {
      this.subjectDetailsLoaded = false;
      await this.getStudents(subject);
      await this.getFaculties();
      await this.getRoomName(subject);
      this.subjectDetailsLoaded = true;
    },
    async getStudents(subject) {
      this.studentArray = [];
      try {
        const objOfStudent = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectId: subject.subjectId
        };
        this.studentList =
          await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject(
            objOfStudent
          );
        this.studentList.forEach((student) => {
          this.studentArray.push(student.uId);
        });
        const objForFullName = {
          uIds: this.studentArray
        };
        // this.studentNames =
        //   await this.userRepositoryInstance.getFullNameOfUsers(objForFullName);
        this.studResponse = await this.userRepositoryInstance.getUsers(
          objForFullName
        );
        const studentFullNames = [];
        this.studResponse.map((stu) => {
          // console.log('stu', stu);
          studentFullNames.push({
            fullName: stu.firstName + " " + stu.middleName + " " + stu.lastName,
            uId: stu.uId,
            prn: stu.collegePRNNo
          });
        });
        this.studentNames = studentFullNames;
        this.applicableStudents = studentFullNames;
        // this.applicableStudents = this.studentNames
        this.allStudents = this.applicableStudents;
      } catch (e) {
        this.studentNames = [];
        this.studentArray = [];
        this.studentList = [];
        console.log(e);
      }
    },
    async getFaculties() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        };
        this.facultyList =
          await this.semesterUserRepositoryInstance.getFacultiesOfAInstitute(
            objToPush
          );
        if (Array.isArray(this.facultyList)) {
          this.facultyList.forEach((faculty) => {
            this.facultyArray.push(faculty.uId);
          });
        }
        const objForFullName = {
          uIds: this.facultyArray
        };
        this.facultyNames =
          await this.userRepositoryInstance.getFullNameOfUsers(objForFullName);
        this.allFaculty = this.facultyNames;
      } catch (err) {
        this.facultyList = [];
        this.facultyNames = [];
        this.facultyArray = [];
        console.log(err);
      }
    },
    async getRoomName(subject) {
      try {
        this.roomNames = [];
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.examDepartmentName,
          courseYear: this.examCourseYear
        };
        this.roomData =
          await this.infrastructureReferenceRepositoryInstance.getRoomsOfADepartment(
            objToPush
          );
        this.roomData.map((room) => {
          this.roomNames.push({ roomName: room.roomName, roomId: room.roomId });
        });
        this.allRoom = this.roomNames;

        this.subjectDetails.map((room) => {
          if (subject.subjectId === room.subjectId) {
            if (Array.isArray(room.roomAllocation)) {
              room.roomAllocation.map((selected) => {
                this.roomNames.map((name) => {
                  if (selected.roomName === name.roomId) {
                    this.updatedRoom = name.roomName;
                  }
                });
              });
            }
          }
        });
      } catch (err) {
        console.log(err);
        this.roomData = [];
        this.locations = [];
      }
    },
    // async getSubjects() {
    //   console.log('helllllloooooooo');
    //   try {
    //     this.subjects = [];
    //     this.subjects =
    //       await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
    //         {
    //           instituteId: this.selectedInstitute,
    //           semId: this.selectedSemester.semId,
    //           department: this.selectedDepartment,
    //           courseYear: this.selectedCourseYear
    //         }
    //       );
    //     this.subjects.forEach((sub) => {
    //       this.subjectNames.push({
    //         subjectName: sub.subjectName,
    //         subjectId: sub.subjectId
    //       });
    //       this.allSubject.push(sub);
    //     });
    //   } catch (err) {
    //     this.subjects = [];
    //     console.log(err);
    //   }
    // },
    async tempAddRoom(subject) {
      if (subject.roomStatus === true) {
        this.editRoomIndex = -1;
        this.selectedStudent = [];
        this.selectedFaculty = [];
        const roomDta = subject.roomNames;
        roomDta.forEach((room, i) => {
          if (room.roomId === this.allowedRoom) {
            roomDta.splice(room, 1);
          }
        });
        subject.roomNames = roomDta;
        subject.facultyNames = subject.facultyNames.filter(
          (item) => !this.selectedFacultyArray.includes(item.uId)
        );
        subject.applicableStudents = subject.applicableStudents.filter(
          (item) => !this.selectedStudentArray.includes(item.uId)
        );
      } else {
        subject["applicableStudents"] = this.applicableStudents;
        subject["facultyNames"] = this.allFaculty;
        subject["roomNames"] = this.allRoom;
      }
      this.allowedRoom = "";
      this.selectedStudent = [];
      this.selectedFaculty = [];
      this.editingRoom = false;
      this.tempSelectedRoom = {
        roomNumber: this.roomAllocation.length + 1,
        roomName: "",
        appliedStudents: [],
        appliedFaculties: []
      };
      this.allStudentList = subject;
      this.addNewRoomDialog = true;
    },
    async saveTempRoom(subject) {
      this.selectedStudentArray = [];
      this.selectedFacultyArray = [];

      this.roomNames.forEach((room) => {
        if (room.roomId === this.allowedRoom) {
          this.roomName = room.roomName;
        }
      });
      this.tempSelectedRoom.roomName = this.allowedRoom;
      this.selectedStudent.map((stud) => {
        this.selectedStudentArray.push(stud.uId);
      });
      this.tempSelectedRoom.appliedStudents = this.selectedStudentArray;
      this.selectedFaculty.map((faculty) => {
        this.selectedFacultyArray.push(faculty.uId);
      });
      this.tempSelectedRoom.appliedFaculties = this.selectedFacultyArray;
      if (this.editRoomIndex !== -1) {
        this.roomAllocation[this.editRoomIndex] = this.tempSelectedRoom;
        this.editRoomIndex = -1;
      } else {
        if (this.allowedRoom !== "") {
          this.roomAllocation.push(this.tempSelectedRoom);
          subject["roomStatus"] = true;
        }
      }
      this.addNewRoomDialog = false;
    },
    async editSubject(subject) {
      this.saveChangesBtnDisabled = false;
      this.dialogWarning = true;
      // console.log('this.dialogWarning ', this.dialogWarning);
      this.applicableStudents = [];
      subject.editSubjectDetails = false;
      this.studentNames.forEach((obj) => {
        subject.totalStudents.forEach((object) => {
          if (object === obj.uId) {
            this.applicableStudents.push(obj);
          }
        });
      });
      this.selectedApplicablestudents = subject.totalStudents;
      this.selectedDate = subject.selectedDate;
      this.selectedTime = subject.selectedTime;
      this.duration = subject.duration;
      this.totalMarks = subject.totalMarks;
      this.roomAllocation = subject.roomAllocation;
      this.passingMarks = subject.passingMarks;
      subject.roomAllocation.forEach((selected) => {
        this.selectedFacultyArray = selected.appliedFaculties;
        this.selectedStudentArray = selected.appliedStudents;
      });
    },
    async saveSubject(subject) {
      let error = false;
      if (Number(this.passingMarks) > Number(this.totalMarks)) {
        error = true;
      } else {
        error = false;
      }

      if (!error) {
        try {
          this.selectedApplicablestudents = [];
          this.applicableStudents.forEach((obj) => {
            this.selectedApplicablestudents.push(obj.uId);
          });

          this.subjectDetails.forEach((subj) => {
            if (subj.subjectId === subject.subjectId) {
              subj.subjectId = subject.subjectId;
              subj.subjectName = subject.subjectName;
              subj.totalStudents = this.selectedApplicablestudents;
              subj.selectedDate = this.selectedDate;
              subj.selectedTime = this.selectedTime;
              subj.duration = this.duration;
              subj.totalMarks = this.totalMarks;
              subj.roomAllocation = this.roomAllocation;
              subj.editSubjectDetails = true;
              subj.passingMarks = this.passingMarks;

              const array1 = [];
              const newStudentIds = [];
              const deletedStudents = [];
              const indexToDelete = [];
              let selectedSubject = {};
              this.questionDetails.map((subject) => {
                if (subj.subjectId === subject.subjectId) {
                  selectedSubject = subject;
                  selectedSubject.studentData = [];
                  if (subject.studentData) {
                    subject.studentData.map((stud) => {
                      array1.push(stud.studentId);
                    });
                  }

                  subj.totalStudents.map((ele) => {
                    if (!array1.includes(ele)) {
                      newStudentIds.push(ele);
                    }
                  });
                  array1.map((ele) => {
                    if (!subj.totalStudents.includes(ele)) {
                      deletedStudents.push(ele);
                    }
                  });
                }
              });
              if (deletedStudents.length > 0) {
                deletedStudents.map((ele) => {
                  selectedSubject.studentData.map((stud, i) => {
                    if (ele === stud.studentId) {
                      indexToDelete.push(i);
                    }
                  });
                });
                for (var i = indexToDelete.length - 1; i >= 0; i--) {
                  selectedSubject.studentData.splice(indexToDelete[i], 1);
                }
              }
              if (newStudentIds.length > 0) {
                this.studentNames.map((eachStudent, i) => {
                  this.subjectDetails.map((stud, index) => {
                    if (stud.subjectId === selectedSubject.subjectId) {
                      if (newStudentIds) {
                        newStudentIds.map((obj) => {
                          if (obj === eachStudent.uId) {
                            const temp = {
                              instituteId: this.selectedInstitute,
                              semId: this.selectedSemester.semId,
                              studentName: eachStudent.fullName,
                              studentId: eachStudent.uId,
                              total: stud.totalMarks,
                              subjectName: stud.subjectName,
                              subjectId: stud.subjectId,
                              studentPrn: eachStudent.prn
                            };
                            selectedSubject.studentData.push(temp);
                          }
                        });
                      }
                    }
                  });
                });
              }
            }
          });
          this.editSubjectDetails = true;
          this.selectedApplicablestudents = [];
          this.selectedDate = "";
          this.selectedTime = "";
          this.duration = "";
          this.totalMarks = "";
          this.roomAllocation = [];
          this.applicableStudents = [];
          this.passingMarks = 0;
        } catch (e) {
          console.log(e);
        }
        if (this.subjectDetails[this.subjectDetails.length - 1].totalMarks) {
          this.saveSubjectDetails = true;
        } else {
          this.saveSubjectDetails = false;
        }
        this.saveChangesBtnDisabled = true;
      }
    },
    async editRoom(index, subject) {
      subject.roomNames = this.allRoom;
      subject.applicableStudents = this.applicableStudents;
      subject.facultyNames = this.allFaculty;

      this.selectedStudent = [];
      this.selectedFaculty = [];
      this.studentNames.map((stud) => {
        this.roomAllocation[index].appliedStudents.map((obj) => {
          if (stud.uId === obj) {
            this.selectedStudent.push(stud);
          }
        });
      });
      this.facultyNames.map((faculty) => {
        this.roomAllocation[index].appliedFaculties.map((obj) => {
          if (faculty.uId === obj) {
            this.selectedFaculty.push(faculty);
          }
        });
      });
      this.tempSelectedRoom = {
        roomNumber: index,
        roomName: this.roomAllocation[index].allowedRoom,
        appliedStudents: this.roomAllocation[index].appliedStudents,
        appliedFaculties: this.roomAllocation[index].appliedFaculties
      };
      this.editRoomIndex = index;
      this.allStudentList = subject;
      this.addNewRoomDialog = true;
    },
    async openDeleteDailog(index, roomAllocation, subject) {
      this.indexValue = index;
      this.roomAllocationObject = roomAllocation;
      this.deleteSubject = subject;
      this.deleteDialog = true;
    },
    async deleteRoom(indexValue, roomAllocation, deleteSubject) {
      const subjectIndex = this.subjectDetails.indexOf(deleteSubject);
      this.allRoom.forEach((room) => {
        if (room.roomId === roomAllocation[indexValue].roomName) {
          roomAllocation[indexValue].roomName = "";
          deleteSubject.roomNames.push(room);
          // deleteSubject['roomNames'] = deleteSubject.roomNames
        }
      });
      //faculty
      this.allFaculty.forEach((faculty) => {
        roomAllocation[indexValue].appliedFaculties.forEach((selected, i) => {
          if (faculty.uId === selected) {
            this.selectedFacultyArray.map((element) => {
              if (element === selected) {
                this.selectedFacultyArray.splice(element, 1);
              }
            });
            if (!deleteSubject.facultyNames.includes(faculty)) {
              deleteSubject.facultyNames.push(faculty);
            }
          }
        });
      });
      //students
      this.allStudents.forEach((student) => {
        roomAllocation[indexValue].appliedStudents.forEach((selected, i) => {
          if (student.uId === selected) {
            this.selectedStudentArray.map((element) => {
              if (element === selected) {
                this.selectedStudentArray.splice(element, 1);
              }
            });
            if (!deleteSubject.applicableStudents.includes(student)) {
              deleteSubject.applicableStudents.push(student);
            }
          }
        });
      });
      this.subjectDetails[subjectIndex] = deleteSubject;
      roomAllocation.splice(indexValue, 1);
      this.deleteDialog = false;
    },
    //question Details
    async updateQuestionDetails(status, count) {
      this.updateQuestionDetailsLoader = true;
      try {
        const update = {
          examId: this.currentExam.examId,
          instituteId: this.currentExam.instituteId,
          semId: this.currentExam.semId,
          questionDetails: this.questionDetails,
          examStatus: status,
          attemptNumber: this.currentExam.attemptNumber ? this.currentExam.attemptNumber : 1
        };
        await apiV3.putRequest("/manageExam/updateAssessmentDetails", update);

        this.updateQuestionDetailsLoader = false;
        if (count === 2) {
          showStatus(
            "Question Details Updated Successfully",
            5000,
            "success",
            this
          );
          // this.$router.push("./activityMyExam");
        } else if (count === 1) {
          showStatus("Exam Published Successfully", 5000, "success", this);
          // this.$router.push("./activityMyExam");
        }
      } catch (e) {
        console.log(e);
        this.updateQuestionDetailsLoader = false;
        showStatus(
          "Error updating question details. Please Try after some time",
          9000,
          "error",
          this
        );
      }
    },
    async questionDetailsAsDraft(status) {
      this.questionSaveAsDraft = true;
      try {
        const update = {
          examId: this.currentExam.examId,
          instituteId: this.currentExam.instituteId,
          semId: this.currentExam.semId,
          questionDetails: this.questionDetails,
          examStatus: status
        };
        await apiV3.putRequest("/manageExam/assessmentDetailsAsDraft", update);

        this.questionSaveAsDraft = false;
        showStatus(
          "Question Details Updated Successfully",
          5000,
          "success",
          this
        );
        this.$router.push("./activityMyExam");
      } catch (e) {
        console.log(e);
        this.questionSaveAsDraft = false;
        showStatus(
          "Error updating question details. Please Try after some time",
          9000,
          "error",
          this
        );
      }
    },

    async handleQuestionDetails() {
      await this.getExam();
      // console.log('subject', this.subjectDetails)
      this.subjectDetails.map((sub) => {
        this.questionDetails.map((que) => {
          if (que.subjectId === sub.subjectId) {
            // console.log('sub', sub)
            que.totalMarks = sub.totalMarks;
          }
        });
      });
      // const questionIds = this.questionDetails.map(que => { return que.subjectId })
      // const allSubjectIds = this.subjectAllocation.map(sub => { return sub.subjectId })
      // this.subjectAllocation.map((sub) => {
      //   if (!questionIds.includes(sub.subjectId)) {
      //     this.questionDetails.push(sub)
      //   }
      // })
      this.ThirdPageDataIsLoaded = false;
    },
    handleExPan(event, subject, i) {
      if (
        !event.currentTarget.classList.contains(
          "v-expansion-panel-header--active"
        )
      ) {
        this.questionPanel(subject, i);
      }
    },
    async questionPanel(subject, i) {
      console.log("subject", subject);
      this.questionDetailSaveError = false;
      this.maxMarks = 0;
      this.tempTotalMarks = 0;
      this.subjectDetails.map((sub) => {
        // console.log('question panel', sub)
        if (sub.totalMarks) {
          if (sub.subjectId === subject.subjectId) {
            this.tempTotalMarks = parseInt(sub.totalMarks);
            this.maxMarks = parseInt(sub.totalMarks);
          }
        }
        if (!sub.totalMarks || !sub.passingMarks || !sub.totalStudents) {
          this.questionDetailSaveError = true;
          this.questionPanelError = `Subject details are not saved yet for ${subject.subjectName}. This will lead to errors while saving assessment details.`;
        } else {
          this.questionDetailSaveError = false;
        }
      });
      this.noMarks = false;
      this.noQuestions = false;
      this.toggleQuestionUpload = null;
      this.toggleMarksUpload = null;
      this.noQuestionOverallThresholdPercentage = this
        .overallThresholdPercentage
        ? this.overallThresholdPercentage
        : 0;
      this.questionDataLoaded = false;
      this.assignedCoArray = [];
      this.assignedBloomsArray = [];
      this.allBlooms.map((bloom) => {
        this.assignedBloomsArray.push(bloom.shortCode);
      });
      await this.getStudents(subject);
      this.test = this.overallThresholdPercentage;

      if (!subject.uploadQuestions) {
        this.noQuestions = true;
      } else {
        this.noQuestions = false;
        await this.uploadQuestionManually(subject);
      }

      if (subject.studentData) {
        this.show = true;
        if (!subject.uploadMarksType) {
          this.noMarks = true;
        } else {
          this.uploadMarksManually(subject);
        }
      } else {
        this.noMarks = true;
      }
      const index = this.questionDetails.indexOf(subject);
      if (subject.editQuestionDetails === true) {
        this.overallThresholdPercentage = subject.overallThreshold;
        this.noQuestionOverallThresholdPercentage = subject.overallThreshold;
        this.questionSet = subject.questions;
        this.totalQuestions = subject.totalQuestions;
        this.studentData = subject.studentData;
        this.assignedCoArray = subject.assignedCo;
        this.assignedBloomsArray = subject.assignedBlooms;
        this.editQuestionDetails = subject.editQuestionDetails;
        if (subject.uploadQuestions === (undefined || null)) {
          this.noQuestions = true;
        } else if (subject.uploadQuestions) {
          this.toggleQuestionUpload = "uploadQuestions";
        } else {
          this.toggleQuestionUpload = "dontUploadQuestions";
          this.assignedCoArray = subject.assignedCo;
          this.assignedBloomsArray = subject.assignedBlooms;
        }
        if (subject.uploadMarksType === undefined) {
          this.noMarks = true;
        } else if (subject.uploadMarksType === "aggregate") {
          this.toggleMarksUpload = "aggregate";
          this.uploadMarksManually(subject);
        } else if (subject.uploadMarksType === "individual") {
          this.toggleMarksUpload = "individual";
          this.uploadMarksManually(subject);
        }

        this.getCourseOutcome(subject);
      } else {
        this.overallThreshold = "";
        this.questionsArray = [];
        this.questionSet = [];
        this.totalQuestions = 0;
        this.uploadQuestionManuallyStatus = false;
        this.editQuestionDetails = false;
        this.uploadMarksManuallyStatus = false;
        if (this.nbaCriteria === true) {
          if (Array.isArray(this.nbaAttainmentObject.thresholdPercentage)) {
            this.nbaAttainmentObject.thresholdPercentage.map((obj, i) => {
              if (obj.subjectId === subject.subjectId) {
                this.overallThresholdPercentage = obj.subjectThreshold;
              }
            });
          } else {
            this.overallThresholdPercentage = this.overallThresholdPercentage
              ? this.overallThresholdPercentage
              : 0;
          }
        } else {
          this.overallThresholdPercentage = this.overallThresholdPercentage
            ? this.overallThresholdPercentage
            : 0;
        }
        const tempStudentData = [];
        this.questionDetails[index].studentData = [];
        this.studentNames.map((eachStudent, i) => {
          this.subjectDetails.map((stud, index) => {
            if (stud.subjectId === subject.subjectId) {
              if (stud.totalStudents) {
                stud.totalStudents.map((obj) => {
                  if (obj === eachStudent.uId) {
                    const temp = {
                      instituteId: this.selectedInstitute,
                      semId: this.selectedSemester.semId,
                      studentName: eachStudent.fullName,
                      studentId: eachStudent.uId,
                      total: stud.totalMarks,
                      subjectName: stud.subjectName,
                      subjectId: stud.subjectId,
                      studentPrn: eachStudent.prn
                    };
                    tempStudentData.push(temp);
                  }
                });
              }
            }
          });
        });
        this.studentData = tempStudentData;
        await this.getCourseOutcome(subject);
        if (Array.isArray(this.nbaData.data)) {
          this.nbaData.data.forEach((nba, i) => {
            this.assignedCoArray.push(nba.CONumber);
          });
        }
        this.assignData(index, this.studentData);
      }
      await this.getStudents(subject);
      if (this.overallThresholdPercentage) {
        this.overallPercentageStatus = true;
      }
      this.questionDataLoaded = true;
    },
    async getCourseOutcome(subject) {
      try {
        this.courseOutcomeData = [];
        this.COFromNba = [];
        // this.assignedCoArray = [];
        this.nbaData = await this.nbaRepositoryInstance.getCos({
          lmsInstituteId: this.selectedInstitute,
          semesterId: this.selectedSemester.semId,
          subjectId: subject.subjectId
        });
        if (Array.isArray(this.nbaData.data)) {
          this.nbaData.data.forEach((nba, i) => {
            this.courseOutcomeData.push({
              Id: nba._id,
              coNumber: nba.CONumber,
              coName: nba.COName
            });
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    editQuestion(subject) {
      this.saveChangeBtnAssesmentPage = false;
      this.editQuestionDetails = false;
      this.questionsArray = [];
      if (subject.questions !== undefined) {
        this.questionsArray = subject.questions;
      }
      this.uploadMarksManuallyStatus = false;
    },
    assignData(index, studentData) {
      this.questionDetails[index].studentData = studentData;
    },
    uploadMarksManually(subject) {
      this.uploadMarksManuallyStatus = true;
      this.marksUpload(subject);
    },
    async marksUpload(subject) {
      this.questionsArray = [];
      this.marksHeader = [];
      let header = [];
      header = [
        {
          text: "",
          value: "action",
          align: "center"
        },
        {
          text: "RN",
          value: "rollNo"
        },
        {
          text: "Student Name",
          value: "studentName"
        },
        {
          text: "PRN",
          value: "studentPrn"
        },
        {
          text: "Total",
          value: "total"
        },
        {
          text: "Total Obtained Marks",
          value: "obtainedMarks"
        }
      ];
      if (subject.questions) {
        subject.questions.forEach((question, i) => {
          header.push({
            text: `Q ${i + 1}`,
            value: `Q ${i + 1}`
          });
          question["questionNumber"] = `Q ${i + 1}`;
          question["studentMarks"] = "";
          this.subjectDetails.forEach((sub, index) => {
            if (sub.subjectId === subject.subjectId) {
              subject.marksHeader = header;
            }
          });
        });
      }
    },
    async uploadQuestionManually(sub) {
      this.uploadQuestionManuallyStatus = true;
      if (this.nbaCriteria === true) {
        this.overallPercentageStatus = true;
        this.indivitualThresholdStatus = true;
      }
      this.questionsArray = sub.questions ? sub.questions : [];
      this.questionSet = this.questionsArray;
      this.overallThresholdPercentage = this.overallThresholdPercentage
        ? this.overallThresholdPercentage
        : 0;
      this.totalQuestions = sub.totalQuestions
        ? parseInt(sub.totalQuestions)
        : 0;
      // this.editQuestionDetails = true
    },
    addNewQuestion() {
      const tempQuestion = {
        number: this.questionSet ? this.questionSet.length + 1 : 1,
        marks: "",
        indivitualThreshold: "",
        question: "",
        COArray: [],
        coDialog: false,
        bloomArray: [],
        bloomDialog: false
      };
      this.questionsArray = [...this.questionsArray, tempQuestion];
      this.questionSet = this.questionsArray;
      this.totalQuestions = this.questionSet.length;
    },
    deleteQuestion(index) {
      const temp = [...this.questionSet];
      temp.splice(index, 1);
      this.questionsArray = temp;
      this.questionSet = this.questionsArray;
      this.totalQuestions = this.questionSet.length;
    },

    async marksCalculation(subject) {
      let total;
      if (this.overallPercentageStatus === true) {
        if (Array.isArray(this.questionSet)) {
          this.questionSet.forEach((que) => {
            if (parseInt(que.marks) > parseInt(total)) {
              que.marks = "";
              showStatus(
                `Marks Should less Less Than ${this.tempTotalMarks}`,
                9000,
                "error",
                this
              );
            } else {
              this.indivitualThresholdAvg =
                (que.marks / 100) * this.overallThresholdPercentage;
              que["indivitualThreshold"] = this.indivitualThresholdAvg;
            }
          });
        }
      }
      if (this.test) {
        if (Array.isArray(this.questionSet)) {
          this.questionSet.forEach((que) => {
            this.indivitualThresholdAvg = (que.marks / 100) * this.test;
            que["indivitualThreshold"] = this.indivitualThresholdAvg;
          });
        }
      }
    },
    async saveQuestions(subject) {
      this.saveChangeBtnAssesmentPage = true;
      if (this.toggleQuestionUpload === "uploadQuestions") {
        let add = 0;
        this.questionSet.map((que) => {
          add += parseInt(que.marks);
        });
        this.questionDetails.forEach((subj) => {
          if (subj.subjectId === subject.subjectId) {
            if (add <= this.tempTotalMarks) {
              subj.uploadQuestions = true;
              subj.subjectId = subject.subjectId;
              subj.subjectName = subject.subjectName;
              subj.overallThreshold = this.overallThresholdPercentage
                ? this.overallThresholdPercentage
                : this.test;
              subj.totalQuestions = this.totalQuestions;
              subj.questions = this.questionSet;
              subj.editQuestionDetails = true;
              this.editQuestionDetails = true;
            } else {
              this.editQuestionDetails = false;
              showStatus(
                `Total of Marks should be Less than  ${this.tempTotalMarks}`,
                9000,
                "error",
                this
              );
            }
          }
        });
        this.uploadQuestionManuallyStatus = true;
      } else if (this.toggleQuestionUpload === "dontUploadQuestions") {
        this.questionDetails.forEach((subj) => {
          if (subj.subjectId === subject.subjectId) {
            subj.uploadQuestions = false;
            subj.subjectId = subject.subjectId;
            subj.subjectName = subject.subjectName;
            subj.assignedCo = this.assignedCoArray;
            subj.assignedBlooms = this.assignedBloomsArray;
            subj.editQuestionDetails = true;
            subj.overallThreshold = this.noQuestionOverallThresholdPercentage;
            this.editQuestionDetails = true;
          }
        });
      }
      if (
        this.questionDetails[this.questionDetails.length - 1]
          .editQuestionDetails
      ) {
        this.publishExam = true;
      } else {
        this.publishExam = false;
      }
    },
    cancelQuestion(subject) {
      if (subject.questions === undefined) {
        this.questionsArray = [];
        this.questionSet = [];
        this.toggleCancelQuestionDialog = false;
      } else {
        this.questionsArray = subject.questions;
        this.questionSet = subject.questions;
        this.toggleCancelQuestionDialog = false;
      }
    },
    async saveMarks(selectedStud) {
      let tempCount = 0;
      if (this.toggleMarksUpload === "individual") {
        for (let index = 0; index < this.questionSet.length; index++) {
          const element = this.questionSet[index];
          if (Number(element.studentMarks) > Number(element.marks)) {
            tempCount++;
          }
        }
      } else if (
        this.toggleMarksUpload === "aggregate" &&
        this.toggleQuestionUpload === "dontUploadQuestions"
      ) {
        if (this.obtainedMarks > this.maxMarks) {
          tempCount++;
        }
      } else if (this.toggleMarksUpload === "aggregate") {
        if (this.obtainedMarks > this.maxMarks) {
          tempCount++;
        }
      }

      if (tempCount === 0) {
        if (this.toggleMarksUpload === "individual") {
          const subject = this.questionDetails.filter(
            (sub) => sub.subjectId === selectedStud.subjectId
          );
          let student = [];
          student = subject[0].studentData;
          this.show = false;
          let totalObtained = 0;
          this.uploadMarksDialog = false;
          this.questionSet.map((que, i) => {
            student.map((data) => {
              if (data.studentId === selectedStud.studentId) {
                data["uploadMarksType"] = "individual";
                data[`Q ${i + 1}`] =
                  data.studentMarks === que.studentMarks
                    ? data.studentMarks
                    : que.studentMarks;
                if (data[`Q ${i + 1}`] !== "") {
                  totalObtained += parseInt(data[`Q ${i + 1}`]);
                }
                data["obtainedMarks"] = totalObtained;
              }
              data.studentMarks = "";
            });
          });
          this.questionDetails.map((sub) => {
            subject.map((subj) => {
              if (sub.subjectId === subj.subjectId) {
                sub = subj;
                sub["uploadMarksType"] = "individual";
              }
            });
          });
          this.show = true;
        } else if (
          this.toggleMarksUpload === "aggregate" &&
          this.toggleQuestionUpload === "dontUploadQuestions"
        ) {
          const subject = this.questionDetails.filter(
            (sub) => sub.subjectId === selectedStud.subjectId
          );
          let student = [];
          student = subject[0].studentData;
          this.show = false;
          this.uploadMarksDialog = false;
          student.map((data) => {
            if (data.studentId === selectedStud.studentId) {
              data["uploadMarksType"] = "aggregate";
              data["obtainedMarks"] = this.obtainedMarks;
            }
          });
          this.questionDetails.map((sub) => {
            subject.map((subj) => {
              if (sub.subjectId === subj.subjectId) {
                sub = subj;
                sub["uploadMarksType"] = "aggregate";
              }
            });
          });
          this.show = true;
          this.uploadOverallMarksDialog = false;
        } else if (this.toggleMarksUpload === "aggregate") {
          const subject = this.questionDetails.filter(
            (sub) => sub.subjectId === selectedStud.subjectId
          );
          let student = [];
          student = subject[0].studentData;
          this.show = false;
          this.uploadMarksDialog = false;
          let marks = 0;
          this.questionSet.map((que, i) => {
            student.map((data) => {
              if (data.studentId === selectedStud.studentId) {
                marks = this.obtainedMarks / this.questionSet.length;
                if (i + 1 !== this.questionSet.length) {
                  data["uploadMarksType"] = "aggregate";
                  data[`Q ${i + 1}`] = Math.ceil(marks * 100) / 100;
                } else {
                  data["uploadMarksType"] = "aggregate";
                  data[`Q ${i + 1}`] = Math.floor(marks * 100) / 100;
                }
                data["obtainedMarks"] = parseInt(this.obtainedMarks);
              }
            });
          });
          this.questionDetails.map((sub) => {
            subject.map((subj) => {
              if (sub.subjectId === subj.subjectId) {
                sub = subj;
                sub["uploadMarksType"] = "aggregate";
              }
            });
          });
          this.show = true;
          this.uploadOverallMarksDialog = false;
        }
      }
    },
    async editStudentMarks(item) {
      this.obtainedMarks = 0;
      if (item) {
        this.obtainedMarks = item.obtainedMarks;
      }
      if (this.toggleMarksUpload === "individual") {
        this.questionSet.map((que, i) => {
          if (item[`Q ${i + 1}`]) {
            que["studentMarks"] = item[`Q ${i + 1}`];
          } else {
            que["studentMarks"] = "";
          }
        });
        this.uploadMarksDialog = true;
        this.selectedStud = item;
      } else if (this.toggleMarksUpload === "aggregate") {
        this.uploadOverallMarksDialog = true;
        this.selectedStud = item;
        if (this.selectedStud.obtainedMarks) {
          this.obtainedMarks = this.selectedStud.obtainedMarks;
        } else {
          this.obtainedMarks = 0;
        }
      }
    },
    async cancelRoom() {
      this.tempSelectedRoom = {};
      this.addNewRoomDialog = false;
    },
    async handleOverallThreshold() {
      this.overallPercentageStatus = true;
    },
    async handleIndivitualThreshold() {
      this.indivitualThresholdStatus = true;
    },
    //analysis
    async examApi() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        examId: this.currentExam.examId
      };
      try {
        this.analysisV3 = await apiV3.getRequest(
          "/manageExam/examAnalysis",
          objToPush
        );
        if (!this.analysisV3.questionDetails) {
          this.analysisV3.questionDetails = [];
        }
        if (!this.analysisV3.bloomsData) {
          this.analysisV3.bloomsData = [];
        }
      } catch (err) {
        console.log(err);
        this.analysisV3 = [];
        this.passingThreshold = 0;
      }
      this.examAnalysisV3();
      // this.handleBloomsAnalysis()
      this.recalculateDefaulters();
      // if (this.instituteType === 'NMIMS') {
      //   this.calculateSubjectWiseOverallLevelAttainmentAnalysis()
      // }
    },
    calculateSubjectWiseOverallLevelAttainmentAnalysis() {
      this.overallAttainmentLoader = false;
      let percentage = 0;
      this.analysisV3.questionDetails.map((data) => {
        data.examDetails.map((exam) => {
          if (this.selectedAttainmentSubject === exam.subjectId) {
            percentage = Math.round((exam.obtainedMarks / exam.total) * 100);
            if (
              percentage >=
              this.attainmentLevelObject.exceedsExpectations.lowerLimit &&
              percentage <=
              this.attainmentLevelObject.exceedsExpectations.upperLimit
            ) {
              this.eeStudents++;
            } else if (
              percentage >=
              this.attainmentLevelObject.meetsExpectations.lowerLimit &&
              percentage <=
              this.attainmentLevelObject.meetsExpectations.upperLimit
            ) {
              this.meStudents++;
            } else if (
              percentage >=
              this.attainmentLevelObject.belowExpectations.lowerLimit &&
              percentage <=
              this.attainmentLevelObject.belowExpectations.upperLimit
            ) {
              this.beStudents++;
            }
          }
        });
        this.eeStudentPercentage = Math.round(
          (this.eeStudents / this.analysisV3.questionDetails.length) * 100
        );
        this.meStudentPercentage = Math.round(
          (this.meStudents / this.analysisV3.questionDetails.length) * 100
        );
        this.beStudentPercentage = Math.round(
          (this.beStudents / this.analysisV3.questionDetails.length) * 100
        );
      });
      console.log(
        "this.beStudentPercentage",
        this.beStudentPercentage,
        this.meStudentPercentage,
        this.eeStudentPercentage
      );
      this.overallAttainmentLoader = true;
      this.calculateQuestionWiseLevelAttainment();
    },
    async examAnalysisV3() {
      this.grandTotal = 0;
      this.averagePerformance = 0;
      this.analysisTableItems = [];
      this.failedCount = 0;
      this.grandTotalOfObtainedMarks = 0;
      this.subjectNames = [];
      this.tableHeaders = [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Total",
          value: "total"
        }
      ];
      this.subjectDetails.map((item) => {
        this.subjectNames.push({
          subjectName: item.subjectName,
          subjectId: item.subjectId
        });
        this.tableHeaders.push({
          text: item.subjectName,
          value: item.subjectId
        });
      });
      // console.log('this.tableHeaders', this.tableHeaders)
      this.attendanceDistribution = {
        "0 % - 10 %": 0,
        "10 % - 20 %": 0,
        "20 % - 30 %": 0,
        "30 % - 40 %": 0,
        "40 % - 50 %": 0,
        "50 % - 60 %": 0,
        "60 % - 70 %": 0,
        "70 % - 80 %": 0,
        "80 % - 90 %": 0,
        "90 % - 100 %": 0
      };
      this.analysisV3.questionDetails.map((data) => {
        let sumOfTotalObtained = 0;
        let sumOfTotal = 0;
        const objToPush = {
          name: data.stduentName
        };
        // const tempAvg = 0
        // console.log('data', data);
        // console.log('After subjectNames', this.subjectNames)
        data.examDetails.map((e, i) => {
          // console.log('e', e);
          // console.log('e.subjectName', e.subjectName);
          // console.log('e.studentName', e.studentName);
          //  console.log('e.total', e.total);
          //  console.log('e.obtainedMarks', e.obtainedMarks);
          // if (!this.tableHeaders.find(item => item.value === e.subjectId)) {
          //   this.tableHeaders.push({ text: e.subjectName, value: e.subjectId })
          // }
          // if (!this.subjectNames.find(item => ((item.subjectId === e.subjectId) && (this.subjectAssinedToFaculty.includes(e.subjectId))))) {
          //   this.subjectNames.push({ subjectName: e.subjectName, subjectId: e.subjectId })
          // }
          if (this.subjectAssinedToFaculty.includes(e.subjectId)) {
            if (e.obtainedMarks) {
              // console.log('e.total', e.total);
              sumOfTotal += parseInt(e.total);

              // console.log('sumOfTotal', sumOfTotal);
              sumOfTotalObtained += parseInt(e.obtainedMarks) || 0;
              objToPush[e.subjectId] =
                e.obtainedMarks +
                ` (${Math.round((e.obtainedMarks / e.total) * 100)}%)` || 0;
            }
          }
        });
        this.grandTotal += sumOfTotal;
        this.grandTotalOfObtainedMarks += sumOfTotalObtained;
        // console.log(' this.grandTotal', this.grandTotal)
        // this.grandTotal += sumOfTotal
        // this.grandTotalOfObtainedMarks += sumOfTotalObtained

        if (sumOfTotalObtained !== 0) {
          objToPush["total"] =
            Math.round((sumOfTotalObtained / sumOfTotal) * 100) + " %";
        }
        this.analysisTableItems.push(objToPush);
        console.log("this.analysisTableItems", this.analysisTableItems);

        let percentage = 0;
        percentage = Math.round((sumOfTotalObtained / sumOfTotal) * 100);

        if (percentage > 0 && percentage <= 10) {
          this.attendanceDistribution["0 % - 10 %"]++;
        }
        if (percentage > 10 && percentage <= 20) {
          this.attendanceDistribution["10 % - 20 %"]++;
        }
        if (percentage > 20 && percentage <= 30) {
          this.attendanceDistribution["20 % - 30 %"]++;
        }
        if (percentage > 30 && percentage <= 40) {
          this.attendanceDistribution["30 % - 40 %"]++;
        }
        if (percentage > 40 && percentage <= 50) {
          this.attendanceDistribution["40 % - 50 %"]++;
        }
        if (percentage > 50 && percentage <= 60) {
          this.attendanceDistribution["50 % - 60 %"]++;
        }
        if (percentage > 60 && percentage <= 70) {
          this.attendanceDistribution["60 % - 70 %"]++;
        }
        if (percentage > 70 && percentage <= 80) {
          this.attendanceDistribution["70 % - 80 %"]++;
        }
        if (percentage > 80 && percentage <= 90) {
          this.attendanceDistribution["80 % - 90 %"]++;
        }
        if (percentage > 90 && percentage <= 100) {
          this.attendanceDistribution["90 % - 100 %"]++;
        }
        if (percentage < this.failingLimit) {
          this.failedCount++;
        }
      });
      this.averagePerformance = (
        (this.grandTotalOfObtainedMarks / this.grandTotal) *
        100
      ).toFixed(2);

      const valueArray = [];
      for (const [, value] of Object.entries(this.attendanceDistribution)) {
        valueArray.push(parseInt(`${value}`));
      }
      this.donutOptions = {
        ...this.donutOptions,
        series: valueArray
      };
      this.overallAttainmentSubjects = this.subjectNames;
      this.selectedSubjectName = this.subjectNames[0];
      this.selectedBloomSubjectName = this.subjectNames[0];

      if (this.instituteType === "NMIMS") {
        this.selectedAttainmentSubject = this.subjectNames[0].subjectId;
        this.getLevelsOfInstitute(this.selectedAttainmentSubject);
        console.log(
          "this.selectedAttainmentSubject",
          this.selectedAttainmentSubject
        );
      }

      this.analysisTableItemsCopy = this.analysisTableItems;
      this.tableHeadersCopy = this.tableHeaders;
      this.handleBloomsAnalysis();
      this.dataLoaded = true;
    },
    async getLevelsOfInstitute(subjectId) {
      this.attainmentLevelObject = {};
      const nbaData = await this.nbaRepositoryInstance.getLevelsOfInstitute({
        lmsInstituteId: this.selectedInstitute,
        semesterId: this.selectedSemester.semId,
        subjectId: subjectId
      });
      console.log("nbaData", nbaData);
      if (nbaData) {
        if (this.instituteType === "NMIMS") {
          this.attainmentLevelObject = nbaData.data[0].attainmentLevels;
          this.calculateSubjectWiseOverallLevelAttainmentAnalysis();
          this.getTargetsOfAnInstitute();
        }
      }
    },
    async getTargetsOfAnInstitute() {
      this.targetsOfInstitute = {};
      const response = await this.nbaRepositoryInstance.getTargetsOfInstitute({
        lmsInstituteId: this.selectedInstitute,
        semesterId: this.selectedSemester.semId
      });
      console.log("response targets", response);
      this.targetsOfInstitute = response.data[0].targets;
    },
    calculateQuestionWiseLevelAttainment() {
      this.levelAttainmentQuestionArray = [];
      this.questionDetails.map((subject) => {
        if (subject.subjectId === this.selectedAttainmentSubject) {
          if (subject.questions) {
            this.levelAttainmentQuestionArray = subject.questions;
          }
        }
      });
      if (this.levelAttainmentQuestionArray.length > 0) {
        this.levelAttainmentQuestionArray.map((question, qi) => {
          question["attainmentObject"] = {
            beStudents: 0,
            meStudents: 0,
            eeStudents: 0
          };
          this.analysisV3.questionDetails.map((data) => {
            if (this.subjectAssinedToFaculty.includes(data.subjectId)) {
              if (data.subjectId === this.selectedAttainmentSubject) {
                data.examDetails.map((que, j) => {
                  let percentage = 0;
                  for (const [key, value] of Object.entries(que)) {
                    if (key.split(" ")[0] === "Q") {
                      if (key === `Q ${qi + 1}`) {
                        percentage = Math.round(
                          (Number(value) /
                            Number(
                              this.levelAttainmentQuestionArray[qi].marks
                            )) *
                          100
                        );
                        if (
                          percentage >=
                          this.attainmentLevelObject.exceedsExpectations
                            .lowerLimit &&
                          percentage <=
                          this.attainmentLevelObject.exceedsExpectations
                            .upperLimit
                        ) {
                          question.attainmentObject.beStudents++;
                        } else if (
                          percentage >=
                          this.attainmentLevelObject.meetsExpectations
                            .lowerLimit &&
                          percentage <=
                          this.attainmentLevelObject.meetsExpectations
                            .upperLimit
                        ) {
                          question.attainmentObject.meStudents++;
                        } else if (
                          percentage >=
                          this.attainmentLevelObject.belowExpectations
                            .lowerLimit &&
                          percentage <=
                          this.attainmentLevelObject.belowExpectations
                            .upperLimit
                        ) {
                          question.attainmentObject.eeStudents++;
                        }
                      }
                    }
                  }
                });
              }
            }
          });

          let sum = 0;
          for (const [key, value] of Object.entries(
            question.attainmentObject
          )) {
            console.log("key", key);
            sum += value;

            question.attainmentObject["beStudentPercentage"] =
              (question.attainmentObject.beStudents / sum) * 100;
            question.attainmentObject["meStudentPercentage"] =
              (question.attainmentObject.meStudents / sum) * 100;
            question.attainmentObject["eeStudentPercentage"] =
              (question.attainmentObject.eeStudents / sum) * 100;
          }
          console.log("question.attainmentObject", question.attainmentObject);
        });
      }
    },
    handleSubjectWiseAnalysis() {
      this.dataLoaded = false;
      this.attendanceDistribution = {
        "0 % - 10 %": 0,
        "10 % - 20 %": 0,
        "20 % - 30 %": 0,
        "30 % - 40 %": 0,
        "40 % - 50 %": 0,
        "50 % - 60 %": 0,
        "60 % - 70 %": 0,
        "70 % - 80 %": 0,
        "80 % - 90 %": 0,
        "90 % - 100 %": 0
      };
      if (this.selectedSubjectName.subjectId === "all") {
        this.dataLoaded = false;
        this.examAnalysisV3();
        this.handleBloomsAnalysis();
      }
      let percentage = 0;
      this.analysisV3.questionDetails.map((data) => {
        data.examDetails.map((exam) => {
          if (this.selectedSubjectName === exam.subjectId) {
            percentage = Math.round((exam.obtainedMarks / exam.total) * 100);
            if (percentage > 0 && percentage <= 10) {
              this.attendanceDistribution["0 % - 10 %"]++;
            }
            if (percentage > 10 && percentage <= 20) {
              this.attendanceDistribution["10 % - 20 %"]++;
            }
            if (percentage > 20 && percentage <= 30) {
              this.attendanceDistribution["20 % - 30 %"]++;
            }
            if (percentage > 30 && percentage <= 40) {
              this.attendanceDistribution["30 % - 40 %"]++;
            }
            if (percentage > 40 && percentage <= 50) {
              this.attendanceDistribution["40 % - 50 %"]++;
            }
            if (percentage > 50 && percentage <= 60) {
              this.attendanceDistribution["50 % - 60 %"]++;
            }
            if (percentage > 60 && percentage <= 70) {
              this.attendanceDistribution["60 % - 70 %"]++;
            }
            if (percentage > 70 && percentage <= 80) {
              this.attendanceDistribution["70 % - 80 %"]++;
            }
            if (percentage > 80 && percentage <= 90) {
              this.attendanceDistribution["80 % - 90 %"]++;
            }
            if (percentage > 90 && percentage <= 100) {
              this.attendanceDistribution["90 % - 100 %"]++;
            }
          }
        });
      });
      const subjectValueArray = [];
      for (const [, value] of Object.entries(this.attendanceDistribution)) {
        subjectValueArray.push(parseInt(`${value}`));
      }
      // console.log('subjectValueArray', subjectValueArray)
      this.donutOptions = {
        ...this.donutOptions,
        series: subjectValueArray
      };
      this.handleSubjectWiseBloomsAnalysis();
      this.dataLoaded = true;
    },

    editPassingThreshold() {
      this.isPassingThresholdEditable = !this.isPassingThresholdEditable;
    },
    showFailedStudents() {
      this.rangeVal = [0, parseInt(this.passingThreshold) - 1];
      this.allStudentBtn = false;
      this.failStudentBtn = true;
      this.applyLimit();
    },
    showAllStudents() {
      this.rangeVal = [0, 100];
      this.applyLimit();
      this.allStudentBtn = true;
      this.failStudentBtn = false;
    },

    applyLimit() {
      this.analysisTableItems = [];
      for (let i = 0; i < this.analysisTableItemsCopy.length; i++) {
        const total = parseInt(
          this.analysisTableItemsCopy[i].total
            .split("%")
            .join("")
            .split(" ")
            .join("")
        );
        if (total <= this.rangeVal[1] && total >= this.rangeVal[0]) {
          this.analysisTableItems.push(
            Object.assign(this.analysisTableItemsCopy[i])
          );
        }
      }
    },
    recalculateDefaulters() {
      this.failedCount = 0;
      for (let i = 0; i < this.analysisTableItemsCopy.length; i++) {
        if (this.analysisTableItemsCopy[i].total) {
          const total = Number(
            this.analysisTableItemsCopy[i].total
              .split("%")
              .join("")
              .split(" ")
              .join("")
          );
          if (total < Number(this.passingThreshold)) {
            this.failedCount++;
          }
        }
      }
    },
    handleBloomsAnalysis() {
      this.bloomsDataObject = {
        "Create (C)": 0,
        "Evaluate (E)": 0,
        "Analyse (A)": 0,
        "Apply (Ap)": 0,
        "Understand (U)": 0,
        "Remember (R)": 0
      };
      const bloomsData = {};
      this.totalBloomCount = 0;
      this.analysisV3.bloomsData.map((data) => {
        if (this.subjectAssinedToFaculty.includes(data.subjectId)) {
          if (data.uploadQuestions) {
            data.questions.map((que) => {
              if (que.bloomArray.length > 0) {
                que.bloomArray.map((bl) => {
                  this.totalBloomCount++;
                  if (bl.shortCode === "A") {
                    this.bloomsDataObject["Analyse (A)"]++;
                  }
                  if (bl.shortCode === "E") {
                    this.bloomsDataObject["Evaluate (E)"]++;
                  }
                  if (bl.shortCode === "C") {
                    this.bloomsDataObject["Create (C)"]++;
                  }
                  if (bl.shortCode === "Ap") {
                    this.bloomsDataObject["Apply (Ap)"]++;
                  }
                  if (bl.shortCode === "U") {
                    this.bloomsDataObject["Understand (U)"]++;
                  }
                  if (bl.shortCode === "R") {
                    this.bloomsDataObject["Remember (R)"]++;
                  }
                });
              }
            });
          } else if (!data.uploadQuestions) {
            data.blooms.map((bl) => {
              this.totalBloomCount++;
              if (bl === "A") {
                this.bloomsDataObject["Analyse (A)"]++;
              }
              if (bl === "E") {
                this.bloomsDataObject["Evaluate (E)"]++;
              }
              if (bl === "C") {
                this.bloomsDataObject["Create (C)"]++;
              }
              if (bl === "Ap") {
                this.bloomsDataObject["Apply (Ap)"]++;
              }
              if (bl === "U") {
                this.bloomsDataObject["Understand (U)"]++;
              }
              if (bl === "R") {
                this.bloomsDataObject["Remember (R)"]++;
              }
            });
          }
        }
      });
      if (this.totalBloomCount > 0) {
        for (const [key, value] of Object.entries(this.bloomsDataObject)) {
          bloomsData[key] = Math.round((value / this.totalBloomCount) * 100);
        }
      }
      this.bloomsDonutOptions = {
        ...this.bloomsDonutOptions,
        series: Object.values(bloomsData)
      };
    },
    handleSubjectWiseBloomsAnalysis() {
      this.totalBloomCount = 0;
      this.bloomsDataObject = {
        "Create (C)": 0,
        "Evaluate (E)": 0,
        "Analyse (A)": 0,
        "Apply (Ap)": 0,
        "Understand (U)": 0,
        "Remember (R)": 0
      };
      //  console.log(' this.selectedSubjectName', this.selectedSubjectName);
      if (this.selectedSubjectName === "all") {
        this.handleBloomsAnalysis();
      } else {
        this.analysisV3.bloomsData.map((subject) => {
          if (subject.subjectId === this.selectedSubjectName) {
            if (subject.uploadQuestions) {
              subject.questions.map((que) => {
                if (que.subjectId === this.selectedSubjectName) {
                  que.bloomArray.map((bl) => {
                    this.totalBloomCount++;
                    if (bl.shortCode === "A") {
                      this.bloomsDataObject["Analyse (A)"]++;
                    }
                    if (bl.shortCode === "E") {
                      this.bloomsDataObject["Evaluate (E)"]++;
                    }
                    if (bl.shortCode === "C") {
                      this.bloomsDataObject["Create (C)"]++;
                    }
                    if (bl.shortCode === "Ap") {
                      this.bloomsDataObject["Apply (Ap)"]++;
                    }
                    if (bl.shortCode === "U") {
                      this.bloomsDataObject["Understand (U)"]++;
                    }
                    if (bl.shortCode === "R") {
                      this.bloomsDataObject["Remember (R)"]++;
                    }
                  });
                }
              });
            } else {
              subject.blooms.map((bl) => {
                this.totalBloomCount++;
                if (bl === "A") {
                  this.bloomsDataObject["Analyse (A)"]++;
                }
                if (bl === "E") {
                  this.bloomsDataObject["Evaluate (E)"]++;
                }
                if (bl === "C") {
                  this.bloomsDataObject["Create (C)"]++;
                }
                if (bl === "Ap") {
                  this.bloomsDataObject["Apply (Ap)"]++;
                }
                if (bl === "U") {
                  this.bloomsDataObject["Understand (U)"]++;
                }
                if (bl === "R") {
                  this.bloomsDataObject["Remember (R)"]++;
                }
              });
            }
          }
        });
      }
      for (const [key, value] of Object.entries(this.bloomsDataObject)) {
        this.bloomsDataObject[key] = Math.round(
          (value / this.totalBloomCount) * 100
        );
      }
      this.bloomsDonutOptions = {
        ...this.bloomsDonutOptions,
        series: Object.values(this.bloomsDataObject)
      };
    },
    showAnalysis() {
      this.isAnalysisVisible = true;
      this.examApi();
    },
    hideAnalysis() {
      this.isAnalysisVisible = false;
    },
    // Handle excels
    async uploadQuestionExcel(sub) {
      const filteredSubject = this.subjectDetails.filter(
        (item) => item.subjectId === sub.subjectId
      );
      if (this.questionExcelFile) {
        var formData = new FormData();
        formData.append("excel", this.questionExcelFile);
        formData.append(
          "subjectTotalMarks",
          Number(filteredSubject[0].totalMarks)
        );
        axios
          .post(
            process.env.VUE_APP_SERVER_URL +
            process.env.VUE_APP_API_PORT +
            "/excel/exam/parse",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then((response) => {
            response.data.questionsArray.map((d) => {
              this.totalQuestions++;
              this.questionsArray.push({
                COArray: [],
                coDialog: false,
                bloomArray: [],
                bloomDialog: false,
                number: this.questionsArray.length + 1,
                marks: d.totalMarks,
                question: d.question,
                indivitualThreshold: this.overallThresholdPercentage
                  ? (d.totalMarks / 100) * this.overallThresholdPercentage
                  : ""
              });
            });
            this.questionSet = this.questionsArray;
            this.uploadQuestionExcelDialog = false;
          })
          .catch((error) => {
            if (
              error.response.data.customErrorMessage === "marksSumExceedError"
            ) {
              this.uploadQuestionExcelDialog = false;
              this.excelCustomError = true;
              this.excelCustomErrorMessage = `Cannot upload excel. Marks sum exceeding ${filteredSubject[0].totalMarks}.`;
            }
          });
      }
      this.questionExcelFile = "";
      this.questionsLoadedViaExcel = true;
    },
    async uploadMarksExcel(subject) {
      this.studentExceedingMarksLimit = [];
      const filteredSubject = this.subjectDetails.filter(
        (item) => item.subjectId === subject.subjectId
      );
      if (this.marksExcelFile) {
        var formData = new FormData();
        formData.append("excel", this.marksExcelFile);
        formData.append("marksUploadType", this.toggleMarksUpload);
        formData.append("questionsUploadType", this.toggleQuestionUpload);
        axios
          .post(
            process.env.VUE_APP_SERVER_URL +
            process.env.VUE_APP_API_PORT +
            "/excel/exam/parseMarks",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(async (response) => {
            // this.uploadMarksManually(subject)
            // this.saveMarksViaExcel(response.data.studentData, subject)
            // this.uploadMarksExcelDialog = false
            await this.uploadMarksManually(subject);

            if (this.toggleMarksUpload === "aggregate") {
              for (
                let index = 0;
                index < response.data.studentData.length;
                index++
              ) {
                const element = response.data.studentData[index];
                if (
                  element.obtainedMarks > Number(filteredSubject[0].totalMarks)
                ) {
                  this.studentExceedingMarksLimit.push(element.studentName);
                }
              }
            } else if (this.toggleMarksUpload === "individual") {
              for (
                let index = 0;
                index < response.data.studentData.length;
                index++
              ) {
                const element = response.data.studentData[index];

                for (const [key, value] of Object.entries(element)) {
                  if (key.split(" ")[0] === "Q") {
                    const filteredQuestion = this.questionSet.filter(
                      (item) => item.questionNumber === key
                    )[0];

                    if (value > filteredQuestion.marks) {
                      if (
                        this.studentExceedingMarksLimit.indexOf(
                          element.studentName
                        ) === -1
                      ) {
                        this.studentExceedingMarksLimit.push(
                          element.studentName
                        );
                      }
                    }
                  }
                }
              }
            }
            this.uploadMarksExcelDialog = false;
            if (this.studentExceedingMarksLimit.length === 0) {
              this.saveMarksViaExcel(response.data.studentData, subject);
            } else {
              this.excelCustomError = true;
              this.excelCustomErrorMessage =
                "Cannot upload excel. Please recheck the marks of following students as the marks are exceeding the limit.";
            }
          });
      }
      this.marksExcelFile = "";
      this.marksLoadedViaExcel = true;
      // this.uploadMarksManuallyStatus = true
    },
    saveMarksViaExcel(studentData, sub) {
      sub.studentData.map((stud) => {
        studentData.map((data, j) => {
          let marks;
          if (stud.studentName === data.studentName) {
            if (
              this.toggleQuestionUpload === "dontUploadQuestions" &&
              this.toggleMarksUpload === "aggregate"
            ) {
              Object.assign(stud, data);
              Object.assign(stud, { uploadMarksType: "aggregate" });
              Object.assign(sub, { uploadMarksType: "aggregate" });
            } else if (this.toggleMarksUpload === "individual") {
              Object.assign(stud, data);
              Object.assign(stud, { uploadMarksType: "individual" });
              Object.assign(sub, { uploadMarksType: "individual" });
            } else if (this.toggleMarksUpload === "aggregate") {
              this.questionSet.map((ques, i) => {
                // data['obtainedMarks'] = data.obtainedMarks
                marks = data.obtainedMarks / this.questionSet.length;
                if (i + 1 !== this.questionSet.length) {
                  data["uploadMarksType"] = "aggregate";
                  data[`Q ${i + 1}`] = Math.ceil(marks * 100) / 100;
                } else {
                  data["uploadMarksType"] = "aggregate";
                  data[`Q ${i + 1}`] = Math.floor(marks * 100) / 100;
                }
              });
              Object.assign(stud, data);
              Object.assign(sub, { uploadMarksType: "aggregate" });
            }
          }
          // console.log('data', data);
        });
      });
    },
    toggleUploadMarksViaExcel() {
      this.uploadMarksExcelDialog = true;

      if (
        this.toggleQuestionUpload === "uploadQuestions" &&
        this.toggleMarksUpload === "individual"
      ) {
        this.marksSampleExcelLink =
          "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1669375313604________questionwise_individual_marks_sample.xlsx?alt=media&token=551218f8-7512-4b55-a4a1-32121f8748e8";
      } else if (
        this.toggleQuestionUpload === "uploadQuestions" &&
        this.toggleMarksUpload === "aggregate"
      ) {
        this.marksSampleExcelLink =
          "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1669375235224________questionwise_aggregate_marks_sample.xlsx?alt=media&token=57bb5196-ca79-40fb-9b2a-7d286be2ed7a";
      } else if (
        this.toggleQuestionUpload === "dontUploadQuestions" &&
        this.toggleMarksUpload === "aggregate"
      ) {
        this.marksSampleExcelLink =
          "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1669374075360________noquestions_overall_marks_sample.xlsx?alt=media&token=df16ebbc-5480-4375-a143-4eb893cfae6e";
      }
    },
    validateQuestionMarks(question) {
      this.maxQuestionMarks = 0;
      this.maxQuestionMarks = question.marks;
    },
    async generateMarksSampleExcel(subject) {
      subject["toggleMarksUpload"] = this.toggleMarksUpload;
      subject["toggleQuestionUpload"] = this.toggleQuestionUpload;
      try {
        const pdfBlob =
          await this.examStudentRepositoryInstance.generateStudentMarksExcel(
            subject
          );
        const pdfBlob2 = new Blob([pdfBlob.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(pdfBlob2);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Marks Sample.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.log("error", err);
      }
    }
  }
};
</script>
<style scoped src='./activityEditExam.css'></style>
