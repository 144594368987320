import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"2"}},[_c('div',{staticStyle:{"margin-bottom":"5px","margin-left":"10px","font-size":"14px","font-weight":"400","color":"#3a3a3a","margin-top":"6px"}},[_vm._v(" Progress Indicator: ")])]),_c(VCol,{staticStyle:{"margin-left":"-20px","margin-right":"4px","margin-top":"14p"},attrs:{"cols":"10"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c(VProgressLinear,{attrs:{"value":_vm.progressIndicator1,"height":"10","color":"#00128C","rounded":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',{staticStyle:{"margin-bottom":"0px","color":"white","font-size":"smaller"}},[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}])})],1)])],1),[_c('div',{staticClass:"center-container",staticStyle:{"margin-top":"30px"}},[_c(VTabs,{attrs:{"centered":"","hide-slider":""},model:{value:(_vm.tabs1),callback:function ($$v) {_vm.tabs1=$$v},expression:"tabs1"}},_vm._l((_vm.secondaryTabItems),function(item,i){return _c(VTab,{key:item,staticClass:"navTab1",staticStyle:{"border-radius":"5px"},attrs:{"ripple":false,"active-class":"navTabActive1"},on:{"change":function($event){return _vm.checkTabItem(item, i)}}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)],_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{staticClass:"custom-search-box",attrs:{"label":"Search","hide-details":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{staticClass:"custom-table",attrs:{"headers":_vm.headers,"items":_vm.selectedSubjects,"search":_vm.search},scopedSlots:_vm._u([{key:"item.srNo",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index+1)+" ")]}},{key:"item.subjectCode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subjectCode)+" ")]}},{key:"item.subjectName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subjectName)+" ")]}},{key:"item.totalMarks",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalMarks)+" ")]}},{key:"item.threshold",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.threshold)+" ")]}},{key:"item.studentMarks",fn:function(ref){
var item = ref.item;
return [(item.isMarksGiven)?_c(VIcon,{staticClass:"paddingWithBorder",staticStyle:{"color":"#ff4f14","margin-left":"5px"},attrs:{"size":"16"},on:{"click":function($event){return _vm.goToAddMarks(item)}}},[_vm._v("mdi-check-bold")]):_c(VIcon,{staticClass:"paddingWithBorder",staticStyle:{"color":"#ff4f14","margin-left":"5px"},attrs:{"size":"16"},on:{"click":function($event){return _vm.goToAddMarks(item)}}},[_vm._v("mdi-plus")])]}},{key:"item.markingMethod",fn:function(ref){
var item = ref.item;
return [_vm._v(" - ")]}},{key:"item.passedStudents",fn:function(ref){
var item = ref.item;
return [_vm._v(" - ")]}},{key:"item.failedStudents",fn:function(ref){
var item = ref.item;
return [_vm._v(" - ")]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }