const state = {
  // this object contains data entities in 'User' store
  user: null,
  authToken: '',
  userData: null,
  userName: ''
}

const getters = {
  // getters for state
  get_user: state => state.user,
  get_authToken: state => state.authToken,
  get_userData: state => state.userData,
  get_userName: state => state.userName
}

const mutations = {
  // mutations for state
  set_user: (state, value) => {
    state.user = value
  },
  set_userData: (state, value) => {
    state.userData = value
  },
  set_userName: (state, value) => {
    state.userName = value
  },
  set_authToken: (state, value) => {
    state.authToken = value
  },
  reset (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

const actions = {
  // actions for state
  set_user: ({ commit, state }, newValue) => {
    commit('set_user', newValue)
    return state.message
  },
  set_userData: ({ commit, state }, newValue) => {
    commit('set_userData', newValue)
    return state.message
  },
  set_userName: ({ commit, state }, newValue) => {
    commit('set_userName', newValue)
    return state.message
  },
  set_authToken: ({ commit, state }, newValue) => {
    commit('set_authToken', newValue)
    return state.message
  }
}

export default {
  // exports the vuex object of 'user' store
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

function initialState () {
  return {
    // this object contains data entities in 'User' store
    user: null,
    authToken: '',
    userData: null,
    userName: ''
  }
}
