import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/courses";
import v3 from "../NetworkManager/apiV3"

class Courses {
  constructor(context) {
    this.context = context;
  }

  async updateCourseInfo(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.updateCourseInfo,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSingleCourse(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSingleCourse,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.course;
  }

  async getMediaLibrary(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.mediaLibrary,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.media;
  }

  async getAllCoursesInfoOfInstitute(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getAllCoursesInfoOfInstitute,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.course;
  }

  async getAllCoursesInfoOfStudent(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getAllCoursesInfoOfStudent,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.course;
  }

  async createStudentCourse(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createStudentCourse,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.course;
  }

  async getAllMediaLibraryByInstitute(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(ApiNames);
    const response = await networkManager.getRequest(
      ApiNames.getAllMediaLibraryByInstitute,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.media;
  }

  async getSingleStudentCourse(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(ApiNames);
    const response = await networkManager.getRequest(
      ApiNames.getSingleStudentCourse,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response.course;
  }

  async getPresignedUrlForR2(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log(ApiNames);
    const response = await networkManager.getRequest(ApiNames.getPresignedUrlForR2, data);
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSignedUrl(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getSignedUrl,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async generateStudentCourseReport(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.downloadFile(
      ApiNames.generateStudentCourseReport,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async generateStudentFeedbackReport(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.downloadFile(
      ApiNames.generateStudentFeedbackReport,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getProgressPercentageOfStudentsInCourse(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getProgressPercentageOfStudentsInCourse,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSubsectionCompletionStatus(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getSubsectionCompletionStatus,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getSyncCoursesDataOfSingleStudent(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getSyncCoursesDataOfSingleStudent,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getCourseRatingDetails(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getCourseRatingDetails,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getMultiCourseRatings(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getMultiCourseRatings,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async createMultipleLogs(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await v3.postRequest(
      ApiNames.createMultipleLogs,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async getPublishLogs(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await v3.getRequest(
      ApiNames.getPublishLogs,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
}
export default Courses;
