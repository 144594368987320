<script>
//  Importing Bar class from the vue-chartjs wrapper
import { Bar } from 'vue-chartjs'
//  Exporting this so it can be used in other components
export default {
  extends: Bar,
  data () {
    return {
      datacollection: {
        //  Data to be represented on x-axis
        // Options here
        labels: [],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: [
              'rgba(54, 162, 235, 0.8)',
              'rgba(255, 99, 132, 0.8)',
              'rgba(255, 206, 86, 0.8)',
              'rgba(75, 192, 192, 0.8)',
              'rgba(153, 102, 255, 0.8)',
              'rgba(255, 159, 64, 0.8)',
              'rgba(54, 162, 235, 0.8)',
              'rgba(255, 99, 132, 0.8)',
              'rgba(255, 206, 86, 0.8)',
              'rgba(75, 192, 192, 0.8)',
              'rgba(153, 102, 255, 0.8)',
              'rgba(255, 159, 64, 0.8)'
            ],
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            //  Data to be represented on y-axis
            // no. of answers here
            data: []
          }
        ]
      },
      //  Chart.js options that controls the appearance of the chart
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            //   max: 0
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  props: [
    'attendanceDistribution'
  ],
  mounted () {
    this.datacollection.labels = Object.keys(this.attendanceDistribution)
    this.datacollection.datasets[0].data = Object.values(this.attendanceDistribution)
    // this.options.scales.yAxes[0].ticks.max = Object.values(this.attendanceDistribution).length
    //  renderChart function renders the chart with the datacollection and options object.
    this.renderChart(this.datacollection, this.options)
  }
}
</script>
