<template src="./activityStudyMaterial.html"></template>
<script>
import auth from "../../Services/Firebase/auth";
import SemesterRepository from "../../Repository/Semester";
import SubjectsRepository from "../../Repository/Subjects";
import TopicsRepository from "../../Repository/Topic";
import UserRepository from "../../Repository/User";
import StudyMaterialRepository from "../../Repository/StudyMaterial";
import NbaRepository from "../../Repository/NBA";
import inputContainer from "../../Components/inputContainer";
import arrayOps from "../../Services/Utils/arrayOps";
import { convertToISTTime } from "../../Services/Utils/DateTimeFormatter";
import PostsRepository from "../../Repository/Posts";
import _ from "lodash";
import { spacesUrl } from "../../NetworkManager";
import showStatus from "../../../src/NetworkManager/showStatus";
var firestorage = null;
export default {
  name: "activityStudyMaterial",
  components: {
    inputContainer
  },
  props: ['prop_subject', 'prop_type', 'prop_groupInfo'],
  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        "state_changed",
        sp => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploadEnd = true;
            // console.log("hello", downloadURL);
            const indexOfSlash = this.fileCurrentlyUploading.type.indexOf("/");
            this.downloadURLs.push({
              downloadURL: downloadURL,
              isMedia: ["image", "video"].includes(
                this.fileCurrentlyUploading.type
                  .substring(0, indexOfSlash)
                  .toLowerCase()
              )
            });
            this.uploadCount++;
            if (this.uploadCount === this.files.length) {
              this.uploadPost();
            } else {
              this.progressUpload = 0;
              this.upload(this.uploadCount);
            }
          });
        }
      );
    },
    publishAt(val) {
      if (!val) {
        this.$refs.publishAtRef.clearHandler()
      }
    }
  },
  data() {
    return {
      copyStudyMaterialDialog: false,
      addNewStudyMaterialDialog: false,
      allTopics: [],
      filterSubject: "",
      isDownloadable: false,
      filterTopic: "All",
      editingPostId: "",
      videoFiles: [],
      imageFiles: [],
      documentFiles: [],
      filterFileFormats: [],
      fileFormats: ["All", "Videos", "Images", "Documents"],
      filterTopicId: "all",
      filterFormat: "All",

      nonEmptyFiles: 0,

      subjects: [],
      selectedSubject: "",
      selectedSubjectId: "",
      topics: [],
      selectedTopic: "",
      selectedTopicId: "",
      postTitle: "",
      postDescription: "",
      videoFormats: ["mp4", "avi", "mpeg", "mov", "wmv"],
      imageFormats: ["jpg", "png"],
      documentFormats: ["pdf", "doc", "docx", "txt", "xls", "xlsx", "svg", "ppt", "pptx"],
      posts: [],
      playingIndex: undefined,
      currentlyUploadingNumber: 0,
      currentUploadProgress: 0,
      uploadedVideoFiles: [],
      uploadedImageFiles: [],
      uploadedDocumentFiles: [],
      snackbar: false,
      message: "",
      snackbarColor: 'green',
      isMobileView: false,
      userData: {},
      files: [],
      files2: [],
      tempTitle: "",
      tempDescription: "",
      uploadTask: "",
      uploadEnd: false,
      uploading: false,
      showAddingDialog: false,
      showToStudents: false,
      showToFaculties: false,
      selectedDepartment: "",
      selectedDepartment2: "",
      departments: [],
      selectedCourseYear: "",
      courseYears: [],
      selectedDivision: "",
      divisions: [],
      selectedInstitute: "",
      selectedSemester: {},
      academicDataTree: {},
      isImportant: false,
      selectedFacultyCriteria: [],
      selectedStudentCriteria: [],
      fileCurrentlyUploading: "",
      postIdInFocus: "",
      publishAt: "",
      isDeletingPost: false,
      isAddingMaterial: false,
      isLoadingMaterial: true,
      type: '',
      showModal: false,
      cosSelectedForTopic: [],
      bloomsSelectedForTopic: [],
      courseOutcomeData: [],
      selectedStudyMaterialForCopy: [],
      allCriteriaCombination: [],
      selectedCopiedCriteria: [],
      finalDataForCombination: [],
      allBlooms: [
        {
          name: "CREATE (C)",
          shortCode: "C",
          description:
            "Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)"
        },
        {
          name: "EVALUATE (E)",
          shortCode: "E",
          description:
            "Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)"
        },
        {
          name: "ANALYZE (A)",
          shortCode: "A",
          description:
            "Draw connections among ideas (Verbs : differentiate, organise, relate, compare, contrast, distinguish, examine, experiment, question, test)"
        },
        {
          name: "APPLY (Ap)",
          shortCode: "Ap",
          description:
            "Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)"
        },
        {
          name: "UNDERSTAND (U)",
          shortCode: "U",
          description:
            "Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)"
        },
        {
          name: "REMEMBER (R)",
          shortCode: "R",
          description:
            "Recall facts and basic concepts (Verbs : define, duplicate, list, memorise, repeat, state)"
        }
      ]
    };
  },
  async created() {
    console.log('prop_type', this.prop_subject)
    console.log('prop_groupInfo', this.prop_groupInfo)
    this.type = this.prop_type
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    const firebase = auth.getFirebaseObject();
    firestorage = firebase.storage();
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.postsRepositoryInstance = new PostsRepository(this);
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.topicsRepositoryInstance = new TopicsRepository(this);
    this.studyMaterialRepositoryInstance = new StudyMaterialRepository(this);
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.selectedInstitute = this.$store.getters[
      "instituteData/get_selectedInstitute"
    ];
    this.selectedSemester = this.$store.getters[
      "instituteData/get_selectedSemester"
    ];
    this.$store.commit("liveData/set_selectedActivityName", "Study Material");
    this.userData = this.$store.getters["user/get_userData"];
    console.log('this.userData', this.userData);
    if (this.prop_subject) {
      // console.log('this.prop_subject', this.prop_subject.subjectName);
      this.selectedSubjectId = this.prop_subject.subjectId;
      await this.fetchTopics(this.prop_subject.subjectId);
    }

    // console.log(this.userData);
    this.getMasterDataTree();
    this.fetchData();
    this.getCourseOutcome();
  },
  methods: {
    dialogOpenForAddStudyMaterial() {
      this.addNewStudyMaterialDialog = true
      this.clearFields()
    },
    async saveCopiedStudyMaterial() {
      this.copyStudyMaterialDialog = false
      console.log('this.selectedStudyMaterialForCopy', this.selectedStudyMaterialForCopy);
      const arr = []
      const finalStudyMaterial = []
      this.selectedCopiedCriteria.map((info) => {
        this.finalDataForCombination.map((allocation) => {
          if (info === allocation.criteriaString && allocation.subjectId === this.prop_subject.subjectId) {
            arr.push(allocation)
          }
        })
      })
      this.selectedStudyMaterialForCopy.map((value) => {
        console.log('arr', arr);
        let studyMaterial = {}
        this.posts.map((study) => {
          if (study.title === value) {
            studyMaterial = study
          }
        })
        console.log('arrAllocation', arr);
        arr.map((allocation) => {
          const obj = {
            ...studyMaterial
          }
          delete obj._id
          delete obj.updatedAt
          delete obj.createdAt
          delete obj.groupId
          delete obj.batch
          delete obj.division
          obj.publishAt = new Date().toISOString()
          obj.department = this.prop_subject.department
          obj.courseYear = this.prop_subject.courseYear
          if (allocation.division && allocation.division !== '') {
            obj.division = allocation.division
          }
          if (allocation.batchName && allocation.batchName !== '') {
            obj.batch = allocation.batchName
          }
          if (allocation.groupId && allocation.groupId !== '') {
            obj.groupId = allocation.groupId
          }
          console.log('obj', obj);
          finalStudyMaterial.push(obj)
        })
      })
      try {
        const data = await this.studyMaterialRepositoryInstance.bulkCreateStudyMaterial(finalStudyMaterial)
        console.log('data', data);
        showStatus('Study material copied successfully.', 3000, 'success', this);
      } catch (err) {
        console.error('err', err);
      }
    },
    getAllCombinationForFacultyTeaching() {
      let currentCriteria = ''
      if (this.prop_type === "Division") {
        currentCriteria = `${this.prop_subject.department}_${this.prop_subject.courseYear}_${this.prop_subject.division}`
      }
      if (this.prop_type === "Batch") {
        currentCriteria = `${this.prop_subject.department}_${this.prop_subject.courseYear}_${this.prop_subject.division}_${this.prop_subject.batch}`
      }
      if (this.prop_type === "Group") {
        currentCriteria = `${this.prop_subject.department}_${this.prop_subject.courseYear}_${this.prop_groupInfo.groupName}`
      }
      this.allCriteriaCombination = []
      console.log('this.userData.assignedGroupForSubject', this.userData.assignedGroupForSubject);
      let arr = []
      if (this.userData.assignedGroupForSubject) {
        console.log('this.userData.assignedGroupForSubject', this.userData.assignedGroupForSubject);
        arr = [...this.userData.assignedGroupForSubject]
      }
      if (this.userData.subjectAllocatedForDiv) {
        arr = [...arr, ...this.userData.subjectAllocatedForDiv]
      }
      console.log('arr', arr);
      let value = ''
      if (arr.length > 0) {
        arr.map((info) => {
          if (info.subjectId === this.prop_subject.subjectId) {
            value = `${this.prop_subject.department}_${this.prop_subject.courseYear}`
            console.log('value', value);
            if (info.division && info.division !== '') {
              value = `${info.subjectDetails.department}_${info.subjectDetails.courseYear}_${info.division}`
            }
            if (info.batchName && info.batchName !== '') {
              value = value + '_' + info.batchName
            }
            if (info.groupName && info.groupName !== '') {
              console.log('value1', value);
              value = value + '_' + info.groupName
              console.log('value2', value);
            }
          }
          console.log('value', value);
          info['criteriaString'] = value
          if (currentCriteria !== value) {
            this.allCriteriaCombination.push(value)
          }
        })
      }
      this.finalDataForCombination = arr
    },

    async getCourseOutcome() {
      try {
        this.courseOutcomeData = [];
        console.log("", this.selectedInstitute, this.selectedSemester.semId, this.selectedSubjectId)
        const cos = await this.nbaRepositoryInstance.getCos({
          lmsInstituteId: this.selectedInstitute,
          semesterId: this.selectedSemester.semId,
          subjectId: this.selectedSubjectId
        });
        console.log("cos", cos);
        if (Array.isArray(cos.data)) {
          cos.data.forEach((nba) => {
            this.courseOutcomeData.push({
              id: nba._id,
              coNumber: nba.CONumber,
              coName: nba.COName
            });
          });
        } else {
          console.log("No COs Added")
        }
      } catch (err) {
        console.log(err);
      }
      console.log('COs', this.courseOutcomeData)
    },
    appendVideoFileInput() {
      this.videoFiles.push({
        name: "",
        thumbnailUrl: "",
        url: ""
      });
    },

    appendImageFileInput() {
      this.imageFiles.push({
        name: "",
        thumbnailUrl: "",
        url: ""
      });
    },
    appendDocumentFileInput() {
      this.documentFiles.push({
        name: "",
        thumbnailUrl: "",
        url: ""
      });
    },
    onDialogCancelClick() {
      this.showAddingDialog = false;
      this.imageFiles = [];
      this.videoFiles = [];
      this.documentFiles = [];
      this.currentlyUploadingNumber = 0;
    },
    async deleteMaterial() {
      try {
        console.log("postIdInFocus", this.postIdInFocus);
        this.isDeletingPost = true
        await this.studyMaterialRepositoryInstance.deleteStudyMaterialOfFaculty({
          _id: this.postIdInFocus
        });
        this.fetchData();
        this.showModal = false;
        this.postIdInFocus = ''
        this.snackbarColor = 'green'
        this.message = 'Deleted Successfully'
        this.snackbar = true
        await this.fetchPosts();
      } catch (e) {
        console.error(e)
        this.snackbarColor = 'red'
        this.message = 'An error occured'
        this.snackbar = true
      } finally {
        this.isDeletingPost = false
      }
    },
    async onFilterBySubject(subjectName) {
      this.filterSubject = subjectName;
      const selectedSubjectObj = this.subjects.filter(
        item => item.subjectName === subjectName
      );
      if (selectedSubjectObj.length > 0) {
        const { subjectId } = selectedSubjectObj[0];
        // console.log("Filter Subject", subjectId, subjectName);
        await this.fetchTopics(subjectId);
      }
    },
    async onFilterByFileFormat(fileFormat) {
      this.filterFileFormat = fileFormat;
      let mimeTypes = [];
      switch (fileFormat) {
        case "Videos": {
          mimeTypes = this.videoFormats;
          break;
        }
        case "Images": {
          mimeTypes = this.imageFormats;
          break;
        }
        case "Documents": {
          mimeTypes = this.documentFormats;
          break;
        }
        default: {
          mimeTypes = [];
        }
      }

      this.filterFileFormats = mimeTypes;
      const params = {};
      if (mimeTypes.length > 0) {
        params.mimeTypes = mimeTypes;
      }

      if (this.filterTopicId && this.filterTopicId !== "all") {
        params.topicId = this.filterTopicId;
      }
      await this.fetchPosts(params);
    },
    async onFilterByTopic(topicName) {
      this.filterTopic = topicName;
      const selectedTopicObj = this.topics.filter(
        item => item.topicName === topicName
      );
      if (selectedTopicObj.length > 0) {
        const { topicId } = selectedTopicObj[0];
        // console.log("Selected Topic", topicId, topicName);
        const params = {
          topicId
        };
        if (topicId === "all") {
          delete params.topicId;
          this.filterTopicId = "";
        } else {
          this.filterTopicId = topicId;
        }
        if (this.filterFileFormats.length > 0) {
          params.mimeTypes = this.filterFileFormats;
        }
        await this.fetchPosts(params);
      }
    },
    async fetchSubjects() {
      try {
        const {
          subjects
        } = await this.subjectsRepositoryInstance.getSubjectsOfAFaculty({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.userData.uId
        });
        this.subjects = subjects.map(item => ({
          subjectId: item.subjectId,
          subjectName: item.subjectName
        }));
        // this.subjects = subjects
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTopics(subjectId) {
      try {
        let topics = await this.topicsRepositoryInstance.getTopicsOfASubject({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectId
        });
        this.allTopics = topics
        // console.log('topics123', topics);
        if (this.prop_type === "Division") {
          let onlyDivisionMaterial = []
          if (topics.length > 0) {
            onlyDivisionMaterial = topics.filter((data) => data.division === this.prop_subject.division && (data.batch === undefined || data.batch === ''))
            topics = onlyDivisionMaterial
          }
        }
        if (this.prop_type === "Batch") {
          let onlyDivisionMaterial = []
          if (topics.length > 0) {
            onlyDivisionMaterial = topics.filter((data) => data.division === this.prop_subject.division && data.batch === this.prop_subject.batch)
            topics = onlyDivisionMaterial
          }
        }
        if (this.prop_type === "Group") {
          let onlyGroupMaterial = []
          if (topics.length > 0) {
            onlyGroupMaterial = topics.filter((data) => data.assignedGroupForSubject?.groupId === this.prop_groupInfo.groupId)
            topics = onlyGroupMaterial
          }
        }

        // this.topics = topics;
        const uniqueTopics = _.uniqBy(topics, "topicId").map(item => ({
          topicId: item.topicId,
          topicName: item.topicName,
          cos: item.cos,
          blooms: item.blooms
        }));
        this.topics = [{ topicId: "all", topicName: "All" }, ...uniqueTopics];
        // console.log("Unique Topics", uniqueTopics);
      } catch (error) {
        console.log(error);
      }
    },
    async onSubjectSelect(subjectName) {
      this.selectedSubject = subjectName;
      const selectedSubjectObj = this.subjects.filter(
        item => item.subjectName === subjectName
      );
      if (selectedSubjectObj.length > 0) {
        const { subjectId } = selectedSubjectObj[0];
        // console.log("Selected Subject", subjectId, subjectName);
        this.selectedSubjectId = subjectId;
        await this.fetchTopics(subjectId);
      }
    },
    async onTopicSelect(topicName) {
      this.selectedTopic = topicName;
      const selectedTopicObj = this.topics.filter(
        item => item.topicName === topicName
      );
      if (selectedTopicObj.length > 0) {
        const { topicId } = selectedTopicObj[0];
        this.cosSelectedForTopic = this.courseOutcomeData.filter((item) => selectedTopicObj[0].cos.includes(item.coNumber))
        this.bloomsSelectedForTopic = this.allBlooms.filter((item) => selectedTopicObj[0].blooms.includes(item.shortCode))
        this.selectedTopicId = topicId;
        // console.log("Selected Topic", topicId, topicName);
      }
    },
    async checkifDownloadable() {
      // console.log('isDownloadable', this.isDownloadable);
    },
    async onAddStudyMaterial() {
      this.addNewStudyMaterialDialog = false
      try {
        this.isAddingMaterial = true
        const videoMaterials = this.uploadedVideoFiles;
        const imageMaterials = this.uploadedImageFiles;
        const documentMaterials = this.uploadedDocumentFiles;

        const media = [
          ...videoMaterials,
          ...imageMaterials,
          ...documentMaterials
        ];
        const mimeTypes = _.uniqBy(media, "mimeType").map(item => item.mimeType);
        const data = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          media,
          subjectId: this.selectedSubjectId,
          subjectName: this.prop_subject.subjectName,
          isDownloadable: this.isDownloadable,
          topicId: this.selectedTopicId,
          topicName: this.selectedTopic,
          title: this.postTitle,
          description: this.postDescription,
          isImportant: this.isImportant,
          uId: this.userData.uId,
          mimeTypes,
          publishAt: this.publishAt ? this.publishAt : new Date().toISOString()
        };
        if (this.prop_type === "Group") {
          data.assignedGroupForSubject = {
            groupName: this.prop_groupInfo.groupName,
            groupId: this.prop_groupInfo.groupId
          }
        }
        if (this.prop_type === "Batch") {
          data.division = this.prop_subject.division
          data.batch = this.prop_subject.batch
        }
        if (this.prop_type === "Division") {
          data.division = this.prop_subject.division
        }
        // console.log('this.prop_type', this.prop_type)
        console.log("Study Material", data);
        const response = await this.studyMaterialRepositoryInstance.createStudyMaterial(
          data
        );
        console.log("Response", response);

        this.clearFields();
        this.message = "Course Content added successfully!";
        this.snackbarColor = 'green'
        this.snackbar = true;
        await this.fetchPosts();
      } catch (e) {
        this.message = "An error occured, try later";
        this.snackbarColor = 'red'
        this.snackbar = true;
      } finally {
        this.isAddingMaterial = false
      }
    },
    clearFields() {
      this.videoFiles = [];
      this.uploadedVideoFiles = [];
      this.imageFiles = [];
      this.uploadedImageFiles = [];
      this.documentFiles = [];
      this.uploadedDocumentFiles = [];
      this.currentlyUploadingNumber = 0;
      this.currentUploadProgress = 0;
      this.isDownloadable = false;
      this.selectedTopicId = "";
      this.selectedTopic = "";
      this.selectedSubject = "";
      this.isImportant = false;
      this.postTitle = "";
      this.postDescription = "";
      this.publishAt = "";
    },
    async onDialogAddClick() {
      this.currentlyUploadingNumber = 0;
      this.currentUploadProgress = 0;
      this.uploading = true;
      this.nonEmptyFiles =
        this.imageFiles.filter(item => item.name).length +
        this.videoFiles.filter(item => item.name).length +
        this.documentFiles.filter(item => item.name).length;
      // console.log("Total Files", this.nonEmptyFiles);

      this.uploadedVideoFiles = await this.uploadVideos();
      this.uploadedImageFiles = await this.uploadImages();
      this.uploadedDocumentFiles = await this.uploadDocuments();

      this.onDialogCancelClick();
      this.uploading = false;
    },
    async uploadImages() {
      if (this.imageFiles.length === 0) return [];
      const imageFilePromises = [];
      const imageFiles = this.imageFiles.filter(item => item.name);
      imageFiles.forEach(imageFile => {
        imageFilePromises.push(
          this.getSignedUrl(imageFile, `${this.userData.uId}/images/`)
        );
      });
      const signedUrlsData = await Promise.all(imageFilePromises);
      const signedUrls = signedUrlsData.map(item => item.signedUrl);
      // console.log("Signed urls", signedUrlsData);

      const uploadPromises = [];
      if (signedUrls.length === imageFiles.length) {
        imageFiles.forEach((imageFile, i) => {
          uploadPromises.push(this.uploadToSpaces(imageFile, signedUrls[i]));
        });

        const uploadResponse = await Promise.all(uploadPromises);
        console.log("Upload response", uploadResponse);
        return signedUrlsData.map((item, i) => ({
          url: spacesUrl + "/" + item.fileName,
          thumbnailUrl: "",
          name: imageFiles[i].name,
          mimeType: item.mimeType
        }));
      }
      return [];
    },
    async uploadVideos() {
      if (this.videoFiles.length === 0) return [];

      const videoFilePromises = [];
      const videoFiles = this.videoFiles.filter(item => item.name);

      videoFiles.forEach(videoFile => {
        videoFilePromises.push(
          this.getSignedUrl(videoFile, `${this.userData.uId}/videos/`)
        );
      });
      const signedUrlsData = await Promise.all(videoFilePromises);
      const signedUrls = signedUrlsData.map(item => item.signedUrl);
      // console.log("Signed urls", signedUrlsData);

      const uploadPromises = [];
      if (signedUrls.length === videoFiles.length) {
        videoFiles.forEach((videoFile, i) => {
          uploadPromises.push(this.uploadToSpaces(videoFile, signedUrls[i]));
        });

        const uploadResponse = await Promise.all(uploadPromises);
        console.log("Upload response", uploadResponse);
        return signedUrlsData.map((item, i) => ({
          url: spacesUrl + "/" + item.fileName,
          thumbnailUrl: "",
          name: videoFiles[i].name,
          mimeType: item.mimeType
        }));
      }
      return [];
    },
    async uploadDocuments() {
      if (this.documentFiles.length === 0) return [];

      const documentFilePromises = [];
      const documentFiles = this.documentFiles.filter(item => item.name);

      documentFiles.forEach(documentFile => {
        documentFilePromises.push(
          this.getSignedUrl(documentFile, `${this.userData.uId}/documents/`)
        );
      });
      const signedUrlsData = await Promise.all(documentFilePromises);
      const signedUrls = signedUrlsData.map(item => item.signedUrl);

      const uploadPromises = [];
      if (signedUrls.length === documentFiles.length) {
        documentFiles.forEach((documentFile, i) => {
          uploadPromises.push(this.uploadToSpaces(documentFile, signedUrls[i]));
        });

        const uploadResponse = await Promise.all(uploadPromises);
        console.log("Upload response", uploadResponse);
        return signedUrlsData.map((item, i) => ({
          url: spacesUrl + "/" + item.fileName,
          thumbnailUrl: "",
          name: documentFiles[i].name,
          mimeType: item.mimeType
        }));
      }
      return [];
    },
    async getSignedUrl(file, path = "") {
      const fileName = path + file.name;
      const body = JSON.stringify({
        fileName,
        fileType: file.type
      });
      const signedUrl = await this.studyMaterialRepositoryInstance.getSignedUrl(
        body
      );
      return signedUrl;
    },
    futch(url, opts = {}, onProgress) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method || "get", url);
        for (var k in opts.headers || {}) {
          xhr.setRequestHeader(k, opts.headers[k]);
        }
        xhr.onload = e => resolve(e.target.responseText);
        xhr.onerror = reject;
        if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
        xhr.send(opts.body);
      });
    },
    async uploadToSpaces(file, signedUrl) {
      const res = await this.futch(
        signedUrl,
        {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
            "x-amz-acl": "public-read"
          }
        },
        event => {
          // console.log("Progress", `${(event.loaded / event.total) * 100}%`);
          const progress = parseInt((event.loaded / event.total) * 100);
          setTimeout(() => {
            // console.log(event);
            this.currentUploadProgress = progress;
            if (progress > 99) {
              this.currentlyUploadingNumber++;
            }
          }, 200);
        }
      );

      this.currentUploadProgress = 0;

      return res;
    },
    async fetchPosts(_params = {}) {
      // console.log('_params', _params)
      try {
        this.isLoadingMaterial = true
        const params = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.userData.uId,
          subjectId: this.selectedSubjectId,
          ..._params
        };
        // console.log('this.allTopics##', this.allTopics);
        const response = await this.studyMaterialRepositoryInstance.getStudyMaterialOfFaculty(
          params
        );
        console.log('response', response);
        // this.allStudyMaterialForFaculty
        // console.log('response1', response);
        if (response.data.length > 0 && this.allTopics.length > 0) {
          response.data.map((stu) => {
            if (!stu.groupId) {
              this.allTopics.map((topic) => {
                if (stu.topicId === topic.topicId) {
                  stu['division'] = topic.division
                }
              })
            }
          })
        }
        //  console.log('response2', response);

        if (this.prop_type === "Division") {
          let onlyDivisionMaterial = []
          if (response.data.length > 0) {
            onlyDivisionMaterial = response.data.filter((data) => data.division === this.prop_subject.division && (data.batch === undefined || data.batch === ''))
            response.data = onlyDivisionMaterial
          }
        }
        if (this.prop_type === "Batch") {
          let onlyDivisionMaterial = []
          if (response.data.length > 0) {
            onlyDivisionMaterial = response.data.filter((data) => data.division === this.prop_subject.division && data.batch === this.prop_subject.batch)
            response.data = onlyDivisionMaterial
          }
        }
        if (this.prop_type === "Group") {
          // console.log('this.prop_type', this.prop_type);
          // console.log('response.data', response.data);
          const arr = []
          // console.log('prop_groupInfo', this.prop_groupInfo);
          for (let i = response.data.length - 1; i >= 0; i--) {
            // console.log('response.data[i].assignedGroupForSubject', response.data[i].assignedGroupForSubject);
            // console.log('response.data[i]', response.data[i]);
            // console.log('response.data[i].groupId', response.data[i].groupId, this.prop_groupInfo.groupId);
            if (response.data[i].assignedGroupForSubject && response.data[i].assignedGroupForSubject.groupId && response.data[i].assignedGroupForSubject.groupId === this.prop_groupInfo.groupId) {
              arr.push(response.data[i])
            }
          }
          // console.log('response.data', response.data);
          // console.log('arr', arr);
          response.data = arr
        }
        this.posts = response.data;
        // console.log('this.posts', this.posts);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingMaterial = false
      }
    },
    async fetchData() {
      this.fetchSubjects();
      this.fetchPosts();
    },
    async getFullNamesOfUsers(firstIndex, lastIndex) {
      // console.log(firstIndex, lastIndex);
      try {
        const objToPush = {
          uIds: []
        };

        for (let i = firstIndex; i < lastIndex; i++) {
          objToPush.uIds.push(this.posts[i].postedById);
        }

        const fullNames = await this.userRepositoryInstance.getFullNameOfUsers(
          objToPush
        );

        for (let i = firstIndex; i < lastIndex; i++) {
          const user = fullNames.find(
            elem => elem.uId === this.posts[i].postedById
          );
          this.posts[i].nameOfPostee = user ? user.fullName : "";
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getFullName(index) {
      try {
        this.posts[
          index
        ].nameOfPostee = await this.userRepositoryInstance.getFullNameOfUser({
          uId: this.posts[index].postedById
        });
      } catch (err) {
        console.log(err);
        this.posts[index].nameOfPostee = "";
      }
    },
    pushFiles() {
      // console.log(this.files);
      this.files2.forEach(file => {
        this.files.push(file);
      });
      this.files2 = [];
      this.showAddingDialog = false;
    },
    removeFile(i) {
      this.files = arrayOps.removeFromArray(this.files, i);
    },
    filesChanged(e) {
      console.log(e);
    },
    getFileName(url) {
      const decodedUrl = decodeURIComponent(url);
      const lastIndexOfDelimiter = decodedUrl.lastIndexOf("________");
      const indexOfQueryParams = decodedUrl.lastIndexOf("?alt");

      return decodedUrl.substring(
        lastIndexOfDelimiter + "________".length,
        indexOfQueryParams
      );
    },
    async getMasterDataTree() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        };
        // console.log(objToPush);
        const semestersList = await this.semesterRepositoryInstance.getDivisionsOfASemester(
          objToPush
        );
        this.constructAcademicDataTree(semestersList);
      } catch (err) {
        console.log(err);
      }
    },
    async constructAcademicDataTree(semestersList) {
      const academicDataTree = {};

      semestersList.forEach(sem => {
        if (!academicDataTree[sem.department]) {
          academicDataTree[sem.department] = {};
        }
        if (!academicDataTree[sem.department][sem.courseYear]) {
          academicDataTree[sem.department][sem.courseYear] = [];
        }
        academicDataTree[sem.department][sem.courseYear].push(sem.division);
        this.departments = ["All Departments"];
        this.departments = this.departments.concat(
          Object.keys(academicDataTree)
        );
        this.academicDataTree = academicDataTree;
      });
    },
    selectDepartment() {
      this.courseYears = [];
      this.selectedCourseYear = "";
      this.divisions = [];
      this.selectedDivision = "";
      this.courseYears = ["All Course Years"];
      if (this.academicDataTree[this.selectedDepartment]) {
        this.courseYears = this.courseYears.concat(
          Object.keys(this.academicDataTree[this.selectedDepartment])
        );
      }
    },
    selectCourseYear() {
      this.divisions = [];
      this.selectedDivision = "";
      this.divisions = ["All Divisions"];
      if (
        this.academicDataTree[this.selectedDepartment][this.selectedCourseYear]
      ) {
        this.divisions = this.divisions.concat(
          this.academicDataTree[this.selectedDepartment][
          this.selectedCourseYear
          ]
        );
      }
    },
    dismissStudentCriteria() {
      this.resetStudentCriteria();
      this.showToStudents = false;
    },
    addStudentCriteria() {
      if (
        this.selectedDepartment === "All Departments" ||
        this.selectedCourseYear === "All Course Years" ||
        this.selectedDivision === "All Divisions"
      ) {
        this.addAllStudentCriteria();
      } else {
        const criteriaToInsert = {
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivision
        };
        if (
          this.selectedStudentCriteria.filter(
            elem =>
              elem.department === criteriaToInsert.department &&
              elem.courseYear === criteriaToInsert.courseYear &&
              elem.division === criteriaToInsert.division
          ).length === 0
        ) {
          this.selectedStudentCriteria.push(criteriaToInsert);
        }
      }
      this.resetStudentCriteria();
      this.showToStudents = false;
    },
    addAllStudentCriteria() {
      if (this.selectedDepartment === "All Departments") {
        this.selectedStudentCriteria = [];
        Object.keys(this.academicDataTree).forEach(department => {
          Object.keys(this.academicDataTree[department]).forEach(courseYear => {
            this.academicDataTree[department][courseYear].forEach(division => {
              this.selectedStudentCriteria.push({
                department: department,
                courseYear: courseYear,
                division: division
              });
            });
          });
        });
      } else if (this.selectedCourseYear === "All Course Years") {
        this.selectedStudentCriteria = this.selectedStudentCriteria.filter(
          elem => elem.department !== this.selectedDepartment
        );
        Object.keys(this.academicDataTree[this.selectedDepartment]).forEach(
          courseYear => {
            this.academicDataTree[this.selectedDepartment][courseYear].forEach(
              division => {
                this.selectedStudentCriteria.push({
                  department: this.selectedDepartment,
                  courseYear: courseYear,
                  division: division
                });
              }
            );
          }
        );
      } else if (this.selectedDivision === "All Divisions") {
        this.selectedStudentCriteria = this.selectedStudentCriteria.filter(
          elem =>
            elem.department !== this.selectedDepartment ||
            elem.courseYear !== this.selectedCourseYear
        );
        this.academicDataTree[this.selectedDepartment][
          this.selectedCourseYear
        ].forEach(division => {
          this.selectedStudentCriteria.push({
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            division: division
          });
        });
      }
    },
    // uploadFiles() {
    //   this.loading = true;
    //   this.downloadURLs = [];
    //   this.uploadCount = 0;
    //   console.log(this.files);
    //   if (this.files.length === 0) {
    //     this.uploadPost();
    //   } else {
    //     this.upload(this.uploadCount);
    //     // this.files.forEach(file => {
    //     //   this.upload(file)
    //     // })
    //   }
    // },
    upload(fileNumber) {
      // console.log("uploading");
      this.fileName = this.files[fileNumber].name;
      this.uploading = true;
      // console.log(this.fileName);
      this.fileCurrentlyUploading = this.files[fileNumber];
      this.uploadTask = firestorage
        .ref(
          "posts/" +
          this.userData.uId +
          "/" +
          new Date().valueOf().toString() +
          "________" +
          this.files[fileNumber].name
        )
        .put(this.files[fileNumber]);
    },
    async uploadPost() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        title: this.tempTitle,
        description: this.tempDescription,
        isImportant: this.isImportant,
        postedById: this.userData.uId,
        dateOfPost: convertToISTTime(new Date()).toISOString(),
        images: this.downloadURLs
          .filter(url => !url.isMedia)
          .map(url => url.downloadURL),
        otherMedia: this.downloadURLs
          .filter(url => url.isMedia)
          .map(url => url.downloadURL),
        criteria: {
          forFaculties: this.selectedFacultyCriteria.length > 0,
          forStudents: this.selectedStudentCriteria.length > 0,
          facultyCriteria: this.selectedFacultyCriteria,
          studentCriteria: this.selectedStudentCriteria
        }
      };
      // console.log(objToPush);
      try {
        await this.postsRepositoryInstance.createPost(objToPush);
        this.resetAddPost();
        this.fetchData();
      } catch (err) {
        console.log(err);
      }
      // console.log(objToPush);
    },
    handleGoBack() {
      history.back()
    },
    getFileType(fileUrl) {
      const intermediatePath = fileUrl.split("?alt=media")[0];
      return intermediatePath.substring(
        intermediatePath.lastIndexOf("."),
        intermediatePath.length
      );
    },
    resetStudentCriteria() {
      this.selectedDepartment = "";
      this.selectedCourseYear = "";
      this.selectedDivision = "";
    },
    dismissFacultyCriteria() {
      this.selectedDepartment2 = "";
      this.showToFaculties = false;
    },
    addFacultyCriteria() {
      if (this.selectedDepartment2 === "All Departments") {
        this.selectedFacultyCriteria = [];
        Object.keys(this.academicDataTree).forEach(department => {
          this.selectedFacultyCriteria.push({
            department: department
          });
        });
      } else if (
        !this.selectedFacultyCriteria.includes(this.selectedDepartment2)
      ) {
        this.selectedFacultyCriteria.push({
          department: this.selectedDepartment2
        });
      }
      this.selectedDepartment2 = "";
      this.showToFaculties = false;
    },
    removeStudentCriteria(index) {
      this.selectedStudentCriteria = arrayOps.removeFromArray(
        this.selectedStudentCriteria,
        index
      );
    },
    removeFacultyCriteria(index) {
      this.selectedFacultyCriteria = arrayOps.removeFromArray(
        this.selectedFacultyCriteria,
        index
      );
    },
    resetAddPost() {
      this.tempTitle = "";
      this.tempDescription = "";
      this.showToStudents = false;
      this.showToFaculties = false;
      this.selectedDepartment = "";
      this.selectedDepartment2 = "";
      this.departments = [];
      this.selectedCourseYear = "";
      this.courseYears = [];
      this.selectedDivision = "";
      this.divisions = [];
      this.isImportant = false;
      this.selectedFacultyCriteria = [];
      this.selectedStudentCriteria = [];
      this.publishAt = ""
    },
    async deletePost(post) {
      this.deletePostConfirm = false
      this.showModal = true
      this.postToDelete = post
      console.log("postToDel", this.postToDelete);
      console.log("post", post);
    }
  }
};
</script>

<style src="./activityStudyMaterial.css"></style>
