import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Exam'

class Exam {
  constructor (context) {
    this.context = context
  }

  async createExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createExam, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getExamById (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamById, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.exam
  }

  async getAllExams (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getAllExams, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.exams
  }

  async getEkAllExams (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getEkAllExams, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async SyncDataToEklavya (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.SyncDataToEklavya, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getExamsOfACriteria (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamsOfACriteria, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.exams
  }

  async getExamsOfAnInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamsOfAnInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.exams
  }

  async update (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.update, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async publishExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.publishExam, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async deleteExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.deleteExam, data)
    this.context.$store.commit('liveData/set_loading', false)
  }
}

export default Exam
