import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"2"}},[_c('div',{staticStyle:{"margin-bottom":"5px","margin-left":"10px","font-size":"14px","font-weight":"400","color":"#3a3a3a","margin-top":"6px"}},[_vm._v(" Progress Indicator: ")])]),_c(VCol,{staticStyle:{"margin-left":"-20px","margin-right":"4px","margin-top":"14p"},attrs:{"cols":"10"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c(VProgressLinear,{attrs:{"value":_vm.progressIndicator1,"height":"10","color":"#00128C","rounded":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',{staticStyle:{"margin-bottom":"0px","color":"white","font-size":"smaller"}},[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}])})],1)])],1),[_c('div',{staticClass:"center-container",staticStyle:{"margin-top":"30px"}},[_c(VTabs,{attrs:{"centered":"","hide-slider":""},model:{value:(_vm.tabs1),callback:function ($$v) {_vm.tabs1=$$v},expression:"tabs1"}},_vm._l((_vm.secondaryTabItems),function(item,i){return _c(VTab,{key:item,staticClass:"navTab1",staticStyle:{"border-radius":"5px"},attrs:{"ripple":false,"active-class":"navTabActive1"},on:{"change":function($event){return _vm.checkTabItem(item, i)}}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)],_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{staticClass:"custom-search-box",attrs:{"label":"Search","hide-details":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{staticClass:"custom-table",attrs:{"headers":_vm.headers,"items":_vm.selectedSubjects,"search":_vm.search},scopedSlots:_vm._u([{key:"item.srNo",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index+1)+" ")]}},{key:"item.subjectCode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subjectCode)+" ")]}},{key:"item.subjectName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subjectName)+" ")]}},{key:"item.totalMarks",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalMarks)+" ")]}},{key:"item.threshold",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.threshold !== '-' ? item.threshold + '%': '')+" ")]}},{key:"item.quePaper",fn:function(ref){
var item = ref.item;
return [(item.queationsCount === 0)?_c(VBtn,{staticClass:"underline-btn",attrs:{"text":"","disabled":item.totalMarks === '-'},on:{"click":function($event){return _vm.goToAddQuestions(item)}}},[_vm._v(" Create ")]):_c(VBtn,{staticClass:"underline-btn",attrs:{"text":"","disabled":item.totalMarks === '-'},on:{"click":function($event){return _vm.goToAddQuestions(item)}}},[_vm._v(" "+_vm._s(item.queationsCount)+"Qs. added ")])]}},{key:"item.cos",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"paddingWithBorder",staticStyle:{"color":"#ff4f14","margin-left":"5px"},attrs:{"size":"16"},on:{"click":function($event){return _vm.handleSubjectCos(item)}}},[_vm._v(_vm._s(item.mappedCOsCount ? item.mappedCOsCount : 'mdi-plus'))])]}},{key:"item.blooms",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"paddingWithBorder",staticStyle:{"color":"#ff4f14","margin-left":"5px"},attrs:{"size":"16"},on:{"click":function($event){return _vm.handleBlooms(item)}}},[_vm._v(_vm._s(item.mappedBloomsCount ? item.mappedBloomsCount : 'mdi-plus'))])]}},{key:"item.modelAnswers",fn:function(ref){
var item = ref.item;
return [_vm._v(" - ")]}}])}),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c(VBtn,{staticStyle:{"margin-left":"15px","width":"133px","height":"40px"},attrs:{"medium":"","outlined":"","color":"#ff4f14"},on:{"click":function($event){return _vm.saveSubjectDetails()}}},[_vm._v(" Save ")]),_c(VDialog,{attrs:{"width":"55%"},model:{value:(_vm.coDialog),callback:function ($$v) {_vm.coDialog=$$v},expression:"coDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Select to add CO ")]),_c(VCardText,[(_vm.totalCos.length === 0)?_c('div',[_c('div',{attrs:{"id":"noDataWarning"}},[_vm._v("No COs are added yet for this subject.")])]):_c('div',[_c(VList,_vm._l((_vm.totalCos),function(obj,i){return _c(VCheckbox,{key:obj.coNumber,attrs:{"color":"#050D92","value":obj,"label":'CO' + obj.coNumber + ' - ' + obj.coName},model:{value:(_vm.mappedCOs),callback:function ($$v) {_vm.mappedCOs=$$v},expression:"mappedCOs"}})}),1)],1)]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticStyle:{"color":"#ffffff","text-transform":"capitalize"},attrs:{"color":"#FF4F1F","filled":""},on:{"click":function($event){return _vm.addSubjectCos()}}},[_vm._v(" Add COs ")]),_c(VSpacer)],1)],1)],1),_c(VDialog,{attrs:{"width":"55%"},model:{value:(_vm.bloomsDialog),callback:function ($$v) {_vm.bloomsDialog=$$v},expression:"bloomsDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Select to Map Blooms ")]),_c(VCardText,[(_vm.totalBlooms.length === 0)?_c('div',[_c('div',{attrs:{"id":"noDataWarning"}},[_vm._v("No blooms are added yet.")])]):_c('div',[_c(VList,[_c(VListItemGroup,{attrs:{"active-class":"activeClass","multiple":""},model:{value:(_vm.mappedBlooms),callback:function ($$v) {_vm.mappedBlooms=$$v},expression:"mappedBlooms"}},[_vm._l((_vm.totalBlooms),function(item,index){return [_c(VListItem,{key:index,attrs:{"id":"listItem","dense":"","ripple":false,"value":item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemContent,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"1"}},[_c(VCheckbox,{attrs:{"input-value":active,"color":"white"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c('div',{attrs:{"id":active ? 'bloomTitleActive' : 'bloomTitleInactive'}},[_vm._v(" "+_vm._s(item.name))])]),_c(VCol,{attrs:{"cols":"8"}},[_c('div',{attrs:{"id":active ? 'bloomDescActive' : 'bloomsDescInactive'}},[_vm._v(" "+_vm._s(item.description))])])],1)],1)]}}],null,true)}),(index < _vm.totalBlooms.length - 1)?_c(VDivider):_vm._e()]})],2)],1)],1)]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticStyle:{"color":"#ffffff","text-transform":"capitalize"},attrs:{"color":"#FF4F1F","filled":""},on:{"click":function($event){return _vm.addSubjectBlooms()}}},[_vm._v(" Add Blooms ")]),_c(VSpacer)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }