<template src="./activityHome.html"></template>
<script>
import auth from '../../Services/Firebase/auth'
import state from '../../Store/Modules/liveData'
import state2 from '../../Store/Modules/instituteData'
import NotificationRepository from '../../Repository/Notification'
import UserRepository from '../../Repository/User'
import NetworkManager from '../../NetworkManager'
import InstituteAPIs from '../../ApiNames/Institute'
import PortalFooter from '../../Components/PortalFooter'
// import sanityClient from "../../utils/SanityClient"
import showStatus from '../../NetworkManager/showStatus'
import VuePortableText from '@bizurd/vue-portable-text'
export default {
  name: 'activityHome',
  components: {
    PortalFooter,
    VuePortableText
  },
  data() {
    return {
      drawer: false,
      isFielInstitute: false,
      isMobileView: false,
      show: false,
      selectedActivity: 1,
      userData: null,
      selectedInstitute: '',
      selectedSemester: {},
      user: null,
      userName: '',
      logoutDialog: false,
      state: state,
      state2: state2,
      notifications: [],
      isClassTeacher: false,
      brandingLogo: '',
      brandingName: '',
      currentRoute: "",
      imagesArray: [],
      videosArray: [],
      pdfsArray: [],
      studiumSaysArr: [],
      expertGuidelinesArr: [],
      fbDialog: false,
      fbDialogRichText: false,
      fbSelectedContent: {},
      fbSelectedContentType: '',
      selectedInstituteName: '',
      fbContentBtnVisible: false,
      loadingSanityData: false,
      floatingButtonVisible: false,
      noSanityContent: false
    }
  },
  created() {
    this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    this.notificationRepositoryInstance = new NotificationRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.user = this.$store.getters['user/get_user']
    this.userName = this.$store.getters['user/get_userName']
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    this.selectedInstituteName = this.$store.getters[
      'instituteData/get_instituteName'
    ]
    if (this.selectedInstitute === 'fc4eb82f-4ea3-4389-8db5-6dad8e0114c3') {
      this.isFielInstitute = true
    }
    this.selectedSemester = this.$store.getters[
      'instituteData/get_selectedSemester'
    ]
    this.checkIfFacultyIsClassTeacher()

    this.fetchBrandingDetails(this.selectedInstitute)
    this.userInformation()
  },
  watch: {
    $route(to, from) {
      this.fbContentBtnVisible = false;
    }
  },
  methods: {
    async fetchSanityData() {
      this.currentRoute = this.$route.path;
      this.loadingSanityData = true;
      if (this.fbContentBtnVisible === true) {
        this.fbContentBtnVisible = false;
        this.loadingSanityData = false
      } else {
        try {
          const query = `*[_type == 'lmsFaculty' && routeTitle == '${this.currentRoute}']{
            'images': Images[]{'url':file.asset->url,'title':title},
            'videos': Videos[]{'url':file.asset->url,'title':title},
            'pdfs': Pdfs[]{'url':file.asset->url,'title':title},
            'studiumSays':studiumSays[],
            'expertGuidelines':expertGuidelines[],
          }`;
          // const data = await sanityClient.fetch(query)
          // console.log('sanityClient', sanityClient);
          console.log('query', query);
          const data = []
          if (data.length === 0) {
            this.loadingSanityData = false;
            this.noSanityContent = true;
            showStatus("No Information Available", 9000, "error", this);
          } else {
            const { images, pdfs, videos, studiumSays, expertGuidelines } = data[0];
            this.imagesArray = [];
            this.imagesArray = images;
            this.pdfsArray = pdfs;
            this.videosArray = videos;
            this.studiumSaysArr = studiumSays;
            this.expertGuidelinesArr = expertGuidelines;
            this.fbContentBtnVisible = !this.fbContentBtnVisible;
            this.loadingSanityData = false
          }
        } catch (error) {
          console.log("error fetching sanity", error)
        }
      }
    },
    openFbDialog(content, type) {
      this.fbSelectedContent = content;
      this.fbSelectedContentType = type;
      this.fbDialog = true
    },
    closeFbDialog() {
      this.fbSelectedContent = {}
      this.fbDialog = false
    },
    async fetchBrandingDetails(instituteId) {
      try {
        const data = await NetworkManager.getRequest(
          InstituteAPIs.getInstituteBranding,
          { instituteId: instituteId }
        )
        this.brandingName = data.instituteBrandingName
        this.brandingLogo = data.instituteLogoLink
      } catch (error) {
        console.log({ error })
      }
    },
    async fetchData() {
      // Call API to get todays notifications
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId
      }
      try {
        this.notifications = await this.notificationRepositoryInstance.getTodaysNotifications(
          objToPush
        )
      } catch (err) {
        this.notifications = []
        console.log(err)
      }
    },
    async checkIfFacultyIsClassTeacher() {
      const res = await this.userRepositoryInstance.getDivisionInformationOfAFacultyAsClassTeacher(
        {
          instituteId: this.userData.instituteId,
          semId: this.userData.semId,
          uId: this.userData.uId
        }
      )
      if (res) {
        this.isClassTeacher = true
      }
    },
    makeLowerCase(semName) {
      return semName.toLowerCase();
    },
    goBack() {
      this.$router.go(-1)
    },
    gotoMyProfile() {
      this.$store.commit('liveData/set_selectedActivity', 1)
      this.$router.push('/activityProfile')
    },
    gotoChangeInstitute() {
      this.$router.push('/activitySelectInstituteNSemester')
    },
    gotoMySubjects() {
      this.$store.commit('liveData/set_selectedActivity', 2)
      this.$router.push('/activityMySubjects')
    },
    gotoMyTimetable() {
      this.$store.commit('liveData/set_selectedActivity', 3)
      this.$router.push('/activityMyTimetable')
    },
    gotoRescheduleRequests() {
      this.$store.commit('liveData/set_selectedActivity', 4)
      this.$router.push('/activityRescheduleRequests')
    },
    gotoTimeline() {
      this.$store.commit('liveData/set_selectedActivity', 5)
      this.$router.push('/activityTimeline')
    },
    gotoNotifications() {
      this.show = false
      this.$store.dispatch('liveData/set_selectedActivity', 6)
      this.$router.push('/activityNotifications')
    },
    gotoAttendanceAnalysis() {
      if (this.isClassTeacher) {
        this.$store.dispatch('liveData/set_selectedActivity', 8)
        this.$store.commit('liveData/set_selectedSubject', 'classteacher')
        this.$router.push({
          name: 'activityCumulativeAttendance',
          params: {
            prop_subject: 'classteacher'
          }
        })
      } else {
        alert('This activity is only for classteachers.')
      }
    },
    gotoChangeSemester() {
      this.$store.dispatch('liveData/set_selectedActivity', 7)
      this.$router.push('/activityChangeSemester')
    },
    gotoFeedbackForm() {
      this.$store.dispatch('liveData/set_selectedActivity', 9)
      this.$router.push('/activityFeedbackForm')
    },
    gotoFeedbackAnalysis() {
      this.$store.dispatch('liveData/set_selectedActivity', 10)
      this.$router.push('/activityFeedbackAnalysis')
    },
    gotoNBA() {
      this.$store.dispatch('liveData/set_selectedActivity', 8)
      this.$router.push('/activityNBA')
    },
    gotoStudyMaterial() {
      this.$store.commit('liveData/set_selectedActivity', 10)
      this.$router.push('/activityStudyMaterial')
    },
    gotoStudentProfile() {
      this.$store.commit('liveData/set_selectedActivity')
      this.$router.push('/activityStudentProfile')
    },
    gotoMyExam() {
      this.$store.commit('liveData/set_selectedActivity', 14)
      this.$router.push('/activityNewAssignments')
      // this.$router.push('/activityMyExam')
    },
    gotoMyAssessments() {
      this.$store.commit('liveData/set_selectedActivity', 12)
      this.$router.push('/activityMyAssessment')
    },
    logout() {
      this.logoutDialog = false
      this.$store.commit('user/reset')
      this.$store.commit('instituteData/reset')
      this.$store.commit('liveData/reset')
      this.$store.commit('mappings/reset')
      const user = window.localStorage.getItem("userKey")
      if (user) {
        window.localStorage.removeItem('userKey')
        this.$router.push('/activityWelcome')
      } else {
        auth.logout(this)
      }
    },
    async userInformation() {
      try {
        const objToPush = { uId: this.userData.uId };
        const [userInfo, emailsAndPhones] = await Promise.all([
          this.userRepositoryInstance.getUserProfile(objToPush),
          this.userRepositoryInstance.getUserAuthentication(objToPush)
        ]);

        const { userName = '-', firstName = '-', middleName = '-', lastName = '-' } = userInfo;
        const { emails, phones } = emailsAndPhones;
        const email = emails && emails.length > 0 ? emails[0] : '-';
        const phone = phones && phones.length > 0 ? phones[0] : '-';

        this.userInfo = userInfo;
        this.userName = userName;
        this.fullName = firstName + ' ' + middleName + ' ' + lastName;

        this.email = email;
        this.phone = phone;

        this.userDetails = {
          userName: this.userName,
          fullName: this.fullName,
          email: this.email,
          phone: this.phone,
          brandingName: this.brandingName
        };
        localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>
<style scoped src="./activityHome.css"></style>
