<template src='./activityCreateExam.html'></template>
<script>
import ExamRepository from "../../../Repository/Exam";
import ExamStudentRepository from "../../../Repository/ExamStudent";
import SemesterRepository from "../../../Repository/Semester";
// import {
//   convertFromYYYYMMDDToDate,
//   convertToISTTime,
// } from "../../../Services/Utils/DateTimeFormatter";
import showStatus from "../../../NetworkManager/showStatus";
import inputContainer from "../../../Components/inputContainer";
import VueApexCharts from "vue-apexcharts";
import SubjectRepository from "../../../Repository/Subjects";
import apiV3 from "../../../NetworkManager/apiV3";
// import nba from "../../../NetworkManager/nba";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import UserRepository from "../../../Repository/User";
import InfrastructureReferenceRepository from "../../../Repository/InfrastructureReference";
import axios from "axios";
import NbaRepository from "../../../Repository/NBA";
import auth from "../../../Services/Firebase/auth";

export default {
  name: "activityCreateExam",
  props: ["prop_courseYear", "prop_division", "prop_department"],
  components: {
    inputContainer,
    apexcharts: VueApexCharts
  },

  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      selectedDate: "",
      selectedTime: "",
      dateModal: false,
      timeModal: false,
      e1: 1,
      limits: [0, 100],
      userData: null,
      examName: "",
      examDescription: "",
      examType: "",
      criteria: [],
      startDate: "",
      endDate: "",
      isOnline: "",
      examTypes: ["CIE(Internal)", "SEE(External)"],
      modal1: false,
      modal2: false,
      loading: true,
      emptyName: false,
      emptyDescription: false,
      noCriteria: false,
      noStartDate: false,
      invalidStartDate: false,
      noEndDate: false,
      invalidEndDate: false,
      checkbox: true,
      dialog: false,
      selectedDepartment: "",
      selectedCourseYear: "",
      selectedDivision: "",
      departments: [],
      courseYears: [],
      divisions: [],
      selectedInstitute: "",
      selectedSemester: {},
      subjects: [],
      subjectNames: [],
      subjectAllocation: [],
      allSubjectSelection: [],
      allSubject: [],
      subjectCheck: false,
      subjectObject: [],
      allUsers: [],
      facultyList: [],
      facultyArray: [],
      studentList: [],
      studentArray: [],
      studentNames: [],
      facultyNames: [],
      addNewRoomDialog: false,
      studentDialog: false,
      search: "",
      applicableStudents: [],
      duration: "",
      totalMarks: "",
      passingMarks: "",
      tempSelectedRoom: {},
      editingRoom: false,
      roomAllocation: [],
      selectedStudent: [],
      selectedStudentArray: [],
      selectedFaculty: [],
      selectedFacultyArray: [],
      selectedApplicablestudents: [],
      openPanel: undefined,
      editSubjectDetails: false,
      editQuestionDetails: false,
      roomData: [],
      roomNames: [],
      editStatus: false,
      deleteDialog: false,
      uploadMarksDialog: false,
      uploadMarksManuallyStatus: false,
      perMarks: "",
      value: [0, 40],
      isEditable: false,
      coDialog: false,
      questionsArray: [],
      questionSet: [],
      questionDetails: [],
      subjectDetails: [],
      totalQuestions: 0,
      COFromNba: [],
      selectedCOS: [],
      applicableHeader: [
        {
          text: "Name",
          value: "fullName"
        }
      ],
      studentHeaders: [
        {
          text: "Roll No",
          align: "start",
          sortable: false,
          value: "rollNo"
        },
        { text: "Student Name", value: "fullName" },
        { text: "PRN", value: "studentPrn" }
      ],
      facultyHeaders: [
        {
          text: "Faculty Name",
          align: "start",
          sortable: false,
          value: "fullName"
        },
        { text: "email", value: "email" }
      ],
      marksHeader: [],
      studentData: [],

      selected: [],
      series: [44, 55, 41, 17, 15],

      headerSubject: [
        {
          text: "RN",
          value: "rollNo"
        },
        { text: "Name", value: "Name" },
        { text: "Overall", value: "overall" }
      ],
      subjectData: [],
      studentMarks: "",
      queTitle: "",
      selectedStud: {},
      questionWiseMarks: [],
      indivitualMarks: "",
      questionsSet: [],
      nbaAttainmentObject: {},
      updatedSubjectThresholdArray: [],
      subjectThresholdArray: [],
      subjectThreshold: [],
      nbaCriteria: false,
      show: true,
      isBasicDetailEditable: false,
      selectedExamType: "",
      thresholdType: null,
      overallThreshold: 0,
      overallThresholdPercentage: 0,
      uploadQuestionManuallyStatus: false,
      indivitualThresholdAvg: 0,
      overallPercentageStatus: false,
      indivitualThresholdStatus: false,
      allFaculty: [],
      allStudents: [],
      allowedRoom: "",
      allRoom: [],
      roomName: "",
      allStudentList: {},
      courseOutcomeData: [],
      roomStatus: false,
      handleCourseState: false,
      selectedDateArray: [],
      tempSelectedSubject: "",
      tempTotalMarks: "",
      bloomDialog: false,
      allBlooms: [
        {
          name: "CREATE (C)",
          shortCode: "C",
          description:
            "Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)"
        },
        {
          name: "EVALUATE (E)",
          shortCode: "E",
          description:
            "Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)"
        },
        {
          name: "ANALYZE (A)",
          shortCode: "A",
          description:
            "Draw connections among ideas (Verbs : differentiate, organise, relate, compare, contrast, distinguish, examine, experiment, question, test)"
        },
        {
          name: "APPLY (Ap)",
          shortCode: "Ap",
          description:
            "Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)"
        },
        {
          name: "UNDERSTAND (U)",
          shortCode: "U",
          description:
            "Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)"
        },
        {
          name: "REMEMBER (R)",
          shortCode: "R",
          description:
            "Recall facts and basic concepts (Verbs : define, duplicate, list, memorise, repeat, state)"
        }
      ],
      roomAllocationObject: {},
      publishExam: false,
      saveSubjectDetails: false,
      toggleQuestionUpload: null,
      assignedCoArray: [],
      toggleMarksUpload: null,
      uploadOverallMarksDialog: false,
      obtainedMarks: 0,
      overallMarksTableHeader: [
        {
          text: "",
          value: "action",
          align: "center"
        },
        {
          text: "RN",
          value: "rollNo"
        },
        {
          text: "Student Name",
          value: "studentName"
        },
        {
          text: "PRN",
          value: "studentPrn"
        },
        {
          text: "Total",
          value: "total"
        },
        {
          text: "Obtained Marks",
          value: "obtainedMarks"
        }
      ],
      noQuestionOverallThresholdPercentage: 0,
      toggleCancelQuestionDialog: false,
      marksUploadSelected: false,
      subjectDetailsLoaded: false,
      questionDetailsLoaded: false,
      uploadQuestionExcelDialog: false,
      questionExcelFile: "",
      questionsLoadedViaExcel: false,
      uploadMarksExcelDialog: false,
      marksExcelFile: "",
      marksLoadedViaExcel: false,
      marksSampleExcelLink: "",
      maxMarks: 0,
      maxQuestionMarks: 0,
      inputRules: {
        numberOnly: (value) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || "Please Enter Only Numbers.";
        },
        numberCounter: (value) => value.length <= 2 || "Max 2 numbers",
        valueLimit: (value) =>
          Number(value) <= this.maxMarks ||
          `Marks cannot be more than ${this.maxMarks}`,
        maxQuestionMarks: (value) =>
          Number(value) <= this.maxQuestionMarks ||
          `Marks cannot be more than ${this.maxQuestionMarks}`,
        passingMarksLimit: (value) =>
          Number(value) <= this.totalMarks ||
          `Marks cannot be more than ${this.totalMarks}`
      },
      saveAsDraftLoader: false,
      publishExamLoader: false,
      assignedBloomsArray: [],
      studResponse: [],
      excelGenerated: false,
      instituteType: "",
      nbaData: {},
      instituteCieSee: "",
      attainmentCalculationType: "",
      excelCustomError: false,
      excelCustomErrorMessage: "",
      studentExceedingMarksLimit: [],
      subjectsMapping: [],
      tempGlobalThreshold: 0,
      currentDivisions: []
    }
  },
  async created() {
    this.editRoomIndex = -1;
    this.userData = this.$store.getters["user/get_userData"];
    console.log('userData', this.userData);
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.instituteType = this.$store.getters["liveData/get_instituteType"];
    this.attainmentCalculationType =
      this.$store.getters["liveData/get_attainmentCalculationType"];
    this.instituteCieSee = this.$store.getters["liveData/get_instituteCieSee"];
    this.subjectsMapping = this.$store.getters['mappings/get_subjectsMapping']
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.examRepositoryInstance = new ExamRepository(this);
    this.examStudentRepositoryInstance = new ExamStudentRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.infrastructureReferenceRepositoryInstance =
      new InfrastructureReferenceRepository(this);

    const temp = await auth.getTempThresholdFromFirebase(
      this.selectedInstitute
    );
    if (temp) {
      this.tempGlobalThreshold = temp
      this.thresholdType = "overallThreshold"
    } else {
      this.tempGlobalThreshold = 0
    }
    this.overallThresholdPercentage = this.tempGlobalThreshold
    this.currentDivisions = [...new Set(this.userData.subjects.map((item) => item.division))]
    // "prop_courseYear", "prop_division", "prop_department"
    await this.fetchData();
    if (this.prop_department !== "") {
      this.selectedDepartment = this.prop_department
      this.selectDepartment()
    }
    if (this.prop_courseYear !== "") {
      this.selectedCourseYear = this.prop_courseYear
      this.selectCourseYear()
    }
    if (this.prop_division !== "") {
      this.selectedDivision = this.prop_division
    }
  },
  watch: {
    drawer(newValue) {
      if (!newValue) {
        this.panel = false;
      }
    }
  },
  methods: {
    async fetchData() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        };
        this.semestersList =
          await this.semesterRepositoryInstance.getDivisionsOfASemester(
            objToPush
          );
        this.constructAcademicDataTree();
      } catch (err) {
        console.log(err);
      }
    },
    async constructAcademicDataTree() {
      this.academicDataTree = {};
      this.semestersList.forEach((sem) => {
        if (this.userData.department === sem.department) {
          if (!this.academicDataTree[sem.department]) {
            this.academicDataTree[sem.department] = {};
          }
          if (!this.academicDataTree[sem.department][sem.courseYear]) {
            this.academicDataTree[sem.department][sem.courseYear] = [];
          }
          this.academicDataTree[sem.department][sem.courseYear].push(
            sem.division
          );
        }
      });
      // this.selectedDepartment = this.userData.department
      this.departments = Object.keys(this.academicDataTree);
      this.courseYears = [];
      this.divisions = [];

      this.loading = false;
    },
    async nbaDetails() {
      this.isBasicDetailEditable = false;
      if (this.nbaCriteria) {
        if (
          this.selectedExamType === "CIE(Internal)" &&
          this.thresholdType === "individualThreshold"
        ) {
          this.nbaAttainmentObject = {
            typeOfExam: "cie",
            thresholdType: "individualThreshold"
          };
        } else if (
          this.selectedExamType === "CIE(Internal)" &&
          this.thresholdType === "overallThreshold"
        ) {
          this.nbaAttainmentObject = {
            typeOfExam: "cie",
            thresholdType: "overallThreshold",
            thresholdPercentage: this.overallThresholdPercentage
          };
        } else if (
          this.selectedExamType === "SEE(External)" &&
          this.thresholdType === "individualThreshold"
        ) {
          this.nbaAttainmentObject = {
            typeOfExam: "see",
            thresholdType: "individualThreshold",
            thresholdPercentage: this.subjectAllocation
          };
        } else if (
          this.selectedExamType === "SEE(External)" &&
          this.thresholdType === "overallThreshold"
        ) {
          this.nbaAttainmentObject = {
            typeOfExam: "see",
            thresholdType: "overallThreshold",
            thresholdPercentage: this.overallThresholdPercentage
          };
        }
      }
      console.log('this.nbaAttainmentObject', this.nbaAttainmentObject);
    },
    selectDepartment() {
      this.courseYears = [];
      this.selectedCourseYear = "";
      this.divisions = [];
      this.selectedDivision = "";
      this.courseYears = Object.keys(
        this.academicDataTree[this.selectedDepartment]
      );
    },
    selectCourseYear() {
      this.divisions = this.academicDataTree[this.selectedDepartment][this.selectedCourseYear]?.filter(div => this.currentDivisions.includes(div));
      this.getSubjects();
    },
    handleSubejcts() {
      this.subjectAllocation.length === this.subjectNames.length
        ? (this.subjectCheck = true)
        : (this.subjectCheck = false);
    },
    selectAll() {
      this.subjectObject = [];
      this.subjectAllocation = [];
      this.allSubjectSelection = [];
      this.questionDetails = [];
      if (this.subjectCheck === true) {
        this.subjectNames.forEach((sub) => {
          this.allSubjectSelection.push(sub.subjectName);
          this.subjectAllocation.push(sub);
          this.subjectObject.push(sub.subjectId);
        });
      } else {
        this.allSubjectSelection = [];
        this.subjectAllocation = [];
      }
    },
    remove_array_element(array, n) {
      if (n > -1) {
        array.splice(n, 1);
      }
      return array;
    },
    clearErrors() {
      this.emptyName = false;
      this.emptyDescription = false;
      this.noCriteria = false;
      this.noEndDate = false;
      this.noStartDate = false;
      this.invalidStartDate = false;
      this.invalidEndDate = false;
    },
    async createExam() {
      this.publishExamLoader = true;
      this.subjectObject = [];
      let noErrors = true;
      if (!this.examName) {
        this.emptyName = true;
        noErrors = false;
        showStatus("Please Enter Valid Exam Name.", 9000, "error", this);
      }
      if (this.selectedDepartment === "") {
        this.noCriteria = true;
        noErrors = false;
      }
      if (this.startDate) {
        if (
          new Date(this.startDate) ===
          new Date(new Date().toLocaleString().split(",")[0])
        ) {
          this.invalidStartDate = true;
          noErrors = false;
        }
      } else {
        this.noStartDate = true;
        noErrors = false;
      }
      if (this.endDate) {
        if (this.startDate) {
          if (!(new Date(this.startDate) <= new Date(this.endDate))) {
            this.invalidEndDate = true;
            noErrors = false;
            showStatus(
              "Error creating exam. Please enter correct start date and end date.",
              9000,
              "error",
              this
            );
          }
        }
      } else {
        this.noEndDate = true;
        noErrors = false;
      }
      if (noErrors) {
        const criteria = {
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear
            ? this.selectedCourseYear
            : undefined,
          division: this.selectedDivision
        };
        this.subjectAllocation.forEach((sub) => {
          this.subjectObject.push(sub.subjectId);
        });

        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          name: this.examName,
          description: this.examDescription,
          type: this.selectedExamType,
          startDate: this.startDate,
          endDate: this.endDate,
          criteria: criteria,
          isForNBA: this.nbaCriteria,
          applicableSubjects: this.subjectObject,
          subjectDetails: this.subjectDetails,
          questionDetails: this.questionDetails,
          examStatus: "Published"
        };
        if (this.nbaCriteria) {
          objToPush.nbaAttainment = this.nbaAttainmentObject;
        }

        try {
          await apiV3.postRequest("/manageExam/create", objToPush);
          showStatus("Exam Created Successfully", 5000, "success", this);
          this.publishExamLoader = false;
          this.$router.push("./activityMyExam");
        } catch (err) {
          console.log(err);
          this.publishExamLoader = false;
          showStatus("Error creating exam", 9000, "error", this);
        }
      }
    },
    async saveAsDraft() {
      this.subjectObject = [];
      this.saveAsDraftLoader = true;
      if (this.nbaCriteria) {
        await this.nbaDetails();
      }
      await this.createArray();

      let noErrors = true;
      if (!this.examName) {
        this.emptyName = true;
        noErrors = false;
      }
      if (this.selectedDepartment === "") {
        this.noCriteria = true;
        noErrors = false;
      }
      if (this.startDate) {
        if (
          new Date(this.startDate) ===
          new Date(new Date().toLocaleString().split(",")[0])
        ) {
          this.invalidStartDate = true;
          noErrors = false;
        }
      } else {
        this.noStartDate = true;
        noErrors = false;
      }
      if (this.endDate) {
        if (this.startDate) {
          if ((new Date(this.startDate) > new Date(this.endDate))) {
            this.invalidEndDate = true;
            noErrors = false;
            showStatus(
              "Error creating exam. Please enter correct start date and end date.",
              9000,
              "error",
              this
            );
          }
        }
      } else {
        this.noEndDate = true;
        noErrors = false;
      }
      if (noErrors === false) {
        showStatus("Please Enter Valid Data", 9000, "error", this);
      }
      if (noErrors) {
        const criteria = {
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear
            ? this.selectedCourseYear
            : undefined,
          division: this.selectedDivision
        };
        this.subjectAllocation.forEach((sub) => {
          this.subjectObject.push(sub.subjectId);
        });
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          name: this.examName,
          description: this.examDescription ? this.examDescription : "",
          type: this.selectedExamType ? this.selectedExamType : "",
          startDate: this.startDate,
          endDate: this.endDate,
          criteria: criteria,
          isForNBA: this.nbaCriteria,
          applicableSubjects: this.subjectObject ? this.subjectObject : [],
          subjectDetails: this.subjectDetails ? this.subjectDetails : [],
          questionDetails: this.questionDetails ? this.questionDetails : [],
          examStatus: "Draft"
        };
        if (this.nbaCriteria) {
          objToPush.nbaAttainment = this.nbaAttainmentObject
            ? this.nbaAttainmentObject
            : "";
        }
        try {
          await apiV3.postRequest("/manageExam/saveAsDraft", objToPush);
          showStatus("Exam Saved as a draft", 5000, "success", this);
          this.saveAsDraftLoader = false;
          this.$router.push("/activityMyExam");
        } catch (err) {
          console.log(err);
          this.saveAsDraftLoader = false;
          showStatus("Error while saving as a draft", 9000, "error", this);
        }
      }
    },
    // subject
    async getCourseOutcome(subject) {
      try {
        this.courseOutcomeData = [];
        this.COFromNba = [];
        this.nbaData = await this.nbaRepositoryInstance.getCos({
          lmsInstituteId: this.selectedInstitute,
          semesterId: this.selectedSemester.semId,
          subjectId: subject.subjectId
        });
        if (Array.isArray(this.nbaData.data)) {
          this.nbaData.data.forEach((nba, i) => {
            this.courseOutcomeData.push({
              Id: nba._id,
              coNumber: nba.CONumber,
              coName: nba.COName
            });
          });
        }
        // this.assignedCoArray = this.courseOutcomeData
      } catch (err) {
        console.log(err);
      }
    },
    async getRoomName() {
      try {
        this.roomNames = [];
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivision
        };
        this.roomData =
          await this.infrastructureReferenceRepositoryInstance.getRoomsOfADepartment(
            objToPush
          );
        this.roomData.map((room) => {
          this.roomNames.push({ roomName: room.roomName, roomId: room.roomId });
        });
        this.allRoom = this.roomNames;
      } catch (err) {
        console.log(err);
        this.locations = [];
      }
    },
    async getSubjects() {
      // this.subjects = this.userData.subjects;
      // this.subjectNames = this.userData.subjects
      // this.allSubject = this.userData.subjects
      this.userData.subjects.map((item) => {
        if (item.subjectId) {
          if (!this.subjects.find(sub => sub.subjectId === item.subjectId)) {
            this.subjects.push(item)
          }
        }
      })
      this.subjectNames = this.subjects
      this.allSubject = this.subjects
      // try {
      //   this.subjects =
      //     await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
      //       {
      //         instituteId: this.selectedInstitute,
      //         semId: this.selectedSemester.semId,
      //         department: this.selectedDepartment,
      //         courseYear: this.selectedCourseYear
      //       }
      //     );
      //   this.subjects.forEach((sub) => {
      //     this.subjectNames.push({
      //       subjectName: sub.subjectName,
      //       subjectId: sub.subjectId
      //     });
      //     this.allSubject.push(sub);
      //   });
      this.handleCourseState = true;
      // } catch (err) {
      //   console.log(err);
      // }
    },
    async getFaculties() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        };
        this.facultyList =
          await this.semesterUserRepositoryInstance.getFacultiesOfAInstitute(
            objToPush
          );
        this.facultyList.forEach((faculty) => {
          this.facultyArray.push(faculty.uId);
        });
        const objForFullName = {
          uIds: this.facultyArray
        };
        this.facultyNames =
          await this.userRepositoryInstance.getFullNameOfUsers(objForFullName);
        this.allFaculty = this.facultyNames;
      } catch (err) {
        console.log(err);
      }
    },
    onSubjectExpansionPanelClick(e, sub) {
      if (!(e.currentTarget.classList.contains('v-expansion-panel-header--active'))) {
        this.subjectPanel(sub)
      }
    },
    async subjectPanel(subject) {
      this.subjectDetailsLoaded = false;
      if (!subject.totalStudents) {
        await this.getStudents(subject);
      }
      await this.getRoomName();
      await this.getFaculties();
      await this.handleDates();
      this.subjectDetailsLoaded = true;
    },
    handleDates() {
      this.subjectDetails.map((sub) => {
        if (sub.selectedDate !== undefined) {
          this.selectedDateArray.push(sub.selectedDate);
        }
      });
    },
    async getStudents(subject) {
      this.studentArray = []
      try {
        const objOfStudent = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectId: subject.subjectId
        };
        this.studentList =
          await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject(
            objOfStudent
          );
        this.studentList.forEach((student) => {
          this.studentArray.push(student.uId);
        });
        const objForFullName = {
          uIds: this.studentArray
        };

        this.studResponse = await this.userRepositoryInstance.getUsers(
          objForFullName
        );

        // this.studentNames =
        //   await this.userRepositoryInstance.getFullNameOfUsers(objForFullName);

        const studentFullNames = [];
        this.studResponse.map((stu) => {
          studentFullNames.push({
            fullName: stu.firstName + " " + stu.middleName + " " + stu.lastName,
            uId: stu.uId,
            prn: stu.collegePRNNo
          });
        });

        this.studentNames = studentFullNames;
        this.applicableStudents = studentFullNames;
        this.allStudents = this.applicableStudents;
      } catch (err) {
        console.log(err);
      }
    },
    async tempAddRoom(subject) {
      if (subject.roomStatus === true) {
        this.editRoomIndex = -1;
        this.selectedStudent = [];
        this.selectedFaculty = [];
        // let roomDta = subject.roomNames
        // roomDta.forEach((room,i)=> {
        //   if(room.roomId === this.allowedRoom){
        //     roomDta.splice(room, 1)
        //   }
        // })
        // subject.roomNames = roomDta
        subject.facultyNames = subject.facultyNames.filter(
          (item) => !this.selectedFacultyArray.includes(item.uId)
        );
        subject.applicableStudents = subject.applicableStudents.filter(
          (item) => !this.selectedStudentArray.includes(item.uId)
        );
      } else {
        subject["applicableStudents"] = this.applicableStudents;
        subject["facultyNames"] = this.allFaculty;
        subject["roomNames"] = this.allRoom;
      }
      this.allowedRoom = "";
      this.selectedStudent = [];
      this.selectedFaculty = [];
      this.editingRoom = false;
      this.tempSelectedRoom = {
        roomNumber: this.roomAllocation.length + 1,
        roomName: "",
        appliedStudents: [],
        appliedFaculties: []
      };
      this.allStudentList = subject;
      this.addNewRoomDialog = true;
    },
    async saveTempRoom(subject) {
      this.selectedStudentArray = [];
      this.selectedFacultyArray = [];

      this.roomNames.forEach((room) => {
        if (room.roomId === this.allowedRoom) {
          this.roomName = room.roomName;
        }
      });
      this.tempSelectedRoom.roomName = this.allowedRoom;

      this.selectedStudent.map((stud) => {
        this.selectedStudentArray.push(stud.uId);
      });
      this.tempSelectedRoom.appliedStudents = this.selectedStudentArray;

      this.selectedFaculty.map((faculty) => {
        this.selectedFacultyArray.push(faculty.uId);
      });
      this.tempSelectedRoom.appliedFaculties = this.selectedFacultyArray;

      if (this.editRoomIndex !== -1) {
        this.roomAllocation[this.editRoomIndex] = this.tempSelectedRoom;
        this.editRoomIndex = -1;
      } else {
        if (subject.applicableStudents.length > 0) {
          this.roomAllocation.push(this.tempSelectedRoom);
          subject["roomStatus"] = true;
        } else {
          showStatus(`No Students are remaining`, 9000, "error", this);
        }
      }
      this.addNewRoomDialog = false;
      // showStatus(`Room added successfully.`, 2000, "success", this);
    },
    async editRoom(index, subject) {
      subject.roomNames = this.allRoom;
      subject.applicableStudents = this.applicableStudents;
      subject.facultyNames = this.allFaculty;

      this.selectedStudent = [];
      this.selectedFaculty = [];
      this.studentNames.map((stud) => {
        this.roomAllocation[index].appliedStudents.map((obj) => {
          if (stud.uId === obj) {
            this.selectedStudent.push(stud);
          }
        });
      });
      this.facultyNames.map((faculty) => {
        this.roomAllocation[index].appliedFaculties.map((obj) => {
          if (faculty.uId === obj) {
            this.selectedFaculty.push(faculty);
          }
        });
      });
      this.tempSelectedRoom = {
        roomNumber: index,
        roomName: this.roomAllocation[index].allowedRoom,
        appliedStudents: this.roomAllocation[index].appliedStudents,
        appliedFaculties: this.roomAllocation[index].appliedFaculties
      };
      this.editRoomIndex = index;
      this.addNewRoomDialog = true;
    },
    async openDeleteDailog(index, roomAllocation, subject) {
      this.indexValue = index;
      this.roomAllocationObject = roomAllocation;
      this.deleteSubject = subject;
      this.deleteDialog = true;
    },
    async deleteRoom(indexValue, roomAllocation, deleteSubject) {
      const subjectIndex = this.subjectDetails.indexOf(deleteSubject);
      this.allRoom.forEach((room) => {
        if (room.roomId === roomAllocation[indexValue].roomName) {
          roomAllocation[indexValue].roomName = "";
          deleteSubject.roomNames.push(room);
          // deleteSubject["roomNames"] = deleteSubject.roomNames;
        }
      });
      //faculty
      this.allFaculty.forEach((faculty) => {
        roomAllocation[indexValue].appliedFaculties.forEach((selected, i) => {
          if (faculty.uId === selected) {
            this.selectedFacultyArray.map((element) => {
              if (element === selected) {
                this.selectedFacultyArray.splice(element, 1);
              }
            });
            if (!deleteSubject.facultyNames.includes(faculty)) {
              deleteSubject.facultyNames.push(faculty);
            }
          }
        });
      });
      //students
      this.allStudents.forEach((student) => {
        roomAllocation[indexValue].appliedStudents.forEach((selected, i) => {
          if (student.uId === selected) {
            this.selectedStudentArray.map((element) => {
              if (element === selected) {
                this.selectedStudentArray.splice(element, 1);
              }
            });
            if (!deleteSubject.applicableStudents.includes(student)) {
              deleteSubject.applicableStudents.push(student);
            }
          }
        });
      });
      this.subjectDetails[subjectIndex] = deleteSubject;
      roomAllocation.splice(indexValue, 1);
      this.deleteDialog = false;
    },
    async cancelRoom() {
      this.tempSelectedRoom = {};
      this.addNewRoomDialog = false;
    },
    async editSubject(subject) {
      subject.editSubjectDetails = false;
      this.studentNames.forEach((obj) => {
        subject.totalStudents.forEach((object) => {
          if (object === obj.uId) {
            this.applicableStudents.push(obj);
          }
        });
      });
      this.selectedApplicablestudents = subject.totalStudents
      this.selectedDate = subject.selectedDate
      this.selectedTime = subject.selectedTime
      this.duration = subject.duration
      this.totalMarks = subject.totalMarks
      this.passingMarks = subject.passingMarks;
      this.roomAllocation = subject.roomAllocation;
    },
    async saveSubject(subject) {
      let error = false;
      if (Number(this.passingMarks) > Number(this.totalMarks)) {
        error = true;
      } else {
        error = false;
      }
      if (!error) {
        try {
          this.selectedApplicablestudents = [];
          this.applicableStudents.forEach((obj) => {
            this.selectedApplicablestudents.push(obj.uId);
          });

          this.subjectDetails.forEach((subj) => {
            if (subj.subjectId === subject.subjectId) {
              subj.subjectId = subject.subjectId;
              subj.subjectName = subject.subjectName;
              subj.totalStudents = this.selectedApplicablestudents;
              subj.selectedDate = this.selectedDate;
              subj.selectedTime = this.selectedTime;
              subj.duration = this.duration;
              subj.totalMarks = this.totalMarks;
              subj.passingMarks = this.passingMarks;
              subj.roomAllocation = this.roomAllocation;
              subj.editSubjectDetails = true;
            }
          });
          this.editSubjectDetails = true;
          this.selectedApplicablestudents = [];
          this.selectedDate = "";
          this.selectedTime = "";
          this.duration = "";
          this.totalMarks = "";
          this.passingMarks = "";
          this.roomAllocation = [];
          this.applicableStudents = [];
        } catch (e) {
          console.log(e);
        }
        const result = this.subjectDetails.every(this.checkSubject);
        result
          ? (this.saveSubjectDetails = true)
          : (this.saveSubjectDetails = false);
      }
    },
    checkSubject(sub) {
      if (sub.totalMarks) {
        return true
      } else {
        return false
      }
    },
    // question
    async questionPanel(subject) {
      this.maxMarks = 0;

      this.tempTotalMarks = 0;
      this.subjectDetails.map((sub) => {
        if (sub.subjectId === subject.subjectId) {
          this.tempTotalMarks = Number(sub.totalMarks);
          this.maxMarks = Number(sub.totalMarks);
        }
      });
      this.questionDetailsLoaded = false;
      this.assignedCoArray = [];
      this.assignedBloomsArray = [];
      this.allBlooms.map((bloom) => {
        this.assignedBloomsArray.push(bloom.shortCode);
      });
      const index = this.questionDetails.indexOf(subject);
      if (subject.editQuestionDetails === true) {
        this.overallThresholdPercentage = subject.overallThreshold;
        this.totalQuestions = subject.totalQuestions;
        this.questionsArray = subject.questions;
        this.questionSet = subject.questions;
        this.studentData = subject.studentData;
        if (subject.uploadQuestions === true) {
          this.toggleQuestionUpload = "uploadQuestions";
          this.uploadQuestionManually();
          this.saveQuestions(subject);
        } else {
          this.toggleQuestionUpload = "dontUploadQuestions";
          this.assignedCoArray = subject.assignedCo;
          this.assignedBloomsArray = subject.assignedBlooms;
          this.uploadQuestionManually();
          this.saveQuestions(subject);
        }
        if (subject.uploadMarksType === "aggregate") {
          this.toggleMarksUpload = "aggregate";
          this.uploadMarksManually(subject);
        } else if (subject.uploadMarksType === "individual") {
          this.toggleMarksUpload = "individual";
          this.uploadMarksManually(subject);
        }
      } else {
        this.toggleMarksUpload = null;
        this.toggleQuestionUpload = "";
        this.assignedCo = [];
        this.overallThreshold = 0;
        this.totalQuestions = 0;
        this.questionsArray = [];
        this.questionSet = [];
        this.uploadQuestionManuallyStatus = false;
        this.editQuestionDetails = false;
        this.uploadMarksManuallyStatus = false;
        if (this.nbaCriteria === true) {
          if (Array.isArray(this.nbaAttainmentObject.thresholdPercentage)) {
            this.nbaAttainmentObject.thresholdPercentage.map((obj, i) => {
              if (obj.subjectId === subject.subjectId) {
                this.overallThresholdPercentage = obj.subjectThreshold;
              }
            });
          } else if (
            this.nbaAttainmentObject.typeOfExam === "cie" &&
            this.nbaAttainmentObject.thresholdType === "individualThreshold"
          ) {
            this.overallThresholdPercentage = 0;
          } else {
            this.overallThresholdPercentage = this.overallThresholdPercentage
              ? this.overallThresholdPercentage
              : 0;
          }
        } else {
          this.overallThresholdPercentage = 0;
        }
        const tempStudentData = [];
        this.questionDetails[index].studentData = [];
        this.studentNames.forEach((eachStudent, i) => {
          this.subjectDetails.forEach((stud, index) => {
            if (stud.subjectId === subject.subjectId) {
              stud.totalStudents.forEach((obj) => {
                if (obj === eachStudent.uId) {
                  const temp = {
                    studentName: eachStudent.fullName,
                    studentId: eachStudent.uId,
                    total: stud.totalMarks,
                    subjectName: stud.subjectName,
                    subjectId: stud.subjectId,
                    studentPrn: eachStudent.prn
                  };
                  tempStudentData.push(temp);
                }
              });
            }
          });
        });
        this.studentData = tempStudentData;
        // this.getCourseOutcome(subject)
        await this.getCourseOutcome(subject);
        if (Array.isArray(this.nbaData.data)) {
          this.nbaData.data.forEach((nba, i) => {
            this.assignedCoArray.push(nba.CONumber);
          });
        }
        this.assignData(index, this.studentData);
      }
      this.noQuestionOverallThresholdPercentage = this
        .overallThresholdPercentage
        ? this.overallThresholdPercentage
        : 0;
      if (!subject.questions) {
        this.indivitualThresholdStatus = false;
        this.overallPercentageStatus = false;
      }
      if (this.overallThresholdPercentage) {
        this.overallPercentageStatus = true;
      }
      this.questionDetailsLoaded = true;
    },
    assignData(index, studentData) {
      this.questionDetails[index].studentData = studentData;
    },
    addNewQuestion() {
      const tempQuestion = {
        number: this.questionSet.length + 1,
        marks: "",
        indivitualThreshold: "",
        question: "",
        COArray: [],
        coDialog: false,
        bloomArray: [],
        bloomDialog: false
      };
      this.questionsArray = [...this.questionsArray, tempQuestion];
      this.questionSet = this.questionsArray;
      this.totalQuestions = this.questionSet.length;
    },
    deleteQuestion(index) {
      const temp = [...this.questionSet];
      temp.splice(index, 1);
      this.questionsArray = temp;
      this.questionSet = this.questionsArray;
      this.totalQuestions = this.questionSet.length;
    },
    // toggleQuestionUploadFunc(subject, i){
    //   console.log('this.questionDetails',this.questionDetails[i]);
    //   // this.questionDetails.push({ subjectName: subject.subjectName, subjectId: subject.subjectId })
    //   // this.questionDetails[i] = {}
    //   if (this.toggleMarksUpload === 'uploadQuestions') {
    //     delete subject.uploadQuestions
    //     delete subject.assignedCo
    //     delete subject.editQuestionDetails
    //     delete subject.uploadQuestions
    //     delete subject.overallThreshold
    //   } else {
    //     delete subject.questions
    //     delete subject.overallThreshold
    //     delete subject.totalQuestions
    //     delete subject.uploadQuestions
    //   }
    // },
    async saveQuestions(subject) {
      this.subjectDetails.map((sub) => {
        if (subject.subjectId === sub.subjectId) {
          this.tempTotalMarks = sub.totalMarks;
        }
      });
      if (this.toggleQuestionUpload === "uploadQuestions") {
        let add = 0;
        this.questionSet.map((que) => {
          add += Number(que.marks);
        });
        this.questionDetails.forEach((subj) => {
          if (subj.subjectId === subject.subjectId) {
            if (add <= this.tempTotalMarks) {
              subj.uploadQuestions = true;
              subj.subjectId = subject.subjectId
              subj.subjectName = subject.subjectName
              subj.overallThreshold = this.overallThresholdPercentage
              subj.totalQuestions = this.totalQuestions
              subj.questions = this.questionSet
              subj.editQuestionDetails = true;
              if (this.toggleMarksUpload !== null) {
                subj.uploadMarks =
                  this.toggleMarksUpload === "individual"
                    ? "individual"
                    : "aggregate";
              }
              this.editQuestionDetails = true;
            } else {
              this.editQuestionDetails = false;
              showStatus(
                `Total of Marks should be Less than  ${this.tempTotalMarks}`,
                9000,
                "error",
                this
              );
            }
          }
        });
      } else if (this.toggleQuestionUpload === "dontUploadQuestions") {
        this.questionDetails.forEach((subj) => {
          if (subj.subjectId === subject.subjectId) {
            subj.uploadQuestions = false;
            subj.subjectId = subject.subjectId;
            subj.subjectName = subject.subjectName;
            subj.assignedCo = this.assignedCoArray;
            subj.assignedBlooms = this.assignedBloomsArray;
            subj.editQuestionDetails = true;
            subj.overallThreshold = this.noQuestionOverallThresholdPercentage;
            this.editQuestionDetails = true;
            if (this.toggleMarksUpload !== null) {
              subj.uploadMarks =
                this.toggleMarksUpload === "individual"
                  ? "individual"
                  : "aggregate";
            }
          }
        });
      }
      if (
        this.questionDetails[this.questionDetails.length - 1]
          .editQuestionDetails
      ) {
        this.publishExam = true;
      } else {
        this.publishExam = false;
      }
      this.handleOverallThreshold()
    },
    editQuestion(subject) {
      this.editQuestionDetails = false;
      this.questionsArray = [];
      if (subject.questions !== undefined) {
        this.questionsArray = subject.questions;
      }
      // this.uploadMarksManuallyStatus = this.uploadMarksManuallyStatus === true ? true : false;
    },
    uploadMarksManually(subject) {
      this.uploadMarksManuallyStatus = true;
      this.marksUpload(subject);
    },
    cancelQuestion(subject) {
      if (subject.questions === undefined) {
        this.questionsArray = [];
        this.questionSet = [];
        this.toggleCancelQuestionDialog = false;
      } else {
        this.questionsArray = subject.questions;
        this.questionSet = subject.questions;
        this.toggleCancelQuestionDialog = false;
      }
    },
    async marksCalculation(subject) {
      let total;
      this.subjectDetails.map((sub) => {
        if (subject.subjectId === sub.subjectId) {
          total = sub.totalMarks;
          this.tempTotalMarks = sub.totalMarks;
        }
      });
      if (this.overallPercentageStatus === true) {
        if (Array.isArray(this.questionSet)) {
          this.questionSet.forEach((que) => {
            if (Number(que.marks) > Number(total)) {
              showStatus(
                `Marks Should Be Less Than ${this.tempTotalMarks}`,
                9000,
                "error",
                this
              );
              que.marks = "";
            } else {
              this.indivitualThresholdAvg =
                (que.marks / 100) * this.overallThresholdPercentage;
              que["indivitualThreshold"] = this.indivitualThresholdAvg;
            }
          });
        }
      }
      if (this.test) {
        if (Array.isArray(this.questionSet)) {
          this.questionSet.forEach((que) => {
            this.indivitualThresholdAvg = (que.marks / 100) * this.test;
            que["indivitualThreshold"] = this.indivitualThresholdAvg;
          });
        }
      }
    },
    async handleOverallThreshold() {
      this.overallPercentageStatus = true;
    },
    async handleIndivitualThreshold() {
      this.indivitualThresholdStatus = true;
    },
    async uploadQuestionManually() {
      this.uploadQuestionManuallyStatus = true;
      if (this.nbaCriteria === true) {
        if (
          this.nbaAttainmentObject.typeOfExam === "cie" &&
          this.nbaAttainmentObject.thresholdType === "overallThreshold"
        ) {
          this.overallPercentageStatus = true;
        } else if (
          this.nbaAttainmentObject.typeOfExam === "see" &&
          this.nbaAttainmentObject.thresholdType === "individualThreshold"
        ) {
          this.overallPercentageStatus = true;
        } else if (
          this.nbaAttainmentObject.typeOfExam === "see" &&
          this.nbaAttainmentObject.thresholdType === "overallThreshold"
        ) {
          this.overallPercentageStatus = true;
        }
      }
      if (this.overallThresholdPercentage) {
        this.overallPercentageStatus = true
      }
      // else {
      //   this.overallPercentageStatus = true
      // }
    },
    validateDate() {
      if (this.startDate > this.endDate) {
        showStatus(
          "End date cannot be ahead of start date.",
          9000,
          "error",
          this
        );
      }
    },
    async saveMarks(selectedStud) {
      let tempCount = 0
      if (this.toggleMarksUpload === "individual") {
        for (let index = 0; index < this.questionSet.length; index++) {
          const element = this.questionSet[index];
          if (Number(element.studentMarks) > Number(element.marks)) {
            tempCount++
          }
        }
      } else if (
        this.toggleMarksUpload === "aggregate" &&
        this.toggleQuestionUpload === "dontUploadQuestions"
      ) {
        if (this.obtainedMarks > this.maxMarks) {
          tempCount++
        }
      } else if (this.toggleMarksUpload === "aggregate") {
        if (this.obtainedMarks > this.maxMarks) {
          tempCount++
        }
      }

      if (tempCount === 0) {
        if (this.toggleMarksUpload === "individual") {
          const subject = this.questionDetails.filter(
            (sub) => sub.subjectId === selectedStud.subjectId
          );
          let student = [];
          student = subject[0].studentData;
          this.show = false;
          let totalObtained = 0;
          this.uploadMarksDialog = false;
          this.questionSet.map((que, i) => {
            student.map((data) => {
              if (data.studentId === selectedStud.studentId) {
                data["uploadMarksType"] = "individual";
                data[`Q ${i + 1}`] =
                  data.studentMarks === que.studentMarks
                    ? data.studentMarks
                    : que.studentMarks;
                totalObtained += Number(data[`Q ${i + 1}`]);
                data["obtainedMarks"] = totalObtained;
              }
            });
          });
          this.questionDetails.map((sub) => {
            subject.map((subj) => {
              if (sub.subjectId === subj.subjectId) {
                sub = subj;
                sub["uploadMarksType"] = "individual";
              }
            });
          });
          this.show = true;
        } else if (
          this.toggleMarksUpload === "aggregate" &&
          this.toggleQuestionUpload === "dontUploadQuestions"
        ) {
          const subject = this.questionDetails.filter(
            (sub) => sub.subjectId === selectedStud.subjectId
          );
          let student = [];
          student = subject[0].studentData;
          this.show = false;
          this.uploadMarksDialog = false;
          student.map((data) => {
            if (data.studentId === selectedStud.studentId) {
              data["uploadMarksType"] = "aggregate";
              data["obtainedMarks"] = this.obtainedMarks;
            }
          });

          this.questionDetails.map((sub) => {
            subject.map((subj) => {
              if (sub.subjectId === subj.subjectId) {
                sub = subj;
                sub["uploadMarksType"] = "aggregate";
              }
            });
          });
          this.show = true;
          this.uploadOverallMarksDialog = false;
        } else if (this.toggleMarksUpload === "aggregate") {
          const subject = this.questionDetails.filter(
            (sub) => sub.subjectId === selectedStud.subjectId
          );
          let student = [];
          student = subject[0].studentData;
          this.show = false;
          this.uploadMarksDialog = false;
          let marks = 0;
          this.questionSet.map((que, i) => {
            student.map((data) => {
              if (data.studentId === selectedStud.studentId) {
                marks = this.obtainedMarks / this.questionSet.length;
                if (i + 1 !== this.questionSet.length) {
                  data["uploadMarksType"] = "aggregate";
                  data[`Q ${i + 1}`] = Math.ceil(marks * 100) / 100;
                } else {
                  data["uploadMarksType"] = "aggregate";
                  data[`Q ${i + 1}`] = Math.floor(marks * 100) / 100;
                }
                data["obtainedMarks"] = Number(this.obtainedMarks);
              }
            });
          });
          this.questionDetails.map((sub) => {
            subject.map((subj) => {
              if (sub.subjectId === subj.subjectId) {
                sub = subj;
                sub["uploadMarksType"] = "aggregate";
              }
            });
          });
          this.show = true;
          this.uploadOverallMarksDialog = false;
        }
      }
    },
    async editStudentMarks(item) {
      this.maxQuestionMarks = 0;
      this.obtainedMarks = 0;
      if (this.toggleMarksUpload === "individual") {
        this.questionSet.map((que, i) => {
          if (item[`Q ${i + 1}`]) {
            que["studentMarks"] = item[`Q ${i + 1}`];
          } else {
            que["studentMarks"] = 0;
          }
        });
        this.uploadMarksDialog = true;
        this.selectedStud = item;
      } else if (this.toggleMarksUpload === "aggregate") {
        this.uploadOverallMarksDialog = true;
        this.selectedStud = item;
        if (this.selectedStud.obtainedMarks) {
          this.obtainedMarks = this.selectedStud.obtainedMarks;
        } else {
          this.obtainedMarks = 0;
        }
      }
    },
    async marksUpload(subject) {
      this.questionsArray = [];
      this.marksHeader = [];
      let header = [];
      header = [
        {
          text: "",
          value: "action",
          align: "center"
        },
        {
          text: "RN",
          value: "rollNo"
        },
        {
          text: "Student Name",
          value: "studentName"
        },
        {
          text: "PRN",
          value: "studentPrn"
        },
        {
          text: "Total",
          value: "total"
        },
        {
          text: "Total Obtained Marks",
          value: "obtainedMarks"
        }
      ];
      if (subject.questions) {
        subject.questions.forEach((question, i) => {
          header.push({
            text: `Q ${i + 1}`,
            value: `Q ${i + 1}`
          });
          question["questionNumber"] = `Q ${i + 1}`;
          question["studentMarks"] = "";
          this.subjectDetails.forEach((sub, index) => {
            if (sub.subjectId === subject.subjectId) {
              subject.marksHeader = header;
            }
          });
        });
      }
    },
    async createArray() {
      const subjectIds = this.subjectDetails.map((sub) => {
        return sub.subjectId;
      });
      const allSubjectIds = this.subjectAllocation.map((sub) => {
        return sub.subjectId;
      });
      this.subjectAllocation.map((sub) => {
        if (!subjectIds.includes(sub.subjectId)) {
          this.subjectDetails.push({
            subjectName: sub.subjectName,
            subjectId: sub.subjectId
          });
          this.questionDetails.push({
            subjectName: sub.subjectName,
            subjectId: sub.subjectId
          });
        }
      });
      if (this.subjectAllocation.length !== this.subjectDetails.length) {
        this.subjectDetails.map((subject, i) => {
          if (!allSubjectIds.includes(subject.subjectId)) {
            this.subjectDetails.splice(i, 1);
          }
        });
        this.questionDetails.map((question, i) => {
          if (!allSubjectIds.includes(question.subjectId)) {
            this.questionDetails.splice(i, 1);
          }
        });
      }
    },
    async gotoNext() {
      let flag = false;
      if (this.endDate === "") {
        showStatus("End date cannot be empty.", 9000, "error", this);
        flag = true;
      }
      if (this.startDate === "") {
        showStatus("Start date cannot be empty.", 9000, "error", this);
        flag = true;
      }
      if (this.examName === "") {
        showStatus("Exam Name cannot be empty.", 9000, "error", this);
        flag = true;
      }
      if (this.selectedExamType === "") {
        showStatus("Exam Type cannot be empty.", 9000, "error", this);
        flag = true;
      }

      if (!flag) {
        if (this.nbaCriteria) {
          await this.nbaDetails();
        }
        await this.createArray();
        this.e1 = 2;
      }
    },
    async uploadQuestionExcel(subject) {
      const filteredSubject = this.subjectDetails.filter(
        (item) => item.subjectId === subject.subjectId
      );
      if (this.questionExcelFile) {
        var formData = new FormData();
        formData.append("excel", this.questionExcelFile);
        formData.append(
          "subjectTotalMarks",
          Number(filteredSubject[0].totalMarks)
        );
        const url =
          process.env.VUE_APP_SERVER_URL +
          process.env.VUE_APP_API_PORT +
          "/excel/exam/parse";
        axios
          .post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then((response) => {
            response.data.questionsArray.map((d) => {
              this.totalQuestions++;
              this.questionsArray.push({
                COArray: [],
                coDialog: false,
                bloomArray: [],
                bloomDialog: false,
                number: this.questionsArray.length + 1,
                marks: d.totalMarks,
                question: d.question,
                indivitualThreshold: this.overallThresholdPercentage
                  ? (d.totalMarks / 100) * this.overallThresholdPercentage
                  : ""
              });
            });
            this.questionSet = this.questionsArray;
            this.uploadQuestionExcelDialog = false;
          })
          .catch((error) => {
            if (
              error.response.data.customErrorMessage === "marksSumExceedError"
            ) {
              this.uploadQuestionExcelDialog = false;
              this.excelCustomError = true;
              this.excelCustomErrorMessage = `Cannot upload excel. Marks sum exceeding ${filteredSubject[0].totalMarks}.`;
            }
          });
      }
      this.questionExcelFile = "";
      this.questionsLoadedViaExcel = true;
    },
    async uploadMarksExcel(subject) {
      this.studentExceedingMarksLimit = [];
      const filteredSubject = this.subjectDetails.filter(
        (item) => item.subjectId === subject.subjectId
      );
      this.marksLoadedViaExcel = true;
      if (this.marksExcelFile) {
        var formData = new FormData();
        formData.append("excel", this.marksExcelFile);
        formData.append("marksUploadType", this.toggleMarksUpload);
        formData.append("questionsUploadType", this.toggleQuestionUpload);
        axios
          .post(
            process.env.VUE_APP_SERVER_URL +
            process.env.VUE_APP_API_PORT +
            "/excel/exam/parseMarks",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(async (response) => {
            // this.marksUpload(subject)
            // this.saveMarksViaExcel(response.data.studentData, subject)
            // this.uploadMarksExcelDialog = false
            await this.uploadMarksManually(subject);

            if (this.toggleMarksUpload === "aggregate") {
              for (
                let index = 0;
                index < response.data.studentData.length;
                index++
              ) {
                const element = response.data.studentData[index];
                if (
                  element.obtainedMarks > Number(filteredSubject[0].totalMarks)
                ) {
                  this.studentExceedingMarksLimit.push(element.studentName);
                }
              }
            } else if (this.toggleMarksUpload === "individual") {
              for (
                let index = 0;
                index < response.data.studentData.length;
                index++
              ) {
                const element = response.data.studentData[index];

                for (const [key, value] of Object.entries(element)) {
                  if (key.split(" ")[0] === "Q") {
                    const filteredQuestion = this.questionSet.filter(
                      (item) => item.questionNumber === key
                    )[0];

                    if (value > filteredQuestion.marks) {
                      if (
                        this.studentExceedingMarksLimit.indexOf(
                          element.studentName
                        ) === -1
                      ) {
                        this.studentExceedingMarksLimit.push(
                          element.studentName
                        );
                      }
                    }
                  }
                }
              }
            }
            this.uploadMarksExcelDialog = false;
            if (this.studentExceedingMarksLimit.length === 0) {
              this.saveMarksViaExcel(response.data.studentData, subject);
            } else {
              this.excelCustomError = true;
              this.excelCustomErrorMessage =
                "Cannot upload excel. Please recheck the marks of following students.";
            }
          });
      }
      this.marksExcelFile = "";
      this.marksLoadedViaExcel = false;
      this.uploadMarksManuallyStatus = true;
    },
    saveMarksViaExcel(studentData, sub) {
      studentData.map((st) => {
        if (st.studentPrn) {
          st.studentPrn = st.studentPrn + "";
        }
      });
      sub.studentData.map((stud) => {
        studentData.map((data, j) => {
          let marks;
          if (stud.studentPrn === data.studentPrn) {
            if (
              this.toggleQuestionUpload === "dontUploadQuestions" &&
              this.toggleMarksUpload === "aggregate"
            ) {
              Object.assign(stud, data);
              Object.assign(stud, { uploadMarksType: "aggregate" });
              Object.assign(sub, { uploadMarksType: "aggregate" });
            } else if (this.toggleMarksUpload === "individual") {
              Object.assign(stud, data);
              Object.assign(stud, { uploadMarksType: "individual" });
              Object.assign(sub, { uploadMarksType: "individual" });
            } else if (this.toggleMarksUpload === "aggregate") {
              this.questionSet.map((ques, i) => {
                if (data.obtainedMarks) {
                  marks = data.obtainedMarks / this.questionSet.length;
                  if (i + 1 !== this.questionSet.length) {
                    data["uploadMarksType"] = "aggregate";
                    data[`Q ${i + 1}`] = Math.ceil(marks * 100) / 100;
                  } else {
                    data["uploadMarksType"] = "aggregate";
                    data[`Q ${i + 1}`] = Math.floor(marks * 100) / 100;
                  }
                }
              });
              Object.assign(stud, data);
              Object.assign(sub, { uploadMarksType: "aggregate" });
            }
          }
        });
      });
    },
    gotoExams() {
      this.$router.push("activityMyExam");
    },
    toggleUploadMarksViaExcel() {
      this.uploadMarksExcelDialog = true;

      if (
        this.toggleQuestionUpload === "uploadQuestions" &&
        this.toggleMarksUpload === "individual"
      ) {
        this.marksSampleExcelLink =
          "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1669375313604________questionwise_individual_marks_sample.xlsx?alt=media&token=551218f8-7512-4b55-a4a1-32121f8748e8";
      } else if (
        this.toggleQuestionUpload === "uploadQuestions" &&
        this.toggleMarksUpload === "aggregate"
      ) {
        this.marksSampleExcelLink =
          "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1669375235224________questionwise_aggregate_marks_sample.xlsx?alt=media&token=57bb5196-ca79-40fb-9b2a-7d286be2ed7a";
      } else if (
        this.toggleQuestionUpload === "dontUploadQuestions" &&
        this.toggleMarksUpload === "aggregate"
      ) {
        this.marksSampleExcelLink =
          "https://firebasestorage.googleapis.com/v0/b/finalinprospect.appspot.com/o/assignments%2Fed28da67-6fc8-451e-832a-507658f74982%2F1669374075360________noquestions_overall_marks_sample.xlsx?alt=media&token=df16ebbc-5480-4375-a143-4eb893cfae6e";
      }
    },
    validateQuestionMarks(question) {
      this.maxQuestionMarks = 0;
      this.maxQuestionMarks = question.marks;
    },
    async generateMarksSampleExcel(subject) {
      this.excelGenerated = true;
      subject["toggleMarksUpload"] = this.toggleMarksUpload;
      subject["toggleQuestionUpload"] = this.toggleQuestionUpload;

      try {
        const pdfBlob =
          await this.examStudentRepositoryInstance.generateStudentMarksExcel(
            subject
          );
        const pdfBlob2 = new Blob([pdfBlob.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(pdfBlob2);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Marks Sample.xlsx");
        document.body.appendChild(link);
        link.click();
        this.excelGenerated = false;
      } catch (err) {
        console.log("error", err);
        showStatus("Error generating sample excel.", 9000, "error", this);
        this.excelGenerated = false;
      }
    }
  }
};
</script>
<style scoped src='./activityCreateExam.css'></style>
