import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-exam-container"},[_c(VDataTable,{attrs:{"items":_vm.allExams,"headers":_vm.examTableHeaders},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","gap":"15%"}},[_c(VIcon,{attrs:{"color":"#050D92"},on:{"click":function($event){return _vm.gotoEditExam(item)}}},[_vm._v("mdi-pencil")])],1)]}}])}),_c(VDialog,{attrs:{"max-width":"390px"},model:{value:(_vm.deleteExamDialog),callback:function ($$v) {_vm.deleteExamDialog=$$v},expression:"deleteExamDialog"}},[_c(VCard,[_c(VCardTitle,{staticStyle:{"color":"red"}},[_vm._v(" Delete "+_vm._s(_vm.examName)+" ? ")]),_c(VCardText,[_c('div',[_vm._v("All data related to this exam will be deleted. "),(_vm.examForNba)?_c('span',[_vm._v("This exam is also linked to "),_c('strong',[_vm._v("NBA")]),_vm._v(".")]):_vm._e(),_vm._v(" Do you still want to delete?")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"#FF4F1F","text":""},on:{"click":function($event){_vm.deleteExamDialog = false}}},[_vm._v(" No ")]),_c(VBtn,{attrs:{"color":"#FF4F1F","outlined":"","loading":_vm.toggleExamDelete},on:{"click":_vm.deleteExam}},[_vm._v(" Yes ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }