import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/Assessment";

class AssessmentRepository {
  constructor(context) {
    this.context = context;
  }

  async getSignedUrl(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getSignedUrl,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }

  async createAssessment(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createAssessment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assessment
  }

  async updateAssessmentDetails(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateAssessmentDetails, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assessment
  }

  async assessmentAsDraft(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.assessmentAsDraft, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.assessment
  }

  async deleteAssessment(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.deleteRequest(ApiNames.deleteAssessment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getAssessmentsOfADivision(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAssessmentsOfADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async generateSampleMarksExcel(data) {
    console.log('data', data);
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateSampleMarksExcel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default AssessmentRepository;
