<template src="./activityMySubjects.html"></template>

<script>
import TimetableRepository from '../../Repository/timetable'
import SubjectsRepository from '../../Repository/Subjects'
import { subjectTypes } from '../../Constants/Utils/Statics'
import CourseRepository from '../../Repository/courses';
import ValueAddedActivityRepository from '../../Repository/valueAddedActivity';
// import showStatus from '../../NetworkManager/showStatus'

export default {
  name: 'activityMySubjects',
  data() {
    return {
      allSubjectToShow: [],
      userData: null,
      isFielInstitute: false,
      selectedInstitute: '',
      selectedSemester: '',
      subjects: [],
      getTimetableSubjects: [],
      subjectTypes: subjectTypes,
      headers: [
        {
          text: 'Department',
          value: 'department',
          width: '20%'
        },
        {
          text: 'Course Year',
          value: 'courseYear',
          width: '20%'
        },
        {
          text: 'Division',
          value: 'division',
          width: '20%'
        },
        {
          text: 'Subject',
          value: 'subjectName',
          width: '20%'
        },
        // {
        //   text: 'Lecture Type',
        //   value: 'lectureType',
        //   width: '20%'
        // },
        {
          text: '',
          value: 'actions',
          width: '30%'
        }
      ],
      subjectsMapping: {},
      type: "",
      isNicmar: false,
      instituteIdsForNicmar: ["d26eac93-150f-446d-8708-8ce794361957", "25738fb8-0205-4b7e-ace2-96f8f940bb8d", "2e1691e6-f0bb-446d-b080-e0173d44af4c", "860153bb-3a42-4101-b3fb-8f08f684db90", "8e38d185-7cb0-42ba-bb19-04fdfacf819d", "f32c10d6-f25d-44b5-9fcb-e35e33e2aec2"]
    }
  },
  created() {
    this.$store.commit('liveData/set_selectedActivityName', ' My Subjects')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    if (this.selectedInstitute === 'fc4eb82f-4ea3-4389-8db5-6dad8e0114c3') {
      this.isFielInstitute = true
    }
    this.selectedSemester = this.$store.getters[
      'instituteData/get_selectedSemester'
    ]
    this.subjectsMapping = this.$store.getters["mappings/get_subjectsMapping"];
    this.timetableRepositoryInstance = new TimetableRepository(this)
    this.subjectsRepositoryInstance = new SubjectsRepository(this)
    this.CourseRepositoryInstance = new CourseRepository(this)
    this.ValueAddedActivityRepositoryInstance = new ValueAddedActivityRepository(this)

    // Call API to fetch subjects
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.isNicmar = false
      if (this.instituteIdsForNicmar.includes(this.selectedInstitute)) {
        this.isNicmar = true
      }
      let valueAddedActivity = []
      try {
        valueAddedActivity = await this.ValueAddedActivityRepositoryInstance.getValueAddedActivityForFaculty({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.userData.uId
        })
      } catch (error) {
        console.error('error', error);
      }

      try {
        this.allSubjectToShow = []
        if (this.selectedSemester.subjectAllocatedForDiv) {
          this.allSubjectToShow = [...this.selectedSemester.subjectAllocatedForDiv, ...this.selectedSemester.assignedGroupForSubject]
        } else {
          this.allSubjectToShow = [...this.selectedSemester.subjects, ...this.selectedSemester.assignedGroupForSubject]
        }
        const subjectIds = []

        this.allSubjectToShow.map((obj) => {
          subjectIds.push(obj.subjectId)
        })
        const getsubjectObjToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectIds: subjectIds
        }
        const subject = await this.subjectsRepositoryInstance.getSubjectDetails(
          getsubjectObjToPush
        )
        const tempSubjects = []
        this.$store.commit("mappings/reset");
        const tempSubjects1 = []
        subject.map((obj) => {
          this.allSubjectToShow.map((data) => {
            if (obj.subjectId === data.subjectId) {
              data.subjectDetails = obj
              tempSubjects1.push(data)
            }
          })
          this.getTimetableSubjects = this.allSubjectToShow

          this.$store.commit('mappings/add_subjectsMapping', obj)
          obj.lectureType = this.subjectTypes[obj.subjectType]
          this.getTimetableSubjects.map((sub) => {
            if (obj.subjectId === sub.subjectId) {
              tempSubjects.push({
                ...obj,
                ...sub
              })
            }
          })
        })
        this.allSubjectToShow = [...tempSubjects1, ...valueAddedActivity]
        this.allSubjectToShow.sort((a, b) => {
            const subjectNameA = a?.subjectDetails?.subjectName?.toUpperCase();
            const subjectNameB = b?.subjectDetails?.subjectName?.toUpperCase();

            if (subjectNameA < subjectNameB) {
              return -1;
            }
            if (subjectNameA > subjectNameB) {
              return 1;
            }
            return 0;
          });
        this.subjects = [...tempSubjects, ...valueAddedActivity]
        this.userData.subjects = this.subjects
        this.$store.commit('user/set_userData', this.userData)
      } catch (err) {
        console.error(err)
        this.subjects = []
      }
    },
    async gotoAttendanceAnalysis(subject, info) {
      let type;
      let divisionName = '';
      let groupInfo = {};
      if (info.division !== undefined && (info.batchName === undefined || info.batchName === '')) {
        type = 'Division'
        divisionName = info.division
        subject.division = info.division
        subject.batch = ''
      }
      if (info.groupName !== undefined) {
        type = 'Group'
        groupInfo = info
      }
      if (info.batchName !== undefined && info.batchName !== '') {
        divisionName = info.division
        type = 'Batch'
        subject.division = info.division
        subject.batch = info.batchName
      }
      if (this.selectedInstitute === '6a2b6228-14b8-4d0b-8044-b55fe74240b3') {
        type = 'MultiDivision'
      }
      this.$store.commit('liveData/set_selectedSubject', subject)
      this.$router.push({
        name: 'activityAttendanceAnalysis',
        params: {
          prop_subject: subject,
          prop_type: type,
          prop_groupInfo: groupInfo,
          prop_divisionName: divisionName
        }
      })
    },
    async gotoTopics(subject, div) {
      let type;
      let groupInfo = {};
      if (div.division !== undefined && (div.batchName === undefined || div.batchName === '')) {
        type = 'Division'
        subject.division = div.division
        subject.batch = ''
      }
      if (div.batchName !== undefined && div.batchName !== '') {
        type = 'Batch'
        subject.division = div.division
        subject.batch = div.batchName
      }
      if (div.groupName !== undefined) {
        type = 'Group'
        groupInfo = div
        subject.division = ''
      }
      this.$store.commit('liveData/set_selectedSubject', subject)
      this.$router.push({
        name: 'activityTopics',
        params: {
          prop_subject: subject,
          prop_type: type,
          prop_groupInfo: groupInfo
        }
      })
    },
    gotoAssignments(subject, div) {
      let type;
      let groupInfo = {};
      if (div.division !== undefined && (div.batchName === undefined || div.batchName === '')) {
        type = 'Division'
        subject.division = div.division
        subject.batch = ''
      }
      if (div.batchName !== undefined && div.batchName !== '') {
        type = 'Batch'
        subject.division = div.division
        subject.batch = div.batchName
      }
      if (div.groupName !== undefined) {
        type = 'Group'
        groupInfo = div
        subject.division = ''
      }
      // subject.division = div.division
      this.$store.commit('liveData/set_selectedSubject', subject)
      this.$router.push({
        name: 'activityAssignments',
        params: {
          prop_subject: subject,
          prop_type: type,
          prop_groupInfo: groupInfo
        }
      })
    },
    gotoMyAssessments(subject, div) {
      let type;
      let groupInfo = {};
      if (div.division !== undefined && (div.batchName === undefined || div.batchName === '')) {
        type = 'Division'
        subject.division = div.division
        subject.batch = ''
      }
      if (div.batchName !== undefined && div.batchName !== '') {
        type = 'Batch'
        subject.division = div.division
        subject.batch = div.batchName
      }
      if (div.groupName !== undefined) {
        type = 'Group'
        groupInfo = div
        subject.division = ''
      }
      // subject.division = div.division
      this.$store.commit('liveData/set_selectedSubject', subject)
      this.$router.push({
        name: 'activityAssessments',
        params: {
          prop_subject: subject,
          prop_type: type,
          prop_groupInfo: groupInfo
        }
      })
    },
    gotoQuickTest(subject, div) {
      let type;
      let groupInfo = {};
      if (div.division !== undefined && (div.batchName === undefined || div.batchName === '')) {
        type = 'Division'
        subject.division = div.division
        subject.batch = ''
      }
      if (div.batchName !== undefined && div.batchName !== '') {
        type = 'Batch'
        subject.division = div.division
        subject.batch = div.batchName
      }
      if (div.groupName !== undefined) {
        type = 'Group'
        groupInfo = div
        subject.division = ''
      }
      // subject.division = div.division
      this.$store.commit('liveData/set_selectedSubject', subject)
      this.$router.push({
        name: 'activityQuickTest',
        params: {
          prop_subject: subject,
          prop_type: type,
          prop_groupInfo: groupInfo
        }
      })
    },
    gotoStudyMaterial(subject, div) {
      let type;
      let groupInfo = {};
      if (div.division !== undefined && (div.batchName === undefined || div.batchName === '')) {
        type = 'Division'
        subject.division = div.division
        subject.batch = ''
      }
      if (div.batchName !== undefined && div.batchName !== '') {
        type = 'Batch'
        subject.batch = div.batchName
        subject.division = div.division
      }
      if (div.groupName !== undefined) {
        type = 'Group'
        groupInfo = div
        subject.division = ''
      }
      this.$store.commit('liveData/set_selectedSubject', subject)
      this.$router.push({
        name: 'activityStudyMaterial',
        params: {
          prop_subject: subject,
          prop_type: type,
          prop_groupInfo: groupInfo
        }
      })
    },
    gotoCourse(subject, div) {
      subject.division = div.division
      this.$store.commit('liveData/set_selectedSubject', subject)
      this.$router.push({
        name: 'activitySubjectCourses',
        params: {
          prop_subject: subject
        }
      })
    }
  }
}
</script>

<style src="./activityMySubjects.css"></style>
