<template src="./examForm.html"></template>

<script>
import SubjectRepository from "../../../Repository/Subjects";
import NewExamRepository from "../../../Repository/NewExam";
import ExamSubjectAndQuestionRepository from "../../../Repository/ExamSubjectAndQuestion";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import UserRepository from "../../../Repository/User";
import UserAuthenticationRepository from "../../../Repository/UserAuthentication";
import ExamFormAndHallTicketRepository from "../../../Repository/ExamFormAndHallTicket";
import InstituteRepository from "../../../Repository/Institute";
import showStatus from "../../../NetworkManager/showStatus";
import apiV3 from "../../../NetworkManager/apiV3";
export default {
  name: "examForm",
  data() {
    return {
      primaryColor: "#050D92",
      secondaryColor: "#FF4F1F",
      progressIndicator: 12,
      examTabs: ["Student List", "Exam Form"],
      tabs: "",
      headers: [
        { text: "Sr. No", value: "srNo", align: "center" },
        { text: "Roll. No", value: "rollNumber", align: "center" },
        { text: "PRN", value: "collegePRNNo", align: "center" },
        { text: "Student Name", value: "studentName", align: "center" },
        {
          text: "Accept Exam Form",
          value: "isExamFormAccepted",
          align: "center",
          sortable: false
        },
        {
          text: "Remarks",
          value: "examFormRemark",
          align: "center",
          width: "20%"
        },
        { text: "View Exam Form", value: "viewExamForm", align: "center" }
      ],
      headersStudent: [
        { text: "Year/sem", value: "semester", align: "center" },
        { text: "Subject Code", value: "subjectCode", align: "center" },
        { text: "Subject Name", value: "subjectName", align: "center" }
      ],
      selectedDepartment: "",
      selectedCourseYear: "",
      selectedInstitute: "",
      selectedSemester: null,
      examId: null,
      courseYearStudents: [],
      search: "",
      examFormTableData: [],
      remarksItem: ["Exam fee not paid", "college fee not paid", "others"],
      examFormDialog: false,
      selectedStudent: {},
      emailIdsOfStudents: [],
      exam: {},
      subjects: [],
      subjectsData: [],
      fetchedData: [],
      instituteInfo: {},
      examTabDependecies: {},
      // examFormAndHallTicketInstance: false,
      dowloadLoader: false,
      acceptAllExamFormCheckbox: false,
      loading: true,
      isSubjectsDataAvailable: true
    };
  },
  activated() {
      console.log("activated")
      // Your logic here
      const newValue = this.$store.state.liveData.examTabDependencies;
      let flag = false;
      Object.entries(newValue).forEach(([key, value]) => {
        console.log("examTabDependecies changed:123456' value", value);
        if (value['tab3'] === true) {
          flag = true;
          value['tab3'] = false;
        }
      });
      console.log("flag", flag);
      if (flag === true) {
        console.log("here to fetch data");
        this.initialize()
      }
    },
  async created() {
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.examSubjectAndQuestionRepositoryInstance =
      new ExamSubjectAndQuestionRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.userAuthenticationInstance = new UserAuthenticationRepository(this);
    this.examFormAndHallTicketInstance = new ExamFormAndHallTicketRepository(
      this
    );
    this.InstituteRepositoryInstance = new InstituteRepository(this);
    this.selectedDepartment =
      this.$store.getters["liveData/get_examDepartment"];
    this.selectedCourseYear =
      this.$store.getters["liveData/get_examCourseYear"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.examId = this.$store.getters["liveData/get_examId"];
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.getStudentsOfACourseYear();
      await this.getUsersDetails();
      await this.getUsersEmailId();
      await this.getStudentsExamForm();
      await this.getExam();
      await this.fetchSubjects();
      await this.getSubjectData();
      this.prepareExamFormTable();
      this.calculateProgressIndicator();
      await this.temp();
      this.checkAllSelected();
      this.examTabDependecies = this.$store.getters["liveData/get_examTabDependencies"]
      // state['tab3'].isTriggered = true;
      this.handleData()
      this.loading = false
    },
    async getStudentsOfACourseYear() {
      try {
        this.courseYearStudents =
          await this.semesterUserRepositoryInstance.getStudentsOfACourseYear({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear
          });
      } catch (error) {
        this.courseYearStudents = [];
      }
    },
    async getUsersDetails() {
      const studentIds = this.courseYearStudents.map((student) => student.uId);
      const data = await this.userRepositoryInstance.getUsers({
        uIds: studentIds
      });
      this.userDetails = data;
      console.log("use detials", this.userDetails);
    },
    async getUsersEmailId() {
      // emailIdsOfStudents
      const studentIds = this.courseYearStudents.map((student) => student.uId);
      const allEmails =
        await this.userAuthenticationInstance.getUserAuthenticationsByUIds({
          uIds: studentIds
        });
        const filteredEmails = [];
      allEmails.forEach((item, index) => {
        const { uId } = item;
        filteredEmails[uId] = item;
      });
      this.emailIdsOfStudents = Object.values(filteredEmails);
    },
    async getExam() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          examId: this.examId
        };
        const res = await this.newExamRepositoryInstance.getExam(objToPush);
        this.exam = res.exam;
        console.log("exam resssssscueddss", this.exam);
        // this.exam = res
      } catch (error) {
        console.error("error", error);
      }
    },
    async fetchSubjects() {
      const res = await this.subjectRepositoryInstance.getSubjects({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        subjectIds: this.exam.subjectIds
      });
      this.subjects = res;
    },
    checkAllSelected() {
      this.acceptAllExamFormCheckbox = this.examFormTableData.every(item => item.isExamFormAccepted);
    },
    async getSubjectData() {
      console.log("response 99", this.exam);
      const response =
        await this.examSubjectAndQuestionRepositoryInstance.getExamMultipleSubjectQuestions(
          {
            instituteId: this.selectedInstitute,
            examId: this.examId,
            subjectIds: this.exam.subjectIds
          }
        );
        if (response.result.length === 0) {
          this.isSubjectsDataAvailable = false;
          return;
        }
      console.log("response of subjects 99", response);
      // let subjectsData = response.result
      const temp = [];
      this.subjects.forEach((subject) => {
        console.log("subject696969", subject)
        const data = response.result.find(
          (item) => subject.subjectId === item.subjectId
          );
          console.log("data696969", data)
          temp.push({
            subjectCode: subject.subjectCode,
            subjectName: subject.subjectName,
            allocatedStudents: data.allocatedStudents,
            semester: this.selectedSemester.semName,
            credits: subject.credits
          });
      });
      this.subjectsData = temp;
      console.log("temp of subjects", temp);
    },
    prepareExamFormTable() {
      const temp = [];
      this.courseYearStudents.forEach((student, index) => {
        const userDetails = this.userDetails.find(
          (user) => student.uId === user.uId
        );
        const userEmail =
          this.emailIdsOfStudents.find((user) => student.uId === user.uId) ? this.emailIdsOfStudents.find((user) => student.uId === user.uId).email : ""
          const examFormDataForStudent = this.fetchedData.find(
          (item) => item.uId === student.uId
        );
        console.log("examFormDataForStudent", examFormDataForStudent);
        console.log("user details", userDetails)
        console.log("user details strudent", student)
        const subData = this.subjectsData.filter((subject) =>
            subject.allocatedStudents.includes(student.uId)
          );
        console.log("subDataForStudent", subData)
        temp.push({
          rollNumber: student.rollNumber,
          collegePRNNo: userDetails.collegePRNNo,
          studentName: userDetails.firstName + " " + userDetails.lastName,
          isExamFormAccepted: examFormDataForStudent
            ? examFormDataForStudent.isExamFormAccepted
            : true,
          examFormRemark: examFormDataForStudent
            ? examFormDataForStudent.examFormRemark
            : null,
          contactNumber: userDetails.mobile,
          userEmail: userEmail,
          uId: student.uId,
          gender: userDetails.gender,
          category: userDetails.category,
          isChanged: !(examFormDataForStudent?.isExamFormAccepted),
          subjectsData: subData,
          subjects: subData
        });
      });
      this.examFormTableData = temp;
    },
    handleExamFormDialog(item) {
      this.examFormDialog = true;
      console.log("item", item);
      item.subjectsData = this.subjectsData.filter((subject) =>
        subject.allocatedStudents.includes(item.uId)
      );
      this.selectedStudent = item;
    },
    async saveAndPublishForm() {
      const changedItems = this.examFormTableData
        .filter((item) => item.isChanged === true)
        .map(({ uId, isExamFormAccepted, examFormRemark }) => ({
          uId,
          isExamFormAccepted,
          examFormRemark,
          examId: this.examId,
          semId: this.selectedSemester.semId,
          instituteId: this.selectedInstitute,
          courseYear: this.selectedCourseYear
        }));
      console.log("changedItems", changedItems);
      try {
        const res =
          await this.examFormAndHallTicketInstance.createExamFormAndHallTicket(
            changedItems
          );
        console.log("Res", res);
        if (res.status.status === 200) {
          showStatus("Successfully saved.", 2000, "success", this);
          this.examFormTableData.forEach((item) => {
            const present = changedItems.find((changedItem) => changedItem.uId === item.uId)
            console.log("Present", present, item)
            if (present) {
              item.isChanged = false
            }
          })
        }
      } catch (error) {
        // console.error("Error",error)
        showStatus("Something went wrong", 2000, "error", this);
      }
      //herer\
      for (const key in this.examTabDependecies['tab3']) {
        console.log("key99", key);
        // this.examTabDependecies['tab3'][key] = Math.random();
        this.examTabDependecies['tab3'][key] = true;
      }
      console.log("state99", this.examTabDependecies)
    },
    async getStudentsExamForm() {
      const studentIds = this.courseYearStudents.map((student) => student.uId);
      const res =
        await this.examFormAndHallTicketInstance.getExamFormAndHallTicket({
          instituteId: this.selectedInstitute,
          examId: this.examId,
          uIds: studentIds
        });
      this.fetchedData = res.result;
      console.log("detials fetched", res);
    },
    calculateProgressIndicator() {
      this.progressIndicator =
        (this.examFormTableData.filter((item) => item.isExamFormAccepted)
          .length /
          this.examFormTableData.length) *
        100;
    },

    async temp() {
      //get insti info
      this.instituteInfo =
        await this.InstituteRepositoryInstance.getInstituteInformation({
          instituteId: this.selectedInstitute
        }); //get exam info
      console.log("this info", this.instituteInfo);
      console.log("this info stundet", this.courseYearStudents);
      console.log("this info stundet considet", this.examFormTableData);
      console.log("this info exam", this.exam);
      //get stundet info
    },
    async downloadExamForm() {
      // this.handleData()
      this.dowloadLoader = true;
      const objToPush = {
        instituteData: this.instituteInfo,
        studentData: this.selectedStudent,
        examData: this.exam
      };
        try {
          const result = await apiV3.postRequest(
            "/manageExam/generateStudentExamForm",
            objToPush
          );
          this.downloadFileFromBuffer(result.data.pdfUrl.data); // pass the buffer of the file
          showStatus('Exam form downloaded successfully.', 2000, 'success', this)
        } catch (error) {
          console.error('error', error);
        }
        this.dowloadLoader = false;
    },
    downloadFileFromBuffer(resultByte) {
      var bytes = new Uint8Array(resultByte); // pass your byte response to this constructor
      var blob = new Blob([bytes], { type: "application/pdf" }); // change resultByte to bytes
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `examForm.pdf`;
      link.click();
      showStatus('Error downloading exam form .', 1000, 'success', this)
    },
    handleData() {
      console.log("exams9090909", this.instituteInfo);
      const tempFields = this.instituteInfo.examFormFormat ? this.instituteInfo.examFormFormat.formFields : []
      for (let i = 0; i < tempFields.length; i++) {
        const element = tempFields[i];
        const objToPush = {}
        if (element === 'Program Name') {
          objToPush.text = 'Program Name'
          objToPush.value = this.exam.criteria ? this.exam.criteria.department : '-'
        } else if (element === 'PUNCODE') {
          objToPush.text = 'PUNCODE'
          objToPush.value = this.instituteInfo.puncode ? this.instituteInfo.puncode : '-'
        } else if (element === 'Exam Name') {
          objToPush.text = 'Exam Name'
          objToPush.value = this.exam.title
        } else if (element === 'Date') {
          objToPush.text = 'Date'
          objToPush.value = this.exam.startDate
        }
        console.log(objToPush)
        this.instituteInfo.examFormFormat['customExamFormFields'] = [objToPush]
      }
      this.instituteInfo.examFormFormat['customSubjectFields'] = []
      const tempSubFields = this.instituteInfo.examFormFormat ? this.instituteInfo.examFormFormat.subjectDetailFields : []
      for (let i = 0; i < tempSubFields?.length; i++) {
        const element = tempSubFields[i];
        const objToPush = {}
        if (element === 'Subject Name') {
          objToPush.text = 'Subject Name'
          objToPush.value = 'subjectName'
        } else if (element === 'Subject Code') {
          objToPush.text = 'Subject Code'
          objToPush.value = 'subjectCode'
        } else if (element === 'CIE (Internal)') {
          objToPush.text = this.isCurrentInstituteIsRiims ? 'CCE (Internal)' : 'CIE (Internal)'
          objToPush.value = ''
        } else if (element === 'SEE (External)') {
          objToPush.text = this.isCurrentInstituteIsRiims ? 'ESE (External)' : 'SEE (External)'
          objToPush.value = ''
        }
        this.instituteInfo.examFormFormat.customSubjectFields.push(objToPush)
      }
      console.log("check90909", this.examFormTableData)
      // this.examFormTableData
    },
    goToNextPage() {
            console.log("next page")
            this.$emit('nextButtonClick');
        },
        selectAllExamForm() {
          const acceptValue = this.acceptAllExamFormCheckbox;
          this.examFormTableData.forEach((item) => {
            if (item.isExamFormAccepted !== acceptValue) {
              item.isExamFormAccepted = acceptValue;
              item.isChanged = true;
            }
          });
      }
  }
};
</script>

<style src="./examForm.css"></style>
