<template src='./activityCOPSOMapping.html'>
</template>
<script>
import NBARepository from '../../Repository/NBA'
import inputContainer from '../../Components/inputContainer'
export default {
  name: 'activityCOPSOMapping',
  props: [
    'prop_DepartmentNBA',
    'prop_subjectNBA',
    'prop_COPSOMapping'
  ],
  components: {
    inputContainer
  },
  data () {
    return {
      COPSOMapping: {},
      model: 0,
      model2: 0,
      COs: [],
      PSOs: [],
      tempMap: '',
      colors: [
        'primary',
        'secondary',
        'yellow darken-2',
        'red',
        'orange'
      ]
    }
  },
  created () {
    this.COPSOMapping = JSON.parse(JSON.stringify(this.prop_COPSOMapping))
    this.COs = this.prop_subjectNBA.subjectCourseOutcomes
    this.PSOs = this.prop_DepartmentNBA.departmentProgramOutcomes
    this.tempMap = this.COPSOMapping[this.COs[0].coId][this.PSOs[0].psoId]
    this.$store.commit('liveData/set_selectedActivityName', 'CO-PSO Mapping')
    this.NBARepositoryInstance = new NBARepository(this)
    console.log(this.prop_DepartmentNBA, this.prop_subjectNBA)
    this.fetchData()
  },
  methods: {
    async fetchData () {
      const res = await this.NBARepositoryInstance.getCOPSOMapping(
        {
          coId: 'nxkcascnasjkcb'
        }
      )
      console.log(res)
    },
    async updateMapping () {
      const objToPush = {
        coId: this.COs[this.model].coId,
        psoId: this.PSOs[this.model2].psoId,
        psoMap: this.tempMap
      }
      console.log(objToPush)
      const res = await this.NBARepositoryInstance.updateCOPSOMapping(
        objToPush
      )
      this.COPSOMapping[this.COs[this.model].coId][this.PSOs[this.model2].psoId] = this.tempMap
      console.log(res)
    }
  }
}
</script>
<style scoped src='./activityCOPSOMapping.css'>
</style>
