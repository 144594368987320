<template>
    <div>
        <v-dialog v-model="isDocModalOpen" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-toolbar color="primary" style="color: #fff">
                <v-btn icon @click="isDocModalOpen = false, docLink = ''" style="color: #fff" class="ml-auto">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <embed :src="docLink" frameborder="0" class="framePdf" />
        </v-dialog>
        <inputContainer v-for="obj, i in pdfList" :key="i" style="margin:10px; padding:20px; height:unset">
            <v-row align="center">
                <v-col :cols="isMobileView ? 12 : 11">
                    <span style="justify-self: start; color:#00128C;  font-weight:700">
                        <span color="#00128C">{{ getFileName(obj) }}</span>
                    </span>
                </v-col>
                <!-- <v-col :cols="isMobileView ? 6 : 2" align="center">
                    <v-btn outlined style="color: #FF4F1F; box-shadow: none;" @click="openFileInPdfViewer(obj)">Open</v-btn>
                </v-col> -->
                <v-col :cols="isMobileView ? 6 : 1" align="center">
                <a :href="obj" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color:#00128C">
                    <v-icon style="font-size: 20px;" small color="#00128C">mdi-eye-outline</v-icon>
                </a>
                </v-col>

            </v-row>
        </inputContainer>

        <inputContainer v-for="obj, i in audiosList" :key="i" style="margin:10px; padding:20px; height:unset; background-color: #fff;">
            <v-row justify="center" align="center">
                <v-col cols="12" style="color:#00128C; font-weight:700">{{ decodeURI(getFileName(obj)) }}</v-col>
                <v-col><audio width="100%" height="auto" controls
                        :controlsList="makeAllAttachmentDownloadable ? download : nodownload">
                        <source :src="obj" />
                        Your browser does not support the video tag.
                    </audio></v-col>
                <br>

            </v-row>
        </inputContainer>

        <inputContainer v-if="link && link.trim() !== ''" style="margin:10px; padding:20px; height:unset; background-color: #fff;">
            <v-row>
                <v-col :cols="isMobileView ? 12 : 6">
                    <div style="justify-self: start; color:#00128C;  font-weight:700; color:#00128C">
                        <a :href="link">{{ link }}</a>
                    </div>
                </v-col>
                <v-col>
                    <div style="justify-self: end;" :cols="isMobileView ? 12 : 6">
                        <v-btn elevation="2" rounded style="color:#FF4F1F" small
                            @click="copyLink(link)"><v-icon>mdi-content-copy</v-icon> Copy
                            Link</v-btn>
                    </div>
                </v-col>
            </v-row>
        </inputContainer>

        <inputContainer v-for="obj, i in videosList" :key="i" style="margin:10px; padding:20px; height:unset; background-color: #fff;">
            <v-row>
                <v-col :cols="isMobileView ? 12 : 8">
                    <span style="justify-self: start; color:#00128C;  font-weight:700">
                        <span color="#00128C">{{ decodeURI(getFileName(obj)) }}</span>
                    </span>
                </v-col>

            </v-row>
            <br>
            <video width="100%" height="auto" controls
                :controlsList="makeAllAttachmentDownloadable ? download : nodownload">
                <source :src="obj" />
                Your browser does not support the video tag.
            </video>
        </inputContainer>
        <br>
        <v-carousel style="padding: 0 30px;" v-if="imagesList.length > 0" cycle hide-delimiter-background show-arrows-on-hover>
            <v-carousel-item v-for="(image, i) in imagesList" :key="i">
                <v-img :src="image"></v-img>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>
<script>
import inputContainer from '../inputContainer.vue';
import { v4 } from "uuid"
export default {
    name: 'DiscussionDownloadList',
    components: { inputContainer },
    props: {
        attachedFiles: Array,
        link: String,
        mediaLibrarySelects: Array,
        makeAllAttachmentDownloadable: Boolean
    },
    data() {
        return {
            imagesList: [],
            videosList: [],
            audiosList: [],
            pdfList: [],
            otherMediaList: [],
            uuid: v4,
            isMobileView: false,
            docLink: '',
            isDocModalOpen: false
        }
    },
    created() {
        this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
        this.attachedFiles.forEach(file => {
            if (file.type.split("/")[0] === "audio") {
                this.audiosList.push(file.url)
            } else if (file.type.split("/")[0] === "Video") {
                this.videosList.push(file.url)
            } else if (file.type.split("/")[0] === "image") {
                this.imagesList.push(file.url)
            } else if (file.type === "application/pdf") {
                this.pdfList.push(file.url)
            } else {
                this.otherMediaList.push(file.url)
            }
        })
        this.mediaLibrarySelects.forEach(file => {
            if (file.fileType.split("/")[0] === "audio") {
                file.linksOfAttachments !== "" && this.audiosList.push(file.linksOfAttachments)
            } else if (file.fileType.split("/")[0] === "video") {
                file.linksOfAttachments !== "" && this.videosList.push(file.linksOfAttachments)
            } else if (file.fileType.split("/")[0] === "image") {
                file.linksOfAttachments !== "" && this.imagesList.push(file.linksOfAttachments)
            } else if (file.fileType === "application/pdf") {
                file.linksOfAttachments !== "" && this.pdfList.push(file.linksOfAttachments)
            } else {
                file.linksOfAttachments !== "" && this.otherMediaList.push(file.linksOfAttachments)
            }
        })
    },
    methods: {
        getFileName(url) {
            if (url.includes("digitaloceanspaces")) {
                return url.split("/").slice(-1)[0]
            } else {
                const decodedUrl = decodeURIComponent(url);
                const lastIndexOfDelimiter = decodedUrl.lastIndexOf("________");
                const indexOfQueryParams = decodedUrl.lastIndexOf("?alt");
                return decodedUrl.substring(
                    lastIndexOfDelimiter + "________".length,
                    indexOfQueryParams
                )
            }
        },
        openFile(url) {
            window.open(url, '_blank');
        },
        copyLink(url) {
            console.log(url);
            window.navigator.clipboard.writeText(url)
        },
        openFileInPdfViewer(attachment) {
            console.log(attachment);
            const PDF_VIEWER = new URL('https://pdf-viewer-seven.vercel.app/')
            PDF_VIEWER.searchParams.set('pdf', attachment)
            console.log(PDF_VIEWER);
            this.docLink = PDF_VIEWER
            this.isDocModalOpen = true
        }
    }
}
</script>
<style scoped>
.framePdf {
    width: 100%;
    height: 100vh;
    background-color: white;
}
</style>
