import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/OnlineLecture'

class OnlineLecture {
  constructor (context) {
    this.context = context
  }

  async createOnlineLecture (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createOnlineLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async createOnlineLectureForStudents (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.createOnlineLectureForStudents, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async createOnlineLectureForStudentsNew (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.createOnlineLectureForStudentsNew, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async getIsOngoingForALecture (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getIsOngoingForALecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.isOngoing
  }

  async getIsOngoingForALectures (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getIsOngoingForALectures, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.isOngoing
  }

  async getAttendanceOfAnOnlineLecture (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAttendanceOfAnOnlineLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getRecordingOfAnOnlineLecture (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getRecordingOfAnOnlineLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.recordedVideoLink
  }

  async updateOnlineLecture (data) {
    console.log(data)
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.putRequest(ApiNames.updateOnlineLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
  }
}

export default OnlineLecture
