<template src='./activityChangeSemester.html'>
</template>

<script>
import TimetableRepository from '../../Repository/timetable'
import SubjectsRepository from '../../Repository/Subjects'
import SemesterUserRepository from '../../Repository/SemesterUser'
import SemesterRepository from '../../Repository/Semester'
import showStatus from '../../NetworkManager/showStatus'
import { subjectTypes } from '../../Constants/Utils/Statics'
export default {
  name: 'activityChangeSemester',
  data() {
    return {
      subjectTypes: subjectTypes,
      isMobileView: false,
      alignment: 'center',
      justify: 'center',
      userData: null,
      selectedInstitute: '',
      selectedSemester: '',
      semesters: [],
      currentlyChosenSemester: '',
      isLoading: false
    }
  },
  created() {
    this.$store.commit('liveData/set_selectedActivity', 7)
    this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    this.$store.commit('liveData/set_selectedActivityName', 'Select Semester')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.timetableRepositoryInstance = new TimetableRepository(this)
    this.subjectsRepositoryInstance = new SubjectsRepository(this)
    this.currentlyChosenSemester = this.selectedSemester.semId
    console.log(this.selectedSemester)
    console.log('this.selectedSemester', this.selectedSemester);
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          uId: this.userData.uId
        }
        try {
          this.semesters = await this.semesterUserRepositoryInstance.getSemesterUsersForAUser(objToPush)
          this.semesters.map((data) => {
            data['subjectAllocatedForDiv'] = data.subjects
          })
        } catch (err) {
          console.log(err)
          this.semesters = []
        }

        const semIdToSemNameMapping = {}
        try {
          const semesterNamesArray = await this.semesterRepositoryInstance.getNamesOfSemesters(objToPush)
          semesterNamesArray.forEach((semesterNameObject) => {
            semIdToSemNameMapping[semesterNameObject.semId] = semesterNameObject.semName
          })
        } catch (err) {
          console.log(err)
        }
        if (this.selectedSemester) {
          console.log('this.selectedSemesteractivityChangeSem', this.selectedSemester);
          await this.getSubjectsFromServer()
        }
        this.semesters.forEach((semester) => {
          if (semIdToSemNameMapping[semester.semId]) {
            semester.semName = semIdToSemNameMapping[semester.semId]
          } else {
            semester.semName = ''
          }
        })
        this.semesters = this.semesters.slice()
        if (this.semesters.length <= 1) {
          this.chooseAndUpdateSemester(this.semesters[0])
        }
      } catch (e) {
        showStatus('An error occured', 3000, 'error', this)
      } finally {
        this.isLoading = false
      }
      // this.highlight ()
    },
    async chooseAndUpdateSemester(semesterObject) {
      this.userData = semesterObject
      this.selectedSemester = semesterObject
      await this.getSubjectsFromServer()
      this.currentlyChosenSemester = semesterObject.semId
      this.$store.dispatch('user/set_userData', semesterObject)
      this.$store.commit('instituteData/set_selectedSemester', semesterObject)
      this.$store.commit("mappings/reset");
      showStatus('Updation successful', 1000, 'success', this)
      setTimeout(() => {
        this.$store.commit('liveData/set_selectedActivity', 2)
        this.$router.push('/activityMySubjects')
      }, 100)
    },
    async getSubjectsFromServer() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId
      };
      let subjectObjects;
      console.log('objToPush sss', objToPush);
      try {
        subjectObjects = await this.timetableRepositoryInstance.getAllocationsOfAFaculty(
          objToPush
        );
      } catch (err) {
        console.log(err);
        subjectObjects = [];
      }
      const subjectIds = subjectObjects.map(item => item.subjectId);
      try {
        const getsubjectObjToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectIds: subjectIds
        }
        const subjects = await this.subjectsRepositoryInstance.getSubjectDetails(
          getsubjectObjToPush
        );
        subjects.map((obj) => {
          obj.subjectType = this.subjectTypes[obj.subjectType];
          this.$store.commit('mappings/add_subjectsMapping', obj)
        })
      } catch (err) {
        console.log(err);
      }
    }
  }
}
</script>

<style src='./activityChangeSemester.css'>

</style>
