/* eslint-disable */
import * as XLSX from 'xlsx/xlsx.mjs'
import * as ExcelJS from 'exceljs'


export const isStringEmptyOrNull = (str) => {
  try {
    if (str === null || str === undefined) return true
    return str?.trim() === ''
  } catch (error) {
    return false
  }
}

export const isValidDate = (dateStr) => {
  if (dateStr === undefined || dateStr === null) return false
  try {
    const date = new Date(dateStr)
    return date.getTime() === date.getTime()
  } catch (error) {
    return false
  }
}


export function createExcel(tableId) {
  // Acquire Data (reference to the HTML table)
  var table_elt = document.getElementById(tableId)
  // Extract Data (create a workbook object from the table)

  var workbook = XLSX.utils.table_to_book(table_elt)
  // Process Data (add a new row)
  var ws = workbook.Sheets['Sheet1']
  XLSX.utils.sheet_add_aoa(ws, [['Created Date of Excel :- ' + new Date()]], {
    origin: -1,
  })

  // Package and Release Data (`writeFile` tries to write and save an XLSB file)
  XLSX.writeFile(workbook, 'table_Data.xlsx')
}

export function createExcelThroughJson(data, fileName) {
  // Create a new workbook
  const wb = XLSX.utils.book_new()

  // Add a new worksheet to the workbook
  const ws = XLSX.utils.json_to_sheet(data)

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

  // Generate the Excel file and download it
  if (!fileName) {
    XLSX.utils.sheet_add_aoa(ws, [['Created Date of Excel :- ' + new Date()]], {
      origin: -1,
    })
    XLSX.writeFile(wb, 'table_Data.xlsx')
  } else {
    XLSX.writeFile(wb, `${fileName}.xlsx`)
  }

  // // Extract Data (create a workbook object from the table)
  // var workbook = XLSX.utils.table_to_book(table_elt);
  // console.log('workbook', workbook);
  // // Process Data (add a new row)
  // var ws = workbook.Sheets["Sheet1"];
  // XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

  // // Package and Release Data (`writeFile` tries to write and save an XLSB file)
  // XLSX.writeFile(workbook, "table_Data.xlsx");
}
export function createExcelThroughJsonForMultipleSheets(data, fileName) {
  // Create a new workbook
  const wb = XLSX.utils.book_new()

  // Add a new worksheet to the workbook)
  let ws = null
  for (let i = 0; i < data.length; i++) {
    ws = XLSX.utils.json_to_sheet(data[i][Object.keys(data[i])[0]])
    XLSX.utils.book_append_sheet(wb, ws, Object.keys(data[i])[0])
  }

  // Add the worksheet to the workbook

  // Generate the Excel file and download it
  if (!fileName) {
    XLSX.utils.sheet_add_aoa(ws, [['Created Date of Excel :- ' + new Date()]], {
      origin: -1,
    })
    XLSX.writeFile(wb, 'table_Data.xlsx')
  } else {
    XLSX.writeFile(wb, `${fileName}.xlsx`)
  }

  // // Extract Data (create a workbook object from the table)
  // var workbook = XLSX.utils.table_to_book(table_elt);
  // console.log('workbook', workbook);
  // // Process Data (add a new row)
  // var ws = workbook.Sheets["Sheet1"];
  // XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

  // // Package and Release Data (`writeFile` tries to write and save an XLSB file)
  // XLSX.writeFile(workbook, "table_Data.xlsx");
}
function tableToJson(table) {
  var data = []
  for (var i = 1; i < table.rows.length; i++) {
    var tableRow = table.rows[i]
    var rowData = []
    for (var j = 0; j < tableRow.cells.length; j++) {
      rowData.push(tableRow.cells[j].innerHTML)
    }
    data.push(rowData)
  }
  return data
}

export async function generateFromJson(schema) {
  const workbook = new ExcelJS.Workbook()
  Object.keys(schema).forEach((sheetName, i) => {
    workbook.addWorksheet(sheetName)
    const worksheet = workbook.getWorksheet(sheetName)
    worksheet.properties.defaultColWidth = 35
    worksheet.getRow(1).height = 30

    let currentValidationColumn = 'A'

    const addListValidationColumn = (listArr) => {
      const testValidationWorksheet = workbook.getWorksheet('LOOKUPS')
      if (
        testValidationWorksheet === null ||
        testValidationWorksheet === undefined
      )
        workbook.addWorksheet('LOOKUPS')
      const validationWorksheet = workbook.getWorksheet('LOOKUPS')
      validationWorksheet.properties.defaultColWidth = 35
      for (let i = 0; i < listArr.length; i++) {
        const cell = validationWorksheet.getCell(
          `${currentValidationColumn}${i}`
        )
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true,
        }
        cell.value = listArr[i]
        cell.protection = {
          locked: true,
        }
      }
      const formulae = [
        `LOOKUPS!$${currentValidationColumn}$1:$${currentValidationColumn}$${listArr.length}`,
      ]
      String.fromCharCode(currentValidationColumn.charCodeAt(0) + 1)
      return formulae
    }

    const getFormulae = (listArr) => {
      const formulaeString = `"${Array.isArray(listArr) ? listArr.join(',') : ''
        }"`
      if (formulaeString.length <= 255) return formulaeString
      return addListValidationColumn(listArr)
    }

    Object.keys(schema[sheetName]).forEach((head, i) => {
      // 65 = A Alphabet
      const key = String.fromCharCode(65 + i)
      const cell = worksheet.getCell(`${key}1`)
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      }
      cell.value = head
      cell.protection = {
        locked: true,
      }

      if (schema[sheetName][head].validation !== undefined) {
        const { type, values, required } = schema[sheetName][head].validation

        if (type !== undefined) {
          let formulae = ''
          const allowBlank = required !== undefined ? required : false

          if (required) {
            cell.font = {
              name: 'Calibri',
              color: { argb: 'ffff6347' },
              bold: true,
            }
          }

          let obj = {}

          if (type === 'list') {
            //formulae = `"${Array.isArray(values) ? values.join(',') : ''}"`
            formulae = getFormulae(values)
          }
          if (type === 'textLength') {
            formulae = 0
            obj['operator'] = 'greaterThan'
          }
          if (type === 'decimal') {
            formulae = 0
            obj['operator'] = 'greaterThanOrEqual'
          }
          if (type === 'whole') {
            formulae = 0
            obj['operator'] = 'greaterThanOrEqual'
          }

          worksheet.dataValidations.add(`${key}2:${key}9999`, {
            ...obj,
            type: type,
            allowBlank,
            formulae: [formulae],
            showErrorMessage: true,
          })
        }
      }
    })
  })
  const testValidationWorksheet = workbook.getWorksheet('LOOKUPS')
  if (
    !(testValidationWorksheet === null || testValidationWorksheet === undefined)
  ) {
    //testValidationWorksheet.protect('studium-excel')
    testValidationWorksheet.state = 'veryHidden'
  }

  const buffer = Buffer.from(await workbook.xlsx.writeBuffer())
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  var csvURL = window.URL.createObjectURL(blob)
  return csvURL
  // const tempLink = document.createElement('a')
  // tempLink.href = csvURL
  // tempLink.setAttribute('download', 'filename.xlsx')
  // tempLink.click()
}

export async function generateFromJsonWithSideHeading(schema) {
  const workbook = new ExcelJS.Workbook()

  Object.keys(schema).forEach((sheetName) => {
    workbook.addWorksheet(sheetName)
    const worksheet = workbook.getWorksheet(sheetName)
    worksheet.properties.defaultColWidth = 35
    worksheet.getRow(1).height = 30

    if (schema[sheetName].sideHeadings) {
      schema[sheetName].sideHeadings.forEach((sideHeading, i) => {
        const sideCell = worksheet.getCell(`A${i + 2}`)
        sideCell.alignment = {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true,
        }
        sideCell.value = sideHeading
        sideCell.protection = {
          locked: true,
        }
      })
    }

    Object.keys(schema[sheetName].topHeadings).forEach((head, i) => {
      const key = String.fromCharCode(65 + i)
      const cell = worksheet.getCell(`${key}1`)
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      }
      cell.value = head
      cell.protection = {
        locked: true,
      }
    })
  })

  const buffer = Buffer.from(await workbook.xlsx.writeBuffer())
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  var csvURL = window.URL.createObjectURL(blob)
  return csvURL
}

export async function generateJsonFromExcel(
  file,
  schema,
  isSideHeaded = false
) {
  try {
    const workbook = new ExcelJS.Workbook()
    await workbook.xlsx.load(await file.arrayBuffer())

    const data = {}

    workbook.worksheets.forEach((wk) => {
      data[wk.name] = []

      const headers = wk.getRow(1).values ?? []
      const headerLen = headers.length

      if (headerLen > 0) {
        wk.eachRow((row, i) => {
          let check = 0;
          row.values.forEach((x) => {
            // if (x === '') {
            //   check++;
            // }
          });
          if (check === 0) {
            if (i !== 1) {
              const lol = {}
              const values = [...row.values]
              const len = values.length

              headers.map((v, j) => {
                lol[v] = j > len ? undefined : values[j]
              })

              data[wk.name].push(
                typeof lol === 'CellHyperlinkValue' ? lol.text : lol
              )
            }
          }
        })
      }
    })
    return isSideHeaded ? transformer2(data, schema) : transformer(data, schema)
  } catch (error) {
    console.error(error)
  }
  return { errors: [], data: {} }
}

const transformer = (data, schema) => {
  const errors = []
  const finalObj = {}

  try {
    const mapping = getMapping(schema)

    Object.keys(data).forEach((workSheet) => {
      finalObj[workSheet] = []
      Array.isArray(data[workSheet]) &&
        data[workSheet].forEach((row, i) => {
          let obj = {}
          Object.keys(row).forEach((key) => {
            if (key !== 'undefined') {
              const { validation } = schema[workSheet][key]
              if (validation) {
                const { type, values, required } = validation
                if (type === 'date' && isValidDate(row[key])) {
                  row[key] = new Date(row[key])
                }
                if (type === 'multiple') {
                  row[key] = isStringEmptyOrNull(row[key])
                    ? []
                    : row[key].split(',')
                }
                if (required === true) {
                  if (type === 'textLength' && isStringEmptyOrNull(row[key])) {
                    errors.push(
                      `Missing '${key}' on Line No ${i + 2
                      } in sheet '${workSheet}'`
                    )
                  }

                  if (type === 'list' && Array.isArray(values)) {
                    if (isStringEmptyOrNull(row[key])) {
                      errors.push(
                        `Missing '${key}' on Line No ${i + 2
                        } in sheet '${workSheet}'`
                      )
                    } else {
                      if (!values.includes(row[key])) {
                        errors.push(
                          `Please Select Valid Value from Dropdown '${key}' on Line No ${i + 2
                          } in sheet '${workSheet}'`
                        )
                      }
                    }
                  }

                  if (type === 'date' && !isValidDate(row[key])) {
                    errors.push(
                      `Please Enter Valid '${key}' on Line No ${i + 2
                      } in sheet '${workSheet}'`
                    )
                  }

                  if (type === 'multiple') {
                    if (Array.isArray(row[key]) && row[key].length === 0) {
                      errors.push(
                        `Please Enter Valid '${key}' on Line No ${i + 2
                        } in sheet '${workSheet}'`
                      )
                    }
                  }
                }
              }

              const newKey =
                mapping[workSheet] && mapping[workSheet][key]
                  ? mapping[workSheet][key]
                  : key

              obj[newKey] = row[key]
            }
          })

          finalObj[workSheet].push(obj)
        })
    })
    console.log({ errors })
    return { errors, data: finalObj }
  } catch (error) {
    console.error(error)
  }
  return { errors, data: finalObj }
}
const transformer2 = (data, schema) => {
  const finalObj = {}

  try {
    const mapping = getMapping(schema)
    Object.keys(data).forEach((workSheet) => {
      finalObj[workSheet] = []
      Array.isArray(data[workSheet]) &&
        data[workSheet].forEach((row, i) => {
          let obj = {}
          Object.keys(row).forEach((key) => {
            if (key !== 'undefined') {
              const newKey =
                mapping[workSheet] && mapping[workSheet][key]
                  ? mapping[workSheet][key]
                  : key

              obj[newKey] = row[key]
            }
          })

          finalObj[workSheet].push(obj)
        })
    })
    return { data: finalObj }
  } catch (error) {
    console.error(error)
  }
  return { data: finalObj }
}

const getMapping = (schema) => {
  const mapping = {}
  try {
    Object.keys(schema).forEach((worksheet) => {
      mapping[worksheet] = {}
      Object.keys(schema[worksheet]).forEach((field) => {
        mapping[worksheet][field] =
          schema[worksheet][field]?.variableName ?? field
      })
    })
  } catch (error) {
    console.error(error)
  }
  return mapping
}
