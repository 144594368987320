<template src="./activitySubjectCourses.html">
</template>
<script>
import SubjectRepository from "../../Repository/Subjects";
import UploadToDigitalOceanRepository from "../../Repository/UploadToDigitalOcean";
import CourseRepository from "../../Repository/courses";
import auth from "../../Services/Firebase/auth";
import showStatus from "../../NetworkManager/showStatus";
import draggable from "vuedraggable";
import axios from "axios";
import CoursesData from "../../Repository/mediaLibrary";
import { v4 } from "uuid";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: "activitySubjectCourses",
    props: ["prop_subject", "prop_selectedSemester"],
    order: 0,
    components: {
        draggable
    },
    data() {
        return {
            currSubject: {},
            activeScreenTab: "basicDetails",
            courseDetails: {},
            courseImageFileName: "",
            icons: {
                edit: "mdi-pencil"
            },
            img: {},
            //Edit section variables
            editImage: "",
            editCourseName: "",
            editCourseDescription: "",
            editApproximateTime: "",
            editCompetencyLevel: "",
            editPrerequisite: "",
            //Rich Text Editor
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "|",
                        "bulletedList",
                        "numberedList",
                        "outdent",
                        "indent",
                        "|",
                        "undo",
                        "redo"
                    ],
                    shouldNotGroupWhenFull: true
                }
            },
            //Selection arr
            arrCompetenceLevel: ["Beginner", "Intermediate", "Advanced"],
            subsectionAssignmentType: [
                "Assignment",
                "Audio",
                "Video",
                "Document",
                "Quiz",
                "Feedback"
            ],
            fileTypesToSelect: ["PDF", "Video", "Audio", "Excel", "Image", "Any"],
            //Modules Tab
            activeModule: 0,
            ediSectionsArr: [],
            currentUploadProgress: -1,
            addNewSubSection: false,
            addNewModule: false,
            //Modals
            uploadQuestionExcelDialog: false,
            quizQuestionExcelFile: null,
            addingQuestion: false,
            showFeedbackModal: false,
            settingsDialog: false,
            tempQuestion: {
                answer: "",
                imageForQuestion: "",
                imageForSolution: Array(0),
                marks: "",
                negativeMarks: "0",
                options: Array(6).fill(null),
                question: "",
                questionNumber: 1,
                solution: "",
                type: "mcq",
                isQuestionEdit: false,
                subsectionNo: -1,
                questionNo: -1
            },
            tempSubSection: {
                allowedFileTypeStudents: [],
                allowedFiles: ".mp3,.mp4, .mkv, .3gp,.jpg, .jpeg,.pdf,.doc,.docx",
                contentType: "Assignment",
                creditOfquiz: "",
                durationOfquiz: "",
                feedbackArr: [],
                hyperlink: "",
                isSubsectionPublished: false,
                isreAttemptAllowed: false,
                mediaLibFiles: null,
                mediaLibOptions: [],
                passingPercentage: null,
                pcFiles: null,
                ploadQuestionExcel: "",
                questions: [],
                selectedQuestions: [],
                solutionFile: [],
                subSectionFileUrl: "",
                subSectionId: "",
                subsectionCredit: ""
            },
            tempFeedbackQuestion: {
                feedbackOptions: Array(4).fill(null),
                feedbackQuestion: "",
                feedbackType: "",
                subsectionNo: -1,
                questionNo: -1,
                isSubsectionEdit: false
            },
            tempNewModule: {
                isSectionPublished: false,
                sectionDescription: "",
                sectionTitle: "",
                subSections: []
            },
            tempCoursesSettings: {
                jumpBetweenSection: false,
                jumpBetweenSubsection: false,
                courseFeedback: false,
                courseCertification: false,
                videoTimeline: false,
                audioTimeline: false,
                pdfDownload: false
            },
            //
            currentEditingQuestion: null,
            //Preview
            activePreviewModule: 0,
            coursePreview: {},
            activePreviewSubsection: -1,
            uploadSolutionImage: null,
            isLoading: true,
            activeModuleSubsection: -1,
            logChanges: {},
            userName: "",
            showPublishModal: false,
            displayPublishLogs: {}
        };
    },
    methods: {
        editSettings() {
            this.tempCoursesSettings.audioTimeline = JSON.parse(
                JSON.stringify(this.courseDetails.audioTimeline)
            );
            this.tempCoursesSettings.courseCertification = JSON.parse(
                JSON.stringify(this.courseDetails.courseCertification)
            );
            this.tempCoursesSettings.courseFeedback = JSON.parse(
                JSON.stringify(this.courseDetails.courseFeedback)
            );
            this.tempCoursesSettings.jumpBetweenSection = JSON.parse(
                JSON.stringify(this.courseDetails.jumpBetweenSection)
            );
            this.tempCoursesSettings.jumpBetweenSubsection = JSON.parse(
                JSON.stringify(this.courseDetails.jumpBetweenSubsection)
            );
            this.tempCoursesSettings.pdfDownload = JSON.parse(
                JSON.stringify(this.courseDetails.pdfDownload)
            );
            this.tempCoursesSettings.videoTimeline = JSON.parse(
                JSON.stringify(this.courseDetails.videoTimeline)
            );

            this.settingsDialog = true;
        },
        async saveNewSettings() {
            this.courseDetails.audioTimeline = JSON.parse(
                JSON.stringify(this.tempCoursesSettings.audioTimeline)
            );
            this.courseDetails.courseCertification = JSON.parse(
                JSON.stringify(this.tempCoursesSettings.courseCertification)
            );
            this.courseDetails.courseFeedback = JSON.parse(
                JSON.stringify(this.tempCoursesSettings.courseFeedback)
            );
            this.courseDetails.jumpBetweenSection = JSON.parse(
                JSON.stringify(this.tempCoursesSettings.jumpBetweenSection)
            );
            this.courseDetails.jumpBetweenSubsection = JSON.parse(
                JSON.stringify(this.tempCoursesSettings.jumpBetweenSubsection)
            );
            this.courseDetails.pdfDownload = JSON.parse(
                JSON.stringify(this.tempCoursesSettings.pdfDownload)
            );
            this.courseDetails.videoTimeline = JSON.parse(
                JSON.stringify(this.tempCoursesSettings.videoTimeline)
            );

            this.courseDetails.subjectId = this.courseDetails.courseId;
            // const res = await this.CourseRepositoryInstance.updateCourseInfo(
            //     this.courseDetails
            // );
            showStatus("Course Updated Successfully", 3000, "success", this);
            this.fetchData();

            this.settingsDialog = false;
        },
        handleTimeUpdate(e, subsection, test) {
            const check =
                test === "video"
                    ? this.courseDetails.videoTimeline
                    : this.courseDetails.audioTimeline;
            if (!check) {
                const video = e.target;
                if (!video.seeking) {
                    subsection.supposedCurrentTime = video.currentTime;
                }
            }
        },
        handleSeeking(e, subsection, test) {
            const check =
                test === "video"
                    ? this.courseDetails.videoTimeline
                    : this.courseDetails.audioTimeline;
            if (!check) {
                const video = e.target;
                const delta = video.currentTime - subsection.supposedCurrentTime;
                if (Math.abs(delta) > 0.01) {
                    console.log("Seeking is disabled");
                    video.currentTime = subsection.supposedCurrentTime;
                }
            }
        },
        getPdfDocLink(url) {
            if (!this.courseDetails.pdfDownload) {
                return url + "#toolbar=0";
            } else return url;
        },
        disableContext() {
            return false;
        },
        openFeedbackModal(idxS) {
            this.tempFeedbackQuestion = {
                feedbackOptions: [null, null, null, null],
                feedbackQuestion: "",
                feedbackType: "Rating",
                subsectionNo: idxS,
                questionNo: -1,
                isSubsectionEdit: false
            };
            this.showFeedbackModal = true;
        },
        async changeActiveScreenTab(screen) {
            this.activeScreenTab = screen;
        },
        onSubjectExpansionPanelClick(e, sub) {
            if (
                !e.currentTarget.classList.contains("v-expansion-panel-header--active")
            ) {
                this.activePreviewSubsection = sub;
            } else this.activePreviewSubsection = -1;
        },
        async editBasicDetails() {
            this.editImage = this.courseImageFileName
            this.editCourseName = this.courseDetails.courseName
            this.editCourseDescription = this.courseDetails.courseDescription
            this.editApproximateTime = this.currSubject.tutorialHrs
            this.editCompetencyLevel = this.currSubject.competenceLevel
            this.editPrerequisite = this.currSubject.prerequisite;
            this.activeScreenTab = "basicDetailsEdit";
        },
        async cancelAddNewModule() {
            this.tempNewModule = {
                isSectionPublished: false,
                sectionDescription: "",
                sectionTitle: "",
                subSections: []
            };
            this.addNewModule = false;
        },
        handleAddNewModule() {
            this.ediSectionsArr.push(this.tempNewModule);
            this.tempNewModule = {
                isSectionPublished: false,
                sectionDescription: "",
                sectionTitle: "",
                subSections: []
            };
            this.addNewModule = false;
        },
        async fetchData() {
            this.isLoading = true;
            const objToPush = {
                instituteId: this.selectedSubject.instituteId,
                semId: this.selectedSubject.semId,
                department: this.selectedSubject.department,
                courseYear: this.selectedSubject.courseYear,
                subjectId: this.selectedSubject.subjectId,
                courseName: this.selectedSubject.subjectName,
                courseImage: this.courseImage
            };

            const subjectsTemp =
                await this.subjectRepositoryInstance.getDetailsOfASubject(objToPush);
            this.currSubject = subjectsTemp;

            let courseDetails = await this.CourseRepositoryInstance.getSingleCourse(
                objToPush
            );
            if (courseDetails.length === 0) {
                const objToPush2 = {
                    instituteId: this.selectedSubject.instituteId,
                    semId: this.selectedSubject.semId,
                    department: this.selectedSubject.department,
                    courseYear: this.selectedSubject.courseYear,
                    subjectId: this.selectedSubject.subjectId,
                    courseId: this.selectedSubject.subjectId,
                    courseName: this.selectedSubject.subjectName,
                    courseImage: this.courseImage,
                    courseCertification: false,
                    courseFeedback: false,
                    jumpBetweenSection: false,
                    jumpBetweenSubsection: false,
                    isCoursePublished: false,
                    videoTimeline: false,
                    audioTimeline: false,
                    pdfDownload: false,
                    sections: []
                };
                courseDetails = await this.CourseRepositoryInstance.updateCourseInfo(
                    objToPush2
                );
                this.courseDetails = courseDetails.course;
            } else this.courseDetails = courseDetails[0];
            this.coursePreview = JSON.parse(JSON.stringify(this.courseDetails));
            this.ediSectionsArr = this.courseDetails.sections;

            for (let i = 0; i < this.ediSectionsArr.length; i++) {
                const section = this.ediSectionsArr[i];
                for (let j = 0; j < section.subSections.length; j++) {
                    const subsection = section.subSections[j];
                    subsection.pcFiles = subsection.subSectionFileUrl
                        ? { name: this.getFileName(subsection.subSectionFileUrl) }
                        : null;
                    subsection.mediaLibFiles = null;
                    subsection.mediaLibOptions = await this.getMediaLibOptions(
                        subsection.contentType
                    );
                    subsection.allowedFiles = await this.getAllowedFiles(
                        subsection.contentType
                    );
                    subsection.selectedQuestions = Array(
                        subsection.questions.length
                    ).fill(false);
                    subsection.pcFileDisabled = false;
                    subsection.mediaLibFileDisabled =
                        !(subsection.pcFiles === null);
                }
            }

            this.img = this.courseDetails?.courseImage;

            let fileName;
            //File Extraction
            if (this.img !== null && this.img !== undefined && this.img !== "") {
                try {
                    const parsedUrl = new URL(this.img);
                    const pathname = parsedUrl.pathname;
                    let segments;
                    if (pathname.includes("________")) {
                        segments = pathname.split("________");
                    } else segments = pathname.split("/");
                    fileName = segments[segments.length - 1];
                    this.courseImageFileName = this.img
                        ? { name: fileName, isOriginal: true }
                        : {};
                } catch (e) {
                    console.error(e);
                }
            }

            const res2 = await this.CourseRepositoryInstance.getPublishLogs({
                instituteId: this.courseDetails.instituteId,
                courseId: this.courseDetails.courseId,
                limitPerSubsection: +this.numPreviousLogs
            })

            this.displayPublishLogs = res2;

            this.isLoading = false;
        },
        async handleAddQuestionImageForQuiz(e) {
            if (e === null) {
                return;
            }
            const signedUrlsData = await this.getSignedUrl(e, `${this.userData.uId}/documents/`)
            const signedUrl = signedUrlsData
            await this.uploadToSpaces(e, signedUrl)
            const link = 'https://render.file-workers.workers.dev/courses-bucket/' + e.name;
            this.tempQuestion.imageForQuestion = link
        },
        getFileName(url) {
            const parsedUrl = new URL(url);
            const pathname = parsedUrl.pathname;
            const segments = pathname.split("/");
            const fileName = segments[segments.length - 1];
            return fileName;
        },
        async getAllowedFiles(contentType) {
            if (contentType === "Assignment") {
                return ".mp3,.mp4, .mkv, .3gp,.jpg, .jpeg,.pdf,.doc,.docx";
            }
            if (contentType === "Audio") {
                return ".mp3";
            }
            if (contentType === "Video") {
                return ".mp4, .mkv, .3gp";
            }
            if (contentType === "Document") {
                return ".pdf";
            }
        },
        async refetchAllowedFiles(tempSubSection) {
            tempSubSection.mediaLibOptions = await this.getMediaLibOptions(
                tempSubSection.contentType
            );
            tempSubSection.allowedFiles = await this.getAllowedFiles(
                tempSubSection.contentType
            );
        },
        async handleAddSolutionFile(e, subsection) {
            if (e === null) {
                return;
            }
            const signedUrlsData = await this.getSignedUrl(
                e,
                `${this.userData.uId}/documents/`
            );
            const signedUrl = signedUrlsData;
            await this.uploadToSpaces(e, signedUrl);
            const link =
                "https://render.file-workers.workers.dev/courses-bucket/" + e.name;
            subsection.solutionFile = [link];
        },
        async cancelAddNewSubSection() {
            this.tempSubSection = {
                allowedFileTypeStudents: [],
                allowedFiles: ".mp3,.mp4, .mkv, .3gp,.jpg, .jpeg,.pdf,.doc,.docx",
                contentType: "Assignment",
                creditOfquiz: "",
                durationOfquiz: "",
                feedbackArr: [],
                hyperlink: "",
                isSubsectionPublished: false,
                isreAttemptAllowed: false,
                mediaLibFiles: null,
                mediaLibOptions: await this.getMediaLibOptions("Assignment"),
                passingPercentage: null,
                pcFiles: null,
                ploadQuestionExcel: "",
                questions: [],
                selectedQuestions: [],
                solutionFile: [],
                subSectionFileUrl: "",
                subSectionId: "",
                subsectionCredit: ""
            };
            this.addNewSubSection = false;
        },
        async handleNewSubsection() {
            this.tempSubSection.subSectionId = v4();
            this.ediSectionsArr[this.activeModule].subSections.push(
                this.tempSubSection
            );
            this.updateLogger(this.ediSectionsArr[this.activeModule], this.tempSubSection, "Create")
            this.tempSubSection = {
                allowedFileTypeStudents: [],
                allowedFiles: ".mp3,.mp4, .mkv, .3gp,.jpg, .jpeg,.pdf,.doc,.docx",
                contentType: "Assignment",
                creditOfquiz: "",
                durationOfquiz: "",
                feedbackArr: [],
                hyperlink: "",
                isSubsectionPublished: false,
                isreAttemptAllowed: false,
                mediaLibFiles: null,
                mediaLibOptions: await this.getMediaLibOptions("Assignment"),
                passingPercentage: null,
                pcFiles: null,
                ploadQuestionExcel: "",
                questions: [],
                selectedQuestions: [],
                solutionFile: [],
                subSectionFileUrl: "",
                subSectionId: "",
                subsectionCredit: ""
            };
            this.addNewSubSection = false;
        },
        async uploadFile(fileArr) {
            try {
                if (fileArr.length > 0) {
                    const fileName = fileArr.filter((item) => item.name);
                    const data = await this.UploadToDigitalOceanRepositoryInstance.upload(
                        fileName,
                        this.userData.uId
                    );
                    return data;
                }
            } catch (error) {
                console.log("error", error);
                const arr = [];
                return arr;
            }
        },
        async deleteSubSection(idxS) {
            const test = await this.$confirm("Confirm Delete Subsection ?");
            if (test) {
                this.updateLogger(this.ediSectionsArr[this.activeModule], this.ediSectionsArr[this.activeModule].subSections[idxS], "Delete")
                this.ediSectionsArr[this.activeModule].subSections.splice(idxS, 1);
            }
        },
        async updateCourseDetails() {
            try {
                const p1 = this.subjectRepositoryInstance.updateCompetenceAndPrequisite(
                    {
                        instituteId: this.prop_subject.instituteId,
                        semId: this.prop_subject.semId,
                        department: this.prop_subject.department,
                        courseYear: this.prop_subject.courseYear,
                        subjectId: this.prop_subject.subjectId,
                        competenceLevel: this.editCompetencyLevel,
                        prerequisite: this.editPrerequisite,
                        tutorialHrs: this.editApproximateTime
                    }
                );
                let uploadedFile;
                if (this.editImage.isOriginal) {
                    uploadedFile = this.prop_subject.courseImage;
                } else {
                    const uFile = await this.uploadFile([this.editImage]);
                    console.log(uFile);
                    uploadedFile = uFile[0].url;
                }
                //const uploadedFile = await this.uploadFile([e])
                const p2 = this.CourseRepositoryInstance.updateCourseInfo({
                    instituteId: this.prop_subject.instituteId,
                    semId: this.prop_subject.semId,
                    department: this.prop_subject.department,
                    courseYear: this.prop_subject.courseYear,
                    subjectId: this.prop_subject.subjectId,
                    courseName:
                        this.prop_subject.courseName || this.prop_subject.subjectName,
                    courseImage: uploadedFile,
                    courseDescription: this.editCourseDescription
                });
                await Promise.all([p1, p2]);
                console.log(typeof this.editImage);
                showStatus("Course Image updated successfully", 3000, "success", this);
                await this.fetchData();
                this.activeScreenTab = "basicDetails";
            } catch (err) {
                showStatus(
                    "Unable to update course image . Please try again later",
                    3000,
                    "error",
                    this
                );
                console.log(err);
            }
        },
        async getMediaLibOptions(contentType) {
            const res = [];
            const objToPush = {
                instituteId: this.selectedSubject.instituteId,
                uId: this.userData.uId
            };
            const result = await this.CourseRepositoryInstance.getMediaLibrary(
                objToPush
            );
            result.map((obj) => {
                obj.file = obj.title + " " + obj.type;
                if (contentType === "Assignment") {
                    res.push(obj);
                }
                if (contentType === "Audio" && obj.type === "audio/mpeg") {
                    res.push(obj);
                }
                if (contentType === "Video" && obj.type === "video/mp4") {
                    res.push(obj);
                }
                if (contentType === "Document" && obj.type === "application/pdf") {
                    res.push(obj);
                }
            });
            return res;
        },
        async handleuploadSubsectionFile(subsection) {
            if (subsection.pcFiles === null) {
                return;
            }
            const signedUrlsData = await this.getSignedUrl(
                subsection.pcFiles,
                `${this.userData.uId}/documents/`
            );
            const signedUrl = signedUrlsData;
            await this.uploadToSpaces(subsection.pcFiles, signedUrl);
            const link =
                "https://render.file-workers.workers.dev/courses-bucket/" +
                subsection.pcFiles.name;
            console.log(link);
            subsection.subSectionFileUrl = link;
        },
        async handleMediaLibFilesChange(subsection) {
            if (subsection.mediaLibFiles === null) {
                return;
            }
            subsection.subSectionFileUrl =
                subsection.mediaLibFiles.linksOfAttachments;
        },
        async getSignedUrl(file, path = "") {
            const fileName = file.name;
            const body = {
                fileName: fileName,
                fileType: file.type,
                instituteId: this.selectedSubject.instituteId
            };
            const signedUrl = await this.mediaLibraryInstance.getPresignedUrlForR2(
                body
            );
            return signedUrl.url;
        },
        async uploadToSpaces(file, signedUrl) {
            const res = await this.futch(
                signedUrl,
                {
                    method: "PUT",
                    body: file
                },
                (event) => {
                    const progress = parseInt((event.loaded / event.total) * 100);
                    setTimeout(() => {
                        this.currentUploadProgress = progress;
                        if (progress > 99) {
                            this.currentlyUploadingNumber++;
                        }
                    }, 200);
                }
            );
            if (this.currentUploadProgress === 100) {
                setTimeout(() => {
                    this.loading = false;
                }, 1500);
            }
            console.log("this.currentUploadProgress", this.currentUploadProgress);
            return res;
        },
        futch(url, opts = {}, onProgress) {
            return new Promise((resolve, reject) => {
                var xhr = new XMLHttpRequest();
                xhr.open(opts.method || "get", url);
                for (var k in opts.headers || {}) {
                    xhr.setRequestHeader(k, opts.headers[k]);
                }
                xhr.onload = (e) => resolve(e.target.responseText);
                xhr.onerror = reject;
                if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
                xhr.send(opts.body);
            });
        },
        async startUploadingQuestionExcel(subsection) {
            if (this.quizQuestionExcelFile) {
                var formData = new FormData();
                formData.append("excel", this.quizQuestionExcelFile);
                const response = await axios.post(
                    process.env.VUE_APP_SERVER_URL +
                    process.env.VUE_APP_API_PORT +
                    "/excel/courses/parse",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                );
                subsection.questions = response.data.questions;
            }
            this.quizQuestionExcelFile = null;
            this.uploadQuestionExcelDialog = false;
        },
        cancelAddEditQuestion() {
            this.tempQuestion = {
                answer: "",
                imageForQuestion: "",
                imageForSolution: Array(0),
                marks: "",
                negativeMarks: "0",
                options: Array(6).fill(null),
                question: "",
                questionNumber: 1,
                solution: "",
                type: "mcq",
                isQuestionEdit: false
            };
            this.uploadSolutionImage = null;
            this.addingQuestion = false;
        },
        editQuestion(idxS, idxQ, question) {
            this.tempQuestion = question;
            if (!this.tempQuestion.imageForQuestion) {
                this.tempQuestion.imageForQuestion = "";
                this.uploadSolutionImage = null;
            } else {
                if (this.tempQuestion.imageForQuestion !== null && this.tempQuestion.imageForQuestion !== undefined && this.tempQuestion.imageForQuestion !== "") {
                try {
                    const parsedUrl = new URL(this.tempQuestion.imageForQuestion);
                    const pathname = parsedUrl.pathname;
                    let segments;
                    if (pathname.includes('________')) {
                        segments = pathname.split('________');
                    } else segments = pathname.split('/');
                    const fileName = segments[segments.length - 1];
                    this.uploadSolutionImage = this.tempQuestion.imageForQuestion ? { name: fileName, isOriginal: true } : {}
                } catch (e) {
                    console.error(e)
                }
            }
            }
            this.tempQuestion.isQuestionEdit = true;
            this.tempQuestion.subsectionNo = idxS;
            this.tempQuestion.questionNo = idxQ;
            this.addingQuestion = true;
        },
        editFeedbackQuestion(idxS, idxFQ, question) {
            this.tempFeedbackQuestion = question;
            this.tempFeedbackQuestion.isSubsectionEdit = true
            this.tempFeedbackQuestion.subsectionNo = idxS
            this.tempFeedbackQuestion.questionNo = idxFQ
            this.showFeedbackModal = true
        },
        cancelEditFeedbackQuestion() {
            this.tempFeedbackQuestion = {
                feedbackOptions: [null, null, null, null],
                feedbackQuestion: "",
                feedbackType: "Rating",
                subsectionNo: -1,
                questionNo: -1,
                isSubsectionEdit: false
            };
            this.showFeedbackModal = false;
        },
        saveFeedbackQuestion() {
            if (this.tempFeedbackQuestion.isSubsectionEdit) {
                this.ediSectionsArr[this.activeModule].subSections[
                    this.tempFeedbackQuestion.subsectionNo
                ].feedbackArr[this.tempFeedbackQuestion.questionNo] = {
                    feedbackOptions: this.tempFeedbackQuestion.feedbackOptions,
                    feedbackQuestion: this.tempFeedbackQuestion.feedbackQuestion,
                    feedbackType: this.tempFeedbackQuestion.feedbackType,
                    questionNumber: -1
                };
            } else {
                this.ediSectionsArr[this.activeModule].subSections[
                    this.tempFeedbackQuestion.subsectionNo
                ].feedbackArr.push({
                    feedbackOptions: this.tempFeedbackQuestion.feedbackOptions,
                    feedbackQuestion: this.tempFeedbackQuestion.feedbackQuestion,
                    feedbackType: this.tempFeedbackQuestion.feedbackType,
                    questionNumber: -1
                });
            }
            this.showFeedbackModal = false;
        },
        saveTempQuestion() {
            if (this.tempQuestion.isQuestionEdit) {
                this.ediSectionsArr[this.activeModule].subSections[
                    this.tempQuestion.subsectionNo
                ].questions[this.tempQuestion.questionNo] = {
                    answer: this.tempQuestion.answer,
                    imageForSolution: this.tempQuestion.imageForSolution,
                    marks: this.tempQuestion.marks,
                    negativeMarks: this.tempQuestion.negativeMarks,
                    options: this.tempQuestion.options,
                    question: this.tempQuestion.question,
                    questionNumber: this.tempQuestion.questionNo,
                    solution: this.tempQuestion.solution,
                    type: this.tempQuestion.type,
                    imageForQuestion: this.tempQuestion.imageForQuestion
                };
            } else {
                this.ediSectionsArr[this.activeModule].subSections[
                    this.tempQuestion.subsectionNo
                ].questions.push({
                    answer: this.tempQuestion.answer,
                    imageForSolution: this.tempQuestion.imageForSolution,
                    marks: this.tempQuestion.marks,
                    negativeMarks: this.tempQuestion.negativeMarks,
                    options: this.tempQuestion.options,
                    question: this.tempQuestion.question,
                    questionNumber: this.tempQuestion.questionNo,
                    solution: this.tempQuestion.solution,
                    type: "mcq",
                    imageForQuestion: this.tempQuestion.imageForQuestion
                });
            }
            this.addingQuestion = false;
        },
        deleteQuestion(idxS, idxQ) {
            this.ediSectionsArr[this.activeModule].subSections[idxS].questions.splice(
                idxQ,
                1
            );
        },
        async handleSaveCourseDetails() {
            try {
                const newSectionsArr = this.ediSectionsArr;
                for (let i = 0; i < this.ediSectionsArr.length; i++) {
                    const section = newSectionsArr[i];
                    for (let j = 0; j < section.subSections.length; j++) {
                        const subsection = section.subSections[j];

                        delete subsection.pcFiles;
                        delete subsection.mediaLibFiles;
                        delete subsection.mediaLibOptions;
                        delete subsection.allowedFiles;
                        delete subsection.selectedQuestions;
                    }
                }
                this.courseDetails.sections = newSectionsArr;
                this.courseDetails.subjectId = this.courseDetails.courseId;
                await this.CourseRepositoryInstance.updateCourseInfo(
                    this.courseDetails
                );
                showStatus("Course Updated Successfully", 3000, "success", this);
                this.fetchData();

                const payloadArr = []
                Object.keys(this.logChanges).map(key => {
                    payloadArr.push(this.logChanges[key]);
                })

                if (payloadArr.length > 0) {
                    await this.CourseRepositoryInstance.createMultipleLogs({
                        logsArray: payloadArr
                    });
                }

                this.logChanges = {};
            } catch (err) {
                showStatus(
                    "Unable to update course. Please try again later",
                    3000,
                    "error",
                    this
                );
            }
        },
        updateLogger(section, subSection, activity) {
            let tempActivity = "Update";
            if (activity) tempActivity = activity
            if (this.logChanges[subSection.subSectionId] === null || this.logChanges[subSection.subSectionId] === undefined) this.logChanges[subSection.subSectionId] = {};

            this.logChanges[subSection.subSectionId] = {
                type: "courses",
                courseLogType: "save",
                instituteId: this.courseDetails.instituteId,
                courseId: this.courseDetails.courseId,
                sectionId: section._id,
                sectionName: section.sectionTitle,
                savedBy: this.userName,
                savedAt: new Date().toISOString(),
                subsectionName: subSection.subsectionTitle,
                activity: tempActivity,
                subSectionId: subSection.subSectionId
            }
        },
        async publishCourse() {
            const test = true;
            if (test) {
                const tempPublishLogsPayload = [];
                this.coursePreview.sections.map((section) => {
                    section.isSectionPublished = true;
                    section.subSections.map((subSection) => {
                        subSection.isSubsectionPublished = true;
                        if (!this.displayPublishLogs[subSection.subSectionId]) {
                            tempPublishLogsPayload.push({
                                type: "courses",
                                courseLogType: "publish",
                                instituteId: this.courseDetails.instituteId,
                                courseId: this.courseDetails.courseId,
                                sectionId: section._id,
                                sectionName: section.sectionTitle,
                                publishedBy: this.userName,
                                publishedAt: new Date().toISOString(),
                                subsectionName: subSection.subsectionTitle,
                                subSectionId: subSection.subSectionId
                            })
                        }
                    });
                });
                this.coursePreview.isCoursePublished = true;
                try {
                    await this.CourseRepositoryInstance.updateCourseInfo({
                        ...{
                            subjectId: this.currSubject.subjectId
                        },
                        ...this.coursePreview
                    });

                    if (tempPublishLogsPayload.length > 0) {
                        await this.CourseRepositoryInstance.createMultipleLogs({
                            logsArray: tempPublishLogsPayload
                        });
                    }
                    showStatus("Course Published Successfully", 3000, "success", this);
                    this.fetchData();
                    this.showPublishModal = false
                } catch (err) {
                    showStatus("Could not publish course", 3000, "error", this);
                }
            }
        },
        handleSubSectionPanelChange(subsectionIndex) {
            console.log(subsectionIndex);
            if (subsectionIndex === this.activeModuleSubsection) this.activeModuleSubsection = -1;
            else this.activeModuleSubsection = subsectionIndex;
        },
        handleGoBack() {
            history.back()
        },
        goToAnalysis() {
        this.$router.push({
            name: "activityCourseAnalysis",
            params: {
                prop_subject: this.prop_subject,
                prop_selectedSemester: this.prop_selectedSemester
            }
        });
    }
    },
    created() {
        this.CourseRepositoryInstance = new CourseRepository(this);
        this.UploadToDigitalOceanRepositoryInstance =
            new UploadToDigitalOceanRepository(this);
        this.subjectRepositoryInstance = new SubjectRepository(this);
        const firebase = auth.getFirebaseObject();
        this.firestorage = firebase.storage();
        this.$store.commit(
            "liveData/set_selectedActivityName",
            this.prop_subject.subjectName
        );
        this.courseName = this.prop_subject.subjectName;
        this.selectedSubject = this.prop_subject;
        this.selectedSemester = this.prop_selectedSemester;
        this.userData = this.$store.getters["user/get_userData"];
        this.userName = this.$store.getters['user/get_userName']
        this.mediaLibraryInstance = new CoursesData(this);
        this.fetchData();

        //
        this.getMediaLibOptions("Assignment").then((res) => {
            this.tempSubSection.mediaLibOptions = res;
        });
    }
};
</script>
<style src="./activitySubjectCourses.css" scoped></style>
