import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"2"}},[_c('div',{staticStyle:{"margin-bottom":"5px","margin-left":"10px","font-size":"14px","font-weight":"400","color":"#3a3a3a","margin-top":"6px"}},[_vm._v(" Progress Indicator: ")])]),_c(VCol,{staticStyle:{"margin-left":"-20px","margin-right":"4px","margin-top":"14px"},attrs:{"cols":"10"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c(VProgressLinear,{attrs:{"value":_vm.progressIndicator1,"height":"10","color":"#00128C","rounded":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',{staticStyle:{"margin-bottom":"0px","color":"white","font-size":"smaller"}},[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}])})],1)])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c('div',{staticStyle:{"margin-bottom":"5px","margin-left":"10px","font-size":"14px","font-weight":"400","color":"#3a3a3a","margin-top":"6px"}},[_vm._v(" Select subjects for this exam: ")])]),_c(VCol,{staticStyle:{"margin-left":"-20px","margin-right":"4px","margin-top":"14px"},attrs:{"cols":"9"}},[_c(VSelect,{attrs:{"items":_vm.subjects,"multiple":"","chips":"","dense":"","solo":"","persistent":"","flat":"","hide-details":"","item-text":"subjectName","return-object":"","background-color":_vm.backgroundColor,"disabled":true},on:{"change":function($event){return _vm.prepareSubjectDetailsTable1()}},model:{value:(_vm.selectedSubjects),callback:function ($$v) {_vm.selectedSubjects=$$v},expression:"selectedSubjects"}})],1)],1),_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"10"}},[_c(VTextField,{staticClass:"custom-search-box",attrs:{"label":"Search","hide-details":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end","margin-right":"20px"}},[_c(VBtn,{attrs:{"medium":"","outlined":"","color":"#ff4f14","disabled":true}},[_c(VIcon,{staticStyle:{"color":"#ff4f14"}},[_vm._v("mdi-plus")]),_vm._v("Upload via Excel ")],1)],1)])],1),_c(VDataTable,{staticClass:"custom-table",attrs:{"headers":_vm.headers,"items":_vm.selectedSubjects,"search":_vm.search},scopedSlots:_vm._u([{key:"item.srNo",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index+1)+" ")]}},{key:"item.subjectCode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subjectCode)+" ")]}},{key:"item.subjectName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subjectName)+" ")]}},{key:"item.allocatedStudents",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"clickable-item",on:{"click":function($event){return _vm.prepareStudentsDialog(item)}}},[_vm._v(" "+_vm._s(item.allocatedStudentsCount)+" ")])]}},{key:"item.examDate",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"hide-details":"","prepend-inner-icon":_vm.calenderIcon,"readonly":"","solo":"","flat":"","dense":"","background-color":_vm.backgroundColor,"disabled":true},model:{value:(item.examDate),callback:function ($$v) {_vm.$set(item, "examDate", $$v)},expression:"item.examDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[_c(VDatePicker,{on:{"input":function($event){item.menu = false}},model:{value:(item.examDate),callback:function ($$v) {_vm.$set(item, "examDate", $$v)},expression:"item.examDate"}})],1)]}},{key:"item.examTime",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div'),_c(VTextField,_vm._g(_vm._b({attrs:{"hide-details":"","prepend-inner-icon":_vm.calenderIcon,"readonly":"","solo":"","flat":"","dense":"","background-color":_vm.backgroundColor,"disabled":true},model:{value:(item.examTime),callback:function ($$v) {_vm.$set(item, "examTime", $$v)},expression:"item.examTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.menu2),callback:function ($$v) {_vm.$set(item, "menu2", $$v)},expression:"item.menu2"}},[(item.menu2)?_c(VTimePicker,{on:{"click:minute":function($event){item.menu2=false}},model:{value:(item.examTime),callback:function ($$v) {_vm.$set(item, "examTime", $$v)},expression:"item.examTime"}}):_vm._e()],1)]}},{key:"item.examDuration",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-input"},[_c(VTextField,{attrs:{"type":"number","min":"0","hide-details":"","dense":"","solo":"","flat":"","background-color":_vm.backgroundColor,"disabled":true},model:{value:(item.examDuration),callback:function ($$v) {_vm.$set(item, "examDuration", $$v)},expression:"item.examDuration"}})],1)]}},{key:"item.totalMarks",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-input"},[_c(VTextField,{attrs:{"type":"number","min":"0","hide-details":"","dense":"","solo":"","flat":"","background-color":_vm.backgroundColor,"disabled":true},model:{value:(item.totalMarks),callback:function ($$v) {_vm.$set(item, "totalMarks", $$v)},expression:"item.totalMarks"}})],1)]}},{key:"item.threshold",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-input"},[_c(VTextField,{attrs:{"type":"number","min":"0","hide-details":"","dense":"","solo":"","flat":"","background-color":_vm.backgroundColor,"disabled":true},model:{value:(item.threshold),callback:function ($$v) {_vm.$set(item, "threshold", $$v)},expression:"item.threshold"}})],1)]}},{key:"item.passingMarks",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-input"},[_c(VTextField,{attrs:{"type":"number","min":"0","hide-details":"","dense":"","solo":"","flat":"","background-color":_vm.backgroundColor,"disabled":true},model:{value:(item.passingMarks),callback:function ($$v) {_vm.$set(item, "passingMarks", $$v)},expression:"item.passingMarks"}})],1)]}}])}),_c(VDialog,{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.studentDialog),callback:function ($$v) {_vm.studentDialog=$$v},expression:"studentDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" 36 students are selected for existing exam ,40 students are for Maths out of 45 students in FE , ")]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[_c(VTextField,{staticClass:"custom-search-box-2",attrs:{"label":"Search","dense":"","hide-details":""},model:{value:(_vm.studentsSearch),callback:function ($$v) {_vm.studentsSearch=$$v},expression:"studentsSearch"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end","margin-right":"20px"}},[_c(VBtn,{attrs:{"medium":"","outlined":"","color":"#ff4f14"}},[_vm._v(" Download ")])],1)])],1),_c(VRadioGroup,{attrs:{"column":""},model:{value:(_vm.studentRadio),callback:function ($$v) {_vm.studentRadio=$$v},expression:"studentRadio"}},[_c(VRadio,{attrs:{"label":"All Students for Course Mapped","value":"option1"}}),_c(VRadio,{attrs:{"label":"Show Selected Students  for Existing Exam","value":"option2"}}),_c(VRadio,{attrs:{"label":"Show not Selected Students for Existing Exam","value":"option3"}})],1)],1),_c(VCardText,[_c(VDataTable,{staticClass:"custom-student-table",attrs:{"headers":_vm.studentDialogHeaders,"items":_vm.filteredStudents,"search":_vm.studentsSearch},scopedSlots:_vm._u([{key:"item.selected",fn:function(ref){
var item = ref.item;
return [_c(VCheckbox,{model:{value:(item.isSelected),callback:function ($$v) {_vm.$set(item, "isSelected", $$v)},expression:"item.isSelected"}})]}},{key:"header.selected",fn:function(){return [_c(VCheckbox,{on:{"change":_vm.selectAllItems},model:{value:(_vm.selectAllStudents),callback:function ($$v) {_vm.selectAllStudents=$$v},expression:"selectAllStudents"}})]},proxy:true}])})],1),_c('div',{staticStyle:{"display":"flex","justify-content":"center","padding":"10px"}},[_c(VBtn,{staticClass:"primaryButton",on:{"click":function($event){return _vm.saveAllocatedStudents()}}},[_vm._v("Save")])],1)],1)],1),_c('div',{staticClass:"center-container"},[_c(VBtn,{staticClass:"primaryButton",attrs:{"color":"#ff4f1f"},on:{"click":function($event){return _vm.saveSubjectIds()}}},[_c('div',[_vm._v("Save")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }