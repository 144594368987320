<template src='./activityAssignments.html'></template>

<script>
import auth from '../../Services/Firebase/auth'
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
import { formatToSlashSepearated, convertUTCDateToLocalDate, convertDate } from '../../Services/Utils/DateTimeFormatter'
// convertDateTimeToYYYYMMDD
import AssignmentUserRepository from '../../Repository/AssignmentUser'
import AssignmentRepository from '../../Repository/Assignment'
import SemesterUserRepository from '../../Repository/SemesterUser'
import UserRepository from '../../Repository/User'
var firestorage = null
export default {
  name: 'activityAssignments',
  components: {
    inputContainer,
    smallButton
  },
  props: [
    'prop_subject', 'prop_type', 'prop_groupInfo'
  ],
  watch: {
    uploadTask: function () {
      this.uploadTask.on('state_changed', sp => {
        this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
      },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploadEnd = true
            // console.log('hello', downloadURL)
            this.downloadURLs.push(downloadURL)
            this.uploadCount++
            if (this.uploadCount === this.files.length) {
              this.createAssignment()
            } else {
              this.progressUpload = 0
              this.upload(this.uploadCount)
            }
          })
        })
    },
    uploadTask2: function () {
      // console.log('state_changed', this.uploadTask2);
      this.uploadTask2.on('state_changed', sp => {
        this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
      },
        null,
        () => {
          this.uploadTask2.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploadEnd = true
            // console.log('updated download url', downloadURL)
            this.downloadURLs2.push(downloadURL)
            this.uploadCount++
            if (this.uploadCount === this.editFiles.length) {
              this.updateAssignment()
            } else {
              this.progressUpload = 0
              this.upload2(this.uploadCount)
            }
          })
        })
    }
  },
  data() {
    return {
      disabledRefreshBtn: false,
      currentAssignmentLoader: false,
      pastAssignmentLoader: false,
      menu2: false,
      time: null,
      selectedStudentListForEdit: [],
      dummyIdsArray: [],
      editSelectedStudent: [],
      studentListArray: [],
      selectedStudentForAssignment: [],
      searchStudent: '',
      headersForStudentList: [
        {
          text: 'Roll Number',
          value: 'rollNumber',
          align: 'left'
        },
        {
          text: 'Name Of Student',
          value: 'name',
          align: 'left'
        }
      ],
      isMobileView: false,
      editAssignment: false,
      tempisForNBA: false,
      editFilesChanged: false,
      files: [],
      editFiles: [],
      uploadTask: '',
      uploadTask2: '',
      editTitle: '',
      assignmentIdToEdit: '',
      editDueDate: '',
      editDescription: '',
      editMarks: '',
      uploadEnd: false,
      editisForNBA: false,
      downloadURLs: [],
      downloadURLs2: [],
      storeUrlSplit: '',
      uploadCount: 0,
      fileName: '',
      uploading: false,
      addingAssignment: false,
      tempTitle: '',
      tempDescription: '',
      tempMarks: 0,
      tempDueDate: '',
      filesUpload: '',
      pastAssignments: [],
      currentAssignments: [],
      progressUpload: 0,
      headers: [
        {
          text: 'Name Of the Assignment',
          value: 'title',
          width: "25%"
        },
        {
          text: "Allowed File Types",
          value: "allowedFileTypes",
          width: "20%"
        },
        {
          text: 'Published On',
          value: 'dateOfAssignment',
          width: "15%"
        },
        {
          text: 'Due',
          value: 'deadlineForSubmission',
          width: "15%"
        },
        {
          text: "Time",
          value: "time",
          width: "120px",
          align: "left"
        },
        {
          text: 'Edit',
          value: 'edit',
          width: "8%",
          align: "left"
        },
        {
          text: 'Delete',
          value: 'delete',
          width: "8%"
        },
        {
          text: '',
          value: 'check',
          width: "10%"
        }
      ],
      loading: false,
      selectedSubject: {},
      allowedFileTypes: [],
      editallowedFileTypes: [],
      type: '',
      showModal: false,
      deletePostConfirm: false,
      postToDelete: {}
    }
  },
  created() {
    this.type = this.prop_type
    // console.log('this.propType', this.prop_type, this.prop_groupInfo)
    this.$store.commit('liveData/set_selectedActivityName', 'Normal Assignments')
    this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    const firebase = auth.getFirebaseObject()
    firestorage = firebase.storage()
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.userData = this.$store.getters['user/get_userData']
    // console.log('this.userData', this.userData);
    this.assignmentRepositoryInstance = new AssignmentRepository(this)
    this.assignmentUserRepositoryInstance = new AssignmentUserRepository(this)
    this.SemesterUserRepository = new SemesterUserRepository(this)
    this.UserRepository = new UserRepository(this)

    if (this.prop_subject) {
      this.selectedSubject = JSON.parse(JSON.stringify(this.prop_subject))
    } else {
      this.selectedSubject = JSON.parse(JSON.stringify(this.$store.getters['liveData/get_selectedSubject']))
    }
    // console.log('this.selectedSubject', this.selectedSubject);
    this.pastAssignments = []
    this.currentAssignments = []
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.disabledRefreshBtn = true
      this.currentAssignmentLoader = true
      this.pastAssignmentLoader = true
      this.currentAssignments = []
      this.pastAssignments = []
      let assignmentsList = []
      let result = []
      try {
        const obj = {
          instituteId: this.selectedSubject.instituteId,
          semId: this.selectedSubject.semId,
          subjectId: this.selectedSubject.subjectId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear
        }
        if (this.prop_type === "Group") {
          obj.groupId = this.prop_groupInfo.groupId
          result = await this.assignmentRepositoryInstance.getAssignmentsByAFacultyForASubjectForAGroup(obj)
        } else {
          result = await this.assignmentRepositoryInstance.getAssignmentsByAFacultyForASubjectForADivision(this.selectedSubject)
        }
        // console.log('result', result);
        assignmentsList = result.filter(item => item.assignmentType === 'Normal')
        // console.log('assignmentsList1', assignmentsList);
        let dummyArrayToFilterAssignment = []
        dummyArrayToFilterAssignment = assignmentsList
        // console.log('dummyArrayToFilterAssignment1', dummyArrayToFilterAssignment);
        dummyArrayToFilterAssignment.map((assignment, index) => {
          if (assignment.assignedFacultyList.length > 0) {
            if (!assignment.assignedFacultyList.includes(this.userData.uId)) {
              dummyArrayToFilterAssignment.splice(index, 1)
            }
          }
        })
        assignmentsList = dummyArrayToFilterAssignment
        // console.log('dummyArrayToFilterAssignment', dummyArrayToFilterAssignment);
        // console.log('assignmentsList', assignmentsList);
      } catch (err) {
        console.log(err)
        assignmentsList = []
      }

      assignmentsList.reverse()

      const currentDate = new Date()

      for (let i = 0; i < assignmentsList.length; i++) {
        assignmentsList[i].deadlineForSubmission = new Date(assignmentsList[i].deadlineForSubmission)
        assignmentsList[i].dateOfAssignment = new Date(assignmentsList[i].dateOfAssignment)
        if (assignmentsList[i].deadlineForSubmission > currentDate) {
          this.currentAssignments.push(assignmentsList[i])
        } else {
          this.pastAssignments.push(assignmentsList[i])
        }
      }
      try {
        const objToPushForStudentList = {
          instituteId: this.selectedSubject.instituteId,
          semId: this.selectedSubject.semId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear,
          subjectId: this.selectedSubject.subjectId
        };

        if (this.prop_type === "Division") {
          objToPushForStudentList.division = this.selectedSubject.division;
        }
        if (this.prop_type === "Batch") {
          objToPushForStudentList.division = this.selectedSubject.division;
          objToPushForStudentList.batch = this.selectedSubject.batch;
        }
        if (this.prop_type === "Group") {
          objToPushForStudentList.groupName = this.prop_groupInfo.groupName;
          objToPushForStudentList.groupId = this.prop_groupInfo.groupId;
        }

        // console.log('objToPushForStudentList', objToPushForStudentList);
        let response = await (this.prop_type === "Division" || this.prop_type === "Batch"
          ? this.SemesterUserRepository.getSemesterUsersOfASubjectOfADivision(objToPushForStudentList)
          : this.SemesterUserRepository.getSemesterUsersOfASubjectOfAGroup(objToPushForStudentList));
        // console.log('response', response);
        if (this.prop_type === 'Batch') {
          const dummy = response.filter((data) => data.batch === this.selectedSubject.batch)
          response = dummy
        }
      const uIdsArray = response.map(student => student.uId);
      const studentListArray1 = await this.UserRepository.getUsers({ uIds: uIdsArray });
      this.studentListArray = await this.SemesterUserRepository.getMultipleSemesterUsers({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uIds: uIdsArray
      });
      const studentMap = new Map(studentListArray1.map(student => [student.uId, student]));
      this.studentListArray.forEach(student => {
        const studentInfo = studentMap.get(student.uId);
        if (studentInfo) {
          student.name = [studentInfo.firstName, studentInfo.middleName, studentInfo.lastName].filter(Boolean).join(" ");
        }
      });
      } catch (error) {
        console.log('error', error);
      }
      this.currentAssignmentLoader = false
      this.pastAssignmentLoader = false
      this.disabledRefreshBtn = false
    },
    async updateAssignment() {
      const uIdsOfSelectedStudentEdit = []
      this.selectedStudentListForEdit.map((data) => {
        uIdsOfSelectedStudentEdit.push(data.uId)
      })
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        assignmentId: this.assignmentIdToEdit,
        title: this.editTitle,
        description: this.editDescription,
        totalObtainableMarks: this.editMarks,
        allowedFileTypes: this.editallowedFileTypes,
        isForNBA: this.editisForNBA,
        linksOfAttachments: this.downloadURLs2,
        deadlineForSubmission: new Date(new Date(this.editDueDate).setHours(23, 59, 59)),
        assignedStudentList: uIdsOfSelectedStudentEdit,
        time: this.time
      }
      // console.log('objToPush1', objToPush);
      try {
        const res = await this.assignmentRepositoryInstance.updateAssignment(objToPush)
        console.log('res', res);
        this.fetchData()
        this.progressUpload = 0
        this.editAssignment = false
      } catch (error) {
        console.log(error);
      }
    },
    async editAssignments(assignment) {
      this.$router.push({
        name: 'activityCreateAssignments',
        params: {
          prop_subject: this.selectedSubject,
          prop_assignment: assignment,
          prop_type: this.prop_type,
          prop_groupInfo: this.prop_groupInfo
        }
      })
      // this.selectedStudentListForEdit = []
      // this.editAssignment = true
      // this.loading = false
      // this.editTitle = item.title
      // this.assignmentIdToEdit = item.assignmentId
      // this.editDescription = item.description
      // this.editMarks = item.totalObtainableMarks
      // this.editallowedFileTypes = item.allowedFileTypes
      // this.editisForNBA = item.isForNBA
      // this.dummyIdsArray = item.assignedStudentList
      // this.time = item.time
      // if (this.dummyIdsArray.length > 0) {
      //   this.studentListArray.map((stu) => {
      //     if (this.dummyIdsArray.includes(stu.uId)) {
      //       this.selectedStudentListForEdit.push(stu)
      //     }
      //   })
      // }
      // // this.selectedStudentListForEdit = []
      // // this.editFiles = item.linksOfAttachments
      // this.editDueDate = convertDateTimeToYYYYMMDD(item.deadlineForSubmission)
      // if (typeof item.linksOfAttachments[0] === "string") {
      //   var url = item.linksOfAttachments[0]
      //   // console.log("url ",url.split("________")[1].split("?")[0] )
      //   this.storeUrlSplit = url.split("________")[1].split("?")[0].replaceAll("%", '')
      //   this.editFiles = item.linksOfAttachments
      // }
    },
    // async deleteAssignments() {
    //   const objToPush = {
    //     instituteId: this.selectedInstitute,
    //     semId: this.selectedSemester.semId,
    //     assignmentId: this.postToDelete.assignmentId
    //   }
    //   const res = await this.assignmentRepositoryInstance.deleteAssignment(objToPush)
    //   console.log('res', res);
    //   console.log("obj", objToPush);
    //   this.fetchData()
    // },
    async updateisforNbaStatus() {
      // console.log('this.tempisForNBA', this.tempisForNBA);
    },
    async updateeditisforNbaStatus() {
      // console.log('this.tempisForNBA', this.tempisForNBA);
    },
    async deleteAssignments() {
    try {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        assignmentId: this.postToDelete.assignmentId
      };
      const res = await this.assignmentRepositoryInstance.deleteAssignment(objToPush);
      console.log('res', res);
      this.fetchData();
      this.showModal = false;
      } catch (error) {
        console.error('Error deleting assignment:', error);
      }
    },
    async createAssignment() {
      const uIdsOfSelectedStudent = []
      this.selectedStudentForAssignment.map((data) => {
        uIdsOfSelectedStudent.push(data.uId)
      })
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear,
          division: this.selectedSubject.division,
          batch: this.selectedSubject.batch,
          uId: this.userData.uId,
          subjectId: this.selectedSubject.subjectId,
          subjectName: this.selectedSubject.subjectName,
          dateOfAssignment: new Date(),
          title: this.tempTitle,
          description: this.tempDescription,
          linksOfAttachments: this.downloadURLs,
          tempDueDate: this.tempDueDate.split('T')[0],
          deadlineForSubmission: new Date(new Date(this.tempDueDate).setHours(23, 59, 59)),
          totalObtainableMarks: this.tempMarks,
          allowedFileTypes: this.allowedFileTypes,
          isForNBA: this.tempisForNBA,
          assignedStudentList: uIdsOfSelectedStudent,
          assignedFacultyList: [this.userData.uId],
          time: this.time,
          assignmentType: 'Normal'
        }
        if (this.prop_type === "Group") {
          objToPush.assignedGroupForSubject = {
            groupName: this.prop_groupInfo.groupName,
            groupId: this.prop_groupInfo.groupId
          }
        }
        // console.log('createAssignment objToPush', objToPush);
        const response = await this.assignmentRepositoryInstance.createAssignment(objToPush)
        console.log(response)
        this.loading = false
        this.progressUpload = 0
        this.cancelCreating()
        this.fetchData()
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    uploadFiles2() {
      this.loading = true
      this.downloadURLs2 = []
      this.uploadCount = 0
      if (this.editFilesChanged !== true) {
        this.updateAssignment()
      } else {
        this.downloadURLs2 = []
        this.startUploadingFiles2()
      }
      this.loading = false
      this.editAssignment = false
    },
    uploadFiles() {
      this.loading = true
      this.downloadURLs = []
      this.uploadCount = 0
      // console.log(this.files)
      if (this.files.length === 0) {
        this.createAssignment()
      } else {
        this.startUploadingFiles()
      }
    },
    startUploadingFiles() {
      this.upload(this.uploadCount)
    },
    upload(fileNumber) {
      this.fileName = this.files[fileNumber].name
      this.uploading = true
      // console.log(this.files[fileNumber])
      // console.log(`assignments/${this.userData.uId}/${new Date().valueOf().toString()}________${this.files[fileNumber].name}`)
      this.uploadTask = firestorage.ref('assignments/' + this.userData.uId + '/' + new Date().valueOf().toString() + '________' + this.files[fileNumber].name).put(this.files[fileNumber])
    },
    upload2(fileNumber) {
      this.fileName1 = this.editFiles[fileNumber].name
      this.uploading = true
      this.uploadTask2 = firestorage.ref('assignments/' + this.userData.uId + '/' + new Date().valueOf().toString() + '________' + this.editFiles[fileNumber].name).put(this.editFiles[fileNumber])
    },
    filesChanged(e) {
      console.log(e)
    },
    filesChanged1(e) {
      this.editFilesChanged = true
      console.log('e', e);
    },
    formatToSlashSepearated(date) {
      return formatToSlashSepearated(date)
    },
    startUploadingFiles2() {
      this.upload2(this.uploadCount)
    },
    cancelCreating() {
      this.tempTitle = ''
      this.tempDescription = ''
      this.tempDueDate = ''
      this.tempMarks = 0
      this.files = []
      this.addingAssignment = false
      this.allowedFileTypes = []
      this.selectedStudentForAssignment = []
      this.time = null
    },
    cancelEditing() {
      this.editTitle = ''
      this.editDescription = ''
      this.editisForNBA = ''
      this.editDueDate = ''
      this.editMarks = 0
      this.editFiles = []
      this.editAssignment = false
      this.editallowedFileTypes = []
      this.time = null
    },
    convertDate(dateTime) {
      return convertDate(dateTime)
    },
    convertUTCDateToLocalDate(dateTime) {
      return convertUTCDateToLocalDate(dateTime)
    },
    gotoAssignmentsCheck(assignment) {
      this.$router.push({
        name: 'activityCheckAssignments',
        params: {
          prop_subject: this.selectedSubject,
          prop_assignment: assignment,
          prop_type: this.prop_type,
          prop_groupInfo: this.prop_groupInfo
        }
      })
    },
    gotoAssignmentCreate() {
      this.$router.push({
        name: 'activityCreateAssignments',
        params: {
          prop_subject: this.selectedSubject,
          prop_type: this.prop_type,
          prop_groupInfo: this.prop_groupInfo
        }
      })
    },
    gotoMySubjects(assignment) {
      this.$router.push({
        name: 'activityMySubjects',
        params: {
          prop_subject: this.selectedSubject,
          prop_assignment: assignment,
          prop_type: this.prop_type,
          prop_groupInfo: this.prop_groupInfo
        }
      })
    },
    validateMarks() {
      if (this.tempMarks === '' || this.tempMarks < 0) {
        this.tempMarks = 0
      }
    },
    async deletePost(post) {
      this.deletePostConfirm = false
      this.showModal = true
      this.postToDelete = post
    },
    handleGoBack() {
        history.back()
    }
  }
}
</script>

<style src='./activityAssignments.css'></style>
