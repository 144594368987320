<template src='./activityMyExam.html'></template>

<script>
import PortalFooter from "../../../Components/PortalFooter";
import SubjectsRepository from "../../../Repository/Subjects";
import SemesterRepository from "../../../Repository/Semester";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import NewExamRepository from "../../../Repository/NewExam";
import showStatus from "../../../NetworkManager/showStatus";

export default {
  name: "activityMyExam",
  components: {
    PortalFooter
  },

  data() {
    return {
      examsLoaded: true,
      isMobileView: false,
      assignedExam: [],
      assignedExamDetails: {},
      academicDataTree: {},
      subjectId: [],
      filteredSubjectId: [],
      courseYears: [],
      subjectNamesById: [],
      courseName: [],
      departments: [],
      divisionName: [],
      semestersList: [],
      divisions: [],
      departmentName: [],
      division: "",
      course: "",
      examToDelete: {},
      deleteExamDialog: false,
      examName: "",
      examForNba: false,
      department: "",
      allExamsDetails: [],
      invigilationDialog: false,
      invigilationExams: [],
      allFilteredSubjects: [],
      allExams: [],
      uniqueIds: [],
      invigilationTableHeaders: [
        {
          text: 'Exam Name',
          value: 'name'
        },
        {
          text: 'Subject Name',
          value: 'subjectName'
        },
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Time',
          value: 'time'
        },
        {
          text: 'Room Name',
          value: 'roomName'
        },
        {
          text: 'Room Number',
          value: 'roomNumber'
        }
      ],
      currentCourseYears: [],
      currentDivisions: [],
      examTableHeaders: [
        {
          text: 'Name',
          value: 'title'
        },
        {
          text: 'Allowed File types',
          value: 'allowedFileTypes'
        },
        {
          text: 'Published On',
          value: 'convertedPublishedDate'
        },
        {
          text: 'Due',
          value: 'convertedDueDate'
        },
        {
          text: 'Time',
          value: 'convertedTime'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ]
    };
  },
  async created() {
    this.$store.commit("liveData/set_selectedActivityName", `My Exam`);
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    // const liveDept = this.$store.getters["liveData/get_examDepartment"];
    // const liveCourseYear = this.$store.getters["liveData/get_examCourseYear"];
    // const liveDiv = this.$store.getters["liveData/get_examDivision"];
    this.newExamRepositoryInstance = new NewExamRepository(this);
    // this.notificationRepositoryInstance = new NotificationRepository(this)
    // this.userRepositoryInstance = new UserRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.user = this.$store.getters["user/get_user"];
    this.userData = this.$store.getters["user/get_userData"];
    this.userData.subjects.forEach(subject => this.allFilteredSubjects.push(subject.subjectId))
    this.userData.assignedGroupForSubject.forEach(group => this.allFilteredSubjects.push(group.subjectId))
    console.log('this.userData', this.allFilteredSubjects)
    this.uniqueIds = [...new Set(this.allFilteredSubjects)];
    console.log('uniqueIds', this.uniqueIds)
    this.getExamsOfSubjects()
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    // await this.getDepartments();
    this.subjectId = this.userData.subjects.map((item) => item.subjectId);
    this.departments = [...new Set(this.userData.subjects.map((item) => item.department))]
    this.currentCourseYears = [...new Set(this.userData.subjects.map((item) => item.courseYear))]
    this.currentDivisions = [...new Set(this.userData.subjects.map((item) => item.division))]
    // await this.getAllExams();
    // if (liveDept) {
    //   this.department = liveDept;
    //   this.selectDepartment();
    // }
    // if (liveCourseYear) {
    //   this.course = liveCourseYear;
    //   this.selectCourseYear();
    // }
    // if (liveDiv) {
    //   this.division = liveDiv;
    //   this.selectDivision();
    // }
  },
  methods: {
    // handleInvigilatorDashboard() {
    //   this.invigilationDialog = true
    // },
    deleteExamDialogFunc(exam) {
      this.examToDelete = {};
      this.deleteExamDialog = true;
      this.examToDelete = {
        examId: exam.examId,
        questionDetails: exam.questionDetails,
        subjectDetails: exam.subjectDetails,
        _id: exam._id,
        attemptNumber: exam.attemptNumber,
        instituteId: exam.instituteId,
        semId: exam.semId
      };
      this.examCourseYear = exam.criteria.courseYear;
      this.examForNba = exam.isForNBA;
      this.examName = exam.name;
    },
    async deleteExam() {
      this.toggleExamDelete = true;
      this.objToPush = {
        lmsInstituteId: this.selectedInstitute,
        semesterId: this.selectedSemester.semId,
        courseYear: this.examCourseYear,
        examId: this.examToDelete.examId,
        type: this.examToDelete.type
      };
      try {
        // await apiV3.postRequest("/manageExam/delete", this.examToDelete);
        // showStatus("Exam Deleted Successfully.", 5000, "success", this);
        const status = await this.newExamRepositoryInstance.deleteExam({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          examId: this.examToDelete.examId
        })
        console.log("delete Status", status)
        this.deleteExamDialog = false;
        // if (this.examForNba) {
        //   this.deleteFromLms();
        // }
        await this.getExamsOfSubjects();
        this.toggleExamDelete = false;
      } catch (error) {
        this.toggleExamDelete = false;
        this.deleteExamDialog = false;
        showStatus(
          "Cannot delete exam. Please try again later.",
          5000,
          "error",
          this
        );
      }
    },
    async getExamsOfSubjects() {
      try {
        this.allExams = await this.newExamRepositoryInstance.getExamsFromSubjectIds({
          subjectIds: this.uniqueIds
        })
        console.log('this.allExams', this.allExams)
      } catch (e) {
        console.error("e", e);
      }
    },
    // async getDepartments() {
    //   try {
    //     const objToPush = {
    //       instituteId: this.selectedInstitute,
    //       semId: this.selectedSemester.semId
    //     };
    //     const data = await this.semesterUserRepositoryInstance.getSemesterUsersForAUser({
    //       instituteId: this.selectedInstitute,
    //       uId: this.userData.uId
    //     });
    //     console.log('data', data);
    //     this.semestersList =
    //       await this.semesterRepositoryInstance.getDivisionsOfASemester(
    //         objToPush
    //       );
    //     // this.academicDataTree = {};
    //     // this.semestersList.forEach((sem) => {
    //     //   if (this.userData.department === sem.department) {
    //     //     if (!this.academicDataTree[sem.department]) {
    //     //       this.academicDataTree[sem.department] = {};
    //     //     }
    //     //     if (!this.academicDataTree[sem.department][sem.courseYear]) {
    //     //       this.academicDataTree[sem.department][sem.courseYear] = [];
    //     //     }
    //     //     this.academicDataTree[sem.department][sem.courseYear].push(
    //     //       sem.division
    //     //     );
    //     //   }
    //     // });
    //     // this.courseYears = [];
    //     // this.divisions = [];
    //   } catch (e) {
    //     console.error("e", e);
    //   }
    //   this.loading = false;
    // },
    // gotoEditExam(item) {
    //   this.$store.commit("liveData/set_selectedExam", item);
    //   this.$router.push("/activityEditExam");
    // },
    // gotoCreateExam() {
    //   this.$router.push({
    //     name: "activityCreateExam",
    //     params: {
    //       prop_courseYear: this.course,
    //       prop_division: this.division,
    //       prop_department: this.department
    //     }
    //   })
    //   // this.$router.push("/activityCreateExam");
    // },
    // selectDepartment() {
    //   this.selectedCourseYear = "";
    //   this.course = "";
    //   this.division = "";
    //   this.selectedDivision = "";
    //   this.assignedExam = []
    //   this.courseYears = this.semestersList.filter(item => item.department === this.department).map(data => data.courseYear)
    //   this.$store.commit("liveData/set_examDepartment", this.department);
    // },
    // selectCourseYear() {
    //   this.division = "";
    //   this.assignedExam = []
    //   this.divisions = this.semestersList.filter(item => item.department === this.department && item.courseYear === this.course)?.map(data => data.division)?.filter(div => this.currentDivisions.includes(div))
    //   this.$store.commit("liveData/set_examCourseYear", this.course);
    // },
    // async selectDivision() {
    //   this.assignedExam = []
    //   this.$store.commit("liveData/set_examDivision", this.division);
    //   this.examsLoaded = false;
    //   const objToPush = {
    //     instituteId: this.userData.instituteId,
    //     semId: this.userData.semId,
    //     department: this.department,
    //     course: this.course,
    //     division: this.division,
    //     subjectIds: this.subjectId
    //   };
    //   // this.assignedExam = await apiV3.postRequest(
    //   //   "/exam/getAllExamsForFaculty",
    //   //   objToPush
    //   // );
    //   // objToPush.subjectIds = this.subjectId;
    //   this.assignedExam = this.allExamsDetails.filter((exam) =>
    //     exam.applicableSubjects.map((subject) => this.subjectId.includes(subject)) && exam.criteria.courseYear === this.course && exam.criteria.department === this.department && (exam.criteria.division === this.division || "All")
    //   );
    //   this.subjectNamesById = await this.subjectsRepositoryInstance.getSubjects(
    //     objToPush
    //   );
    //   this.examsLoaded = true;
    //   this.findSubjectName();
    // },
    // findSubjectName(id) {
    //   const nameOfSubject = this.subjectNamesById.map((x) => {
    //     if (x.subjectId === id) {
    //       return x.subjectName;
    //     }
    //   });
    //   if (nameOfSubject !== "") {
    //     return nameOfSubject;
    //   }
    // },
    // async getAllExams() {
    //   const objToPush = {
    //     instituteId: this.userData.instituteId,
    //     semId: this.userData.semId,
    //     facultyId: this.userData.uId
    //   };
    //   try {
    //     const response = await apiV3.getRequest(
    //       "/manageExam/getAllExamDetails",
    //       objToPush
    //     );
    //     this.allExamsDetails = response.data
    //   } catch (error) {
    //     this.allExamsDetails = []
    //   }
    //   this.filterInvigilationDuties(this.allExamsDetails)
    // },
    // filterInvigilationDuties(allExams) {
    //   this.invigilationExams = []
    //   for (let i = 0; i < allExams.length; i++) {
    //     const exam = allExams[i];
    //     if (exam.examStatus === 'Published' && (new Date() <= new Date(exam.startDate))) {
    //       for (let j = 0; j < exam.subjectDetails.length; j++) {
    //         const subject = exam.subjectDetails[j];
    //         if (subject.roomStatus) {
    //           for (let k = 0; k < subject.roomAllocation.length; k++) {
    //             const objToPush = {}
    //             const room = subject.roomAllocation[k];
    //             if (room.appliedFaculties.includes(this.userData.uId)) {
    //               objToPush.name = exam.name
    //               objToPush.subjectName = subject.subjectName
    //               objToPush.date = subject.selectedDate ? subject.selectedDate : '-'
    //               objToPush.time = subject.selectedTime ? subject.selectedTime : '-'
    //               objToPush.roomName = room.roomName ? subject.roomNames.filter(item => item.roomId === room.roomName)[0].roomName : '-'
    //               objToPush.roomNumber = room.roomNumber ? room.roomNumber : '-'
    //               this.invigilationExams.push(objToPush)
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    async gotoEditExam(item, pageNumber) {
      console.log("selectedExam item", item)
      this.$store.commit("liveData/set_examId", item.examId);
      console.log('this.$store.getters["instituteData/get_selectedInstitute"];', this.$store.getters["liveData/get_examDepartment"], this.$store.getters["liveData/get_examCourseYear"])
      this.$store.commit(
        "liveData/set_selectedInstitute",
        item.instituteId
      );
      const examSemester = await this.semesterRepositoryInstance.getSemestersBySemId({
        instituteId: item.instituteId,
        semId: item.semId
        });
        console.log('examSemester', examSemester)
      this.$store.commit(
        "liveData/set_selectedSemester",
        examSemester[0]
      );
      this.$store.commit(
        "liveData/set_examDepartment",
        item.department
      );
      this.$store.commit(
        "liveData/set_examCourseYear",
        item.courseYear
      );
      // this.$store.commit("liveData/set_selectedExam", item);
      this.$router.push({
        name: "activityManageExamsRevamped",
        params: {
          instituteToBeSet: this.selectedInstitute,
          semesterToBeSet: this.selectedSemester
        }
      });
    }

  }
};
</script>

<style scoped src='./activityMyExam.css'></style>
