export default {
  addEmail: '/auth/createUserAuthenticationWithEmail',
  addPhone: '/auth/createUserAuthenticationWithPhone',
  login: '/auth/login',
  getUserProfile: '/users/getUser',
  getUserAuthentication: '/auth/getUserAuthenticationsOfAUser',
  getFullNameOfUser: '/users/getFullNameOfUser',
  getFullNameOfUsers: '/users/getFullNameOfUsers',
  updateFirstName: '/users/updateFirstName',
  updateMiddleName: '/users/updateMiddleName',
  updateLastName: '/users/updateLastName',
  updateDateOfBirth: '/users/updateDateOfBirth',
  updateGender: '/users/updateGender',
  updateEmail: '/auth/updateEmail',
  updatePhone: '/auth/updatePhone',
  deleteEmail: '/auth/deleteUserAuthenticationUsingEmail',
  deletePhone: '/auth/deleteUserAuthenticationUsingPhone',
  getDivisionInformationOfAFacultyAsClassTeacher: '/semester/getDivisionInformationOfAFacultyAsClassTeacher',
  loginWithUserNameAndPassword: '/auth/loginWithUserNameAndPassword',
  passwordUpdate: '/auth/passwordUpdate',
  forgotPassword: '/auth/loginPasswordByUserName',
  getUsers: '/users/getUsers'
}
