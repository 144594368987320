<template src="./activityCourseAnalysisRevamped.html">
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import SemesterUserRepository from '../../Repository/SemesterUser'
import UserRepository from '../../Repository/User'
import CoursesRepository from "../../Repository/courses"
export default {
    name: 'activityCourseAnalysis',
    props: ["prop_subject", "prop_selectedSemester"],
    components: {
        apexcharts: VueApexCharts
    },
    data() {
        return {
            activeScreenTab: "Student Wise",
            chartOptions: {
                series: [0, 0, 1],
                colors: ["#04BDBD", "rgba(255, 202, 12, 0.80)", "#FF6F6F"],
                labels: ['Completed', 'Ongoing', 'Not Started'],
                chart: {
                    type: 'pie',
                    animations: {
                        enabled: false // Disable animations
                    }
                },
                plotOptions: {
                    pie: {
                        startAngle: 0,
                        endAngle: 360,
                        expandOnClick: true,
                        dataLabels: {
                            offset: 0
                        }
                    }
                }
            },
            headers: [
                { text: 'Roll No.', value: 'rollNo' },
                { text: 'Student Name', value: 'firstName' },
                { text: 'Progress', value: 'progressPercentage' },
                { text: 'Status', value: 'status' },
                { text: 'Contact Number', value: 'mobile' },
                { text: 'Email ID', value: 'email' },
                { text: 'Action', value: 'actions' }
            ],
            userData: [],
            uidArr: [],
            //Module-Wise
            activeModule: 0,
            subsectionStatusModal: false,
            activeSubsectionModal: {},
            completionModalArr: [],
            filteredCompletionModalArr: [],
            seacrhStudentField: '',
            isSubsectionModalLoading: true,
            completionProgress: {},
            courseCompletionProgress: [0, 0, 1],
            //Feedback
            totalReviews: 0,
            ratingSegregation: {},
            averageRating: 0,
            ratingSegregationPercentage: {},
            ratingModal: false,
            searchRatingStudent: null,
            idMapToUser: {},
            feedbackModalArr: [],
            feedbackModalFilteredArr: [],
            showCourseNotPublishedModal: false,
            //
            adminCourse: {}
        }
    },
    async created() {
        this.$store.commit("liveData/set_selectedActivityName", this.prop_subject.subjectName);
        this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
        this.userRepositoryInstance = new UserRepository(this)
        this.coursesRepositoryInstance = new CoursesRepository(this)
        if (!(this.prop_subject?.sections?.length !== 0)) {
            this.showCourseNotPublishedModal = true
            this.prop_subject.sections.subsections = []
        }

        const subject = await this.coursesRepositoryInstance.getSingleCourse({
                instituteId: this.prop_subject.instituteId,
                semId: this.prop_subject.semId,
                subjectId: this.prop_subject.subjectId,
                department: this.prop_subject.department,
                courseName: this.prop_subject.subjectName,
                courseYear: this.prop_subject.courseYear
            })
        this.adminCourse = { ...subject[0], ...this.prop_subject };

        this.getStudentsData()
        this.getCourseRatingDetails()
    },
    methods: {
        async changeActiveScreenTab(screen) {
            this.activeScreenTab = screen
        },
        async getStudentsData() {
            const semUsers = await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject({
                instituteId: this.prop_subject.instituteId,
                semId: this.prop_subject.semId,
                subjectId: this.prop_subject.subjectId
            })
            const uIds = [];
            for (let i = 0; i < semUsers.length; i++) {
                uIds.push(semUsers[i].uId);
            }
            this.uidArr = uIds;
            const users = await this.userRepositoryInstance.getUsers({ uIds: uIds });
            let tempSubsections = 0;
            for (let i = 0; i < this.adminCourse.sections.length; i++) {
                const section = this.adminCourse.sections[i];
                if (
                    section.isSectionPublished &&
                    section.subSections &&
                    Array.isArray(section.subSections)
                ) {
                    section.subSections.forEach((subsec) => {
                        if (subsec.isSubsectionPublished) tempSubsections += 1;
                    });
                }
            }
            const { data } = await this.coursesRepositoryInstance.getProgressPercentageOfStudentsInCourse({
                users: uIds,
                courseId: this.adminCourse.subjectId,
                totalSubsections: tempSubsections
            });

            users.forEach(usr => {
                this.idMapToUser[usr.uId] = usr.firstName + ' ' + usr.lastName
            })

            this.completionProgress = data;
            Object.values(data).forEach(val => {
                if (val === 100) this.courseCompletionProgress[0] += 1;
                else if (val !== 0) this.courseCompletionProgress[1] += 1;
                else this.courseCompletionProgress[2] += 1
            })
            this.courseCompletionProgress[2] -= 1
            console.log(users);
            this.chartOptions.series = this.courseCompletionProgress
            this.userData = users;
        },
        async goToCourseMaterial(user) {
            this.$router.push({
                name: "activityCourseMaterial",
                params: {
                    prop_subject: this.adminCourse,
                    prop_user: user,
                    prop_instituteId: this.adminCourse.instituteId,
                    prop_selectedSemester: this.prop_selectedSemester,
                    prop_department: this.adminCourse.department,
                    prop_courseYear: this.adminCourse.courseYear,
                    prop_subjectId: this.adminCourse.subjectId,
                    prop_courseName: this.adminCourse.courseName
                }
            });
        },
        async getStatus(id) {
            console.log(this.completionProgress[id])
            if (this.completionProgress[id] === 0) return "Not Started"
            else if (this.completionProgress[id] !== 100) return "Ongoing"
            else return "Completed"
        },
        async getSubsectionCompletionStatus(subsection) {
            this.isSubsectionModalLoading = true
            try {
                const { data } = await this.coursesRepositoryInstance.getSubsectionCompletionStatus({
                    users: this.uidArr,
                    subSectionId: subsection.subSectionId,
                    courseId: this.adminCourse.subjectId
                });
                const targetArr = [];
                this.userData.forEach(user => {
                    targetArr.push({
                        id: user.uId,
                        isSectionCompleted: data[user.uId] ?? false,
                        name: user.firstName + ' ' + user.lastName
                    })
                })
                this.completionModalArr = targetArr;
                this.filteredCompletionModalArr = targetArr;
                this.seacrhStudentField = null;
            } catch (e) {
                console.log(e);
                this.completionModalArr = [];
                this.filteredCompletionModalArr = [];
            } finally {
                this.isSubsectionModalLoading = false
                this.subsectionStatusModal = true
            }
        },
        handleFilterStudents() {
            if (this.seacrhStudentField !== null && this.seacrhStudentField !== "") {
                const tempFilteredArr = this.completionModalArr.filter(student => {
                    const test = student.name.toLocaleUpperCase();
                    const inp = this.seacrhStudentField.toLocaleUpperCase()
                    return test.startsWith(inp);
                })
                this.filteredCompletionModalArr = tempFilteredArr;
            } else this.filteredCompletionModalArr = this.completionModalArr;
        },
        handleFilterRatingStudents() {
            if (this.searchRatingStudent !== null && this.searchRatingStudent !== "") {
                const tempFilteredArr = this.feedbackModalArr.filter(student => {
                    const test = student.toLocaleUpperCase();
                    const inp = this.searchRatingStudent.toLocaleUpperCase()
                    return test.startsWith(inp);
                })
                this.feedbackModalFilteredArr = tempFilteredArr;
            } else this.feedbackModalFilteredArr = this.feedbackModalArr;
        },
        openFeedbackStudentsModal(id) {
            const resArr = [];
            if (this.ratingSegregation && this.ratingSegregation[id]) {
                this.ratingSegregation[id].forEach(id => {
                id.name = this.idMapToUser[id.uId];
                resArr.push(id)
            })
            }
            this.feedbackModalArr = resArr;
            this.feedbackModalFilteredArr = resArr;
            this.ratingModal = true;
        },
        async getCourseRatingDetails() {
            const { data } = await this.coursesRepositoryInstance.getCourseRatingDetails({
                courseId: this.adminCourse.subjectId
            });
            this.averageRating = data.averageRating;
            this.ratingSegregation = data.ratingSegregation;
            Object.keys(data.ratingSegregation).forEach(key => {
                this.ratingSegregationPercentage[key] = (data.ratingSegregation[key].length / data.totalRatings) * 100
            })
            this.totalReviews = data.totalRatings
        },
        gotoactivityCourseQuizResult(uid) {
            this.$router.push({
                name: "activityQuizResult",
                params: {
                    prop_subject: this.adminCourse,
                    prop_uId: uid,
                    prop_subSectionId: this.activeSubsectionModal.subSectionId,
                    prop_selectedSemester: this.prop_selectedSemester
                }
            });
        },
        gotoactivityCourseAssignmentCheck(uid, name) {
            this.$router.push({
                name: "activityCourseAssignmentCheck",
                params: {
                    prop_subject: this.adminCourse,
                    prop_uId: uid,
                    prop_subSectionId: this.activeSubsectionModal.subSectionId,
                    prop_selectedSemester: this.prop_selectedSemester,
                    prop_userName: name
                }
            });
        },
        gotoactivityCourseSubsectionFeedback(uid, name) {
            this.$router.push({
                name: "activityCourseSubsectionFeedback",
                params: {
                    prop_subject: this.adminCourse,
                    prop_uId: uid,
                    prop_subSectionId: this.activeSubsectionModal.subSectionId,
                    prop_selectedSemester: this.prop_selectedSemester,
                    prop_userName: name
                }
            });
        },
        goBack() {
            this.$router.push({
                name: "activitySubjectCourses",
                params: {
                    prop_subject: this.prop_subject,
                    prop_selectedSemester: this.prop_selectedSemester
                }
            });
        }
    }
}
</script>
<style src="./activityCourseAnalysisRevamped.css" scoped></style>
