<template>
    <div>
        <v-dialog v-model="isDocModalOpen" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-toolbar color="primary" style="color: #fff">
                <v-btn icon @click="isDocModalOpen = false, docLink = ''" style="color: #fff" class="ml-auto">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <iframe :src="docLink" frameborder="0" class="framePdf"></iframe>
        </v-dialog>
        <inputContainer v-for="obj, i in pdfList" :key="i" style="margin:10px 8px; padding:20px; height:unset">
            <v-row @click='openFileInPdfViewer(obj)' style="cursor: pointer">
                <v-col :cols="isMobileView ? 12 : 11">
                    <span style="justify-self: start; color:#3a3a3a; font-size: 16px; font-weight:400; cursor: pointer; background-color: #DBDDFF; border-radius: 4px;">
                        <span color="#3a3a3a">{{ decodeURI(getFileName(obj)) }}</span>
                    </span>
                </v-col>
                <!-- <v-col :cols="isMobileView ? 6 : 3">
                    <v-btn style="text-transform: capitalize" small @click='openFileInPdfViewer(obj)'>
                        Open
                    </v-btn>
                </v-col> -->
                <v-col :cols="isMobileView ? 6 : 1" v-if="makeAllAttachmentDownloadable">
                    <a :href="obj" download style="text-decoration: none; color:#00128C">
                        <v-icon small
                            color="#00128C">mdi-download
                        </v-icon> Download</a>
                </v-col>
                <v-col :cols="isMobileView ? 6 : 1">
                        <v-icon small style="font-size: 20px;"
                            color="#00128C">mdi-eye-outline
                        </v-icon>
                </v-col>
            </v-row>
        </inputContainer>
        <inputContainer v-for="obj, i in audiosList" :key="i" style=" padding: 20px 20px 0 20px; height:unset; background-color: none;">
            <v-row justify="center" align="center" style="display: flex; flex-direction: column">
                <v-col style="color:#000; font-weight:400; font-size: 16px; padding: 0px">{{ decodeURI(getFileName(obj)) }}</v-col>
                <v-col style="padding: 12px 0"><audio width="100%" height="auto" controls :controlsList="controlList">
                        <source :src="obj" />
                        Your browser does not support the video tag.
                    </audio></v-col>
                <br>
                <v-col v-if="makeAllAttachmentDownloadable">
                    <a :href="obj" download style="text-decoration: none; color:#00128C"><v-icon small
                            color="#00128C">mdi-download
                        </v-icon> Download</a>
                </v-col>
            </v-row>
        </inputContainer>

        <inputContainer v-if="link && link.trim() !== ''" style="padding: 0 10px 0 10px; height: unset; background-color: none">
            <a :href="link" target="_blank" style = "justify-self: start; font-size: 14px; color: #3a3a3a; font-weight: normal; text-decoration: underline; cursor: pointer">
                {{ link }}
            </a>
            <!-- <div style="justify-self: end;">
                <v-btn elevation="2" rounded style="color:#FF4F1F" small
                    @click="copyLink(link)"><v-icon>mdi-content-copy</v-icon> Copy
                    Link</v-btn>
            </div> -->
        </inputContainer>

        <inputContainer v-for="obj, i in videosList" :key="i" style="margin:10px; padding:20px 0; height:unset; background-color: none">
            <v-row style="margin-bottom: -20px">
                <v-col :cols="isMobileView ? 12 : 8">
                    <span style="justify-self: start; color:#000; font-size: 16px;font-weight: normal">
                        <span color="#3a3a3a">{{ decodeURI(getFileName(obj)) }}</span>
                    </span>
                </v-col>
                <v-col v-if="makeAllAttachmentDownloadable">
                    <a :href="obj" download style="text-decoration: none; color:#00128C"><v-icon small
                            color="#00128C">mdi-download
                        </v-icon> Download</a>
                </v-col>
            </v-row>
            <br>
            <video width="100%" height="auto" controls :controlsList="controlList">
                <source :src="obj" />
                Your browser does not support the video tag.
            </video>
        </inputContainer>
        <br>
        <v-carousel style="padding: 0 10px;" v-if="imagesList.length > 0" cycle hide-delimiter-background show-arrows-on-hover>
            <v-carousel-item v-for="(image, i) in imagesList" :key="i">
                <v-img :src="image"></v-img>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>
<script>
import inputContainer from '../inputContainer.vue';
import { v4 } from "uuid"
export default {
    name: 'confirmationBox',
    components: { inputContainer },
    props: {
        images: Array,
        otherMedia: Array,
        attachedFiles: Array,
        link: String,
        mediaLibrarySelects: Array,
        makeAllAttachmentDownloadable: Boolean
    },
    data() {
        return {
            imagesList: [],
            videosList: [],
            audiosList: [],
            otherMediaList: [],
            pdfList: [],
            uuid: v4,
            isMobileView: false,
            docLink: '',
            isDocModalOpen: false,
            controlList: ""
        }
    },
    created() {
        this.controlList = this.makeAllAttachmentDownloadable ? "download" : "nodownload"
        this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
        this.attachedFiles.forEach(file => {
            if (file.type.split("/")[0] === "audio") {
                this.audiosList.push(file.url)
            } else if (file.type.split("/")[0] === "Video") {
                this.videosList.push(file.url)
            } else if (file.type.split("/")[0] === "image") {
                this.imagesList.push(file.url)
            } else if (file.type === "application/pdf") {
                this.pdfList.push(file.url)
            } else {
                this.otherMediaList.push(file.url)
            }
        })
        this.mediaLibrarySelects.forEach(file => {
            if (file.fileType.split("/")[0] === "audio") {
                file.linksOfAttachments !== "" && this.audiosList.push(file.linksOfAttachments)
            } else if (file.fileType.split("/")[0] === "video") {
                file.linksOfAttachments !== "" && this.videosList.push(file.linksOfAttachments)
            } else if (file.fileType.split("/")[0] === "image") {
                file.linksOfAttachments !== "" && this.imagesList.push(file.linksOfAttachments)
            } else if (file.fileType === "application/pdf") {
                file.linksOfAttachments !== "" && this.pdfList.push(file.linksOfAttachments)
            } else {
                file.linksOfAttachments !== "" && this.otherMediaList.push(file.linksOfAttachments)
            }
        })
        this.imagesList.push(...this.images)
    },
    methods: {
        getFileName(url) {
            if (url.includes("digitaloceanspaces")) {
                return url.split("/").slice(-1)[0]
            } else if (url.includes("courses-bucket/")) {
                return url.split("/").slice(-1)[0]
            } else {
                const decodedUrl = decodeURIComponent(url);
                const lastIndexOfDelimiter = decodedUrl.lastIndexOf("________");
                const indexOfQueryParams = decodedUrl.lastIndexOf("?alt");
                return decodedUrl.substring(
                    lastIndexOfDelimiter + "________".length,
                    indexOfQueryParams
                )
            }
        },
        openFile(url) {
            window.open(url, '_blank');
        },
        copyLink(url) {
            console.log(url);
            window.navigator.clipboard.writeText(url)
        },
        openFileInPdfViewer(attachment) {
            console.log(attachment);
            const PDF_VIEWER = new URL('https://pdf-viewer-seven.vercel.app/')
            PDF_VIEWER.searchParams.set('pdf', attachment)
            console.log(PDF_VIEWER);
            this.docLink = PDF_VIEWER
            this.isDocModalOpen = true
        }
    }
}
</script>
<style scoped>
.framePdf {
    width: 100%;
    height: 100vh;
    background-color: white;
}
</style>
