export default {
  updateCourseInfo: "/courses/updateCourseInfo", //post
  getSingleCourse: "/courses/getSingleCourse", //get
  mediaLibrary: "/mediaLibrary/getMediaLibrary",
  getAllCoursesInfoOfInstitute: "/courses/getAllCoursesInfoOfInstitute",
  getAllCoursesInfoOfStudent: "/courses/getAllCoursesInfoOfStudent",
  createStudentCourse: "/courses/createStudentCourse",
  getAllMediaLibraryByInstitute: "/mediaLibrary/getAllMediaLibraryByInstitute",
  getSingleStudentCourse: "/courses/getSingleStudentCourse",
  getSignedUrl: "/spaces/presigned_url",
  getPresignedUrlForR2: "/mediaLibrary/getPresignedUrlForR2",
  generateStudentCourseReport: "/excel/courses/generateStudentCourseReport",
  generateStudentFeedbackReport: "/excel/courses/generateStudentFeedbackReport",
  getProgressPercentageOfStudentsInCourse:
    "/courses/getProgressPercentageOfStudentsInCourse",
  getSyncCoursesDataOfSingleStudent: "/courses/syncCoursesDataOfSingleStudent",
  getSubsectionCompletionStatus: "/courses/getSubsectionCompletionStatus",
  getCourseRatingDetails: "/courses/getCourseRatingDetails",
  getMultiCourseRatings: "/courses/getMultiCourseRatings",
  createMultipleLogs: "/logger/courses/createMultipleLogs",
  getPublishLogs: "/logger/courses/getPublishLogs"
};
