export default {
  createOnlineLecture: '/onlineLecture/createOnlineLecture',
  getAttendanceOfAnOnlineLecture: '/onlineLecture/getAttendanceOfAnOnlineLecture',
  getIsOngoingForALecture: '/onlineLecture/getIsOngoingForALecture',
  getIsOngoingForALectures: '/onlineLecture/getIsOngoingForALectures',
  getRecordingOfAnOnlineLecture: '/onlineLecture/getRecordingOfAnOnlineLecture',
  updateOnlineLecture: '/onlineLecture/updateOnlineLecture',
  createOnlineLectureForStudents: '/onlineLectureStudent/createOnlineLectureForStudents',
  createOnlineLectureForStudentsNew: '/onlineLectureStudent/createOnlineLectureForStudentsNew'
}
