const state = {
  subjectsMapping: {}
}

const getters = { // getters for state
  get_subjectsMapping: state => state.subjectsMapping
}

const mutations = { // mutations for state
  add_subjectsMapping: (state, value) => {
    state.subjectsMapping[value.subjectId] = value
  },
  reset (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

const actions = { // actions for state
}

export default { // exports the vuex object of 'institutesData' store
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

function initialState () {
  return {
    subjectsMapping: {}
  }
}
