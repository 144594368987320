<template src="./examCopyMarks.html"></template>

<script>
import ExamRepository from "../../../Repository/NewExam";
import apiV3 from '../../../NetworkManager/apiV3';
import AssignmentUserRepo from '../../../Repository/AssignmentUser';
import UserRepository from "../../../Repository/User";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import ExamSubjectAndQuestionRepository from "../../../Repository/ExamSubjectAndQuestion"

export default {
    name: 'examCopyMarks',
    props: [
        'prop_selectedSubject',
        'prop_selectedSubjectId',
        'prop_tabNumber',
        'prop_allSubjects',
        'prop_selectedItem',
        'prop_exam'
    ],
    data() {
        return {
            allStudentDataArr: [],
            errorArray: [],
            headerForStudent: [],
            selectedStudent: [],
            totalMarksString: '',
            allUserData: {},
            totalOfSelectedExam: 0,
            selectedExams: [],
            examSelectionDialog: false,
            userData: null,
            tab: '',
            selectedDepartment: '',
            selectedCourseYear: '',
            selectedInstitute: '',
            selectedSemester: {},
            examId: '',
            totalMarks: '',
            headersForStuDummy: [
                {
                    text: "Sr.No",
                    value: "srNo"
                },
                {
                    text: "PRN",
                    value: "prn"
                },
                {
                    text: "Roll No",
                    value: "rollNumber"
                },
                {
                    text: "Student Name",
                    value: "fullName"
                },
                {
                    text: "Total Marks Obtained",
                    value: "totalMarks"
                }
            ],
            headersForList: [
                {
                    text: "Sr.No",
                    value: "srNo"
                },
                {
                    text: "Name of the assignment/Quicktest",
                    value: "title"
                },
                {
                    text: "Type",
                    value: "type"
                },
                {
                    text: "Published Date and Time",
                    value: "date"
                },
                {
                    text: "Total Marks",
                    value: "totalMarks"
                }
            ],
            allExams: [],
            markBelow: false,
            reducedMarksFlag: false,
            markAbove: false,
            markEqual: false,
            copyDialog: false,
            reducedMarksTotal: 0,
            objForExamMarks: {}
        }
    },
    created() {
        this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
        this.examSubjectAndQuestionRepositoryInstance = new ExamSubjectAndQuestionRepository(this);
        this.examRepositoryInstance = new ExamRepository(this);
        this.assignmentRepositoryInstance = new AssignmentUserRepo(this);
        this.userRepositoryInstance = new UserRepository(this);
        this.userData = this.$store.getters['user/get_userData']
        this.selectedDepartment = this.$store.getters["liveData/get_examDepartment"];
        this.selectedCourseYear = this.$store.getters["liveData/get_examCourseYear"];
        this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
        this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];
        this.examId = this.$store.getters["liveData/get_examId"];
        this.totalMarks = `Total Marks : ${this.prop_selectedItem.totalMarks}`
        this.fetchData()
        // console.log('this.userData', this.userData);
        // console.log('this.prop_exam', this.prop_exam);
        // console.log('this.prop_selectedItem', this.prop_selectedItem);
    },

    methods: {
        goBack() {
            this.$router.push({
                name: 'examStudentMarks',
                params: {
                    prop_selectedSubject: this.prop_selectedSubject,
                    prop_selectedSubjectId: this.prop_selectedSubjectId,
                    prop_tabNumber: this.prop_tabNumber,
                    prop_allSubjects: this.prop_allSubjects,
                    prop_selectedItem: this.prop_selectedItem,
                    prop_selectedRadio: null
                }
            })
        },
        async fetchData() {
            this.allExams = []
            this.selectedExams = []
            try {
                const data = await this.examRepositoryInstance.getAssignmentForFacultyForSubject({
                    instituteId: this.selectedInstitute,
                    semId: this.selectedSemester.semId,
                    department: this.selectedDepartment,
                    courseYear: this.selectedCourseYear,
                    uId: this.userData.uId,
                    subjectId: this.prop_selectedSubjectId
                })
                // console.log('data', data);
                if (Array.isArray(data) && data.length > 0) {
                    data.map((item) => {
                        const obj = {
                            title: item.title,
                            type: 'Assignment',
                            date: this.getFormattedDate(item.publishDate),
                            id: item.examId,
                            totalMarks: Number(item.totalMarks),
                            reducedMarks: Number(item.totalMarks)
                        }
                        this.allExams.push(obj)
                    })
                }
                // console.log('this.allExams', this.allExams);
                const { exams = [] } = await apiV3.getRequest('/exam/all', {
                    category: 'quick_exam',
                    facultyId: this.userData.uId,
                    subjectId: this.prop_selectedSubjectId,
                    semId: this.selectedSemester.semId
                })
                // console.log('exams', exams);
                if (Array.isArray(exams) && exams.length > 0) {
                    exams.map((item) => {
                        const obj = {
                            title: item.name,
                            id: item._id,
                            type: 'Quick Test',
                            date: this.getFormattedDate(item.dateTimeUTC),
                            totalMarks: Number(item.totalMarks),
                            reducedMarks: Number(item.totalMarks)
                        }
                        this.allExams.push(obj)
                    })
                }
            } catch (error) {
                console.log('error', error);
            }
        },
        getFormattedDate(date1) {
            const date = new Date(date1);
            const day = String(date.getUTCDate()).padStart(2, '0');
            const month = date.getUTCMonth() + 1;
            const year = date.getUTCFullYear();
            return `${day}-${month}-${year}`;
        },
        dialogOpenForExams() {
            this.markBelow = false
            this.markAbove = false
            this.markEqual = false
            this.reducedMarksFlag = false
            this.examSelectionDialog = true
            this.totalOfSelectedExam = 0
            this.errorArray = []
            // console.log(' this.selectedExams', this.selectedExams);
            this.selectedExams.map((item) => {
                this.totalOfSelectedExam = this.totalOfSelectedExam + Number(item.totalMarks)
            })
            this.reducedMarksTotal = this.totalOfSelectedExam
            if (this.prop_selectedItem.totalMarks > this.totalOfSelectedExam) {
                this.markBelow = true
                // this.totalMarksString = `Total Marks: ${this.totalOfSelectedExam}`
            }
            if (this.prop_selectedItem.totalMarks < this.totalOfSelectedExam) {
                this.markAbove = true
                // this.totalMarksString = `Total Marks: ${this.totalOfSelectedExam}`
            }
            if (this.prop_selectedItem.totalMarks === this.totalOfSelectedExam) {
                this.markEqual = true
                this.reducedMarksFlag = true
                // this.totalMarksString = `Total Marks: ${this.totalOfSelectedExam}`
            }
        },
        async getAssignmentResponse(arr) {
            let data = []
            try {
                data = await this.assignmentRepositoryInstance.getAssignmentUserForMultipleAssignment({
                    instituteId: this.selectedInstitute,
                    semId: this.selectedSemester.semId,
                    assignmentIds: arr
                })
                // console.log('data', data);
            } catch (error) {
                console.log('error', error);
            }
            return data
        },
        async getQuickTestResponse(arr) {
            let data = []
            try {
                data = await apiV3.getRequest(`/exam/getMultipleExamAnswer?examId=${arr}`)
                data = data.data ? data.data : []
            } catch (error) {
                console.log('error', error);
            }
            return data
        },
        async getFullNameUser(arr) {
            let data = []
            try {
                data = await this.userRepositoryInstance.getFullNameOfUsers({ uIds: arr });
            } catch (error) {
                console.log('error', error);
            }
            return data
        },
        async getSemUser(arr) {
            let data = []
            try {
                data =
                    await this.semesterUserRepositoryInstance.getMultipleSemesterUsers({
                        instituteId: this.selectedInstitute,
                        semId: this.selectedSemester.semId,
                        uIds: arr
                    });
            } catch (err) {
                console.error(err);
            }
            return data
        },
        async updateMarksInExam(arr) {
            try {
                await this.examSubjectAndQuestionRepositoryInstance.createExamSubjectQuestions({
                    instituteId: this.selectedInstitute,
                    examId: this.examId,
                    subjectId: this.prop_selectedSubjectId,
                    examMarks: arr
                });
            } catch (err) {
                console.error(err);
            }
        },
        convertMarksToReducedMarks(totalMarks, obtainedMarks, reducedTo) {
            let marks = 0
            marks = ((Number(obtainedMarks) * reducedTo) / totalMarks).toFixed(0)
            return Number(marks)
        },
        reduceMarksChange() {
            this.errorArray = [];
            this.reducedMarksTotal = 0
            this.selectedExams.map((item) => {
                this.reducedMarksTotal = this.reducedMarksTotal + Number(item.reducedMarks)
            })
        },
        async getMarksForExam() {
            this.errorArray = []
            this.reducedMarksFlag = false
            this.reducedMarksTotal = 0
            if (this.markAbove === true) {
                let marks = 0
                this.selectedExams.map((item) => {
                    marks = marks + Number(item.reducedMarks)
                    if (Number(item.reducedMarks) === 0) {
                        this.errorArray.push('Reduced Marks can not be zero')
                    }
                })
                this.reducedMarksTotal = marks
                if (marks === this.prop_selectedItem.totalMarks) {
                    this.reducedMarksFlag = true
                }
                if (marks > this.prop_selectedItem.totalMarks) {
                    this.errorArray.push(`Reduced Marks above ${this.prop_selectedItem.totalMarks}`)
                }
                if (marks < this.prop_selectedItem.totalMarks) {
                    this.errorArray.push(`Reduced Marks below ${this.prop_selectedItem.totalMarks}`)
                }
                if (this.errorArray.length > 0) {
                    return
                }
            }
            this.examSelectionDialog = false;
            const arr = []
            const arr1 = []
            const objForExamIdAndReducedMarks = {}
            this.allStudentDataArr = []
            this.headerForStudent = [...this.headersForStuDummy]
            this.selectedExams.map((item) => {
                objForExamIdAndReducedMarks[item.id] = []
                objForExamIdAndReducedMarks[item.id]['reducedMarks'] = Number(item.reducedMarks)
                objForExamIdAndReducedMarks[item.id]['totalMarks'] = item.totalMarks
                if (item.type === 'Assignment') {
                    arr.push(item.id)
                } else {
                    arr1.push(item.id)
                }
                this.headerForStudent.push({
                    text: item.title + ` ( out of ${item.reducedMarks})`,
                    value: String(item.id)
                })
            })
            // console.log('0');
            this.objForExamMarks = objForExamIdAndReducedMarks
            // console.log('objForExamIdAndReducedMarks', objForExamIdAndReducedMarks);
            const allAssignmentUserRes = await this.getAssignmentResponse(arr)
            const getQuickTestResponse = await this.getQuickTestResponse(arr1)
            this.allUserData = {}
            const uIds = []
            // console.log('1');
            if (allAssignmentUserRes.length > 0) {
                for (let i = 0; i < allAssignmentUserRes.length; i++) {
                    if (this.prop_selectedItem.allocatedStudents.includes(allAssignmentUserRes[i].uId)) {
                        uIds.push(allAssignmentUserRes[i].uId)
                    }
                    if (!this.allUserData[allAssignmentUserRes[i].uId]) {
                        this.allUserData[allAssignmentUserRes[i].uId] = {}
                        this.allUserData[allAssignmentUserRes[i].uId][allAssignmentUserRes[i].examId] = this.convertMarksToReducedMarks(objForExamIdAndReducedMarks[allAssignmentUserRes[i].examId]['totalMarks'], allAssignmentUserRes[i].totalMarksObtained, objForExamIdAndReducedMarks[allAssignmentUserRes[i].examId]['reducedMarks'])
                        this.allUserData[allAssignmentUserRes[i].uId]['totalMarks'] = objForExamIdAndReducedMarks[allAssignmentUserRes[i].examId]['reducedMarks']
                        this.allUserData[allAssignmentUserRes[i].uId]['totalObtained'] = this.convertMarksToReducedMarks(objForExamIdAndReducedMarks[allAssignmentUserRes[i].examId]['totalMarks'], allAssignmentUserRes[i].totalMarksObtained, objForExamIdAndReducedMarks[allAssignmentUserRes[i].examId]['reducedMarks'])
                    } else {
                        this.allUserData[allAssignmentUserRes[i].uId][allAssignmentUserRes[i].examId] = this.convertMarksToReducedMarks(objForExamIdAndReducedMarks[allAssignmentUserRes[i].examId]['totalMarks'], allAssignmentUserRes[i].totalMarksObtained, objForExamIdAndReducedMarks[allAssignmentUserRes[i].examId]['reducedMarks'])
                        this.allUserData[allAssignmentUserRes[i].uId]['totalObtained'] = this.allUserData[allAssignmentUserRes[i].uId]['totalObtained'] + this.convertMarksToReducedMarks(objForExamIdAndReducedMarks[allAssignmentUserRes[i].examId]['totalMarks'], allAssignmentUserRes[i].totalMarksObtained, objForExamIdAndReducedMarks[allAssignmentUserRes[i].examId]['reducedMarks'])
                        this.allUserData[allAssignmentUserRes[i].uId]['totalMarks'] = this.allUserData[allAssignmentUserRes[i].uId]['totalMarks'] + objForExamIdAndReducedMarks[allAssignmentUserRes[i].examId]['reducedMarks']
                    }
                }
            }
            // console.log('2');
            if (getQuickTestResponse.length > 0) {
                for (let i = 0; i < getQuickTestResponse.length; i++) {
                    // console.log('getQuickTestResponse[i]', getQuickTestResponse[i]);
                    const examId = getQuickTestResponse[i].examId
                    // console.log('examId', examId);
                    if (this.prop_selectedItem.allocatedStudents.includes(getQuickTestResponse[i].uId)) {
                        uIds.push(getQuickTestResponse[i].studentId)
                    }
                    if (!this.allUserData[getQuickTestResponse[i].studentId]) {
                        this.allUserData[getQuickTestResponse[i].studentId] = {}
                        this.allUserData[getQuickTestResponse[i].studentId][examId] = this.convertMarksToReducedMarks(objForExamIdAndReducedMarks[examId]['totalMarks'], getQuickTestResponse[i].totalMarksObtained, objForExamIdAndReducedMarks[examId]['reducedMarks'])
                        console.log('this.allUserData[getQuickTestResponse[i].uId]', this.allUserData[getQuickTestResponse[i].studentId]);
                        this.allUserData[getQuickTestResponse[i].studentId]['totalMarks'] = objForExamIdAndReducedMarks[getQuickTestResponse[i].examId]['reducedMarks']
                        this.allUserData[getQuickTestResponse[i].studentId]['totalObtained'] = this.convertMarksToReducedMarks(objForExamIdAndReducedMarks[getQuickTestResponse[i].examId]['totalMarks'], getQuickTestResponse[i].totalMarksObtained, objForExamIdAndReducedMarks[getQuickTestResponse[i].examId]['reducedMarks'])
                    } else {
                        // console.log('else');
                        this.allUserData[getQuickTestResponse[i].studentId][examId] = this.convertMarksToReducedMarks(objForExamIdAndReducedMarks[examId]['totalMarks'], getQuickTestResponse[i].totalMarksObtained, objForExamIdAndReducedMarks[examId]['reducedMarks'])
                        this.allUserData[getQuickTestResponse[i].studentId]['totalMarks'] = this.allUserData[getQuickTestResponse[i].studentId]['totalMarks'] + objForExamIdAndReducedMarks[getQuickTestResponse[i].examId]['reducedMarks']
                        this.allUserData[getQuickTestResponse[i].studentId]['totalObtained'] = this.allUserData[getQuickTestResponse[i].studentId]['totalObtained'] + this.convertMarksToReducedMarks(objForExamIdAndReducedMarks[getQuickTestResponse[i].examId]['totalMarks'], getQuickTestResponse[i].totalMarksObtained, objForExamIdAndReducedMarks[getQuickTestResponse[i].examId]['reducedMarks'])
                    }
                }
            }
            // console.log('3');
            const allStudentName = await this.getFullNameUser(uIds)
            const allStudentSem = await this.getSemUser(uIds)
            // console.log('4');
            if (allStudentName.length > 0) {
                for (let i = 0; i < allStudentName.length; i++) {
                    this.allUserData[allStudentName[i].uId]['fullName'] = allStudentName[i].fullName
                    this.allUserData[allStudentName[i].uId]['prn'] = allStudentName[i].collegePRNNo ? allStudentName[i].collegePRNNo : '-'
                }
            }
            if (allStudentSem.length > 0) {
                for (let i = 0; i < allStudentSem.length; i++) {
                    this.allUserData[allStudentName[i].uId]['rollNumber'] = allStudentSem[i].rollNumber ? allStudentSem[i].rollNumber : '-';
                    this.allUserData[allStudentName[i].uId]['uId'] = allStudentName[i].uId
                    this.allStudentDataArr.push(this.allUserData[allStudentName[i].uId])
                }
            }
            // console.log('this.allStudentDataArr', this.allStudentDataArr);
            // console.log('this.headerForStudent', this.headerForStudent);
            this.tab = 1
        },
        tabChanged() {
            // console.log('abc');
            this.errorArray = []
        },
        async copyMarksToExam() {
            const arr = []
            this.allStudentDataArr.map((item) => {
                const marks = item.totalObtained === null || item.totalObtained === undefined ? 0 : item.totalObtained
                arr.push({
                    uId: item.uId,
                    totalMarksObtained: String(marks),
                    answers: [{
                        obtainedMarks: String(marks)
                    }]
                })
            })
            await this.updateMarksInExam(arr)
            this.goBack()
            // console.log('arr', arr);
        }
    }
}

</script>

<style src="./examCopyMarks.css"></style>
