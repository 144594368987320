import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"assessmentHeadWrapper"},[_c('div',{attrs:{"id":"tableTitle"}},[_vm._v("Ongoing Assessments")]),_c(VBtn,{attrs:{"color":_vm.primaryOrange,"id":"createAssessmentBtn"},on:{"click":_vm.createAssessment}},[_vm._v("+ New Assessment")])],1),_c(VDataTable,{staticClass:"assessmentTable",attrs:{"headers":_vm.assessmentHeaders,"items":_vm.assessmentList,"loading":_vm.assessmentsLoaded,"items-per-page":10,"loading-text":"Loading assessments... Please wait"},scopedSlots:_vm._u([{key:"item.isForNBA",fn:function(ref){
var item = ref.item;
return [(item.isForNBA)?_c('div',{attrs:{"id":"nbaFlagWrapper"}},[_c(VIcon,{staticClass:"nbaFlagIcon"},[_vm._v("mdi-label-variant")]),_c('span',{staticClass:"nbaTitle"},[_vm._v("NBA")])],1):_vm._e()]}},{key:"item.publishedDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.dateOfAssignment.slice(0,10)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'statusPublished': item.status === 'Published', 'statusDraft': item.status === 'Draft' }},[_vm._v(_vm._s(item.status))])]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.deadlineForSubmission.slice(0,10))+", "+_vm._s(item.time))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"deleteItemIcon",on:{"click":function($event){return _vm.confirmDeleteAssessment(item)}}},[_vm._v("mdi-delete-outline")]),_c(VIcon,{staticClass:"gotoItemIcon",on:{"click":function($event){return _vm.gotoEditAssessment(item)}}},[_vm._v("mdi-arrow-right-circle")])]}},{key:"item.allowedFileTypes",fn:function(ref){
var item = ref.item;
return _vm._l((item.allowedFileTypes),function(f,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(f.text)+", ")])})}},{key:"no-data",fn:function(){return [_c('span',{staticStyle:{"color":"#fd0000"}},[_vm._v("No Assessments are created for this subject yet.")])]},proxy:true}])}),_vm._m(0),_c(VDialog,{attrs:{"width":"35%"},model:{value:(_vm.deleteAssessmentDialog),callback:function ($$v) {_vm.deleteAssessmentDialog=$$v},expression:"deleteAssessmentDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Delete "+_vm._s(_vm.assessmentToDelete.title)+"?")]),_c(VCardText,[_c('div',[_vm._v("All data related to this assessment will be deleted. "),(_vm.assessmentForNba)?_c('span',[_vm._v("This assessment is also linked to "),_c('strong',[_vm._v("NBA")]),_vm._v(".")]):_vm._e(),_vm._v(" Do you still want to delete?")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":_vm.primaryOrange,"outlined":""},on:{"click":function($event){_vm.deleteAssessmentDialog = false}}},[_vm._v("Cancel")]),_c(VBtn,{staticStyle:{"color":"#ffffff"},attrs:{"color":_vm.primaryOrange,"loading":_vm.deleteAssessmentLoader,"filled":""},on:{"click":_vm.deleteAssessment}},[_vm._v("Delete")])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"assessmentHeadWrapper"},[_c('div',{attrs:{"id":"tableTitle"}},[_vm._v("Past Assessments")])])}]

export { render, staticRenderFns }