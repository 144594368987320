// this is the main class of the router which will contain all the routes

// Import statements to get all required libraries
import Vue from 'vue'
import Router from 'vue-router' // library for creating Router object
import validateTravel from './routerGuard'

// Import statements to get all required components for routing
import activityWelcome from '../Activities/activityWelcome/activityWelcome.vue'
import activityHome from '../Activities/activityHome/activityHome'
import activitySetup from '../Activities/activitySetup/activitySetup'
import activityProfile from '../Activities/activityProfile/activityProfile'
import activitySelectInstituteNSemester from '../Activities/activitySelectInstituteNSemester/activitySelectInstituteNSemester'
import activityDirections from '../Activities/activityDirections/activityDirections'
import activityCreateInstituteOrUser from '../Activities/activityCreateInstituteOrUser/activityCreateInstituteOrUser'
import activityMySubjects from '../Activities/activityMySubjects/activityMySubjects'
import activityMyTimetable from '../Activities/activityMyTimetable/activityMyTimetable'
import activityArrangeLecture from '../Activities/activityArrangeLecture/activityArrangeLecture'
import activityTakeAttendance from '../Activities/activityTakeAttendance/activityTakeAttendance'
import activityAttendanceAnalysis from '../Activities/activityAttendanceAnalysis/activityAttendanceAnalysis'
import activityCumulativeAttendance from '../Activities/activityCumulativeAttendance/activityCumulativeAttendance'
import activityTopics from '../Activities/activityTopics/activityTopics'
import activityRescheduleRequests from '../Activities/activityRescheduleRequests/activityRescheduleRequests'
import activityAssignments from '../Activities/activityAssignments/activityAssignments'
import activityCheckAssignments from '../Activities/activityCheckAssignments/activityCheckAssignments'
import activityTimeline from '../Activities/activityTimeline/activityTimeline'
import activityDiscussion from '../Activities/activityDiscussion/activityDiscussion'
import activityNotifications from '../Activities/activityNotifications/activityNotifications'
import activityChangeSemester from '../Activities/activityChangeSemester/activityChangeSemester'
import activityTopicFeedback from '../Activities/activityTopicFeedback/activityTopicFeedback'
import activityTopicFeedbackAction from '../Activities/activityTopicFeedbackAction/activityTopicFeedbackAction'
import activityQuickTest from '../Activities/activityQuickTest/activityQuickTest'
import activityCreateTest from '../Activities/activityCreateTest/activityCreateTest'
import activityQuickTestInsight from '../Activities/activityQuickTestInsight/activityQuickTestInsight'
import activityNBA from '../Activities/activityNBA/activityNBA'
import activityCOAttainment from '../Activities/activityCOAttainment/activityCOAttainment'
import activityCOPOMapping from '../Activities/activityCOPOMapping/activityCOPOMapping'
import activityCOPSOMapping from '../Activities/activityCOPSOMapping/activityCOPSOMapping'
import activityFeedbackForm from '../Activities/activityFeedbackForm/activityFeedbackForm'
import activityFeedbackAnalysis from '../Activities/activityFeedbackAnalysis/activityFeedbackAnalysis'
import activityCreateFeedback from '../Activities/activityCreateFeedback/activityCreateFeedback'
import activityStudyMaterial from '../Activities/activityStudyMaterial/activityStudyMaterial'
import activityAssessments from '../Activities/assessmentActivities/activityAssessments/activityAssessments'
import activityCreateAssessment from '../Activities/assessmentActivities/activityCreateAssessment/activityCreateAssessment'
import activityEditAssessment from '../Activities/assessmentActivities/activityEditAssessment/activityEditAssessment'
import activityMyAssessment from '../Activities/assessmentActivities/activityMyAssessments/activityMyAssessments'
import activityMyExam from '../Activities/activityExam/activityMyExam/activityMyExam'
import activityEditExam from '../Activities/activityExam/activityEditExam/activityEditExam'
import activityCreateExam from '../Activities/activityExam/activityCreateExam/activityCreateExam'
import activityNewAssignments from '../Activities/activityNewExams/activityNewAssignments/activityNewAssignments'
import activityNewCreateExam from '../Activities/activityNewExams/activityNewCreateExam/activityNewCreateExam'
import activitySubjectCourses from '../Activities/activitySubjectCourses/activitySubjectCourses'
import activityCourseAnalysis from '../Activities/activityCourseAnalysisRevamped/activityCourseAnalysis'
import activityCourseMaterial from '../Activities/activityCourseAnalysisRevamped/activityCourseMaterial/activityCourseMaterial'
import activityCourseAssignmentChecking from '../Activities/activityCourseAnalysisRevamped/activeCourseAssignmentCheck/activeCourseAssignmentCheck'
import activityCourseSubjectAnalysis from '../Activities/activityCourseSubjectAnalysis/activityCourseSubjectAnalysis'
import activityQuizResult from '../Activities/activityCourseAnalysisRevamped/activityQuizResult/activityQuizResult'
import feedbackSubmitted from '../Activities/activityCourseAnalysisRevamped/activityCourseSubsectionFeedback/activityCourseSubsectionFeedback'
import activityCreateAssignments from '../Activities/activityCreateAssignments/activityCreateAssignments'
import activityManageExamsRevamped from "../Activities/activityManageExamsRevamped/activityManageExamsRevamped";
import examQuestions from "../Activities/activityManageExamsRevamped/examQuestions/examQuestions";
import examStudentMarks from "../Activities/activityManageExamsRevamped/examStudentMarks/examStudentMarks";
import examCopyMarks from "../Activities/activityManageExamsRevamped/examCopyMarks/examCopyMarks.vue";
Vue.use(Router) // Using Router object in Vue

export const router = new Router({
  // Exporting a constant object 'router' which is of Router type
  mode: 'history', // using history mode to maintain history stack of routes
  base: process.env.BASE_URL, // using 'base' variable as a baseURL
  routes: [
    // this is the array which contains all the paths/routes
    {
      // this is the default route which redirects to route of 'redirect' property
      path: '/',
      redirect: '/activityWelcome'
    },
    {
      // this is the object which defines route and name for component 'activityWelcome'
      path: '/activityWelcome', // route of component
      name: 'activityWelcome', // name of component
      component: activityWelcome, // object of component
      meta: { guestOnly: true }
    },
    {
      path: '/activitySetup',
      name: 'activitySetup',
      component: activitySetup,
      meta: { guestOnly: true }
    },
    {
      path: '/activitySelectInstituteNSemester',
      name: 'activitySelectInstituteNSemester',
      component: activitySelectInstituteNSemester,
      meta: { requireAuth: true }
    },
    {
      path: '/activityUser',
      name: 'activityUser',
      component: activityCreateInstituteOrUser,
      meta: { requireAuth: true }
    },
    {
      path: '/activityCreateInstitute',
      name: 'acitivityCreateInstitute',
      component: activityCreateInstituteOrUser,
      meta: { requireAuth: true }
    },
    {
      path: '/activityHome',
      name: 'activityHome',
      component: activityHome,
      meta: { requireAuth: true },

      children: [
        {
          path: '/',
          redirect: '/activityMySubjects'
        },
        {
          path: '/activityDirections',
          name: 'activityDirections',
          component: activityDirections,
          meta: { requireAuth: true }
        },
        {
          path: '/activityProfile',
          name: 'activityProfile',
          component: activityProfile,
          meta: { requireAuth: true }
        },
        {
          path: '/activityMySubjects',
          name: 'activityMySubjects',
          component: activityMySubjects,
          meta: { requireAuth: true }
        },
        {
          path: '/activityMyTimetable',
          name: 'activityMyTimetable',
          component: activityMyTimetable,
          meta: { requireAuth: true }
        },
        {
          path: '/activityArrangeLecture',
          name: 'activityArrangeLecture',
          component: activityArrangeLecture,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityTakeAttendance',
          name: 'activityTakeAttendance',
          component: activityTakeAttendance,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityAttendanceAnalysis',
          name: 'activityAttendanceAnalysis',
          component: activityAttendanceAnalysis,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityCumulativeAttendance',
          name: 'activityCumulativeAttendance',
          component: activityCumulativeAttendance,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityTopics',
          name: 'activityTopics',
          component: activityTopics,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityAssignments',
          name: 'activityAssignments',
          component: activityAssignments,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityCreateAssignments',
          name: 'activityCreateAssignments',
          component: activityCreateAssignments,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityCheckAssignments',
          name: 'activityCheckAssignments',
          component: activityCheckAssignments,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityRescheduleRequests',
          name: 'activityRescheduleRequests',
          component: activityRescheduleRequests,
          meta: { requireAuth: true }
        },
        {
          path: '/activityTimeline',
          name: 'activityTimeline',
          component: activityTimeline,
          meta: { requireAuth: true }
        },
        {
          path: '/activityDiscussion',
          name: 'activityDiscussion',
          component: activityDiscussion,
          meta: { requireAuth: true }
        },
        {
          path: '/activityNotifications',
          name: 'activityNotifications',
          component: activityNotifications,
          meta: { requireAuth: true }
        },
        {
          path: '/activityChangeSemester',
          name: 'activityChangeSemester',
          component: activityChangeSemester,
          meta: { requireAuth: true }
        },
        {
          path: '/activityTopicFeedback',
          name: 'activityTopicFeedback',
          component: activityTopicFeedback,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityTopicFeedbackAction',
          name: 'activityTopicFeedbackAction',
          component: activityTopicFeedbackAction,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityQuickTest',
          name: 'activityQuickTest',
          component: activityQuickTest,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityCreateTest',
          name: 'activityCreateTest',
          component: activityCreateTest,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityQuickTestInsight',
          name: 'activityQuickTestInsight',
          component: activityQuickTestInsight,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityNBA',
          name: 'activityNBA',
          component: activityNBA,
          meta: { requireAuth: true }
        },
        {
          path: '/activityCOAttainment',
          name: 'activityCOAttainment',
          component: activityCOAttainment,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityCOPOMapping',
          name: 'activityCOPOMapping',
          component: activityCOPOMapping,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityCOPSOMapping',
          name: 'activityCOPSOMapping',
          component: activityCOPSOMapping,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityFeedbackForm',
          name: 'activityFeedbackForm',
          component: activityFeedbackForm,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityFeedbackAnalysis',
          name: 'activityFeedbackAnalysis',
          component: activityFeedbackAnalysis,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityCreateFeedback',
          name: 'activityCreateFeedback',
          component: activityCreateFeedback,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityStudyMaterial',
          name: 'activityStudyMaterial',
          component: activityStudyMaterial,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityAssessments',
          name: 'activityAssessments',
          component: activityAssessments,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityCreateAssessment',
          name: 'activityCreateAssessment',
          component: activityCreateAssessment,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityEditAssessment',
          name: 'activityEditAssessment',
          component: activityEditAssessment,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityMyExam',
          name: 'activityMyExam',
          component: activityMyExam,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityMyAssessment',
          name: 'activityMyAssessment',
          component: activityMyAssessment,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityEditExam',
          name: 'activityEditExam',
          component: activityEditExam,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityCreateExam',
          name: 'activityCreateExam',
          component: activityCreateExam,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityNewAssignments',
          name: 'activityNewAssignments',
          component: activityNewAssignments,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityNewCreateExam',
          name: 'activityNewCreateExam',
          component: activityNewCreateExam,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activitySubjectCourses',
          name: 'activitySubjectCourses',
          component: activitySubjectCourses,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityCourseAnalysis',
          name: 'activityCourseAnalysis',
          component: activityCourseAnalysis,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityCourseMaterial',
          name: 'activityCourseMaterial',
          component: activityCourseMaterial,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityCourseAssignmentChecking',
          name: 'activityCourseAssignmentCheck',
          component: activityCourseAssignmentChecking,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/feedbackSubmitted',
          name: 'activityCourseSubsectionFeedback',
          component: feedbackSubmitted,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: '/activityCourseSubjectAnalysis',
          name: 'activityCourseSubjectAnalysis',
          component: activityCourseSubjectAnalysis,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityQuizResult",
          name: "activityQuizResult",
          component: activityQuizResult,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/activityManageExamsRevamped",
          name: "activityManageExamsRevamped",
          component: activityManageExamsRevamped,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/examQuestions",
          name: "examQuestions",
          component: examQuestions,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/examStudentMarks",
          name: "examStudentMarks",
          component: examStudentMarks,
          props: true,
          meta: { requireAuth: true }
        },
        {
          path: "/examCopyMarks",
          name: "examCopyMarks",
          component: examCopyMarks,
          props: true,
          meta: { requireAuth: true }
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  validateTravel(to, from, next)
})
