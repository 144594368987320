<template src='./activityArrangeLecture.html'></template>

<script>
import inputContainer from '../../Components/inputContainer'
import moment from 'moment'
import SemesterRepository from '../../Repository/Semester'
import ShiftRepository from '../../Repository/Shift'
import TimetableRepository from '../../Repository/timetable'
import LecturesRepository from '../../Repository/lectures'
import InfrastructureReferenceRepository from '../../Repository/InfrastructureReference'
import { timeStringConverter } from '../../Services/Utils/DateTimeFormatter'
import showStatus from '../../NetworkManager/showStatus'
export default {
  name: 'activityArrangeLecture',
  props: [
    'prop_selectedDate',
    'prop_occupiedSlots'
  ],
  components: {
    inputContainer
  },
  data() {
    return {
      menu: false,
      chooseDate: '',
      roomClashCheck: false,
      facultyClashCheck: false,
      roomClash: [],
      facultyClash: [],
      batchForDivision: [],
      splitLectArr: [],
      lectureToShow: [],
      splitCount: 1,
      menu2: false,
      menu1: false,
      selectedSubjectAllInfo: {},
      selectedDiv: [],
      selectedGrp: [],
      divisionForSelectedSubject: [],
      selectedSubjectType: [],
      selectedSubjectId: '',
      divisionForSubject: [],
      allSubjectDetails: [],
      lectureBySubjectDialog: false,
      subjectNameForLecture: '',
      selectedBatch: '',
      selectedItem: null,
      selectTimeSlotDisable: false,
      time: null,
      endTime: false,
      startTime: false,
      tempStartTime: '',
      tempEndTime: '',
      addManuallyShow: false,
      userData: {},
      selectedInstitute: '',
      selectedSemester: '',
      allocations: [],
      subjectsMapping: {},
      freeSlots: [],
      selectedAllocation: {
        name: ''
      },
      tempTimeSlotArray: [],
      tempTimeSlot: '',
      tempLocation: '',
      locations: [],
      locationNames: [],
      freeSlotsList: [],
      finalStep: false,
      timetable: [],
      shift: {},
      facultyOccupiedSlots: [],
      divisionOccupiedSlots: [],
      subjectNames: [],
      subjectGroups: [],
      subjectGroupNames: [],
      groupForSubject: [],
      selectedGrpTemp: "",
      selectedDeptTemp: ""
    }
  },
  created() {
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.shiftRepositoryInstance = new ShiftRepository(this)
    this.timetableRepositoryInstance = new TimetableRepository(this)
    this.lecturesRepositoryInstance = new LecturesRepository(this)
    this.infrastructureReferenceRepositoryInstance = new InfrastructureReferenceRepository(this)
    this.subjectsMapping = this.$store.getters['mappings/get_subjectsMapping']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.$store.commit('liveData/set_selectedActivityName', 'Arrange Lecture')
    this.userData = this.$store.getters['user/get_userData']
    this.fetchData()
  },
  methods: {
    async checkRoomClash() {
      this.roomClash = []
      let utcStartTime;
      let utcEndTime;
      try {
        if (this.tempLocation !== '') {
          const indexOfLocation = this.locationNames.indexOf(this.tempLocation)
          const obj = {
            instituteId: this.selectedInstitute,
            roomId: this.locations[indexOfLocation].roomId
          }
          if (this.tempTimeSlotArray.length > 0) {
            this.tempTimeSlotArray.map(async (timeSlotSelected) => {
              if (this.roomClash.length === 0) {
                const indexOfSlot = this.freeSlotsList.indexOf(timeSlotSelected);
                utcStartTime = this.getUtcTimeOfLecture(
                  this.prop_selectedDate,
                  this.freeSlots[indexOfSlot].startTime
                )
                utcEndTime = this.getUtcTimeOfLecture(
                  this.prop_selectedDate,
                  this.freeSlots[indexOfSlot].endTime
                )
                utcStartTime.setMinutes(utcStartTime.getMinutes() - 2);
                utcEndTime.setMinutes(utcEndTime.getMinutes() + 2);
                obj.startTime = utcStartTime.toISOString()
                obj.endTime = utcEndTime.toISOString()
                console.log('obj', obj);
                this.roomClash = await this.lecturesRepositoryInstance.roomClashDetection(obj)
                console.log('this.facultyClash', this.facultyClash);
              }
            })
          } else if (this.tempEndTime !== '') {
            utcStartTime = this.getUtcTimeOfLecture(
              this.prop_selectedDate,
              this.tempStartTime.split(':').join('_')
            )
            utcEndTime = this.getUtcTimeOfLecture(
              this.prop_selectedDate,
              this.tempEndTime.split(':').join('_')
            )
            utcStartTime.setMinutes(utcStartTime.getMinutes() - 2);
            utcEndTime.setMinutes(utcEndTime.getMinutes() + 2);
            console.log('utcEndTime', utcEndTime.toISOString());
            obj.startTime = utcStartTime.toISOString()
            obj.endTime = utcEndTime.toISOString()
            console.log('obj', obj);
            this.roomClash = await this.lecturesRepositoryInstance.roomClashDetection(obj)
            console.log('this.facultyClash', this.facultyClash);
          }
          // roomClashDetection
        }
      } catch (err) {
        console.error('err', err);
      }
    },
    async checkLectureClash() {
      try {
        this.facultyClash = []
        let utcStartTime;
        let utcEndTime;
        const obj = {
          instituteId: this.selectedInstitute,
          uId: this.userData.uId
        }
        if (this.tempTimeSlotArray.length > 0) {
          for (let i = 0; i < this.tempTimeSlotArray.length; i++) {
            const timeSlotSelected = this.tempTimeSlotArray[i];
            console.log('this.facultyClash.length', this.facultyClash.length);

            if (this.facultyClash.length === 0) {
              const indexOfSlot = this.freeSlotsList.indexOf(timeSlotSelected);
              utcStartTime = this.getUtcTimeOfLecture(
                this.prop_selectedDate,
                this.freeSlots[indexOfSlot].startTime
              );
              utcEndTime = this.getUtcTimeOfLecture(
                this.prop_selectedDate,
                this.freeSlots[indexOfSlot].endTime
              );
              utcStartTime.setMinutes(utcStartTime.getMinutes() - 2);
              utcEndTime.setMinutes(utcEndTime.getMinutes() + 2);
              obj.startTime = utcStartTime.toISOString();
              obj.endTime = utcEndTime.toISOString();
              console.log('obj', obj);

              this.facultyClash = await this.lecturesRepositoryInstance.facultyLectureClashDetection(obj);
              console.log('this.facultyClash', this.facultyClash);
            }
          }
        } else if (this.tempEndTime !== '') {
          utcStartTime = this.getUtcTimeOfLecture(
            this.prop_selectedDate,
            this.tempStartTime.split(':').join('_')
          )
          utcEndTime = this.getUtcTimeOfLecture(
            this.prop_selectedDate,
            this.tempEndTime.split(':').join('_')
          )
          utcStartTime.setMinutes(utcStartTime.getMinutes() - 2);
          utcEndTime.setMinutes(utcEndTime.getMinutes() + 2);
          console.log('utcEndTime', utcEndTime.toISOString());
          obj.startTime = utcStartTime.toISOString()
          obj.endTime = utcEndTime.toISOString()
          console.log('obj', obj);
          this.facultyClash = await this.lecturesRepositoryInstance.facultyLectureClashDetection(obj)
          console.log('this.facultyClash', this.facultyClash);
        }
      } catch (err) {
        console.error('err', err);
      }
    },
    splitLecture(start, end) {
      this.lectureToShow = []
      const timeStart = new Date("01/01/2007 " + start)
      const timeEnd = new Date("01/01/2007 " + end)
      if (this.splitCount > 1) {
        const diff = (timeEnd - timeStart) / (60 * 1000);
        const timeToAdd = diff / this.splitCount
        this.splitLectArr = []
        this.splitLectArr.push(timeStart.getHours() + ':' + timeStart.getMinutes())
        for (let index = 2; index <= this.splitCount; index++) {
          let value = null;
          value = timeToAdd * (index - 1)
          const date1 = moment(timeStart).add(value, 'm').toDate()
          this.splitLectArr.push(date1.getHours() + ':' + date1.getMinutes())
        }
        this.splitLectArr.push(timeEnd.getHours() + ':' + timeEnd.getMinutes())
        this.lectureToShow = []
        for (let index = 0; index < this.splitLectArr.length - 1; index++) {
          this.lectureToShow.push(this.splitLectArr[index] + '-' + this.splitLectArr[index + 1])
        }
      }
    },
    divValueChange() {
      if (this.selectedGrpTemp !== "") {
        this.selectedGrp = [this.selectedGrpTemp]
      }
      if (this.selectedGrp.length === 0) {
        if (this.selectedDiv.length === 1) {
          this.selectAllocation(this.selectedDiv[0])
        }

        if (this.selectedDiv.length > 1) {
          this.tempTimeSlotArray = []
        }
      } else {
        if (this.selectedGrp.length === 1) {
          this.selectAllocation(this.selectedGrp[0])
        }

        if (this.selectedGrp.length > 1) {
          this.tempTimeSlotArray = []
        }
      }

      this.batchForDivision = []
      // this.selectedSubjectAllInfo.subjectId
      if (this.selectedDiv.length === 1) {
        if (this.selectedSemester.subjectAllocatedForDiv.length > 0) {
          this.selectedSemester.subjectAllocatedForDiv.map((data) => {
            if (data.subjectId === this.selectedSubjectAllInfo.subjectId && data.division === this.selectedDiv[0]) {
              if (data.batchName && data.batchName !== '') {
                this.batchForDivision.push(data.batchName)
              }
            }
          })
        }
      } else {
        this.selectedBatch = ''
      }
      console.log('this.selectedSemester.subjectAllocatedForDiv', this.selectedSemester.subjectAllocatedForDiv);
    },

   async selectedSubjectForLecture(data, i) {
      await this.getShifts()
      const [day, month, year] = this.prop_selectedDate.split('/');
      this.chooseDate = `${year}-${month}-${day}`;
      this.subjectGroups = []
      this.roomClashCheck = false
      this.facultyClashCheck = false
      this.roomClash = []
      this.facultyClash = []
      this.selectedBatch = ''
      this.batchForDivision = []
      this.addManuallyShow = false
      this.tempEndTime = ''
      this.tempStartTime = ''
      this.tempLocation = ''
      this.tempTimeSlotArray = []
      this.lectureToShow = []
      this.splitLectArr = []
      this.selectedSubjectAllInfo = {}
      this.selectedSubjectAllInfo = data
      this.selectedDiv = []
      this.subjectNameForLecture = data.subjectName
      this.selectedItem = null
      this.lectureBySubjectDialog = true
      this.selectedSubjectId = ''
      this.divisionForSelectedSubject = []
      this.allSubjectDetails.map((info) => {
        if (info.subjectId === data.subjectId) {
          if (!this.divisionForSelectedSubject.includes(info.division)) {
            this.divisionForSelectedSubject.push(info.division)
          }
        }
      })
      if (this.divisionForSelectedSubject.length === 1) {
        console.log('this.divisionForSelectedSubject', this.divisionForSelectedSubject);
        this.selectedDiv = this.divisionForSelectedSubject[0]
        this.divValueChange()
      }
      this.subjectGroups = data?.groupForSubject
      // console.log('this.subjectGroups', this.subjectGroups)
      const assignedGroupsSet = new Set();
      for (const assignedGroup of this.userData.assignedGroupForSubject) {
        const groupIdentifier = `${assignedGroup.groupName}-${assignedGroup.groupId}`;
        assignedGroupsSet.add(groupIdentifier);
      }
      this.subjectGroups = this.subjectGroups.filter(subjectGroup => {
        const groupIdentifier = `${subjectGroup.groupName}-${subjectGroup.groupId}`;
        return assignedGroupsSet.has(groupIdentifier);
      });
      // console.log('this.userData', this.userData)
      this.subjectGroupNames = this.subjectGroups.map((element) => element.groupName);
      // console.log('this.subjectGroups', this.subjectGroups)
      this.divisionForSelectedSubject.sort()
    },

    handelToggleManually() {
      this.addManuallyShow = !this.addManuallyShow
      this.tempTimeSlotArray = []
      this.lectureToShow = []
      this.splitLectArr = []
      this.tempTimeSlot = ''
      this.selectTimeSlotDisable = !this.selectTimeSlotDisable
      if (!this.selectTimeSlotDisable) {
        this.tempStartTime = ''
        this.tempEndTime = ''
      }
    },
    async fetchData() {
      try {
        this.allSubjectDetails = []
        // console.log('this.selectedSemester123', this.selectedSemester)
        this.allSubjectDetails = [...this.selectedSemester.subjectAllocatedForDiv, ...this.selectedSemester.assignedGroupForSubject]
        // this.allSubjectDetails = await this.timetableRepositoryInstance.getAllocationsOfAFaculty(objToPush)
        // console.log('this.allSubjectDetails', this.allSubjectDetails);
        const subjectNameOnly = []
        this.allSubjectDetails.map((data) => {
          if (this.subjectsMapping[data.subjectId]) {
            const obj = {}
            obj['subjectId'] = data.subjectId
            obj['groupForSubject'] = this.subjectsMapping[data.subjectId]?.groupForSubject ? this.subjectsMapping[data.subjectId]?.groupForSubject : []
            obj['subjectName'] = this.subjectsMapping[data.subjectId]?.subjectName
            obj['courseYear'] = this.subjectsMapping[data.subjectId]?.courseYear
            obj['lectureType'] = this.subjectsMapping[data.subjectId]?.lectureType
            obj['instituteId'] = this.subjectsMapping[data.subjectId]?.instituteId
            obj['semId'] = this.subjectsMapping[data.subjectId]?.semId
            obj['department'] = this.subjectsMapping[data.subjectId]?.department
            obj['key'] = data.subjectId + this.subjectsMapping[data.subjectId]?.courseYear + this.subjectsMapping[data.subjectId]?.lectureType
            subjectNameOnly.push(obj)
          }
        })
        // console.log('subjectNameOnly', subjectNameOnly)
        this.subjectNames = subjectNameOnly

        const unique = subjectNameOnly.filter((obj, index) =>
          subjectNameOnly.findIndex((item) => item.key === obj.key) === index
        );

        this.allocations = unique
      } catch (err) {
        console.log(err)
        this.allocations = []
      }
    },
    async getShifts() {
      this.freeSlots = []
      this.freeSlotsList = []
      try {
        this.shift = await this.shiftRepositoryInstance.getShifts({
          instituteId: this.selectedInstitute
        })
        if (this.shift.timeSlots) {
        this.shift.timeSlots.forEach((timeSlot) => {
          this.freeSlots.push(timeSlot)
        })
      }
      // console.log('this.freeSlots', this.freeSlots);
      this.freeSlots.forEach((tSlot) => {
        this.freeSlotsList.push(timeStringConverter(tSlot.startTime) + ' - ' + timeStringConverter(tSlot.endTime))
      })
      } catch (err) {
        console.log(err)
      }
    },

    async selectAllocation(div) {
      this.tempTimeSlot = ''
      this.tempLocation = ''
      const objToPush = {
        instituteId: this.selectedSubjectAllInfo.instituteId,
        semId: this.selectedSubjectAllInfo.semId,
        department: this.selectedSubjectAllInfo.department,
        courseYear: this.selectedSubjectAllInfo.courseYear,
        division: div,
        dateOfLecturesNeeded: this.convertToISOString(this.prop_selectedDate)
      }

      this.locations = []

      try {
        this.locations = await this.infrastructureReferenceRepositoryInstance.getRoomsOfADepartment(objToPush)
      } catch (err) {
        console.log(err)
        this.locations = []
      }

      this.locations.forEach((location) => {
        this.locationNames.push(location.roomName)
      })

      try {
        this.timetable = await this.lecturesRepositoryInstance.getLecturesOfADivisionForADay(objToPush)
      } catch (err) {
        console.log(err)
        this.timetable = []
      }

      this.facultyOccupiedSlots = []
      this.divisionOccupiedSlots = []
      // this.shift.timeSlots

      this.prop_occupiedSlots.forEach((tSlot) => {
        this.facultyOccupiedSlots.push(timeStringConverter(tSlot.startTime) + ' - ' + timeStringConverter(tSlot.endTime))
      })
      this.timetable.forEach((tSlot) => {
        this.divisionOccupiedSlots.push(timeStringConverter(tSlot.startTime) + ' - ' + timeStringConverter(tSlot.endTime))
      })
    },
    unselectAllocation() {
      this.finalStep = false
      this.selectedAllocation = {
        name: ''
      }
      this.tempTimeSlot = ''
      this.tempLocation = ''
    },
    getDayNameFromDayNumber(dayNumber) {
      switch (dayNumber) {
        case 0: return 'Sunday'
        case 1: return 'Monday'
        case 2: return 'Tuesday'
        case 3: return 'Wednesday'
        case 4: return 'Thursday'
        case 5: return 'Friday'
        case 6: return 'Saturday'
      }
    },
    getDateTimeOfLecture(date, startTimeOfLecture) {
      const dateObject = new Date(date)
      const timeArray = startTimeOfLecture
        .split("_")
        .map((time) => parseInt(time));
      return new Date(
        dateObject.getFullYear(),
        dateObject.getMonth(),
        dateObject.getDate(),
        ...timeArray
      );
    },
    getUtcTimeOfLecture(date, tempTime) {
      const dateObject = new Date(date)
      const timeArray = tempTime
        .split("_")
        .map((time) => parseInt(time));
      const time = new Date(
        dateObject.getFullYear(),
        dateObject.getMonth(),
        dateObject.getDate(),
        ...timeArray
      );
      return time
    },
    async arrangeLecture() {
      this.prop_selectedDate = new Date(this.chooseDate)
      this.groupForSubject = []
      for (let i = 0; i < this.subjectGroups.length; i++) {
        for (let j = 0; j < this.selectedGrp.length; j++) {
          if (this.subjectGroups[i].groupName === this.selectedGrp[j]) {
            this.groupForSubject.push(this.subjectGroups[i])
          }
        }
      }
      // console.log('this.groupForSubject', this.groupForSubject)
      if (this.tempTimeSlotArray.length > 0) {
        this.tempTimeSlotArray.map(async (timeSlotSelected) => {
          const indexOfSlot = this.freeSlotsList.indexOf(timeSlotSelected)
          let indexOfLocation
          if (this.tempLocation) {
            indexOfLocation = this.locationNames.indexOf(this.tempLocation)
          }
          const lectureToArrange = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedSubjectAllInfo.department,
            courseYear: this.selectedSubjectAllInfo.courseYear,
            // batch: this.selectedSubjectAllInfo.batch,
            subjectId: this.selectedSubjectAllInfo.subjectId,
            uId: this.userData.uId,
            day: this.getDayNameFromDayNumber(this.prop_selectedDate.getDay())
          }
          if (this.selectedBatch !== '') {
            lectureToArrange.batch = this.selectedBatch
          }
          if (this.selectedGrp.length > 0) {
            lectureToArrange['groupForSubject'] = this.groupForSubject
          }
          if (this.selectedDiv.length === 1) {
            lectureToArrange['division'] = this.selectedDiv[0]
          }
          if (this.selectedDiv.length > 1) {
            lectureToArrange['multiDivision'] = this.selectedDiv
          }
          if (indexOfLocation) {
            lectureToArrange.roomId = this.locations[indexOfLocation].roomId
          } else {
            lectureToArrange.roomId = '-'
          }

          const dateTime = await this.getDateTimeOfLecture(
            this.prop_selectedDate,
            this.freeSlots[indexOfSlot].startTime
          )
          const utcStartTime = await this.getUtcTimeOfLecture(
            this.prop_selectedDate,
            this.freeSlots[indexOfSlot].startTime
          )
          const utcEndTime = await this.getUtcTimeOfLecture(
            this.prop_selectedDate,
            this.freeSlots[indexOfSlot].endTime
          )
          lectureToArrange.startTime = utcStartTime
          lectureToArrange.endTime = utcEndTime
          lectureToArrange.dateTime = new Date(dateTime).toISOString()

          try {
            const objToPush = {
              lectures: [lectureToArrange]
            }
            const lectures = await this.lecturesRepositoryInstance.arrangeALecture(objToPush)
            console.log('###', lectures)
            // console.log('lectureToArrange', lectureToArrange)
            this.unselectAllocation()
            showStatus('Lectures Arranged successfully.', 2000, 'success', this)
            const self = this
            setTimeout(() => self.$router.go(-1), 1000)
          } catch (err) {
            console.log(err)
            showStatus('Could not arrange lectures. Try again', 1000, 'error', this)
          }
          // this.tempStartTime
        })
      } else {
        if (this.lectureToShow.length === 0) {
          let indexOfLocation
          if (this.tempLocation) {
            indexOfLocation = this.locationNames.indexOf(this.tempLocation)
          }
          const lectureToArrange2 = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedSubjectAllInfo.department,
            courseYear: this.selectedSubjectAllInfo.courseYear,
            // batch: this.selectedSubjectAllInfo.batch,
            subjectId: this.selectedSubjectAllInfo.subjectId,
            uId: this.userData.uId,
            day: this.getDayNameFromDayNumber(this.prop_selectedDate.getDay())
          }
          if (this.selectedBatch !== '') {
            lectureToArrange2.batch = this.selectedBatch
          }
          if (this.selectedGrp.length > 0) {
            lectureToArrange2['groupForSubject'] = this.groupForSubject
          }
          if (this.selectedDiv.length === 1) {
            lectureToArrange2['division'] = this.selectedDiv[0]
          }
          if (this.selectedDiv.length > 1) {
            lectureToArrange2['multiDivision'] = this.selectedDiv
          }
          if (indexOfLocation) {
            lectureToArrange2.roomId = this.locations[indexOfLocation].roomId
          } else {
            lectureToArrange2.roomId = '-'
          }

          const dateTime = await this.getDateTimeOfLecture(
            this.prop_selectedDate,
            this.tempStartTime.split(':').join('_')
          )
          const utcStartTime = await this.getUtcTimeOfLecture(
            this.prop_selectedDate,
            this.tempStartTime.split(':').join('_')
          )
          const utcEndTime = await this.getUtcTimeOfLecture(
            this.prop_selectedDate,
            this.tempEndTime.split(':').join('_')
          )
          lectureToArrange2.startTime = utcStartTime
          lectureToArrange2.endTime = utcEndTime
          lectureToArrange2.dateTime = new Date(dateTime).toISOString()
          try {
            const objToPush = {
              lectures: [lectureToArrange2]
            }
            const lectures = await this.lecturesRepositoryInstance.arrangeALecture(objToPush)
            console.log('lectures', lectures)
            this.unselectAllocation()
            showStatus('Lectures Arranged successfully.', 2000, 'success', this)
            const self = this
            setTimeout(() => self.$router.go(-1), 1000)
          } catch (err) {
            console.log(err)
            showStatus('Could not arrange lectures. Try again', 1000, 'error', this)
          }
          this.tempStartTime = ''
          this.tempEndTime = ''
        } else {
          this.lectureToShow.map(async (data) => {
            const [startTime1, endTime1] = data.split('-')
            let indexOfLocation
            if (this.tempLocation) {
              indexOfLocation = this.locationNames.indexOf(this.tempLocation)
            }
            const lectureToArrange2 = {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: this.selectedSubjectAllInfo.department,
              courseYear: this.selectedSubjectAllInfo.courseYear,
              // batch: this.selectedSubjectAllInfo.batch,
              subjectId: this.selectedSubjectAllInfo.subjectId,
              uId: this.userData.uId,
              day: this.getDayNameFromDayNumber(this.prop_selectedDate.getDay())
            }
            if (this.selectedBatch !== '') {
              lectureToArrange2.batch = this.selectedBatch
            }
            if (this.selectedDiv.length === 1) {
              lectureToArrange2['division'] = this.selectedDiv[0]
            }
            if (this.selectedDiv.length > 1) {
              lectureToArrange2['multiDivision'] = this.selectedDiv
            }
            if (indexOfLocation) {
              lectureToArrange2.roomId = this.locations[indexOfLocation].roomId
            } else {
              lectureToArrange2.roomId = '-'
            }

            const dateTime = await this.getDateTimeOfLecture(
              this.prop_selectedDate,
              startTime1.split(':').join('_')
            )
            const utcStartTime = await this.getUtcTimeOfLecture(
              this.prop_selectedDate,
              startTime1.split(':').join('_')
            )
            const utcEndTime = await this.getUtcTimeOfLecture(
              this.prop_selectedDate,
              endTime1.split(':').join('_')
            )

            lectureToArrange2.startTime = utcStartTime
            lectureToArrange2.endTime = utcEndTime
            lectureToArrange2.dateTime = new Date(dateTime).toISOString()
            try {
              const objToPush = {
                lectures: [lectureToArrange2]
              }
              const lectures = await this.lecturesRepositoryInstance.arrangeALecture(objToPush)
              console.log('###', lectures)
              this.unselectAllocation()
              showStatus('Lectures Arranged successfully.', 2000, 'success', this)
              const self = this
              setTimeout(() => self.$router.go(-1), 1000)
            } catch (err) {
              console.log(err)
              showStatus('Could not arrange lectures. Try again', 1000, 'error', this)
            }
            this.tempStartTime = ''
            this.tempEndTime = ''
          })
        }
      }
      // this.tempTimeSlot
    },
    convertToISOString(date) {
      try {
        const convertedDate = new Date(date.valueOf())
        // IST is 5.30 hours ahead of UTC
        convertedDate.setHours(5, 30, 0, 0)
        return convertedDate.toISOString()
      } catch (err) {
        console.log(err)
        return undefined
      }
    },
    handleGoBack() {
      history.back()
    }
  }
}
</script>

<style src='./activityArrangeLecture.css'></style>
