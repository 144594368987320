export const subjectTypes = [
  'Normal Theory',
  'Normal Practical',
  'Normal Tutorial',
  'Normal Audit Course',
  'Elective Theory',
  'Elective Practical',
  'Elective Tutorial',
  'Elective Audit Course',
  'Course Survey'
]

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const lectureTypes = {
  ORDINARY: 0,
  PENDING: 1,
  RESCHEDULED: 2,
  RESCHEDULED_EXTRA: 3,
  REMEDIAL: 4,
  HOLIDAY: 5,
  CANCELLED: 6
}

export const ownershipStatuses = ['']

export const optionEnabled = ['Single Answer MCQ', 'Multiselect MCQ']

export const instituteTypes = ['']

export const semesterTypes = ['Current', 'Upcoming', 'Past']

export const RescheduleRequestStatus = {
  REJECTED: -1,
  PENDING: 0,
  ACCEPTED: 1,
  CANCELLED: 2
}
