<template src='./activityNBA.html'>
</template>
<script>
import { subjectTypes } from '../../Constants/Utils/Statics'
import NBARepository from '../../Repository/NBA'
import SubjectRepository from '../../Repository/Subjects'
import inputContainer from '../../Components/inputContainer'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'activityNBA',
  components: {
    inputContainer
  },
  data () {
    return {
      COPOMapping: {},
      COPSOMapping: {},
      COs: [],
      POs: [],
      PSOs: [],
      isMobileView: false,
      selectedMap: '',
      // maps: ['CO PO Matrix', 'CO PSO Matrix', 'CO Direct Attainment'],
      maps: ['CO PO Matrix', 'CO Direct Attainment'],
      toggle: true,
      flagMission: true,
      flagPO: true,
      itemIndex: 0,
      tempMission: '',
      dataLoaded: false,
      addingMission: false,
      NBAObject: {},
      DepartmentNBAObject: {},
      SubjectNBAObject: {},
      editingCO: false,
      editingVision: false,
      addingCO: false,
      tempheading: '',
      tempdescription: '',
      editingMission: false,
      desserts: [
        {
          name: 'S.T.Gaikwad',
          calories: 'SE',
          fat: 'SPOS',
          carbs: 'Complete'
        },
        {
          name: 'Disha Deshmukh',
          calories: 'TE',
          fat: 'M-II',
          carbs: 'Complete'
        },
        {
          name: 'Kiran Kendre',
          calories: 'SE',
          fat: 'DAA',
          carbs: 'Inprogress'
        },
        {
          name: 'Pooja Joshi',
          calories: 'TE',
          fat: 'DS',
          carbs: 'Complete'
        },
        {
          name: 'Rajesh Kedar',
          calories: 'BE',
          fat: 'SPOS',
          carbs: 'Inprogress'
        }
      ],
      headers: [
        {
          text: 'Class teacher',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Year', value: 'calories', sortable: false },
        { text: 'Subject', value: 'fat', sortable: false },
        { text: 'Status', value: 'carbs', sortable: false }
      ],
      userData: null,
      itemIndexForMission: 0,
      selectedInstitute: '',
      selectedSemester: '',
      subjects: [],
      subjectTypes: subjectTypes,
      selectedSubject: {},
      subjectId: '',
      noSubjectNBA: false
    }
  },
  created () {
    this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    this.$store.commit('liveData/set_selectedActivityName', 'NBA/NAAC Module')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    this.selectedSemester = this.$store.getters[
      'instituteData/get_selectedSemester'
    ]
    console.log(this.selectedSemester)
    this.NBARepositoryInstance = new NBARepository(this)
    this.SubjectRepositoryInstance = new SubjectRepository(this)
    // Call API to fetch subjects
    this.fetchData()
  },
  methods: {
    async fetchData () {
      console.log(this.userData)
      try {
        this.subjects = await this.SubjectRepositoryInstance.getSubjectOfACoordinator({
          instituteId: this.userData.instituteId,
          semId: this.userData.semId,
          department: this.userData.department,
          uId: this.userData.uId
        })
        this.subjects = this.subjects.subject
        const objToPush = {
          instituteId: this.selectedInstitute,
          department: this.userData.department
          //   semId: this.selectedSemester.semId,
          //   uId: this.userData.uId
        }
        console.log('this is the object to push', objToPush)
        this.NBAObject = await this.NBARepositoryInstance.getNBA(objToPush)
        this.DepartmentNBAObject = await this.NBARepositoryInstance.getDepartmentNBA(objToPush)
        console.log(this.subjects, this.DepartmentNBAObject, this.NBAObject)
      } catch (err) {
        console.log(err)
      }
    },
    async fetchData2 () {
      const objToPush = {
        instituteId: this.selectedInstitute,
        department: this.userData.department,
        semId: this.selectedSemester.semId,
        subjectId: this.subjectId
      }
      console.log(objToPush)
      this.SubjectNBAObject = await this.NBARepositoryInstance.getSubjectNBA(objToPush)
      console.log('this is the nbaObject', this.SubjectNBAObject)
      this.COs = this.SubjectNBAObject.subjectCourseOutcomes || []
      this.POs = this.NBAObject.instituteProgramOutcomes || []
      this.PSOs = this.DepartmentNBAObject.departmentProgramOutcomes || []
      if (this.SubjectNBAObject.instituteId) {
        this.noSubjectNBA = false
        this.COPOMapping = {}
        this.COPSOMapping = {}
        for (let i = 0; i < this.SubjectNBAObject.subjectCourseOutcomes.length; i++) {
          const co = this.SubjectNBAObject.subjectCourseOutcomes[i]
          this.COPOMapping[co.coId] = {}
          this.COPSOMapping[co.coId] = {}
          this.NBAObject.instituteProgramOutcomes.forEach(po => {
            this.COPOMapping[co.coId][po.poId] = ''
          })
          this.DepartmentNBAObject.departmentProgramOutcomes.forEach(pso => {
            this.COPSOMapping[co.coId][pso.psoId] = ''
          })
          const copoMaps = await this.NBARepositoryInstance.getCOMapping({ coId: co.coId })
          console.log(copoMaps)
          copoMaps.forEach(map => {
            this.COPOMapping[map.coId][map.poId] = map.poMap
          })
          const copsoMaps = await this.NBARepositoryInstance.getCOPSOMapping({ coId: co.coId })
          console.log(copsoMaps)
          copsoMaps.forEach(map => {
            this.COPSOMapping[map.coId][map.psoId] = map.psoMap
          })
        }
        console.log(this.COPOMapping, this.COPSOMapping)
      } else {
        this.SubjectNBAObject = {}
        this.SubjectNBAObject.subjectCourseOutcomes = []
        this.SubjectNBAObject.exams = []
        this.SubjectNBAObject.subjectId = this.subjectId
        this.SubjectNBAObject.instituteId = this.selectedInstitute
        this.SubjectNBAObject.semId = this.selectedSemester.semId
        this.SubjectNBAObject.department = this.userData.department
        this.noSubjectNBA = true
        throw console.error('No data found!')
      }
      this.dataLoaded = true
    },
    selectSubject (subject) {
      this.selectedSubject = subject
      this.subjectId = subject.subjectId
      this.fetchData2()
    },
    async addCO () {
      // if (!this.flagMission) {
      //   this.DepartmentNBAObject.departmentMission.splice(0, 1)
      //   this.flagMission = true
      // }
      // if (!this.flagPO) {
      //   this.DepartmentNBAObject.departmentProgramOutcomes.splice(0, 1)
      //   this.flagPO = true
      // }
      const objToPush = {
        coId: uuidv4(),
        heading: this.tempheading,
        description: this.tempdescription,
        directAttainment: {
          tut: '',
          abet: '',
          insem: '',
          endsem: '',
          practical: '',
          oral: '',
          average: ''
        }
      }
      this.SubjectNBAObject.subjectCourseOutcomes.push(objToPush)
      console.log(this.SubjectNBAObject)
      this.tempheading = ''
      this.tempdescription = ''
      this.addingCO = false
      this.updateSubjectNBA()
    },
    async editItem (item) {
      this.itemIndex = item
      console.log(
        'this is in edit',
        this.SubjectNBAObject.subjectCourseOutcomes[this.itemIndex]
      )
      this.editingCO = true
    },
    async deleteItem (index) {
      this.SubjectNBAObject.subjectCourseOutcomes.splice(index, 1)
      this.updateSubjectNBA()
    },
    async updateSubjectNBA () {
      console.log('this to update NBA', this.SubjectNBAObject)
      await this.NBARepositoryInstance.updateSubjectNBA(
        this.SubjectNBAObject
      )
      this.fetchData2()
    },
    gotoMappingActivities () {
      console.log(this.selectedMap)
      switch (this.selectedMap) {
        case 'CO Direct Attainment':
          // this.$router.push('activityCOAttainment')
          this.$router.push({
            name: 'activityCOAttainment',
            params: {
              prop_subject: this.selectedSubject,
              prop_subjectNBA: this.SubjectNBAObject
            }
          })
          break
        case 'CO PO Matrix':
          this.$router.push({
            name: 'activityCOPOMapping',
            params: {
              prop_NBA: this.NBAObject,
              prop_subjectNBA: this.SubjectNBAObject,
              prop_COPOMapping: this.COPOMapping
            }
          })
          break
        case 'CO PSO Matrix':
          this.$router.push({
            name: 'activityCOPSOMapping',
            params: {
              prop_DepartmentNBA: this.DepartmentNBAObject,
              prop_subjectNBA: this.SubjectNBAObject,
              prop_COPSOMapping: this.COPSOMapping
            }
          })
          break
      }
    }
  }
}
</script>
<style scoped src='./activityNBA.css'>
</style>
