export default {
  createTopic: '/topic/createTopic',
  createTopicMultipleTopics: '/topic/createTopicMultipleTopics',
  getTopicsOfASubjectOfAFaculty: '/topic/getTopicsOfASubjectOfAFaculty',
  markAsImplemented: '/topic/markAsImplemented',
  markAsUnimplemented: '/topic/markAsUnimplemented',
  updateTopic: '/topic/updateTopic',
  deleteTopic: '/topic/deleteTopic',
  getTopicOfASubject: '/topic/getTopicOfASubject',
  getTopicsOfASubject: '/topic/getTopicsOfASubject',
  generateTopicReport: '/excel/topic/generateTopicReport',
  getTopicsOfADivisionOfASubject: '/topic/getTopicsOfADivisionOfASubject'
}
