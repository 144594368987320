<template>
<div style="background-color: #e1e2fa; padding-left: 10px; padding-right: 10px; height: 50px; border-radius: 5px">
<slot></slot>
</div>
<!-- <v-text-field :label='label' v-model='model' solo background-color='#e1e2fa' flat hide-details></v-text-field> -->
</template>
<script>
export default {
  name: 'inputContainer'
}
</script>
<style scoped>
</style>
