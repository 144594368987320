import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/SemesterUser'

class SemesterUser {
  constructor (context) {
    this.context = context
  }

  async getCurrentSemesterUserForAUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getCurrentSemesterUserForAUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUser
  }

  async getSemesterUsersOfASubject (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesterUsersOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getStudentsOfACourseYear(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentsOfACourseYear, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getMultipleSemesterUsers (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getMultipleSemesterUsers, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getFacultiesOfAInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getFacultiesOfAInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getSemesterUsersForAUser (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesterUsersForAUser, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getSemesterUsersOfASubjectOfADivision (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesterUsersOfASubjectOfADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getSemesterUsersOfASubjectOfAGroup (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesterUsersOfASubjectOfAGroup, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }

  async getStudentsOfADivision (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentsOfADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesterUsers
  }
}

export default SemesterUser
