import axios from 'axios'
import user from "../Store/Modules/user"
import liveData from "../Store/Modules/liveData"
import instituteData from "../Store/Modules/instituteData";
const token = JSON.parse(window.localStorage.getItem("token")) || { token: "" }
const getHeaders = () => {
  const token = JSON.parse(window.localStorage.getItem("token")) || { token: "" }
  return {
    userType: 'faculty',
    userId: user?.state?.userData?.uId || null,
    instituteId: instituteData?.state?.selectedInstitute || null,
    instituteName: liveData?.state?.brandingName || null,
    authorization: `JWT ${token?.token}`
  }
}

// const serverUrl = 'http://ec2-54-209-157-112.compute-1.amazonaws.com:3010'
// const serverUrl = 'http://localhost:3000'
const serverUrlForApi =
  process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT
export const socketUrl =
  process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_SOCKET_PORT

export const spacesUrl = `https://${process.env.VUE_APP_SPACES_NAME}.${process.env.VUE_APP_SPACES_URL}`

export default {
  serverUrl: serverUrlForApi,
  getRequest: async (apiName, data) => {
    const res = await axios.get(serverUrlForApi + apiName, { params: data, headers: { ...getHeaders(), authorization: `JWT ${token.token}` } })
    return res.data
  },

  postRequest: async (apiName, data, customHeaders) => {
    const config = {}
    if (customHeaders) {
      config.headers = { ...getHeaders(), ...customHeaders };
    } else config.headers = getHeaders()
    const res = await axios.post(serverUrlForApi + apiName, data, config)
    return res.data
  },

  putRequest: async (apiName, data) => {
    const res = await axios.put(serverUrlForApi + apiName, data, { headers: getHeaders() })
    return res.data
  },

  deleteRequest: async (apiName, data) => {
    const res = await axios.delete(serverUrlForApi + apiName, { params: data, headers: getHeaders() })
    return res
  },

  downloadFile: async (apiName, data) => {
    const res = await axios.post(serverUrlForApi + apiName, data, {
      responseType: 'arraybuffer',
      headers: getHeaders()
    })
    return res
  }
}

// function getHeaders() {
//   //add custom headers here
//   return {
//     uId: ""
//   }
// }
