<template src="./examSubjectDetails.html"></template>
<script>
import SubjectRepository from "../../../Repository/Subjects";
import NewExamRepository from "../../../Repository/NewExam";
import ExamSubjectAndQuestionRepository from "../../../Repository/ExamSubjectAndQuestion";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import UserRepository from "../../../Repository/User";
import showStatus from "../../../NetworkManager/showStatus";
// import { sub } from "date-fns";
export default {
  name: "examSubjectDetails",
  data() {
    return {
      progressIndicator1: 0,
      userData: null,
      loading: false,
      examName: "",
      backgroundColor: "#E7E7FFCF",
      rules: {
        required: (value) => !!value || "This field is required",
        totalMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed.",
        passingMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed."
      },
      search: "",
      studentsSearch: "",
      headers: [
        { text: "Sr No.", value: "srNo" },
        { text: "Subject code", value: "subjectCode" },
        { text: "Subject name", value: "subjectName" },
        { text: "Allocated Students", value: "allocatedStudents" },
        { text: "Exam date", value: "examDate", width: "120px" },
        { text: "Time", value: "examTime", width: "120px" },
        { text: "Duration(in mins)", value: "examDuration" },
        { text: "Total marks", value: "totalMarks" },
        { text: "Passing marks", value: "passingMarks" },
        { text: "Threshold", value: "threshold" }
      ],
      studentDialogHeaders: [
        { text: "Selected", value: "selected" },
        { text: "Roll No.", value: "rollNumber" },
        { text: "Prn", value: "prnNumber" },
        { text: "Exam Seat Number", value: "examSeatNumber" },
        { text: "Student Name  ", value: "studentName" }
      ],
      itemsArray: [
        {
          subjectCode: "123",
          subjectName: "Maths",
          passingMarks: 2,
          examDate: null,
          examTime: null
          // 'id1' : v4()
        },
        {
          subjectCode: "1234",
          subjectName: "Bios",
          examDate: null,
          examTime: null
        },
        {
          subjectCode: "124",
          subjectName: "Os",
          examDate: null,
          examTime: null
        }
      ],
      examDateModel: false,
      examDate: null,
      calenderIcon: "mdi-calendar-month-outline",
      menu: false,
      selectedDepartment: "",
      selectAllStudents: false,
      selectedCourseYear: "",
      selectedInstitute: "",
      selectedSemester: null,
      subjects: [],
      selectedSubjects: [],
      examId: null,
      subjectsArray: [],
      studentDialog: false,
      courseYearStudents: [],
      userDetails: [],
      studentsTableData: [],
      studentRadio: "option1",
      filteredStudents: [],
      subjectAllocatedStudents: null,
      subejctToEdit: {},
      exam: {},
      examTabDependecies: {}
    };
  },
  computed: {
    filteredStudentsByRadio() {
      switch (this.studentRadio) {
        case "option1":
          return this.studentsTableData; // All Students for Course Mapped
        case "option2":
          return this.studentsTableData.filter((student) => student.isSelected); // Show Selected Students for Existing Exam
        case "option3":
          return this.studentsTableData.filter(
            (student) => !student.isSelected
          ); // Show not Selected Students for Existing Exam
        default:
          return this.studentsTableData;
      }
    },
    computedSelectedStudents() {
      return (item) =>
        item.allocatedStudent.filter((stud) => stud.isSelected === true);
    }
  },
  mounted() {},
  activated() {
    const newValue = this.$store.state.liveData.examTabDependencies;
    let flag = false;
    Object.entries(newValue).forEach(([key, value]) => {
      if (value["tab2"] === true) {
        flag = true;
        value["tab2"] = false;
      }
    });

    if (flag === true) {
      this.fetchData();
      this.getStudentsOfACourseYear();
    }
  },
  watch: {
    studentRadio() {
      this.filteredStudents = this.filteredStudentsByRadio;
    }
  },
  async created() {
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.userData = this.$store.getters['user/get_userData']
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.examSubjectAndQuestionRepositoryInstance =
      new ExamSubjectAndQuestionRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.selectedDepartment =
      this.$store.getters["liveData/get_examDepartment"];
    this.selectedCourseYear =
      this.$store.getters["liveData/get_examCourseYear"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.examId = this.$store.getters["liveData/get_examId"];
    await this.initialize();
    this.examTabDependecies =
      this.$store.getters["liveData/get_examTabDependencies"];
  },
  methods: {
    async initialize() {
      await this.getStudentsOfACourseYear();
      const studentIds = this.courseYearStudents.map((student) => student.uId);
      await this.getUsersDetails(studentIds);
      await this.fetchSubjects();
      await this.getExam();
      await this.getSubjectData();
      await this.prepareSubjectDetailsTable("fromDatabase");
      this.calculateProgressIndicator();
    },
    async getStudentsOfACourseYear() {
      try {
        this.courseYearStudents =
          await this.semesterUserRepositoryInstance.getStudentsOfACourseYear({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear
          });
      } catch (error) {
        this.courseYearStudents = [];
      }
    },
    selectAllItems() {
      // Toggle the selection of all items based on the selectAll value
      this.filteredStudents.forEach(item => {
        item.isSelected = this.selectAllStudents;
      });
    },
    async fetchSubjects() {
      const allSubjects =
        await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
          {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear
          }
        );
      this.subjects = allSubjects.map((s) => {
        const { subjectCode, subjectName, subjectId } = s;
        return { subjectCode, subjectName, subjectId };
      });
      const userSubjectIds = []
      this.userData.subjects.forEach((subject) => userSubjectIds.push(subject.subjectId))
      const filteredSubjects = []
      this.subjects.forEach((subject) => {
        if (userSubjectIds.includes(subject.subjectId)) {
          filteredSubjects.push(subject)
        }
      })
      this.subjects = filteredSubjects
    },
    async getExam() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          examId: this.examId
        };
        const res = await this.newExamRepositoryInstance.getExam(objToPush);
        this.exam = res.exam;
        // this.exam = res
      } catch (error) {
        console.error("error", error);
      }
    },
    async getSubjectData() {
      const response =
        await this.examSubjectAndQuestionRepositoryInstance.getExamMultipleSubjectQuestions(
          {
            instituteId: this.selectedInstitute,
            examId: this.examId,
            subjectIds: this.exam.subjectIds
          }
        );
        const subjectsData = response.result;
        const temp = this.subjects
        .map((subject) => {
          const dataFound = subjectsData.find(
            (d) => d.subjectId === subject.subjectId
          );
          // let studentIds = this.courseYearStudents.map(
          //   (student) => student.uId
          // );

          let toReturn = {};
          if (dataFound !== undefined) {
            toReturn = {
              subjectCode: subject.subjectCode,
              subjectId: subject.subjectId,
              subjectName: subject.subjectName,
              examDuration: dataFound.examDuration,
              threshold: dataFound.threshold,
              passingMarks: dataFound.passingMarks,
              totalMarks: dataFound.totalMarks,
              examDate: dataFound.examDate,
              examTime: dataFound.examTime,
              allocatedStudentIds: dataFound.allocatedStudents
              // allocatedStudents : dataFound.allocatedStudents,
            };
          } else {
            // toReturn = {
            //   subjectCode : subject.subjectCode,
            //   subjectId : subject.subjectId,
            //   subjectName : subject.subjectName,
            //   allocatedStudentIds : studentIds,
            // }
          }
          return toReturn;
        })
        .filter((item) => item?.subjectId);
      this.selectedSubjects = temp;
      // temp = temp.filter((item) => item);
      // this.
      // this.selectedSubjects =  this.subjects.filter((subject) => this.exam.subjectIds.includes(subject.subjectId))
      // this.selectedSubject.map((subject) => {

      // })
      // this.createdQuestions = assignmentQuestions.result
    },
    async saveSubjectIds() {
      const selectedSubjectsIds = this.selectedSubjects?.map((s) => s?.subjectId);
      try {
        const response = await this.newExamRepositoryInstance.createExam({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          examId: this.examId,
          subjectIds: selectedSubjectsIds,
          courseYear: this.selectedCourseYear
        });
        this.exam = response.exam;
        if (response.status.status === 200) {
          await this.saveSubjectDetails();
          // showStatus("Successfully saved basic details of the exam.", 2000, 'success', this);
          // let response = this.examSubjectAndQuestionRepository.createExamSubjectQuestions({
          //   instituteId: this.selectedInstitute,
          //   semId:  this.selectedSemester.semId,
          //   examId: this.examId,
          // })
        } else {
          showStatus("Something went wrong", 2000, "error", this);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async saveSubjectDetails() {
      const subjectsToUpdate = this.selectedSubjects.map((s) => {
        const { subjectCode, subjectName, subjectId } = s;
        const examDuration =
          s.examDuration != null ? Number(s.examDuration) : null;
        const passingMarks =
          s.passingMarks != null ? Number(s.passingMarks) : null;
        const totalMarks = s.totalMarks != null ? Number(s.totalMarks) : null;
        const threshold = s.threshold != null ? Number(s.threshold) : null;
        const examId = this.examId;
        const instituteId = this.selectedInstitute;
        const examDate = s.examDate;
        const examTime = s.examTime;
        const allocatedStudents = s.allocatedStudent
          ?.filter((s) => s.isSelected === true)
          .map((s) => s.uId);
        // const allocatedStudents = s.allocatedStudents.filter()
        return {
          instituteId,
          examId,
          subjectId,
          subjectCode,
          subjectName,
          examDuration,
          passingMarks,
          totalMarks,
          threshold,
          examDate,
          examTime,
          allocatedStudents
        };
      });
      try {
        const response =
          await this.examSubjectAndQuestionRepositoryInstance.bulkCreateExamSubjectQuestions(
            subjectsToUpdate
          );
        if (response.status.status === 200) {
          showStatus(
            "Successfully saved basic details of the exam.",
            2000,
            "success",
            this
          );
        }
      } catch (err) {
        console.error("error", err);
      }
      // showStatus("Something went wrong", 2000, 'error', this);
      // let response = this.examSubjectAndQuestionRepository.bulkCreateExamSubjectQuestions(subjectsToUpdate)
      // console.log("response", response)
      for (const key in this.examTabDependecies["tab2"]) {
        // this.examTabDependecies['tab3'][key] = Math.random();
        this.examTabDependecies["tab2"][key] = true;
      }
    },
    async getUsersDetails(uIds) {
      const data = await this.userRepositoryInstance.getUsers({
        uIds: uIds
      });
      this.userDetails = data;
    },
    calculateStudentCount(item) {
      let counter = 0;
      item.allocatedStudent.forEach((student) => {
        if (student.isSelected === true) {
          counter++;
        }
      });
      this.$set(item, "allocatedStudentsCount", counter);
      // item.allocatedStudentsCount = counter
      // let temp = item

      // item = temp
    },
    async prepareSubjectDetailsTable(calledFrom) {
      this.selectedSubjects.forEach((selectedSubject) => {
        const studentsForSubject = this.courseYearStudents?.filter((student) =>
          student.subjects.includes(selectedSubject?.subjectId)
        );
        const data = [];
        studentsForSubject.forEach((student, index) => {
          const studentDetails = this.userDetails.find(
            (user) => user.uId === student.uId
          );
          // let isSelected = selectedSubject.allocatedStudentIds?.includes(student.uId)
          // console.log("isSelected", isSelected)
          // let isSelected = selectedSubject.allocatedStudentIds.includes(student.uId)
          // selectedSubject.isSelected
          // let isSelected = true
          // let isSelected = ;
          // console.log("selectedSubject",student,index,selectedSubject.allocatedStudent?.filter((item)=>item.uId ===student.uId))
          let isSelected = true;
          if (calledFrom === "fromDatabase") {
            isSelected = selectedSubject.allocatedStudentIds.includes(
              student.uId
            );
          } else {
            if (selectedSubject.allocatedStudent) {
              // Check if the student is in the allocatedStudent array and get isSelected value
              const studentAllocation = selectedSubject.allocatedStudent.find(
                (item) => item.uId === student.uId
              );
              isSelected = studentAllocation
                ? studentAllocation.isSelected
                : false;
            }
            // isSelected = selectedSubject.allocatedStudent !== undefined ?
            // console.log("here ", selectedSubject.allocatedStudent ?  selectedSubject?.allocatedStudent?.filter(selectedSubject => selectedSubject.uId === student.uId)[0]?.isSelected : true)
          }
          // if(selectedSubject.isSelected){
          //   isSelected = selectedSubject.isSelected
          // }
          data.push({
            uId: student.uId,
            rollNumber: student.rollNumber,
            prnNumber: studentDetails.collegePRNNo,
            examSeatNumber: studentDetails.examSeatNumber,
            studentName:
              studentDetails.firstName + " " + studentDetails.lastName,
            // isSelected: temp?.isSelected !== undefined ? temp?.isSelected : true
            // isSelected: selectedSubject.allocatedStudentIds ? selectedSubject.allocatedStudentIds.includes(student.uId) : isSelected
            isSelected: isSelected
          });
          selectedSubject.allocatedStudent = data;
        });
        this.calculateStudentCount(selectedSubject);
      });
    },
    async prepareSubjectDetailsTable1() {
      this.selectedSubjects.forEach((selectedSubject) => {
        const studentsForSubject = this.courseYearStudents?.filter((student) =>
          student.subjects.includes(selectedSubject?.subjectId)
        );
        const data = [];
        studentsForSubject.forEach((student, index) => {
          const studentDetails = this.userDetails.find(
            (user) => user.uId === student.uId
          );
          const temp = selectedSubject?.allocatedStudent?.filter(
            (selectedSubject) => selectedSubject.uId === student.uId
          )[0];
          let bool;
          if (temp === undefined) {
            bool = true;
          } else {
            bool = temp.isSelected;
          }
          data.push({
            uId: student.uId,
            rollNumber: student.rollNumber,
            prnNumber: studentDetails.collegePRNNo,
            examSeatNumber: studentDetails.examSeatNumber,
            studentName:
              studentDetails.firstName + " " + studentDetails.lastName,
            // isSelected: temp?.isSelected !== undefined ? temp?.isSelected : true
            isSelected: bool
          });
        });
        selectedSubject.allocatedStudent = [...data];
        this.calculateStudentCount(selectedSubject);
        this.calculateProgressIndicator();
      });
    },
    async prepareStudentsDialog(item) {
      this.studentDialog = true;
      this.selectedSubjectToEdit = item;
      this.studentsTableData = item.allocatedStudent;
      this.filteredStudents = this.studentsTableData;
    },
    async saveAllocatedStudents() {
      const selSub = this.selectedSubjects?.map((sub) => {
        if (sub?.subjectId === this.selectedSubjectToEdit?.subjectId) {
          // this.selectedSubjects
          sub.allocatedStudent = this.studentsTableData;
          this.calculateStudentCount(sub);
        }
        // console.log("sub", sub);
      });
      console.log(selSub)
      this.studentDialog = false;
    },
    cancelAllocatedStudets() {
      const selSub = this.selectedSubjects?.map((sub) => {
        if (sub.subjectId === this.selectedSubjectToEdit?.subjectId) {
          // this.selectedSubjects
          // console.log("im am here to cancel all", this.previousItemState);
          this.filteredStudents = this.previousItemState;
        }
        this.calculateStudentCount(sub);
      });
      console.log(selSub)
      this.studentDialog = false;
    },
    calculateProgressIndicator() {
      // this.progressIndicator1 = (this.selectedSubjects.length) / this.examFormTableData.length) * 100;
      this.progressIndicator1 =
        (this.selectedSubjects.length / this.subjects.length) * 100;
      // console.log("999",)
    }
  }
};
</script>

<style src="./examSubjectDetails.css"></style>
