import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/NBA'
import nbaNetworkManager from '../NetworkManager/nba'

class NBARepository {
  constructor (context) {
    this.context = context
  }

  async getNBA (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getNBA, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.NBA || {}
  }

  async getDepartmentNBA (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getDepartmentNBA, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.NBA || {}
  }

  async getSubjectNBA (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSubjectNBA, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.NBA || {}
  }

  async updateNBA (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateNBA, data)
    this.context.$store.commit('liveData/set_loading', false)
    console.log(response)
  }

  async updateDepartmentNBA (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateDepartmentNBA, data)
    this.context.$store.commit('liveData/set_loading', false)
    console.log(response)
  }

  async updateSubjectNBA (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateSubjectNBA, data)
    this.context.$store.commit('liveData/set_loading', false)
    console.log(response)
  }

  async getCOMapping (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getCOMapping, data)
    this.context.$store.commit('liveData/set_loading', false)
    console.log('Vveru anskcjnasckb', response)
    return response.coMappings || []
  }

  async updateCOMapping (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateCOMapping, data)
    this.context.$store.commit('liveData/set_loading', false)
    console.log(response)
  }

  async getCOPSOMapping (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getCOPSOMapping, data)
    this.context.$store.commit('liveData/set_loading', false)
    console.log('Vveru anskcjnasckb', response)
    return response.copsoMappings || []
  }

  async updateCOPSOMapping (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateCOPSOMapping, data)
    this.context.$store.commit('liveData/set_loading', false)
    console.log(response)
  }

  //
  async getCos (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await nbaNetworkManager.getRequest(ApiNames.getCos, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getPos (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await nbaNetworkManager.getRequest(ApiNames.getPos, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getPsos (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await nbaNetworkManager.getRequest(ApiNames.getPsos, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getLevelsOfInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await nbaNetworkManager.getRequest(ApiNames.getLevelsOfInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getTargetsOfInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await nbaNetworkManager.getRequest(ApiNames.getTargetsOfInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default NBARepository
