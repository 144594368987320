export default {
  getDetailsOfASubject: '/subject/getSubject',
  getSubjects: '/subject/getSubjects',
  getSubjectDetails: '/subject/getSubjectDetails',
  getSubjectName: '/subject/getSubjectName',
  getSubjectOfACoordinator: '/subject/getSubjectOfACoordinator',
  getSubjectsOfAInstitute: '/subject/getSubjectsOfAInstitute',
  getSubjectsOfAFaculty: '/subject/getSubjectsOfAFaculty',
  getSubjectsOfACourseYear: '/subject/getSubjectsOfACourseYear',
  getFacultiesTeachingASubject: '/subject/getFacultiesTeachingASubject',
  getSubjectsTaughtByAFaculty: '/subject/getSubjectsTaughtByAFaculty',
  getExamForSimilarSubjectCode: '/subject/getExamForSimilarSubjectCode'
}
