export default {
  createInstitute: '/institute/createInstitute',
  getInstituteInformation: '/institute/getInstitute',
  updateInstituteName: '/institute/updateInstituteName',
  updateInstituteAddress: '/institute/updateInstituteAddress',
  updateAffliatingUniversityName: '/institute/updateAffliatingUniversityName',
  updateAffiliatingUniversityAddress:
    '/institute/updateAffliatingUniversityAddress',
  updateInstituteEmail: '/institute/updateInstituteEmail',
  updateInstitutePhoneNumber: '/institute/updateInstitutePhoneNumber',
  updateEstablishmentYear: '/institute/updateEstablishmentYear',
  updateInstituteType: '/institute/updateInstitutionType',
  updateOwnershipStatus: '/institute/updateOwnershipStatus',
  updateOwners: '/institute/updateOwners',
  updateInstituteWebsite: '/institute/updateInstituteWebsite',
  updateInstituteLocation: '/institute/updateInstituteLocation',
  getInstituteBranding: '/institute/getInstituteBranding',
  updateInstituteLogo: '/institute/updateInstituteLogoLink',
  updateInstituteDetails: '/institute/updateInstituteDetails',
  updateInstituteBrandingName: '/institute/updateInstituteBrandingName'
}
