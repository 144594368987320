import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/ExamStudent'

class ExamStudent {
  constructor (context) {
    this.context = context
  }

  async createExamStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    await networkManager.postRequest(ApiNames.createExamStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
  }

  async getBacklogExamStudentsForAnExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getBacklogExamStudentsForAnExam, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.examStudents
  }

  async getExamStudentForAnExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamStudentForAnExam, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.examStudents
  }

  async getExamAnalysis (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getExamAnalysis, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.examAnalysis
  }

  async generateStudentMarksExcel(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateStudentMarksExcel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default ExamStudent
