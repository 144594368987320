<template src='./activityCreateInstituteOrUser.html'>
</template>
<script>
import activityProfile from '../activityProfile/activityProfile'
export default {
  name: 'activityCreateInstituteOrUser',
  components: {
    activityProfile
  }
}
</script>
<style src='./activityCreateInstituteOrUser.css' scoped>
</style>
