import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/TopicFeedbackActionTemplate'

class TopicFeedbackActionTemplateRepository {
  constructor (context) {
    this.context = context
  }

  async createTopicFeedbackActionTemplate (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createTopicFeedbackActionTemplate, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedbackActionTemplate
  }

  async getTopicFeedbackActionTemplatesOfAnInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicFeedbackActionTemplatesOfAnInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedbackActionTemplates
  }

  async getTopicFeedbackActionTemplateById (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTopicFeedbackActionTemplateById, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.topicFeedbackActionTemplate
  }
}

export default TopicFeedbackActionTemplateRepository
