<template src="./activeCourseAssignmentCheck.html"></template>
<script>
import CourseRepository from '../../../Repository/courses';
import auth from '../../../Services/Firebase/auth';
export default {
    name: 'activityCourseAssignmentCheck',
    props: ["prop_subject", "prop_subSectionId", "prop_uId", "prop_selectedSemester", "prop_userName"],
    data() {
        return {
            selectedSubsection: {},
            courseDetails: {},
            markAsComplete: false,
            checkedAssignmentFile: null,
            uploadAdminsideAssignment: "",
            checkedAssignmentLink: "",
            progressUpload: 0,
            adminAssignmentFeedback: ""
        }
    },
    // watch: {
    //     uploadAdminsideAssignment: function () {
    //         this.uploadAdminsideAssignment.on('state_changed', sp => {
    //             this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
    //         },
    //             null,
    //             () => {
    //                 this.uploadAdminsideAssignment.snapshot.ref.getDownloadURL().then(downloadURL => {
    //                     this.checkedAssignmentLink = downloadURL
    //                 })
    //             })
    //     }
    // },
    async created() {
        this.$store.commit("liveData/set_selectedActivityName", this.prop_subject.courseName);
        this.CourseRepositoryInstance = new CourseRepository(this)
        this.fetchData();
        const firebase = auth.getFirebaseObject()
        this.firestorage = firebase.storage()
    },
    methods: {
        async fetchData() {
            this.isLoading = true
            try {
                const objToPush = {
                    instituteId: this.prop_subject.instituteId,
                    semId: this.prop_subject.semId,
                    department: this.prop_subject.department,
                    courseYear: this.prop_subject.courseYear,
                    subjectId: this.prop_subject.subjectId,
                    courseName: this.prop_subject.courseName,
                    uId: this.prop_uId
                }
                const { data } = await this.CourseRepositoryInstance.getSyncCoursesDataOfSingleStudent(objToPush);
                if (data.isCourseFound) {
                    this.courseDetails = data.courseDetails;
                    this.sectionCompletedCount = data.sectionCompletedCount;
                    this.totalCredit = data.totalCredit;
                    this.totalCreditsRecieved = data.totalCreditsRecieved;
                    this.subsectionCompletedCount = data.subsectionCompletedCount;
                    this.progressPercentage = data.progressPercentage;
                    this.studentCourse = data.studentCourse;
                    this.subsectinsCount = data.subsectinsCount;
                    this.isCourseCompleted = this.sectionCompletedCount === this.courseDetails.sections.length
                    this.editCourseSubsections = JSON.parse(JSON.stringify(this.courseDetails));

                    let tempCourseCompleted = true;
                    for (let i = 0; i < this.courseDetails.sections.length; i++) {
                        if (!this.courseDetails.sections[i].isSectionCompleted) {
                            tempCourseCompleted = false;
                            break;
                        }
                    }
                    this.isCourseCompleted = tempCourseCompleted
                }
                for (let i = 0; i < this.courseDetails.sections.length; i++) {
                    if (this.courseDetails.sections[i].subSections && Array.isArray(this.courseDetails.sections[i].subSections)) {
                        for (let j = 0; j < this.courseDetails.sections[i].subSections.length; j++) {
                            // eslint-disable-next-line eqeqeq
                            if (this.courseDetails.sections[i].subSections[j].subSectionId == this.prop_subSectionId) {
                                this.selectedSubsection = this.courseDetails.sections[i].subSections[j];
                                break;
                            }
                        }
                    }
                }
                this.adminAssignmentFeedback = this.selectedSubsection.assignmentFeedback;
                if (this.selectedSubsection.checkedAssignmentFileUrl && this.selectedSubsection.checkedAssignmentFileUrl !== "") {
                    this.checkedAssignmentFile = { name: this.extractFileNameFromUrl(this.selectedSubsection.checkedAssignmentFileUrl) }
                    this.checkedAssignmentLink = this.selectedSubsection.checkedAssignmentFileUrl
                }
            } catch (err) {
                console.log(err);
                this.showContentNotFoundModal = true
            } finally {
                this.isLoading = false
            }
        },
        extractFileNameFromUrl(url) {
            const parts = url.split('/');
            const fileNameWithToken = parts[parts.length - 1];
            const fileNameDecoded = decodeURIComponent(fileNameWithToken);
            const fileName = fileNameDecoded.split('?')[0];
            return fileName;
        },
        async handleUploadCheckedAssignment() {
            try {
                console.log("We are here");

                if (this.checkedAssignmentFile != null) {
                    this.fileName = this.checkedAssignmentFile.name;
                    this.uploading = true;

                    const storagePath = 'checkedAssignment/' + new Date().valueOf().toString() + '________' + this.checkedAssignmentFile.name;
                    this.uploadAdminsideAssignment = this.firestorage.ref(storagePath);

                    const snapshot = await this.uploadAdminsideAssignment.put(this.checkedAssignmentFile);
                    const link = await snapshot.ref.getDownloadURL();
                    this.checkedAssignmentLink = link;

                    console.log(link);
                }

                console.log("We are here End");
            } catch (error) {
                console.error("Error uploading assignment:", error);
            } finally {
                this.uploading = false;
            }
        },
        async handleSubmit() {
            this.selectedSubsection.assignmentFeedback = this.adminAssignmentFeedback;
            this.selectedSubsection.checkedAssignmentFileUrl = this.checkedAssignmentLink;
            this.selectedSubsection.isSubsectionCompleted = this.markAsComplete
            this.selectedSubsection.isSubsectionChecked = true

            for (let i = 0; i < this.courseDetails.sections.length; i++) {
                if (this.courseDetails.sections[i].subSections && Array.isArray(this.courseDetails.sections[i].subSections)) {
                    for (let j = 0; j < this.courseDetails.sections[i].subSections.length; j++) {
                        // eslint-disable-next-line eqeqeq
                        if (this.courseDetails.sections[i].subSections[j].subSectionId == this.prop_subSectionId) {
                            this.selectedSubsection = this.courseDetails.sections[i].subSections[j];
                            this.courseDetails.sections[i].subSections[j] = this.selectedSubsection;
                            break;
                        }
                    }
                }
            }
            const objToPush = {
                instituteId: this.courseDetails.instituteId,
                semId: this.courseDetails.semId,
                department: this.courseDetails.department,
                courseYear: this.courseDetails.courseYear,
                courseId: this.courseDetails.courseId,
                uId: this.prop_uId,
                sections: JSON.parse(JSON.stringify(this.courseDetails.sections))
            }
            await this.CourseRepositoryInstance.createStudentCourse(objToPush)
            this.goBack()
        },
        goBack() {
            this.$router.push({
                name: "activityCourseAnalysis",
                params: {
                    prop_subject: this.prop_subject,
                    prop_selectedSemester: this.prop_selectedSemester
                }
            });
        }
    }
}
</script>
<style src="./activeCourseAssignmentCheck.css" scoped></style>
