<template>
    <v-card style="margin-top: 0px; margin-bottom:15px; box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 10px; border-radius: 20px;;">
        <v-card-text style="color:#000">
            <v-row justify="start" align="center">

                <v-col align="start" style="display: flex;">
                    <v-row style="padding-left: 5px">
                        <v-col cols="1" class="my-auto">
                        <v-btn
                        fab
                        small
                        outlined
                        color="#050992"
                        style="
                            background-color: #dbddff;
                            height: 36px;
                            width: 36px;"
                        v-if="!isMobileView"
                      >
                        {{(discussion.postedByFullname || "").substring(0,1).toUpperCase()}}
                      </v-btn>
                    </v-col>
                    <div class="title"
                      style="
                        font-size: 1rem !important;
                        white-space: nowrap !important;
                        text-overflow: ellipsis;
                        display: flex;
                        align-content: center;
                        flex-wrap: wrap;
                        margin-left: 35px;
                    ">
                        {{ discussion.postedByFullname }}
                    </div>
                </v-row>
            </v-col>
            <v-col align="end" style="display: flex; justify-content: flex-end; align-items: center">
                <div style="font-weight: unset;">{{ formattedStartDate }}
                </div>
                <!-- {{ discussion.postedById }}
                {{ uId }} -->
                <v-btn
                    v-if="discussion.postedById === uId"
                    :loading="postBeingDeleted === discussion.discussionId"
                    icon
                    class="ml-2 text-sm-right"
                    @click="showModal2 = true">
                    <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <!-- divider/seperator -->
        <v-divider style="width: 100%; margin: 5px 0 20px 0; margin-top: 20px; opacity: 20%"></v-divider>
            <div style="display: flex; justify-content: space-between">
                <inputContainer
                    style="text-align:center; height:unset; padding-left: 0; color:#00128C; background-color: none;font-weight:bold; font-size:18px;text-align: left;">
                    {{ discussion.title }}
                </inputContainer>
                <v-icon
                color="orange"
                style="margin-right: 10px"
                >mdi-star-circle</v-icon
                >
            </div>
            <p style="margin-bottom: 0px !important;">{{ discussion.description }}</p>
            <br>
            <v-row  align="center">
                <v-col :cols="isMobileView ? 12 : 3">
                    <p style="margin:unset; font-size:14px; color:#3a3a3a; margin-bottom: 0px !important;">Total Participant</p>
                    <p style="font-weight:bold; font-size:16px; color:#3a3a3a">{{ discussion.totalParticipants }}</p>
                </v-col>
                <v-col :cols="isMobileView ? 12 : 3">
                    <p style="margin:unset; font-size:14px; color:#3a3a3a; margin-bottom: 0px !important;">Total Responses</p>
                    <p style="font-weight:bold; font-size:16px; color:#3a3a3a">{{ discussion.totalResponses }}</p>
                </v-col><v-col :cols="isMobileView ? 12 : 3">
                    <p style="margin:unset; font-size:14px; color:#3a3a3a; margin-bottom: 0px !important;">{{ ended ? "Ended At" : "Open till" }}</p>
                    <p style="font-weight:bold; font-size:16px; color:#3a3a3a">{{
                        formattedEndDate
                    }} </p>
                </v-col>
            </v-row>
            <br>
            <button block elevation="2" style="width: 100%;
              margin-top: 0px;
              border: solid 2px #ff4f1f;
              border-radius: 4px;
              color: #ff4f1f; padding:10px" @click="onClickGo"><span style="white-space: normal; font-size: 16px">
                Go To Discussion
            </span></button>

            <!-- <button
            elevation="2"
            outlined
            large
            style="
              width: 100%;
              margin-top: 20px;
              border: solid 2px #ff4f1f;
              color: #ff4f1f;
            "
            class="pa-3 rounded"
            @click="onClickGo"
          >
            <span style="white-space: normal; font-size: 16px">
                Go To Discussion
            </span>
          </button> -->

        </v-card-text>

        <!-- Discussion Delete -->
            <v-dialog v-model="showModal2"  width="30vw">
            <v-card height="190px">
            <div class="confirmation-modal">
                <p style="font-weight: 600; font-size: 24px;">Confirmation</p>
                <p style="font-weight: 400; font-size: 16px;">Are you sure you want to delete?</p>
                <div class="buttons">
                <button @click="showModal2 = false" class="cancel-button" style="text-decoration: underline; font-weight: 500;">Cancel</button>
                <button @click="deleteDiscussion(discussion)" class="Delete-button" style="font-weight: 500;">Delete</button>
                </div>
            </div>
            </v-card>
            </v-dialog>
    </v-card>
</template>
<script>
// import inputContainer from '../inputContainer.vue';
import moment from "moment"
import inputContainer from '../inputContainer.vue';

export default {
    name: 'DiscussionListCard',
    components: { inputContainer },
    props: {
        discussion: Object,
        isMobileView: Boolean,
        deleteDiscussion: Function,
        uId: String
    },
    data() {
        return {
            formattedStartDate: "",
            formattedEndDate: "",
            ended: false,
            showModal2: false,
            postBeingDeleted: null
        }
    },
    created() {
        this.formattedStartDate = moment(this.discussion.startDate).format("DD/MM/YYYY LT")
        this.formattedEndDate = moment(this.discussion.endDate).format("DD/MM/YYYY LT")
        this.ended = new Date(this.discussion.endDate) < new Date()
        // console.log('uiD', this.uId)
    },
    methods: {
        onClickGo() {
            this.$router.push({ name: "activityDiscussion", query: { discussionId: this.discussion.discussionId } })
        }
    }
}
</script>
<style scoped></style>
